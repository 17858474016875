import { createAction, props } from '@ngrx/store';
import { Player, CardReveal } from './state';

export const updatePlayers = createAction(
  '[Clue Game] Update Players Array',
  props<{ playersArr: Player[] }>(),
);
export const updateCurrentUser = createAction(
  '[Clue Game] Update Current User',
  props<{ currentUser: Player }>(),
);

export const goToNextPlayer = createAction('[Clue Game] Next Players Turn');
export const updateStartGameBtn = createAction(
  '[Clue Game] Update Start Game Button',
  props<{ enableStartGameBtn: boolean }>(),
);
export const updateEndGameBtn = createAction(
  '[Clue Game] Update End game Btn',
  props<{ enableEndGameBtn: boolean }>(),
);
export const updateCardReveal = createAction(
  '[Clue Game] Update Card Reveal',
  props<{ cardReveal: CardReveal }>(),
);

export const toggleShowExample = createAction(
  '[Clue Game] Toggle Show Example',
);
export const toggleShowInstruction = createAction(
  '[Clue Game] Toggle Show Instruction',
);
export const toggleShowOverview = createAction(
  '[Clue Game] Toggle Show Overview',
);
export const closeShowInstruction = createAction(
  '[Clue Game] Close Show Instruction',
);
export const closeShowOverview = createAction(
  '[Clue Game] Close Show Overview',
);
