import {
  Component,
  Input,
  ViewChild,
  ViewEncapsulation,
  Output,
  EventEmitter,
} from '@angular/core';
import { PLExpansionPanelComponent } from '@common/components/pl-expansion-panel/pl-expansion-panel.component';

@Component({
  selector: 'pl-drawer-panel',
  templateUrl: 'pl-drawer-panel.component.html',
  styleUrls: ['pl-drawer-panel.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class PLDrawerPanelComponent {
  @ViewChild(PLExpansionPanelComponent) panel: PLExpansionPanelComponent;
  @Output() readonly opened = new EventEmitter<void>();
  @Output() readonly closed = new EventEmitter<void>();
  @Output() readonly rightIconClick = new EventEmitter<void>();
  @Input() header: string;
  @Input() noExpand = false;
  @Input() rightIcon: string;

  onRightIconClick() {
    this.rightIconClick.emit();
  }

  onPanelOpened() {
    this.opened.emit();
  }

  onPanelClosed() {
    this.closed.emit();
  }

  close() {
    this.panel.close();
  }

  open() {
    this.panel.open();
  }

  toggle() {
    this.panel.toggle();
  }
}
