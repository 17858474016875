import { Component, EventEmitter, Input, Output } from '@angular/core';
export enum SortType {
  Custom = 'custom',
  Alphabetical = 'alphabetical',
  ReverseAlphabetical = 'reverse-alphabetical',
  Date = 'date',
  ReverseDate = 'reverse-date',
}
export interface SortOption {
  value: SortType;
  label: string;
}

export const sortOptionsDict: Record<SortType, SortOption> = {
  [SortType.Custom]: { value: SortType.Custom, label: 'Custom order' },
  [SortType.Alphabetical]: {
    value: SortType.Alphabetical,
    label: 'Alphabetical A-Z',
  },
  [SortType.ReverseAlphabetical]: {
    value: SortType.ReverseAlphabetical,
    label: 'Alphabetical Z-A',
  },
  [SortType.Date]: { value: SortType.Date, label: 'Newest' },
  [SortType.ReverseDate]: { value: SortType.ReverseDate, label: 'Oldest' },
};

@Component({
  selector: 'pl-sort-menu',
  templateUrl: './sort-menu.component.html',
  styleUrls: ['./sort-menu.component.less'],
})
export class SortMenuComponent {
  // default to all options being available
  @Input() sortOptions: SortOption[] = [
    sortOptionsDict[SortType.Custom],
    sortOptionsDict[SortType.Alphabetical],
    sortOptionsDict[SortType.ReverseAlphabetical],
    sortOptionsDict[SortType.Date],
    sortOptionsDict[SortType.ReverseDate],
  ];
  @Input() currentSortOption: SortOption = sortOptionsDict[SortType.Custom];
  @Input() disabled: boolean = false;
  @Output() readonly sortOptionSelected = new EventEmitter<SortOption>();

  sortMenuOpen = false;

  sortOptionSelectedHandler(option: SortOption) {
    this.sortMenuOpen = false;
    this.sortOptionSelected.emit(option);
  }
}
