import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogData, PLConfirmComponent } from './pl-confirm.component';

@Injectable({ providedIn: 'root' })
export class PLConfirmService {
  constructor(private dialogService: MatDialog) {}

  show(data: ConfirmDialogData) {
    return this.dialogService.open(PLConfirmComponent, {
      data,
      width: '400px',
    });
  }
}
