import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppModel } from '@common/models/app-model.service';
import { Store } from '@ngrx/store';
import { AppState } from '@root/src/app/store';
import { TokboxRecordService } from './tokbox-record.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'pl-session-record-drawer',
  templateUrl: 'pl-session-record-drawer.component.html',
  styleUrls: ['./pl-session-record-drawer.component.less'],
})
export class PLSessionRecordDrawerComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  constructor(
    public appModel: AppModel,
    private store: Store<AppState>,
    private tokboxRecordService: TokboxRecordService,
  ) {}

  ngOnInit() {
    this.subscriptions.push(this.subscribeToRecordActions());
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private subscribeToRecordActions() {
    return this.store.select('tokboxRecord').subscribe(data => {
      if (!data) {
        return;
      }
      const type = data.type;
      const payload = data.payload;
      switch (type) {
        case 'TOKBOX_RECORD_TOGGLE':
          this.tokboxRecordService.handleRecordToggle(payload);
          return;
        case 'TOKBOX_SCREENSHARE_TOGGLE':
          this.tokboxRecordService.handleScreenshareToggle(payload);
          return;
      }
    });
  }
}
