<div class="kanga-documentation">
  <pl-kanga-documentation-appointment-selection
    *ngIf="
      (isFreemiumOver$ | async) !== null || (isSessionInProgress$ | async);
      else freemiumOver
    "
    [events]="events"
    [clients]="clients"
    [selectedStudent]="selectedStudent"
    (selectionChanged)="onSelectStudent($event)">
  </pl-kanga-documentation-appointment-selection>

  <ng-template #freemiumOver>
    <div class="freemium-over">
      <div class="margin-b">
        You do not have any free minutes left for this month.
      </div>
      <div class="margin-b">
        You must upgrade your license to proceed or wait until next month to
        have a session.
      </div>
    </div>
  </ng-template>

  <div class="margin-t padding session-complete" *ngIf="lastSavedSessionItem">
    <div class="margin-b">Your session is now complete.</div>
    <div class="margin-b">
      You can find notes and related information on the Student Profile under
      Sessions tab.
    </div>
    <div class="student-link" (click)="onOpenStudentProfile()">
      View Student Profile
    </div>
  </div>

  <pl-kanga-documentation-appointment-item
    (sessionEnded)="onSessionEnded()"
    [studentItem]="selectedStudent"></pl-kanga-documentation-appointment-item>
</div>
