import { Injectable } from '@angular/core';
import { of, EMPTY } from 'rxjs';
import {
  concatMap,
  catchError,
  map,
  exhaustMap,
  tap,
  filter,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { AppState } from '@app/store';
import { DevicesHistoryService, PersistentDeviceSettings } from '@common/media';
import { PluckDefinedResult, pluckDefinedWithId } from '@common/rxjs/operators';
import {
  SessionActions,
  Participant,
  selectLocalParticipant,
  filterSessionReady,
  ParticipantType,
} from '@room/session/store';
import { LayoutMode, selectLayoutMode } from '@room/app/store';
import { RoomConferenceService } from '@root/src/app/modules/room/conference/room-conference.service';
import { ConferenceRTService } from '@room/conference/conference-rt.service';
import {
  selectLocalStream,
  selectParticipantPrimaryStreamId,
  selectLocalSecondaryStreamId,
  selectHasLocalJoined,
  selectLocalPrimaryId,
  selectStreamIsMuted,
  selectStreamIsHidden,
  selectIsStreamVideoDeviceAvailable,
} from '../conference.selectors';
import { StreamType, LocalStream, FilterVideoType } from '../conference.model';
import {
  ConferenceActions,
  ConferenceActionTypes,
} from '../conference.actions';

class LocalSecondaryStreamNotFoundError extends Error {
  constructor() {
    super(`Local Secondary Stream not found`);
  }
}

@Injectable()
export class ConferenceLocalStreamsEffects {
  localParticipantAdded$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SessionActions.addLocal),
      concatLatestFrom(() => this.store.select(selectLayoutMode)),
      map(([{ participant }, layoutMode]) => {
        const isPromoted =
          (participant.type === ParticipantType.host &&
            layoutMode === LayoutMode.jumbotron) ||
          layoutMode === LayoutMode.grid;
        return this.createLocalPrimary(participant, isPromoted);
      }),
      map(stream => ConferenceActions.addLocalPrimary({ stream })),
    );
  });

  addPrimaryLocalStream$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConferenceActions.addLocalPrimary),
      exhaustMap(({ stream }) => {
        return this.conferenceRTService.addStream(stream).pipe(
          map(() => ConferenceActions.addLocalPrimarySuccess({ stream })),
          catchError(error => {
            const errorObj = {
              stream,
              error,
            };
            console.error('ADD_LOCAL_PRIMARY', errorObj);
            return of(ConferenceActions.addLocalPrimaryError(errorObj));
          }),
        );
      }),
    );
  });

  joinError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ConferenceActions.joinError),
        concatMap(action => {
          return of(action).pipe(
            withLatestFrom(this.store.select(selectLocalStream(action.id))),
          );
        }),
        tap(([_, stream]) => {
          this.deviceHistory.removeAudioDeviceId(stream.type);
          this.deviceHistory.removeVideoDeviceId(stream.type);
        }),
      );
    },
    {
      dispatch: false,
    },
  );

  // after the MediaStream is created we set the stream device id info
  setDefaultMedia$ = createEffect(() => {
    return this.onLocalStreamJoined().pipe(
      map(({ id, audioSource, videoSource }) => {
        return ConferenceActions.setDefaultMedia({
          id,
          devices: {
            audio: audioSource,
            video: videoSource,
          },
        });
      }),
    );
  });

  persistDefaultDevices$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ConferenceActions.setDefaultMedia),
        concatMap(action => {
          return of(action).pipe(
            withLatestFrom(this.store.select(selectLocalStream(action.id))),
          );
        }),
        tap(([{ devices }, stream]) => {
          const { audio, video } = devices;

          if (video) {
            this.deviceHistory.setVideoDeviceId(stream.type, video);
          }

          if (audio) {
            this.deviceHistory.setAudioDeviceId(stream.type, audio);
          }
        }),
      );
    },
    {
      dispatch: false,
    },
  );

  toggleRTVideo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConferenceActions.hide, ConferenceActions.show),
      concatMap(({ id, type }) => {
        const isHidden = type === ConferenceActionTypes.hide;
        const successAction = isHidden
          ? ConferenceActions.hideSuccess
          : ConferenceActions.showSuccess;
        const errorAction = isHidden
          ? ConferenceActions.hideError
          : ConferenceActions.showError;

        return this.conferenceRTService
          .updateStream(id, {
            video: {
              isHidden,
            },
          })
          .pipe(
            map(() => successAction({ id })),
            catchError(error => {
              const errorObj = {
                id,
                error,
              };
              console.error('TOGGLE_VIDEO', errorObj);
              return of(errorAction(errorObj));
            }),
          );
      }),
    );
  });

  toggleLocalVideo$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ConferenceActions.hideSuccess, ConferenceActions.showSuccess),
        concatMap(({ id }) => {
          return of(id).pipe(
            withLatestFrom(this.store.select(selectStreamIsHidden(id))),
          );
        }),
        switchMap(([id, isHidden]) => {
          if (isHidden) {
            this.conferenceService.hide(id);
          } else {
            this.conferenceService.show(id);
          }
          return EMPTY;
        }),
      );
    },
    {
      dispatch: false,
    },
  );

  toggleRTAudio$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConferenceActions.unmute, ConferenceActions.mute),
      concatMap(({ id, type }) => {
        const isMuted = type === ConferenceActionTypes.mute;
        const successAction = isMuted
          ? ConferenceActions.muteSuccess
          : ConferenceActions.unmuteSuccess;
        const errorAction = isMuted
          ? ConferenceActions.muteError
          : ConferenceActions.unmuteError;

        return this.conferenceRTService
          .updateStream(id, {
            microphone: {
              isMuted,
            },
          })
          .pipe(
            map(() => successAction({ id })),
            catchError(error => {
              const errorObj = {
                id,
                error,
              };
              console.error('TOGGLE_AUDIO', errorObj);
              return of(errorAction(errorObj));
            }),
          );
      }),
    );
  });

  toggleLocalAudio$ = createEffect(
    () => {
      return this.store.select(selectHasLocalJoined).pipe(
        filter(Boolean),
        concatMap(() => {
          return this.store.select(selectLocalPrimaryId);
        }),
        switchMap(id => {
          return this.store.select(selectStreamIsMuted(id)).pipe(
            switchMap(isMuted => {
              if (isMuted) {
                this.conferenceService.mute(id);
              } else {
                this.conferenceService.unmute(id);
              }
              return EMPTY;
            }),
          );
        }),
      );
    },
    {
      dispatch: false,
    },
  );

  setIsMutedRemotely$ = createEffect(() => {
    return this.onLocalStreamChangedFromRT().pipe(
      pluckDefinedWithId('microphone'),
      pluckDefinedWithId('isMuted'),
      map(([id, isMuted]) =>
        ConferenceActions.setIsMutedRemotely({ id, isMuted }),
      ),
    );
  });

  setAudioSource$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConferenceActions.setMicDevice),
      concatMap(action => {
        return of(action).pipe(
          withLatestFrom(
            this.store.select(
              selectParticipantPrimaryStreamId(action.participantId),
            ),
          ),
        );
      }),
      filter(([_, streamId]) => !!streamId),
      concatMap(([{ micDevice }, streamId]) => {
        return this.conferenceService.setAudioSource(streamId, micDevice).pipe(
          map(() =>
            ConferenceActions.setMicDeviceSuccess({ streamId, micDevice }),
          ),
          catchError(error => {
            const errorObj = {
              streamId,
              micDevice,
              error,
            };
            console.error('SET_AUDIO_SOURCE', errorObj);
            return of(ConferenceActions.setMicDeviceError(errorObj));
          }),
        );
      }),
    );
  });

  persistMicDevice$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ConferenceActions.setMicDeviceSuccess),
        concatMap(action => {
          return of(action).pipe(
            withLatestFrom(
              this.store.select(selectLocalStream(action.streamId)),
            ),
          );
        }),
        tap(([{ micDevice }, stream]) => {
          this.deviceHistory.setAudioDeviceId(stream.type, micDevice);
        }),
      );
    },
    {
      dispatch: false,
    },
  );

  setDisplayName$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConferenceActions.setDisplayName),
      concatMap(({ id, displayName }) => {
        return this.conferenceRTService.updateStream(id, { displayName }).pipe(
          map(() =>
            ConferenceActions.setDisplayNameSuccess({ id, displayName }),
          ),
          catchError(error => {
            const errorObj = {
              id,
              displayName,
              error,
            };
            console.error('SET_DISPLAY_NAME', errorObj);
            return of(ConferenceActions.setDisplayNameError(errorObj));
          }),
        );
      }),
    );
  });

  displayNameUpdatedRemotely$ = createEffect(() => {
    return this.onLocalStreamChangedFromRT().pipe(
      pluckDefinedWithId('displayName'),
      map(([id, displayName]) =>
        ConferenceActions.setDisplayNameRemotely({ id, displayName }),
      ),
    );
  });

  setVideoSource$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConferenceActions.setVideoDevice),
      concatMap(({ id, videoDevice }) => {
        return this.conferenceService.setVideoSource(id, videoDevice).pipe(
          map(() =>
            ConferenceActions.setVideoDeviceSuccess({ id, videoDevice }),
          ),
          catchError(error => {
            const errorObj = {
              id,
              videoDevice,
              error,
            };
            console.error('SET_VIDEO_SOURCE', errorObj);
            return of(ConferenceActions.setVideoDeviceError(errorObj));
          }),
        );
      }),
    );
  });

  persistVideoDevice$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ConferenceActions.setVideoDeviceSuccess),
        concatMap(action => {
          return of(action).pipe(
            withLatestFrom(this.store.select(selectLocalStream(action.id))),
          );
        }),
        tap(([{ videoDevice }, stream]) => {
          this.deviceHistory.setVideoDeviceId(stream.type, videoDevice);
        }),
      );
    },
    {
      dispatch: false,
    },
  );

  toggleRTIsPromoted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConferenceActions.demote, ConferenceActions.promote),
      concatMap(({ id, type }) => {
        const isPromoted = type === ConferenceActionTypes.promote;
        const successAction = isPromoted
          ? ConferenceActions.promoteSuccess
          : ConferenceActions.demoteSuccess;
        const errorAction = isPromoted
          ? ConferenceActions.promoteError
          : ConferenceActions.demoteError;

        return this.conferenceRTService
          .updateStream(id, {
            isPromoted,
          })
          .pipe(
            map(() => successAction({ id })),
            catchError(error => {
              const errorObj = {
                id,
                error,
              };
              console.error('TOGGLE_IS_PROMOTED', errorObj);
              return of(errorAction(errorObj));
            }),
          );
      }),
    );
  });

  isPromotedUpdatedRemotely$ = createEffect(() => {
    return this.onLocalStreamChangedFromRT().pipe(
      pluckDefinedWithId('isPromoted'),
      map(([id, isPromoted]) =>
        ConferenceActions.setIsPromotedRemotely({ id, isPromoted }),
      ),
    );
  });

  addLocalSecondary$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConferenceActions.addLocalSecondary),
      switchMap(action => {
        return of(action).pipe(
          withLatestFrom(this.store.select(selectLocalParticipant)),
          withLatestFrom(this.store.select(selectLayoutMode)),
        );
      }),
      exhaustMap(([[{ videoDeviceId }, participant], layoutMode]) => {
        const isPromoted = layoutMode === LayoutMode.grid;
        const stream = this.createLocalSecondary(
          participant,
          videoDeviceId,
          isPromoted,
        );
        return this.conferenceRTService.addStream(stream).pipe(
          map(() =>
            ConferenceActions.addLocalSecondarySuccess({
              stream,
            }),
          ),
          catchError(error => {
            const errorObj = {
              stream,
              error,
            };
            console.error('ADD_LOCAL_SECONDARY', errorObj);
            return of(ConferenceActions.addLocalSecondaryError(errorObj));
          }),
        );
      }),
    );
  });

  removeLocalSecondary$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConferenceActions.removeLocalSecondary),
      switchMap(action => {
        return of(action).pipe(
          withLatestFrom(this.store.select(selectLocalSecondaryStreamId)),
        );
      }),
      exhaustMap(([_, streamId]) => {
        if (!streamId) {
          throw new LocalSecondaryStreamNotFoundError();
        }

        return this.conferenceRTService.removeStream(streamId).pipe(
          map(() =>
            ConferenceActions.removeLocalSecondarySuccess({
              id: streamId,
            }),
          ),
          catchError(error => {
            const errorObj = {
              error,
              id: streamId,
            };
            console.error('REMOVE_LOCAL_SECONDARY', errorObj);
            return of(ConferenceActions.removeLocalSecondaryError(errorObj));
          }),
        );
      }),
    );
  });

  secondaryDeviceNotAvailable$ = createEffect(() => {
    return this.store.select(selectLocalSecondaryStreamId).pipe(
      switchMap(streamId => {
        if (!streamId) {
          return EMPTY;
        }
        return this.store
          .select(selectIsStreamVideoDeviceAvailable(streamId))
          .pipe(
            switchMap(isAvailable => {
              if (isAvailable) {
                return EMPTY;
              }

              return of(ConferenceActions.removeLocalSecondary());
            }),
          );
      }),
    );
  });

  toggleRTCovered$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConferenceActions.uncover, ConferenceActions.cover),
      concatMap(({ id, type }) => {
        const isCovered = type === ConferenceActionTypes.cover;
        const successAction = isCovered
          ? ConferenceActions.coverSuccess
          : ConferenceActions.uncoverSuccess;
        const errorAction = isCovered
          ? ConferenceActions.coverError
          : ConferenceActions.uncoverError;

        return this.conferenceRTService
          .updateStream(id, {
            video: {
              effects: {
                isCovered,
              },
            },
          })
          .pipe(
            map(() => successAction({ id })),
            catchError(error => {
              const errorObj = {
                id,
                error,
              };
              console.error('TOGGLE_IS_COVERED', errorObj);
              return of(errorAction(errorObj));
            }),
          );
      }),
    );
  });

  isCoveredUpdatedRemotely$ = createEffect(() => {
    return this.onLocalStreamChangedFromRT().pipe(
      pluckDefinedWithId('video'),
      pluckDefinedWithId('effects'),
      pluckDefinedWithId('isCovered'),
      map(([id, isCovered]) =>
        ConferenceActions.setIsCoveredRemotely({ id, isCovered }),
      ),
    );
  });

  streamRotated$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ConferenceActions.rotate),
        switchMap(action => this.withLocalStreamIds(action)),
        switchMap(([{ id: rotatedId }, primaryId, secondaryId]) => {
          let localDevice = '';
          if (rotatedId === primaryId) {
            localDevice = 'primary';
          } else if (rotatedId === secondaryId) {
            localDevice = 'secondary';
          }
          if (localDevice) {
            this.deviceHistory.setSetting(
              'video',
              localDevice,
              PersistentDeviceSettings.isRotated,
              true,
            );
          }
          return EMPTY;
        }),
      );
    },
    {
      dispatch: false,
    },
  );

  streamDeRotated$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ConferenceActions.derotate),
        switchMap(action => this.withLocalStreamIds(action)),
        switchMap(([{ id: rotatedId }, primaryId, secondaryId]) => {
          let localDevice = '';
          if (rotatedId === primaryId) {
            localDevice = 'primary';
          } else if (rotatedId === secondaryId) {
            localDevice = 'secondary';
          }
          if (localDevice) {
            this.deviceHistory.setSetting(
              'video',
              localDevice,
              PersistentDeviceSettings.isRotated,
              false,
            );
          }
          return EMPTY;
        }),
      );
    },
    {
      dispatch: false,
    },
  );

  streamMirrored$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ConferenceActions.mirror),
        switchMap(action => this.withLocalStreamIds(action)),
        switchMap(([{ id: rotatedId }, primaryId, secondaryId]) => {
          let localDevice = '';
          if (rotatedId === primaryId) {
            localDevice = 'primary';
          } else if (rotatedId === secondaryId) {
            localDevice = 'secondary';
          }
          if (localDevice) {
            this.deviceHistory.setSetting(
              'video',
              localDevice,
              PersistentDeviceSettings.isMirrored,
              true,
            );
          }
          return EMPTY;
        }),
      );
    },
    {
      dispatch: false,
    },
  );

  streamUnMirrored$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ConferenceActions.unmirror),
        switchMap(action => this.withLocalStreamIds(action)),
        switchMap(([{ id: rotatedId }, primaryId, secondaryId]) => {
          let localDevice = '';
          if (rotatedId === primaryId) {
            localDevice = 'primary';
          } else if (rotatedId === secondaryId) {
            localDevice = 'secondary';
          }
          if (localDevice) {
            this.deviceHistory.setSetting(
              'video',
              localDevice,
              PersistentDeviceSettings.isMirrored,
              false,
            );
          }
          return EMPTY;
        }),
      );
    },
    {
      dispatch: false,
    },
  );

  streamBlurred$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ConferenceActions.blur),
        switchMap(action => this.withLocalStreamIds(action)),
        switchMap(([{ id: blurredId }, primaryId, secondaryId]) => {
          let localDevice = '';
          if (blurredId === primaryId) {
            localDevice = 'primary';
          } else if (blurredId === secondaryId) {
            localDevice = 'secondary';
          }
          if (localDevice) {
            this.deviceHistory.setSetting(
              'video',
              localDevice,
              PersistentDeviceSettings.filterMode,
              FilterVideoType.BLUR,
            );
          }
          return this.conferenceService.setBlurred(blurredId).pipe(
            map(() => console.log('blurredId', blurredId)),
            catchError(error => {
              const errorObj = {
                blurredId,
                error,
              };
              console.warn('SET_STREAM_BLURRED', errorObj);
              return EMPTY;
            }),
          );
        }),
      );
    },
    {
      dispatch: false,
    },
  );

  streamBackgrounded$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ConferenceActions.backgroundImage),
        switchMap(action => this.withLocalStreamIds(action)),
        switchMap(([{ id: backgroundedId }, primaryId, secondaryId]) => {
          let localDevice = '';
          if (backgroundedId === primaryId) {
            localDevice = 'primary';
          } else if (backgroundedId === secondaryId) {
            localDevice = 'secondary';
          }
          if (localDevice) {
            this.deviceHistory.setSetting(
              'video',
              localDevice,
              PersistentDeviceSettings.filterMode,
              FilterVideoType.BACKGROUND,
            );
          }
          return this.conferenceService.setVideoBackground(backgroundedId).pipe(
            map(() => console.log('backgroundId', backgroundedId)),
            catchError(error => {
              const errorObj = {
                backgroundedId,
                error,
              };
              console.warn('SET_STREAM_BACKGROUND', errorObj);
              return EMPTY;
            }),
          );
        }),
      );
    },
    {
      dispatch: false,
    },
  );

  streamUnFiltered$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ConferenceActions.clearFilter),
        switchMap(action => this.withLocalStreamIds(action)),
        switchMap(([{ id: unfilteredId }, primaryId, secondaryId]) => {
          let localDevice = '';
          if (unfilteredId === primaryId) {
            localDevice = 'primary';
          } else if (unfilteredId === secondaryId) {
            localDevice = 'secondary';
          }
          if (localDevice) {
            this.deviceHistory.setSetting(
              'video',
              localDevice,
              PersistentDeviceSettings.filterMode,
              FilterVideoType.NONE,
            );
          }
          return this.conferenceService.clearFilter(unfilteredId).pipe(
            map(() => console.log('unfilteredId', unfilteredId)),
            catchError(error => {
              const errorObj = {
                unfilteredId,
                error,
              };
              console.warn('SET_STREAM_UNFILTERED', errorObj);
              return EMPTY;
            }),
          );
        }),
      );
    },
    {
      dispatch: false,
    },
  );

  private withLocalStreamIds(action) {
    return of(action).pipe(
      withLatestFrom(
        this.store.select(selectLocalPrimaryId),
        this.store.select(selectLocalSecondaryStreamId),
      ),
    );
  }

  private createLocalSecondary(
    participant: Participant,
    videoDeviceId: string,
    isPromoted = false,
  ) {
    const type = StreamType.secondary;
    const isRotatedSetting = this.deviceHistory.getSetting(
      'video',
      type,
      PersistentDeviceSettings.isRotated,
    );
    const isMirroredSetting = this.deviceHistory.getSetting(
      'video',
      type,
      PersistentDeviceSettings.isMirrored,
    );
    const filterModeSetting = this.deviceHistory.getSetting(
      'video',
      type,
      PersistentDeviceSettings.filterMode,
    );
    const isRotated =
      isRotatedSetting === null ? false : JSON.parse(isRotatedSetting);
    const isMirrored =
      isMirroredSetting === null ? true : JSON.parse(isMirroredSetting);
    const filterMode =
      filterModeSetting === null
        ? FilterVideoType.NONE
        : FilterVideoType[filterModeSetting];
    const newStream: LocalStream = {
      type,
      isPromoted,
      isLocal: true,
      id: `${participant.id}-${type}`,
      participantId: participant.id,
      displayName: `${participant.displayName} - 2`,
      video: {
        isHidden: false,
        deviceId: videoDeviceId,
        effects: {
          isMirrored,
          isRotated,
          filterMode,
          isCovered: false,
        },
      },
    };
    return newStream;
  }

  private createLocalPrimary(participant: Participant, isPromoted = false) {
    const type = StreamType.primary;
    const isRotatedSetting = this.deviceHistory.getSetting(
      'video',
      type,
      PersistentDeviceSettings.isRotated,
    );
    const isMirroredSetting = this.deviceHistory.getSetting(
      'video',
      type,
      PersistentDeviceSettings.isMirrored,
    );
    const filterModeSetting = this.deviceHistory.getSetting(
      'video',
      type,
      PersistentDeviceSettings.filterMode,
    );
    const isRotated =
      isRotatedSetting === null ? false : JSON.parse(isRotatedSetting);
    const isMirrored =
      isMirroredSetting === null ? true : JSON.parse(isMirroredSetting);
    const filterMode =
      filterModeSetting === null
        ? FilterVideoType.NONE
        : FilterVideoType[filterModeSetting];
    const newStream: LocalStream = {
      type,
      isPromoted,
      isLocal: true,
      id: `${participant.id}-${type}`,
      participantId: participant.id,
      displayName: participant.displayName,
      video: {
        isHidden: true,
        deviceId: this.deviceHistory.getVideoDeviceId(type),
        effects: {
          isMirrored,
          isRotated,
          filterMode,
          isCovered: false,
        },
      },
      microphone: {
        isMuted: participant.joinMuted,
        deviceId: this.deviceHistory.getAudioDeviceId(type),
      },
    };
    return newStream;
  }

  private onLocalStreamJoined() {
    return this.conferenceService.onJoined().pipe(
      concatMap(ev => {
        return of(ev).pipe(
          withLatestFrom(this.store.select(selectLocalStream(ev.id))),
        );
      }),
      filter(([_, stream]) => !!stream),
      map(([ev]) => ev),
    );
  }

  private onLocalStreamChangedFromRT() {
    return this.store.pipe(
      filterSessionReady,
      switchMap(() => {
        return this.conferenceRTService.onStreamChanged().pipe(
          concatMap(changes => {
            return of(changes).pipe(
              withLatestFrom(this.store.select(selectLocalStream(changes.id))),
            );
          }),
          filter(([_, stream]) => !!stream),
          map(
            ([changes]) =>
              [changes.id, changes] as PluckDefinedResult<typeof changes>,
          ),
        );
      }),
    );
  }

  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private conferenceRTService: ConferenceRTService,
    private conferenceService: RoomConferenceService,
    private deviceHistory: DevicesHistoryService,
  ) {}
}
