import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { PLButtonModule, PLIconModule, PLInputModule } from '@root/index';

import { PLQueueAddComponent } from './pl-queue-add.component';
import {
  PLQuickYoutubeComponent,
  PLQuickYoutubeDialogComponent,
} from './pl-quick-youtube/pl-quick-youtube.component';
import { PLYouTubeService } from './pl-quick-youtube/pl-you-tube.service';
import { MatSelectModule } from '@angular/material/select';
import { ActivityTypeLabelPipe } from '../pl-activity-type-label.pipe';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PLActivitiesListComponent } from '../pl-activities-list/pl-activities-list.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PLButtonModule,
    PLIconModule,
    PLInputModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDialogModule,
    MatInputModule,
    MatToolbarModule,
    MatTooltipModule,
    MatIconModule,
    MatProgressBarModule,
    DragDropModule,
  ],
  exports: [
    PLQueueAddComponent,
    PLQuickYoutubeComponent,
    ActivityTypeLabelPipe,
  ],
  declarations: [
    PLQueueAddComponent,
    PLQuickYoutubeComponent,
    PLQuickYoutubeDialogComponent,
    PLActivitiesListComponent,
    ActivityTypeLabelPipe,
  ],
  providers: [PLYouTubeService],
})
export class PLQueueAddModule {}

export { PLQueueAddComponent } from './pl-queue-add.component';
