<div class="stickers">
  <div class="stickers__content">
    <mat-form-field color="accent" class="stickers__select w-100">
      <mat-label>Select sticker set</mat-label>
      <mat-select
        data-testid="stickers-set-select"
        [disabled]="!canControlStickers"
        [value]="selectedStickerPackName"
        (selectionChange)="selectedStickerPackNameChange.emit($event.value)"
      >
        <mat-option
          *ngFor="let option of stickerOptions"
          [value]="option.value"
        >
          {{ option.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <ng-container *ngIf="selectedStickerPackName">
      <p class="stickers__text">
        Click on individual stickers to add to tray, or select Add all stickers.
      </p>

      <button
        mat-stroked-button
        color="primary"
        class="stickers__add-all-button"
        data-testid="add-all-stickers-button"
        (click)="addAllStickers.emit()"
        [disabled]="!canControlStickers || !areStickersVisible"
      >
        Add all stickers
      </button>

      <div class="stickers__list">
        <button
          mat-button
          class="sticker-button"
          data-testid="add-sticker-button"
          *ngFor="let sticker of stickerPackOptions"
          (click)="addSticker.emit(sticker)"
          [disabled]="!canControlStickers || !areStickersVisible"
        >
          <div class="sticker-button__img">
            <img [src]="sticker.imageSrc" alt="" />
          </div>
          {{ sticker.displayName }}
        </button>
      </div>
    </ng-container>
  </div>
  <div class="stickers__footer">
    Stickers visible
    <mat-slide-toggle
      [checked]="areStickersVisible"
      (change)="areStickersVisibleChange.emit($event.checked)"
      data-testid="stickers-visibility-toggle"
      [disabled]="!canControlStickers"
    >
      {{ areStickersVisible ? 'On' : 'Off' }}
    </mat-slide-toggle>
  </div>
</div>
