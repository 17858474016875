import { Injectable } from '@angular/core';
import { PLHttpService, PLUrlsService } from '@common/services/pl-http';
import * as moment from 'moment';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ClientGoal,
  ClientObjective,
  SessionNote,
  StudentItem,
  KangaEvent,
} from './kanga-documentation.model';

@Injectable()
export class KangaDocumentationService {
  constructor(private plHttp: PLHttpService, private plUrls: PLUrlsService) {}

  getEvents(): Observable<any> {
    const url = `${this.plUrls.urls.apiTherapyEssentialsFE}/api/v1/events/`;
    const now = moment();
    const params = {
      start: now.startOf('day').format('YYYY-MM-DDTHH:mm:ssZ'),
      end: now.endOf('day').format('YYYY-MM-DDTHH:mm:ssZ'),
      start_date_local__date: now.format('YYYY-MM-DD'),
      ordering: 'start_date_local',
    };
    return this.plHttp.get('', params, url);
  }

  getClients(): Observable<any> {
    const url = `${this.plUrls.urls.apiTherapyEssentialsFE}/api/v1/clients/`;
    const params = {
      ordering: 'last_name,first_name',
    };
    return this.plHttp.get('', params, url);
  }

  saveNote(noteData: SessionNote): Observable<SessionNote> {
    let url = `${this.plUrls.urls.apiTherapyEssentialsFE}/api/v1/sessions/`;
    if (noteData?.uuid) {
      url += `${noteData.uuid}/`;
    }
    return this.plHttp.save('', noteData || {}, url);
  }

  getStudentSession(student: StudentItem): Observable<any> {
    const urlNote = `${this.plUrls.urls.apiTherapyEssentialsFE}/api/v1/sessions/`;
    const data = {
      client__uuid: student.client.uuid,
      event__uuid: student.event.uuid,
    };
    return this.plHttp.get('', data, urlNote).pipe(
      map((notes: any): SessionNote => {
        return notes.length ? notes[0] : null;
      }),
    );
  }

  getRunningSession(): Observable<any> {
    const urlNote = `${this.plUrls.urls.apiTherapyEssentialsFE}/api/v1/sessions/`;
    const data = {
      session_status__in: 'started',
    };
    return this.plHttp.get('', data, urlNote).pipe(
      map((notes: any): SessionNote => {
        return notes.length ? notes[0] : null;
      }),
    );
  }

  saveRecurrentEvent(eventData: KangaEvent) {
    let url = `${this.plUrls.urls.apiTherapyEssentialsFE}/api/v1/events/`;
    let method = 'POST';
    if (eventData.recurrence_set && eventData.id) {
      url += `${eventData.id}/`;
      method = 'PATCH';
    }
    return this.plHttp.go({
      method: method,
      url: url,
      body: eventData,
    });
  }

  saveEvent(eventData: KangaEvent) {
    if (eventData.recurrence_set) return this.saveRecurrentEvent(eventData);
    let url = `${this.plUrls.urls.apiTherapyEssentialsFE}/api/v1/events/`;
    if (eventData.uuid) {
      url += `${eventData.uuid}/`;
    }
    return this.plHttp.save('', eventData, url);
  }

  getClientGoals(
    clientId: string,
    sessionId: string,
  ): Observable<ClientGoal[]> {
    const goalsUrl = `${this.plUrls.urls.apiTherapyEssentialsFE}/api/v1/clients/${clientId}/goals/`;
    const goalsReq = this.plHttp.get('', {}, goalsUrl);
    const objectivesUrl = sessionId
      ? `${this.plUrls.urls.apiTherapyEssentialsFE}/api/v1/sessions/${sessionId}/objectives/`
      : `${this.plUrls.urls.apiTherapyEssentialsFE}/api/v1/clients/${clientId}/objectives/`;
    const objectivesReq = this.plHttp.get('', {}, objectivesUrl);
    return forkJoin([objectivesReq, goalsReq]).pipe(
      map((results: any[]) => {
        const objectives = results[0];
        const goals = results[1];
        const objDict = objectives.reduce((res, value) => {
          res[value.objective] = value;
          return res;
        }, {});
        goals.forEach(goal => {
          goal.objectives = goal.objectives.map(obj => {
            return {
              ...obj,
              values: objDict[obj.uuid] || {
                objective: obj.uuid,
                correct: 0,
                incorrect: 0,
              },
            };
          });
        });
        return goals;
      }),
    );
  }

  getSessionsMetrics(sessionId: string) {
    const url = `${this.plUrls.urls.apiTherapyEssentialsFE}/api/v1/sessions/${sessionId}/objectives/`;
    return this.plHttp.get('', {}, url);
  }

  updateMetric(objective: ClientObjective, sessionId: string) {
    let url = `${this.plUrls.urls.apiTherapyEssentialsFE}/api/v1/sessions/${sessionId}/objectives/`;
    if (objective.values.uuid) {
      url += `${objective.values.uuid}/`;
    }
    return this.plHttp.save('', objective.values, url);
  }
}
