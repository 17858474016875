import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { AppState } from '@app/store';
import { DrawerActions } from '@modules/room/pl-drawers/store';
import { PLWidgetsBoardModelService } from '@modules/room/pl-widgets/pl-widgets-board/pl-widgets-board-model.service';
import { FirebaseAppModel } from './firebase/firebase-app-model.service';
import { CurrentUserModel } from './CurrentUserModel';
import { PLAssessmentModelService } from '@room/pl-activity/model/assessment-model.service';
import { Subject } from 'rxjs';
import { ScreenshareActions } from '../../modules/room/conference/screenshare/store';
import { selectPromotedStreamsCount } from '@modules/room/conference/store';

import {
  AppActions,
  CentralContent,
  CentralContentType,
  selectCurrentCentralContent,
  selectIsCentralContentAssessment,
  selectIsLayoutModeFullScreen,
} from '../../modules/room/app/store';

@Injectable({ providedIn: 'root' })
export class AppModel {
  private showExitSource = new Subject();
  showExit$ = this.showExitSource.asObservable();
  drawerIsHiddenValue: boolean;
  app: {
    activeDrawer: string;
    updatingScreenshareVideo: boolean;
    backgroundBlurOn: boolean;
  };
  timeouts = {
    chatOpen: null,
  };
  promotedStreamsCount = 0;
  isFullScreen = false;
  assessmentActive = false;
  currentCentralContent: CentralContent;

  constructor(
    private $firebaseAppModel: FirebaseAppModel,
    private currentUserModel: CurrentUserModel,
    private widgetsBoardModel: PLWidgetsBoardModelService,
    private assessmentModel: PLAssessmentModelService,
    private ngrxStoreService: Store<AppState>,
  ) {
    // default app states
    this.app = {
      activeDrawer: 'chat',
      updatingScreenshareVideo: false,
      backgroundBlurOn: false,
    };

    this.app.activeDrawer = 'chat';
    this.drawerIsHiddenValue = true;

    this.ngrxStoreService
      .select(selectPromotedStreamsCount)
      .subscribe(count => {
        this.promotedStreamsCount = count;
      });
    this.ngrxStoreService
      .select(selectIsLayoutModeFullScreen)
      .subscribe(isFullScreen => {
        this.isFullScreen = isFullScreen;
      });
    this.ngrxStoreService
      .select(selectIsCentralContentAssessment)
      .subscribe(isAssessment => (this.assessmentActive = isAssessment));
    this.ngrxStoreService
      .select(selectCurrentCentralContent)
      .subscribe(content => {
        this.currentCentralContent = content;
      });
  }

  get showAssessmentInstructionsJumbotronOverlay() {
    return (
      this.assessmentActive &&
      this.isFullScreen &&
      this.promotedStreamsCount < 2 &&
      this.assessmentModel.getShowInstructions()
    );
  }

  get drawerIsHidden() {
    return this.drawerIsHiddenValue;
  }

  set drawerIsHidden(value) {
    this.drawerIsHiddenValue = value;
    $(window).resize();
  }

  openDrawer(drawerName: string) {
    if (this.timeouts.chatOpen) {
      clearTimeout(this.timeouts.chatOpen);
    }

    if (drawerName === 'chat') {
      this.resetChatTimeout();
    }

    if (this.app.activeDrawer === drawerName) {
      this.drawerIsHidden = !this.drawerIsHidden;
    } else {
      this.drawerIsHidden = false;
      this.app.activeDrawer = drawerName;
    }
    // 160 is a hardcoded match to just above the 150 animation time.
    this.widgetsBoardModel.recalcBoardLoop(null, null, null, null, 160, 10);

    sessionStorage.setItem('activeDrawer', this.app.activeDrawer);
  }

  resetChatTimeout() {
    if (this.timeouts.chatOpen) {
      clearTimeout(this.timeouts.chatOpen);
    }
    if (!this.currentUserModel.user.isInGroup('observer')) {
      this.timeouts.chatOpen = setTimeout(() => {
        if (this.app.activeDrawer === 'chat') {
          this.ngrxStoreService.dispatch(
            DrawerActions.close({ drawerName: this.app.activeDrawer }),
          );
          this.closeDrawer();
        }
      }, 3 * 60 * 1000);
    }
  }

  closeDrawer() {
    this.drawerIsHidden = true;
    // 160 is a hardcoded match to just above the 150 animation time.
    this.widgetsBoardModel.recalcBoardLoop(null, null, null, null, 160, 10);
  }

  toggleGames(value) {
    if (value !== this.$firebaseAppModel.app.gamesActive) {
      this.$firebaseAppModel.setGamesActive(value);

      if (this.$firebaseAppModel.app.gamesActive) {
        this.$firebaseAppModel.setWhiteboardActive(false);
      }
    }
  }

  toggleSessionRecordDrawer(value) {
    if (value !== this.$firebaseAppModel.app.sessionRecordDrawerActive) {
      this.$firebaseAppModel.setSessionRecordDrawerActive(value);
    }
  }

  toggleViewSessionRecording(value) {
    if (value !== this.$firebaseAppModel.app.sessionRecordingViewerActive) {
      this.$firebaseAppModel.setSessionRecordingViewerActive(value);
      if (value) {
        this.toggleScreenshare(false);
        this.$firebaseAppModel.showWorkspace(false);
      }
    }
  }

  toggleScreenshare(value) {
    const action = value
      ? ScreenshareActions.start()
      : ScreenshareActions.stop();
    this.ngrxStoreService.dispatch(action);
  }

  toggleWhiteboard() {
    this.$firebaseAppModel.setWhiteboardActive(
      !this.$firebaseAppModel.app.whiteboardActive,
    );

    if (
      this.currentCentralContent.contentType === CentralContentType.Teamwrite &&
      this.$firebaseAppModel.app.whiteboardActive
    ) {
      this.ngrxStoreService.dispatch(
        AppActions.setCentralContent({
          contentType: CentralContentType.Empty,
          contentDetail: null,
        }),
      );
    }
  }

  showExitFromRoom() {
    this.showExitSource.next();
  }
}
