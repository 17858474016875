import { Injectable } from '@angular/core';
import { allClueCards, Card } from './clue-store';
import { Store } from '@ngrx/store';
import * as ClueActions from './clue-store/actions';

@Injectable({
  providedIn: 'root',
})
export class ClueService {
  private cards;
  private dealingCards: Card[] = [];

  constructor(private store: Store<any>) {}

  public updateCurrentUser(playersArr, currentUser): void {
    let currentUserCheck = [];

    if (playersArr) {
      currentUserCheck = playersArr.filter(player => {
        return player.userId === currentUser.userId;
      });
    }

    if (currentUserCheck.length > 0) {
      const updatedUser = { ...currentUserCheck[0] };
      if (currentUser.cardList) {
        updatedUser.cardList = currentUser.cardList;
      }
      this.store.dispatch(
        ClueActions.updateCurrentUser({ currentUser: updatedUser }),
      );
    }
  }

  public refreshCardDeck() {
    this.cards = {
      suspects: [...allClueCards.suspects],
      methods: [...allClueCards.methods],
      locations: [...allClueCards.locations],
    };

    this.dealingCards = [];
  }

  public makeWinningHand() {
    const randomSuspect = this.cards.suspects.splice(
      Math.floor(Math.random() * this.cards.suspects.length),
      1,
    )[0];
    const randomMethod = this.cards.methods.splice(
      Math.floor(Math.random() * this.cards.methods.length),
      1,
    )[0];
    const randomLocation = this.cards.locations.splice(
      Math.floor(Math.random() * this.cards.locations.length),
      1,
    )[0];

    this.dealingCards = [
      ...this.cards.suspects,
      ...this.cards.methods,
      ...this.cards.locations,
    ];

    return {
      suspect: randomSuspect,
      location: randomLocation,
      method: randomMethod,
    };
  }

  public randomCardsArray(len = 2) {
    let newHand = [];
    let numCards = len === 4 ? 3 : 4;

    for (let i = 0; i < numCards; i++) {
      let cardFound = this.dealingCards.splice(
        Math.floor(Math.random() * this.dealingCards.length),
        1,
      )[0];
      newHand.push(cardFound);
      cardFound = null;
    }

    return newHand;
  }

  public populatePlayerHands(playersArr, cards) {
    const numPlayers = playersArr.length;
    return playersArr.map(player => {
      player.confidentialCards = this.randomCardsArray(numPlayers);
      player.cardList = { ...cards };

      return player;
    });
  }

  public updateInvestigationObj(playersArr, currentPlayerTurn: number): any {
    let witnessTurn = currentPlayerTurn + 1;
    if (witnessTurn > playersArr.length) {
      witnessTurn = 1;
    }

    const investigator = playersArr.filter(player => {
      return player.position === currentPlayerTurn;
    })[0];

    if (investigator.disabled) {
      const nextTurn = currentPlayerTurn + 1;
      return this.updateInvestigationObj(playersArr, nextTurn);
    }

    const witness = playersArr.filter(player => {
      return player.position === witnessTurn;
    })[0];

    const newInvestigationObj = {
      investigatorPosition: currentPlayerTurn,
      witnessPosition: null,
      isAsking: false,
      investigator: investigator,
      witness: witness,
      responseReceived: '',
    };

    return {
      investigationObj: newInvestigationObj,
      playerTurn: currentPlayerTurn,
    };
  }
}
