<div class="dialog">
  <header class="mat-dialog-header">
    <h2 mat-dialog-title>Instant Video</h2>
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </header>

  <mat-dialog-content>
    <mat-label>
      Search for a video on
      <a href="https://www.youtube.com/" target="”_blank”">YouTube</a>
      and paste the URL below.
    </mat-label>

    <div class="input">
      <mat-form-field color="accent">
        <mat-label>Paste YouTube URL here</mat-label>
        <input matInput [(ngModel)]="data.youTubeUrl" />
        <button
          *ngIf="data.youTubeUrl"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="clearInput()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <button mat-raised-button (click)="selectVideo()">Preview Video</button>
    </div>

    <div class="youtube-preview">
      <div *ngIf="emptyResults" class="error-message">Video Not Found</div>
      <div *ngIf="searchError" class="error-message">Error Finding Video</div>
      <div class="preview">
        <div class="details-container">
          <div #videoPlayer class="video">
            <div class="player"></div>
          </div>
          <div class="text" *ngIf="data.youTubeData">
            <h6>
              {{ data.youTubeData.duration._data.minutes }}:{{
                data.youTubeData.duration._data.seconds
              }}
              |
              {{
                data.youTubeData.snippet.publishedAt | date : format : longDate
              }}
            </h6>
            <h3>{{ data.youTubeData.snippet.title }}</h3>
            <p>
              {{ data.youTubeData.snippet.description | slice : 0 : 300
              }}{{
                data.youTubeData.snippet.description.length > 300 ? '...' : ''
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-stroked-button mat-dialog-close color="primary">Cancel</button>
    <button
      mat-raised-button
      color="primary"
      [mat-dialog-close]="data"
      [disabled]="!videoFound"
    >
      Play in room
    </button>
  </mat-dialog-actions>
</div>
