import { createAction, props } from '@ngrx/store';
import { SessionNote } from '../../pl-drawers/pl-documentation-drawer/documentation-te/documentation-te.model';

const initialize = createAction(
  '/licensing/initialize',
  props<{ isFreemium: boolean; isTEProvider: boolean; minutesLeft: number }>(),
);
const initializeError = createAction('/licensing/initializeError');
const startSession = createAction(
  '/licensing/startSession',
  props<{ sessionData: SessionNote }>(),
);
const sessionStarted = createAction('/licensing/sessionStarted');
const decreaseMinute = createAction('/licensing/decreaseMinute');
const stopSession = createAction('/licensing/stopSession');
const sessionStopped = createAction('/licensing/sessionStopped');
const heartbeat = createAction('/licensing/heartbeat');
const heartbeatCompleted = createAction(
  '/licensing/heartbeatCompleted',
  props<{ minutesLeft: number }>(),
);
const setUserLicensing = createAction(
  '/licensing/setUserLicensing',
  props<{ isTEProvider: boolean; isFreemium: boolean }>(),
);
const setRemaningMinutes = createAction(
  '/licensing/setRemaningMinutes',
  props<{ minutesLeft: number }>(),
);

export const LicensingActions = {
  initialize,
  initializeError,
  startSession,
  sessionStarted,
  decreaseMinute,
  stopSession,
  sessionStopped,
  heartbeat,
  heartbeatCompleted,
  setUserLicensing,
  setRemaningMinutes,
};
