<video
  [muted]="isMuted$ | async"
  autoplay
  playsinline
  [srcObject]="src"
  [class.mirror]="isMirrored"
  [class.rotate]="isRotated"
  [class.keep-aspect-ratio]="shouldKeepAspectRatio"
  (play)="onPlay($event)"
></video>
