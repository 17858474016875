import { Pipe, PipeTransform } from '@angular/core';

const labelByActivityType: { [key: string]: string } = {
  pdf: 'Lesson',
  pdfviewer: 'Document',
  youtube: 'Video',
  flashcards: 'Flashcard Deck',
  memory: 'Memory Game',
  doubleassessment: 'Assessment',
  wjaudioassessment: 'Audio Assessment',
  'game-go-fish': 'Go Fish Game',
  'game-gametable-checkers': 'Logic Game',
  'game-gametable-dots-and-boxes': 'Logic Game',
  'game-gametable-blokd': 'Logic Game',
  'game-gametable-trio': 'Logic Game',
  'game-gametable-teamword': 'Logic Game',
  'game-potato-head': 'Sticker Game',
  'game-scenes': 'Sticker Game',
  'game-clue': 'Game',
  'game-candyland': 'Game',
  'whos-that': 'Game',
};

@Pipe({
  name: 'activityTypeLabel',
})
export class ActivityTypeLabelPipe implements PipeTransform {
  transform(value: string) {
    return labelByActivityType[value] || value;
  }
}
