import { Component, OnDestroy, OnInit, NgZone, Input } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as ClueActions from '../clue-store/actions';
import * as ClueSelectors from '../clue-store/selectors';
import { Player, allClueCards, Cards, EndGameObj } from '../clue-store';
import { PLVendorGameService } from '../../vendor-games';
import { takeUntil } from 'rxjs/operators';
import { ClueService } from '../pl-clue.service';
import { FirebaseService } from '@root/src/app/common/firebase/firebase.service';
import { PlayerCountOption } from '@root/src/app/common/components/player-count-select/player-count-select.component';

@Component({
  selector: 'pl-clue-drawer',
  templateUrl: './pl-clue-drawer.component.html',
  styleUrls: ['./pl-clue-drawer.component.less'],
})
export class PLClueDrawerComponent implements OnInit, OnDestroy {
  @Input() activity: any = {};
  private subscriptions: Subscription[] = [];
  public playersArr: Player[] = [];
  public numPlayers: number = 2;
  public enableStartGameBtn: boolean = false;
  public enableEndGameBtn: boolean = false;
  public isGameActive: boolean = false;
  public showClueInstruction: boolean = false;
  public showClueOverview: boolean = false;
  public firebaseClueRef;
  public cards: Cards;
  public currentPlayerTurn;
  public provider;
  public endGameObj: EndGameObj = {
    winningHand: {
      suspect: { name: '', icon: '', status: '' },
      method: { name: '', icon: '', status: '' },
      location: { name: '', icon: '', status: '' },
    },
    showWinningCards: false,
    winnersList: [],
    playersSkipped: [],
    defaultWin: false,
  };
  public fbInitialized: boolean = false;

  playerCountOptions: PlayerCountOption[] = [
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
  ];

  destroyed$ = new Subject<boolean>();

  constructor(
    public firebaseService: FirebaseService,
    private store: Store<any>,
    private vendorGameService: PLVendorGameService,
    private zone: NgZone,
    private clueService: ClueService,
  ) {}

  ngOnInit(): void {
    const fbPath = `activities/queues/items/${this.activity.queueId}/items/${this.activity.activityId}`;
    this.firebaseClueRef = this.firebaseService.getRoomRef(fbPath);

    this.initFirebase();

    this.subscriptions.push(this.subscribeToRoomUserAndPersonas());
    this.subscriptions.push(this.subscribeToStartGameBtnStatus());
    this.subscriptions.push(this.subscribeToEndGameBtnStatus());
    this.subscriptions.push(this.subscribeToShowInstructionStatus());
    this.subscriptions.push(this.subscribeToGetCards());
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.firebaseClueRef.update({
      showInstruction: false,
      showOverview: false,
    });
    this.store.dispatch(ClueActions.closeShowOverview());
    this.store.dispatch(ClueActions.closeShowInstruction());
  }

  public onChangeNumPlayers(val: number) {
    this.numPlayers = val;
    this.canStartNewGame();
  }

  getPlayerAtPosition(pos) {
    const player = this.playersArr.find(p => p.position === pos);
    return player ? player.name : null;
  }

  public onChangePlayerCheck(e, positionInput) {
    const updatedPlayersArr = [...this.playersArr].map(player => {
      const temp = { ...player };
      if (temp.position === positionInput) {
        temp.position = null;
      }
      if (temp.name === e.value) {
        temp.position = positionInput;
        return temp;
      }
      return temp;
    });

    this.firebaseClueRef.update({ playersArr: updatedPlayersArr });
    this.canStartNewGame();
  }

  public onClickStartNewGame(): void {
    this.clueService.refreshCardDeck();

    const disabledPlayers = this.playersArr.filter(player => !player.position);

    const sortedPlayersPosition = this.playersArr
      .filter(player => player.position)
      .sort((first, second) => (first.position > second.position ? 1 : -1))
      .map(player => {
        player.disabled = false;
        player.solvingTheCaseObj = {
          isSolving: false,
          skipSolving: false,
          answersSubmitted: false,
          answersObj: {
            suspect: { name: '', icon: '', status: '' },
            method: { name: '', icon: '', status: '' },
            location: { name: '', icon: '', status: '' },
          },
        };
        return player;
      });

    let updatedEndGameObj = { ...this.endGameObj };
    updatedEndGameObj.winningHand = this.clueService.makeWinningHand();
    const populateHands = this.clueService.populatePlayerHands(
      sortedPlayersPosition,
      this.cards,
    );

    this.firebaseClueRef.update({
      endGameObj: updatedEndGameObj,
      playersArr: populateHands,
      disabledPlayersArr: disabledPlayers,
    });

    if (populateHands.length === 2) {
      const ghostHand = this.clueService.randomCardsArray();
      const newGhostObj = {
        hasGhostPlayer: true,
        ghostCardsArr: ghostHand,
      };
      this.firebaseClueRef.update({
        ghostPlayerObj: newGhostObj,
      });
    }

    const updatedInvestigationObj = this.clueService.updateInvestigationObj(
      sortedPlayersPosition,
      1,
    );

    this.firebaseClueRef.update({
      investigationObj: updatedInvestigationObj.investigationObj,
      playerTurn: updatedInvestigationObj.playerTurn,
    });

    this.updateStartGameBtn(false);
    this.updateEndGameBtn(true);
    this.updateStartGameFolder(true);
  }

  public onClickEndGame(): void {
    this.startNewFirebase(true);
    this.updateEndGameBtn(false);
    this.updateIsGameActive(false);
    const updatedPlayersArr = this.playersArr.map(player => {
      player.position = null;
      player.cardList = {};
      return player;
    });
    this.firebaseClueRef.update({ playersArr: updatedPlayersArr });
    this.store.dispatch(
      ClueActions.updatePlayers({ playersArr: updatedPlayersArr }),
    );
  }

  public toggleOverview(): void {
    this.firebaseClueRef.update({ showOverview: !this.showClueOverview });
    this.store.dispatch(ClueActions.toggleShowOverview());
  }

  public toggleInstruction(): void {
    this.firebaseClueRef.update({ showInstruction: !this.showClueInstruction });
    this.store.dispatch(ClueActions.toggleShowInstruction());
  }

  // ===================================================================================================================
  // ======================================== PRIVATE METHODS BELOW ====================================================
  // ===================================================================================================================

  private initFirebase() {
    this.firebaseClueRef.on('value', data => {
      const val = data.val();

      if (val === null) {
        return;
      }

      this.zone.run(() => {
        const {
          playersArr,
          gameActive,
          enableStartGameBtn,
          enableEndGameBtn,
          showInstruction,
          showOverview,
          playerTurn,
          provider,
        } = val;

        if (!this.fbInitialized && !gameActive) {
          this.fbInitialized = true;
          this.startNewFirebase();
          return;
        }

        this.playersArr = playersArr || [];
        this.provider = provider || {};
        this.isGameActive = !!gameActive;
        this.enableStartGameBtn = !!enableStartGameBtn;
        this.enableEndGameBtn = !!enableEndGameBtn;
        this.showClueInstruction = !!showInstruction;
        this.showClueOverview = !!showOverview;
        this.currentPlayerTurn = playerTurn || 1;

        if (enableEndGameBtn) {
          this.numPlayers = this.playersArr.length;
        }

        this.updateEndGameBtn(this.enableEndGameBtn);
        this.updateStartGameBtn(this.enableStartGameBtn);
        this.updateIsGameActive(this.isGameActive);
      });
    });
  }

  private startNewFirebase(keepPlayers = false) {
    this.firebaseClueRef.update({
      gameActive: false,
      playerTurn: 1,
      enableEndGameBtn: false,
      enableStartGameBtn: false,
      showInstruction: false,
      showExample: false,
      solveCaseActive: false,
      cards: allClueCards,
      folderScreenActive: false,
      conclusionScreenActive: false,
      endGameObj: this.endGameObj,
      investigationObj: {},
      ghostPlayerObj: {
        hasGhostPlayer: false,
        ghostCardsArr: [],
      },
      clearCardRevealObj: false,
    });

    if (!keepPlayers) {
      this.firebaseClueRef.update({
        playersArr: [],
        provider: '',
      });
    }
  }

  private subscribeToGetCards(): Subscription {
    return this.store.pipe(select(ClueSelectors.clueCards)).subscribe(cards => {
      this.cards = cards;
    });
  }

  private subscribeToRoomUserAndPersonas(): Subscription {
    return this.vendorGameService
      .getParticipantsAndLocalId()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(data => {
        if (this.isGameActive) {
          return;
        }

        const updatedPlayersArr = data[0].map(user => {
          if (this.playersArr) {
            const currentState = this.playersArr.find(
              p => p.userId === user.userId,
            );
            if (currentState) {
              return currentState;
            }
          }
          const newState: Player = {
            id: user.userId,
            userId: user.userId,
            position: null,
            name: user.displayName,
            confidentialCards: [
              { name: '', icon: '', status: '' },
              { name: '', icon: '', status: '' },
              { name: '', icon: '', status: '' },
            ],
            cardList: {},
            disabled: false,
            solvingTheCaseObj: {
              isSolving: false,
              skipSolving: false,
              answersSubmitted: false,
              answersObj: {
                suspect: { name: '', icon: '', status: '' },
                method: { name: '', icon: '', status: '' },
                location: { name: '', icon: '', status: '' },
              },
            },
          };
          return newState;
        });

        const providerString: any = data[0].map(user => {
          if (user.type === 'host') {
            return user;
          }
        })[0].userId;

        this.firebaseClueRef.update({
          playersArr: updatedPlayersArr,
          provider: providerString,
        });
        this.store.dispatch(
          ClueActions.updatePlayers({ playersArr: updatedPlayersArr }),
        );
        this.playersArr = updatedPlayersArr;
        this.canStartNewGame();
      });
  }

  private subscribeToShowInstructionStatus(): Subscription {
    return this.store
      .pipe(select(ClueSelectors.clueInstructionActive))
      .subscribe(data => {
        this.showClueInstruction = data;
      });
  }

  private subscribeToStartGameBtnStatus(): Subscription {
    return this.store
      .pipe(select(ClueSelectors.clueStartGameBtnStatus))
      .subscribe(data => {
        this.enableStartGameBtn = data;
      });
  }

  private subscribeToEndGameBtnStatus(): Subscription {
    return this.store
      .pipe(select(ClueSelectors.clueEndGameBtnStatus))
      .subscribe(data => {
        this.enableEndGameBtn = data;
      });
  }

  private canStartNewGame(): void {
    const participatingPlayers = this.playersArr.filter(player => {
      return !!player.position;
    });

    const hasProvider = participatingPlayers.some(
      player => player.userId === this.provider,
    );

    if (!hasProvider || participatingPlayers.length < this.numPlayers) {
      this.updateStartGameBtn(false);
    } else if (!this.enableEndGameBtn) {
      this.updateStartGameBtn(true);
    }
  }

  private updateStartGameBtn(status?: boolean): void {
    this.firebaseClueRef.update({ enableStartGameBtn: status });
    this.store.dispatch(
      ClueActions.updateStartGameBtn({ enableStartGameBtn: status }),
    );
  }

  private updateEndGameBtn(status?: boolean): void {
    this.firebaseClueRef.update({ enableEndGameBtn: status });
    this.store.dispatch(
      ClueActions.updateEndGameBtn({ enableEndGameBtn: status }),
    );
  }

  private updateIsGameActive(status?: boolean): void {
    this.firebaseClueRef.update({ gameActive: status });
  }

  private updateStartGameFolder(status: boolean): void {
    this.firebaseClueRef.update({ folderScreenActive: status });
  }
}
