<div
  class="end-game-text-container"
  *ngIf="!endGameObj.winnersList || endGameObj.winnersList.length === 0"
>
  <h2>Oh no! No one had the correct answer!</h2>
  <div class="end-game-text">
    <p
      *ngIf="
        endGameObj.playersSkipped &&
        endGameObj.playersSkipped.length > 1 &&
        (!endGameObj.winnersList || endGameObj.winnersList.length === 0)
      "
    >
      The responses submitted did not match what is in the confidential folder!
      Players that submitted incorrect responses can no longer ask questions or
      try and solve the crime again.
    </p>
    <p
      *ngIf="
        !(
          endGameObj.playersSkipped &&
          endGameObj.playersSkipped.length > 1 &&
          (!endGameObj.winnersList || endGameObj.winnersList.length === 0)
        )
      "
    >
      The responses submitted did not match what is in the confidential folder!
      Game over
    </p>
    <button
      *ngIf="showContinueBtn"
      (click)="continueGameClick()"
      class="show-win-btn"
    >
      Continue game
    </button>
  </div>
</div>

<div class="end-game-text-container" *ngIf="winnersBySolutionBool">
  <h1>Congratulations, the following players solved the case!</h1>
  <p *ngFor="let player of endGameObj.winnersList">
    Player {{ player.position }} - {{ player.name }}
  </p>
</div>

<div class="end-game-text-container" *ngIf="winnerByDefaultBool">
  <h1>
    Player {{ endGameObj.playersSkipped[0].position }},
    {{ endGameObj.playersSkipped[0].name }} Won!
  </h1>
  <p>None of the guesses submitted were correct</p>
</div>

<div class="cards-container">
  <div class="winning-cards-show">
    <div class="card-container">
      <h2 class="card-header">Suspect</h2>
      <div *ngIf="!endGameObj.showWinningCards" class="card">
        <img
          class="w-100 card-img"
          src="/assets/board-games/clue_game_assets/clue_card_back.png"
        />
      </div>
      <div *ngIf="endGameObj.showWinningCards" class="card">
        <div
          class="check-container"
          *ngIf="
            !(
              currentUser.solvingTheCaseObj.skipSolving &&
              endGameObj.showWinningCards
            )
          "
        >
          <img
            *ngIf="
              endGameObj.winningHand.suspect.name ===
              currentUser.solvingTheCaseObj.answersObj.suspect.name
            "
            class="check-img"
            src="/assets/board-games/clue_game_assets/white_check_green_back_circle.svg"
          />
          <img
            *ngIf="
              endGameObj.winningHand.suspect.name !==
              currentUser.solvingTheCaseObj.answersObj.suspect.name
            "
            class="check-img"
            src="/assets/board-games/clue_game_assets/icon_red_x_cancel.svg"
          />
        </div>
        <img
          class="w-100 card-img"
          src="{{ endGameObj.winningHand.suspect.icon }}"
        />
        <h4>{{ endGameObj.winningHand.suspect.name }}</h4>
      </div>
    </div>

    <div class="card-container">
      <h2 class="card-header">Location</h2>
      <div *ngIf="!endGameObj.showWinningCards" class="card">
        <img
          class="w-100 card-img"
          src="/assets/board-games/clue_game_assets/clue_card_back.png"
        />
      </div>
      <div *ngIf="endGameObj.showWinningCards" class="card">
        <div
          class="check-container"
          *ngIf="
            !(
              currentUser.solvingTheCaseObj.skipSolving &&
              endGameObj.showWinningCards
            )
          "
        >
          <img
            *ngIf="
              endGameObj.winningHand.location.name ===
              currentUser.solvingTheCaseObj.answersObj.location.name
            "
            class="check-img"
            src="/assets/board-games/clue_game_assets/white_check_green_back_circle.svg"
          />
          <img
            *ngIf="
              endGameObj.winningHand.location.name !==
              currentUser.solvingTheCaseObj.answersObj.location.name
            "
            class="check-img"
            src="/assets/board-games/clue_game_assets/icon_red_x_cancel.svg"
          />
        </div>
        <img
          class="w-100 card-img"
          src="{{ endGameObj.winningHand.location.icon }}"
        />
        <h4>{{ endGameObj.winningHand.location.name }}</h4>
      </div>
    </div>

    <div class="card-container">
      <h2 class="card-header">Method</h2>
      <div *ngIf="!endGameObj.showWinningCards" class="card">
        <img
          class="w-100 card-img"
          src="/assets/board-games/clue_game_assets/clue_card_back.png"
        />
      </div>
      <div *ngIf="endGameObj.showWinningCards" class="card">
        <div
          class="check-container"
          *ngIf="
            !(
              currentUser.solvingTheCaseObj.skipSolving &&
              endGameObj.showWinningCards
            )
          "
        >
          <img
            *ngIf="
              endGameObj.winningHand.method.name ===
              currentUser.solvingTheCaseObj.answersObj.method.name
            "
            class="check-img"
            src="/assets/board-games/clue_game_assets/white_check_green_back_circle.svg"
          />
          <img
            *ngIf="
              endGameObj.winningHand.method.name !==
              currentUser.solvingTheCaseObj.answersObj.method.name
            "
            class="check-img"
            src="/assets/board-games/clue_game_assets/icon_red_x_cancel.svg"
          />
        </div>
        <img
          class="w-100 card-img"
          src="{{ endGameObj.winningHand.method.icon }}"
        />
        <h4>{{ endGameObj.winningHand.method.name }}</h4>
      </div>
    </div>
  </div>
  <div class="confidential-card-container">
    <h4 class="card-header">Confidential folder</h4>

    <div class="confidential-card">
      <img
        src="/assets/board-games/clue_game_assets/confidential_folder_question_mark.svg"
      />
    </div>
  </div>
</div>
