import {
  Component,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@root/src/app/store';
import { Subscription } from 'rxjs';
import { Activity } from '../../app/store';
import { selectIsLocalParticipantHost } from '../../session/store';
import { PLActivityModelService } from '../model/activity-model.service';

export enum PDFFBPaths {
  PageNum = 'pageNum',
  BookMode = 'bookMode',
  Scale = 'scale',
  PageRotation = 'pageRotation',
  ScrollXPercent = 'scrollXPercent',
  ScrollYPercent = 'scrollYPercent',
}

@Component({
  selector: 'pl-pdf-activity',
  templateUrl: 'pdf-activity.component.html',
})
export class PDFActivityComponent implements OnChanges, OnDestroy {
  @Input() public activity: Activity;
  pdfUrl = '';
  currentPage = 1;
  scale = 1;
  bookMode = 1;
  scrollXPercent = 0;
  scrollYPercent = 0;
  pageRotations: Record<string, number> = {};
  private DBL_PAGE_EVEN_MODE = 2;
  private DBL_PAGE_ODD_MODE = 3;
  private DEFAULT_PAGE_NUM = 1;
  private DEFAULT_ROTATION_DEG = 0;
  private DEFAULT_MODE = 1;

  private DEFAULT_SCALE = 1.0;
  private DEFAULT_SCROLL_X = 0;
  private DEFAULT_SCROLL_Y = 0;
  private isHost = false;
  private subscriptions: Subscription[] = [];
  constructor(
    private activityModel: PLActivityModelService,
    private zone: NgZone,
    private store: Store<AppState>,
  ) {
    this.subscriptions.push(
      this.store.select(selectIsLocalParticipantHost).subscribe(isHost => {
        this.isHost = isHost;
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    const activityChange = changes.activity;
    if (
      activityChange &&
      activityChange.currentValue !== activityChange.previousValue
    ) {
      this.pdfUrl = JSON.parse(
        this.activityModel.configModel.model.descriptor,
      ).url;
      this.activityModel.getSessionRef(this.sessionID).off('value');
      this.activityModel.getSessionRef(this.sessionID).on(
        'value',
        snap => {
          let newPage = this.getPageNumFromSnap(snap);
          const newScale = this.getScaleFromSnap(snap);
          const newMode = this.getModeFromSnap(snap);
          const pageRotations = this.getPageRotationsFromSnap(snap);

          if (this.bookMode !== newMode) {
            this.bookMode = newMode;
          }
          if (newMode === this.DBL_PAGE_ODD_MODE && newPage % 2 !== 1) {
            newPage = newPage - 1;
          } else if (newMode === this.DBL_PAGE_EVEN_MODE && newPage % 2 !== 0) {
            newPage = Math.max(newPage - 1, 1);
          }
          if (this.currentPage !== newPage) {
            this.currentPage = newPage;
          }

          if (this.scale !== newScale) {
            this.scale = newScale;
          }

          this.pageRotations = pageRotations;
        },
        err => this.handleActivityError(err),
      );

      this.activityModel
        .getSessionRef(this.sessionID)
        .child(PDFFBPaths.ScrollXPercent)
        .on(
          'value',
          snap => {
            const xPercent = this.getScrollXFromSnap(snap);

            this.zone.run(() => {
              this.scrollXPercent = xPercent;
            });
          },
          err => this.handleActivityError(err),
        );

      this.activityModel
        .getSessionRef(this.sessionID)
        .child(PDFFBPaths.ScrollYPercent)
        .on(
          'value',
          snap => {
            const yPercent = this.getScrollYFromSnap(snap);

            this.zone.run(() => {
              this.scrollYPercent = yPercent;
            });
          },
          err => this.handleActivityError(err),
        );
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  get sessionID() {
    return this.activity.session_id;
  }

  private getValueFromSnap(snap, key, defaultValue) {
    const val = snap.val();
    let newVal = val || defaultValue;

    if (val !== null && typeof val === 'object') {
      newVal = val[key];
    }

    return newVal || defaultValue;
  }

  pdfLoaded(/* pdf */) {}

  private getPageRotationsFromSnap(snap) {
    return this.getValueFromSnap(
      snap,
      PDFFBPaths.PageRotation,
      this.DEFAULT_ROTATION_DEG,
    );
  }

  private getPageNumFromSnap(snap) {
    return this.getValueFromSnap(
      snap,
      PDFFBPaths.PageNum,
      this.DEFAULT_PAGE_NUM,
    );
  }

  private getModeFromSnap(snap) {
    return this.getValueFromSnap(snap, PDFFBPaths.BookMode, this.DEFAULT_MODE);
  }

  private getScaleFromSnap(snap) {
    return this.getValueFromSnap(snap, PDFFBPaths.Scale, this.DEFAULT_SCALE);
  }

  private getScrollXFromSnap(snap) {
    this.scrollXPercent = this.getValueFromSnap(
      snap,
      PDFFBPaths.ScrollXPercent,
      this.DEFAULT_SCROLL_X,
    );
    return this.scrollXPercent;
  }

  private getScrollYFromSnap(snap) {
    this.scrollYPercent = this.getValueFromSnap(
      snap,
      PDFFBPaths.ScrollYPercent,
      this.DEFAULT_SCROLL_Y,
    );
    return this.scrollYPercent;
  }

  private handleActivityError(error) {
    console.error('[PdfViewerDirective] activity ref load error: ', error.code);
  }

  saveScrollPosition() {
    if (!this.isHost) {
      return;
    }
    this.activityModel.getSessionRef(this.sessionID).update({
      scrollYPercent: this.scrollYPercent,
      scrollXPercent: this.scrollXPercent,
    });
  }
}
