<nav class="right-toolbar">
  <div class="app-btns">
    <button
      id="drawer-toggle"
      class="toolbar-btn"
      (click)="toggleDrawer()"
      matTooltip="Open and close the Activity Panel."
      [matTooltipPosition]="'left'"
    >
      <mat-icon
        class="toolbar-btn-icon"
        [svgIcon]="'toggle'"
        [class.rtl]="drawer.open"
      >
      </mat-icon>
    </button>

    <ng-container *ngFor="let item of items">
      <button
        class="toolbar-btn"
        [id]="item.id"
        matTooltip="{{ item.tooltip }}"
        [matTooltipPosition]="'left'"
        *plHasPermission="item.permissions; uiFlag: item.uiFlag"
        [ngClass]="{
          active: item.drawerName === drawer.activeName && drawer.open,
          'unread-chats':
            item.drawerName === 'chat' && (unreadChatCount$ | async) > 0
        }"
        [ngSwitch]="item.drawerName"
        (click)="openDrawer(item.drawerName)"
      >
        <mat-icon
          class="toolbar-btn-icon"
          [svgIcon]="item.iconProps.icon"
          [class.active]="item.drawerName === drawer.activeName && drawer.open"
        >
        </mat-icon>

        <div
          *ngSwitchCase="'chat'"
          class="unread-messages"
          [hidden]="(unreadChatCount$ | async) === 0"
        >
          {{ unreadChatCount$ | async }}
        </div>

        <span
          *ngSwitchCase="'sessionRecord'"
          class="recording-light-on"
          [hidden]="!isTokboxRecording"
        >
        </span>
      </button>
    </ng-container>
    <button
      class="toolbar-btn help-drawer-btn"
      [ngClass]="{ active: isHelpDrawerActive }"
      [matMenuTriggerFor]="helpMenu"
      (menuClosed)="toggleHelpActive(false)"
      (menuOpened)="toggleHelpActive(true)"
      matTooltip="Help"
      [matTooltipPosition]="'left'"
    >
      <mat-icon>help_outline</mat-icon>
    </button>
    <mat-menu #helpMenu="matMenu" class="help-menu">
      <pl-help-drawer></pl-help-drawer>
    </mat-menu>
  </div>
</nav>
