import { createReducer, on } from '@ngrx/store';
import { AppState } from '@root/src/app/store';
import { GameState, initialState } from './state';
import {
  GameActions,
  GameUpdatePayload,
  AddGamePiecePayload,
  RemoveGamePiecePayload,
} from './actions';

function startAction(_state: GameState, action: GameUpdatePayload) {
  return {
    action,
    open: true,
    gameCategory: action.gameCategory,
    game: action.game,
    pieces: [],
    isGameActive: action.isGameActive,
  };
}

function endGame(state: GameState) {
  return {
    ...state,
    isGameActive: false,
  };
}

function addPieceAction(state: GameState, action: AddGamePiecePayload) {
  const newState = { ...state };
  const newPieces = state.pieces.slice();
  newPieces.push(action.piece);
  newState.pieces = newPieces;
  return {
    ...newState,
    action,
    newPiece: action.piece,
    location: action.location,
  };
}
function removePieceAction(state: GameState, action: RemoveGamePiecePayload) {
  const index = state.pieces.indexOf(action.piece);
  const newState = { ...state };
  if (index > -1) {
    newState.pieces.splice(index, 1);
  }
  return {
    ...newState,
    action,
    removedPiece: action.piece,
  };
}

export const reducer = createReducer(
  initialState,
  on(GameActions.start, startAction),
  on(GameActions.addPiece, addPieceAction),
  on(GameActions.removePiece, removePieceAction),
  on(GameActions.end, endGame),
);

export const updateGame = (state: AppState) => state.game;
