<div class="game-drawer" data-testid="game-drawer">
  <button
    mat-button
    class="instructions-button"
    (click)="toggleInstruction()"
    [disabled]="showClueOverview"
  >
    <mat-icon class="material-icons-outlined">preview</mat-icon>
    {{ showClueInstruction ? 'Hide instructions' : 'Show instructions' }}
  </button>

  <button
    mat-button
    class="instructions-button"
    (click)="toggleOverview()"
    [disabled]="showClueInstruction"
  >
    <mat-icon class="material-icons-outlined">preview</mat-icon>
    {{ showClueOverview ? 'Hide game overview' : 'Show game overview' }}
  </button>

  <div class="game-drawer__content">
    <div class="players-container">
      <pl-player-count-select
        [playerCountOptions]="playerCountOptions"
        [selection]="numPlayers"
        (numPlayersChange)="onChangeNumPlayers($event)"
        [isDisabled]="enableEndGameBtn"
      ></pl-player-count-select>

      <ng-container
        *ngFor="let _ of [].constructor(numPlayers); let index = index"
      >
        <mat-form-field color="accent" class="w-100 mb-4">
          <mat-label>Select player {{ index + 1 }}</mat-label>
          <mat-select
            name="player-{{ index + 1 }}"
            data-testid="player-select"
            [value]="getPlayerAtPosition(index + 1)"
            [disabled]="enableEndGameBtn"
            (selectionChange)="onChangePlayerCheck($event, index + 1)"
          >
            <mat-option *ngFor="let player of playersArr" [value]="player.name">
              {{ player.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>
    </div>
  </div>

  <game-start-end-btns
    [canStartGame]="enableStartGameBtn"
    [isGameActive]="enableEndGameBtn"
    (clickNewGame)="onClickStartNewGame()"
    (clickEndGame)="onClickEndGame()"
  ></game-start-end-btns>
</div>
