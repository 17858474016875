import { AfterViewInit, Component } from '@angular/core';
import moment from 'moment';

import { PLActivityModelService } from '@root/src/app/modules/room/pl-activity/model/activity-model.service';
import { PLYouTubeService } from '@root/src/app/modules/pl-queue/pl-queue-add/pl-quick-youtube/pl-you-tube.service';
import { LoggerService } from '@root/src/app/common/services/logger/logger.service';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pl-youtube-activity-drawer',
  templateUrl: 'pl-youtube-activity-drawer.component.html',
  styleUrls: ['pl-youtube-activity-drawer.component.less'],
})
export class PLYoutubeActivityDrawerComponent implements AfterViewInit {
  title: string;
  description: string;
  thumbnailURL: string;
  duration: string;
  publishedAt: string;

  isModelLoaded$ = new BehaviorSubject<boolean>(false);
  isYoutubeDataLoaded$ = new BehaviorSubject<boolean>(false);

  isLoaded$: Observable<boolean>;

  constructor(
    private activityModel: PLActivityModelService,
    private youTubeService: PLYouTubeService,
    private loggerService: LoggerService,
  ) {
    this.isLoaded$ = combineLatest([
      this.isModelLoaded$,
      this.isYoutubeDataLoaded$,
    ]).pipe(
      map(([isModelLoaded, isYoutubeDataLoaded]) => {
        return isModelLoaded && isYoutubeDataLoaded;
      }),
    );

    const youtubeID = this.activityModel.activity.config;

    this.youTubeService.youTubeIDSearch(youtubeID).subscribe(
      result => {
        if (result === this.youTubeService.NO_RESULTS) {
          return;
        }

        this.thumbnailURL = result.snippet.thumbnails.medium.url;
        this.duration = this.getDuration(result.contentDetails.duration);
        this.publishedAt = moment(result.snippet.publishedAt).format(
          'MMMM D, YYYY',
        );
      },
      error => {
        this.isYoutubeDataLoaded$.next(true);
        this.loggerService.error(error);
      },
      () => {
        this.isYoutubeDataLoaded$.next(true);
      },
    );
  }

  ngAfterViewInit() {
    setTimeout(() => this.addFoundationListener(), 500);
  }

  getDuration(dur: string) {
    const duration = moment.duration(dur);
    return moment.utc(duration.as('milliseconds')).format('mm:ss');
  }

  addFoundationListener = () => {
    this.activityModel.foundationLoaded.then(
      () => {
        this.activityModel.getSessionRef().once('value', snap => {
          const model = this.activityModel.configModel.model;
          this.description = model.description;
          this.title = model.name;
          this.isModelLoaded$.next(true);
        });
      },
      () => this.addFoundationListener(),
    );
  };
}
