import { Injectable } from '@angular/core';
import { PLLodashService } from '@root/src/app/common/services';
import { PLGraphQLService } from '@root/src/app/common/services/pl-graph-ql';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import * as moment from 'moment';
import { PLHttpService } from '@root/src/app/common/services/pl-http';

import { roomSignOffBillingCodes } from './presence-documentation-constants.service';
import { PLRecordRoomService } from '@root/src/app/common/services/pl-records';
import {
  BillingCode,
  ClientAppointment,
  ClientService,
} from '../documentation.models';

@Injectable({
  providedIn: 'root',
})
export class ClientServicesService {
  constructor(
    private plGraphQL: PLGraphQLService,
    private plRecordRoom: PLRecordRoomService,
    private plLodash: PLLodashService,
    private plHttp: PLHttpService,
  ) {}

  getBillingCodes(): Observable<BillingCode[]> {
    return this.plHttp
      .get('billingCodes', {
        with_can_provide: 1,
        limit: 1000,
      })
      .pipe(map((result: any) => result.results));
  }

  getBillingCodesOpts(
    billingCodes: BillingCode[],
  ): { value: string; label: string }[] {
    const opts = this.getRoomBillingCodeList(billingCodes).map(billingCode => ({
      value: billingCode.uuid,
      label: this.toSentenceCase(billingCode.name),
    }));
    return this.plLodash.sort2d(opts, 'label');
  }

  getRoomBillingCodeList(billingCodes: BillingCode[]): BillingCode[] {
    return billingCodes.filter(code =>
      roomSignOffBillingCodes.includes(code.code),
    );
  }

  getClientServices(clientAppointment, provider): Observable<ClientService[]> {
    const vars = {
      first: 100,
      clientId: clientAppointment.uuid,
      compatibleWithProviderId: provider.uuid,
      billingCodeId: clientAppointment.record.billing_code,
      status_In: 'not_started,in_process,idle',
    };
    const query = `query ClientServices(
            $first: Int!,
            $clientId: ID,
            $compatibleWithProviderId: String,
            $billingCodeId: UUID,
            $status_In: String,
        ) {
            clientServices(
                first: $first,
                clientId: $clientId,
                compatibleWithProviderId: $compatibleWithProviderId,
                billingCodeId: $billingCodeId,
                status_In: $status_In
            ) {
                totalCount
                edges {
                    node {
                        ... on DirectService {
                            id
                            service {
                                id
                                code
                                productType {
                                    id
                                    code
                                    name
                                }
                                serviceType {
                                    id
                                    code
                                    shortName
                                    longName
                                }
                            }
                            startDate
                            endDate
                        }
                        ... on Evaluation {
                            id
                            service {
                                id
                                code
                                productType {
                                    id
                                    code
                                    name
                                }
                                serviceType {
                                    id
                                    code
                                    shortName
                                    longName
                                }
                            }
                        }
                        ... on ClientService {
                            id
                        }
                    }
                }
            }
        }`;
    return this.plGraphQL
      .query(query, vars, {})
      .pipe(
        map((result: any) => this.parseClientServices(result.clientServices)),
      );
  }

  checkForServiceRequired(appointment: ClientAppointment) {
    // Some billing codes may not need a client service, so check first.
    return this.plRecordRoom
      .isClientServiceRequired(appointment.record.billing_code)
      .pipe(first());
  }

  initializeServiceSelection(
    clientAppointment: ClientAppointment,
    selectionOptions,
    localStorageKey,
  ) {
    let selectedClientServiceId;

    if (clientAppointment.record.client_service) {
      selectedClientServiceId = clientAppointment.record.client_service;
    } else {
      // If only 1 to select, just auto select it.
      if (selectionOptions.length === 1) {
        selectedClientServiceId = selectionOptions[0].value;
      } else {
        const data = this.getLocalStorageData(localStorageKey);
        if (data && data.selectedClientServiceId) {
          selectedClientServiceId = data.selectedClientServiceId;
        }
      }
    }
    return selectedClientServiceId;
  }

  selectClientServiceAndPreCreateRecord(
    clientAppointment: ClientAppointment,
    provider,
    selectedClientServiceId: string,
    selectedBillingCodeId: string,
    localStorageKey,
  ) {
    const localStorageData = this.getLocalStorageData(localStorageKey);
    localStorageData.selectedClientServiceId = selectedClientServiceId;
    localStorage.setItem(localStorageKey, JSON.stringify(localStorageData));

    return this.preCreateRecord(
      clientAppointment,
      provider,
      selectedClientServiceId,
      selectedBillingCodeId,
    );
  }

  preCreateRecord(
    clientAppointment: ClientAppointment,
    provider,
    selectedClientServiceId: string,
    selectedBillingCode: string,
  ) {
    return new Observable((observer: any) => {
      if (
        !clientAppointment.record.uuid ||
        selectedClientServiceId !== clientAppointment.record.client_service ||
        selectedBillingCode !== clientAppointment.record.billing_code
      ) {
        const record: any = Object.assign(clientAppointment.record, {
          billing_code: selectedBillingCode,
          client_service: selectedClientServiceId,
          note_schema: clientAppointment.record.note_schema || '',
          tracking_type: clientAppointment.record.tracking_type || 'regular',
        });
        this.plRecordRoom
          .saveRecord(
            record,
            clientAppointment.uuid,
            record.appointment,
            clientAppointment.appointment.event,
            provider.uuid,
          )
          .pipe(first())
          .subscribe((resRecord: any) => {
            clientAppointment.record = Object.assign(
              clientAppointment.record,
              resRecord.record,
            );
            observer.next();
          });
      } else {
        observer.next();
      }
    });
  }

  /**
   * Convert the camelCase returned by graphQL to the snake_case returned by REST services.
   * While camelCase is desired, snake_case is more prevalent currently.
   * @param clientServicesData
   * @returns
   */
  private parseClientServices(clientServicesData: any[]): ClientService[] {
    const services: ClientService[] = clientServicesData.map(
      (item: any): ClientService => {
        let service = {
          id: item.id,
          start_date: item.startDate,
          end_date: item.endDate,
          service: {
            id: item.service.id,
            code: item.service.code,
            product_type: {
              id: item.service.productType.id,
              code: item.service.productType.code,
              name: item.service.productType.name,
            },
            service_type: {
              id: item.service.serviceType.id,
              code: item.service.serviceType.code,
              short_name: item.service.serviceType.shortName,
              long_name: item.service.serviceType.longName,
            },
          },
        };
        return service;
      },
    );
    return services;
  }

  getViewModeFromLocalStorage(localStorageKey): string {
    // Load state from local storage and pre-select if necessary.
    let data: any = this.getLocalStorageData(localStorageKey);
    return data.viewMode;
  }

  private getLocalStorageData(localStorageKey: any) {
    let data: any;
    try {
      data = localStorage.getItem(localStorageKey);
    } catch (e) {
      console.debug('localStorage error in ClientServicesService');
    }
    if (data) {
      data = JSON.parse(data);
      return data;
    }
    return {};
  }

  formSelectOptsClientServices(
    clientServices1,
    skipEnded = true,
    skipEvals = false,
  ) {
    const clientServices: ClientService[] = this.filterClientServices(
      clientServices1,
      skipEnded,
      skipEvals,
    );
    return clientServices.map((clientService: ClientService) => {
      return {
        value: clientService.id,
        label: this.formSelectLabelClientService(clientService),
      };
    });
  }

  formSelectLabelClientService(clientService: ClientService) {
    const start = clientService.start_date
      ? moment(clientService.start_date).format('MM/YYYY')
      : '';
    const end = clientService.end_date
      ? moment(clientService.end_date).format('MM/YYYY')
      : '';
    const time =
      start && end
        ? `${start} - ${end}`
        : start
        ? `${start} - no end date`
        : '';
    const label = `${clientService.service.service_type.short_name} ${clientService.service.product_type.name}`;
    return `${label} ${time}`;
  }

  filterClientServices(
    clientServices: ClientService[],
    skipEnded = true,
    skipEvals = true,
  ): ClientService[] {
    const nowTime = moment();
    return clientServices.filter((clientService: ClientService) => {
      if (skipEvals && !clientService.start_date) {
        return false;
      }
      return skipEnded &&
        clientService.end_date &&
        moment(clientService.end_date) < nowTime
        ? false
        : true;
    });
  }

  private toSentenceCase(str: string) {
    return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
  }
}
