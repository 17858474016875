<div class="teamword-drawer" data-testid="game-teamword-drawer">
  <mat-tab-group
    class="teamword-tabs is-compact"
    mat-stretch-tabs
    color="accent"
    [selectedIndex]="currentTabIndex$ | async"
  >
    <mat-tab label="Players">
      <ng-container *ngTemplateOutlet="players"></ng-container>
    </mat-tab>

    <mat-tab label="Options">
      <ng-container *ngTemplateOutlet="options"></ng-container>
    </mat-tab>
  </mat-tab-group>

  <game-start-end-btns
    [canStartGame]="canStartNewGame()"
    [isGameActive]="canEndGame()"
    (clickNewGame)="onClickStartNewGame()"
    (clickEndGame)="onClickEndGame()"
  ></game-start-end-btns>
</div>

<ng-template #players>
  <div class="players-container">
    <pl-player-count-select
      [playerCountOptions]="formData.numPlayersOpts"
      [selection]="formData.model.numPlayers"
      (numPlayersChange)="onChangeNumPlayers($event)"
      [isDisabled]="canEndGame()"
    ></pl-player-count-select>

    <mat-form-field
      color="accent"
      class="w-100"
      *ngFor="
        let item of [].constructor(formData.model.numPlayers);
        let $index = index
      "
    >
      <mat-label>Select player {{ $index + 1 }}</mat-label>
      <mat-select
        [(ngModel)]="formData.model['player' + ($index + 1)]"
        (selectionChange)="onChangePlayer($index + 1)"
        [disabled]="canEndGame()"
        data-testid="teamword-player"
      >
        <mat-option *ngFor="let opt of formData.playerOpts" [value]="opt.value">
          {{ opt.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div></ng-template
>

<ng-template #options>
  <div class="options-group">
    <div class="mat-slide-toggle-group" data-testid="game-sounds-group">
      <label id="game-sounds-toggle"> Game sounds </label>
      <mat-slide-toggle
        data-testid="game-sounds-toggle"
        [(ngModel)]="formData.model.gameSounds"
        (ngModelChange)="onChangeGameSounds()"
        aria-labelledby="game-sounds-toggle"
      >
        {{ formData.model.gameSounds ? 'On' : 'Off' }}
      </mat-slide-toggle>
    </div>
  </div>
  <div class="options-group">
    <label id="word-type-label">Word type</label>
    <mat-button-toggle-group
      name="word-type"
      [(ngModel)]="formData.model.wordType"
      [disabled]="canEndGame()"
    >
      <mat-button-toggle value="random">Random</mat-button-toggle>
      <mat-button-toggle value="manual">Manual</mat-button-toggle>
    </mat-button-toggle-group>

    <ng-container *ngIf="formData.model.wordType === 'random'">
      <mat-form-field color="accent" class="w-100">
        <mat-label>Level</mat-label>
        <mat-select
          [(ngModel)]="formData.model.level"
          (selectionChange)="onLevelChange()"
          [disabled]="canEndGame()"
          data-testid="teamword-level"
        >
          <mat-option
            *ngFor="let opt of formData.levelOpts"
            [value]="opt.value"
          >
            {{ opt.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="w-100 word-length-container">
        <label id="choose-word-lenght-label">Word length</label>

        <mat-radio-group
          aria-labelledby="choose-word-lenght-label"
          class="radio-buttons"
          color="primary"
          data-testid="word-lenght-radio-group"
          [(ngModel)]="formData.model.wordLength"
        >
          <mat-radio-button
            *ngFor="let option of wordLengthOpts"
            class="radio-bg-button"
            data-testid="word-lenght-radio-button"
            [value]="option.value"
            [checked]="option.value === formData.model.wordLength"
            [disabled]="canEndGame()"
          >
            {{ option.label }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </ng-container>

    <ng-container *ngIf="formData.model.wordType === 'manual'">
      <p>Enter a custom 3-5 letter word.</p>
      <mat-form-field color="accent" class="w-100">
        <mat-label>Custom word</mat-label>
        <input
          matInput
          [formControl]="manualWordFC"
          required
          [readonly]="canEndGame()"
        />
        <mat-error *ngIf="manualWordFC.hasError('minlength')">
          {{ manualWordValidationMessages['minlength'] }}
        </mat-error>
        <mat-error *ngIf="manualWordFC.hasError('maxlength')">
          {{ manualWordValidationMessages['maxlength'] }}
        </mat-error>
        <mat-error *ngIf="manualWordFC.hasError('pattern')">
          {{ manualWordValidationMessages['pattern'] }}
        </mat-error>
      </mat-form-field>
    </ng-container>
  </div>
  <div class="options-group">
    <label id="choose-board-theme-label">Number of guesses</label>

    <mat-radio-group
      aria-labelledby="choose-board-theme-label"
      class="radio-buttons"
      color="primary"
      data-testid="board-theme-radio-group"
      [(ngModel)]="formData.model.numGuesses"
    >
      <mat-radio-button
        *ngFor="let option of formData.numGuessesOpts"
        class="radio-bg-button"
        data-testid="board-theme-radio-button"
        [value]="option.value"
        [checked]="option.value === formData.model.numGuesses"
        [disabled]="canEndGame()"
      >
        {{ option.label }}
      </mat-radio-button>
    </mat-radio-group>
  </div>
</ng-template>
