import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

import { PLScenesComponent } from './pl-scenes.component';
import { PLScenesDrawerComponent } from './pl-scenes-drawer.component';
import { PLScenesFactoryService } from './pl-scenes-factory.service';
import { PLScenesBackgroundsComponent } from './scenes-backgrounds/scenes-backgrounds.component';
import { PLScenesStickersComponent } from './scenes-stickers/scenes-stickers.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    DragDropModule,
    FormsModule,
    MatTabsModule,
    MatButtonModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatFormFieldModule,
  ],
  exports: [PLScenesComponent, PLScenesDrawerComponent],
  declarations: [
    PLScenesComponent,
    PLScenesDrawerComponent,
    PLScenesBackgroundsComponent,
    PLScenesStickersComponent,
  ],
  providers: [PLScenesFactoryService],
})
export class PLScenesModule {}
