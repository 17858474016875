import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  PLVendorGameService,
  PLGameDbAPI,
  PLGameDb,
} from '../pl-vendor-game.service';
import { GametableTeamwordConfigInterface } from './gametable-config.interface';
import { PLVendorGames } from '../pl-vendor-game-definitions.service';
import { Participant } from '@room/session/store';
import { UntypedFormControl, Validators } from '@angular/forms';

// General game rail form requirements:
// * start game - save shared game state with chosen form data
// * end game - remove shared game state
// * refresh form data (in case participants list has changed)
// * get game config - from form data to start game
@Component({
  selector: 'pl-gametable-teamword-drawer',
  templateUrl: './pl-gametable-teamword-drawer.component.html',
  styleUrls: ['./pl-gametable-teamword-drawer.component.less'],
})
export class PLGametableTeamwordDrawerComponent implements OnInit, OnDestroy {
  @Input() activity: any;

  currentTabIndex$ = new BehaviorSubject<number>(0);

  initialized = false;
  destroyed$ = new Subject<boolean>();

  participants: Participant[];
  formData: any;
  gameState: any;

  gameDb: PLGameDb;
  gameApi: PLGameDbAPI;
  settingsApi: PLGameDbAPI;

  manualWordFC: UntypedFormControl;
  manualWordValidationMessages = {
    minlength: '3 Letter min',
    maxlength: '5 Letter max',
    pattern: 'Only letters (A-Z)',
  };
  wordLengthOpts: any[];

  constructor(private vendorGameService: PLVendorGameService) {
    this.manualWordFC = new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(5),
      Validators.pattern('[a-zA-Z ]*'),
    ]);
  }

  ngOnInit() {
    // setup form
    this.formData = PLVendorGames.teamword.newFormData();
    this.onLevelChange();

    // get connected participants
    this.vendorGameService
      .getParticipantsAndLocalId()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([participants]) => {
        this.participants = participants;
        this.refreshPlayerOpts();

        // check for an existing game
        this.gameDb =
          this.gameDb || this.vendorGameService.setupPLGameDb(this.activity);
        this.gameApi = this.gameDb.ref('rules');
        this.settingsApi = this.gameDb.ref('settings');
        this.checkSavedGame(() => {
          console.log(`--- Rail: Initialized`, { STATE: this });
          this.initialized = true;
        });
        this.checkSavedSettings();
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.gameDb?.destroy();
  }

  onLevelChange() {
    if (this.formData.model.level === 'kindergarten') {
      if (this.formData.model.wordLength === 4) {
        this.formData.model.wordLength = -1;
      }
      this.wordLengthOpts = this.formData.wordLengthOpts.filter(
        opt => opt.value !== 4,
      );
    } else {
      this.wordLengthOpts = [...this.formData.wordLengthOpts];
    }
  }

  onClickStartNewGame() {
    const gameConfig = this.getGameConfig();
    this.gameApi.set(gameConfig);
    this.gameApi.get(
      (game: any) => {
        this.gameState = game;
        console.log('--- Rail: new game OK', { gameConfig, STATE: this });
      },
      (err: any) => {
        console.error('--- Rail: new game ERR', err);
      },
    );
  }

  onClickEndGame() {
    this.gameApi.set(null);
    this.refreshPlayerOpts();
    this.gameState = null;
  }

  onChangeNumPlayers(value: number) {
    const model = this.formData.model;
    model.numPlayers = value;
    if (model.numPlayers < 4) {
      model.player4 = null;
    }
    if (model.numPlayers < 3) {
      model.player3 = null;
    }
    if (model.numPlayers < 2) {
      model.player2 = null;
    }
  }

  onChangePlayer(playerNum) {
    const model = this.formData.model;
    const player = model[`player${playerNum}`];
    const players = [1, 2, 3, 4];
    players.forEach((num: number) => {
      if (num === playerNum) return;
      const otherPlayer = model[`player${num}`];
      if (otherPlayer === player) {
        model[`player${num}`] = null;
      }
    });
  }

  onChangeGameSounds() {
    this.settingsApi.set({
      mute: !this.formData.model.gameSounds,
    });
  }

  canStartNewGame() {
    const model = this.formData.model;
    const numPlayers = model.numPlayers;
    for (let i = 1; i <= numPlayers; i++) {
      if (!model[`player${i}`]) {
        return false;
      }
    }

    if (model.wordType === 'manual') {
      if (this.manualWordFC.invalid) {
        return false;
      }
    }

    return !this.gameState;
  }

  canEndGame() {
    return !!this.gameState;
  }

  // ==========================
  // Private
  // ==========================
  private refreshPlayerOpts() {
    // update player opts and clear out stale model values
    this.formData.playerOpts = this.vendorGameService.getPlayerOpts(
      this.participants,
    );
    console.log('--- refreshPlayersOpts', { STATE: this });
  }

  private setPlayersFromSavedGame(gameData: GametableTeamwordConfigInterface) {
    this.gameState = gameData;
    if (!gameData) return;

    this.formData.model.numPlayers = gameData.numPlayers;
    const nums = [1, 2, 3, 4];
    nums.forEach((num: any) => {
      const player = gameData[`player${num}`];
      if (player) {
        const P = this.participants.find(p => p.userId === player.clientId);
        if (P) {
          this.formData.model[`player${num}`] = P.userId;
        }
      }
    });
  }

  private checkSavedGame(doneInit: Function) {
    this.gameApi.get(
      (game: any) => {
        console.log('--- Rail: load game OK', game);
        this.setPlayersFromSavedGame(game);
        doneInit();
      },
      (err: any) => {
        console.error('--- Rail: load game ERR', err);
      },
    );
  }

  private checkSavedSettings() {
    this.settingsApi.get(
      (settings: any) => {
        console.log('--- Rail: load settings OK', settings);
        this.formData.model.gameSounds = !(settings && settings.mute);
      },
      (err: any) => {
        console.error('--- Rail: load settings ERR', err);
      },
    );
  }

  private getGameConfig(): GametableTeamwordConfigInterface {
    const { level, numGuesses, wordType, wordLength } = this.formData.model;
    return {
      level,
      numGuesses,
      wordType,
      wordLength,
      manualWord: this.manualWordFC.value,
      gameId: Date.now(),
      skinId: this.formData.model.boardTheme,
      numPlayers: this.formData.model.numPlayers,
      player1: { clientId: this.formData.model.player1 },
      player2: { clientId: this.formData.model.player2 },
      player3: { clientId: this.formData.model.player3 },
      player4: { clientId: this.formData.model.player4 },
    };
  }
}
