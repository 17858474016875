<div
  class="pl-document-item"
  (click)="onLoadDocument()"
  [ngClass]="{
    current: document.current,
    disabled: itemDisabled,
    clickable: !itemDisabled && !document.current && !document.trashed
  }"
>
  <div class="document-properties">
    <div class="doc-name" *ngIf="!isEditingName">
      {{ document.name && document.name.length ? document.name : 'untitled' }}
    </div>
    <input
      #docNameEditor
      type="text"
      id="docNameEditor"
      autofocus
      *ngIf="isEditingName"
      [value]="document.name"
      (click)="$event.stopPropagation()"
      (blur)="onToggleRename(false)"
      (keyup.esc)="onToggleRename(false)"
      (keyup.enter)="onEnter($event)"
    />
    <div class="doc-date">Last saved: {{ document.date }}</div>
  </div>

  <button
    mat-icon-button
    (click)="$event.stopPropagation()"
    [matMenuTriggerFor]="menu"
    aria-label="Example icon-button with a menu"
  >
    <mat-icon>more_horiz</mat-icon>
  </button>

  <mat-menu #menu="matMenu" backdropClass="is-dark">
    <button
      mat-menu-item
      *ngIf="!document.trashed"
      (click)="onToggleRename(true)"
    >
      <span>Rename</span>
    </button>
    <button
      mat-menu-item
      *ngIf="!document.trashed"
      [disabled]="document.current && !allowDeleteCurrent"
      (click)="onTrashDocument()"
    >
      <span>Remove</span>
    </button>
    <button
      mat-menu-item
      *ngIf="document.trashed"
      (click)="onRestoreDocument()"
    >
      <span>Restore</span>
    </button>
    <button mat-menu-item *ngIf="document.trashed" (click)="onDeleteDocument()">
      <span>Delete</span>
    </button>
  </mat-menu>
</div>
