<div class="drawer-chat">
  <header class="drawer-header">
    <h3 class="drawer-header__title">Chat</h3>
  </header>

  <div *ngIf="user.uuid === hostUserId" class="control-container">
    <div class="chat-toggle-container">
      <h6>Clients can chat</h6>
      <mat-slide-toggle [(ngModel)]="canUsersChat" (change)="setChatMuted()">
        {{ canUsersChat ? 'On' : 'Off' }}
      </mat-slide-toggle>
    </div>
    <div class="clear-messages-container">
      <button
        *ngIf="!showClearVerification"
        mat-stroked-button
        color="warn"
        class="w-100"
        (click)="toggleShowClearVerification()"
      >
        Clear all messages
      </button>

      <div *ngIf="showClearVerification" class="message-verification-container">
        <p>Are you sure you want to clear all chat messages?</p>
        <div class="button-container">
          <button
            mat-flat-button
            class="is-small"
            color="warn"
            (click)="toggleShowClearVerification()"
          >
            No
          </button>
          <button
            mat-flat-button
            class="is-small"
            color="success"
            (click)="clearMessages()"
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  </div>

  <main #messagesContainer>
    <blockquote
      *ngFor="let group of messages"
      [class.author]="group.userUuid === user.uuid"
    >
      <div class="message-timestamp">
        <span
          >{{ group.userName }} - {{ group.time | date : 'MM/dd/yyyy' }}
          {{ group.time | timeFormatter }}</span
        >
      </div>
      <div
        class="content"
        [ngClass]="{ hostStyle: group.userUuid === hostUserId }"
      >
        <pl-chat-message
          #message
          *ngFor="let msg of group.messages"
          [message]="msg.message"
        ></pl-chat-message>
      </div>
      <div
        class="bubble-point"
        [ngClass]="{
          local: group.userUuid === user.uuid,
          sessionhost: group.userUuid === hostUserId
        }"
      ></div>
    </blockquote>
  </main>

  <footer>
    <div class="embargo" *ngIf="preventStudentType"></div>
    <textarea
      [(ngModel)]="newMessage"
      (keydown.enter)="sendMessage($event)"
      (keyup)="sendChatInteraction()"
      (ngModelChange)="onModelChange()"
      [disabled]="preventStudentType"
      tabindex="1"
    >
    </textarea>
    <button
      (click)="sendMessage($event)"
      [class.active]="newMessage !== ''"
      tabindex="2"
    >
      <mat-icon>send</mat-icon>
    </button>
  </footer>
</div>
