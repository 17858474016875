import { Component, Input } from '@angular/core';
import { Activity } from '../../app/store';
import { PLVendorGameService } from './vendor-games';

@Component({
  selector: 'pl-game-drawer',
  templateUrl: './pl-game-drawer.component.html',
  styleUrls: ['./pl-game-drawer.component.less'],
})
export class PLGameDrawerComponent {
  @Input() public activity: Activity;

  constructor(private vendorGameService: PLVendorGameService) {}

  isVendorGame(activity) {
    return this.vendorGameService.isVendorGame(activity);
  }
}
