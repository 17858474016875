import { Injectable } from '@angular/core';
import { PLUrlsService, PLHttpService } from '@common/services/pl-http';
import { DRFModel } from './DRFModel.service';

@Injectable({ providedIn: 'root' })
export class DRFLessonModel extends DRFModel {
  constructor(http: PLHttpService, plUrls: PLUrlsService) {
    super(`${plUrls.urls.platformFE}/api/v1/lesson/`, http);
  }

  use(token) {
    if (!this.isSaved()) {
      throw new Error("This model hasn't been saved yet.");
    }
    this.setKey('use');
    return this.save({}, token);
  }
}
