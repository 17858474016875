import {
  Component,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store';

import { selectIsLocalParticipantHost } from '@room/session/store';

@Component({
  selector: 'pl-conference-video',
  templateUrl: 'conference-video.component.html',
  styleUrls: ['conference-video.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConferenceVideoComponent {
  @Input() public src: MediaStream;
  @Input() public isMirrored = false;
  @Input() public isRotated = false;
  @Input() public shouldKeepAspectRatio = false;

  @Output() public readonly played = new EventEmitter<Event>();

  isMuted$: Observable<boolean>;

  constructor(private store: Store<AppState>) {
    this.isMuted$ = this.store.select(selectIsLocalParticipantHost);
  }

  onPlay(ev: Event) {
    this.played.emit(ev);
  }
}
