<div class="w-100">
    <h4 class="item-title">
        Suspects
    </h4>
    <div
        *ngFor="let suspect of currentUser.cardList.suspects"
        class="item-row">
        <div class="item-img-container">
            <img class="w-100" src="{{suspect.icon}}">
        </div>

        <span>
            {{ suspect.name }}
        </span>

        <div class="hover-click" (click)="suspectNotes(suspect, 'yes')">
            <img *ngIf="suspect.status !== 'yes'" src="/assets/board-games/clue_game_assets/icon_check.svg">
            <img *ngIf="suspect.status === 'yes'" src="/assets/board-games/clue_game_assets/icon_check_green.svg">
        </div>
        <div class="hover-click" (click)="suspectNotes(suspect, 'no')">
            <img *ngIf="suspect.status !== 'no'" src="/assets/board-games/clue_game_assets/icon_cancel.svg">
            <img *ngIf="suspect.status === 'no'" src="/assets/board-games/clue_game_assets/icon_cancel_red.svg">
        </div>
        <div class="hover-click" (click)="suspectNotes(suspect, 'unknown')">
            <img *ngIf="suspect.status !== 'unknown'" src="/assets/board-games/clue_game_assets/icon_unknown.svg">
            <img *ngIf="suspect.status === 'unknown'" src="/assets/board-games/clue_game_assets/icon_unknown_blue.svg">
        </div>
    </div>
</div>
<div class="locations-container w-100">
    <h4 class="item-title">
        Locations
    </h4>
    <div
        *ngFor="let location of currentUser.cardList.locations"
        class="item-row">
        <div class="item-img-container">
            <img class="w-100" src="{{location.icon}}">
        </div>

        <span>
            {{ location.name }}
        </span>

        <div class="hover-click" (click)="locationNotes(location, 'yes')">
            <img *ngIf="location.status !== 'yes'" src="/assets/board-games/clue_game_assets/icon_check.svg">
            <img *ngIf="location.status === 'yes'" src="/assets/board-games/clue_game_assets/icon_check_green.svg">
        </div>
        <div class="hover-click" (click)="locationNotes(location, 'no')">
            <img *ngIf="location.status !== 'no'" src="/assets/board-games/clue_game_assets/icon_cancel.svg">
            <img *ngIf="location.status === 'no'" src="/assets/board-games/clue_game_assets/icon_cancel_red.svg">
        </div>
        <div class="hover-click" (click)="locationNotes(location, 'unknown')">
            <img *ngIf="location.status !== 'unknown'" src="/assets/board-games/clue_game_assets/icon_unknown.svg">
            <img *ngIf="location.status === 'unknown'" src="/assets/board-games/clue_game_assets/icon_unknown_blue.svg">
        </div>
    </div>
</div>
<div class="methods-container w-100">
    <h4 class="item-title">
        Methods
    </h4>
    <div
        *ngFor="let method of currentUser.cardList.methods"
        class="item-row">

        <div class="item-img-container">
            <img class="w-100" src="{{method.icon}}">
        </div>

        <span>
            {{ method.name }}
        </span>

        <div class="hover-click" (click)="methodNotes(method, 'yes')">
            <img *ngIf="method.status !== 'yes'" src="/assets/board-games/clue_game_assets/icon_check.svg">
            <img *ngIf="method.status === 'yes'" src="/assets/board-games/clue_game_assets/icon_check_green.svg">
        </div>
        <div class="hover-click" (click)="methodNotes(method, 'no')">
            <img *ngIf="method.status !== 'no'" src="/assets/board-games/clue_game_assets/icon_cancel.svg">
            <img *ngIf="method.status === 'no'" src="/assets/board-games/clue_game_assets/icon_cancel_red.svg">
        </div>
        <div class="hover-click" (click)="methodNotes(method, 'unknown')">
            <img *ngIf="method.status !== 'unknown'" src="/assets/board-games/clue_game_assets/icon_unknown.svg">
            <img *ngIf="method.status === 'unknown'" src="/assets/board-games/clue_game_assets/icon_unknown_blue.svg">
        </div>
    </div>
</div>