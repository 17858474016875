<div class="filter-video-settings" *ngIf="canBackgroundBlur$ | async">
  <mat-radio-group
    aria-label="Filter video"
    [(ngModel)]="mode.filterMode"
    (change)="filterChanged($event)"
    *ngIf="{ filterMode: filterMode$ | async } as mode"
  >
    <div class="radio-buttons">
      <label>Filter:</label>
      <mat-radio-button value="{{ FilterVideoType.BLUR }}"
        >Blur</mat-radio-button
      >
      <mat-radio-button value="{{ FilterVideoType.BACKGROUND }}"
        >Background</mat-radio-button
      >
      <mat-radio-button value="{{ FilterVideoType.NONE }}"
        >None</mat-radio-button
      >
    </div>
  </mat-radio-group>
</div>
