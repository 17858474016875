import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IntrojsService } from './introjs.service';
import { LoginMonitorService } from './login-monitor.service';
import { PlAppGlobalService } from './pl-app-global.service';
import { PLLodashService } from './pl-lodash.service';
import { PLTimezoneService } from './pl-timezone.service';
import { IPadSupportService } from './ipad-support.service';
import { WebSafeFontsService } from '../../modules/room/pl-whiteboard/models/web-safe-fonts.service';
import { StampFactoryService } from '../../modules/room/pl-whiteboard/models/stamp-factory.service';
import { ResizeObserverService } from './resize-observer.service';

@NgModule({
  imports: [CommonModule],
  exports: [],
  declarations: [],
  providers: [IntrojsService, LoginMonitorService],
})
export class PLCommonServicesModule {}

export {
  IntrojsService,
  LoginMonitorService,
  PlAppGlobalService,
  IPadSupportService,
  WebSafeFontsService,
  StampFactoryService,
  ResizeObserverService,
  PLLodashService,
  PLTimezoneService,
};
