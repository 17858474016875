import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'pl-queue-name-form',
  templateUrl: './pl-queue-name-form.component.html',
  styleUrls: ['./pl-queue-name-form.component.less'],
})
export class PLQueueNameFormComponent implements OnInit, AfterViewInit {
  @ViewChild('input') inputRef: ElementRef<HTMLInputElement>;
  @Input() initialQueueName: string;

  @Output() readonly save = new EventEmitter<string>();
  @Output() readonly discard = new EventEmitter<any>();

  queueName: string;

  ngOnInit() {
    this.queueName = this.initialQueueName;
  }

  ngAfterViewInit(): void {
    // Adding this timeout to ensure we focus input after the .scrollLeft was resetted
    // on the drawer container, otherwise it steals the focus causing unwanted "blur" event
    setTimeout(() => {
      this.inputRef?.nativeElement.focus();
    }, 0);
  }

  onSubmitQueueName(event: Event) {
    event.preventDefault();
    this.save.emit(this.queueName);
  }

  onInputBlur() {
    this.save.emit(this.queueName);
  }

  onInputESCKey() {
    this.queueName = this.initialQueueName;
    this.discard.emit(this.queueName);
  }
}
