import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

import { UIRouter, UIRouterModule } from '@uirouter/angular';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { PLToastModule } from '@lib-components/index';

import { PLTimezoneService, PLCommonServicesModule } from '@common/services';

import { FirebaseResolverService } from '@common/firebase/proxy-resolver.service';
import { FirebaseEffects } from '@common/firebase/store/firebase.effects';
import { PLApiModule } from '@common/services/api';
import { PLGraphQLModule } from '@common/services/pl-graph-ql';
import { PLHttpModule } from '@common/services/pl-http';
import { MediaEffects } from '@common/media/store';

import { PLUserModule } from '@modules/user';
import { DesignSystemModule } from '@modules/design-system/design-system.module';
import { AppEffects } from '@room/app/store';

import { extModules } from './build-specifics';
import { AppComponent } from './app.component';
import { appStore } from './store';
import { AppConfigService } from './app-config.service';
import { SvgToMatIconsRegistryService } from './common/services/svg-to-mat-icons-registry.service';
import { HotkeyModule } from 'angular2-hotkeys';
import { WindowModule } from './common/window';
import { WaitingRoomModule } from './common/waiting-room';
import { createErrorHandler } from './common/services/logger/logger.service';
import { roomFutureStates } from './modules/room/room.future.states';
import { studentLoginFutureState } from './modules/student-login/student-login.future.states';
import { waitingRoomFutureState } from './modules/waiting-room/waiting-room.future.state';
import { plSetupFutureState } from './modules/pl-setup/pl-setup.future.states';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MAT_PROGRESS_BAR_DEFAULT_OPTIONS } from '@angular/material/progress-bar';
import { designSystemFutureState } from './modules/design-system/design-system.future.states';
import { invalidRoomFutureState } from './modules/room/invalid-room/invalid-room.future.states';
import { pdfPrinterFutureState } from './modules/pdf-printer/pdf-printer.future.states';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,

    PLHttpModule,
    PLApiModule,
    PLGraphQLModule.forRoot(),

    HotkeyModule.forRoot(),
    UIRouterModule.forRoot({
      deferIntercept: true,
      deferInitialRender: true,
      config(uiRouterInstance) {
        uiRouterInstance.transitionService.onFinish({}, () => {
          const loader = document.querySelector('#page-loader');
          if (loader) {
            loader.remove();
          }
        });
      },
      states: [
        pdfPrinterFutureState,
        studentLoginFutureState,
        waitingRoomFutureState,
        invalidRoomFutureState,
        ...roomFutureStates,
        plSetupFutureState,
        localStorage.getItem('plDesignSystemEnabled') === 'true'
          ? designSystemFutureState
          : null,
      ].filter(Boolean),
    }),
    StoreModule.forRoot(appStore),
    EffectsModule.forRoot([FirebaseEffects, AppEffects, MediaEffects]),

    PLCommonServicesModule,
    PLUserModule,
    WaitingRoomModule,
    PLToastModule,
    WindowModule,
    DesignSystemModule,
    ...extModules,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: bootstrapInitializer,
      multi: true,
      deps: [
        UIRouter,
        AppConfigService,
        FirebaseResolverService,
        SvgToMatIconsRegistryService,
      ],
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
        // TODO: Set default color as accent after upgrading to version 14
        // color: 'accent',
      },
    },
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: {
        color: 'accent',
      },
    },
    {
      provide: MAT_PROGRESS_BAR_DEFAULT_OPTIONS,
      useValue: {
        color: 'accent',
      },
    },
    {
      provide: ErrorHandler,
      useValue: createErrorHandler(),
    },
    PLTimezoneService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function bootstrapInitializer(
  uiRouter: UIRouter,
  appConfigService: AppConfigService,
  firebaseResolverService: FirebaseResolverService,
  svgToMatIconsRegistryService: SvgToMatIconsRegistryService,
) {
  return async () => {
    await firebaseResolverService.load();

    appConfigService.checkForConfig();

    svgToMatIconsRegistryService.register();

    uiRouter.urlService.listen();
    uiRouter.urlService.sync();
  };
}
