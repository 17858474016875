<h2 class="heading-2">Please switch to landscape mode</h2>
<p>
  This app works best in landscape mode. Please rotate the device back to
  landscape.
</p>
<div class="examples">
  <div class="example" id="incorrect-ipad"></div>
  <div>
    <div class="separator"></div>
  </div>
  <div class="example" id="correct-ipad"></div>
</div>
<hr />
<p>
  Still having trouble?
  <a href="#" (click)="onStartChat()">Contact tech support</a>
</p>
