import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class FlashcardsDnDService {
  public flashcardDragging$ = new BehaviorSubject<any>(null);

  public updateFlashCardsDragging(card) {
    this.flashcardDragging$.next(card);
  }
}
