<div class="go-fish-container">
  <!-- this is commented out because the game-instructions-control feature is not fully designed -->
  <!-- <game-instructions-control
    (clickShowInstructions)="showInstructions()"
  ></game-instructions-control> -->

  <div class="card-deck-select-container">
    <ng-container *ngIf="deckSelected; else deckNotSelected">
      <p class="card-deck-select-container__title">{{ deck.title }}</p>
      <p class="card-deck-select-container__info">
        {{ deckImages.length }} cards
      </p>
      <button
        mat-stroked-button
        color="primary"
        class="is-small"
        [disabled]="gameActive"
        (click)="chooseDeck()"
      >
        Change card deck
      </button>
    </ng-container>

    <ng-template #deckNotSelected>
      <p class="card-deck-select-container__title">No card deck selected</p>
      <button mat-raised-button color="primary" (click)="chooseDeck()">
        Select a card deck
      </button>
    </ng-template>
  </div>

  <div class="game-options-container">
    <div [class.inactive]="!deckSelected"></div>
    <div class="select-num-cards game-options-section">
      <p>Select how many cards to deal each player.</p>
      <mat-radio-button
        *ngFor="let opt of dealCountOpts"
        class="drawer-radio-btn"
        [disabled]="gameActive && dealCount !== opt.value"
        value="{{ opt.value }}"
        [checked]="dealCount === opt.value"
        (change)="changeDealCount($event)"
      >
        {{ opt.label }}
      </mat-radio-button>
    </div>

    <div class="display-options game-options-section">
      <pl-card-display-options
        (optionSelected)="changeCardDisplay($event)"
        [value]="cardDisplay"
      ></pl-card-display-options>
    </div>
    <div class="x-ray-toggle-container">
      <label>Clinician X-ray</label>
      <mat-slide-toggle (change)="toggleXRay($event)">
        {{ xRayOn ? 'On' : 'Off' }}
      </mat-slide-toggle>
    </div>
  </div>
</div>

<game-start-end-btns
  [allDisabled]="!deckSelected"
  [canStartGame]="newGameBtnActive"
  [isGameActive]="gameActive"
  (clickNewGame)="newGame()"
  (clickEndGame)="endGame()"
></game-start-end-btns>
