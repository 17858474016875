<div class="confirm-alert">
  <div class="confirm-alert__content">
    <ng-content></ng-content>
  </div>

  <div class="confirm-alert__actions">
    <button
      mat-flat-button
      class="is-small"
      color="warn"
      (click)="cancel.emit(); $event.stopPropagation()">
      {{ cancelText }}
    </button>
    <button
      mat-flat-button
      class="is-small"
      color="success"
      (click)="confirm.emit(); $event.stopPropagation()"
      data-testid="confirm-yes-btn">
      {{ confirmText }}
    </button>
  </div>
</div>
