import { Component, Input, OnChanges, OnInit } from '@angular/core';
import {
  BoardGame,
  GamePieceSetup,
  GamePieceInstance,
  PLBoardGamesFactoryService,
} from '@modules/pl-games/pl-board-games/pl-board-games-factory.service';
import { Store } from '@ngrx/store';
import { AppState } from '@root/src/app/store';
import { GameActions, GameCategoryType } from '@modules/pl-games/store';
import { FirebaseService } from '@root/src/app/common/firebase/firebase.service';
import { Subscription } from 'rxjs';
import {
  CentralContentType,
  selectIsCurrentCentralContent,
} from '../../../../app/store';
@Component({
  selector: 'pl-board-game-controls',
  templateUrl: './pl-board-game-controls.component.html',
  styleUrls: ['./pl-board-game-controls.component.less'],
})
export class PLBoardGameControlsComponent implements OnInit, OnChanges {
  @Input() gameName: string;

  firebaseGamePath: string;
  boardGamesStateRef: any;
  currentGameInstanceRef: any;
  currentPieceSetups: any;

  game: BoardGame;
  currentGameKey: number;
  instances: any[];
  currentInstance: any;
  changeTimer: any;
  currentGameName: any;
  totalUsedPieceCount: number;
  loadingGame: any;
  trashingGame: boolean;
  gamesStateRef: any;
  boardGamesActive: any;
  gamesActive: boolean;
  isGameActive: boolean;
  openedGame = false;
  openedGameSub: Subscription;

  constructor(
    private store: Store<AppState>,
    private boardGameFactory: PLBoardGamesFactoryService,
    public firebaseService: FirebaseService,
  ) {}

  ngOnInit(): void {
    this.boardGamesStateRef =
      this.firebaseService.getRoomRef('games/boardGames');
    this.initFirebase();
  }

  private currentPathHandler = snap => {
    this.firebaseGamePath = snap.val();
    if (this.firebaseGamePath) {
      this.currentGameInstanceRef = this.firebaseService.getRoomRef(
        `games/boardGames/${this.firebaseGamePath}`,
      );
      this.updatePieceSetups();
      this.isGameActive = true;
    } else {
      this.isGameActive = false;
    }
  };

  initFirebase() {
    if (this.boardGamesStateRef) {
      this.boardGamesStateRef
        .child('currentPath')
        .off('value', this.currentPathHandler);
    }
    this.boardGamesStateRef
      .child('currentGame')
      .once('value', currentGameSnap => {
        const val = currentGameSnap.val();
        if (val) {
          this.gameName = val;
          this.currentGameName = val;
          this.game = this.boardGameFactory.getBoardGame(this.gameName);
          this.currentPieceSetups = [...this.game.pieceSetups];

          this.store.dispatch(
            GameActions.start({
              gameCategory: GameCategoryType.BoardGame,
              game: this.game,
            }),
          );

          this.boardGamesStateRef
            .child('currentPath')
            .on('value', this.currentPathHandler);

          this.boardGamesStateRef
            .child(this.game.name)
            .child('currentGameId')
            .once('value', snap => {
              this.currentGameKey = snap.val();
              this.updateRefs();
              this.updateInstances();
            });
        }
      });
  }

  private updateInstances(): Promise<void> {
    return new Promise(resolve => {
      const newInstances = [];
      this.boardGamesStateRef
        .child(this.game.name)
        .child('instances')
        .once('value', snap => {
          snap.forEach(childSnap => {
            const key = childSnap.key;
            const childData = childSnap.val();
            const instance = Object.assign({ key }, childData);
            instance.current = instance.key === this.currentGameKey;
            if (instance.current) {
              this.currentInstance = instance;
            }
            newInstances.push(instance);
          });
          newInstances.sort((a, b) => (a.modified < b.modified ? 1 : -1));
          this.instances = newInstances;
          resolve();
        });
    });
  }

  ngOnChanges(event) {
    if (this.openedGameSub) {
      this.openedGameSub.unsubscribe();
    }
    this.openedGameSub = this.store
      .select(
        selectIsCurrentCentralContent(CentralContentType.Game, {
          name: event.gameName.currentValue,
        }),
      )
      .subscribe(isOpen => {
        this.openedGame = isOpen;
        if (this.openedGame) {
          this.boardGamesStateRef.child('currentGame').set(this.gameName);
          this.initFirebase();
        }
      });

    if (event.gameName && this.gameName) {
      this.game = this.boardGameFactory.getBoardGame(this.gameName);
      this.currentPieceSetups = [...this.game.pieceSetups];
      this.boardGamesStateRef.child('browsingGame').set(this.game.name);
      if (event.gameName.currentValue === this.currentGameName) {
        this.updateRefs();
        this.initFirebase();
      }
      this.updateInstances();
    }
  }

  clearInstances() {
    this.instances.forEach(instance => {
      if (!instance.current) {
        this.boardGamesStateRef
          .child(this.game.name)
          .child('instances')
          .child(instance.key)
          .remove();
      }
    });
    setTimeout(() => {
      this.updateInstances();
    }, 100);
  }

  clearBoard() {
    this.currentGameInstanceRef.child('pieces').remove();
    this.totalUsedPieceCount = 0;
  }

  pieceClicked(pieceSetup: GamePieceSetup) {
    if (this.currentGameName !== this.gameName) {
      return;
    }
    if (pieceSetup.usedCount < pieceSetup.count) {
      const piece: GamePieceInstance =
        this.boardGameFactory.getGamePieceInstance(pieceSetup.type);
      this.store.dispatch(GameActions.addPiece({ piece }));
    } else if (pieceSetup.count === 1 && pieceSetup.usedCount >= 1) {
      this.findAndRemoveFirst(pieceSetup);
    }
    let newUsedCount = 0;
    this.currentPieceSetups.forEach(setup => {
      newUsedCount += setup.usedCount;
    });
    this.totalUsedPieceCount = newUsedCount;
  }
  findAndRemoveFirst(setup: GamePieceSetup) {
    let removeKey: any;
    this.currentGameInstanceRef.child('pieces').once('value', snap => {
      const pieces = snap.val();
      for (const key in pieces) {
        if (key) {
          const piece = pieces[key];
          if (piece.name === setup.type.name) {
            removeKey = key;
            break;
          }
        }
      }
      this.currentGameInstanceRef
        .child('pieces')
        .child(removeKey)
        .remove()
        .then(() => {
          setup.usedCount--;
        });
    });
  }

  loadGame(event) {
    if (!this.loadingGame) {
      this.loadingGame = true;
      this.currentGameKey = event.key;
      this.currentGameName = this.gameName;
      this.instances = this.instances.map(instance => {
        instance.current = instance.key === event.key;
        return instance;
      });
      this.updateRefs();
      this.updatePieceSetups();
      this.updateInstances();
    }

    // throttle by 1000ms
    setTimeout(() => (this.loadingGame = false), 1000);
  }

  trashGame(event) {
    this.trashingGame = true;
    const trashed = this.instances.find(instance => instance.key === event);
    if (trashed.current) {
      this.boardGamesStateRef.child('currentGame').remove();
      this.boardGamesStateRef.child('currentPath').remove();
      this.boardGamesStateRef
        .child(this.game.name)
        .child('currentGameId')
        .remove();
      this.currentGameName = '';
    }
    this.boardGamesStateRef
      .child(this.game.name)
      .child('instances')
      .child(event)
      .remove();
    this.updateInstances();
    // throttle by 500ms
    setTimeout(() => (this.trashingGame = false), 1000);
  }

  gameInstanceNameChanged(event) {
    const gamePath = `${this.game.name}/instances/${event.key}`;
    this.boardGamesStateRef.child(gamePath).child('name').set(event.name);
  }

  newGame() {
    if (!this.loadingGame) {
      this.loadingGame = true;
      this.currentGameName = this.gameName;
      this.store.dispatch(
        GameActions.start({
          gameCategory: GameCategoryType.BoardGame,
          game: this.game,
          isGameActive: true,
        }),
      );
      const newGameInstanceKey = this.boardGamesStateRef
        .child(this.game.name)
        .child('instances')
        .push().key;
      this.currentGameKey = newGameInstanceKey;
      this.updateInstances();
      this.updateRefs();
      this.setupNewGameInFirebase();
      this.updatePieceSetups();
    }
    // throttle by 1000ms
    setTimeout(() => (this.loadingGame = false), 1000);
  }

  endGame() {
    this.store.dispatch(GameActions.end());
    this.boardGamesStateRef
      .child(this.game.name)
      .child('currentGameId')
      .remove();
    this.isGameActive = false;
    this.currentGameName = '';
  }

  updateRefs() {
    this.boardGamesStateRef.child('currentGame').set(this.game.name);
    if (this.currentGameKey) {
      this.firebaseGamePath = `${this.game.name}/instances/${this.currentGameKey}`;
      this.boardGamesStateRef.child('currentPath').set(this.firebaseGamePath);

      this.boardGamesStateRef
        .child(this.game.name)
        .child('currentGameId')
        .set(this.currentGameKey);
      this.currentGameInstanceRef = this.firebaseService.getRoomRef(
        `games/boardGames/${this.firebaseGamePath}`,
      );
      this.currentGameInstanceRef.child('modified').on('value', snap => {
        setTimeout(() => {
          if (this.currentInstance) {
            this.currentInstance.modified = snap.val();

            this.instances.sort((a, b) => (a.modified < b.modified ? 1 : -1));
          }
        });
      });
    } else {
      this.boardGamesStateRef.child('currentPath').remove();
    }
  }

  setupNewGameInFirebase() {
    this.currentGameInstanceRef.child('name').set('');
    this.currentGameInstanceRef.child('isGameActive').set(true);
    if (this.game.board.imageSrc) {
      this.currentGameInstanceRef
        .child('gameBoardImgSrc')
        .set(this.game.board.imageSrc);
    }
    if (this.game.startRegion) {
      this.currentGameInstanceRef
        .child('startRegion')
        .set(this.game.startRegion);
    }
    this.updateInstances();
    this.currentGameInstanceRef.child('modified').set(new Date().getTime());
  }

  /**
   * When we switch games, check firebase to see if there are already pieces in play
   * and update used count appropriately
   */
  updatePieceSetups() {
    this.currentGameInstanceRef.child('pieces').on('value', snap => {
      const pieceTypeCounts = {};
      const pieces = snap.val();
      for (const key in pieces) {
        if (key) {
          const piece = pieces[key];
          const name = piece.name;
          if (pieceTypeCounts[name]) {
            pieceTypeCounts[name]++;
          } else {
            pieceTypeCounts[name] = 1;
          }
        }
      }
      let newUsedCount = 0;

      this.currentPieceSetups = this.currentPieceSetups.map(
        (setup: GamePieceSetup) => {
          const setupName = setup.type.name;
          const newSetup = { ...setup };
          newSetup.usedCount = pieceTypeCounts[setupName]
            ? pieceTypeCounts[setupName]
            : 0;
          newUsedCount += newSetup.usedCount;
          return newSetup;
        },
      );

      this.totalUsedPieceCount = newUsedCount;
    });
  }

  trackByPiece(_: number, piece: any) {
    return piece.type.name;
  }
}
