import { Injectable } from '@angular/core';
import { PLGraphQLService } from '@root/src/app/common/services/pl-graph-ql';
import { PLRecordRoomService } from '@root/src/app/common/services/pl-records';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ClientAppointment,
  IepGoal,
  IepServiceArea,
  MetricPoint,
} from '../documentation.models';

@Injectable({
  providedIn: 'root',
})
export class IepGoalsService {
  constructor(
    private plGraphQL: PLGraphQLService,
    private plRecordRoom: PLRecordRoomService,
  ) {}

  getIepAndInfo(clientId): Observable<IepServiceArea[]> {
    const vars = {
      clientId,
    };
    const query = `query ClientIEPs(
              $clientId: UUID!,
          ) {
              clientIeps(
                  clientId: $clientId
              ) {
                  totalCount
                  edges {
                      node {
                          id
                          status
                          startDate
                          serviceAreas {
                              edges {
                                  node {
                                      serviceType {
                                          id
                                          code
                                      }
                                      goals {
                                          edges {
                                              node {
                                                  id
                                                  description
                                                  metrics {
                                                      edges {
                                                          node {
                                                              id
                                                              name
                                                          }
                                                      }
                                                  }
                                              }
                                          }
                                      }
                                  }
                              }
                          }
                      }
                  }
              }
          }`;
    return this.plGraphQL.query(query, vars, {}).pipe(
      map((res: any) => {
        let iepServiceAreas: IepServiceArea[] = [];
        // Get active iep.
        for (let ii = 0; ii < res.clientIeps.length; ii++) {
          if (res.clientIeps[ii].status === 'ACTIVE') {
            iepServiceAreas = res.clientIeps[ii].serviceAreas;
          }
        }
        return iepServiceAreas;
      }),
    );
  }

  getGoalsForService(iepServiceAreas: IepServiceArea[], service) {
    let iepGoals: IepGoal[];
    for (let ii = 0; ii < iepServiceAreas.length; ii++) {
      if (
        iepServiceAreas[ii].serviceType.id === service.service.service_type.id
      ) {
        iepGoals = iepServiceAreas[ii].goals;
        break;
      }
    }
    return iepGoals;
  }

  getCombinedMetrics(goals: IepGoal[]) {
    const metrics = [];
    goals.forEach((goal, indexGoal) => {
      const shortName = `G${indexGoal + 1}`;
      goal.metrics.forEach(metric => {
        metrics.push(
          Object.assign(metric, {
            goal: {
              id: goal.id,
              description: goal.description,
              shortName: shortName,
            },
          }),
        );
      });
    });
    return metrics;
  }

  loadMetricPoints(
    metrics: any[],
    clientAppointment: ClientAppointment,
  ): Observable<MetricPoint[]> {
    return this.plRecordRoom
      .getMetrics(
        clientAppointment.record.client_service,
        clientAppointment.record.uuid,
        metrics,
      )

      .pipe(
        map((res: any) => {
          res.metricPoints.forEach((metricPoint: any) => {
            metricPoint.xSelected = false;
          });
          return res.metricPoints;
        }),
      );
  }
}
