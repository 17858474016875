import { Component, ElementRef, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@root/src/app/store';
import { Observable } from 'rxjs';
import { selectCanPromote } from '../../../store';
import { StreamDisplayNameService } from './display-name.service';

@Component({
  selector: 'pl-stream-display-name',
  templateUrl: 'display-name.component.html',
  styleUrls: ['display-name.component.less'],
  providers: [StreamDisplayNameService],
})
export class StreamDisplayNameComponent {
  @ViewChild('displayNameSpan') spanEl: ElementRef;

  public displayName$: Observable<string>;
  public showMuted$: Observable<boolean>;
  public showCovered$: Observable<boolean>;
  public showClickMuted$: Observable<boolean>;
  public canPromote$: Observable<boolean>;

  constructor(
    streamDisplayNameService: StreamDisplayNameService,
    store: Store<AppState>,
  ) {
    this.displayName$ = streamDisplayNameService.displayName$;
    this.showMuted$ = streamDisplayNameService.isMuted$;
    this.showClickMuted$ = streamDisplayNameService.isGuestClickMuted$;
    this.showCovered$ = streamDisplayNameService.isCovered$;
    this.canPromote$ = store.select(selectCanPromote);
  }

  get isLongDisplayName() {
    return (
      this.spanEl &&
      this.spanEl.nativeElement.offsetWidth <
        this.spanEl.nativeElement.scrollWidth
    );
  }
}
