import { Component, Input, OnInit } from '@angular/core';
import { Card, CardReveal, Player } from '../../clue-store';
import { Store } from '@ngrx/store';
import * as ClueActions from '../../clue-store/actions';

@Component({
  selector: 'submit-answers',
  templateUrl: './submit-answers.component.html',
  styleUrls: ['./submit-answers.component.less'],
})

export class PLClueSubmitAnswersComponent implements OnInit {
  @Input() currentUser: Player;
  @Input() cardReveal: CardReveal
  @Input() playersArr: Player[];
  @Input() conclusionScreenActive: boolean;
  @Input() firebaseClueRef;
  public isSelecting: boolean;
  public category: string;
  public answersObj: {
    suspect: Card,
    method: Card,
    location: Card
  };
  public canSubmit: boolean;
  private disabledChoice: boolean = false;
  
  constructor(
    private store: Store<any>
  ) {}

  ngOnInit(): void {
    this.checkAnswersObj();
    this.canSubmitAnswer();
  }

  private checkAnswersObj() {
    const currentPlayer = this.playersArr.filter((player) => {
      if (this.currentUser.userId === player.userId) {
        return player;
      }
    });

    if (currentPlayer.length > 0) {
      this.answersObj = currentPlayer[0].solvingTheCaseObj.answersObj
    }
  }

  public submitAnswers() {
    const tempCurrentUser = {
      ...this.currentUser,
      solvingTheCaseObj: {
        ...this.currentUser.solvingTheCaseObj,
        answersSubmitted: true,
        answersObj: { ...this.answersObj }
      }
    }

    const tempPlayersArr = this.playersArr.map((player) => {
      const tempPlayer = {
        ...player,
        solvingTheCaseObj: {
          ...player.solvingTheCaseObj
        }
      }
      if (tempPlayer.userId === tempCurrentUser.userId) {
        tempPlayer.solvingTheCaseObj.answersSubmitted = true;
        tempPlayer.solvingTheCaseObj.answersObj= { ...this.answersObj };
      }
      return tempPlayer;
    });

    this.disabledChoice = true;

    this.store.dispatch(ClueActions.updateCurrentUser({ currentUser: tempCurrentUser }));
    this.firebaseClueRef.update({ playersArr: tempPlayersArr });
  }

  public showSuspects(){
    if (this.disabledChoice) {
      return;
    }
    this.isSelecting = true;
    this.category = "Suspect";
  }

  public showMethods(){
    if (this.disabledChoice) {
      return;
    }
    this.isSelecting = true;
    this.category = "Method";
  }
  public showLocations(){
    if (this.disabledChoice) {
      return;
    }
    this.isSelecting = true;
    this.category = "Location";
  }

  public selectSuspect(e) {
    if (this.disabledChoice) {
      e.preventDefault();
      return;
    }
    this.answersObj = {
      ...this.answersObj,
      suspect: {
        name: e.target.getAttribute("card-name"),
        icon: e.target.getAttribute("card-icon"),
        status: ""
      }
    }
    this.canSubmitAnswer();
  }

  public selectMethod(e) {
    if (this.disabledChoice) {
      e.preventDefault();
      return;
    }
    this.answersObj = {
      ...this.answersObj,
      method: {
        name: e.target.getAttribute("card-name"),
        icon: e.target.getAttribute("card-icon"),
        status: ""
      }
    }
    this.canSubmitAnswer();
  }

  public selectLocation(e) {
    if (this.disabledChoice) {
      e.preventDefault();
      return;
    }

    this.answersObj = {
      ...this.answersObj,
      location: {
        name: e.target.getAttribute("card-name"),
        icon: e.target.getAttribute("card-icon"),
        status: ""
      }
    }

    this.canSubmitAnswer();
  }

  private canSubmitAnswer() {
    this.isSelecting = false;
    this.category = "";

    const { suspect, method, location } = this.answersObj;
    if ( suspect.name && method.name && location.name ) { this.canSubmit = true; }
  }

  public cancelSelection(){
    this.isSelecting = false;
    this.category = "";
  }
}
