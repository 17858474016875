import { Component } from '@angular/core';
import { StreamSettingsService } from '../stream-settings.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'pl-local-settings',
  templateUrl: 'local-settings.component.html',
  styleUrls: ['local-settings.component.less'],
})
export class LocalSettingsComponent {
  public streamIds$: Observable<string[]>;

  constructor(streamSettingsService: StreamSettingsService) {
    this.streamIds$ = streamSettingsService.streamIds$;
  }
}
