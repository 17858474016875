<header class="btn-container">
  <button
    mat-raised-button
    color="primary"
    class="w-100"
    (click)="openCreateNewQueue.emit()"
    data-testid="create-queue-button"
    [disabled]="isLoading">
    Create new queue
  </button>
</header>

<pl-sort-menu
  [disabled]="isLoading"
  [currentSortOption]="currentSortOption"
  (sortOptionSelected)="sortOrderChanged($event)"></pl-sort-menu>
<pl-loader class="padding" *ngIf="isLoading"></pl-loader>
<ul
  cdkDropList
  cdkDropListLockAxis="y"
  (cdkDropListDropped)="onQueueDrop($event)"
  class="queues-list">
  <li
    class="queue-item"
    cdkDrag
    [cdkDragDisabled]="currentSortOption.value !== SortType.Custom"
    *ngFor="let queueId of currentQueuesOrder"
    (click)="openQueue.emit({ queueId })">
    <div class="queue-item__row">
      <div class="queue-item__inner">
        <span class="queue-item__name">
          {{ queuesItems[queueId].name }}
        </span>
        <span class="queue-item__count">
          <ng-container
            *ngIf="
              queuesItems[queueId].order as activitiesOrder;
              else noActivites
            ">
            {{ activitiesOrder.length }}
            {{ activitiesOrder.length === 1 ? 'item' : 'items' }}
          </ng-container>
          <ng-template #noActivites> No items </ng-template>
        </span>
      </div>

      <button
        mat-icon-button
        class="queue-item__menu-btn"
        [matMenuTriggerFor]="menu"
        (click)="$event.stopPropagation()">
        <mat-icon>more_horiz</mat-icon>
      </button>
    </div>

    <pl-confirm-alert
      *ngIf="queueIdBeingDeleted === queueId"
      class="mt-3"
      (click)="$event.stopPropagation()"
      (cancel)="cancelDeleteQueue()"
      (confirm)="confirmDeleteQueue(queueId)">
      Are you sure you want to delete this queue?
    </pl-confirm-alert>

    <mat-menu #menu="matMenu" backdropClass="is-dark">
      <button
        mat-menu-item
        (click)="renameQueue(queueId)"
        data-testid="rename-queue-btn">
        <span>Rename queue</span>
      </button>
      <button
        mat-menu-item
        (click)="attemptDeleteQueue(queueId)"
        data-testid="delete-queue-btn">
        <span>Delete queue</span>
      </button>
    </mat-menu>
  </li>
</ul>
