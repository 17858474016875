import { Component, Input, OnInit } from '@angular/core';
import { Icon, materialIcons, svgIcons } from '../../types/icons';

@Component({
  selector: 'pl-icon-new',
  templateUrl: './pl-icon.component.html',
  styleUrls: ['./pl-icon.component.less'],
})
export class PLIconComponent implements OnInit {
  @Input() name: Icon;
  @Input() inline: string;

  isMaterialIcon = false;
  isSvgIcon = false;

  ngOnInit() {
    this.isMaterialIcon = materialIcons.includes(this.name as any);
    this.isSvgIcon = svgIcons.includes(this.name as any);

    if (!this.isMaterialIcon && !this.isSvgIcon) {
      throw new Error(`Icon ${this.name} not found`);
    }
  }
}
