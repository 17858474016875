import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SiteshareComponent } from './siteshare.component';
import { SiteshareService } from './siteshare.service';

@NgModule({
  imports: [CommonModule],
  exports: [SiteshareComponent],
  declarations: [SiteshareComponent],
  providers: [SiteshareService],
})
export class SiteshareModule {}
