import { Injectable } from '@angular/core';
import { TokboxConfig, ConferenceService } from '@common/conference';
import { LocalStream, StreamType } from './store';

export interface ConferenceConfig {
  tokbox?: TokboxConfig;
}

@Injectable()
export class RoomConferenceService extends ConferenceService {
  streamToJoinConfig(stream: LocalStream) {
    const { microphone, video, type } = stream;
    let audioSource = microphone
      ? this.resolveJoinSource(microphone.deviceId)
      : undefined;

    if (type === StreamType.secondary) {
      audioSource = false;
    }

    const videoSource = this.resolveJoinSource(video.deviceId);
    return {
      audioSource,
      videoSource,
      isMuted: microphone ? microphone.isMuted : true,
      isHidden: video.isHidden,
      filterMode: video.effects.filterMode,
    };
  }

  private resolveJoinSource(source?: string) {
    if (typeof source === 'undefined' || source === null) {
      return true;
    }

    return source;
  }
}
