import { Component, OnDestroy, OnInit } from '@angular/core';

import { Observable, Subscription, forkJoin } from 'rxjs';
import { User } from '@app/modules/user/user.model';
import { AppState } from '@app/store';
import { Store } from '@ngrx/store';

import { PLUrlsService } from '@common/services/pl-http';
import { selectAuth } from '@modules/user/store';

import { StudentItem } from './kanga-documentation.model';
import { KangaDocumentationService } from './kanga-documentation.service';

import {
  selectIsFreemiumOver,
  selectIsSessionInProgress,
} from '@room/freemium-status/store';
@Component({
  selector: 'pl-kanga-documentation',
  templateUrl: 'kanga-documentation.component.html',
  styleUrls: ['./kanga-documentation.component.less'],
})
export class KangaDocumentationComponent implements OnInit, OnDestroy {
  currentUser: User;
  isSessionInProgress$: Observable<boolean>;
  isFreemiumOver$: Observable<boolean>;
  private subscriptions: Subscription[] = [];

  events: StudentItem[];
  clients: StudentItem[];

  selectedStudent: StudentItem;
  lastSavedSessionItem: StudentItem;

  constructor(
    private store: Store<AppState>,
    private documentationService: KangaDocumentationService,
    private plUrls: PLUrlsService,
  ) {
    this.subscriptions.push(
      this.store.select(selectAuth).subscribe(({ isAuthenticated, user }) => {
        if (isAuthenticated) {
          this.currentUser = user;
        }
      }),
    );
    this.isSessionInProgress$ = this.store.select(selectIsSessionInProgress);
    this.isFreemiumOver$ = this.store.select(selectIsFreemiumOver);
  }
  ngOnDestroy(): void {
    throw new Error('Method not implemented.');
  }

  ngOnInit(): void {
    this.loadStudents();
    // Listen for tab switches and focus changes, update appointments
    // when the user comes back from their calendar.
    // We may want to use polling, but this seems to be effective.
    document.addEventListener('visibilitychange', () => {
      if (!document.hidden) {
        this.loadStudents();
      }
    });
    window.addEventListener('focus', () => {
      this.loadStudents();
    });
  }

  loadStudents() {
    const subs = [
      this.documentationService.getClients(),
      this.documentationService.getEvents(),
    ];
    this.subscriptions.push(
      forkJoin(subs).subscribe(results => {
        const clients = results[0];
        let events = [];
        results[1].forEach(event => {
          const clientEvents = event.clients.map(clientId => {
            const client = clients.find(c => c.uuid === clientId);
            return {
              event,
              client,
            };
          });
          events = events.concat(clientEvents);
        });
        events.sort(this.sortEvents);
        this.events = events;
        this.clients = clients.map(client => {
          return {
            client,
          };
        });
      }),
    );
  }

  private sortEvents(a: StudentItem, b: StudentItem) {
    const aDate = moment(a.event.start_date_local);
    const bDate = moment(b.event.start_date_local);
    if (aDate > bDate) {
      return 1;
    }
    if (aDate < bDate) {
      return -1;
    }
    if (a.client.last_name > b.client.last_name) {
      return 1;
    }
    if (a.client.last_name === b.client.last_name) {
      return a.client.first_name > b.client.first_name ? 1 : -1;
    }
    return -1;
  }

  onSelectStudent(item) {
    this.lastSavedSessionItem = null;
    this.selectedStudent = item;
  }

  onSessionEnded() {
    this.lastSavedSessionItem = this.selectedStudent;
    this.selectedStudent = null;
    this.loadStudents();
  }

  onOpenStudentProfile() {
    const url = `${this.plUrls.urls.therapyFE}/clients/details/${this.lastSavedSessionItem.client.uuid}/sessions/list`;
    window.open(url, '_blank');
  }
}
