import { props } from '@ngrx/store';
import { createAction } from '@common/utils';
import { Participant, sessionFeatureKey } from './session.model';
export interface UpdateParticipantPayload<T extends Participant> {
  id: string;
  participant: PartialDeep<Omit<T, 'id'>>;
}

export const initObserver = createAction(
  `/${sessionFeatureKey}/initObserver`,
  props<{ participant: Participant }>(),
);

export const triggerObserverInteraction = createAction(
  `/${sessionFeatureKey}/triggerObserverInteraction`,
);

export const addLocal = createAction(
  `/${sessionFeatureKey}/addLocal`,
  props<{ participant: Participant }>(),
);

export const addLocalSuccess = createAction(
  `/${sessionFeatureKey}/addLocalSuccess`,
  props<{ participant: Participant }>(),
);

export const addLocalError = createAction(
  `/${sessionFeatureKey}/addLocalError`,
  props<{ participant: Participant; error: any }>(),
);

export const addedRemotely = createAction(
  `/${sessionFeatureKey}/addedRemotely`,
  props<{ participant: Participant }>(),
);

export const updatedRemotely = createAction(
  `/${sessionFeatureKey}/updatedRemotely`,
  props<{ id: string; participant: Partial<Participant> }>(),
);

export const removedRemotely = createAction(
  `/${sessionFeatureKey}/removedRemotely`,
  props<{ id: string }>(),
);

export const setIsViewingPage = createAction(
  `/${sessionFeatureKey}/setIsViewingPage`,
  props<{ isViewingPage: boolean }>(),
);

export const setIsViewingPageSuccess = createAction(
  `/${sessionFeatureKey}/setIsViewingPageSuccess`,
  props<{ id: string; isViewingPage: boolean }>(),
);

export const setIsViewingPageError = createAction(
  `/${sessionFeatureKey}/setIsViewingPageError`,
  props<{ isViewingPage: boolean; error: any }>(),
);

export const addWaiting = createAction(
  `/${sessionFeatureKey}/addWaiting`,
  props<{ participant: Participant }>(),
);

export const addWaitingSuccess = createAction(
  `/${sessionFeatureKey}/addWaitingSuccess`,
  props<{ participant: Participant }>(),
);

export const addWaitingError = createAction(
  `/${sessionFeatureKey}/addWaitingError`,
  props<{ participant: Participant; error: any }>(),
);

export const admit = createAction(
  `/${sessionFeatureKey}/admit`,
  props<{ id: string }>(),
);

export const admitSuccess = createAction(
  `/${sessionFeatureKey}/admitSuccess`,
  props<{ id: string }>(),
);

export const admitError = createAction(
  `/${sessionFeatureKey}/admitError`,
  props<{ id: string; error: any }>(),
);

export const studentAdmissionFailed = createAction(
  `/${sessionFeatureKey}/studentAdmissionFailed`,
  props<{ id: string }>(),
);

export const studentAdmissionFailedSetSuccess = createAction(
  `/${sessionFeatureKey}/studentAdmissionFailedSetSuccess`,
  props<{ id: string }>(),
);

export const studentAdmissionFailedSetError = createAction(
  `/${sessionFeatureKey}/studentAdmissionFailedSetError`,
  props<{ id: string }>(),
);

export const kick = createAction(
  `/${sessionFeatureKey}/kick`,
  props<{ id: string }>(),
);

export const kickSuccess = createAction(
  `/${sessionFeatureKey}/kickSuccess`,
  props<{ id: string }>(),
);

export const kickError = createAction(
  `/${sessionFeatureKey}/kickError`,
  props<{ id: string; error: any }>(),
);

export const kicked = createAction(
  `/${sessionFeatureKey}/kicked`,
);

export const removeOfflineSuccess = createAction(
  `/${sessionFeatureKey}/removeOfflineSuccess`,
  props<{ id: string }>(),
);

export const removeOfflineError = createAction(
  `/${sessionFeatureKey}/removeOfflineError`,
  props<{ id: string; error: any }>(),
);

export const setYoutubeInteractionPending = createAction(
  `/${sessionFeatureKey}/setYoutubeInteractionPending`,
  props<{ isPending: boolean }>(),
);

export const setYoutubeInteractionPendingSuccess = createAction(
  `/${sessionFeatureKey}/setYoutubeInteractionPendingSuccess`,
  props<{ id: string; isPending: boolean }>(),
);

export const setYoutubeInteractionPendingError = createAction(
  `/${sessionFeatureKey}/setYoutubeInteractionPendingError`,
  props<{ isPending: boolean; error: any }>(),
);

export const setLocalOffline = createAction(
  `/${sessionFeatureKey}/setLocalOffline`,
  props<{ offline: boolean }>(),
);

export const leave = createAction(
  `/${sessionFeatureKey}/leave`,
);

export const kickDuplicateds = createAction(
  `/${sessionFeatureKey}/kickDuplicateds`,
);

export const kickDuplicatedsSuccess = createAction(
  `/${sessionFeatureKey}/kickDuplicatedsSuccess`,
);

export const kickDuplicatedsError = createAction(
  `/${sessionFeatureKey}/kickDuplicatedsError`,
  props<{ error: any }>(),
);

export const kickAllParticipants = createAction(
  `/${sessionFeatureKey}/kickAllParticipants`,
);

export const kickAllParticipantsSuccess = createAction(
  `/${sessionFeatureKey}/kickAllParticipantsSuccess`,
);

export const kickAllParticipantsError = createAction(
  `/${sessionFeatureKey}/kickAllParticipantsError`,
  props<{ error: any }>(),
);
