import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { DocumentationTEService } from './documentation-te.service';
import * as moment from 'moment';
import { StudentItem } from './documentation-te.model';
import { Store } from '@ngrx/store';
import { AppState } from '@root/src/app/store';
import {
  selectIsFreemiumOver,
  selectIsSessionInProgress,
} from '../../../freemium-status/store';
import { PLUrlsService } from '@common/services/pl-http';

@Component({
  selector: 'pl-documentation-te',
  templateUrl: 'documentation-te.component.html',
  styleUrls: ['./documentation-te.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class DocumentationTEComponent implements OnInit, OnDestroy {
  selectedStudent: StudentItem;
  lastSavedSessionItem: StudentItem;
  isSessionInProgress$: Observable<boolean>;
  isFreemiumOver$: Observable<boolean>;
  studentFilter = '';
  filteredClients = [];
  filteredEvents = [];
  private events: StudentItem[] = [];
  private clients: StudentItem[] = [];
  private subscriptions: Subscription[] = [];

  constructor(
    private documentationService: DocumentationTEService,
    private store: Store<AppState>,
    private plUrls: PLUrlsService,
  ) {
    this.isSessionInProgress$ = this.store.select(selectIsSessionInProgress);
    this.isFreemiumOver$ = this.store.select(selectIsFreemiumOver);
  }

  ngOnInit() {
    this.loadStudents();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  loadStudents() {
    const subs = [
      this.documentationService.getClients(),
      this.documentationService.getEvents(),
    ];
    this.subscriptions.push(
      forkJoin(subs).subscribe(results => {
        const clients = results[0];
        let events = [];
        results[1].forEach(event => {
          const clientEvents = event.clients.map(clientId => {
            const client = clients.find(c => c.uuid === clientId);
            return {
              event,
              client,
            };
          });
          events = events.concat(clientEvents);
        });
        events.sort(this.sortEvents);
        this.events = events;
        this.clients = clients.map(client => {
          return {
            client,
          };
        });
        this.filteredEvents = this.events;
        this.filteredClients = this.clients;
      }),
    );
  }

  getStudentDisplayLabel(item) {
    let label = `${item.client.last_name}, ${item.client.first_name}`;
    if (item.event) {
      const startTime = moment(item.event.start_date_local).format('h:mma');
      label = `${startTime}, ${label}`;
    }
    return label;
  }

  onSelectStudent(item) {
    this.lastSavedSessionItem = null;
    this.selectedStudent = item;
  }

  onSessionEnded() {
    this.lastSavedSessionItem = this.selectedStudent;
    this.selectedStudent = null;
    this.loadStudents();
  }

  onOpenStudentProfile() {
    const url = `${this.plUrls.urls.therapyFE}/clients/details/${this.lastSavedSessionItem.client.uuid}/sessions/list`;
    window.open(url, '_blank');
  }

  stopPropagation($event) {
    $event.stopPropagation();
  }

  filterStudentsList() {
    this.filteredClients = this.clients.filter(c => {
      const fullName = `${c.client.last_name} ${c.client.first_name}`;
      return fullName.toLowerCase().includes(this.studentFilter.toLowerCase());
    });
    this.filteredEvents = this.events.filter(c => {
      const fullName = `${c.client.last_name} ${c.client.first_name}`;
      return fullName.toLowerCase().includes(this.studentFilter.toLowerCase());
    });
  }

  private sortEvents(a: StudentItem, b: StudentItem) {
    const aDate = moment(a.event.start_date_local);
    const bDate = moment(b.event.start_date_local);
    if (aDate > bDate) {
      return 1;
    }
    if (aDate < bDate) {
      return -1;
    }
    if (a.client.last_name > b.client.last_name) {
      return 1;
    }
    if (a.client.last_name === b.client.last_name) {
      return a.client.first_name > b.client.first_name ? 1 : -1;
    }
    return -1;
  }
}
