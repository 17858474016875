import {
  Component,
  OnInit,
  ViewEncapsulation,
  OnDestroy,
  ViewChild,
  ElementRef,
  NgZone,
} from '@angular/core';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store';
import { ResizeObserverService } from '@common/services';
import { LayoutMode, selectLayoutMode } from '@room/app/store';
import { selectConferenceStreamsInSession } from '../../store';
import { StreamBoxService } from './stream-box.service';
import {
  FeatureFlag,
  FeatureFlagsService,
} from '@root/src/app/common/services/feature-flags';

import { ConferenceActions } from '@conference/store';
@Component({
  selector: 'pl-stream-box',
  templateUrl: 'stream-box.component.html',
  styleUrls: ['stream-box.component.less'],
  providers: [StreamBoxService, ResizeObserverService],
  encapsulation: ViewEncapsulation.None,
})
export class StreamBoxComponent implements OnInit, OnDestroy {
  @ViewChild('toolbar') toolbar: ElementRef;
  public isPromoted$: Observable<boolean>;
  public layoutMode$: Observable<LayoutMode>;
  public styles: any = {};
  subs: Subscription[] = [];

  layoutSizes: {
    topBarHeight: number;
    leftDrawerWidth: number;
    rightDrawerWidth: number;
    rightToolbarWidth: number;
  };

  constructor(
    private streamBoxService: StreamBoxService,
    private store: Store<AppState>,
    private resizeObserverService: ResizeObserverService,
    private zone: NgZone,
    private featureFlagsService: FeatureFlagsService,
  ) {}

  ngOnInit() {
    this.setLayoutSizes();
    this.init();
  }

  setLayoutSizes() {
    const rootEl = document.querySelector(':root');
    const styles = getComputedStyle(rootEl);

    this.layoutSizes = {
      topBarHeight: parseInt(styles.getPropertyValue('--s-top-bar-height'), 10),
      leftDrawerWidth: parseInt(
        styles.getPropertyValue('--s-left-drawer-width'),
        10,
      ),
      rightDrawerWidth: parseInt(
        styles.getPropertyValue('--s-right-drawer-width'),
        10,
      ),
      rightToolbarWidth: parseInt(
        styles.getPropertyValue('--s-right-toolbar-width'),
        10,
      ),
    };
  }

  init() {
    this.isPromoted$ = this.streamBoxService.isPromoted$;
    this.layoutMode$ = this.store.select(selectLayoutMode);
    const streams$ = this.store.select(selectConferenceStreamsInSession);
    const element = document.getElementsByClassName('conference')[0];
    combineLatest([
      streams$,
      this.streamBoxService.id$,
      this.layoutMode$,
      this.resizeObserverService.observe(element),
      this.isPromoted$,
    ])
      .pipe(
        map(([streams, id, layoutMode, entry, _promoted]) => {
          const leftDrawerSize = this.layoutSizes.leftDrawerWidth;
          const toolbarHeight = this.layoutSizes.topBarHeight;
          const videoBoxHeight = 159;

          const compactToolbarHeight =
            this.toolbar.nativeElement.childElementCount > 0
              ? toolbarHeight
              : 0;

          const compactHeight = videoBoxHeight + compactToolbarHeight;

          if (layoutMode === LayoutMode.compact) {
            return {
              width: '100%',
              height: `${compactHeight}px`,
            };
          }

          const currentStream = streams.find(stream => stream.id === id);

          if (!currentStream?.isPromoted) {
            return {
              width: `${leftDrawerSize}px`,
              height: `${compactHeight}px`,
            };
          }

          const width = entry.contentRect.width;
          const height = entry.contentRect.height;
          const promotedStreams = streams.filter(s => s.isPromoted);
          const allPromoted = promotedStreams.length === streams.length;
          const count = promotedStreams.length;
          const numCols = Math.ceil(Math.sqrt(count));
          const numRows = Math.ceil(count / numCols);
          const leftMargin = allPromoted ? 25 : leftDrawerSize + 25;
          const fullWidth = width - leftMargin;
          const fullHeight = height;
          let boxWidth = fullWidth / numCols;
          let boxHeight = 0.75 * boxWidth + toolbarHeight;

          if (boxHeight * numRows > fullHeight) {
            boxHeight = fullHeight / numRows;
            boxWidth = 1.33 * (boxHeight - toolbarHeight);
          }

          const xMargin = (fullWidth - boxWidth * numCols) / 2;
          const yMargin = (fullHeight - boxHeight * numRows) / 2;

          const boxMargin = 25;
          const index = promotedStreams.indexOf(currentStream);
          const styles: any = {};

          const currentColumn = index % numCols;
          const currentRow = Math.floor(index / numCols);

          let locY = yMargin;
          let locX = xMargin + leftMargin;
          locX += boxWidth * currentColumn;
          locY += boxHeight * currentRow;

          styles.maxHeight = '100%';
          styles.maxWidth = '100%';
          styles.position = 'absolute';
          styles.top = `${locY}px`;
          styles.left = `${locX}px`;
          styles.width = `${boxWidth - boxMargin}px`;
          styles.height = `${boxHeight - boxMargin}px`;

          return styles;
        }),
      )
      .subscribe((styles: any) => {
        this.zone.run((): void => {
          this.styles = styles;
        });
      });

    const enabled$ = this.featureFlagsService.isFeatureEnabled(
      FeatureFlag.BACKGROUND_BLURRING,
    );
    this.subs.push(
      combineLatest([
        enabled$,
        this.streamBoxService.id$,
        this.streamBoxService.isLocal$,
      ]).subscribe(([enabled, id, local]) => {
        if (!enabled && local) {
          this.store.dispatch(
            ConferenceActions.clearFilter({
              id,
            }),
          );
        }
      }),
    );
  }

  ngOnDestroy() {
    this.resizeObserverService.disconnect();
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
