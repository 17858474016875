import { Injectable } from '@angular/core';
import { DRFActivityModel } from '@common/models/DRF/DRFActivityModel.service';
import { DRFAssessmentModel } from '@common/models/DRF/DRFAssessmentModel.service';
import { DRFLessonModel } from '@common/models/DRF/DRFLessonModel.service';
import { FirebaseModel } from '@common/models/firebase/FirebaseModel';
import { ChannelFactoryService } from '@common/services/channel.service';
import { RightsMgmtService } from '@common/services/RightsMgmtService';
import { PLHttpService, PLUrlsService } from '@common/services/pl-http';
import { BehaviorSubject } from 'rxjs';
import { PLActivityModelService } from './activity-model.service';
@Injectable()
export class PLLessonModelService extends PLActivityModelService {
  pageRef: any;
  preferencesRef: any;

  DEFAULT_SCROLL_X = 0;
  DEFAULT_SCROLL_Y = 0;
  DEFAULT_OPACITY = 0.6;

  callbackRegistry: any = new Set();

  allowToggleInstructions = true;
  showInstructions = false;
  instructionOpacity = 0.6;

  scrollXPercent = 0;
  scrollYPercent = 0;
  private changedSubject = new BehaviorSubject(null);
  changed$ = this.changedSubject.asObservable();
  private currentSessionId;

  get sessionId() {
    return this.currentSessionId;
  }

  set sessionId(value) {
    this.currentSessionId = value;
    this.loadState();
  }

  constructor(
    drfActivityModel: DRFActivityModel,
    drfAssessmentModel: DRFAssessmentModel,
    private drfLessonModel: DRFLessonModel,
    firebaseModel: FirebaseModel,
    private rightsMgmtService: RightsMgmtService,
    ChannelService: ChannelFactoryService,
    protected plUrls: PLUrlsService,
    protected plHttp: PLHttpService,
  ) {
    super(
      firebaseModel,
      drfActivityModel,
      drfAssessmentModel,
      ChannelService,
      plUrls,
      plHttp,
    );
  }

  /***************************
   * overridden functions
   ***************************/
  reset() {
    this.clearCallbackRegistry(); // this must be before any persisted value changes
    return super.reset(); // this must be last
  }

  /**
   * Setup the load and value handlers for the remote object
   */
  initialize(model, token = null) {
    this.getActivityConfig(model, token);
    return this;
  }

  getActivityConfig(model, token = null) {
    this.configModel = this.drfLessonModel;

    this.configModel.init();
    this.configModel.setKey(this.getActivityId());
    this.configErrorCode = null;

    this.configModel.get(token).subscribe(
      () => this.handleConfigValue(this.configModel.model),
      error => {
        if (error.status === 404 || error.status === 403) {
          this.configErrorCode = error.status;
          this.handleConfigError(error);
        }
      },
    );
    return this;
  }

  /**
   * Handlers
   */
  handleConfigValue = config => {
    this.activity = Object.assign(this.activity, config);
    this.loadResolver(this);
  };

  /***************************
   * rights mgmt
   ***************************/

  getProtectedContentUrl(key) {
    return this.rightsMgmtService.getProtectedContentUrl(
      this.getActivityId(),
      key,
      null,
      true,
    );
  }

  cachedProtectedAsset(key) {
    return this.rightsMgmtService.assets[key];
  }

  setScrollXPercent(value) {
    this.scrollXPercent = value;
  }

  getScrollXPercent() {
    return this.scrollXPercent;
  }

  setScrollYPercent(value) {
    this.scrollYPercent = value;
  }

  getScrollYPercent() {
    return this.scrollYPercent;
  }

  /***************************
   * firebase mutation methods
   ***************************/
  saveScrollPosition(scrollX, scrollY) {
    try {
      this.pageRef.update({ scrollXPercent: scrollX, scrollYPercent: scrollY });
    } catch (err) {
      console.error('[LessonModel] Error in saveScrollPosition: ', err);
    }
  }

  saveZoom(value) {
    this.pageRef.child('zoom').set(value);
  }

  savePage(page) {
    try {
      this.pageRef.update(page);
    } catch (err) {
      console.error('[LessonModel] Error in savePage: ', err);
    }
  }

  /***************************
   * firebase listeners
   ***************************/
  loadState() {
    this.preferencesRef = this.firebaseModel.getFirebaseRef(
      `activities/sessions/${this.sessionId}/${this.activity.configId}/preferences`,
    );

    this.pageRef = this.firebaseModel.getFirebaseRef(
      `activities/sessions/${this.sessionId}`,
    );

    const eventType = 'value';

    let ref = this.pageRef.child('pageNum');
    this.registerCallback(
      ref,
      eventType,
      ref.on(eventType, this.handlePageChange, this),
      null,
    );

    ref = this.pageRef.child('zoom');
    this.registerCallback(
      ref,
      eventType,
      ref.on(eventType, this.handleZoomChange, this),
      null,
    );

    ref = this.pageRef.child('offset');
    this.registerCallback(
      ref,
      eventType,
      ref.on(eventType, this.handleOffsetChange, this),
      null,
    );

    ref = this.pageRef.child('scrollXPercent');
    this.registerCallback(
      ref,
      eventType,
      ref.on(eventType, this.handleScrollXPercent, this),
      null,
    );

    ref = this.pageRef.child('scrollYPercent');
    this.registerCallback(
      ref,
      eventType,
      ref.on(eventType, this.handleScrollYPercent, this),
      null,
    );
  }

  private handlePageChange(snap) {
    this.dispatchEvent(snap, snap.val(), 'value', 'pageChangeEvent');
  }

  private handleZoomChange(snap) {
    this.dispatchEvent(snap, snap.val(), 'value', 'zoomChangeEvent');
  }

  private handleOffsetChange(snap) {
    this.dispatchEvent(snap, snap.val(), 'value', 'offsetChangeEvent');
  }

  private handleScrollXPercent(snap) {
    this.setScrollXPercent(this.getScrollXFromSnap(snap));
    this.dispatchEvent(
      snap,
      this.getScrollXPercent(),
      'value',
      'scrollXChangeEvent',
    );
  }

  private handleScrollYPercent(snap) {
    this.setScrollYPercent(this.getScrollYFromSnap(snap));
    this.dispatchEvent(
      snap,
      this.getScrollYPercent(),
      'value',
      'scrollYChangeEvent',
    );
  }

  dispatchEvent(snap, data, srcType, targetType) {
    const event: any = {};
    event.type = targetType;
    event.data = data;
    event._snapshot = snap; // convenience
    event.sessionId = this.sessionId;
    this.changedSubject.next(event);
  }

  private registerCallback(ref, event, callback, context) {
    this.callbackRegistry.add({ ref, event, callback, context });
  }

  /**
   * Clean up all firebase listeners that were registered.
   * @private
   */
  private clearCallbackRegistry() {
    if (!this.callbackRegistry) {
      this.callbackRegistry = new Set();
    }
    for (const item of this.callbackRegistry.values()) {
      try {
        item.ref.off(item.event, item.callback, this);
      } catch (error) {
        console.warn(error);
      }
    }
    this.callbackRegistry.clear();
  }

  /***************************
   * util
   ***************************/
  private getValueFromSnap(snap, key, defaultValue) {
    const val = snap.val();
    let newVal = val || defaultValue;
    if (val !== null && typeof val === 'object') {
      newVal = val[key];
    }
    return newVal || defaultValue;
  }

  private getScrollXFromSnap(snap) {
    const scrollXPercent = this.getValueFromSnap(
      snap,
      this.fbScrollXPercentPath(),
      this.DEFAULT_SCROLL_X,
    );
    return scrollXPercent;
  }

  private getScrollYFromSnap(snap) {
    const scrollYPercent = this.getValueFromSnap(
      snap,
      this.fbScrollYPercentPath(),
      this.DEFAULT_SCROLL_Y,
    );
    return scrollYPercent;
  }

  private fbScrollXPercentPath() {
    return 'scrollXPercent';
  }

  private fbScrollYPercentPath() {
    return 'scrollYPercent';
  }
}
