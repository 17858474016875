<div class="pl-help-drawer">
  <div class="help-menu-item support-chat-button" (click)="onSupportChat()">
    <div class="help-item-icon margin">
      <mat-icon>support_agent</mat-icon>
    </div>
    <div class="help-item-text margin-r margin-l">
      <div class="help-item-title">Support</div>
      <div class="help-item-subtitle">
        Having tech issues? Chat with a real person right now.
      </div>
    </div>
  </div>
  <div
    class="help-menu-item help-center-button"
    *ngIf="isProvider$ | async"
    (click)="onHelpCenter()"
  >
    <div class="help-item-icon margin">
      <mat-icon>help</mat-icon>
    </div>
    <div class="help-item-text margin-r margin-l">
      <div class="help-item-title">Knowledge Base</div>
      <div class="help-item-subtitle">
        Watch video tutorials and read user guides.
      </div>
    </div>
  </div>
  <div
    class="help-menu-item room-tips-button"
    *ngIf="isProvider$ | async"
    (click)="onStartTour()"
  >
    <div class="help-item-icon margin">
      <mat-icon>lightbulb</mat-icon>
    </div>
    <div class="help-item-text margin-r margin-l">
      <div class="help-item-title">Room tips</div>
      <div class="help-item-subtitle">Review therapy room features</div>
    </div>
  </div>
  <div
    class="help-menu-item telehealth-institute-button"
    *ngIf="(isProvider$ | async) && !(isLimitedSaasAccess$ | async)"
    (click)="onTelehealthInstitute()"
  >
    <div class="help-item-icon margin">
      <mat-icon>school</mat-icon>
    </div>
    <div class="help-item-text margin-r margin-l">
      <div class="help-item-title">Telehealth Institute</div>
      <div class="help-item-subtitle">
        Become an expert with case studies, practice exercises, and
        demonstrations.
      </div>
    </div>
  </div>
  <div class="help-menu-item tech-check-button" (click)="onComputerSetup()">
    <div class="help-item-icon margin">
      <mat-icon>assignment_turned_in</mat-icon>
    </div>
    <div class="help-item-text margin-r margin-l">
      <div class="help-item-title">Tech Check</div>
      <div class="help-item-subtitle">
        Confirm your computer and equipment are ready to work.
      </div>
    </div>
  </div>
  <div class="margin">
    <mat-divider></mat-divider>
  </div>
  <div class="margin" (click)="onFirebaseClick($event)">
    <span>Version: {{ version }}</span>
  </div>
</div>
