<div class="recording-status">
  <button
    mat-stroked-button
    [matMenuTriggerFor]="recordingMenu"
    *ngIf="isRecording$ | async">
    <mat-icon color="warn">circle</mat-icon>
    <span>REC</span>
  </button>
</div>

<mat-menu
  class="recording-menu"
  #recordingMenu="matMenu"
  xPosition="before"
  yPosition="below">
  <header class="recording-menu__header">
    <h3 class="heading-3">Session Recording</h3>
  </header>

  <div class="recording-menu__content">
    <div class="recording-menu__text">
      You are currently recording this session.
    </div>
    <button mat-flat-button color="warn" (click)="stopRecording()">
      Stop recording
    </button>
  </div>
</mat-menu>
