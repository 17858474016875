<div
  class="memorycard-board"
  #memoryCardBoard
  (resized)="asyncResize()"
  [plDrop]="dropCard"
  (dropped)="dropCard($event)"
  (dragOver)="dragCard($event)"
>
  <div class="memory-game-container memorycard-scale-wrapper hide">
    <div [ngClass]="getContainerClass()">
      <div
        *ngFor="let card of cards.toArray(); let $index = index; trackBy: getId"
        [ngClass]="getCardClass($index)"
        [ngStyle]="getCardStyle($index)"
        (click)="onCardClick($event, $index)"
      >
        <div class="cover"></div>
        <div class="front">
          <img
            *ngIf="card.thumbnail_url"
            class="image"
            [src]="card.thumbnail_url"
          />
          <div class="title">
            <label>{{ card.title }}</label>
          </div>
        </div>
      </div>
      <div class="position-marker" [ngStyle]="markerStyle"></div>
    </div>

    <div class="side">
      <input
        type="button"
        class="btn end-turn"
        [ngClass]="{ zero: turnsLeft === 0 }"
        [value]="endTurnTitle()"
        [disabled]="
          !isGameActive || cards.players.length < 1 || !cards.hasTurns()
        "
        (click)="endTurn()"
        *ngIf="isClinician()"
      />

      <div class="flips-counter" *ngIf="cards.players.length">
        {{ flipsStatusMessage }}
      </div>

      <div
        class="avatar-container"
        *ngFor="let player of cards.players; let $index = index"
      >
        <div
          class="user-avatar {{ player.color }}"
          [ngClass]="{ active: cards.turn === $index && cards.hasTurns() }"
        >
          <div class="box">
            <div class="icon"></div>
            <div class="name">{{ player.name }}</div>
            <div class="score">{{ getPlayerScore($index) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
