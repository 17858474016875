import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
@Component({
  selector: 'pl-colorpicker',
  templateUrl: './colorpicker.component.html',
  styleUrls: ['./colorpicker.component.less'],
})
export class ColorpickerComponent {
  wheelVisible = false;
  hoverColor = '';

  @Input() color = '';
  @Input() label = '';
  @Output() readonly colorSelected = new EventEmitter<string>();

  @HostListener('document:click', ['$event']) onMouseDown(ev: PointerEvent) {
    if (!this.el.nativeElement.contains(ev.target)) {
      this.setVisible(false);
    }
  }

  constructor(private el: ElementRef) {}

  colorClicked(colorEvent) {
    this.color = colorEvent;
    this.colorSelected.emit(colorEvent);
    this.setVisible(false);
  }

  toggleVisible = () => {
    this.setVisible(!this.wheelVisible);
  }

  setVisible (val) {
    this.wheelVisible = val;
  }
}
