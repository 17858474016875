import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class PLGuessWhoService {
  public xRayStatus$ = new BehaviorSubject<boolean>(false);
  public autoSelectStatus$ = new BehaviorSubject<boolean>(false);
  public gameStarted$ = new BehaviorSubject<boolean>(false);

  public setXray(value): void {
    this.xRayStatus$.next(value);
  }

  public setAutoSelect(value): void {
    this.autoSelectStatus$.next(value);
  }

  public setGameStarted(value): void {
    this.gameStarted$.next(value);
  }
}
