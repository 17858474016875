import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PLCandyLandBoardComponent } from './candyland-board/pl-candyland-board.component';
import { PLCandyLandDrawerComponent } from './candyland-drawer/pl-candyland-drawer.component';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { PLGamesModule } from '@root/src/app/modules/pl-games';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { GameStartEndBtnsModule } from '../game-start-end-btns';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [
    CommonModule,
    MatSelectModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    PLGamesModule,
    DragDropModule,
    GameStartEndBtnsModule,
  ],
  exports: [PLCandyLandBoardComponent, PLCandyLandDrawerComponent],
  declarations: [PLCandyLandBoardComponent, PLCandyLandDrawerComponent],
  providers: [],
})
export class PLCandyLandModule {}
