import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

@Directive({
  selector: '[plDrag]',
})
export class PLDragDirective {
  @Input() plDragData: any;
  @Input() plDrag: any;
  @Output() public readonly dragStart = new EventEmitter<{
    event: any;
    data: any;
  }>();

  constructor(private el: ElementRef) {
    this.el.nativeElement.draggable = true;
  }

  @HostListener('dragstart', ['$event']) onDragStart = e => {
    this.dragStart.emit({ event: e, data: this.plDragData });
  };
}
