<div class="pdf-paginator">
  <div class="pdf-paginator__pages">
    <input
      (keyup)="handleGoToPage($event)"
      (blur)="$event.target.value = page"
      class="pdf-paginator__input"
      data-testid="paginator-input"
      type="text"
      [value]="page"
    />
    <span>/ {{ totalPages }}</span>
  </div>
  <span class="spacer"></span>
  <div class="pdf-paginator__buttons">
    <button
      (click)="handleGoToPrevPage()"
      matRipple
      class="pdf-paginator__button"
      data-testid="prev-page-button"
      [disabled]="!canGoToPrevPage()"
    >
      <mat-icon>expand_less</mat-icon>
      <span>Previous</span>
    </button>
    <button
      (click)="handleGoToNextPage()"
      class="pdf-paginator__button"
      data-testid="next-page-button"
      matRipple
      [disabled]="!canGoToNextPage()"
    >
      <mat-icon>expand_more</mat-icon>
      <span>Next</span>
    </button>
  </div>
</div>
