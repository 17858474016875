<div
  class="pdf-pages"
  #pdfPages
  (scroll)="!hasThumbs && throttleRenderThumbnails()"
>
  <mat-progress-bar
    mode="indeterminate"
    *ngIf="isLoading"
    class="pdf-pages__loader"
  ></mat-progress-bar>
  <ng-container *ngIf="bookMode === bookModeEnum.SINGLE_PAGE_MODE">
    <div
      *ngFor="let page of pages"
      role="button"
      class="pdf-page js-pdf-page"
      data-testid="pdf-page"
      [class.is-active]="page === currentPage"
      (click)="handleGoToPage(page)"
    >
      <div class="pdf-page__media js-media-container">
        <mat-spinner
          *ngIf="isPageLoading[page]"
          diameter="24"
          class="pdf-page__loader"
        ></mat-spinner>
        <img
          [src]="thumbs[page - 1]"
          [ngStyle]="getThumbStyle(page)"
          *ngIf="hasThumbs"
        />
        <canvas *ngIf="!hasThumbs" id="pageCanvas{{ page }}"></canvas>
        <mat-icon
          *ngIf="thumbnailIcon"
          [svgIcon]="thumbnailIcon"
          class="pdf-page__media-icon"
        ></mat-icon>
      </div>
      <div class="pdf-page__page-num">
        {{ page }}
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="bookMode !== bookModeEnum.SINGLE_PAGE_MODE">
    <div
      *ngFor="let page of doublePages"
      role="button"
      class="pdf-page js-pdf-page"
      data-testid="pdf-page"
      [class.is-active]="page[0] === currentPage || page[1] === currentPage"
      (click)="handleGoToPage(page[0])"
    >
      <div
        class="pdf-page__media js-pdf-page-media"
        [class.has-two-pages]="page[1]"
      >
        <mat-spinner
          *ngIf="isPageLoading[page[0]] || isPageLoading[page[1]]"
          diameter="24"
          class="pdf-page__loader"
        ></mat-spinner>
        <ng-container *ngIf="hasThumbs; else noThumbs">
          <div class="thumb-wrapper">
            <img
              [src]="thumbs[page[0] - 1]"
              [ngStyle]="getThumbStyle(page[0], page[1] > 0)"
            />
          </div>
          <div class="thumb-wrapper" *ngIf="page[1]">
            <img
              [src]="thumbs[page[1] - 1]"
              [ngStyle]="getThumbStyle(page[1], true)"
            />
          </div>
        </ng-container>
        <ng-template #noThumbs>
          <canvas id="pageCanvas{{ page[0] }}"></canvas>
          <canvas *ngIf="page[1]" id="pageCanvas{{ page[1] }}"></canvas>
        </ng-template>
      </div>
      <div class="pdf-page__page-num">
        <span>{{ page[0] }}</span>
        <span *ngIf="page[1]">{{ page[1] }}</span>
      </div>
    </div>
  </ng-container>
</div>
