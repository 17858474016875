import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store';
import { selectPreventParticipantDismiss } from '@room/session/store';
import { ConferenceActions } from '@room/conference/store';
import { ConferenceStreamService } from '../../conference-stream.service';

@Injectable()
export class DismissService {
  canDismiss$: Observable<boolean>;
  constructor(
    private conferenceStreamService: ConferenceStreamService,
    private store: Store<AppState>,
  ) {
    this.canDismiss$ = conferenceStreamService.participantId$.pipe(
      switchMap(id => store.select(selectPreventParticipantDismiss(id))),
    );
  }

  dismiss() {
    this.store.dispatch(
      ConferenceActions.dismiss({
        id: this.conferenceStreamService.getId(),
      }),
    );
  }
}
