import { Component } from '@angular/core';
import { LiveagentService } from '../../services/LiveagentService';

@Component({
  selector: 'pl-window-warning-dialog',
  templateUrl: 'window-warning-dialog.component.html',
  styleUrls: ['./window-warning-dialog.component.less'],
})
export class PLWindowWarningDialogComponent {
  constructor(private liveAgentService: LiveagentService) {}

  onStartChat() {
    this.liveAgentService.startChat();
  }
}
