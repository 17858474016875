<div class="delete-info">
  {{ hardDeleteTime }} {{ hardDeleteUnit }} to restore queue.
</div>

<ul class="queues-list">
  <li class="queue-item" *ngFor="let queue of queues">
    <span class="queue-item__name"> {{ queue.name }} </span>

    <span class="queue-item__count">
      <ng-container *ngIf="queue.order as activitiesOrder; else noActivites">
        {{ activitiesOrder.length }}
        {{ activitiesOrder.length === 1 ? 'item' : 'items' }}
      </ng-container>
      <ng-template #noActivites> No items </ng-template>
    </span>

    <div class="queue-item__restore-info">
      <span>Deleted {{ queue.deletedDate | date : 'M/dd/YY' }}</span>
      <button
        mat-button
        color="primary"
        class="is-small"
        (click)="restoreQueue.emit({ queueId: queue.id })"
      >
        Restore
      </button>
    </div>
  </li>
</ul>
