import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PLClueBoardComponent } from './clue-board/pl-clue-board.component';
import { PLClueDrawerComponent } from './clue-drawer/pl-clue-drawer.component';
import { PLClueInvestigationItemsComponent } from './clue-board/investigation-items/investigation-items.component';
import { PLCluePlayerHandComponent } from './clue-board/player-hand/player-hand.component';
import { PLClueSubmitAnswersComponent } from './clue-board/submit-answers/submit-answers.component';
import { PLClueConfidentialFolderScreenComponent } from './clue-board/confidential-folder-screen/confidential-folder-screen.component';
import { PLClueConclusionScreenComponent } from './clue-board/conclusion-screen/conclusion-screen.component';
import { PLClueOverviewInstructionsComponent } from './clue-board/overview-instructions/overview-instructions.component';
import { StoreModule } from '@ngrx/store';
import { reducer } from './clue-store';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { GameStartEndBtnsModule } from '../game-start-end-btns';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatButtonModule } from '@angular/material/button';
import { PLCommonComponentsModule } from '@common/components';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('clueState', reducer),
    FormsModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatIconModule,
    MatSelectModule,
    GameStartEndBtnsModule,
    PLCommonComponentsModule,
  ],
  exports: [
    PLClueBoardComponent,
    PLClueDrawerComponent,
    PLClueInvestigationItemsComponent,
    PLCluePlayerHandComponent,
    PLClueSubmitAnswersComponent,
    PLClueConfidentialFolderScreenComponent,
    PLClueConclusionScreenComponent,
    PLClueOverviewInstructionsComponent,
  ],
  declarations: [
    PLClueBoardComponent,
    PLClueDrawerComponent,
    PLClueInvestigationItemsComponent,
    PLCluePlayerHandComponent,
    PLClueSubmitAnswersComponent,
    PLClueConfidentialFolderScreenComponent,
    PLClueConclusionScreenComponent,
    PLClueOverviewInstructionsComponent,
  ],
  providers: [],
})
export class PLClueModule {}
