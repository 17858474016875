import { AppState } from '@app/store';
import { createSelector } from '@ngrx/store';
import { selectCurrentUser } from '@modules/user/store';
import { selectFirebase } from '@common/firebase/store';

export const selectRoom = (state: AppState) => state.room;

export const selectRoomData = createSelector(selectRoom, room => room?.data);

export const selectIsRoomReady = createSelector(
  selectCurrentUser,
  selectRoom,
  selectFirebase,
  (user, room, fbState) =>
    user.groups &&
    room.status === 'success' &&
    fbState.status === 'initialized',
);
