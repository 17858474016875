import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { PdfDrawerPagesComponent } from '@root/src/app/modules/pl-queue/pdf-drawer-pages/pdf-drawer-pages.component';
import { LoggerService } from '@root/src/app/common/services/logger/logger.service';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Subject, of } from 'rxjs';
import {
  AssessmentSessionChangeEvent,
  PLAssessmentModelService,
} from '../../model/assessment-model.service';

const DEFAULT_PAGE_NUM = 1;
const DEFAULT_ZOOM = 1;

@Component({
  selector: 'pl-pdf-assessment-drawer',
  templateUrl: 'pdf-assessment-drawer.component.html',
  styleUrls: ['pdf-assessment-drawer.component.less'],
})
export class PLPDFAssessmentDrawerComponent
  implements OnDestroy, AfterViewInit, OnDestroy
{
  @ViewChild(PdfDrawerPagesComponent)
  private pagesRef: PdfDrawerPagesComponent;

  @Input() public activity;

  private destroyed$ = new Subject();

  totalPages = 0;
  thumbs: string[] = [];
  studentPages: number[] = [];
  hasThumbs = false;
  currentPage = DEFAULT_PAGE_NUM;
  currentScale = DEFAULT_ZOOM;
  hasInstructions = false;

  constructor(
    private assessmentModel: PLAssessmentModelService,
    private loggerService: LoggerService,
  ) {}

  get areInstructionsVisible() {
    return this.assessmentModel.getShowInstructions();
  }

  get instructionsOpacity() {
    return this.assessmentModel.instructionOpacity;
  }

  ngAfterViewInit() {
    this.init();
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  getActualPageNum(absolutePageNum: number) {
    return this.studentPages[absolutePageNum - 1];
  }

  async init() {
    try {
      await this.assessmentModel.foundationLoaded;

      this.assessmentModel.sessionId = this.activity.session_id;

      const { currentPage, currentScale } = await this.loadState();

      const pages = this.assessmentModel.activity.config.pages;
      this.studentPages = pages.map(p => p.stimulus);
      this.totalPages = this.studentPages.length;
      this.hasInstructions = Number.isInteger(pages[0].instructions);

      // We're converting absolute page to a relative number based on the actual pages
      // that we're displaying to the users
      this.currentPage = this.studentPages.indexOf(currentPage) + 1;
      this.currentScale = currentScale;

      if (this.assessmentModel.activity.thumbnails?.length) {
        this.hasThumbs = true;
        this.thumbs = this.studentPages.map(
          pageNum => this.assessmentModel.activity.thumbnails[pageNum - 1],
        );
      }

      this.addListeners();

      if (!this.hasThumbs) {
        const url = this.assessmentModel.activity.config.url;
        await this.loadPDF(url);
      }
    } catch (err) {
      this.loggerService.error(err);
      await new Promise(resolve => setTimeout(resolve, 1000));
      return this.init();
    }
  }

  async loadPDF(url: string) {
    const cachedUrl = this.assessmentModel.cachedProtectedAsset(url);

    if (cachedUrl) {
      return this.pagesRef.loadPDF(cachedUrl);
    }

    return this.assessmentModel
      .getProtectedContentUrl(url)
      .pipe(
        switchMap(data => {
          const protectedUrl = data.assets[url];
          return of(this.pagesRef.loadPDF(protectedUrl));
        }),
      )
      .toPromise();
  }

  private addListeners() {
    this.assessmentModel.changed$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((event: AssessmentSessionChangeEvent) => {
        if (!event || event.sessionId !== this.activity.session_id) {
          return;
        }

        switch (event.type) {
          case 'zoomChangeEvent':
            this.currentScale = event.data || DEFAULT_ZOOM;
            break;
        }
      });
  }

  private loadState(): Promise<{
    currentPage: number;
    currentScale: number;
  }> {
    return new Promise(resolve => {
      this.assessmentModel.pageRef.once('value', snap => {
        const data = snap.val() || {};
        resolve({
          currentPage: data?.currentPage || DEFAULT_PAGE_NUM,
          currentScale: data?.zoom || DEFAULT_ZOOM,
        });
      });
    });
  }

  onTabChange(tabIndex: number) {
    // Tab with index 0 renders thumbnails
    if (tabIndex === 0) {
      requestAnimationFrame(() => {
        this.pagesRef.triggerRerender();
      });
    }
  }

  zoomTo(zoomVal: number) {
    this.currentScale = zoomVal;
    this.assessmentModel.saveZoom(zoomVal);
  }

  gotoPage(pageNum: number) {
    this.currentPage = pageNum;

    // We're saving an absolute page, but locally operate on a relative one
    this.assessmentModel.savePage({
      currentPage: this.studentPages[pageNum - 1],
    });

    // when we switch pages, clear any existing scroll positions
    this.assessmentModel.saveScrollPosition(0, 0);
  }

  toggleInstructions() {
    this.assessmentModel.toggleInstructions();
  }

  setInstructionsOpacity(value: number) {
    this.assessmentModel.saveOpacity(value);
  }
}
