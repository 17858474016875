<div class="pdf-pages-display-tab">
  <div *ngIf="canZoom" class="slider-group" data-testid="pdf-zoom-group">
    <div class="slider-group__label-container">
      <label class="slider-group__label" id="pdf-zoom-slider">
        Page zoom level
      </label>
      <span class="slider-group__value">{{ scale * 100 }}%</span>
    </div>
    <mat-slider
      [min]="0"
      [max]="zoomLevels.length - 1"
      [step]="1"
      [tickInterval]="'auto'"
      [ngModel]="zoomLevels.indexOf(scale)"
      (input)="handleZoomTo($event.value)"
      aria-labelledby="pdf-zoom-slider"
      class="slider-group__slider"
    ></mat-slider>
  </div>

  <div
    *ngIf="canSwitchMode && totalPages > 1"
    class="radio-group"
    data-testid="pdf-layout-group"
  >
    <label id="choose-pdf-layout-radio-group" class="radio-group__label"
      >Layout</label
    >
    <mat-radio-group
      aria-labelledby="choose-pdf-layout-radio-group"
      class="radio-group__radios"
      color="primary"
      data-testid="pdf-layout-radio-group"
      [ngModel]="bookMode"
      (ngModelChange)="handleSwitchMode($event)"
    >
      <mat-radio-button
        *ngFor="let bookMode of bookModeList"
        [value]="bookMode.value"
      >
        {{ bookMode.extendedLabel }}
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <button
    mat-stroked-button
    color="primary"
    class="w-100"
    data-testid="pdf-rotate-button"
    *ngIf="canRotate"
    [disabled]="bookMode !== bookModeEnum.SINGLE_PAGE_MODE"
    (click)="handleRotate()"
  >
    <mat-icon>rotate_left</mat-icon>
    Rotate pages
  </button>

  <ng-content></ng-content>
</div>
