import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { PLCommonComponentsModule } from '@root/src/app/common/components';

import { PLGametableCheckersDrawerComponent } from './gametable/pl-gametable-checkers-drawer.component';
import { PLGametableDotsAndBoxesDrawerComponent } from './gametable/pl-gametable-dots-and-boxes-drawer.component';
import { PLGametableBlokdDrawerComponent } from './gametable/pl-gametable-blokd-drawer.component';
import { PLGametableTrioDrawerComponent } from './gametable/pl-gametable-trio-drawer.component';
import { PLGametableTeamwordDrawerComponent } from './gametable/pl-gametable-teamword-drawer.component';
import { GameStartEndBtnsModule } from '../game-start-end-btns';
import { PLVendorGameComponent } from './pl-vendor-game.component';
import { PLVendorGameDrawerComponent } from './pl-vendor-game-drawer.component';
import { PLVendorGameService } from './pl-vendor-game.service';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PLCommonComponentsModule,
    MatRadioModule,
    MatTabsModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    MatInputModule,
    GameStartEndBtnsModule,
  ],
  exports: [
    PLVendorGameComponent,
    PLVendorGameDrawerComponent,
    PLGametableCheckersDrawerComponent,
    PLGametableDotsAndBoxesDrawerComponent,
    PLGametableBlokdDrawerComponent,
    PLGametableTrioDrawerComponent,
  ],
  declarations: [
    PLVendorGameComponent,
    PLVendorGameDrawerComponent,
    PLGametableCheckersDrawerComponent,
    PLGametableDotsAndBoxesDrawerComponent,
    PLGametableBlokdDrawerComponent,
    PLGametableTrioDrawerComponent,
    PLGametableTeamwordDrawerComponent,
  ],
  providers: [PLVendorGameService],
})
export class PLVendorGamesModule {}

export { PLVendorGameComponent } from './pl-vendor-game.component';
export { PLVendorGameDrawerComponent } from './pl-vendor-game-drawer.component';
export { PLVendorGameService } from './pl-vendor-game.service';
