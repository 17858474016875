<div class="queue-activities">
  <pl-sort-menu
    [currentSortOption]="currentSortOption"
    [sortOptions]="sortOptions"
    (sortOptionSelected)="sortOrderChanged($event)"></pl-sort-menu>

  <div class="list-container">
    <ul
      #activitiesList
      cdkDropList
      cdkDropListLockAxis="y"
      (cdkDropListDropped)="onActivityDrop($event)"
      class="activities-list">
      <li
        class="activity-item"
        cdkDrag
        [cdkDragDisabled]="currentSortOption.value !== SortType.Custom"
        *ngFor="let activityId of displayOrder"
        (click)="handleOpenActivity(activityId)"
        data-testid="activity-list-item">
        <ng-container *ngIf="activities[activityId] as activity">
          <img
            *ngIf="activity.thumbnail_url"
            class="activity-item__img"
            [src]="activity.thumbnail_url" />

          <div class="activity-item__content">
            <div class="activity-item__type">
              {{ activity.type | activityTypeLabel }}
            </div>
            <div
              class="activity-item__name"
              [matTooltipDisabled]="activity.name.length < 30"
              matTooltip="{{ activity.name }}">
              {{ activity.name }}
            </div>
          </div>

          <button
            mat-icon-button
            class="activity-item__menu-btn"
            [matMenuTriggerFor]="menu"
            (click)="$event.stopPropagation()">
            <mat-icon>more_horiz</mat-icon>
          </button>

          <mat-menu #menu="matMenu" backdropClass="is-dark">
            <button mat-menu-item (click)="handleRemoveActivity(activityId)">
              <span>Remove from queue</span>
            </button>
            <!-- <button mat-menu-item>
          <span>Add to other queues...</span>
        </button> -->
          </mat-menu>
        </ng-container>
      </li>
    </ul>
  </div>

  <div
    class="page-buttons"
    *ngIf="activitiesOrder?.length > PAGE_LENGTH">
    <button
      mat-icon-button
      [disabled]="currentPage === 1"
      (click)="incrementPage(-1)">
      <mat-icon>chevron_left</mat-icon>
    </button>

    <span class="page-buttons__current-page">
      {{ currentPage }} / {{ totalPages }}
    </span>

    <button
      mat-icon-button
      [disabled]="currentPage === totalPages"
      (click)="incrementPage(1)">
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>
</div>
