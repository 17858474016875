import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import {
  mergeMap,
  map,
  catchError,
  filter,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { createEffect, Actions, ofType, concatLatestFrom } from '@ngrx/effects';
import { AppState } from '@app/store';
import {
  filterSessionReady,
  selectLocalParticipantId,
  filterHostSessionReady,
} from '@room/session/store';
import { SessionService } from '@room/session/session.service';
import { ConferenceRTService } from '@room/conference/conference-rt.service';
import { ConferenceActions } from '../conference.actions';
import { selectParticipantStreams } from '../conference.selectors';
import { StreamType } from '../conference.model';

@Injectable()
export class ConferenceCleanupEffects {
  removeExistingSecondaryStreams$ = createEffect(() => {
    return this.store.pipe(
      filterSessionReady,
      concatLatestFrom(() => this.store.select(selectLocalParticipantId)),
      switchMap(participantId => {
        const streamId = `${participantId}-${StreamType.secondary}`;
        return this.conferenceRTService.removeStream(streamId).pipe(
          map(() =>
            ConferenceActions.removeLocalSecondarySuccess({ id: streamId }),
          ),
          catchError(error => {
            const errorObj = {
              error,
              id: streamId,
            };
            console.error('REMOVE_EXISTING_SECONDARY', errorObj);
            return of(ConferenceActions.removeLocalSecondaryError(errorObj));
          }),
        );
      }),
    );
  });

  participantRemoved$ = createEffect(() => {
    return this.store.pipe(
      filterHostSessionReady(this.store),
      switchMap(() => {
        return this.conferenceRTService.onStreamAdded().pipe(
          mergeMap(({ participantId }) => {
            return this.sessionService.hasParticipant(participantId).pipe(
              takeUntil(
                this.actions$.pipe(
                  ofType(ConferenceActions.removeParticipantStreamsSuccess),
                  filter(({ participantId: pId }) => pId === participantId),
                ),
              ),
              filter(hasParticipant => !hasParticipant),
              map(() =>
                ConferenceActions.removeParticipantStreams({
                  participantId,
                }),
              ),
            );
          }),
        );
      }),
    );
  });

  removeParticipantStreams$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConferenceActions.removeParticipantStreams),
      concatLatestFrom(({ participantId }) =>
        this.store.select(selectParticipantStreams(participantId)),
      ),
      mergeMap(([{ participantId }, streams]) => {
        const ids = streams.map(stream => stream.id);
        return this.conferenceRTService.removeMultipleStreams(ids).pipe(
          map(() =>
            ConferenceActions.removeParticipantStreamsSuccess({
              participantId,
            }),
          ),
          catchError(error => {
            const errorObj = {
              error,
              participantId,
            };
            console.error('REMOVE_PARTICIPANT_STREAMS', errorObj);
            return of(
              ConferenceActions.removeParticipantStreamsError(errorObj),
            );
          }),
        );
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private conferenceRTService: ConferenceRTService,
    private sessionService: SessionService,
  ) {}
}
