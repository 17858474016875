import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { LocalStorageService } from '@root/src/app/common/services/LocalStorageService';
import moment from 'moment';
import { NoteSchema } from '@root/src/app/common/services/pl-records';
import { DocumentationNotes, MetricPoint } from '../../../documentation.models';

enum DictationStatus {
  stopped = 'stopped',
  starting = 'starting',
  dictating = 'dictating',
}

export enum DocumentationNoteType {
  subjective = 'subjective',
  objective = 'objective',
  assessment = 'assessment',
  plan = 'plan',
  notes = 'notes',
}

const SPEECH_TIMEOUT_SECONDS = 15;
@Component({
  selector: 'pl-presence-appointment-item-notes',
  templateUrl: 'presence-appointment-item-notes.component.html',
  styleUrls: ['./presence-appointment-item-notes.component.less'],
})
export class PresenceAppointmentItemNotesComponent
  implements OnInit, OnChanges
{
  private _isSigned: boolean;
  @Input() public get isSigned() {
    return this._isSigned;
  }

  public set isSigned(val: boolean) {
    this._isSigned = val;
    if (val) {
      this.signConfirmation = true;
    }
  }
  @Input() public optionalSOAPNotes = false;
  @Input() public notes: DocumentationNotes;
  @Input() public noteSchema: NoteSchema;
  @Input() public isSavingNotes: boolean = false;
  @Input() public isSigningLoading: boolean = false;
  @Input() public isEvaluation: boolean = false;
  @Input() public currentDisplayedNote = DocumentationNoteType.notes;
  @Output() public currentDisplayedNoteChange =
    new EventEmitter<DocumentationNoteType>();
  @Input() public metricPoints: MetricPoint[];
  @Output() public notesChange = new EventEmitter<DocumentationNotes>();
  @Output() public isSignedChange = new EventEmitter<boolean>();
  @Output() public openEventDetails = new EventEmitter<void>();

  hasNewMetrics = false;
  currentDictationStatus: DictationStatus = DictationStatus.stopped;
  recognizing = false;
  speechRecognition: SpeechRecognition = null;
  speechTimeout: NodeJS.Timeout;
  finalTranscript = '';
  speaking = false;
  timeoutSavingTrigger: NodeJS.Timeout;
  signConfirmation = false;
  private momentFormat = 'YYYY-MM-DD HH:mm:ssZ';
  private localStorageKeyDictation: string = 'documentationNotesDictation';
  private dictationDisclaimerResetDays: number = 30;

  constructor(private localStorageService: LocalStorageService) {}

  ngOnInit() {
    this.initSpeakToText();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.metricPoints) {
      this.checkForNewMetrics(changes.metricPoints);
    }
  }

  onStartDictation() {
    let data = this.localStorageService.get(this.localStorageKeyDictation);
    if (data) {
      data = JSON.parse(data);
      if (data.hideDictationDisclaimer) {
        if (data.hideDictationDisclaimerDate) {
          const lastDate = moment(
            data.hideDictationDisclaimerDate,
            this.momentFormat,
          );
          const daysFromNow = moment().diff(lastDate, 'days');
          if (daysFromNow >= this.dictationDisclaimerResetDays) {
            this.dictationDisclaimerSeen(false);
          } else {
            this.currentDictationStatus = DictationStatus.dictating;
            this.startSpeaking();
            return;
          }
        }
      }
    }
    this.currentDictationStatus = DictationStatus.starting;
  }

  onConfirmDictationStart() {
    this.dictationDisclaimerSeen(true);
    this.currentDictationStatus = DictationStatus.dictating;
    this.startSpeaking();
  }

  onStopDictation() {
    this.currentDictationStatus = DictationStatus.stopped;
    this.stopSpeaking();
  }

  onChangeDisplayedNote(noteType: DocumentationNoteType) {
    this.currentDisplayedNote = noteType;
    this.currentDisplayedNoteChange.emit(noteType);
  }

  onNotesChanged() {
    this.notesChange.emit(this.notes);
  }

  onSignOff(value: boolean) {
    this.isSignedChange.emit(value);
  }

  onViewComponents() {
    this.openEventDetails.emit();
  }

  onImportMetrics() {
    const existingText =
      this.notes.objective.length > 0 ? `${this.notes.objective}\n\n` : '';
    this.notes.objective = `${existingText}${this.formatMetrics(
      this.metricPoints,
    )}`;
    this.hasNewMetrics = false;
    this.onNotesChanged();
  }

  get isLoading() {
    return this.isSigningLoading || this.isSavingNotes;
  }

  get canSign() {
    return (
      this.notes &&
      (this.optionalSOAPNotes ||
        this.notes[DocumentationNoteType.subjective] ||
        this.notes[DocumentationNoteType.objective] ||
        this.notes[DocumentationNoteType.assessment] ||
        this.notes[DocumentationNoteType.plan])
    );
  }

  private checkForNewMetrics(change: SimpleChange) {
    const oldMetrics = change.previousValue || [];
    const newMetrics = change.currentValue || [];

    const currentIds = oldMetrics.map(mp => mp.id);
    const newIds = newMetrics.map(mp => mp.id);
    if (
      newMetrics.some(mp => !currentIds.includes(mp.id)) || // New metric added
      currentIds.some(id => !newIds.includes(id)) || // Metric removed
      newMetrics.some(mp => {
        // Metric updated
        const currentPoint = oldMetrics.find(p => p.id === mp.id);
        return (
          currentPoint.correct !== mp.correct ||
          currentPoint.trials !== mp.trials
        );
      })
    ) {
      this.hasNewMetrics = true;
    }
  }

  private formatMetrics(metrics: MetricPoint[]) {
    let metricsList = [];
    metrics.forEach(metric => {
      // Only add if the metric has at least one data point (1 trial).
      if (metric.trials > 0) {
        let percent;
        if (metric.percentage !== undefined) {
          percent = metric.percentage;
        } else {
          percent =
            metric.trials <= 0
              ? 0
              : Math.round((metric.correct / metric.trials) * 100);
        }
        metricsList.push(
          `${metric.metric.name}: ${metric.correct}/${metric.trials} (${percent}%)`,
        );
      }
    });
    return metricsList.join('; ');
  }

  private initSpeakToText() {
    let blockSpeech =
      /edg/.test(navigator.userAgent) || /Edg/.test(navigator.userAgent)
        ? 1
        : 0;
    if ('webkitSpeechRecognition' in window && !blockSpeech) {
      this.speechRecognition = new webkitSpeechRecognition();
      this.speechRecognition.lang = 'en-US';
      this.speechRecognition.continuous = true;
      this.speechRecognition.interimResults = true;

      this.speechRecognition.onstart = () => {
        this.recognizing = true;
      };

      this.speechRecognition.onerror = event => {
        console.log(event.error);
      };

      this.speechRecognition.onend = () => {
        this.recognizing = false;
      };

      this.speechRecognition.onresult = event => {
        this.speakingResetTimeout();
        for (let ii = event.resultIndex; ii < event.results.length; ++ii) {
          if (event.results[ii].isFinal) {
            this.finalTranscript += event.results[ii][0].transcript;
            this.notes[this.currentDisplayedNote] += this.notes[
              this.currentDisplayedNote
            ]
              ? `\n${this.finalTranscript}`
              : this.finalTranscript;
            this.finalTranscript = '';
          }
        }
      };
    }
  }

  private speakingResetTimeout() {
    if (this.speechTimeout) {
      clearTimeout(this.speechTimeout);
    }
    this.speechTimeout = setTimeout(() => {
      this.stopSpeaking();
    }, SPEECH_TIMEOUT_SECONDS * 1000);
  }

  private startSpeaking() {
    if (this.speechRecognition) {
      this.finalTranscript = '';
      this.speechRecognition.start();
      this.speaking = true;
      this.speakingResetTimeout();
    }
  }

  private stopSpeaking() {
    if (this.recognizing) {
      this.speechRecognition.stop();
    }
    this.speaking = false;
  }

  private dictationDisclaimerSeen(hideIt = true) {
    const localStorageDataDictation = {
      hideDictationDisclaimer: hideIt,
      hideDictationDisclaimerDate: moment().format(this.momentFormat),
    };
    this.localStorageService.set(
      this.localStorageKeyDictation,
      JSON.stringify(localStorageDataDictation),
    );
  }
}
