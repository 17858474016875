<header class="queue-header" *ngIf="queue">
  <pl-queue-name-form
    *ngIf="isEditing"
    [initialQueueName]="queue.name"
    (save)="saveQueueName($event)"
    (discard)="cancelSaveQueueName()">
  </pl-queue-name-form>

  <div class="queue-item" *ngIf="!isEditing">
    <div class="queue-item__row">
      <span class="queue-item__inner">
        <span class="queue-item__name">
          {{ queue.name }}
        </span>
        <span class="queue-item__count">
          <ng-container
            *ngIf="queue.order?.length as activitiesOrderLength; else noItems">
            {{ activitiesOrderLength }}
            {{ activitiesOrderLength === 1 ? 'item' : 'items' }}
          </ng-container>
          <ng-template #noItems> No items </ng-template>
        </span>
      </span>

      <button
        mat-icon-button
        class="queue-item__menu-btn"
        [matMenuTriggerFor]="menu"
        (click)="$event.stopPropagation()">
        <mat-icon>more_horiz</mat-icon>
      </button>
    </div>

    <pl-confirm-alert
      *ngIf="isDeleting"
      class="mt-3"
      (click)="$event.stopPropagation()"
      (cancel)="cancelDeleteQueue()"
      (confirm)="confirmDeleteQueue()">
      Are you sure you want to delete this queue?
    </pl-confirm-alert>

    <mat-menu #menu="matMenu" backdropClass="is-dark">
      <button mat-menu-item (click)="editQueueName()">
        <span>Rename queue</span>
      </button>
      <button mat-menu-item (click)="attemptDeleteQueue()">
        <span>Delete queue</span>
      </button>
    </mat-menu>
  </div>
</header>

<div class="queue-detail">
  <div
    class="queue-detail-list"
    *ngIf="queue.order && queue.order.length; else noActivities">
    <pl-queue-activities
      [activities]="queue.items"
      [activitiesOrder]="queue.order"
      (openActivity)="openActivity.emit($event)"
      (removeActivity)="removeActivity.emit($event)"
      (orderChanged)="changeActivitiesOrder.emit($event)">
    </pl-queue-activities>

    <div class="button-panel">
      <button
        mat-stroked-button
        color="primary"
        (click)="openAddActivity.emit()"
        class="w-100"
        *ngIf="!isDeleting">
        Add more activities
      </button>
    </div>
  </div>

  <ng-template #noActivities>
    <div class="no-activities">
      <div>
        Queues allow you to organize activities to be used during sessions. You
        can add activities to queues directly from the Room or within the
        Library.
      </div>

      <button
        mat-stroked-button
        color="primary"
        (click)="openAddActivity.emit()"
        class="w-100"
        *ngIf="!isDeleting">
        Add activities
      </button>
    </div>
  </ng-template>
</div>
