import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { switchMap, concatMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store';
import { PLVendorGamesByActivity } from './pl-vendor-game-definitions.service';
import {
  filterSessionReady,
  selectActiveParticipants,
  selectLocalParticipantId,
  Participant,
  ParticipantType,
} from '@room/session/store';
import { FirebaseService } from '@root/src/app/common/firebase/firebase.service';

// SE NOTE: Added these types for clarity sake
interface PLGameDbRefValueCallback {
  (value: any): void;
}
interface PLGameDbRefValueListener {
  /** The function actually listening on the fb reference. */
  listener: { (snapshot: any): void };
  /** The game callback function to make within listener, it should be passed snapshot.val() as a param. */
  callback: PLGameDbRefValueCallback;
}

interface PLGameDbRef {
  plGameDbAPI: PLGameDbAPI;
  valueListeners: PLGameDbRefValueListener[]; // SE NOTE: Renamed to valueListeners for clarity and added map type
}

export interface PLGameDb {
  ref: (path: string) => PLGameDbAPI;
  destroy: () => void;
}
export interface PLGameDbAPI {
  path: string;
  isDisposed: boolean;

  get: Function;
  set: Function;
  update: Function;
  onValue: Function;
  offValue: Function;
  dispose: Function;
}

export interface PLGameAdapterUrlParams {
  isRoomOwner: 0 | 1;
  clientId: string;
}

export interface PLVendorGame {
  vendor: string;
  name: string;
  setConfig: (plGameDb: PLGameDb, gameConfig: PLGameConfig) => void;
  getIframeUrl: (params: PLGameAdapterUrlParams) => string;
  newFormData: () => any;
}

export interface PLGameConfig {}

@Injectable()
export class PLVendorGameService {
  gameDbs: PLGameDb[] = [];

  constructor(
    private store: Store<AppState>,
    private domSanitizer: DomSanitizer,
    private firebaseService: FirebaseService,
  ) {}

  setupPLGameDb(activity): PLGameDb {
    const db = this.createDbForActivity(activity);
    this.gameDbs.push(db);
    return db;
  }

  destroy() {
    this.gameDbs.forEach(db => db.destroy());
  }

  createDbForActivity(activity): PLGameDb {
    const fbPath = `activities/queues/items/${activity.queueId}/items/${activity.activityId}`;
    const refs: PLGameDbAPI[] = [];

    const db: PLGameDb = {
      ref: (path: string): PLGameDbAPI => {
        const dbRef = this.getGameDbPlRef(fbPath, path).plGameDbAPI;
        refs.push(dbRef);
        return dbRef;
      },
      destroy: () => {
        refs.forEach(ref => ref.dispose());
        refs.splice(0, refs.length);
        this.gameDbs = this.gameDbs.filter(gdb => gdb !== db);
      },
    };

    return db;
  }

  sanitizeUrl(url: string): any {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  isVendorGame(activity: any) {
    return PLVendorGamesByActivity[activity.type];
  }

  getParticipantsAndLocalId() {
    return this.store.pipe(
      filterSessionReady,
      switchMap(() => {
        return this.store.select(selectActiveParticipants).pipe(
          concatMap(participants => {
            return of(participants).pipe(
              withLatestFrom(this.store.select(selectLocalParticipantId)),
            );
          }),
        );
      }),
    );
  }

  // produce a unique list of participants
  getPlayerOpts(participants: Participant[]) {
    const hostParticipant = participants.find(
      p => p.type === ParticipantType.host,
    );
    const otherParticipants = participants.filter(
      p => p.type !== ParticipantType.host,
    );
    if (hostParticipant) {
      const opts = otherParticipants.map(p => {
        return { label: p.displayName, value: p.userId };
      });
      const sorted = opts.sort((a, b) => a.label.localeCompare(b.label));
      const { displayName, userId } = hostParticipant;
      return [{ label: displayName, value: userId }, ...sorted];
    }
  }

  private getGameDbPlRef(rootPath: string, path: string): PLGameDbRef {
    const fbActivityRef = this.firebaseService.getRoomRef(rootPath);
    const fbRef = fbActivityRef.child(path);
    const plRef: PLGameDbRef = {
      plGameDbAPI: {
        path,
        isDisposed: false,
        get: (success, failure) => {
          const listener = snapshot => success(snapshot.val());
          fbRef.once('value').then(listener).catch(failure);
        },
        set: (value, success, failure) => {
          fbRef.set(value).then(success).catch(failure);
        },
        update: (value, success, failure) => {
          fbRef.update(value).then(success).catch(failure);
        },
        onValue: callback => {
          const valueListener = {
            callback,
            listener: snapshot => {
              callback(snapshot.val());
            },
          };
          plRef.valueListeners.push(valueListener); //plRef.listeners[callback] = valueListener; // SE NOTE: changed this line to use push
          return fbRef.on('value', valueListener.listener);
        },
        offValue: (callback?) => {
          if (callback) {
            // SE NOTE: going to change this quite a bit so i am just commenting out the next 2 lines and rewriting it below that
            //const valueListener = plRef.listeners[callback];
            //fbRef.off('value', valueListener);

            // We want to remove the 1st instance of a valueListener with the supplied callback
            for (let i = 0; i < plRef.valueListeners.length; ++i) {
              if (plRef.valueListeners[i].callback === callback) {
                fbRef.off('value', plRef.valueListeners[i].listener); // NOTE: remove the 'listener' property here because thats what is attached in fbRef.on
                plRef.valueListeners.splice(i, 1); // remove it from the list since its no longer active
                return;
              }
            }
          } else {
            // SE NOTE: going to change this quite a bit so i am just commenting out the next line and rewriting it below that
            //fbRef.off('value');

            // We want to remove all valueListeners, but because of how firebase reference's off function works, we MUST supply the callback to remove
            // So while there are still value listeners remaining, just remove them one at a time
            while (plRef.valueListeners.length > 0)
              plRef.plGameDbAPI.offValue(plRef.valueListeners[0].callback);
          }
        },
        dispose: () => {
          const gameDb = plRef.plGameDbAPI;
          if (gameDb.isDisposed) return;
          gameDb.isDisposed = true;

          // call off() to remove all listener
          gameDb.offValue();
        },
      },
      valueListeners: [], // SE NOTE: updated from listeners to valueListeners for name change mentioned above
    };
    return plRef;
  }
}
