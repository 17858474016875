<ng-container *ngIf="studentItem">
  <div class="appointment-title">
    <button
      mat-icon-button
      matTooltip="View student record"
      (click)="avatarClicked($event)">
      <mat-icon>account_circle</mat-icon>
    </button>
    <span
      >{{ studentItem.client.first_name }}
      {{ studentItem.client.last_name }}</span
    >
  </div>
  <mat-tab-group
    [selectedIndex]="itemState.selectedTabIndex"
    (selectedIndexChange)="onTabChanged($event)"
    mat-stretch-tabs
    class="is-compact three-tabs"
    color="accent">
    <mat-tab label="Details">
      <h2>Details</h2>
      <!-- <pl-kanga-service-details></pl-kanga-service-details> -->
    </mat-tab>

    <mat-tab label="Progress">
      <h2>Progress</h2>
      <div class="tab-content">
        <!-- <pl-metrics-tracking></pl-metrics-tracking> -->
      </div>
    </mat-tab>

    <mat-tab label="Notes">
      <h2>Notes</h2>
      <div class="tab-content">
        <!-- <pl-kanga-appointment-item-notes></pl-kanga-appointment-item-notes> -->
      </div>
    </mat-tab>
  </mat-tab-group>
</ng-container>
