<ng-container
  *ngIf="
    (isCurrentActivity$ | async) || (isWorkspaceEmpty$ | async);
    else switchto
  "
>
  <button
    (click)="onSwitchToThis()"
    *ngIf="isWorkspaceEmpty$ | async"
    mat-button
    class="activity-switch-button"
  >
    <mat-icon class="material-icons-outlined">visibility_off</mat-icon>
    Show in Room
  </button>
  <ng-container *ngIf="!(isWorkspaceEmpty$ | async)">
    <button
      (click)="onShowWorkspace(false)"
      *ngIf="isCentralContentVisible$ | async"
      mat-button
      class="activity-switch-button"
    >
      <mat-icon class="material-icons-outlined">visibility</mat-icon>
      Hide from Room
    </button>
    <button
      (click)="onShowWorkspace(true)"
      *ngIf="!(isCentralContentVisible$ | async)"
      mat-button
      class="activity-switch-button"
    >
      <mat-icon class="material-icons-outlined">visibility_off</mat-icon>
      Show in Room
    </button>
  </ng-container>
</ng-container>
<ng-template #switchto>
  <button mat-button class="activity-switch-button" (click)="onSwitchToThis()">
    <mat-icon class="material-icons-outlined">swap_horiz</mat-icon>
    Switch to this activity
  </button>
</ng-template>
