import { Injectable } from '@angular/core';
import { environment } from '@root/src/environments/environment';
import { CurrentUserModel } from '../models/CurrentUserModel';
declare var liveagent: any;
@Injectable({ providedIn: 'root' })
export class LiveagentService {
  state = {
    chatAvailable: false,
  };
  startChat() {
    if (this.state.chatAvailable && typeof liveagent !== 'undefined') {
      setTimeout(() => {
        // collides with youtube api $apply
        liveagent.startChat(environment.liveagent.buttonId);
      }, 1);
    }
  }

  constructor(currentUserModel: CurrentUserModel) {
    const buttonCallback = e => {
      if (e === liveagent.BUTTON_EVENT.BUTTON_AVAILABLE) {
        this.state.chatAvailable = true;
      }
      if (e === liveagent.BUTTON_EVENT.BUTTON_UNAVAILABLE) {
        this.state.chatAvailable = false;
      }

      if (e === liveagent.BUTTON_EVENT.BUTTON_ACCEPTED) {
        // if you don't return true here then the chat popup fails
        return true;
      }

      return false;
    };

    if (typeof liveagent !== 'undefined') {
      liveagent.addButtonEventHandler(
        environment.liveagent.buttonId,
        buttonCallback,
      );
      if (currentUserModel.user != null) {
        liveagent.setName(currentUserModel.user.getName());
      } else {
        liveagent.setName('guest');
      }
      liveagent.init(
        environment.liveagent.endpoint,
        environment.liveagent.initArg1,
        environment.liveagent.initArg2,
      );

      // without this, the callback doesn't fire
      liveagent.showWhenOnline(environment.liveagent.buttonId, null);
    } else {
      // Techcheck used this remote log:
      // remoteLogging.logToSentry('liveagent undefined');
      console.error('liveagent undefined in LiveagentService!');
    }
  }
}
