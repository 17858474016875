import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivitySwitchButtonComponent } from './activity-switch-button.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

const exportedComponents = [ActivitySwitchButtonComponent];

@NgModule({
  imports: [CommonModule, MatButtonModule, MatIconModule],
  exports: [...exportedComponents],
  declarations: [...exportedComponents],
  providers: [],
})
export class ActivitySwitchModule {}
