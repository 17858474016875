<div class="pl-game" [class]="activity.type">
  <pl-clue-board
    *ngIf="activity.type === 'game-clue'"
    [activity]="activity"
  ></pl-clue-board>
  <pl-candyland-board
    *ngIf="activity.type === 'game-candyland'"
    [activity]="activity"
  >
  </pl-candyland-board>
  <pl-go-fish
    *ngIf="activity.type === 'game-go-fish'"
    [activity]="activity"
  ></pl-go-fish>
  <pl-scenes
    *ngIf="activity.type === 'game-scenes'"
    [activity]="activity"
  ></pl-scenes>
  <pl-scenes
    *ngIf="activity.type === 'game-potato-head'"
    [activity]="activity"
  ></pl-scenes>
  <pl-vendor-game *ngIf="isVendorGame()" [activity]="activity"></pl-vendor-game>
</div>
