import { NgModule } from '@angular/core';

import { PLDrawerPanelComponent } from './pl-drawer-panel.component';
import { CommonModule } from '@angular/common';
import { PLIconModule } from '@root/src/lib-components';
import { PLDrawerPanelHeaderComponent } from './pl-drawer-panel-header.component';
import { PLCommonComponentsModule } from '@root/src/app/common/components';

const exported = [PLDrawerPanelComponent, PLDrawerPanelHeaderComponent];

@NgModule({
  imports: [CommonModule, PLIconModule, PLCommonComponentsModule],
  exports: [...exported],
  declarations: [...exported],
  providers: [],
})
export class PLDrawerPanelModule {}

export { PLDrawerPanelComponent, PLDrawerPanelHeaderComponent };
