import moment from 'moment';
import { Injectable } from '@angular/core';
import { tap, switchMap, map } from 'rxjs/operators';
import { PLUrlsService, PLHttpService } from '@common/services/pl-http';
import { RoomConferenceService } from '@room/conference';
import { CurrentUserModel } from '@common/models/CurrentUserModel';
import { RoomnameModel } from '@common/models/RoomnameModel';
import { FirebaseModel } from '@common/models/firebase/FirebaseModel';
import { BehaviorSubject, Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionRecordingSnackbarComponent } from './recording-snackbar/session-recording-snackbar.component';
import { Record } from '@room/pl-drawers/new-documentation-drawer/documentation.models';

export const RECORD_STREAM_ID = 'SESSION_RECORD_SCREENSHARE';

interface ToboxRecordingProperties {
  archiveId: string;
  providerId: string;
  clientIDs: string[];
  record: Record;
}

export interface TokboxRecordingSalvagedData {
  success: boolean;
  clientIds: string[];
  archiveId: string;
  error?: any;
}

export interface ScreenshareUpdate {
  success: boolean;
  error?: any;
}

@Injectable({ providedIn: 'root' })
export class TokboxRecordService {
  currentArchiveId = null;
  tokboxApiKey = '';
  sessionId = '';
  videoUrl = '';
  archiveName = '';
  tokboxEnvironment = 'enterprise';

  lastInitError = {
    code: '',
    message: '',
  };

  screensharing: boolean;
  appRef: firebase.database.Reference;

  isScreensharing$ = new BehaviorSubject<boolean>(false);
  isRecording$ = new BehaviorSubject<boolean>(false);

  recordingSalvaged$ = new Subject<TokboxRecordingSalvagedData>();
  screenshareUpdate$ = new Subject<ScreenshareUpdate>();
  currentClientIDs: string[];
  currentRecord: Record;

  constructor(
    private plHttp: PLHttpService,
    private currentUserModel: CurrentUserModel,
    roomnameModel: RoomnameModel,
    private plUrls: PLUrlsService,
    private firebaseModel: FirebaseModel,
    private conferenceService: RoomConferenceService,
    private snackBar: MatSnackBar,
  ) {
    this.tokboxApiKey = roomnameModel.value.tokbox_api_key;
    this.sessionId = roomnameModel.value.tokbox_session_id;
    this.tokboxEnvironment = roomnameModel.value.tokbox_environment;

    this.appRef = this.firebaseModel.getFirebaseRef('app');
    this.videoUrl = `${this.plUrls.urls.video}`;

    this.checkForRecording();
  }

  checkForRecording = () => {
    if (!this.currentUserModel.user.isClinicianOrExternalProvider()) {
      return;
    }
    this.appRef.once('value', snapshot => {
      const val = snapshot.val();
      if (val.tokboxIsRecording) {
        const recordingProps: ToboxRecordingProperties =
          val.tokboxRecordingProperties;
        if (
          recordingProps &&
          recordingProps.providerId === this.currentUserModel.user.uuid
        ) {
          this.getRecording(recordingProps);
        }
      }
    });
  };
  getRecording(recordingProps: ToboxRecordingProperties) {
    this.getTokboxArchive(recordingProps.archiveId).subscribe(
      (archive: any) => {
        if (archive.status === 'started' || archive.status === 'paused') {
          this.stopTokboxRecording(
            recordingProps.archiveId,
            recordingProps.clientIDs,
            recordingProps.record,
          ).subscribe(
            () => this.handleRecoverySuccess(recordingProps),
            error => {
              this.handleRecoveryFailure(recordingProps, error);
            },
          );
        } else if (
          archive.status === 'available' ||
          archive.status === 'uploaded'
        ) {
          this.postRecordingResult(
            archive,
            recordingProps.clientIDs,
            recordingProps.record,
          ).subscribe(
            () => this.handleRecoverySuccess(recordingProps),
            error => {
              this.handleRecoveryFailure(recordingProps, error);
            },
          );
        } else if (
          archive.status === 'expired' ||
          archive.status === 'failed'
        ) {
          console.log('Failing to recover existing failed archive: ', archive);
          this.handleRecoveryFailure(
            recordingProps,
            'Archive ' + archive.status,
          );
        }
      },
    );
  }

  stopScreensharing() {
    this.conferenceService.stopScreenshare(RECORD_STREAM_ID);
    this.screensharing = false;
  }

  setTokboxRecordingProperties = value => {
    this.appRef.update({
      tokboxRecordingProperties: value,
    });
  };

  setTokboxIsRecording(value) {
    this.isRecording$.next(value);
    this.appRef.update({
      tokboxIsRecording: value,
    });
  }

  startRecording(clientIDs: string[], record: Record) {
    this.startTokboxRecording().subscribe(result => {
      this.currentClientIDs = clientIDs;
      this.currentRecord = record;
      this.setTokboxIsRecording(true);
      this.setTokboxRecordingProperties({
        archiveId: result,
        clientIDs,
        record,
        providerId: this.currentUserModel.user.uuid,
      });
    });
  }

  stopCurrentRecording() {
    this.stopTokboxRecording(
      this.currentArchiveId,
      this.currentClientIDs,
      this.currentRecord,
    ).subscribe(result => {
      this.setTokboxIsRecording(false);
      this.stopScreensharing();
      this.showResultSnackbar(result);
    });
  }
  showResultSnackbar(result) {
    const clientId = result.clients[0].client;
    const clientEventsUrl = `${this.plUrls.urls.eduClientsFE}/client/${clientId}/reports`;
    const snackBar = this.snackBar.openFromComponent(
      SessionRecordingSnackbarComponent,
      {
        duration: 30000,
        horizontalPosition: 'left',
        data: {
          clientEventsUrl,
        },
      },
    );
  }

  private handleRecoveryFailure(
    recordingProps: ToboxRecordingProperties,
    error: any,
  ) {
    this.setTokboxIsRecording(false);
    this.setTokboxRecordingProperties(null);
    this.recordingSalvaged$.next({
      error,
      success: false,
      clientIds: recordingProps.clientIDs,
      archiveId: recordingProps.archiveId,
    });
  }

  private handleRecoverySuccess(recordingProps: ToboxRecordingProperties) {
    this.setTokboxIsRecording(false);
    this.setTokboxRecordingProperties(null);
    this.recordingSalvaged$.next({
      success: true,
      clientIds: recordingProps.clientIDs,
      archiveId: recordingProps.archiveId,
    });
  }

  setLayoutClassesForStream = (streamId: string, classes: string[]) => {
    return this.httpPut(`${this.videoUrl}session/${this.sessionId}/stream`, {
      items: [
        {
          id: streamId,
          layoutClassList: classes,
        },
      ],
    }).pipe(
      tap({
        error: error => {
          console.error('setLayoutClassesForStream error: ', error);
        },
      }),
    );
  };

  startScreensharing = () => {
    this.conferenceService
      .startScreenshare(RECORD_STREAM_ID)
      .pipe(
        switchMap(() => {
          const streamId =
            this.conferenceService.getProviderId(RECORD_STREAM_ID);
          return this.setLayoutClassesForStream(streamId, ['screenshareVideo']);
        }),
      )
      .subscribe(
        () => {
          this.screenshareUpdate$.next({
            success: true,
          });
        },
        error => {
          this.screenshareUpdate$.next({
            error,
            success: false,
          });
        },
      );
    this.screensharing = true;
  };

  private startTokboxRecording = () => {
    this.archiveName =
      this.currentUserModel.user.username + moment.utc().format();

    const stylesheet = `archive {
                position: relative;
                margin:0;
                width: 1280px;
                height: 720px;
                overflow: hidden;
            }
            stream {
                display: block;
                width: 1%;
                height: 1%;
            }
            stream.screenshareVideo {
                width: 100%;
                height: 100%;
            }`;

    return this.httpPost(this.videoUrl + 'archive/', {
      sessionId: this.sessionId,
      uid: this.archiveName,
      hasAudio: true,
      hasVideo: true,
      layout: {
        stylesheet,
        type: 'custom',
      },
      outputMode: 'composed',
      resolution: '1280x720',
    }).pipe(
      map((result: any) => {
        this.currentArchiveId = result.id;
        return this.currentArchiveId;
      }),
      tap({
        error: error => {
          console.log('startTokboxRecording error: ', error);
          return error;
        },
      }),
    );
  };

  stopTokboxRecording = (archiveId, clientIDs, record) => {
    return this.httpPost(`${this.videoUrl}archive/${archiveId}/stop`, {
      uid: this.archiveName,
    }).pipe(
      switchMap(result =>
        this.getTokboxArchive(archiveId).pipe(
          switchMap(() => this.postRecordingResult(result, clientIDs, record)),
        ),
      ),
      tap({
        error: error => {
          console.error('stopTokboxRecording error: ', error);
        },
      }),
    );
  };

  postRecordingResult(
    recordingResult: any,
    clientIDs: string[],
    record: Record,
  ) {
    const recordingUrl = this.plUrls.urls.recording;
    const clientList = clientIDs.map(id => {
      return { client: id };
    });
    const body = {
      clients: clientList,
      provider: this.currentUserModel.user.uuid,
      record: record.uuid,
      status: 'stopped',
      tokbox_env_origin: 'enterprise',
      tokbox_archive_id: recordingResult.id,
    };
    return this.httpPost(recordingUrl, body);
  }

  getTokboxArchive(archiveId: string) {
    return this.httpGet(`${this.videoUrl}archive/${archiveId}`);
  }

  private httpGet(url: string) {
    return this.httpReq({
      url,
      method: 'GET',
    });
  }

  private httpPost(url: string, body: any) {
    return this.httpReq({
      url,
      body,
      method: 'POST',
    });
  }

  private httpPut(url: string, body: any) {
    return this.httpReq({
      url,
      body,
      method: 'PUT',
    });
  }

  private httpReq(options: {
    url: string;
    method: 'GET' | 'POST' | 'PUT';
    body?: any;
    headers?: any;
  }) {
    return this.plHttp.go(
      {
        headers: {
          'X-Tokbox-Env': this.tokboxEnvironment,
        },
        ...options,
      },
      null,
      { suppressError: true },
    );
  }
}
