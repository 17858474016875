import { Component } from '@angular/core';
import { PLConfirmService } from '@root/src/app/common/components/pl-confirm/pl-confirm.service';
import { PlayerCountOption } from '@root/src/app/common/components/player-count-select/player-count-select.component';
import {
  SortOption,
  SortType,
  sortOptionsDict,
} from '@root/src/app/common/components/sort-menu/sort-menu.component';

@Component({
  selector: 'pl-design-system-common',
  templateUrl: './design-system-common.component.html',
  styleUrls: ['./design-system-common.component.scss'],
})
export class DesignSystemCommonComponent {
  showConfirmAlert: boolean;
  readonly demoDocument = {
    key: 123,
    created: Date.now(),
    modified: Date.now(),
    name: 'My Document',
    trashed: false,
  };
  playerCountOptions: PlayerCountOption[] = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
  ];
  numPlayers = 1;

  currentSortOption: SortOption = sortOptionsDict[SortType.Custom];
  currentActivitiesOrder: string[];
  showHijack = false;

  sortOptions: SortOption[] = [
    sortOptionsDict[SortType.Custom],
    sortOptionsDict[SortType.Alphabetical],
    sortOptionsDict[SortType.ReverseAlphabetical],
  ];

  constructor(private plConfirmService: PLConfirmService) {}

  showPlConfirm() {
    this.plConfirmService.show({
      header: 'PL Confirm Component',
      content: `<div>
                   This is a demonstration of the PL Confirm Component
                    </div>`,
      confirmLabel: `Confirm`,
      cancelLabel: 'Cancel',
      onConfirm: () => {
        alert('Confirmed!');
      },
    });
  }

  showPlConfirmAlert() {
    this.showConfirmAlert = true;
  }
  cancelAlert() {
    this.showConfirmAlert = false;
  }
  confirmAlert() {
    this.showConfirmAlert = false;
  }
  sortOrderChanged(option: SortOption) {
    this.currentSortOption = option;
  }
}
