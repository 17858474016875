import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { RecordingStatusComponent } from './recording-status/recording-status.component';
import { SessionRecordingComponent } from './session-recording.component';
import { SessionPreviewComponent } from './session-preview.component';
import { SessionRecordingSnackbarComponent } from './recording-snackbar/session-recording-snackbar.component';

import { PLCommonComponentsModule } from '@common/components';
import { TokboxRecordService } from './tokbox-record.service';

const exportedComponents = [
  SessionRecordingComponent,
  SessionPreviewComponent,
  RecordingStatusComponent,
  SessionRecordingSnackbarComponent,
];

@NgModule({
  imports: [
    CommonModule,
    PLCommonComponentsModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatProgressSpinnerModule,
  ],
  exports: [...exportedComponents],
  declarations: [...exportedComponents],
  providers: [TokboxRecordService],
})
export class SessionRecordingModule {}
