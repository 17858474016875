import { Component, Input } from '@angular/core';
import { CardReveal, Player, InvestigationObj } from '../../clue-store';
import { Store } from '@ngrx/store';
import * as ClueActions from '../../clue-store/actions';

@Component({
  selector: 'player-hand',
  templateUrl: './player-hand.component.html',
  styleUrls: ['./player-hand.component.less'],
})
export class PLCluePlayerHandComponent {
  @Input() currentUser: Player;
  @Input() cardReveal: CardReveal;
  @Input() investigationObj: InvestigationObj;

  constructor(private store: Store<any>) {}

  public updateCardReveal(e) {
    if (
      !this.investigationObj.isAsking ||
      this.investigationObj.witness.userId !== this.currentUser.userId
    ) {
      e.preventDefault();
      return;
    }
    let tempCard = {
      ...this.cardReveal,
      card: {
        name: e.target.getAttribute('card-name'),
        icon: e.target.getAttribute('card-icon'),
      },
      isCardSelected: true,
    };
    this.store.dispatch(ClueActions.updateCardReveal({ cardReveal: tempCard }));
  }
}
