import {
  AfterViewInit,
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { CurrentUserModel } from '@common/models/CurrentUserModel';
import { LocalStorageService } from '@common/services/LocalStorageService';
import { AppState } from '@root/src/app/store';
import { PLActivityModelService } from '@root/src/app/modules/room/pl-activity/model/activity-model.service';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { debounce, propertyOf } from 'lodash';
import { selectDrawer } from '../../pl-drawers/store';
import { MemoryCardsService } from './services/cards.service';
import { PLMemoryBaseService } from './services/memory-base.service';
import { MEMORY_CARDS_OPTIONS } from './services/memory-card-options.constant';
import { CardDisplayOption } from '@common/components/card-display-options/card-display-options.component';
import {
  CentralContentType,
  selectCurrentCentralContent,
} from '../../app/store';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'pl-memory-activity',
  templateUrl: 'memory-activity.component.html',
  styleUrls: ['./memory-activity.component.less'],
  encapsulation: ViewEncapsulation.None,
  providers: [MemoryCardsService],
})
export class PLMemoryActivityComponent
  extends PLMemoryBaseService
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('memoryCardBoard') memoryCardBoard: ElementRef;
  isFirefox = false;
  initialized = false;
  bodyOffset: any = {};
  mousePosition = {
    x: 0,
    y: 0,
  };
  markerStyle: any = {};
  cardsServiceWillingToChangeVisibility = [];
  isGameActive = false;
  xRay = false;
  tempXRay = false;
  lastInsertPos: any;
  lastHighlightCard: any;
  focused: boolean;
  lastHoverCard: any;
  dropConfig: any;
  turnsLeft = 999;
  scale;
  asyncResize = debounce(() => {
    this.bodyOffset = $('.memorycard-board').offset();
    this.resizeHandler();
  }, 100);
  flipsStatusMessage: string;
  private subscriptions: Subscription[] = [];

  /**
   * @constructor
   * @param {Object} $scope
   * @param {Cards} cards
   * @param {Object} currentUserModel
   * @param {ActivityModel} activityModel
   * @param {LocalStorageService} localStorage
   * @param {Object} options
   * @param {Function} $timeout
   * @param {hotkeys} hotkeys
   */
  constructor(
    public cards: MemoryCardsService,
    currentUserModel: CurrentUserModel,
    activityModel: PLActivityModelService,
    localStorage: LocalStorageService,
    private store: Store<AppState>,
    private hotkeysService: HotkeysService,
    private host: ElementRef,
    private zone: NgZone,
  ) {
    super(activityModel, localStorage, currentUserModel);

    this.isFirefox = window.navigator.userAgent.indexOf('Firefox') > -1;

    this.hotkeysService.add(
      new Hotkey('alt', (event: KeyboardEvent) => {
        this.selectCards(event);
        return false; // Prevent bubbling
      }),
    );

    this.hotkeysService.add(
      new Hotkey(['backspace', 'del'], (event: KeyboardEvent) => {
        this.deleteSelectedCards(event);
        this.clearHighlightedIfClinician();
        return false; // Prevent bubbling
      }),
    );

    const animateUnmatched = indices => {
      (indices || []).forEach(index => {
        const domCard = $($('.memorycard-board div.card').get(index));

        domCard.addClass('ripple-in-fail');

        setTimeout(() => {
          domCard.removeClass('ripple-in-fail');
        }, 1000);
      });
    };

    const animateMatched = indices => {
      (indices || []).forEach(index => {
        const domCard = $($('.memorycard-board div.card').get(index));

        domCard.addClass('ripple-out');

        setTimeout(() => domCard.removeClass('ripple-out'), 1000);
      });
    };

    const onDataUpdate = (newData, oldData) => {
      this.isGameActive = newData.isGameActive;

      this.initialized = true;

      // This is how we prevent flickering on page load
      $('.memorycard-scale-wrapper').removeClass('hide');

      // animate unmatched
      if (
        newData.turnPaused &&
        newData.turnPaused !== oldData.turnPaused &&
        newData.unmatched &&
        newData.unmatched.length
      ) {
        animateUnmatched(newData.unmatched);
      }

      // animate matched cards
      if (newData.matched && newData.matched !== oldData.matched) {
        // Added timeout due to PL-1994 to run match animation after flip animation
        setTimeout(
          () => animateMatched(this.cards.getCardIndicesById(newData.matched)),
          250,
        );
      }

      this.updateFlipsStatusMessage();
    };

    this.cards
      .on('dataUpdate', onDataUpdate)
      .on('match', card =>
        animateMatched(
          (this.cards.getSimilar(card) || { indices: [] }).indices,
        ),
      )
      .on('matchFail', ids => animateUnmatched(this.cards.unmatched));

    this.cards.updateData();

    this.isClinician = this.isClinician.bind(this);

    this.getCardStyle = this.getCardStyle.bind(this);
    this.getCardClass = this.getCardClass.bind(this);
    this.setFocusedTrue = this.setFocusedTrue.bind(this);
    this.setFocusedFalse = this.setFocusedFalse.bind(this);
    this.setMousePosAndSelectCards = this.setMousePosAndSelectCards.bind(this);

    try {
      this.xRay = this.localStorage.get(this.KEY_XRAY) === 'true';
    } catch (e) {
      console.error('localStorage error in MemoryController');
    }

    this.subscriptions.push(
      store
        .select(selectCurrentCentralContent)
        .pipe(
          filter(
            content => content.contentType === CentralContentType.QueueItem,
          ),
        )
        .subscribe(content => {
          this.cards.currentActivty = content.contentDetail;
        }),
    );
  }

  ngOnInit() {
    this.initScale();
    this.initGlobalEvents();
  }

  ngAfterViewInit() {
    const observer = new ResizeObserver(entries => {
      this.zone.run(() => {
        this.asyncResize();
      });
    });
    observer.observe(this.memoryCardBoard.nativeElement);
  }

  ngOnDestroy() {
    this.cards.cleanModel();
    this.removeGlobalEvents();
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  get cardList() {
    return this.cards.toArray();
  }

  /**
   * Initializes cross-frame communication channel event
   * listeners
   */
  initChannel() {
    return this.getChannel().then((channel: any) => {
      channel.bind('xRayChanged', (e, value) => {
        this.xRay = value;
      });
      channel.bind('tempXRayChanged', (e, value) => {
        this.tempXRay = value;
      });

      channel.bind('animateCards', () => {
        this.cards.animated = true;
      });

      channel.bind('dragCardCancel', () => {
        this.lastInsertPos = null;
        this.markerStyle.display = 'none';
        this.cards.dehighlight();
      });
    });
  }

  updateFlipsStatusMessage() {
    if (!this.cards.maxFlipsPerTurn) {
      this.turnsLeft = 999;
      this.flipsStatusMessage = '';
      return 'unlimited flips';
    }

    this.turnsLeft = this.cards.maxFlipsPerTurn - this.cards.turnFlips;

    this.flipsStatusMessage = this.turnsLeft + ' flips left';
  }

  /**
   * Highlights all similar cards to the card under x,y coordinates ath the deck
   *
   * @param {number} x
   * @param {number} y
   */
  highlightByXY(x, y) {
    const scale = this.scale;
    const card = this.cards.getCardByXY(x, y, scale);

    if (card && this.lastHighlightCard !== card) {
      const similar = this.cards.getSimilar(card);

      if (similar) {
        this.cards.dehighlight().highlight(similar.indices);
      }

      this.lastHighlightCard = card;
    } else if (!card) {
      this.clearHighlighted();
    }
  }

  /**
   * Marks all cards dehighlighted if there is highlighed any
   */
  clearHighlighted() {
    if (this.cards.hasHighlighted()) {
      this.cards.dehighlight();
      this.lastHighlightCard = null;
    }
  }

  /**
   * Remove selected cards
   */
  deleteSelectedCards(e) {
    if (!this.isClinician()) {
      return;
    }
    this.cards.animated = false;
    this.cards.removeSelected().save();
    this.reanimate();
    e.preventDefault();
  }

  /**
   * Select cards
   */
  selectCards(e) {
    if (!this.isClinician()) {
      return;
    }
    e.preventDefault();
    this.highlightByXY(this.mousePosition.x, this.mousePosition.y);
  }

  /**
   *  clear highlighted
   */
  clearHighlightedIfClinician() {
    if (!this.isClinician()) {
      return;
    }
    this.clearHighlighted();
  }

  /**
   * set focused true
   */
  setFocusedTrue() {
    this.focused = true;
  }

  /**
   * set focused false
   */
  setFocusedFalse() {
    this.focused = false;
  }

  /**
   * Set mouse position and select cards
   */
  setMousePosAndSelectCards(e) {
    if (!this.focused) {
      window.focus();
    }

    this.mousePosition.x =
      (e.pageX || e.clientX || e.x) - (propertyOf(this.bodyOffset)('left') | 0);
    this.mousePosition.y =
      (e.pageY || e.clientY || e.y) - (propertyOf(this.bodyOffset)('top') | 0);

    if (e.altKey && this.isClinician()) {
      e.preventDefault();
      this.highlightByXY(this.mousePosition.x, this.mousePosition.y);
    } else {
      this.clearHighlighted();
    }
  }

  /**
   * Initializes global frame events
   *
   * @access private
   */
  initGlobalEvents() {
    this.mousePosition.x = 0;
    this.mousePosition.y = 0;

    $(window)
      .on('focus', this.setFocusedTrue)
      .on('blur', this.setFocusedFalse)
      .on('mousemove', this.setMousePosAndSelectCards);
  }

  removeGlobalEvents() {
    this.mousePosition.x = 0;
    this.mousePosition.y = 0;

    $(window)
      .off('focus', this.setFocusedTrue)
      .off('blur', this.setFocusedFalse)
      .off('mousemove', this.setMousePosAndSelectCards);
  }

  /**
   * Returns true if current deck mode set to x-ray, false otherwise
   *
   * @returns {boolean}
   */
  isXRay() {
    return this.isClinician() && (this.xRay || this.tempXRay);
  }

  /**
   * Returns CSS classes defined for the cards container
   *
   * @returns {string}
   */
  getContainerClass() {
    let classes = 'main card-' + this.cards.cardType;

    if (this.isXRay()) {
      classes += ' card-xray';
    }

    if (this.cards.animated) {
      classes += ' animated';
    }

    return classes;
  }

  /**
   * Dynamically calculates and returns classes for the card
   * by it's index in the model collection
   *
   * @param {number} index
   * @returns {string}
   */
  getCardClass(index) {
    let returnValue = 'card';

    if (!this.isGameActive) {
      returnValue += ' disabled';
    }

    if (this.cards.isHighlighted(index)) {
      returnValue += ' dragover';
    }

    if (!this.cards.isVisible(index)) {
      returnValue += ' back';
    }

    if (this.cards.isSelected(index)) {
      returnValue += ' selected';
    }

    if (!this.cards.get(index).thumbnail_url) {
      returnValue += ' no-image';
    }

    return returnValue;
  }

  /**
   * Dynamically calculates and returns styles for the card with the given
   * index in a cards collection
   *
   * @param {number} index
   * @returns {{top: number, left: number, width: number, height: number}}
   */
  getCardStyle(index) {
    const dims = this.cards.getPosition(index);

    return {
      top: `${dims.top}px`,
      left: `${dims.left}px`,
      fontSize: `${dims.width}px`,
    };
  }

  /**
   * Initializes scaling factor on controller init and
   * re-calculates it on window resize event
   *
   * @access private
   */
  initScale() {
    const body = $('.memorycard-board');

    this.bodyOffset = body.offset();

    const halfDrawerWidth = MEMORY_CARDS_OPTIONS.DRAWER_WIDTH / 2;

    this.store.select(selectDrawer).subscribe(state => {
      if (this.bodyOffset) {
        this.bodyOffset.left += state.open ? -halfDrawerWidth : halfDrawerWidth;
      }
    });

    this.resizeHandler();
  }

  resizeHandler = () => {
    const body = $('.memorycard-board');
    const html = $('html');
    const ratio =
      MEMORY_CARDS_OPTIONS.BOARD_WIDTH / MEMORY_CARDS_OPTIONS.BOARD_HEIGHT;

    const realWidth = body.width();
    const realHeight = body.height();
    const realRatio = realWidth / realHeight;

    const scale =
      ratio > realRatio
        ? realWidth / MEMORY_CARDS_OPTIONS.BOARD_WIDTH
        : realHeight / MEMORY_CARDS_OPTIONS.BOARD_HEIGHT;

    this.scale = scale;
    this.bodyOffset = body.offset();

    html
      .find('.memorycard-scale-wrapper')
      .css('transform', `scale3d(${scale}, ${scale}, ${scale})`);
  };

  /**
   * Card click handler
   *
   * @param {Event} $event
   * @param {number} $index
   */
  onCardClick($event, $index) {
    if (!this.isGameActive) return;
    this.cards.animated = true;

    if ($event.altKey && this.isClinician()) {
      return this.toggleSelection($index);
    }

    this.cards.deselect(null);

    if (!this.cards.turnPaused) {
      this.toggleVisibility($index);
    }
    this.updateFlipsStatusMessage();
  }

  /**
   * Toggles card selection state by its index
   *
   * @param {number} $index
   */
  toggleSelection($index) {
    const similar = this.cards.getSimilar($index);

    if (similar) {
      this.cards[
        this.cards.isSelected(similar.indices) ? 'deselect' : 'select'
      ](similar.indices);
    }
  }

  /**
   * Toggles card visibility by its index
   *
   * @param {number} $index
   */
  toggleVisibility($index) {
    if (this.cards.isVisible($index)) {
      if (this.cards.isOpenedInCurrentTurn($index)) {
        this.cards.setHidden($index, true).updateGameScores($index).save();
      }
    } else {
      this.cards.setVisible($index, true).updateGameScores(true).save();
    }
  }

  /**
   * Processes end of the turn of the cards game
   */
  endTurn() {
    if (this.cards.hasTurns()) {
      this.cards.endTurn().save();
    }
    this.updateFlipsStatusMessage();
  }

  /**
   * Returns title for end turn button depending on the current game state
   *
   * @returns {string}
   */
  endTurnTitle() {
    return this.cards.length &&
      this.cards.players.length &&
      !this.cards.hasTurns()
      ? 'Game Over'
      : 'End Turn';
  }

  /**
   * Returns score unit name for a given player
   *
   * @param {number} index - player index
   */
  getPlayerScore(index) {
    const score = this.cards.score[index] || 0;
    let similarsName =
      MEMORY_CARDS_OPTIONS.SIMILAR_NAMES[MEMORY_CARDS_OPTIONS.SIMILAR_LENGTH];

    similarsName =
      similarsName.charAt(0).toUpperCase() +
      similarsName.substr(1).toLowerCase();

    return `${score} ${
      score === 1 ? similarsName.replace(/s$/, '') : similarsName
    }`;
  }

  /**
   * Hadles card dragging event over the board
   *
   * @param {Event} e
   */
  dragCard({ event: e }) {
    const scale = this.scale || 1;
    const mouseX =
      (e.pageX || e.clientX || e.x) - (propertyOf(this.bodyOffset)('left') | 0);
    const mouseY =
      (e.pageY || e.clientY || e.y) - (propertyOf(this.bodyOffset)('top') | 0);
    const shapes = this.cards.getShapes();
    let pos = null;
    let type = 'insert';
    let insertIndex = -1;
    let cardIndex = -1;

    shapes.some((shape, i) => {
      Object.keys(shape).some(area => {
        let { top, left, bottom, right } = shape[area];

        top *= scale;
        left *= scale;
        bottom *= scale;
        right *= scale;

        if (
          mouseX >= left &&
          mouseX <= right &&
          mouseY >= top &&
          mouseY <= bottom
        ) {
          pos = {
            left: shape[area][area],
            top: shape[area].top,
          };

          type = area === 'main' ? 'replace' : 'insert';
          cardIndex = i;
          insertIndex = this.cards.cardsOrder.indexOf(i);

          if (area === 'right') {
            insertIndex++;
          }

          return true;
        }

        return false;
      });

      return !!pos;
    });

    if (
      !this.cards.length ||
      (this.cards.isFull() && (!pos || (pos && type === 'insert')))
    ) {
      if (this.cards.hasHighlighted()) {
        this.lastHoverCard = null;
        this.cards.dehighlight();
        this.dropConfig = null;
      }

      return;
    }

    if (!pos) {
      // insert by default at the end of cards
      cardIndex = shapes.length - 1;
      pos = (shapes[this.cards.cardsOrder[cardIndex]] || {}).right;

      if (pos) {
        pos = {
          left: pos.right,
          top: pos.top,
        };
      }

      type = 'insert';
      insertIndex = shapes.length;
    }

    switch (type) {
      case 'replace': {
        this.lastInsertPos = null;
        this.markerStyle.display = 'none';

        if (
          this.lastHoverCard &&
          this.lastHoverCard === this.cards.get(cardIndex)
        ) {
          return;
        }

        const similar = this.cards.getSimilar(cardIndex);

        if (similar) {
          this.cards.dehighlight().highlight(similar.indices);

          this.dropConfig = {
            type: 'replace',
            cards: similar.indices,
          };
        }

        this.lastHoverCard = this.cards.get(cardIndex);
        break;
      }
      case 'insert': {
        this.cards.dehighlight();
        this.lastHoverCard = null;

        if (this.lastInsertPos && this.lastInsertPos === pos) {
          return;
        }

        this.dropConfig = {
          type: 'insert',
          cards: insertIndex,
        };

        this.markerStyle.display = 'block';

        this.markerStyle.top =
          Math.round(pos.top + MEMORY_CARDS_OPTIONS.VERTICAL_PADDING / 2) +
          'px';
        this.markerStyle.left = Math.round(pos.left) + 'px';

        this.markerStyle.height =
          (this.cards.cardType === CardDisplayOption.ImageAndText
            ? this.cards.grid.cellHeight
            : this.cards.grid.cellWidth) + 'px';

        this.lastInsertPos = pos;
        break;
      }
    }
  }

  /**
   * Handles drop card event on a whiteboard
   *
   * @param {Event} e
   */
  dropCard({ event: e }) {
    this.cards.animated = false;
    this.markerStyle.display = 'none';
    this.cards.dehighlight();

    this.dropConfig = this.dropConfig || {};
    this.lastHoverCard = this.lastInsertPos = null;

    const type = this.dropConfig.type;
    const dropCardJson = e.dataTransfer.getData('memorycard');
    const cardsData = this.dropConfig.cards;
    const similar = Array(MEMORY_CARDS_OPTIONS.SIMILAR_LENGTH)
      .fill(undefined)
      .map(() => JSON.parse(dropCardJson));

    if (this.cards.isFull() && type !== 'replace') {
      return; // deny adding more then allowed
    }

    this.getChannel().then((channel: any) => {
      channel.call({
        method: 'cardDropped',
        params: JSON.parse(dropCardJson),
      });
    });

    switch (type) {
      case 'insert': {
        this.cards.insert(similar, cardsData);
        break;
      }
      case 'replace': {
        this.cards.replace(cardsData, similar);
        break;
      }
      default: {
        // simply add to the end
        this.cards.add(similar);
        break;
      }
    }

    this.cards.save();
    this.dropConfig = {};

    this.reanimate();
  }

  /**
   * Re-animate cards
   */
  reanimate() {
    setTimeout(() => {
      this.cards.animated = true;
      this.cards.save();
    });
  }

  getId(index, item) {
    return item.id;
  }
}
