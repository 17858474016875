<div class="pl-colorpicker">
  <button
    class="colorpicker-btn colorpicker-{{ label }}"
    (click)="toggleVisible()"
  >
    <figure [ngStyle]="{ 'background-color': color }"></figure>
    <span class="font-h6">{{ label | titlecase }}</span>
  </button>
  <pl-colorwheel [hidden]="!wheelVisible" (colorClicked)="colorClicked($event)">
  </pl-colorwheel>
</div>
