import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

@Directive({
  selector: '[plDrop]',
})
export class PLDropDirective {
  @Input() plDragData: any;
  @Input() plDrop: any;
  @Output() public readonly dragEnd = new EventEmitter<{
    event: any;
    data: any;
  }>();
  @Output() public readonly dragOver = new EventEmitter<{ event: any }>();
  @Output() public readonly dropped = new EventEmitter<{
    event: any;
    data: any;
  }>();
  @Output() public readonly dragCancel = new EventEmitter<{
    event: any;
    data: any;
  }>();
  private isDropped;

  @HostListener('dragover', ['$event']) onDragOver(e) {
    this.isDropped = false;
    e.preventDefault();
    this.dragOver.emit({ event: e });
  }

  @HostListener('drop', ['$event']) onDrop(e) {
    this.dropped.emit({ event: e, data: this.plDragData });
    this.isDropped = true;
  }

  @HostListener('dragend', ['$event']) onDragEnd(e) {
    this.dragEnd.emit({ event: e, data: this.plDragData });
    if (!this.isDropped === false) {
      this.dragCancel.emit({ event: e, data: this.plDragData });
    }
  }
}
