import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subscription } from 'rxjs';
import {
  ClientGoal,
  ClientObjective,
  StudentItem,
} from '../../documentation-te.model';

@Component({
  selector: 'pl-documentation-te-metrics',
  templateUrl: 'documentation-te-metrics.component.html',
  styleUrls: ['./documentation-te-metrics.component.less'],
})
export class DocumentationTEMetricsComponent implements OnInit, OnDestroy {
  @Input() public clientGoals: ClientGoal[];
  @Input() public sessionId: string;
  @Input() public clientEvent: StudentItem;
  @Output() readonly saveMetric = new EventEmitter<any>();

  objectiveOptions = [];
  selectedObjectives = [];
  objectivesDict: any = {};
  goalsDict: any = {};
  loadingDict: any = {};

  private subscriptions: Subscription[] = [];
  private timeout;

  ngOnInit() {
    this.objectiveOptions = [];
    this.clientGoals.forEach(goal => {
      this.goalsDict[goal.uuid] = goal;
      goal.objectives.forEach(obj => {
        this.objectivesDict[obj.uuid] = obj;
        this.objectiveOptions.push({
          label: obj.description,
          value: obj.uuid,
        });
        if (obj.values.correct || obj.values.incorrect) {
          this.selectedObjectives.push(obj.uuid);
        }
      });
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onMetricChanged(objective: ClientObjective) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      this.loadingDict[objective.uuid] = true;
      this.saveMetric.emit({
        objective,
        metricCallback: savedObjective => {
          objective.values.uuid = savedObjective.uuid;
          this.loadingDict[objective.uuid] = false;
        },
      });
    }, 500);
  }
}
