import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'pl-confirm-alert',
  templateUrl: 'pl-confirm-alert.component.html',
  styleUrls: ['./pl-confirm-alert.component.less'],
})
export class PLConfirmAlertComponent {
  @Input() public cancelText = 'No';
  @Input() public confirmText = 'Yes';
  @Output() readonly cancel = new EventEmitter<void>();
  @Output() readonly confirm = new EventEmitter<void>();
}
