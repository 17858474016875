import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
@Component({
  selector: 'pl-colorwheel',
  templateUrl: './colorwheel.component.html',
  styleUrls: ['./colorwheel.component.less'],
})
export class ColorwheelComponent implements AfterViewInit {
  @ViewChild('canvas') canvas: ElementRef;
  @Output() readonly colorClicked = new EventEmitter<string>();

  readonly COLORS = [
    '#d7ec45',
    '#74b842',
    '#1b9dd2',
    '#1458fb',
    '#4615ab',
    '#9019b6',
    '#CF1EAD',
    '#FF6C98',
    '#af2056',
    '#fc3023',
    '#fb5f21',
    '#faa328',
    '#fbc42d',
    '#fefd50',
    '#F6E6B5',
    '#D0AD76',
    '#906740',
  ];

  readonly INNER_COLORS = ['#747678', 'black', 'white', '#EDEEEF'];
  center = {
    x: 100,
    y: 106,
  };
  radius = 70;
  centerRadius = 15;
  strokeThickness = 40;
  ctx: any;

  ngAfterViewInit() {
    this.ctx = this.canvas.nativeElement.getContext('2d');
    this.drawBasicWheel();
  }

  drawCenter(color) {
    this.ctx.beginPath();
    this.ctx.arc(
      this.center.x,
      this.center.y,
      this.centerRadius,
      0,
      2 * Math.PI,
    );
    this.ctx.fillStyle = color;
    this.ctx.fill();
  }

  drawBasicWheel() {
    let segments = this.COLORS.length;
    let radOffset = (2 * Math.PI) / segments / 2;
    let radsPerSeg = (2 * Math.PI) / segments;
    for (let i = 0; i < segments; i++) {
      const startRads = radOffset + i * radsPerSeg;
      const endRads = radOffset + (i + 1) * radsPerSeg;
      this.ctx.beginPath();
      this.ctx.arc(
        this.center.x,
        this.center.y,
        this.radius,
        startRads,
        endRads,
      );
      this.ctx.lineWidth = this.strokeThickness;
      this.ctx.strokeStyle = this.COLORS[i];
      this.ctx.stroke();
    }

    segments = this.INNER_COLORS.length;
    radOffset = (2 * Math.PI) / segments / 2;
    radsPerSeg = (2 * Math.PI) / segments;

    for (let i = 0; i < segments; i++) {
      const startRads = radOffset + i * radsPerSeg;
      const endRads = radOffset + (i + 1) * radsPerSeg;
      this.ctx.beginPath();
      this.ctx.arc(this.center.x, this.center.y, 30, startRads, endRads);
      this.ctx.lineWidth = this.strokeThickness;
      this.ctx.strokeStyle = this.INNER_COLORS[i];
      this.ctx.stroke();
    }
  }

  getColorUnderPt = (x, y) => {
    const canvasElement: HTMLElement = this.canvas.nativeElement;
    const rect = canvasElement.getBoundingClientRect();
    const canvasX = Math.floor(x - rect.left);
    const canvasY = Math.floor(y - rect.top);

    // is the point in the center circle?
    const ptInCircle =
      Math.pow(canvasX - this.center.x, 2) +
        Math.pow(canvasY - this.center.y, 2) <=
      Math.pow(this.centerRadius, 2);
    if (ptInCircle) {
      return 'transparent';
    }
    const imageData = this.ctx.getImageData(canvasX, canvasY, 1, 1);
    const pixel = imageData.data;

    // Convert the pixel object into a color
    let hexColor = '#';
    for (let i = 0; i < 3; i++) {
      let hexVal = pixel[i].toString(16);
      hexVal = hexVal.length === 2 ? hexVal : `0${hexVal}`;
      hexColor += hexVal;
    }
    return hexColor;
  };

  // // this has to be in the wheel directive so it gets cleaned up correctly
  // // when the wheel is removed
  // hotkeys.bindTo($scope)
  //     .add({
  //         combo: 'escape',
  //         callback: (e) => {
  //             e.preventDefault();
  //             e.stopPropagation();
  //             colorpickerController.setVisible(false);
  //         },

  //     });

  canvasClicked = e => {
    e.preventDefault();
    e.stopPropagation();
    const color = this.getColorUnderPt(e.clientX, e.clientY);
    this.colorClicked.emit(color);
  };
}
