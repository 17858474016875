<ng-container *ngIf="clientAppointment">
  <mat-expansion-panel
    class="documentation-appointment-item"
    [expanded]="expanded"
    (opened)="openMe()"
    (closed)="closeMe()">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="appointment-title">
          <button
            mat-icon-button
            matTooltip="View client record"
            (click)="avatarClicked($event)">
            <mat-icon>account_circle</mat-icon>
          </button>
          <span
            >{{ clientAppointment.first_name }}
            {{ clientAppointment.last_name }}</span
          >
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-tab-group
      [selectedIndex]="itemState.selectedTabIndex"
      (selectedIndexChange)="onTabChanged($event)"
      mat-stretch-tabs
      class="is-compact three-tabs"
      color="accent">
      <mat-tab label="Details">
        <div class="tab-content"></div>
        <pl-service-details
          [requiresService]="clientServiceRequired"
          [services]="services"
          [isEvaluation]="isEvaluationAppointment"
          [clientAppointment]="clientAppointment"
          [serviceSelectOptions]="serviceSelectOptions"
          [selectedServiceId]="selectedServiceId"
          [billingCodeSelectOptions]="billingCodeSelectOptions"
          [selectedBillingCode]="selectedBillingCode"
          (billingCodeSelected)="billingCodeSelected($event)"
          (serviceSelected)="serviceSelected($event)"
          (makeUpChoiceChanged)="onMakeUpChoiceChanged($event)"
          (openEventDetails)="onOpenEventDetails()"></pl-service-details>
      </mat-tab>

      <mat-tab
        label="Progress"
        [disabled]="!this.services?.length || !showMetrics">
        <div class="tab-content">
          <pl-metrics-tracking
            (metricsUpdated)="onMetricsUpdated($event)"
            [clientAppointment]="clientAppointmentWithRecord"
            [provider]="provider"
            [service]="selectedService"></pl-metrics-tracking>
        </div>
      </mat-tab>

      <mat-tab label="Notes">
        <div class="tab-content">
          <pl-presence-appointment-item-notes
            [optionalSOAPNotes]="isOptionalSOAPNotes"
            [(currentDisplayedNote)]="itemState.selectedNotesTab"
            (currentDisplayedNoteChange)="onDisplayedNoteChanged($event)"
            [isEvaluation]="isEvaluationAppointment"
            [isSavingNotes]="isSaving"
            [isSigningLoading]="isSigningLoading"
            [(notes)]="clientAppointment.record.notes"
            [noteSchema]="noteSchema"
            [metricPoints]="metricPoints"
            [isSigned]="clientAppointment.record.signed"
            (isSignedChange)="onSignChange($event)"
            (notesChange)="onNotesChanged($event)"
            (openEventDetails)="
              onOpenEventDetails()
            "></pl-presence-appointment-item-notes>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-expansion-panel>
</ng-container>
