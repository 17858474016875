import { Component, Inject } from '@angular/core';
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';

@Component({
  templateUrl: './session-recording-snackbar.component.html',
  styleUrls: ['./session-recording-snackbar.component.less'],
})
export class SessionRecordingSnackbarComponent {
  constructor(
    private snackBarRef: MatSnackBarRef<SessionRecordingSnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
  ) {}

  openEvents() {
    this.snackBarRef.dismiss();
    window.open(this.data.clientEventsUrl, '_blank');
  }
}
