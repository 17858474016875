<div class="drawer-games" [ngClass]="{ 'panel-2-active': detailView }">
  <header class="drawer-header">
    <div class="drawer-header-left">
      <mat-icon
        *ngIf="detailView"
        (click)="leaveDetail()"
        class="icon-gray"
        role="button"
      >
        arrow_back
      </mat-icon>
      <h3 class="drawer-header__title">Games</h3>
    </div>
  </header>

  <div class="panel-1 game-shelf-list">
    <pl-board-game-select-button
      *ngFor="let game of boardGamesList"
      [game]="game"
      (boardGameSelectClicked)="loadBoardGame($event)"
    >
    </pl-board-game-select-button>
  </div>

  <div class="panel-2 game-shelf-list">
    <main class="drawer-ui">
      <div class="drawer-subheader" *ngIf="currentGame">
        <h4
          class="drawer-subheader__title"
          *ngIf="currentGame.displayName.length < 50"
        >
          {{ currentGame.displayName }}
        </h4>
        <h4
          class="drawer-subheader__title"
          *ngIf="currentGame.displayName.length > 50"
          matTooltip="{{ currentGame.displayName }}"
        >
          {{ currentGame.displayName }}
        </h4>
      </div>
      <pl-activity-switch-button
        *ngIf="currentGame"
        [contentType]="'game'"
        [contentDetail]="currentGame"
      >
      </pl-activity-switch-button>
      <pl-board-game-controls
        [gameName]="currentGame ? currentGame.name : null"
      >
      </pl-board-game-controls>
    </main>
  </div>
</div>
