import { Component, Input, ViewChild, AfterViewInit } from '@angular/core';

import { CurrentUserModel } from '@common/models/CurrentUserModel';
import { IntrojsService } from '@common/services/introjs.service';
import { PLDrawerPanelComponent } from '../pl-drawer-panel/pl-drawer-panel.component';
@Component({
  selector: 'pl-room-help-drawer',
  templateUrl: './pl-room-help-drawer.component.html',
  styleUrls: ['./pl-room-help-drawer.component.less'],
})
export class PLRoomHelpDrawerComponent implements AfterViewInit {
  @ViewChild(PLDrawerPanelComponent) linkPanel: PLDrawerPanelComponent;

  @Input() active = false;
  screenshareElement: any;

  defaultCopyButtonText = 'Copy URL';
  copyButtonText = this.defaultCopyButtonText;

  username = '';

  isSSP = false;

  constructor(
    private currentUser: CurrentUserModel,
    private introjsService: IntrojsService,
  ) {
    this.isSSP = this.currentUser.user.groups.some(
      (g: any) => g.indexOf('School Staff') >= 0,
    );
    const user: any = this.currentUser.user;
    this.username = user.username;
  }

  ngAfterViewInit() {
    this.linkPanel.open();
  }

  copyUrl() {
    const url = `https://room.presencelearning.com/${this.username}`;
    navigator.clipboard.writeText(url);
    this.copyButtonText = 'URL Copied!';
    setTimeout(() => {
      this.copyButtonText = this.defaultCopyButtonText;
    }, 2000);
  }

  startTour() {
    this.introjsService.roomOverview();
  }
}
