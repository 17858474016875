<div class="assumed-login-bar" [ngClass]="{ expanded: isExpanded }">
  <div class="assumed-login-bar__tab" (click)="onClickTab()">
    <div class="assumed-login-bar__tab-visual">
      <pl-icon-new
        [name]="isExpanded ? 'chevron-down' : 'chevron-up'"
        class="chevron-icon"
      ></pl-icon-new>
    </div>
  </div>
  <div class="assumed-login-bar__body">
    <p>You are currently working on behalf of {{ username }}</p>
    <button
      mat-stroked-button
      class="release-user-btn is-small"
      (click)="onClickReleaseUser()"
    >
      Release {{ username }}
    </button>
  </div>
</div>
