import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import * as PDFJS from 'pdfjs-dist/build/pdf';

import { BookModes } from '../../room/pl-activity/pl-pdf-activity/drawer/pdf-viewer-drawer.component';
import { throttle } from 'lodash';

@Component({
  selector: 'pl-pdf-drawer-pages',
  templateUrl: './pdf-drawer-pages.component.html',
  styleUrls: ['./pdf-drawer-pages.component.less'],
})
export class PdfDrawerPagesComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('pdfPages') private pagesRef: ElementRef<HTMLElement>;

  @Input() currentPage: number;
  @Input() totalPages: number;
  @Input() actualPageNumbers: number[] = [];
  @Input() hasThumbs: boolean;
  @Input() thumbnailIcon: string;
  @Input() thumbs: string[] = [];
  @Input() bookMode: BookModes = BookModes.SINGLE_PAGE_MODE;
  @Input() pageRotations: Record<string, number> = {};

  @Output() goToPage = new EventEmitter<number>();
  @Output() pdfLoaded = new EventEmitter<any>();

  private loadingTasks = [];
  private pdfDoc = null;

  isLoading = true;
  bookModeEnum = BookModes;
  isPageLoading: Record<number, boolean> = {};
  pages: number[] = [];
  doublePagesEven: number[][] = [];
  doublePagesOdd: number[][] = [];
  doublePages: number[][] = [];
  pagesRendered: number[] = [];

  throttleRenderThumbnails = throttle(() => this.renderThumbnails(), 100);

  ngOnInit(): void {
    if (this.totalPages) {
      this.configurePages();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.bookMode?.currentValue !== changes.bookMode?.previousValue) {
      this.onModeChanged(changes.bookMode.currentValue);
    }

    if (
      changes.totalPages?.currentValue &&
      changes.totalPages.currentValue !== changes.totalPages?.previousValue
    ) {
      this.onTotalPagesChanged();
    }

    if (
      changes.currentPage?.currentValue !== changes.currentPage?.previousValue
    ) {
      this.onCurrentPageChanged(changes.currentPage.currentValue);
    }

    if (
      changes.pageRotations?.currentValue !==
      changes.pageRotations?.previousValue
    ) {
      this.onPageRotationsChanged(
        changes.pageRotations?.currentValue,
        changes.pageRotations?.previousValue,
      );
    }
  }

  ngOnDestroy() {
    this.loadingTasks.forEach(lt => lt.destroy());
    if (this.pdfDoc) {
      this.pdfDoc.destroy();
    }
    this.pdfDoc = null;
  }

  onModeChanged(mode: BookModes) {
    this.pagesRendered = [];
    if (mode === BookModes.DBL_PAGE_EVEN_MODE) {
      this.doublePages = this.doublePagesEven;
    } else if (mode === BookModes.DBL_PAGE_ODD_MODE) {
      this.doublePages = this.doublePagesOdd;
    }
  }

  onTotalPagesChanged() {
    this.configurePages();
  }

  onCurrentPageChanged(currentPage: number) {
    this.scrollToPage(currentPage);
  }

  onPageRotationsChanged(
    currentPageRotations: Record<string, number> = {},
    prevPageRotations: Record<string, number> = {},
  ) {
    // If we detect that page's rotations have changed, we need to re-render
    // so we remove the page from the list of pages that have already been rendered
    Object.keys(currentPageRotations).forEach(pageKey => {
      if (currentPageRotations[pageKey] !== prevPageRotations[pageKey]) {
        const pageNum = Number(pageKey.slice(4));
        this.pagesRendered = this.pagesRendered.filter(p => p !== pageNum);
      }
    });
  }

  // This method is used to trigger a rerender of the pages
  // from the parent component, e.g. when material tab is activated
  // due to how it works and the fact we can't rely on the persisted DOM state
  triggerRerender() {
    // We're using throttled version of `renderThumbnails`
    // because scroll event also triggers it, this way we don't render same content twice
    this.throttleRenderThumbnails();
    this.scrollToPage(this.currentPage);
  }

  async loadPDF(url) {
    const task = PDFJS.getDocument(url);
    this.loadingTasks.push(task);

    const pdf = await task.promise;
    this.pdfDoc = pdf;
    this.pdfLoaded.emit(this.pdfDoc);
    await new Promise(r => setTimeout(r));
    await this.scrollToPage(this.currentPage);
    await this.renderThumbnails();
  }

  handleGoToPage(pageNum: number) {
    this.goToPage.emit(pageNum);
  }

  getThumbStyle = (pageNum: number, isHalf = false) => {
    const style: any = {};
    if (this.pageRotations) {
      const degrees = this.pageRotations['page' + pageNum] || 0;
      if (degrees) {
        style.transform = `rotate(${degrees}deg)`;
        if ((Math.abs(degrees) / 90) % 2 !== 0) {
          // We have to hack boundries of roated image to properly fit in the avaiable
          // area after being rotated.
          style.width = '109px';
          style.height = isHalf ? '72px' : '160px';
        }
      }
    }
    return style;
  };

  private configurePages() {
    // When we configure pages everything is already loaded, be it thumbnails or PDF
    this.isLoading = false;

    this.pages = [];
    this.doublePagesEven = [];
    this.doublePagesOdd = [];

    for (let i = 1; i <= this.totalPages; i++) {
      this.pages.push(i);
    }

    for (let i = 1; i <= this.totalPages; i = i + 2) {
      if (i + 1 <= this.totalPages) {
        this.doublePagesOdd.push([i, i + 1]);
      } else {
        this.doublePagesOdd.push([i]);
      }
    }

    this.doublePagesEven.push([1]);
    for (let i = 2; i < this.totalPages; i = i + 2) {
      if (i + 1 <= this.totalPages) {
        this.doublePagesEven.push([i, i + 1]);
      } else {
        this.doublePagesEven.push([i]);
      }
    }

    if (this.totalPages % 2 === 0) {
      this.doublePagesEven.push([this.totalPages]);
    }

    if (this.bookMode === BookModes.DBL_PAGE_EVEN_MODE) {
      this.doublePages = this.doublePagesEven;
    } else if (this.bookMode === BookModes.DBL_PAGE_ODD_MODE) {
      this.doublePages = this.doublePagesOdd;
    }
  }

  private async scrollToPage(pageNum = 1) {
    // Ensures that elements are rendered before scrolling.
    await new Promise(resolve => setTimeout(resolve, 50));

    const thumbnailNum = this.getThumbnailNumForPage(pageNum);
    const main = this.pagesRef.nativeElement;
    const thumbnail = main.querySelector(
      `.js-pdf-page:nth-child(${thumbnailNum})`,
    ) as HTMLElement;

    if (!thumbnail) {
      return;
    }

    thumbnail.scrollIntoView({
      block: 'center',
      behavior: this.isPageInViewport(pageNum) ? 'smooth' : 'auto',
    });
  }

  private getThumbnailNumForPage(pageNum: number): number {
    let thumbnailNum = pageNum;

    if (this.bookMode === BookModes.DBL_PAGE_EVEN_MODE) {
      thumbnailNum = Math.floor(thumbnailNum / 2 + 1);
    } else if (this.bookMode === BookModes.DBL_PAGE_ODD_MODE) {
      thumbnailNum = Math.ceil(thumbnailNum / 2);
    }

    return thumbnailNum;
  }

  private isPageInViewport(pageNum: number): boolean {
    const thumbnailNum = this.getThumbnailNumForPage(pageNum);

    const main = this.pagesRef.nativeElement;
    const thumbnail = main.querySelector(
      `.js-pdf-page:nth-child(${thumbnailNum})`,
    ) as HTMLElement;

    if (!thumbnail) {
      return false;
    }

    const { bottom, height, top } = thumbnail.getBoundingClientRect();
    const containerRect = main.getBoundingClientRect();

    return top <= containerRect.top
      ? containerRect.top - top <= height
      : bottom - containerRect.bottom <= height;
  }

  private renderThumbnails() {
    if (this.hasThumbs || !this.pdfDoc) {
      return;
    }

    const promises = [];

    for (let i = 0; i < this.totalPages; i++) {
      const pageNum = i + 1;

      if (this.isPageInViewport(pageNum)) {
        promises.push(this.renderPage(pageNum));
      }
    }

    return Promise.all(promises);
  }

  private renderPage(pageNum: number) {
    const main = this.pagesRef.nativeElement;

    return new Promise<void>((resolve, reject) => {
      if (this.pagesRendered.includes(pageNum)) {
        resolve();
        return;
      }
      this.pagesRendered.push(pageNum);

      const canv = main.querySelector(
        `#pageCanvas${pageNum}`,
      ) as HTMLCanvasElement;

      this.isPageLoading[pageNum] = true;

      if (!this.pdfDoc || !canv) {
        console.error(`bailing on renderPage #: ${pageNum}
                            pdf:${this.pdfDoc},
                            canv: ${canv}`);
        reject();
      }

      const rotationKey = Object.keys(this.pageRotations).find(
        pageToRotate => parseInt(pageToRotate.slice(4), 10) === pageNum,
      );

      let rotation = this.pageRotations[rotationKey];

      if (!rotation) {
        rotation = 0;
      }

      const containerEl = canv.parentElement;
      let containerWidth = containerEl.clientWidth;
      let containerHeight = containerEl.clientHeight;

      if (
        this.bookMode === BookModes.DBL_PAGE_ODD_MODE ||
        (this.bookMode === BookModes.DBL_PAGE_EVEN_MODE && pageNum !== 1)
      ) {
        containerWidth = containerWidth / 2 - 2;
      }
      try {
        this.pdfDoc
          .getPage(this.actualPageNumbers[pageNum - 1] || pageNum)
          .then(page => {
            const initialViewport = page.getViewport({
              scale: 1.0,
              rotation,
            });

            const viewportScale = this.calcScale(
              initialViewport.width,
              initialViewport.height,
              containerWidth,
              containerHeight,
              1,
            );

            const viewport = page.getViewport({
              scale: viewportScale,
              rotation,
            });

            canv.width = viewport.width;
            canv.height = viewport.height;

            const context = canv.getContext('2d');
            context.clearRect(0, 0, canv.width, canv.height);
            context.beginPath();

            const renderContext = {
              viewport,
              canvasContext: context,
            };

            return page.render(renderContext);
          })
          .then(() => {
            this.isPageLoading[pageNum] = false;
            resolve();
          });
      } catch (e) {
        console.error('pdf-drawer-pages -> renderPage()', e);
        resolve();
      }
    });
  }

  private calcScale(pdfW, pdfH, containerW, containerH, scale) {
    let newScale = scale;
    let scaleX;
    let scaleY;

    if (pdfW < containerW && pdfH < containerH) {
      scaleX = containerW / pdfW;
      scaleY = containerH / pdfH;
      newScale = Math.min(scaleX, scaleY) * scale;
    } else if (pdfW > containerW && pdfH > containerH) {
      scaleX = containerW / pdfW;
      scaleY = containerH / pdfH;
      newScale = Math.min(scaleX, scaleY) * scale;
    } else if (pdfW < containerW && pdfH > containerH) {
      // it's taller than the container, make the height smaller
      scaleY = containerH / pdfH;
      newScale = scaleY * scale;
    } else if (pdfW > containerW && pdfH < containerH) {
      // it's wider than the container, make it narrower
      scaleX = containerW / pdfW;
      newScale = scaleX * scale;
    }

    return newScale;
  }
}
