import { createReducer, on } from '@ngrx/store';
import { DrawerState, initialState } from './drawers.state';
import { DrawerActions, DrawerOpenPayload } from './drawers.actions';

function openAction(_state: DrawerState, action: DrawerOpenPayload) {
  return {
    open: true,
    activeName: action.drawerName,
  };
}

function closeAction(_state: DrawerState, action: DrawerOpenPayload) {
  return {
    open: false,
    activeName: action.drawerName,
  };
}

export const reducer = createReducer(
  initialState,
  on(DrawerActions.init, (_, { open, activeName }) => {
    return {
      open,
      activeName,
    };
  }),
  on(DrawerActions.open, openAction),
  on(DrawerActions.close, closeAction),
  on(DrawerActions.toggle, state => {
    if (!state.open) {
      return openAction(state, { drawerName: state.activeName });
    }
    return closeAction(state, { drawerName: state.activeName });
  }),
);
