<div class="action-container">
  <pl-stream-action
    *ngIf="
      (showButton$ | async) &&
      !loading && {
        screenshotting: isScreenshotting$ | async,
        capturing: isCapturing$ | async
      } as state
    "
    [disabled]="state.capturing"
    [svgIcon]="state.screenshotting ? 'capture' : 'screenshot'"
    [label]="state.screenshotting ? 'Capture' : 'Screenshot'"
    (action)="state.screenshotting ? capture() : startScreenshotting()"
  >
  </pl-stream-action>

  <div *ngIf="loading">
    <mat-progress-spinner
      diameter="24"
      mode="indeterminate"
    ></mat-progress-spinner>
    <div>Loading</div>
  </div>
</div>
