import { Injectable } from '@angular/core';

import { PLUrlsService, PLHttpService } from '@common/services/pl-http';

import { DRFModel } from './DRFModel.service';

@Injectable({ providedIn: 'root' })
export class DRFActivityModel extends DRFModel {
  tags: string[] = [];
  constructor(http: PLHttpService, plUrls: PLUrlsService) {
    super(`${plUrls.urls.platformFE}/api/v1/activity/`, http);
  }
  use(token) {
    if (!this.isSaved()) {
      throw new Error("This model hasn't been saved yet.");
    }
    this.setKey('use');
    return this.save({}, token);
  }
}
