<div [ngSwitch]="participantStatus$ | async">
  <ng-container *ngSwitchCase="'entering'">
    <span>Client entering Room...</span>
  </ng-container>
  <ng-container *ngSwitchCase="'entering-timeout'">
    <span>Client not responding</span>
  </ng-container>
  <ng-container
    *ngSwitchCase="'joined'"
    [ngSwitch]="showNotHiddenStates$ | async"
  >
    <ng-container *ngSwitchCase="true">
      <span *ngIf="isOffline$ | async">Not connected</span>
      <span *ngIf="isConferenceConnecting$ | async">Connecting to Video</span>
      <span *ngIf="isConferenceDisconnected$ | async"
        >Not connected to video</span
      >
      <span *ngIf="isNotViewingPage$ | async">Client not viewing Room</span>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <span *ngIf="isHidden$ | async">Video off</span>
      <span *ngIf="isVideoStarting$ | async">Starting video</span>
    </ng-container>
  </ng-container>
</div>
