<div class="session-recording">
  <div [hidden]="isRecording$ | async">
    <div *ngIf="canRecordClient; else showConsentMessage">
      <pl-alert-message>
        <div>
          A recording consent form has been uploaded for this client. You may
          record this evaluation session.
        </div>
        <div class="w-100">
          <div
            class="waiting"
            *ngIf="waitingToStartRecording; else notWaitingToStart">
            <mat-spinner [diameter]="44"></mat-spinner>
          </div>
          <ng-template #notWaitingToStart>
            <button
              mat-flat-button
              color="success"
              class="w-100"
              (click)="showStartRecordingDialog()">
              Start recording
            </button>
          </ng-template>
        </div>
      </pl-alert-message>
    </div>
    <ng-template #showConsentMessage>
      <pl-alert-message>
        <div>
          No recording consent form found for this client. You must upload a
          signed consent form to their record before you can record evaluation
          sessions.
        </div>
        <div>
          <a
            href="https://presencelearning.helpjuice.com/record-the-room"
            target="_blank">
            Read about session recording in the Presence Knowledge Base
          </a>
        </div>
      </pl-alert-message>
    </ng-template>
  </div>

  <div class="center" [hidden]="(isRecording$ | async) === false">
    <pl-alert-message>
      <div class="recording-message">
        You are currently recording this session.
      </div>
      <div class="margin-t" id="screenshare-preview" #screensharePreview>
        <pl-session-preview></pl-session-preview>
      </div>
      <div class="w-100">
        <div
          class="waiting"
          *ngIf="waitingToStopRecording; else notWaitingToStop">
          <mat-spinner [diameter]="44"></mat-spinner>
        </div>
        <ng-template #notWaitingToStop>
          <button
            mat-flat-button
            color="warn"
            (click)="stopRecording()"
            class="w-100">
            Stop recording
          </button>
        </ng-template>
      </div>
    </pl-alert-message>
  </div>
</div>
