import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { Store } from '@ngrx/store';

import { PLHttpService, PLUrlsService } from '@common/services/pl-http';

import { selectCurrentUser } from '@modules/user/store';
import { AppState } from '@app/store';

import { Subscription } from 'rxjs';
import { CurrentUserModel } from '@common/models/CurrentUserModel';
import * as isUrl from 'is-url';
import { FirebaseService } from '@root/src/app/common/firebase/firebase.service';
import { debounce } from 'lodash';

@Component({
  selector: 'pl-queue-add',
  templateUrl: './pl-queue-add.component.html',
  styleUrls: ['./pl-queue-add.component.less'],
})
export class PLQueueAddComponent implements OnInit, OnDestroy {
  @ViewChild('quickYoutube', { static: false }) quickYoutube;

  @Input() activeQueueId: string;
  @Input() isIntegratedLibraryEnabled: boolean;
  @Output() openLibrary = new EventEmitter<any>();

  private readonly OFFICIAL_SECTION = 'pldrl';
  private readonly COMMUNITY_SECTION = 'ugc';
  private readonly MY_ACTIVITIES_SECTION = 'own';
  private user: any;
  private subscription: Subscription;

  filterOpts: any = {
    activitySection: this.OFFICIAL_SECTION,
    activityType: 'all',
    search: '',
  };

  defaultSelectOptsTypes: any[] = [
    { value: 'all', label: 'All activity types' },
    { value: 'pdfviewer', label: 'Document' },
    { value: 'flashcards', label: 'Flashcard' },
    { value: 'memory', label: 'Memory' },
    { value: 'youtube', label: 'Video' },
  ];
  assessmentOpt = { value: 'assessment', label: 'Assessment' };
  lessonOpt = { value: 'lesson', label: 'Lessons' };
  gameOpt = { value: 'game', label: 'Game' };

  selectOptsTypes: any[] = this.defaultSelectOptsTypes;

  selectOptsSections: any[] = [
    { value: this.OFFICIAL_SECTION, label: 'Presence Library' },
    { value: this.MY_ACTIVITIES_SECTION, label: 'My Activities' },
  ];

  items: any[] = [];
  loading = false;
  blockGetItems = false;

  queuesRef: any;
  queueItemsById: any = {};
  queueName = '';
  libraryUrl = '';

  activityBase = {
    age_level_max: 8,
    age_level_min: -1,
    disabled: false,
    favorite: false,
  };

  debouncedGetItems = debounce(this.getItems, 300);

  constructor(
    private plHttp: PLHttpService,
    private plUrls: PLUrlsService,
    private store: Store<AppState>,
    private currentUser: CurrentUserModel,
    private firebaseService: FirebaseService,
  ) {}

  ngOnInit() {
    this.queuesRef = this.firebaseService.getRoomRef('activities/queues');
    this.libraryUrl = this.plUrls.urls.libraryFE;

    this.subscription = this.store.select(selectCurrentUser).subscribe(user => {
      if (user && user.groups) {
        this.user = user;

        this.setActivityTypesOptions();
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getQueueItems() {
    if (this.activeQueueId) {
      this.queueItemsById = {};
      // Get existing queue items to pre-select them in search results if
      // already in queue.
      const queueRef = this.firebaseService.getRoomRef(
        `activities/queues/items/${this.activeQueueId}`,
      );

      queueRef.once('value', ref => {
        const queue = ref.val();
        this.queueName = queue.name;
        const queueItems = {};

        for (const key of Object.keys(queue.items ?? {})) {
          const item = queue.items[key];
          // Use a hash for quick look up.
          queueItems[queue.items[key].id] = {
            id: item.id,
            activityId: item.activityId,
            type: item.type,
          };
        }
        this.queueItemsById = queueItems;
      });
    }
  }

  youtubeQuickstart() {
    this.quickYoutube.open();
  }

  activityTypeChanged() {
    this.filterOpts.search = '';
    this.getItems();
  }

  getItems(evt: any = {}) {
    this.getQueueItems();
    if (!this.blockGetItems) {
      this.loading = true;
      const urlBase = `${this.plUrls.urls.platformFE}/api/v1/`;
      let url = `${urlBase}activity/`;
      const params: any = {
        disabled: false,
        private: false,
        facet: 'all',
        section: this.OFFICIAL_SECTION,
      };
      if (this.filterOpts.activitySection) {
        if (this.filterOpts.activitySection === this.MY_ACTIVITIES_SECTION) {
          params.private = true;
          params.section = this.COMMUNITY_SECTION;
          params.owner__uuid = this.currentUser.user.uuid;
        } else {
          params.section = this.filterOpts.activitySection;
        }
      }
      if (
        this.filterOpts.activityType &&
        this.filterOpts.activityType !== 'all'
      ) {
        if (this.filterOpts.activityType === 'assessment') {
          url = `${urlBase}assessment/`;
        } else if (this.filterOpts.activityType === 'game') {
          url = `${urlBase}game/`;
        } else if (this.filterOpts.activityType === 'lesson') {
          url = `${urlBase}lesson/`;
        } else {
          params.activity_type = this.filterOpts.activityType;
        }
      }
      if (this.filterOpts.search) {
        params.q = this.filterOpts.search;
      }
      // Filter out image decks and record forms.
      params.exclude_activity_type = 'imageDeck,recordform';
      this.plHttp.get('', params, url).subscribe((data: any) => {
        const items = data.results;
        items.forEach((item: any) => {
          if (!isUrl(item.thumbnail_url)) {
            item.thumbnail_url = null;
          }

          const hasItemId = item.id in this.queueItemsById;
          const itemType = this.getQueueItemType(item);
          const isSameType = this.queueItemsById[item.id]?.type === itemType;
          if (hasItemId && isSameType) {
            item.xAdded = true;
            item.xClasses = 'added';
            item.xActivityId = this.queueItemsById[item.id].activityId;
          } else {
            item.xAdded = false;
            item.xClasses = '';
            item.xActivityId = '';
          }
          item.xOverlayText = '';
        });
        this.items = items;

        this.loading = false;
      });
    }
  }

  getQueueItemType(item) {
    return item.game_type ? item.game_type : item.activity_type;
  }

  // Copied from pl-queue.directive; not sure why it is so complex.
  getResourceType(activity) {
    let isAssessment: boolean;
    let isGame: boolean;
    let type = 'activity';
    if (activity.resource_uri) {
      isAssessment =
        activity.resource_uri.toLowerCase().indexOf('assessment') >= 0;
      isGame = activity.resource_uri.toLowerCase().indexOf('game') >= 0;
    } else {
      isAssessment = activity.activity_type === 'assessment';
      isGame = activity.activity_type === 'game';
    }
    if (isAssessment) {
      type = 'assessment';
    } else if (isGame) {
      type = 'game';
    }
    if (activity.type === 'lesson') {
      type = 'lesson';
    }
    return type;
  }

  // Copied from ng1
  generateUUID() {
    let d = Date.now();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      const r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x7) | 0x8).toString(16);
    });
    return uuid;
  }

  addOrRemoveItem(item) {
    // If were in the text box then click an item, this will also trigger
    // a "blur" event to be fired to re-get items. We want to block that.
    // Set the timeout to just over the blur debounce of 1000.
    this.blockGetItems = true;
    setTimeout(() => {
      this.blockGetItems = false;
    }, 1100);

    if (item.xAdded) {
      this.removeItem(item);
    } else {
      this.addItem(item);
    }
  }

  removeItem(item) {
    const queueRef = this.firebaseService.getRoomRef(
      `activities/queues/items/${this.activeQueueId}`,
    );
    queueRef.once('value', ref => {
      queueRef.child('items').child(item.xActivityId).remove();

      // Need to remove from order as well.
      const queue = ref.val();
      const queueOrder = queue.order.filter(activityId => {
        return activityId !== item.xActivityId ? true : false;
      });
      queueRef.child('order').set(queueOrder);
    });

    item.xAdded = false;
    item.xClasses = '';
    item.xActivityId = '';
    item.xOverlayText = 'Removed from queue';
    setTimeout(() => {
      item.xOverlayText = '';
    }, 2000);
  }

  addItem(item) {
    const queueId = this.activeQueueId;
    const activityId = this.generateUUID();
    const activityType = this.getResourceType(item);
    const activity = {
      queueId,
      activityId,
      id: item.id,
      slug: item.slug,
      thumbnail_url: item.thumbnail_url,
      type: this.getQueueItemType(item),
      activity_type: activityType,
      name: item.name,
      session_id: this.generateUUID(),
    };
    this.queuesRef
      .child('items')
      .child(queueId)
      .child('items')
      .child(activityId)
      .set(activity);

    // Need to add to order as well, otherwise they will not show up.
    const queueRef = this.firebaseService.getRoomRef(
      `/activities/queues/items/${queueId}`,
    );

    queueRef.child('order').once('value', ref => {
      let queueOrder = ref.val();
      if (!queueOrder || !queueOrder.length) {
        queueOrder = [];
      }
      queueOrder.push(activityId);
      queueRef.child('order').set(queueOrder);
    });

    item.xAdded = true;
    item.xClasses = 'added';
    // Required for removing activity.
    item.xActivityId = activityId;
    item.xOverlayText = 'Added to queue';
    setTimeout(() => {
      item.xOverlayText = '';
    }, 2000);
  }

  done() {
    this.blockGetItems = true;
    // Just like addOrRemoveItem block - set to just above the 1000 blur
    // debounce.
    setTimeout(() => {
      this.blockGetItems = false;
    }, 1100);
    // Reset items (for next time).
    this.items = [];
    this.filterOpts.activitySection = this.OFFICIAL_SECTION;
    this.filterOpts.activityType = 'all';
    this.filterOpts.search = '';
  }

  setActivityTypesOptions() {
    const user = this.user;
    const defaultSelectOptsTypes = [...this.defaultSelectOptsTypes];
    const isOfficialSectionSelected =
      this.filterOpts.activitySection === this.OFFICIAL_SECTION;
    if (user && user.groups) {
      let availableOptions = [];
      if (isOfficialSectionSelected) {
        defaultSelectOptsTypes.push(this.gameOpt);
      } else if (
        this.filterOpts.activityType === 'game' ||
        this.filterOpts.activityType === 'assessment'
      ) {
        this.filterOpts.activityType = 'all';
      }
      if (
        user.groups.indexOf('School Staff Providers') >= 0 ||
        user.groups.indexOf('Private Practice') >= 0
      ) {
        if (
          user.groups.indexOf('Activity Users') >= 0 &&
          user.groups.indexOf('Assessment Users') >= 0
        ) {
          availableOptions = defaultSelectOptsTypes;
          if (isOfficialSectionSelected) {
            availableOptions.push(this.assessmentOpt);
          }
        } else if (user.groups.indexOf('Activity Users') >= 0) {
          availableOptions = defaultSelectOptsTypes;
        } else if (
          user.groups.indexOf('Assessment Users') >= 0 &&
          isOfficialSectionSelected
        ) {
          this.filterOpts.activityType = 'assessment';
          availableOptions = [this.assessmentOpt];
        }
      } else if (
        user.groups.indexOf('Provider') >= 0 ||
        user.groups.indexOf('Therapist') >= 0
      ) {
        availableOptions = defaultSelectOptsTypes;
        if (isOfficialSectionSelected) {
          availableOptions.push(this.assessmentOpt);
        }
      }
      if (user.groups.indexOf('Lesson Users') >= 0) {
        availableOptions.push(this.lessonOpt);
      }
      availableOptions = availableOptions.sort((a, b) => {
        return a.label > b.label ? 1 : -1;
      });
      this.selectOptsTypes = availableOptions;
      this.getItems();
    }
  }
}
