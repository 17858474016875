import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BookModes } from '../../room/pl-activity/pl-pdf-activity/drawer/pdf-viewer-drawer.component';

interface BookMode {
  icon: string;
  value: BookModes;
  label: string;
  extendedLabel: string;
}

export const ZOOM_LEVELS = [0.1, 0.25, 0.5, 0.75, 1, 1.5, 2, 3, 4, 5, 6];

@Component({
  selector: 'pl-pdf-drawer-display',
  templateUrl: './pdf-drawer-display.component.html',
  styleUrls: ['./pdf-drawer-display.component.less'],
})
export class PdfDrawerDisplayComponent {
  @Input() canRotate: boolean;
  @Input() canZoom: boolean;
  @Input() canSwitchMode: boolean;

  @Input() scale: number = 1;
  @Input() totalPages: number;
  @Input() bookMode: BookModes = BookModes.SINGLE_PAGE_MODE;

  @Output() readonly zoom = new EventEmitter<number>();
  @Output() readonly switchMode = new EventEmitter<BookModes>();
  @Output() readonly rotate = new EventEmitter();

  bookModeEnum = BookModes;

  bookModeList: BookMode[] = [
    {
      icon: 'description',
      value: BookModes.SINGLE_PAGE_MODE,
      label: 'Single',
      extendedLabel: 'Single',
    },
    {
      icon: 'menu_book',
      value: BookModes.DBL_PAGE_ODD_MODE,
      label: 'Double (pg 1)',
      extendedLabel: 'Double (start pg 1)',
    },
    {
      icon: 'menu_book',
      value: BookModes.DBL_PAGE_EVEN_MODE,
      label: 'Double (pg 2)',
      extendedLabel: 'Double (start pg 2)',
    },
  ];

  zoomLevels: number[] = ZOOM_LEVELS;

  handleZoomTo(index: number) {
    this.zoom.emit(this.zoomLevels[index]);
  }

  handleSwitchMode(mode: BookModes) {
    this.switchMode.emit(mode);
  }

  handleRotate() {
    this.rotate.emit();
  }
}
