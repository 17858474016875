import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { PLUrlsService } from '@root/src/app/common/services/pl-http';
import { ClientServicesService } from '../client-services.service';
import { Subscription } from 'rxjs';
import { User } from '@app/modules/user/user.model';
import {
  NoteSchema,
  PLRecordRoomService,
} from '@root/src/app/common/services/pl-records';
import {
  billingCodes,
  noMetricsBillingCodes,
} from '../presence-documentation-constants.service';
import { Store } from '@ngrx/store';
import { AppState } from '@root/src/app/store';
import { selectCurrentUser } from '@root/src/app/modules/user/store';
import { first } from 'rxjs/operators';
import {
  BillingCode,
  ClientAppointment,
  ClientService,
  DocumentationItemState,
  MetricPoint,
} from '../../documentation.models';
import { DocumentationNoteType } from './appointment-item-notes/presence-appointment-item-notes.component';
import { MatTabGroup } from '@angular/material/tabs';

const OPTIONAL_SOAP_BILLING_CODES = [
  billingCodes.CANCELED_BY_PROVIDER,
  billingCodes.CANCELED_TECH_ISSUE,
  billingCodes.CANCELED_HOLIDAY,
  billingCodes.UNPLANNED_SCHOOL_CLOSURE,
  billingCodes.CANCELED_24_NOTICE,
  billingCodes.STUDENT_ABSENCE_LT_24_HR,
  billingCodes.STUDENT_ABSENCE_NO_NOTICE,
];

@Component({
  selector: 'pl-presence-documentation-appointment-item',
  templateUrl: 'presence-documentation-appointment-item.component.html',
  styleUrls: ['presence-documentation-appointment-item.component.less'],
})
export class PresenceDocumentationAppointmentItemComponent
  implements OnInit, OnChanges, OnDestroy
{
  @ViewChild(MatTabGroup, { static: false }) tabGroup!: MatTabGroup;
  @Input() clientAppointment: ClientAppointment;
  @Input() provider: User;
  @Input() public itemState: DocumentationItemState;
  @Output() public itemStateChange = new EventEmitter<DocumentationItemState>();

  urls: { [key: string]: string } = {};
  private subscriptions: Subscription[] = [];

  services: ClientService[];
  serviceSelectOptions: { value: string; label: string }[] = [];
  selectedService: ClientService;
  selectedServiceId: string;

  billingCodes: BillingCode[];
  billingCodeSelectOptions: { value: string; label: string }[] = [];
  selectedBillingCode: BillingCode;
  selectedBillingCodeId: string;

  localStorageKey: string;

  clientServiceRequired: boolean;
  isDirectService: boolean;
  billingCodeHasMetrics = false;
  showMetrics: boolean;
  isSaving = false;
  isSigningLoading = false;
  private currentUserId: string;
  private savingTimeout: NodeJS.Timeout;
  servicesSubscription: Subscription;
  noteSchema: NoteSchema;
  expanded: boolean;
  clientAppointmentWithRecord: ClientAppointment;
  isEvaluationAppointment: boolean;
  hasNewMetrics = false;
  metricPoints: MetricPoint[] = [];
  isOptionalSOAPNotes = false;

  constructor(
    private plUrls: PLUrlsService,
    private clientServices: ClientServicesService,
    private plRecordRoom: PLRecordRoomService,
    private store: Store<AppState>,
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.servicesSubscription.unsubscribe();
  }

  ngOnInit() {
    this.urls = this.plUrls.urls;

    this.subscriptions.push(
      this.clientServices.getBillingCodes().subscribe(result => {
        this.billingCodes = result;
        this.billingCodeSelectOptions = this.clientServices.getBillingCodesOpts(
          this.billingCodes,
        );
        if (this.clientAppointment) {
          this.initializeBillingCodeSelection();
        }
      }),
      this.store.select(selectCurrentUser).subscribe(user => {
        this.currentUserId = user.uuid;
      }),
    );
    if (!this.itemState) {
      this.itemState = {
        selectedNotesTab: DocumentationNoteType.notes,
        selectedTabIndex: 0,
      };
      this.itemStateChange.emit(this.itemState);
    }
  }

  ngOnChanges() {
    if (this.clientAppointment && this.provider) {
      // don't full initialize billing code selection until appointments are loaded,
      // but store the current selectedBillingCodeId immediately for use in preCreateRecord()
      this.selectedBillingCodeId = this.clientAppointment.record.billing_code;
      if (this.billingCodes) {
        this.initializeBillingCodeSelection();
      }

      this.localStorageKey = `documentationClient${this.clientAppointment.xInstanceId}`;
      this.readExpandedFromLocalStorage();

      this.updateServices();
    }

    // force tabGroup to realign ink bar after setting selectedTabIndex
    setTimeout(() => {
      this.tabGroup.realignInkBar();
    }, 1000);
  }

  updateServices() {
    this.checkServiceIsRequiredForAppointment();
    if (this.servicesSubscription) this.servicesSubscription.unsubscribe();

    this.servicesSubscription = this.clientServices
      .getClientServices(this.clientAppointment, this.provider)
      .subscribe(result => {
        this.services = result;
        this.serviceSelectOptions =
          this.clientServices.formSelectOptsClientServices(this.services);

        this.selectedServiceId = this.clientServices.initializeServiceSelection(
          this.clientAppointment,
          this.serviceSelectOptions,
          this.localStorageKey,
        );
        if (this.selectedServiceId && this.services?.length) {
          this.serviceSelected(this.selectedServiceId);
        }
      });
  }

  openMe() {
    this.expanded = true;
    this.updateExpandedInLocalStorage();
  }
  closeMe() {
    this.expanded = false;
    this.updateExpandedInLocalStorage();
  }

  getLocalStorageData() {
    let data;
    try {
      data = localStorage.getItem(this.localStorageKey);
    } catch (e) {
      console.debug(
        'localStorage error in PresenceDocumentationAppointmentItemComponent',
      );
    }
    if (data) {
      data = JSON.parse(data);
    }
    return data;
  }
  updateExpandedInLocalStorage() {
    let data = this.getLocalStorageData();
    if (data) {
      data.expanded = this.expanded;
      localStorage.setItem(this.localStorageKey, JSON.stringify(data));
    }
  }

  readExpandedFromLocalStorage() {
    let data = this.getLocalStorageData();
    if (data) {
      this.expanded = data.expanded;
    }
  }

  avatarClicked(event) {
    window.open(
      `${this.urls.eduClientsFE}/client/${this.clientAppointment.uuid}`,
      '_blank',
    );
    event.stopPropagation();
  }

  initializeBillingCodeSelection() {
    this.selectedBillingCode = this.billingCodes.find(
      billingCode => billingCode.uuid === this.selectedBillingCodeId,
    );
    this.isEvaluationAppointment =
      this.selectedBillingCode?.code === 'evaluation';
    this.updatBillingCodeHasMetrics();
    this.isOptionalSOAPNotes = OPTIONAL_SOAP_BILLING_CODES.includes(
      this.selectedBillingCode?.code,
    );
  }

  billingCodeSelected(billingCodeId: string) {
    this.selectedBillingCodeId = billingCodeId;
    this.clientAppointment.record.billing_code = this.selectedBillingCodeId;
    this.selectedBillingCode = this.billingCodes.find(
      billingCode => billingCode.uuid === billingCodeId,
    );
    this.isEvaluationAppointment =
      this.selectedBillingCode?.code === 'evaluation';
    this.updateServices();
    this.updatBillingCodeHasMetrics();
    this.updateShowMetrics();
    this.saveRecord();
    this.isOptionalSOAPNotes = OPTIONAL_SOAP_BILLING_CODES.includes(
      this.selectedBillingCode?.code,
    );
  }

  serviceSelected(serviceId: string) {
    this.selectedServiceId = serviceId;
    this.selectedService = this.services.find(
      service => service.id === serviceId,
    );
    this.clientServices
      .selectClientServiceAndPreCreateRecord(
        this.clientAppointment,
        this.provider,
        serviceId,
        this.selectedBillingCodeId,
        this.localStorageKey,
      )
      .pipe(first())
      .subscribe(() => {
        this.updateNotesSchema();
        this.clientAppointmentWithRecord = Object.assign(
          {},
          this.clientAppointment,
        );
      });
  }

  private checkServiceIsRequiredForAppointment() {
    this.clientServices
      .checkForServiceRequired(this.clientAppointment)
      .subscribe((resRequired: any) => {
        this.clientServiceRequired = resRequired.required;
        this.updateShowMetrics();
      });
  }

  private updatBillingCodeHasMetrics() {
    this.billingCodeHasMetrics =
      this.selectedBillingCode &&
      !noMetricsBillingCodes.includes(this.selectedBillingCode.code);
    this.updateShowMetrics();
  }

  private updateShowMetrics() {
    this.showMetrics = this.clientServiceRequired && this.billingCodeHasMetrics;
  }

  onNotesChanged() {
    if (this.savingTimeout) {
      clearTimeout(this.savingTimeout);
    }
    this.savingTimeout = setTimeout(() => {
      this.saveRecord();
    }, 1000);
  }

  onSignChange(signed) {
    let record = this.clientAppointment.record;
    record.signed = signed;

    if (signed) {
      record.signed_by = this.provider.uuid;
      record.signed_on = new Date().toISOString();
    } else {
      record.signed_by = null;
      record.signed_on = null;
    }

    this.saveRecord(true);
  }

  onMakeUpChoiceChanged(makeUpChoice: boolean) {
    this.clientAppointment.record.is_makeup_session_required = makeUpChoice;
    this.saveRecord();
  }

  onOpenEventDetails() {
    // for now just open schedule. later this will open the event details directly
    window.open(this.urls.scheduleFE, '_blank');
  }

  onTabChanged(tabIndex: number) {
    this.itemState.selectedTabIndex = tabIndex;
    this.itemStateChange.emit(this.itemState);
  }

  onDisplayedNoteChanged(noteType: DocumentationNoteType) {
    this.itemState.selectedNotesTab = noteType;
    this.itemStateChange.emit(this.itemState);
  }

  onMetricsUpdated(metricPoints) {
    this.metricPoints = [...metricPoints.map(mp => ({ ...mp }))]; // deep copy
  }

  private saveRecord(isSigningOff = false) {
    this.isSigningLoading = isSigningOff;
    this.isSaving = true;
    return this.plRecordRoom
      .saveRecord(
        this.clientAppointment.record,
        this.clientAppointment.uuid,
        this.clientAppointment.record.appointment,
        this.clientAppointment.appointment.event,
        this.currentUserId,
      )
      .pipe(first())
      .subscribe(() => {
        this.isSaving = false;
        this.isSigningLoading = false;
      });
  }

  private updateNotesSchema() {
    return this.plRecordRoom
      .getRecordNotesSchema(this.clientAppointment.record.billing_code)
      .pipe(first())
      .subscribe((noteSchema: NoteSchema) => {
        this.noteSchema = noteSchema;
        if (!this.clientAppointment.record.note_schema) {
          this.clientAppointment.record.note_schema = noteSchema.uuid;
          this.clientAppointment.record.notes = {
            subjective: '',
            objective: '',
            assessment: '',
            plan: '',
            notes: '',
          };
        }
        const isEvalPAService = this.plRecordRoom.isEvalPA(
          this.selectedService,
        ); // PA Evaluations have a different note schema('general' schema) than other evaluations
        if (isEvalPAService) {
          const generalNoteSchema = this.plRecordRoom.noteSchemas.find(
            schema => schema.code === 'general',
          );
          this.clientAppointment.record.note_schema = generalNoteSchema.uuid;
          this.noteSchema = generalNoteSchema;
        }
      });
  }
}
