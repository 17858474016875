import { ENV } from '../@types/internal';

// This configuration is used by staging-room.presence.com & k8s
export const environment: ENV = {
  env_key: 'staging',
  production: false,
  debug: false,
  app_name: 'room',
  cookie_domain: process.env.COOKIE_DOMAIN || 'presence.com',
  pendo_key: '',
  sentry_key:
    process.env.SENTRY_DSN ??
    'https://a275cf49dbf948e0959808f246109f19@o7007.ingest.sentry.io/6480814',
  sentry_replay_enabled: false,
  speed_of_me: {
    key: 'SOM60d26a7e8aa1b',
  },
  config_cat_sdk:
    process.env.CONFIG_CAT_SDK ||
    'DgLbCHEjckGEDa0uS7QeUQ/csBQVeuXPky3txDOpyng_w',
  siteshare_url: 'https://staging-siteshare.presencelearning.com',
  emulate_firebase: process.env.EMULATE_FIREBASE === 'true',
  git_sha: process.env.GIT_SHA,
  apps: {
    auth: {
      url: 'https://staging-login.presence.com',
    },
    apiWorkplace: {
      url: 'https://staging-workplace.presence.com',
    },
    apollo: {
      url: 'https://staging-workplace.presence.com/graphql/v1/',
    },
    eduClients: {
      url: 'https://staging-apps.presence.com/c',
    },
    flutterApp: {
      url: 'https://staging-flutterapp.presence.com',
    },
    platform: {
      url: 'https://staging-platform.presence.com',
    },
    library: {
      url: 'https://staging-library.presence.com',
    },
    apiEssentials: {
      url: 'https://staging-essentials.presence.com',
    },
    therapy: {
      url: 'https://staging-kanga.presence.com',
    },
    learnupon: {
      url: 'presencedev.learnuponus.com',
    },
  },
  room: {
    inactiveSeconds: 3600,
  },
  feature_flags: {
    sharedCursorTokbox: 1,
  },
  liveagent: {
    endpoint: 'https://d.la2-c2cs-ph2.salesforceliveagent.com/chat',
    buttonId: '57380000000GnQ2',
    initArg1: '57280000000CbGq',
    initArg2: '00D8A000000D0oY',
  },
};
