import { Component, Input } from '@angular/core';
import { LiveagentService } from '@root/src/app/common/services/LiveagentService';
import { PLUrlsService } from '@root/src/app/common/services/pl-http';

enum AppointmentErrors {
  overlappingSameAssignment = 'You can only have overlapping events for the same assignment.',
}

@Component({
  selector: 'pl-documentation-errors',
  templateUrl: 'pl-documentation-errors.component.html',
})
export class PLDocumentationErrorsComponent {
  @Input() public error: string;
  appointmentErrors = AppointmentErrors;

  constructor(
    private liveagentService: LiveagentService,
    private plUrls: PLUrlsService,
  ) {}

  onViewSchedule() {
    window.open(this.plUrls.urls.scheduleFE, '_blank');
  }

  onChatWithSupport() {
    this.liveagentService.startChat();
  }
}
