import { Injectable } from '@angular/core';
import { DRFActivityModel } from '@common/models/DRF/DRFActivityModel.service';
import { DRFAssessmentModel } from '@common/models/DRF/DRFAssessmentModel.service';
import { FirebaseModel } from '@common/models/firebase/FirebaseModel';
import { ChannelFactoryService } from '@common/services/channel.service';
import { PLHttpService, PLUrlsService } from '@common/services/pl-http';

@Injectable()
export class PLActivityModelService {
  defaults = {};
  activity: any;
  loadResolver;
  loadRejecter;
  foundationLoaded: Promise<any>;
  share: boolean;
  channels: Record<string, any> = {};
  channel: any;
  getRef: any;
  configModel: any;
  configErrorCode: any;
  ref: any;
  getSessionRef: (string?) => any;

  constructor(
    protected firebaseModel: FirebaseModel,
    private drfActivityModel: DRFActivityModel,
    private drfAssessmentModel: DRFAssessmentModel,
    private ChannelService: ChannelFactoryService,
    protected plUrls: PLUrlsService,
    protected plHttp: PLHttpService,
  ) {
    this.reset();
  }

  reset() {
    this.foundationLoaded = new Promise<any>((resolve, reject) => {
      this.loadResolver = resolve;
      this.loadRejecter = reject;
    });
    this.activity = { ...this.defaults };
    this.share = false;
    return this;
  }

  /**
   * Fields
   */

  /**
   * activityId (activity type)
   * @param id
   * @return ActivityModel
   */
  setActivityId(id) {
    this.activity.activityId = id;
    return this;
  }

  getActivityId() {
    return this.activity.activityId;
  }

  /**
   * configId (database location)
   * @param id
   * @return ActivityModel
   */
  setConfigId(id) {
    this.activity.configId = id;
    return this;
  }

  getConfigId() {
    return this.activity.configId;
  }

  setActivityType(type) {
    this.activity.activityType = type;
    return this;
  }

  getActivityType() {
    return this.activity.activityType;
  }

  setType(type) {
    this.activity.type = type;
    return this;
  }

  getType() {
    return this.activity.type;
  }

  setChannel(channel) {
    this.channel = channel;
    return this;
  }

  getChannel() {
    return this.channel;
  }

  /**
   * sessionId (firebase location)
   * @param id
   */
  setSessionId(id) {
    if (!this.channels[id]) {
      this.channels[id] = this.ChannelService.newChannel();
    }
    this.channel = this.channels[id];
    this.activity.sessionId = id;
    return this;
  }

  getSessionId() {
    return this.activity.sessionId;
  }

  /**
   * Set the mode to shared or not shared (e.g. remote saving)
   * @param value
   */
  setShared(value) {
    this.share = value;
    if (this.share) {
      this.getRef = this.firebaseModel.getFirebaseRef;
    } else {
      console.error(`firebasemock was removed, if see this,
                need to replace it or prevent from getting here in the first place.`);
      // this.getRef = (name) => {
      //     return new this.firebaseMock(name);
      // };
    }
    try {
      this.getSessionRef = id => {
        const sessionId = id || this.getSessionId();
        if (sessionId) {
          return this.firebaseModel
            .getFirebaseRef('activities/sessions/')
            .child(sessionId);
        }
      };
    } catch (e) {
      console.error(
        'error in getSessionRef in setShared in activityModel: ',
        e,
      );
    }
    return this;
  }

  /**
   * Setup the load and value handlers for the remote object
   */
  initialize(model, token = null) {
    this.getActivityConfig(model, token);
    return this;
  }

  getActivityConfig(model, token = null) {
    switch (this.activity.type) {
      case 'assessment':
        this.configModel = this.drfAssessmentModel;
        break;
      case 'activity':
      default:
        this.configModel = this.drfActivityModel;
    }
    this.configModel.init();
    this.configModel.setKey(this.getActivityId());
    this.configErrorCode = null;

    if (this.activity.type === 'instant_youtube') {
      this.loadResolver(this);
    } else {
      this.configModel.get(token).subscribe(
        () => this.handleConfigValue(this.configModel.model),
        error => {
          if (error.status === 404 || error.status === 403) {
            this.configErrorCode = error.status;
            this.handleConfigError(error);
          }
        },
      );
    }
    return this;
  }

  /**
   * Handlers
   */
  handleConfigValue = config => {
    if (config === undefined || config.descriptor === undefined) {
      console.error('Cannot load activity');
      return;
    }
    const configJson = JSON.parse(config.descriptor);

    if (configJson == null) {
      console.error('[ActivityModel] config data is null');
    } else {
      // Handle image decks.
      if (configJson.imageDeck) {
        const imageDeck = configJson.imageDeck;
        const url = `${this.plUrls.urls.platformFE}/api/v1/activity/${imageDeck.id}/`;
        this.plHttp
          .get(
            '',
            {
              id: imageDeck.id,
            },
            url,
          )
          .subscribe(res => {
            const data = res.data;
            // Mimic old cards version.
            const descriptor = {
              title: data.name,
              description: data.description,
              cards: [],
            };
            const descriptorImageDeck = data.descriptor
              ? JSON.parse(data.descriptor)
              : {};
            if (descriptorImageDeck.images) {
              descriptorImageDeck.images.forEach((image, index) => {
                descriptor.cards.push({
                  id: index + 1,
                  title: '',
                  url: image.url,
                  thumbnail_url: image.url,
                  type: 'image',
                });
              });
            }
            this.activity.config = descriptor;
            this.loadResolver(this);
          });
      } else {
        const keys1 = Object.keys(configJson);
        let configRoot = {};
        if (keys1.length === 1) {
          configRoot = configJson[keys1[0]];
        } else {
          keys1.forEach(item => {
            configRoot[item] = configJson[item];
          });
        }
        this.activity.config = configRoot;
        this.activity.thumbnails = config.page_thumbnails;
        this.loadResolver(this);
      }
    }
  };

  handleConfigError(error) {
    console.error('[ActivityModel] activity ref load error:', error);
    this.loadRejecter(error);
  }

  /**
   * Persistence methods
   */
  updateActivity() {
    if (this.share) {
      this.ref.set(this.activity);
    }
    return this;
  }
}
