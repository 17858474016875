import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PLIconModule } from '@root/index';
import { MatMenuModule } from '@angular/material/menu';
import { MatSliderModule } from '@angular/material/slider';
import { FormsModule } from '@angular/forms';
import { PLPDFActivityModule } from '../pl-pdf-activity';
import { PLPDFAssessmentContentComponent } from './pdf-assessment-content.component';
import { PLCommonComponentsModule } from '@root/src/app/common/components';

@NgModule({
  imports: [
    CommonModule,
    PLIconModule,
    MatMenuModule,
    MatSliderModule,
    FormsModule,
    PLPDFActivityModule,
    PLCommonComponentsModule,
  ],
  exports: [PLPDFAssessmentContentComponent],
  declarations: [PLPDFAssessmentContentComponent],
  providers: [],
})
export class PLPDFAssessmentModule {}
