<div
  class="youtube-drawer"
  *ngIf="isLoaded$ | async; else loader"
  data-testid="activity-youtube-drawer"
>
  <img
    [src]="thumbnailURL"
    [alt]="title"
    class="thumbnail"
    width="320"
    height="180"
  />
  <div class="description">
    <p>{{ duration }} | {{ publishedAt }}</p>
    <p>{{ description }}</p>
  </div>
</div>

<ng-template #loader>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>
