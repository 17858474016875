<div class="pl-queue-add">
  <div class="pl-queue-add__form">
    <mat-form-field color="accent" class="w-100">
      <mat-label>Content library</mat-label>
      <mat-select
        [(ngModel)]="filterOpts.activitySection"
        (selectionChange)="setActivityTypesOptions()"
        data-testid="content-library-select"
      >
        <mat-option *ngFor="let opt of selectOptsSections" [value]="opt.value">
          {{ opt.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field color="accent" class="w-100">
      <mat-label>Activity types</mat-label>
      <mat-select
        data-testid="activity-types-select"
        [(ngModel)]="filterOpts.activityType"
        (selectionChange)="activityTypeChanged()"
      >
        <mat-option *ngFor="let opt of selectOptsTypes" [value]="opt.value">
          {{ opt.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field
      color="accent"
      class="w-100"
      *ngIf="filterOpts.activityType !== 'game'"
    >
      <mat-label>Search for activities</mat-label>
      <input
        matInput
        data-testid="activity-search-input"
        [(ngModel)]="filterOpts.search"
        (ngModelChange)="debouncedGetItems($event)"
      />
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
  </div>

  <div
    class="pl-queue-add__text"
    *ngIf="filterOpts.activityType === 'assessment'"
  >
    Assessment record forms are available to download in the full Library.
  </div>

  <div class="pl-queue-add__text" *ngIf="filterOpts.activityType === 'youtube'">
    <p class="mb-2">Quickly display a video using a YouTube URL</p>

    <button
      mat-raised-button
      color="primary"
      class="w-100"
      (click)="youtubeQuickstart()"
    >
      Play a Video
    </button>
  </div>

  <div class="pl-queue-add__text" *ngIf="items.length">
    Click one or more items to add them to your queue.
  </div>

  <div class="pl-queue-add__content" data-testid="activities-results">
    <mat-progress-bar
      mode="indeterminate"
      *ngIf="loading"
      class="pl-queue-add__loader"
    ></mat-progress-bar>

    <div class="pl-queue-add__no-results" *ngIf="!loading && !items.length">
      No results found
    </div>

    <pl-activities-list
      [activities]="items"
      (addActivity)="addItem($event)"
      (removeActivity)="removeItem($event)"
    >
    </pl-activities-list>

    <pl-quick-youtube #quickYoutube></pl-quick-youtube>
  </div>

  <footer class="pl-queue-add__footer">
    <button
      *ngIf="isIntegratedLibraryEnabled; else noIntegratedLibrary"
      mat-stroked-button
      color="primary"
      class="w-100"
      (click)="openLibrary.emit()"
    >
      Open Library
    </button>
    <ng-template #noIntegratedLibrary>
      <a [href]="libraryUrl" target="_blank">
        <button mat-stroked-button color="primary" class="w-100">
          Open full Library
          <mat-icon>open_in_new</mat-icon>
        </button>
      </a>
    </ng-template>
  </footer>
</div>
