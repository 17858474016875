import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ColorpickerComponent } from './colorpicker.component';
import { ColorwheelComponent } from './colorwheel.component';

@NgModule({
  imports: [CommonModule],
  exports: [ColorpickerComponent, ColorwheelComponent],
  declarations: [ColorpickerComponent, ColorwheelComponent],
  providers: [],
})
export class PLColorPickerModule {}

export { ColorpickerComponent, ColorwheelComponent };
