import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'pl-scenes-backgrounds',
  templateUrl: './scenes-backgrounds.component.html',
  styleUrls: ['./scenes-backgrounds.component.less'],
})
export class PLScenesBackgroundsComponent {
  @Input() selectedSceneName: string;
  @Input() sceneOptions: {
    label: string;
    value: string;
    backgroundImageSrc: string;
  }[];
  @Input() isPreviewAvailable: boolean;
  @Input() isPreviewEnabled: boolean;

  @Output() selectedSceneNameChange = new EventEmitter<string>();
  @Output() togglePreview = new EventEmitter();
}
