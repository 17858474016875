import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import moment from 'moment';

import { StudentItem } from '../kanga-documentation.model';

@Component({
  selector: 'pl-kanga-documentation-appointment-selection',
  templateUrl: 'kanga-documentation-appointment-selection.component.html',
  styleUrls: ['kanga-documentation-appointment-selection.component.less'],
})
export class KangaDocumentationAppointmentSelectionComponent {
  @Output() readonly selectionChanged = new EventEmitter<StudentItem>();
  @Input() events: StudentItem[];
  @Input() clients: StudentItem[];
  @Input() selectedStudent: StudentItem;

  selectedCount = 0;
  studentFilter = '';
  filteredClients: StudentItem[] = [];
  filteredEvents: StudentItem[] = [];

  ngOnChanges(): void {
    if (this.events && this.clients) {
      this.updateLabels();
      this.filteredEvents = this.events;
      this.filteredClients = this.clients;
    }
    this.selectedCount = this.selectedStudent ? 1 : 0;
  }

  onSelectStudent(student: StudentItem) {
    this.selectionChanged.emit(student);
  }

  updateLabels() {
    this.events.forEach(item => {
      item.label = this.getStudentDisplayLabel(item);
    });
    this.clients.forEach(item => {
      item.label = this.getStudentDisplayLabel(item);
    });
  }

  getStudentDisplayLabel(item) {
    let label = `${item.client.last_name}, ${item.client.first_name}`;
    if (item.event) {
      const startTime = moment(item.event.start_date_local).format('h:mma');
      label = `${startTime}, ${label}`;
    }
    return label;
  }

  filterStudentsList() {
    this.filteredClients = this.clients.filter(c => {
      const fullName = `${c.client.last_name} ${c.client.first_name}`;
      return fullName.toLowerCase().includes(this.studentFilter.toLowerCase());
    });
    this.filteredEvents = this.events.filter(c => {
      const fullName = `${c.client.last_name} ${c.client.first_name}`;
      return fullName.toLowerCase().includes(this.studentFilter.toLowerCase());
    });
  }
}
