import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Activity } from '../../room/app/store';

@Component({
  selector: 'pl-activities-list',
  templateUrl: './pl-activities-list.component.html',
  styleUrls: ['./pl-activities-list.component.less'],
})
export class PLActivitiesListComponent {
  @Input() activities: any[];

  @Output() readonly addActivity = new EventEmitter<Activity>();
  @Output() readonly removeActivity = new EventEmitter<Activity>();

  activityClicked(item) {
    if (!item.xAdded) this.addActivity.emit(item);
  }
}
