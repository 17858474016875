import { Injectable } from '@angular/core';
import { PLGuidService } from '@common/services/GuidService';
import { PLAssessmentModelService } from '../model/assessment-model.service';

@Injectable()
export class PLAssessmentService {
  constructor(
    private assessmentModel: PLAssessmentModelService,
    private guidService: PLGuidService,
  ) {}
  startup(activity, token = null) {
    if (activity.id === this.assessmentModel.activity.activityId) {
      return this.assessmentModel.foundationLoaded;
    }
    this.reset();
    this.assessmentModel.setActivityId(activity.id);
    this.assessmentModel.setConfigId(activity.slug);
    this.assessmentModel.setType(activity.resource_type);
    const shareId = activity.session_id;
    if (shareId) {
      // enable firebase
      this.assessmentModel.setSessionId(shareId);
      this.assessmentModel.setShared(true);
    } else {
      // skip firebase
      this.assessmentModel.setSessionId(this.guidService.generateUUID());
      this.assessmentModel.setShared(false);
    }
    this.assessmentModel.initialize({}, token);
    return this.assessmentModel.foundationLoaded;
  }

  reset() {
    this.assessmentModel.reset();
  }
}
