import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store';
import {
  selectStreamFilterMode,
  ConferenceActions,
  FilterVideoType,
} from '@room/conference/store';
import { VideoSettingsContainerService } from '../video-settings-container/video-settings-container.service';
@Injectable()
export class FilterVideoService {
  filterMode$: Observable<FilterVideoType>;

  constructor(
    private videoSettingsContainerService: VideoSettingsContainerService,
    private store: Store<AppState>,
  ) {
    this.filterMode$ = this.videoSettingsContainerService.streamId$.pipe(
      switchMap(id => this.store.select(selectStreamFilterMode(id))),
    );
  }

  blur() {
    this.dispatch(ConferenceActions.blur);
  }

  clearFilter() {
    this.dispatch(ConferenceActions.clearFilter);
  }

  backgroundImage() {
    this.dispatch(ConferenceActions.backgroundImage);
  }

  private dispatch(action) {
    this.store.dispatch(
      action({
        id: this.videoSettingsContainerService.getId(),
      }),
    );
  }
}
