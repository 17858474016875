import { AppState } from '@app/store';
import { createSelector } from '@ngrx/store';

export const clueGameState = (state: AppState) => state.clueState;

export const clueStartGameBtnStatus = createSelector(clueGameState, clue => clue.enableStartGameBtn);

export const clueEndGameBtnStatus = createSelector(clueGameState, clue => clue.enableEndGameBtn);

export const clueInstructionActive = createSelector(clueGameState, clue => clue.showInstruction);

export const clueCards = createSelector(clueGameState, clue => clue.cards);

export const clueCurrentUser = createSelector(clueGameState, clue => clue.currentUser);

export const clueCardReveal = createSelector(clueGameState, clue => clue.cardReveal);
