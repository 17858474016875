import { Component, Input } from '@angular/core';

@Component({
  selector: 'pl-vendor-game-drawer',
  templateUrl: './pl-vendor-game-drawer.component.html',
  styleUrls: ['./pl-vendor-game-drawer.component.less'],
})
export class PLVendorGameDrawerComponent {
  @Input() activity: any;
}
