<div class="drawer-subheader">
  <h4 class="drawer-subheader__title">Boom Cards</h4>
</div>
<pl-activity-switch-button
  [contentType]="'boomcards'"
  [contentDetail]="'boomcards'"></pl-activity-switch-button>

<div
  class="boomcards-info"
  *ngIf="{
    mode: boomCardsMode$ | async,
    isActive: isBoomCardsActive$ | async
  } as boomCardsState">
  <pl-alert-message>
    <span>
      <a href="https://wow.boomlearning.com/" target="_blank">
        Boom Cards <pl-icon-new inline name="open_in_new"></pl-icon-new> </a
      >are interactive activities that can be played with everyone in the Room.
    </span>
  </pl-alert-message>
  <div>
    Standard sessions use Site Share to display Boom Cards within the Room and
    require you to login each time.
  </div>
  <div>
    Boom Live session requires a premium license and uses an embedded version of
    the site to keep you logged in.
  </div>
  <a
    href="https://presencelearning.helpjuice.com/61244-technical-support-troubleshooting/using-boom-cards"
    target="_blank">
    Learn more about Boom Card session types
    <pl-icon-new inline name="open_in_new"></pl-icon-new
  ></a>

  <mat-radio-group
    color="primary"
    [value]="boomCardsState.mode"
    (change)="onModeChanged($event)">
    <mat-radio-button [value]="'standard'" [disabled]="boomCardsState.isActive">
      Standard session
    </mat-radio-button>
    <mat-radio-button [value]="'boomLive'" [disabled]="boomCardsState.isActive">
      Boom Live session
    </mat-radio-button>
  </mat-radio-group>

  <div class="boomcards-options">
    <div class="boomcards-info-labeled-button">
      <button
        *ngIf="!boomCardsState.isActive"
        (click)="toggleBoomCards(boomCardsState.mode, true)"
        mat-raised-button
        color="success">
        Start Boom Cards
      </button>

      <button
        *ngIf="boomCardsState.isActive"
        (click)="toggleBoomCards(boomCardsState.mode, false)"
        mat-raised-button
        color="warn">
        Stop Boom Cards
      </button>
    </div>
  </div>

  <div *ngIf="boomCardsState.isActive">
    <ng-container *ngIf="boomCardsState.mode === 'standard'">
      Once you have logged in to Boom Cards and chosen an activity from your
      Library, select “Guests can control” from the “Permissions” menu to allow
      everyone to interact with the activity.
    </ng-container>
    <ng-container *ngIf="boomCardsState.mode === 'boomLive'">
      Once you have logged in to Boom Cards and chosen an activity from your
      Library, select “Start Live Session” from the dropdown menu for the
      activity and then press the “Start Session” button to allow everyone to
      interact with the activity.
    </ng-container>
  </div>
</div>
