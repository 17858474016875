import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@root/src/app/store';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ConferenceActions, selectStreamIsHidden } from '../../../store';
import { ConferenceStreamService } from '../../conference-stream.service';
import { StreamBoxService } from '../stream-box.service';

@Component({
  selector: 'pl-video-menu',
  templateUrl: 'video-menu.component.html',
  styleUrls: ['./video-menu.component.less'],
})
export class PLVideoMenuComponent {
  isRotated$: Observable<boolean>;
  isMirrored$: Observable<boolean>;
  isHidden$: Observable<boolean>;
  isMenuOpen = false;

  constructor(
    private conferenceStreamService: ConferenceStreamService,
    streamBoxService: StreamBoxService,
    private store: Store<AppState>,
  ) {
    this.isRotated$ = streamBoxService.isRotated$;
    this.isMirrored$ = streamBoxService.isMirrored$;
    this.isHidden$ = conferenceStreamService.streamId$.pipe(
      switchMap(id => store.select(selectStreamIsHidden(id))),
    );
  }

  onMenuOpen() {
    this.isMenuOpen = true;
  }

  onMenuClosed() {
    this.isMenuOpen = false;
  }

  onRotate() {
    this.store.dispatch(
      ConferenceActions.rotate({
        id: this.conferenceStreamService.getId(),
      }),
    );
  }

  onDeRotate() {
    this.store.dispatch(
      ConferenceActions.derotate({
        id: this.conferenceStreamService.getId(),
      }),
    );
  }

  onMirror() {
    this.store.dispatch(
      ConferenceActions.mirror({
        id: this.conferenceStreamService.getId(),
      }),
    );
  }

  onUnMirror() {
    this.store.dispatch(
      ConferenceActions.unmirror({
        id: this.conferenceStreamService.getId(),
      }),
    );
  }
}
