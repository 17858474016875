<mat-dialog-content class="preview-area">
  <div class="current-image">
    <img [src]="nextCaptureUrl" />
  </div>
  <div class="image-list" *ngIf="imageUrls.length > 1">
    <div class="thumbnail" *ngFor="let url of imageUrls">
      <img [src]="url" />
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-stroked-button (click)="onCancelClick()" color="primary">
    Exit without saving
  </button>
  <button mat-stroked-button (click)="onRetakeClick()" color="primary">
    Retake
  </button>
  <button mat-stroked-button (click)="onTakeMoreClick()" color="primary">
    Take more
  </button>
  <button mat-raised-button (click)="onSaveClick()" color="primary">
    Save
  </button>
</mat-dialog-actions>
