<div class="client-appointments-select">
  <div [ngClass]="{ 'dialog-links-row': dialogMode }">
    <div *ngIf="currentUser" class="center margin-large-tb">
      <a mat-button [ngClass]="linkClass" (click)="openSchedule()"
        >Add clients</a
      >
    </div>
    <div *ngIf="loadingAppointments" class="margin-large-t loading-refresh">
      <pl-loader></pl-loader>
    </div>
    <div
      *ngIf="!loadingAppointments"
      (click)="refreshClientAppointments()"
      class="pointer center margin-large-t loading-refresh"
    >
      <a [ngClass]="linkClass" mat-button>
        <mat-icon inline="true">refresh</mat-icon>
        <span>Refresh</span>
      </a>
    </div>
  </div>

  <pl-input-select
    *ngIf="clientAppointments.length"
    [placeholderColor]="inputPlaceholderColor"
    class="margin-t form-input"
    [placeholder]="'Select clients'"
    [(model)]="selectedClientAppointmentId"
    [options]="selectOptsClientAppointments"
    (onChange)="clientAppointmentSelectionChange()"
    [disabled]="isRecording"
  ></pl-input-select>
</div>
