<div class="margin-t documentation-te">
  <div
    class="margin-lr pl-input-select"
    tabindex="0"
    [ngClass]="{ disabled: selectedStudent }"
    *ngIf="
      (isFreemiumOver$ | async) !== null || (isSessionInProgress$ | async);
      else freemiumOver
    "
  >
    <div class="select-and-options border relative">
      <div class="select-button flexbox" [matMenuTriggerFor]="studentOptions">
        <div class="flex1 label">
          {{ selectedStudent ? '1 selected' : 'Select a Student' }}
        </div>
        <pl-icon
          class="icon"
          verticalAlign="-6px"
          [scale]="0.6"
          [svg]="'chevron-down'"
        ></pl-icon>
      </div>
      <mat-menu #studentOptions="matMenu" class="student-list-menu">
        <div class="student-search">
          <mat-form-field
            (click)="stopPropagation($event)"
            color="accent"
            name="student-search"
          >
            <mat-label>Search for Students</mat-label>
            <input
              [(ngModel)]="studentFilter"
              matInput
              (keyup)="filterStudentsList()"
            />
          </mat-form-field>
          <hr />
        </div>
        <div class="student-list-container">
          <ng-container *ngIf="filteredEvents.length > 0">
            <div class="group-header">Scheduled students</div>
            <div
              class="student-option"
              [ngClass]="{ selected: item === selectedStudent }"
              *ngFor="let item of filteredEvents"
              (click)="onSelectStudent(item)"
            >
              {{ getStudentDisplayLabel(item) }}
            </div>
            <hr />
          </ng-container>
          <div class="group-header">Unscheduled students</div>
          <div
            class="student-option"
            [ngClass]="{ selected: item === selectedStudent }"
            *ngFor="let item of filteredClients"
            (click)="onSelectStudent(item)"
          >
            {{ getStudentDisplayLabel(item) }}
          </div>
        </div>
      </mat-menu>
    </div>
  </div>
  <ng-template #freemiumOver>
    <div class="freemium-over">
      <div class="margin-b">
        You do not have any free minutes left for this month.
      </div>
      <div class="margin-b">
        You must upgrade your license to proceed or wait until next month to
        have a session.
      </div>
    </div>
  </ng-template>
  <div class="margin-t padding session-complete" *ngIf="lastSavedSessionItem">
    <div class="margin-b">Your session is now complete.</div>
    <div class="margin-b">
      You can find notes and related information on the Student Profile under
      Sessions tab.
    </div>
    <div class="student-link" (click)="onOpenStudentProfile()">
      View Student Profile
    </div>
  </div>
  <div class="margin-large-t" *ngIf="selectedStudent">
    <div class="client-appointment">
      <div class="client-header pointer flexbox padding padding-large-lr">
        <div class="flex1">
          <div class="name">
            {{ selectedStudent.client.first_name }}
            {{ selectedStudent.client.last_name }}
          </div>
        </div>
      </div>
      <pl-documentation-te-client
        (sessionEnded)="onSessionEnded()"
        [clientEvent]="selectedStudent"
      ></pl-documentation-te-client>
    </div>
  </div>
</div>
