export enum CardDisplayOption {
  ImageAndText = 'imageAndText',
  TextOnly = 'textOnly',
  ImageOnly = 'imageOnly',
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'pl-card-display-options',
  templateUrl: './card-display-options.component.html',
  styleUrls: ['./card-display-options.component.less'],
})
export class CardDisplayOptionsComponent implements OnInit {
  // default to all options being available
  @Input() useOptions: CardDisplayOption[] = [
    CardDisplayOption.ImageAndText,
    CardDisplayOption.TextOnly,
    CardDisplayOption.ImageOnly,
  ];
  @Input() label = 'Card display options';
  @Input() value: CardDisplayOption = CardDisplayOption.ImageAndText;
  @Output() readonly optionSelected = new EventEmitter<CardDisplayOption>();

  selectedOption: CardDisplayOption = CardDisplayOption.ImageAndText;

  ngOnInit() {
    this.selectedOption = this.value;
  }
  ngOnChanges(changes) {
    if (changes.value) {
      this.selectedOption = this.value;
    }
  }
  chooseOption(option: CardDisplayOption) {
    this.selectedOption = option;
    this.optionSelected.emit(option);
  }

  public get CardDisplayOption() {
    return CardDisplayOption;
  }
}
