import { Injectable } from '@angular/core';
import { combineLatest, of, throwError } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  take,
  tap,
  timeoutWith,
} from 'rxjs/operators';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store';
import { RoomConferenceService } from '../../room-conference.service';
import { ConferenceActions } from '../conference.actions';
import {
  selectLocalStream,
  selectParticipantStreamsIds,
} from '../conference.selectors';
import {
  selectAudioDevice,
  selectVideoDevice,
} from '@root/src/app/common/media/store';
import {
  selectLocalParticipantId,
  SessionActions,
} from '../../../session/store';

class LocalStreamNotFoundError extends Error {
  constructor(public id: string) {
    super(`Local Stream with id ${id} not found`);
  }
}

class LocalStreamAlreadyJoined extends Error {
  constructor(public id: string) {
    super(`Local Stream with id ${id} is joined already`);
  }
}

@Injectable({ providedIn: 'root' })
export class ConferenceJoinEffects {
  addLocalStream$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        ConferenceActions.addLocalPrimarySuccess,
        ConferenceActions.addLocalSecondarySuccess,
      ),
      map(({ stream }) => ConferenceActions.join({ id: stream.id })),
    );
  });

  sessionLeave$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SessionActions.leave),
      concatLatestFrom(() => this.store.select(selectLocalParticipantId)),
      concatLatestFrom(([_, localId]) =>
        this.store.select(selectParticipantStreamsIds(localId)),
      ),
      mergeMap(([, streamIds]) =>
        streamIds.map(id => ConferenceActions.leave({ id })),
      ),
    );
  });

  join$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConferenceActions.join),
      map(action => action.id),
      concatLatestFrom(id => this.store.select(selectLocalStream(id))),
      mergeMap(([id, stream]) => {
        if (!stream) {
          return throwError(new LocalStreamNotFoundError(id));
        }

        if (stream.joined) {
          return throwError(new LocalStreamAlreadyJoined(id));
        }

        const config = this.conferenceService.streamToJoinConfig(stream);

        return this.conferenceService.join(id, config).pipe(
          map(() =>
            ConferenceActions.joinSuccess({
              id,
            }),
          ),
          catchError(error => {
            const errorObj = {
              id,
              error,
            };
            console.error('CONFERENCE_JOIN', stream, errorObj);
            return of(ConferenceActions.joinError(errorObj));
          }),
        );
      }),
    );
  });

  leave$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ConferenceActions.leave),
        tap(({ id }) => {
          this.conferenceService.leave(id);
        }),
      );
    },
    {
      dispatch: false,
    },
  );

  private waitForDevices(audio: string | boolean, video: string | boolean) {
    return combineLatest([
      audio === false ? of(true) : this.store.select(selectAudioDevice(audio)),
      video === false ? of(true) : this.store.select(selectVideoDevice(video)),
    ]).pipe(
      filter(results => results.every(Boolean)),
      take(1),
      timeoutWith(60_000, throwError(new Error('A/V devices not available'))),
    );
  }

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private conferenceService: RoomConferenceService,
  ) {}
}
