<div
  (window:mousemove)="onMoveScrubber($event)"
  (window:mouseup)="onReleaseScrubber($event)"
  class="pl-youtube-player"
  data-testid="youtube-player"
>
  <div
    *ngIf="isClinician && !!iPadStudentsPendingYoutubeInteraction"
    class="youtube-verification-message"
  >
    <span class="message">Ask&nbsp;</span>
    <span class="list">{{ iPadStudentsPendingYoutubeInteraction }}</span>
    <span class="message margin-small-r">&nbsp;to touch the treasure box</span>

    <pl-icon
      class="jiggle"
      [svg]="'treasure-chest'"
      [width]="25"
      [height]="25"
    ></pl-icon>
  </div>
  <div
    class="player-area"
    [ngStyle]="{
      width: playerWidth + 'px',
      height: playerHeight + 'px',
      margin: '-' + marginTop + 'px 0 0 -' + marginLeft + 'px'
    }"
    style="position: relative"
  >
    <div
      class="ipad-video-overlay"
      [ngStyle]="{
        width: playerWidth + 'px',
        height: playerHeight + 150 + 'px'
      }"
      style="display: none"
      (click)="activateYoutubeFromInteraction($event)"
    >
      <div class="treasure-chest" width="456" height="356">
        <pl-icon
          class="jiggle"
          [svg]="'treasure-chest'"
          [width]="456"
          [height]="356"
          [scale]="0.8"
        ></pl-icon>
      </div>
    </div>
    <div class="player" style="display: none"></div>
    <nav class="controls" [hidden]="isVideoBlocked || !playerReady">
      <button
        [ngClass]="{ 'play-pause': true, playing: playing }"
        (click)="!blockPlayButton && play(!playing)"
      >
        <mat-icon *ngIf="!playing" class="play-btn">play_arrow</mat-icon>
        <mat-icon *ngIf="playing" class="pause-btn">pause</mat-icon>
      </button>
      <div class="scrubber" (mousedown)="onPressScrubber($event)">
        <div class="track">
          <div
            class="progress-fill"
            [ngStyle]="{ width: progressPercentage + '%' }"
          ></div>
          <button
            class="handle"
            [ngStyle]="{ left: progressPercentage + '%' }"
          ></button>
        </div>
      </div>
      <div class="right-nav">
        <button
          class="sound-btn"
          [ngClass]="{ mutebtn: true, active: muted }"
          (click)="mute(!muted)"
        >
          <mat-icon *ngIf="!muted">volume_up</mat-icon>
          <mat-icon *ngIf="muted">volume_off</mat-icon>
        </button>
        <time
          >{{ currentTime }} <span class="slash">/</span> {{ totalTime }}</time
        >
      </div>
    </nav>
    <button class="click-trapper" (click)="play(!playing)"></button>
  </div>
</div>
