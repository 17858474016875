<div class="service-details">
  <label>{{ timeRange }}</label>
  <mat-form-field color="accent" class="w-100">
    <mat-label>Select a billing code</mat-label>
    <mat-select
      (selectionChange)="onBillingCodeSelected($event.value)"
      [value]="selectedBillingCodeId"
      [matTooltip]="selectedBillingCodeLabel"
      [matTooltipDisabled]="selectedBillingCodeLabel.length < 23">
      <mat-option
        [matTooltip]="option.label"
        [matTooltipDisabled]="option.label.length < 39"
        *ngFor="let option of billingCodeSelectOptions"
        [value]="option.value"
        >{{ option.label }}</mat-option
      >
    </mat-select>
  </mat-form-field>
  <div *ngIf="services?.length === 0 && requiresService; else serviceSelection">
    <pl-alert-message>
      <div>
        This client is missing a service. You must add a service before you can
        document this session.
      </div>
      <a [href]="documentationHelpUrl" target="_blank"
        >Read about documentation in the Presence Knowledge Base</a
      >
      <button mat-flat-button color="primary" (click)="openServices()">
        <span>Add a service</span>
        <pl-icon-new [name]="'open_in_new'"></pl-icon-new>
      </button>
    </pl-alert-message>
  </div>
  <ng-template #serviceSelection>
    <mat-form-field color="accent" class="w-100" *ngIf="requiresService">
      <mat-label>Select a service</mat-label>
      <mat-select
        (selectionChange)="selectedService($event.value)"
        [value]="selectedServiceId"
        [matTooltip]="selectedServiceLabel"
        [matTooltipDisabled]="selectedServiceLabel.length < 23">
        <mat-option
          *ngFor="let option of serviceSelectOptions"
          [matTooltip]="option.label"
          [matTooltipDisabled]="option.label.length < 39"
          [value]="option.value"
          >{{ option.label }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <button mat-stroked-button color="primary" (click)="onOpenEventDetails()">
      <span>View event details</span>
      <pl-icon-new [name]="'open_in_new'"></pl-icon-new>
    </button>
  </ng-template>

  <pl-alert-message *ngIf="promptForMakeUpSession">
    <div>
      Does this missed session require a make-up? You may add a general note and
      sign-off on this event in the Notes tab.
    </div>
    <mat-radio-group
      [value]="isMakeUpSessionRequired"
      [disabled]="clientAppointment.record.signed"
      (change)="onMakeUpChoiceChanged($event)"
      class="rsm-radio-buttons">
      <mat-radio-button class="margin-large-r" [value]="true"
        >Yes</mat-radio-button
      >
      <mat-radio-button [value]="false">No</mat-radio-button>
    </mat-radio-group>
  </pl-alert-message>
  <pl-session-recording
    [clientData]="client"
    [clientAppointment]="clientAppointment"
    *ngIf="isEvaluation && services?.length > 0"></pl-session-recording>
</div>
