import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PLButtonModule, PLIconModule, PLInputModule } from '@root/index';
import { GameStartEndBtnsModule } from '../game/game-start-end-btns';
import { PLImageDecksModule } from '../../pl-image-decks';
import { PLGameComponent } from './pl-game.component';
import { PLGameDrawerComponent } from './pl-game-drawer.component';
import { PLGuessWhoService } from './guess-who/pl-guess-who.service';
import { PLScenesModule } from './pl-scenes';
import { PLVendorGamesModule } from './vendor-games';
import { PLClueModule } from './pl-clue';
import { PLCandyLandModule } from './pl-candyland';
import { PLGoFishModule } from './go-fish';
import { PLGuessWhoModule } from './guess-who';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  imports: [
    CommonModule,
    GameStartEndBtnsModule,
    MatIconModule,
    PLButtonModule,
    PLIconModule,
    PLInputModule,
    PLImageDecksModule,
    PLScenesModule,
    PLVendorGamesModule,
    PLClueModule,
    PLCandyLandModule,
    PLGoFishModule,
    PLGuessWhoModule,
  ],
  exports: [PLGameComponent, PLGameDrawerComponent],
  declarations: [PLGameComponent, PLGameDrawerComponent],
  providers: [PLGuessWhoService],
})
export class PLGameModule {}

export { PLGameComponent } from './pl-game.component';
export { PLGameDrawerComponent } from './pl-game-drawer.component';
