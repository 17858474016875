<h1
  [matTooltip]="isLongDisplayName ? (displayName$ | async) : null"
  class="display-name"
  [ngClass]="{'can-promote': canPromote$ | async}"
>
  <mat-icon class="status-icon" *ngIf="showMuted$ | async"> mic_off </mat-icon>
  <mat-icon class="status-icon" *ngIf="showCovered$ | async">
    videocam_off
  </mat-icon>
  <mat-icon
    class="status-icon-custom"
    *ngIf="showClickMuted$ | async"
    [svgIcon]="'mouse_click_muted'"
  >
  </mat-icon>
  <span #displayNameSpan>
    {{ displayName$ | async }}
  </span>
</h1>
