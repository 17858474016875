import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

interface NotificationData {
  type?: 'default' | 'error';
  text: string;
}

const DEFAULT_DURATION = 4000;

@Injectable({
  providedIn: 'root',
})
export class Notifications {
  constructor(private snackBar: MatSnackBar) {}

  show(
    notification: NotificationData,
    verticalPosition: MatSnackBarVerticalPosition = 'top',
    horizontalPosition: MatSnackBarHorizontalPosition = 'center',
  ) {
    this.snackBar.open(notification.text, null, {
      duration: DEFAULT_DURATION,
      panelClass: `snack-bar-is-${notification.type || 'default'}`,
      verticalPosition,
      horizontalPosition,
    });
  }
}
