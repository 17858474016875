import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';

import { PLCommonComponentsModule } from '@common/components';
import { KangaDocumentationComponent } from './kanga-documentation.component';

import { KangaDocumentationAppointmentSelectionComponent } from './kanga-documentation-appointment-selection/kanga-documentation-appointment-selection.component';
import { KangaDocumentationAppointmentItemComponent } from './kanga-documentation-appointment-item/kanga-documentation-appointment-item.component';
// import { KangaServiceDetailsComponent } from './kanga-service-details/kanga-service-details.component';

import { KangaDocumentationService } from './kanga-documentation.service';

import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    PLCommonComponentsModule,
    MatButtonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatTabsModule,
    MatTooltipModule,
  ],
  exports: [KangaDocumentationComponent],
  declarations: [
    KangaDocumentationComponent,
    KangaDocumentationAppointmentSelectionComponent,
    KangaDocumentationAppointmentItemComponent,
    // KangaServiceDetailsComponent,
  ],
  providers: [KangaDocumentationService],
})
export class KangaDocumentationModule {}
