import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PLUrlsService } from '@common/services/pl-http';

import { SelectionOption } from '@common/components/multi-select-menu/multi-select-menu.component';
import { ClientAppointmentsService } from '../client-appointments.service';

@Component({
  selector: 'pl-presence-documentation-appointment-selection',
  templateUrl: 'presence-documentation-appointment-selection.component.html',
  styleUrls: ['presence-documentation-appointment-selection.component.less'],
})
export class PresenceDocumentationAppointmentSelectionComponent {
  @Input() clientAppointmentsSelectionOptions: SelectionOption[] = [];
  @Output() readonly selectionChanged = new EventEmitter<SelectionOption[]>();

  scheduleUrl: string;
  loading = false;

  constructor(
    private plUrls: PLUrlsService,
    appointmentsService: ClientAppointmentsService,
  ) {
    this.scheduleUrl = this.plUrls.urls.scheduleFE;
    this.loading = appointmentsService.loadingAppointments;
  }

  openSchedule() {
    window.open(`${this.scheduleUrl}/calendar`, '_blank');
  }

  clientAppointmentSelectionChanged(options) {
    this.selectionChanged.emit(options);
  }
}
