import { Component, OnDestroy, OnInit, Input, NgZone } from '@angular/core';
import { Subscription } from 'rxjs';
import { GamePieceInstance } from '@modules/pl-games/pl-board-games/pl-board-games-factory.service';
import { FirebaseService } from '@root/src/app/common/firebase/firebase.service';

@Component({
  selector: 'pl-candyland-drawer',
  templateUrl: './pl-candyland-drawer.component.html',
  styleUrls: ['./pl-candyland-drawer.component.less'],
})
export class PLCandyLandDrawerComponent implements OnInit, OnDestroy {
  @Input() activity: any = {};
  showCandyLandInstruction: boolean = false;
  isGameActive: boolean;
  enableStartGameBtn: boolean;
  enableEndGameBtn: boolean;
  undoBtnDisabled: boolean;
  currentCastle: number;
  newDeck = [];

  firebaseCandyLandRef;
  private subscriptions: Subscription[] = [];
  public startingCardDeck: any = {
    blue_1: 6,
    blue_2: 6,
    green_1: 6,
    green_2: 6,
    orange_1: 6,
    orange_2: 6,
    purple_1: 6,
    purple_2: 6,
    red_1: 6,
    red_2: 6,
    yellow_1: 6,
    yellow_2: 6,
    bon_bon: 1,
    gumdrop: 1,
    ice_cream: 1,
    king: 24,
    lollipop: 1,
    peppermint: 1,
  };
  private startingPieces: GamePieceInstance[] = [
    {
      displayName: 'chip1',
      height: 5,
      imageSrc:
        '/assets/board-games/candyland_game_assets/player_pieces/blue_game_piece.png',
      name: 'chip1',
      updated: 1655839864956,
      width: 3.5,
      x: 25.27,
      y: 87,
    },
    {
      displayName: 'chip2',
      height: 5,
      imageSrc:
        '/assets/board-games/candyland_game_assets/player_pieces/blue_game_piece.png',
      name: 'chip2',
      updated: 1655839864956,
      width: 3.5,
      x: 29.5,
      y: 88.7,
    },
    {
      displayName: 'chip3',
      height: 5,
      imageSrc:
        '/assets/board-games/candyland_game_assets/player_pieces/blue_game_piece.png',
      name: 'chip3',
      updated: 1655839864956,
      width: 3.5,
      x: 33.6,
      y: 90.7,
    },
    {
      displayName: 'chip4',
      height: 5,
      imageSrc:
        '/assets/board-games/candyland_game_assets/player_pieces/blue_game_piece.png',
      name: 'chip4',
      updated: 1655839864956,
      width: 3.5,
      x: 37.6,
      y: 90.9,
    },
    {
      displayName: 'chip5',
      height: 5,
      imageSrc:
        '/assets/board-games/candyland_game_assets/player_pieces/blue_game_piece.png',
      name: 'chip5',
      updated: 1655839864956,
      width: 3.5,
      x: 41.7,
      y: 89.2,
    },
    {
      displayName: 'chip6',
      height: 5,
      imageSrc:
        '/assets/board-games/candyland_game_assets/player_pieces/blue_game_piece.png',
      name: 'chip6',
      updated: 1655839864956,
      width: 3.5,
      x: 45.5,
      y: 88,
    },
  ];

  constructor(public firebaseService: FirebaseService, public zone: NgZone) {}

  ngOnInit(): void {
    const fbPath = `activities/queues/items/${this.activity.queueId}/items/${this.activity.activityId}`;
    this.firebaseCandyLandRef = this.firebaseService.getRoomRef(fbPath);
    this.initFirebase();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  addPlayerPieces() {
    this.startingPieces.map(piece => {
      const newPostKey = this.firebaseCandyLandRef.child('pieces').push().key;
      piece.key = newPostKey;

      this.fbUpdatePiece(piece);
      return piece;
    });
  }

  fbUpdatePiece(piece: GamePieceInstance) {
    piece.updated = new Date().getTime();
    this.firebaseCandyLandRef.child('pieces/' + piece.key).update(piece);
    this.firebaseCandyLandRef.child('modified').set(new Date().getTime());
  }

  createDeck() {
    let keys = Object.keys(this.startingCardDeck);
    keys.map(key => {
      if (this.startingCardDeck[key] > 0) {
        for (let i = 0; i < this.startingCardDeck[key]; i++) {
          this.newDeck.push(key);
        }
      }
    });

    this.newDeck.sort((first, second) => 0.5 - Math.random());
    this.firebaseCandyLandRef.update({ cardDeck: [...this.newDeck] });
  }

  onClickStartNewGame(): void {
    this.updateIsGameActive(true);
    this.createDeck();
    this.addPlayerPieces();
    this.updateStartGameBtn(false);
    this.updateEndGameBtn(true);
  }

  onClickEndGame(): void {
    this.firebaseCandyLandRef.update({
      cardDeck: null,
      currentCard: 'card_back',
    });
    this.startNewFirebase();
    this.updateEndGameBtn(false);
    this.updateIsGameActive(false);
  }

  updateStartGameBtn(status?: boolean): void {
    this.firebaseCandyLandRef.update({ enableStartGameBtn: status });
  }

  updateEndGameBtn(status?: boolean): void {
    this.firebaseCandyLandRef.update({ enableEndGameBtn: status });
  }

  updateIsGameActive(status?: boolean): void {
    this.firebaseCandyLandRef.update({ isGameActive: status });
  }

  restoreCastlePiece(): void {
    if (this.currentCastle > 0) {
      const newCastle = this.currentCastle - 1;
      this.firebaseCandyLandRef.update({ currentCastleNumber: newCastle });
      if (newCastle === 0) {
        this.firebaseCandyLandRef.update({ undoBtnDisabled: true });
      }
    }
  }

  toggleInstruction(): void {
    this.firebaseCandyLandRef.update({
      showInstruction: !this.showCandyLandInstruction,
    });
  }

  initFirebase() {
    this.firebaseCandyLandRef.on('value', data => {
      const val = data.val();
      if (!val) {
        return;
      }

      this.zone.run(() => {
        const {
          isGameActive,
          enableStartGameBtn,
          enableEndGameBtn,
          showInstruction,
          undoBtnDisabled,
          currentCastleNumber,
        } = data.val();
        this.isGameActive = !!isGameActive;
        this.enableStartGameBtn = !!enableStartGameBtn;
        this.enableEndGameBtn = !!enableEndGameBtn;
        this.showCandyLandInstruction = !!showInstruction;
        this.undoBtnDisabled = !!undoBtnDisabled;
        this.currentCastle = currentCastleNumber;

        if (showInstruction === undefined) {
          this.firebaseCandyLandRef.update({ showInstruction: false });
        }

        if (currentCastleNumber > 0) {
          this.undoBtnDisabled = false;
          this.firebaseCandyLandRef.update({ undoBtnDisabled: false });
        }

        if (!isGameActive) {
          this.enableStartGameBtn = true;
          this.startNewFirebase();
        }

        if (!isGameActive || currentCastleNumber === 0) {
          this.undoBtnDisabled = true;
          this.firebaseCandyLandRef.update({ undoBtnDisabled: true });
        }
      });
    });
  }

  startNewFirebase() {
    this.firebaseCandyLandRef.update({
      isGameActive: false,
      enableEndGameBtn: false,
      enableStartGameBtn: true,
      undoBtnDisabled: true,
      currentCastleNumber: 0,
      currentCard: '',
      cardDeck: [],
      pieces: [],
    });
  }
}
