import { propertyOf, cloneDeep } from 'lodash';
import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ViewEncapsulation,
  Output,
  EventEmitter,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@root/src/app/store';
import { selectCurrentDrawerActivity } from '../../room/app/store';
import { CurrentUserModel } from '@common/models/CurrentUserModel';
import { PLAssessmentService } from '../../room/pl-activity/services/assessment.service';
import { PLLessonService } from '../../room/pl-activity/services/lesson.service';
import { PLActivityService } from '../../room/pl-activity/services/activity.service';

@Component({
  selector: 'pl-activity-drawer',
  templateUrl: 'pl-activity-drawer.component.html',
  styleUrls: ['./pl-activity-drawer.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class PLActivityDrawerComponent implements OnInit, OnDestroy {
  @Input() private storeActivity: string;

  private subscriptions: Subscription[] = [];
  typeActivity = 'activity';
  typeAssessment = 'assessment';
  typeLesson = 'lesson';
  typeGame = 'game';
  types: string[];
  typeFieldName = 'type';
  typeFieldNameStore = 'activity_type';
  activityAssessmentFieldName = 'activity_type';
  activityAssessmentFieldNameStore = 'resource_type';
  showActivity = false;
  currentService = null;
  unavailable = false;
  assessmentAccessDenied = false;
  activityType;
  currentActivity;

  constructor(
    private activityService: PLActivityService,
    private assessmentService: PLAssessmentService,
    private lessonService: PLLessonService,
    private store: Store<AppState>,
    private currentUserModel: CurrentUserModel,
  ) {
    this.types = [this.typeActivity, this.typeAssessment, this.typeGame];
  }

  ngOnInit() {
    this.subscriptions.push(this.listenCurrentActivity());
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
    if (!this.currentService) {
      return;
    }
    this.currentService.reset();
    this.currentService = null;
  }

  listenCurrentActivity() {
    const sub = this.store
      .select(selectCurrentDrawerActivity)
      .subscribe(activity => {
        if (
          this.currentActivity &&
          activity &&
          this.currentActivity.activityId === activity.activityId
        ) {
          return;
        }
        this.showActivity = false;
        this.unavailable = false;
        this.currentActivity = activity;
        if (!activity) {
          this.activityType = null;
          if (this.currentService) {
            this.currentService.reset();
          }
          return;
        }
        const typeField = this.storeActivity
          ? this.typeFieldNameStore
          : this.typeFieldName;
        const isActivity =
          activity[this.activityAssessmentFieldName] === this.typeActivity ||
          activity[this.activityAssessmentFieldNameStore] === this.typeActivity;
        const isGame =
          activity[this.activityAssessmentFieldName] === this.typeGame ||
          activity[this.activityAssessmentFieldNameStore] === this.typeGame;
        const isAssessment =
          activity[this.activityAssessmentFieldName] === this.typeAssessment ||
          activity[this.activityAssessmentFieldNameStore] ===
            this.typeAssessment;
        const isLesson =
          activity[this.activityAssessmentFieldName] === this.typeLesson ||
          activity[this.activityAssessmentFieldNameStore] === this.typeLesson;
        const serviceData = cloneDeep(activity);
        if (-1 !== this.types.indexOf(serviceData.activity_type)) {
          serviceData.resource_type = serviceData.activity_type;
        }
        this.activityType = propertyOf(activity)(typeField);
        if (!isGame) {
          this.currentService = isActivity
            ? this.activityService
            : isLesson
            ? this.lessonService
            : this.assessmentService;
          this.currentService
            .startup(serviceData)
            .then(() => {
              this.showActivity = true;
            })
            .catch(err => {
              const isPLProvider =
                this.currentUserModel.user &&
                this.currentUserModel.user.groups &&
                this.currentUserModel.user.groups.indexOf('Provider') > -1;

              // PL providers get a specific error message on blocked assessment
              // content
              if (isPLProvider && isAssessment && err.status === 403) {
                this.assessmentAccessDenied = true;
              } else {
                this.unavailable = true;
              }
            });
        } else {
          this.showActivity = true;
        }

        if (this.storeActivity) {
          sub.unsubscribe();
        }
      });
    return sub;
  }
}
