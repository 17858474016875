import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@root/src/app/store';
import { debounce } from 'lodash';
import * as PDFJS from 'pdfjs-dist/build/pdf';
import { selectIsDrawerOpen } from '../../pl-drawers/store';
import { selectIsLayoutModeFullScreen } from '../../app/store';
import { filter } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { ResizeObserverService } from '@common/services';
import { selectIsLocalParticipantHost } from '../../session/store';
export enum PDFPageModes {
  SINGLE_PAGE_MODE = 1,
  DBL_PAGE_EVEN_MODE = 2,
  DBL_PAGE_ODD_MODE = 3,
}
import { LoggerService } from '@root/src/app/common/services/logger/logger.service';
const PDF_RENDER_TOLERANCE_MS = 3000;
@Component({
  selector: 'pl-pdf-viewer',
  templateUrl: 'pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.less'],
  providers: [ResizeObserverService],
})
export class PLPDFViewerComponent
  implements OnInit, OnDestroy, AfterViewInit, OnChanges
{
  @Input() public pdfUrl: string;
  @Input() public pageNumber = 1;
  @Input() public bookMode = 1;
  @Input() public scale = 1;
  @Input() public pageRotations: Record<string, number> = {};
  @Input() public scrollXPercent = 0;
  @Input() public scrollYPercent = 0;
  @Output() public pdfLoaded = new EventEmitter<any>();
  @Output() public scrollXPercentChange = new EventEmitter<number>();
  @Output() public scrollYPercentChange = new EventEmitter<number>();
  @ViewChild('pdfHolder') private holderRef: ElementRef<HTMLElement>;
  @ViewChild('viewer') private viewerRef: ElementRef<HTMLElement>;
  @ViewChild('pdfHolderCanvas')
  private pdfHolderCanvasRef: ElementRef<HTMLCanvasElement>;
  @ViewChild('page2Canvas')
  private page2CanvasRef: ElementRef<HTMLCanvasElement>;

  private canvas: HTMLCanvasElement;
  private pdf = null;
  private scrollOffset = {
    x: null,
    y: null,
  };
  private boundingEl: Element;
  private currentlyRenderingNum = 0;
  private holderEventsDisabled = false;
  private subscriptions: Subscription[] = [];
  private scrolling = false;
  private loadingTasks = [];

  rendering = false;
  renderTask;
  renderTask2;
  loading = false;
  soloPage = false;
  canvasWidth = 0;
  canvasHeight = 0;
  holderWidth = 0;
  holderHeight = 0;
  pageHolderWidth = 0;
  pageHolderTop = 0;
  scrollPage = debounce(e => this.saveScrollPosition(), 20);
  resizeHandler = debounce(
    () => {
      setTimeout(() => {
        this.render();
      });
    },
    100,
    { trailing: true },
  );
  isHost$: Observable<boolean>;

  constructor(
    private store: Store<AppState>,
    private resizeObserverService: ResizeObserverService,
    private loggerService: LoggerService,
  ) {
    this.isHost$ = store.select(selectIsLocalParticipantHost);
  }

  ngOnInit() {
    this.initialize();
    const elements = document.getElementsByClassName(
      'aspect-ratio-constriction',
    );
    if (elements.length) {
      this.boundingEl = elements[0];
    }
    if (this.pdfUrl) {
      this.loadPDF(this.pdfUrl);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.propertyChanged(changes.pdfUrl)) {
      this.loadPDF(this.pdfUrl);
    } else if (this.pdfUrl) {
      if (
        this.propertyChanged(changes.scale) ||
        this.propertyChanged(changes.pageNumber) ||
        this.propertyChanged(changes.pageRotations) ||
        this.propertyChanged(changes.bookMode)
      ) {
        this.render();
      } else if (
        this.propertyChanged(changes.scrollYPercent) ||
        this.propertyChanged(changes.scrollXPercent)
      ) {
        this.positionPageFromPercentage(
          this.scrollXPercent,
          this.scrollYPercent,
        );
      }
    }
  }

  private propertyChanged(change: SimpleChange) {
    if (!change) return false;

    const prevValue =
      typeof change.previousValue === 'object'
        ? JSON.stringify(change.previousValue)
        : change.previousValue;

    const currentValue =
      typeof change.currentValue === 'object'
        ? JSON.stringify(change.currentValue)
        : change.currentValue;

    return prevValue !== currentValue;
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(this.listenToHolderResize());
  }

  listenToHolderResize() {
    return this.resizeObserverService
      .observe(this.viewerRef.nativeElement)
      .subscribe(() => {
        this.resizeHandler();
      });
  }

  ngOnDestroy() {
    this.loadingTasks.forEach(lt => lt.destroy());
    this.subscriptions.forEach(s => s.unsubscribe());
    if (this.pdf) {
      try {
        this.pdf.destroy();
      } catch (destroyError) {
        console.error('destroy error: ', destroyError);
      }
    }
  }

  loadPDF(url) {
    this.loading = true;
    try {
      const task = PDFJS.getDocument(url);
      this.loadingTasks.push(task);

      task.promise
        .then(
          loaded_pdf => {
            this.pdf = loaded_pdf;
            this.pdfLoaded.emit(this.pdf);
            this.render();
          },
          error => {
            console.error(
              'PDFJS Error while getting PDF document in PdfViewerDirective:',
              error,
            );
          },
        )
        .catch(e => {
          console.error('PDFJS load error in PdfViewerDirective: ', e);
        });
    } catch (exc) {
      console.error('PDFJS load error in PdfViewerDirective: ', exc);
    }
  }

  onMouseMove(event, isHost) {
    if (!this.scrolling || !isHost) {
      return;
    }
    const deltaY = this.scrollOffset.y - event.clientY;
    const deltaX = this.scrollOffset.x - event.clientX;

    this.positionPage(deltaX, deltaY);
    this.saveScrollPosition();
  }

  onMouseUp = (event, isHost) => {
    this.scrolling = false;
    if (this.holderEventsDisabled || !isHost) {
      return;
    }
    this.holderEventsDisabled = true;

    this.scrollOffset.x = null;
    this.scrollOffset.y = null;
  };

  onMouseDown = (event, isHost) => {
    if (!isHost) {
      return;
    }
    this.scrolling = true;
    this.holderEventsDisabled = false;

    this.scrollOffset.y =
      this.holderRef.nativeElement.scrollTop + event.clientY;
    this.scrollOffset.x =
      this.holderRef.nativeElement.scrollLeft + event.clientX;
  };

  /**
   * Determines the percentage scroll of the page with 0 being scrolled to the top
   * of the page and 100% being scrolled to the bottom of the page. If no scrolling
   * is present, returns 0;
   * @param  {[type]} x [description]
   * @return {[type]}   [description]
   */
  private calculatePagePercentY() {
    const result = this.holderRef.nativeElement.scrollTop / this.canvas.height;
    return isNaN(result) ? 0 : result;
  }

  private calculatePagePercentX() {
    const result = this.holderRef.nativeElement.scrollLeft / this.canvas.width;
    return isNaN(result) ? 0 : result;
  }

  private saveScrollPosition() {
    if (this.canvas) {
      const newScrollY = this.calculatePagePercentY();
      const newScrollX = this.calculatePagePercentX();
      this.scrollXPercentChange.emit(newScrollX);
      this.scrollYPercentChange.emit(newScrollY);
    }
  }

  private calcScale(pdfW, pdfH, containerW, containerH, newScale) {
    let scaleX;
    let scaleY;
    let resScale = newScale;

    if (pdfW < containerW && pdfH < containerH) {
      scaleX = containerW / pdfW;
      scaleY = containerH / pdfH;
      resScale = Math.min(scaleX, scaleY) * resScale;
    } else if (pdfW > containerW && pdfH > containerH) {
      scaleX = containerW / pdfW;
      scaleY = containerH / pdfH;
      resScale = Math.min(scaleX, scaleY) * resScale;
    } else if (pdfW < containerW && pdfH > containerH) {
      // it's taller than the container, make the height smaller
      scaleY = containerH / pdfH;
      resScale = scaleY * resScale;
    } else if (pdfW > containerW && pdfH < containerH) {
      // it's wider than the container, make it narrower
      scaleX = containerW / pdfW;
      resScale = scaleX * resScale;
    }
    return resScale;
  }

  private positionPageX(x) {
    this.holderRef.nativeElement.scrollLeft = x;
  }

  private positionPageY(y) {
    this.holderRef.nativeElement.scrollTop = y;
  }

  private positionPage(x, y) {
    this.positionPageX(x);
    this.positionPageY(y);
  }

  private positionPageXFromPercentage(xPercent) {
    if (this.canvas) {
      this.positionPageX(xPercent * this.canvas.width);
    }
  }

  private positionPageYFromPercentage(yPercent) {
    if (this.canvas) {
      this.positionPageY(yPercent * this.canvas.height);
    }
  }

  private positionPageFromPercentage(xPercent, yPercent) {
    this.positionPageXFromPercentage(xPercent);
    this.positionPageYFromPercentage(yPercent);
  }

  private render() {
    const pageNum = this.pageNumber;
    if (typeof pageNum === 'undefined') {
      return;
    }

    if (!this.pdf) {
      return;
    }

    const totalPages = this.pdf.numPages;

    // We're forcing to render a single page if the mode is set to single
    // or if it's the first page in double mode that start with a single page
    // or if it's the last page in double mode that ends with a single page
    if (
      this.bookMode === PDFPageModes.SINGLE_PAGE_MODE ||
      (pageNum === 1 && this.bookMode === PDFPageModes.DBL_PAGE_EVEN_MODE) ||
      (pageNum === totalPages &&
        this.bookMode === PDFPageModes.DBL_PAGE_EVEN_MODE &&
        totalPages % 2 === 0) ||
      (pageNum === totalPages &&
        this.bookMode === PDFPageModes.DBL_PAGE_ODD_MODE &&
        totalPages % 2 !== 0)
    ) {
      this.soloPage = true;
      return this.renderSingle(pageNum);
    } else {
      this.soloPage = false;
      return this.renderDouble(pageNum);
    }
  }

  private renderSingle(pageNumParam) {
    if (this.renderTask) {
      this.renderTask.cancel();
    }

    const pageNum = pageNumParam || this.pageNumber;
    const pageRotationKey = `page${pageNum}`;
    const rotation = Number.isInteger(this.pageRotations[pageRotationKey])
      ? this.pageRotations[pageRotationKey]
      : undefined;

    if (isNaN(pageNum)) {
      console.warn('Page number is not a number: ', pageNum);
      return;
    }

    this.currentlyRenderingNum = pageNum;
    return (
      this.pdf &&
      this.pdf
        .getPage(pageNum)
        .then(
          page => {
            if (this.currentlyRenderingNum !== pageNum) {
              return;
            }

            this.rendering = true;

            const initialViewport = page.getViewport({
              scale: 1.0,
              rotation,
            });

            const viewportScale = this.calcScale(
              initialViewport.width,
              initialViewport.height,
              this.holderRef.nativeElement.clientWidth,
              this.holderRef.nativeElement.clientHeight,
              this.scale,
            );

            const viewport = page.getViewport({
              scale: viewportScale,
              rotation,
            });

            this.canvas = this.pdfHolderCanvasRef.nativeElement;
            const canvasWidth = viewport.width;
            const canvasHeight = viewport.height;
            this.canvas.width = canvasWidth;
            this.canvas.height = canvasHeight;

            this.pageHolderWidth = 0;

            let pageTop;
            if (viewport.height > viewport.width) {
              pageTop = Math.max(
                (this.boundingEl.clientHeight - viewport.height) / 2,
                0,
              );
            } else {
              pageTop = Math.max(
                (this.boundingEl.clientHeight - viewport.width) / 2,
                0,
              );
            }

            this.pageHolderTop = pageTop;

            const context = this.canvas.getContext('2d');
            const offscreenCanvas = document.createElement('canvas');
            // render the page to an offscreen canvas first, then copy the complete rendered raster
            // with drawImage, to avoid partial render errors from complex PDFs
            offscreenCanvas.width = canvasWidth;
            offscreenCanvas.height = canvasHeight;

            const offscreenCanvasContext = offscreenCanvas.getContext('2d');
            offscreenCanvasContext.clearRect(0, 0, canvasWidth, canvasHeight);

            const renderContext = {
              viewport,
              canvasContext: offscreenCanvasContext,
            };

            this.renderTask = page.render(renderContext);

            const renderStarted = Date.now();
            return this.renderTask.promise.then(
              () => {
                if (offscreenCanvas.width > 0 && offscreenCanvas.height > 0) {
                  context.drawImage(offscreenCanvas, 0, 0);
                }
                this.loading = false;
                this.positionPageFromPercentage(
                  this.scrollXPercent,
                  this.scrollYPercent,
                );

                this.rendering = false;
                const renderFinished = Date.now();
                const renderDuration = renderFinished - renderStarted;
                // Log an event when rendering takes > 3s
                if (renderDuration > PDF_RENDER_TOLERANCE_MS) {
                  this.loggerService.logEvent('Slow PDF Render', {
                    level: 'warning',
                    tags: {
                      pdf: this.pdfUrl,
                      renderTimeMS: renderDuration,
                      pageNumber: this.pageNumber,
                    },
                  });
                }
              },
              error => {
                if (error.name !== 'RenderingCancelledException') {
                  console.error(error);
                }
              },
            );
          },
          err => {
            console.error('Error retreiving PDF page: ', err);
            console.log('Errored on page number: ', pageNum);
            console.log('Errored on pdf: ', this.pdf);
          },
        )
        .catch(e => {
          console.error('PDFJS getPage error in PdfViewerDirective: ', e);
        })
    );
  }

  private renderDouble(pageNumParam) {
    if (this.renderTask) {
      this.renderTask.cancel();
    }
    if (this.renderTask2) {
      this.renderTask2.cancel();
    }

    const pageNum = pageNumParam || this.pageNumber;
    if (isNaN(pageNum)) {
      console.warn('Page number is not a number: ', pageNum);
      return;
    }

    this.currentlyRenderingNum = pageNum;

    if (!this.pdf) {
      return;
    }

    return Promise.all([
      this.pdf.getPage(pageNum),
      this.pdf.getPage(pageNum + 1),
    ])
      .then(([page1, page2]) => {
        if (this.currentlyRenderingNum !== pageNum) {
          return;
        }

        this.rendering = true;

        const page1RotationKey = `page${pageNum}`;
        const page2RotationKey = `page${pageNum + 1}`;
        const rotation1 = this.pageRotations[page1RotationKey] || 0;
        const rotation2 = this.pageRotations[page2RotationKey] || 0;

        const initialViewport1 = page1.getViewport({
          scale: 1.0,
          rotation: rotation1,
        });

        const viewportScale1 = this.calcScale(
          initialViewport1.width,
          initialViewport1.height,
          this.holderRef.nativeElement.clientWidth / 2,
          this.holderRef.nativeElement.clientHeight,
          this.scale,
        );

        const viewport1 = page1.getViewport({
          scale: viewportScale1,
          rotation: rotation1,
        });

        const initialViewport2 = page2.getViewport({
          scale: 1.0,
          rotation: rotation2,
        });

        const viewportScale2 = this.calcScale(
          initialViewport2.width,
          initialViewport2.height,
          this.holderRef.nativeElement.clientWidth / 2,
          this.holderRef.nativeElement.clientHeight,
          this.scale,
        );

        const viewport2 = page2.getViewport({
          scale: viewportScale2,
          rotation: rotation2,
        });

        // page 1
        this.canvas = this.pdfHolderCanvasRef.nativeElement;
        const canvasWidth = viewport1.width;
        const canvasHeight = viewport1.height;
        this.canvas.width = canvasWidth;
        this.canvas.height = canvasHeight;

        const context = this.canvas.getContext('2d');

        // render the page to an offscreen canvas first, then copy the complete rendered raster
        // with drawImage, to avoid partial render errors from complex PDFs
        const offscreenCanvas = document.createElement('canvas');
        offscreenCanvas.width = canvasWidth;
        offscreenCanvas.height = canvasHeight;
        const offscreenCanvasContext = offscreenCanvas.getContext('2d');
        offscreenCanvasContext.clearRect(0, 0, canvasWidth, canvasHeight);

        this.renderTask = page1.render({
          viewport: viewport1,
          canvasContext: offscreenCanvasContext,
        });

        this.renderTask.promise.then(() => {
          if (offscreenCanvas.width > 0 && offscreenCanvas.height > 0) {
            context.drawImage(offscreenCanvas, 0, 0);
          }
        });

        // page2
        const canvas2 = this.page2CanvasRef.nativeElement;
        canvas2.width = viewport2.width;
        canvas2.height = viewport2.height;

        this.pageHolderWidth = this.canvas.width + canvas2.width;

        const delta = Math.max(viewport1.height, viewport2.height);
        const pageTop = Math.max((this.boundingEl.clientHeight - delta) / 2, 0);
        this.pageHolderTop = pageTop;

        const context2 = canvas2.getContext('2d');

        // render the page to an offscreen canvas first, then copy the complete rendered raster
        // with drawImage, to avoid partial render errors from complex PDFs
        const offscreenCanvas2 = document.createElement('canvas');
        offscreenCanvas2.width = viewport2.width;
        offscreenCanvas2.height = viewport2.height;
        const offscreenCanvasContext2 = offscreenCanvas2.getContext('2d');
        offscreenCanvasContext2.clearRect(
          0,
          0,
          viewport2.width,
          viewport2.height,
        );

        this.renderTask2 = page2.render({
          canvasContext: offscreenCanvasContext2,
          viewport: viewport2,
        });

        return this.renderTask2.promise.then(
          () => {
            context2.drawImage(offscreenCanvas2, 0, 0);
            this.loading = false;
            this.positionPageFromPercentage(
              this.scrollXPercent,
              this.scrollYPercent,
            );

            this.rendering = false;
          },
          error => {
            if (error.name !== 'RenderingCancelledException') {
              console.error(error);
            }
          },
        );
      })
      .catch(e => {
        this.loggerService.error(e);
      });
  }

  private initialize() {
    this.subscriptions.push(
      this.store
        .select(selectIsDrawerOpen)
        // .pipe(filter(Boolean))
        .subscribe(() => {
          if (this.pdf) {
            this.resizeHandler();
          }
        }),
    );

    this.subscriptions.push(
      this.store
        .select(selectIsLayoutModeFullScreen)
        .pipe(filter(Boolean))
        .subscribe(() => {
          // if we don't give some time after a fullscreen event for the drawer animation to complete,
          // the PDF viewport may not be ready
          if (this.pageNumber) {
            setTimeout(() => {
              this.render();
            }, 400);
          }
        }),
    );
  }
}
