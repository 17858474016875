import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PLCommonComponentsModule } from '@common/components';
import { PLIconModule, PLInputModule } from '@root/index';
import { HotkeyModule } from 'angular2-hotkeys';
import { PLDragAndDropModule } from '@common/directives/drag-n-drop';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { FormsModule } from '@angular/forms';
import { FlashCardsService } from '../services/flashcards-new.service';
import { PLFlashcardsActivityDrawerComponent } from './pl-flashcards-activity-drawer.component';
import { FlashcardsDnDService } from '../services/flashcards-dnd.service';

@NgModule({
  imports: [
    CommonModule,
    PLCommonComponentsModule,
    PLIconModule,
    HotkeyModule,
    PLInputModule,
    PLDragAndDropModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatIconModule,
    MatSlideToggleModule,
    MatTabsModule,
    FormsModule,
  ],
  exports: [PLFlashcardsActivityDrawerComponent],
  declarations: [PLFlashcardsActivityDrawerComponent],
  providers: [FlashCardsService, FlashcardsDnDService],
})
export class PLFlashCardsDrawerModule {}
