import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { ClientAppointment, MetricPoint } from '../../../documentation.models';

@Component({
  selector: 'pl-metric-item',
  templateUrl: './metric-item.component.html',
  styleUrls: ['./metric-item.component.less'],
})
export class MetricItemComponent implements OnChanges {
  @Input() metricPoint: MetricPoint;
  @Input() updating: boolean;
  @Input() clientAppointment: ClientAppointment;
  @Output() metricPointUpdated = new EventEmitter<MetricPoint>();
  lastMetricPoint: Partial<MetricPoint> = {
    correct: 0,
    trials: 0,
  };

  ngOnChanges() {
    if (this.metricPoint.correct === undefined) {
      this.metricPoint.correct = 0;
    }
    if (this.metricPoint.trials === undefined) {
      this.metricPoint.trials = 0;
    }
    this.lastMetricPoint.trials = this.metricPoint.trials;
    this.lastMetricPoint.correct = this.metricPoint.correct;
    this.calculatePercent();
  }
  incrementCorrect() {
    if (!this.updating && this.isEditable()) {
      this.metricPoint.correct++;
      this.metricPoint.trials++;
      this.calculateAndSave();
    }
  }

  incrementWrong() {
    if (!this.updating && this.isEditable()) {
      this.metricPoint.trials++;
      this.calculateAndSave();
    }
  }
  correctUp() {
    if (
      !this.updating &&
      this.isEditable() &&
      this.metricPoint.correct < this.metricPoint.trials
    ) {
      this.metricPoint.correct++;
      this.calculateAndSave();
    }
  }

  correctDown() {
    if (!this.updating && this.isEditable() && this.metricPoint.correct > 0) {
      this.metricPoint.correct--;
      this.calculateAndSave();
    }
  }

  trialsUp() {
    if (!this.updating && this.isEditable()) {
      this.metricPoint.trials++;
      this.calculateAndSave();
    }
  }

  trialsDown() {
    if (!this.updating && this.isEditable() && this.metricPoint.trials > 0) {
      this.metricPoint.trials--;
      if (this.metricPoint.correct > this.metricPoint.trials) {
        this.metricPoint.correct = this.metricPoint.trials;
      }
      this.calculateAndSave();
    }
  }

  calculatePercent() {
    this.validateTrials();
    this.metricPoint.percent_correct =
      this.metricPoint.trials <= 0
        ? 0
        : Math.round(
            (this.metricPoint.correct / this.metricPoint.trials) * 100,
          );
  }

  calculateAndSave() {
    if (!this.updating) {
      this.calculatePercent();
      this.updateMetric();
    }
  }

  updateMetric() {
    this.metricPointUpdated.emit(this.metricPoint);
  }

  blurInput() {
    this.calculateAndSave();
  }

  keyInput(evt) {
    if (evt.keyCode === 13) {
      this.calculateAndSave();
    }
  }

  onKeyUpCorrect() {
    this.calculateAndSave();
  }
  onBlurCorrect() {
    this.calculateAndSave();
  }

  validateTrials() {
    if (
      (!this.metricPoint.correct && this.metricPoint.correct !== 0) ||
      this.metricPoint.correct < 0
    ) {
      this.metricPoint.correct = this.lastMetricPoint.correct;
    }
    if (
      (!this.metricPoint.trials &&
        (this.metricPoint.trials !== 0 || this.metricPoint.correct !== 0)) ||
      this.metricPoint.trials < 0
    ) {
      this.metricPoint.trials = this.lastMetricPoint.trials;
    }
    if (this.metricPoint.trials < this.metricPoint.correct) {
      this.metricPoint.trials = this.lastMetricPoint.trials;
      this.metricPoint.correct = this.lastMetricPoint.correct;
    }
    // Update for next time.
    this.lastMetricPoint.trials = this.metricPoint.trials;
    this.lastMetricPoint.correct = this.metricPoint.correct;
  }

  isEditable() {
    return (
      this.clientAppointment.record && !this.clientAppointment.record.signed
    );
  }
}
