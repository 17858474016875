<div class="drawer-team-write">
  <header class="drawer-header">
    <h3 class="drawer-header__title">Team Write</h3>
  </header>

  <pl-activity-switch-button
    [contentType]="'teamwrite'"
  ></pl-activity-switch-button>
  <div
    *ngIf="teamWriteReady && !loadingDocumentKey"
    class="create-new-btn-container"
  >
    <button (click)="new()" mat-raised-button color="primary" class="w-100">
      Create new document
    </button>
  </div>

  <div class="team-write-tab-container">
    <div *ngIf="!teamWriteReady || loadingDocumentKey" class="loader-container">
      <pl-loader></pl-loader>
    </div>

    <mat-tab-group
      mat-stretch-tabs
      *ngIf="teamWriteReady"
      class="is-compact h-100"
      color="accent"
    >
      <mat-tab label="Documents">
        <div class="team-write-tab fade-in">
          <div *ngIf="!currentDocument" class="secondary-text">
            <p>
              No document loaded. Create a new one or select one from below.
            </p>
          </div>
          <div
            class="document-item-container"
            *ngFor="let document of documents"
          >
            <pl-document-item
              *ngIf="!document.trashed"
              [document]="document"
              [allowDeleteCurrent]="true"
              (loadDocument)="loadDocument($event)"
              (trashDocument)="trashDocument($event)"
              (restoreDocument)="restoreDocument($event)"
              (deleteDocument)="deleteDocument($event)"
              (nameChanged)="nameChanged($event)"
              [itemDisabled]="loadingDocumentKey"
            ></pl-document-item>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="Trash">
        <div class="team-write-tab fade-in">
          <div class="secondary-text">
            <p>
              Deleted Team Write documents will be automatically removed after
              30 days.
            </p>
          </div>
          <div
            class="document-item-container"
            *ngFor="let document of documents"
          >
            <pl-document-item
              *ngIf="document.trashed"
              [document]="document"
              (loadDocument)="loadDocument($event)"
              (trashDocument)="trashDocument($event)"
              (restoreDocument)="restoreDocument($event)"
              (deleteDocument)="deleteDocument($event)"
              (nameChanged)="nameChanged($event)"
              [itemDisabled]="loadingDocumentKey"
            ></pl-document-item>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
