import { Injectable } from '@angular/core';
import { DeviceType } from './store';

const STORAGE_KEY = 'devices';

export enum PersistentDeviceSettings {
  isRotated = 'rotated',
  isMirrored = 'mirrored',
  filterMode = 'filterMode',
}
@Injectable({ providedIn: 'root' })
export class DevicesHistoryService {
  getVideoDeviceId(name: string) {
    return this.getDeviceId('video', name);
  }

  setVideoDeviceId(name: string, id: string) {
    this.setDeviceId('video', name, id);
  }

  removeVideoDeviceId(name: string) {
    this.removeDeviceId('video', name);
  }

  getAudioDeviceId(name: string) {
    return this.getDeviceId('audio', name);
  }

  setAudioDeviceId(name: string, id: string) {
    this.setDeviceId('audio', name, id);
  }

  removeAudioDeviceId(name: string) {
    this.removeDeviceId('audio', name);
  }

  setSetting(
    type: DeviceType,
    name: string,
    setting: PersistentDeviceSettings,
    value: any,
  ) {
    const key = this.getKey(type, name, setting);
    localStorage.setItem(key, value);
  }

  getSetting(
    type: DeviceType,
    name: string,
    setting: PersistentDeviceSettings,
  ) {
    const key = this.getKey(type, name, setting);
    return localStorage.getItem(key);
  }

  private getDeviceId(type: DeviceType, name: string) {
    const key = this.getKey(type, name);
    return localStorage.getItem(key);
  }

  private setDeviceId(type: DeviceType, name: string, id: string) {
    const key = this.getKey(type, name);
    localStorage.setItem(key, id);
  }

  private removeDeviceId(type: DeviceType, name: string) {
    const key = this.getKey(type, name);
    localStorage.removeItem(key);
  }

  private getKey(
    type: DeviceType,
    name: string,
    setting: PersistentDeviceSettings = null,
  ) {
    const settingSuffix = setting ? `.${setting}` : '';
    return `${STORAGE_KEY}.${type}.${name}${settingSuffix}`;
  }
}
