import { Injectable } from '@angular/core';
import { PLGuidService } from '@common/services/GuidService';
import { FlashCardsService } from '../flashcards/services/flashcards-new.service';
import { PLActivityModelService } from '../model/activity-model.service';

@Injectable()
export class PLActivityService {
  constructor(
    private activityModel: PLActivityModelService,
    private guidService: PLGuidService,
    private flashCards: FlashCardsService,
  ) {}

  startup(activity, token = null) {
    if (activity.id === this.activityModel.activity.activityId) {
      return this.activityModel.foundationLoaded;
    }

    this.reset();
    this.activityModel
      .setActivityId(activity.id)
      .setConfigId(activity.slug)
      .setActivityType(activity.activity_type)
      .setType(activity.type);

    if (activity.type === 'instant_youtube') {
      this.activityModel.activity.config = activity.config;
    }

    if (activity.session_id) {
      // enable firebase
      this.activityModel.setSessionId(activity.session_id).setShared(true);
    } else {
      // skip firebase
      this.activityModel
        .setSessionId(this.guidService.generateUUID())
        .setShared(false);
    }
    this.activityModel.initialize(
      activity.descriptor ? activity : undefined,
      token,
    );

    return this.activityModel.foundationLoaded;
  }

  reset() {
    this.flashCards.clean();
    this.activityModel.reset();
  }
}
