import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'pl-session-duration-select',
  templateUrl: 'session-duration-select.component.html',
  styleUrls: ['./session-duration-select.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class SessionDurationSelctComponent {
  @ViewChild('input') input: ElementRef;
  @Input() public model;
  @Input() public options;
  @Input() public disabled = false;
  @Output() public readonly modelChange = new EventEmitter<any>();
  @Output() public readonly changed = new EventEmitter<any>();

  focused = false;
  hasOptionsOpened = false;

  onChange() {
    this.modelChange.emit(this.model);
    this.changed.emit(this.model);
  }

  toggleOptionsOpen() {
    if (!this.disabled) {
      this.hasOptionsOpened = !this.hasOptionsOpened;
    }
  }

  onBlurDropdown(info: { blurred: boolean; evt: any }) {
    if (
      info.blurred &&
      this.focused &&
      !this.input.nativeElement.contains(info.evt.target)
    ) {
      this.unFocus(info.evt);
    }
  }

  unFocus(evt: any) {
    const isKeyTab = evt.code === 'Tab';
    const nativeHasEvtTarget = this.input.nativeElement.contains(evt.target);
    const nativeHasActiveElement = this.input.nativeElement.contains(
      document.activeElement,
    );

    if (isKeyTab && !nativeHasActiveElement) {
      this.focused = false;
      this.hasOptionsOpened = false;
    } else if (!nativeHasEvtTarget && !nativeHasActiveElement) {
      this.focused = false;
      this.hasOptionsOpened = false;
    }
  }

  onFocusInput() {
    this.focused = true;
  }

  onBlurInput(info: { blurred: boolean; evt: any }) {
    this.unFocus(info.evt);
  }

  selectOption(option: any) {
    this.model = option.value;
    this.hasOptionsOpened = false;
    this.onChange();
  }
}
