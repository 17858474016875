import { Component, OnInit } from '@angular/core';
import { TokboxRecordService } from '../tokbox-record.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'pl-recording-status',
  templateUrl: './recording-status.component.html',
  styleUrls: ['./recording-status.component.less'],
})
export class RecordingStatusComponent implements OnInit {
  isRecording$: BehaviorSubject<boolean>;
  constructor(private tokboxRecordService: TokboxRecordService) {}

  ngOnInit(): void {
    this.isRecording$ = this.tokboxRecordService.isRecording$;
  }

  stopRecording() {
    this.tokboxRecordService.stopCurrentRecording();
  }
}
