<div
  class="pdf-viewer"
  #viewer
  data-testid="pdf-viewer"
  [attr.data-teststate]="rendering || loading ? 'rendering' : 'rendered'"
  *ngIf="{ isHost: isHost$ | async } as isHostData"
>
  <div
    [style.width.px]="holderWidth ? holderWidth : null"
    [style.height.px]="holderHeight ? holderHeight : null"
    [style.overflow-y]="scale === 1 || !isHostData.isHost ? 'hidden' : 'auto'"
    [style.overflow-x]="!isHostData.isHost ? 'hidden' : 'auto'"
    class="pdf-holder"
    (window:resize)="resizeHandler()"
    (scroll)="scrollPage($event, isHostData.isHost)"
    (mousemove)="onMouseMove($event, isHostData.isHost)"
    (mouseup)="onMouseUp($event, isHostData.isHost)"
    (mousedown)="onMouseDown($event, isHostData.isHost)"
    #pdfHolder
  >
    <div *ngIf="rendering" class="rendering">
      <pl-loader></pl-loader>
    </div>
    <div
      class="pageHolder"
      [style.top.px]="pageHolderTop"
      [style.width.px]="pageHolderWidth || null"
    >
      <canvas class="pdf-holder-canvas" #pdfHolderCanvas></canvas>
      <canvas
        [hidden]="bookMode <= 1 || soloPage"
        class="page2-canvas"
        #page2Canvas
      ></canvas>
    </div>
  </div>
</div>
