import { Injectable } from '@angular/core';
import { PLGuidService } from '@common/services/GuidService';
import { PLLessonModelService } from '../model/lesson-model.service';

@Injectable()
export class PLLessonService {
  constructor(
    private lessonModel: PLLessonModelService,
    private guidService: PLGuidService,
  ) {}
  startup(activity, token = null) {
    if (activity.id === this.lessonModel.activity.activityId) {
      return this.lessonModel.foundationLoaded;
    }
    this.reset();
    this.lessonModel.setActivityId(activity.id);
    this.lessonModel.setConfigId(activity.slug);
    this.lessonModel.setType('lesson');
    const shareId = activity.session_id;
    if (shareId) {
      // enable firebase
      this.lessonModel.setSessionId(shareId);
      this.lessonModel.setShared(true);
    } else {
      // skip firebase
      this.lessonModel.setSessionId(this.guidService.generateUUID());
      this.lessonModel.setShared(false);
    }
    this.lessonModel.initialize({}, token);
    return this.lessonModel.foundationLoaded;
  }

  reset() {
    this.lessonModel.reset();
  }
}
