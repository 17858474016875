import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { FirebaseService } from '@common/firebase/firebase.service';
import {
  FirebaseCollection,
  Add,
  Update,
} from '@common/firebase/firebase-collection';
import { User } from '@modules/user/user.model';
import {
  Participant,
  ParticipantStatus,
  ParticipantType,
  RTParticipant,
} from './store/session.model';

@Injectable({ providedIn: 'root' })
export class SessionService {
  private sessionRef: FirebaseCollection<RTParticipant>;

  constructor(firebaseService: FirebaseService) {
    this.sessionRef = new FirebaseCollection(
      firebaseService.getLazyRoomRef('session'),
    );
  }

  onParticipantAdded() {
    return this.sessionRef.onAdded();
  }

  onParticipantChanged() {
    return this.sessionRef.onChanged();
  }

  onParticipantRemoved() {
    return this.sessionRef.onRemoved();
  }

  hasParticipant(id: string) {
    return this.sessionRef.hasChild(id);
  }

  participant(id: string) {
    return this.sessionRef.child(id);
  }

  getParticipantsByUserId(userId: string) {
    return this.sessionRef.getAll().pipe(
      map(participants => participants.filter(p => p.userId === userId)),
      catchError(() => of([] as RTParticipant[])),
    );
  }

  addParticipant({ isLocal, ...participant }: Add<Participant>) {
    return this.sessionRef.add(participant);
  }

  updateParticipant(id: string, { isLocal, ...data }: Update<Participant>) {
    return this.sessionRef.update(id, data);
  }

  removeParticipant(id: string) {
    return this.sessionRef.remove(id);
  }

  removeMultipleParticipants(ids: string[]) {
    return this.sessionRef.bulkRemove(ids);
  }

  createDisconnectedRef(id: string) {
    const childRef = this.sessionRef.getChildRef(id);
    return childRef.createOnDisconnect();
  }

  getUserParticipantType(user: User) {
    const isStudent = user.groups.includes('student');
    const isObserver = user.isObserver;

    if (isObserver) {
      return ParticipantType.observer;
    }

    if (!isStudent) {
      return ParticipantType.host;
    }

    return ParticipantType.guest;
  }

  validateParticipant(participant: RTParticipant) {
    const requiredFields: (keyof Participant)[] = [
      'id',
      'userId',
      'displayName',
      'type',
      'status',
    ];
    const participantFields = Object.keys(participant);

    const hasRequiredFields = requiredFields.every(rf =>
      participantFields.includes(rf),
    );
    if (!hasRequiredFields) {
      return false;
    }

    const validTypeChoices = Object.keys(ParticipantType);
    if (!validTypeChoices.includes(participant.type)) {
      return false;
    }

    const validStatusChoices = Object.keys(ParticipantStatus);
    if (!validStatusChoices.includes(participant.status)) {
      return false;
    }

    return true;
  }
}
