import { Component, Input } from '@angular/core';
import { Player } from '../../clue-store';

@Component({
  selector: 'confidential-folder-screen',
  templateUrl: './confidential-folder-screen.component.html',
  styleUrls: ['./confidential-folder-screen.component.less'],
})

// LUXURY GOAL: The goal for this component in the future is to remove the current folder img and instead replace it with a folder background and our own card elements
// these cards will have a sliding animation going into the black question mark confidential card slot

// EXTRA REFACTOR GOAL: after that luxury goal is complete we can consider making this a reusable component since the same design is used for the winning screen.
export class PLClueConfidentialFolderScreenComponent {
  @Input() currentUser: Player;
  @Input() provider: string;
  @Input() firebaseClueRef;

  public startGame() {
    this.firebaseClueRef.update({ gameActive: true });
    this.firebaseClueRef.update({ folderScreenActive: false });
  }
}
