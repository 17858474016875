import { Injectable } from '@angular/core';
import { filter, map, tap } from 'rxjs/operators';
import { ConferenceService } from '../conference';
import { KnockData } from './store';

const HOST_CONNECTED_CMD = 'clinicanEntered';
const ADMIT_CMD = 'admit';
const DENY_CMD = 'dismiss';
const KNOCK_CMD = 'login';
const CANCEL_KNOCK_CMD = 'cancel-login';

@Injectable({ providedIn: 'root' })
export class WaitingRoomConferenceService extends ConferenceService {
  private guestConnections = new Map<string, string>();
  onGuestKnocked() {
    return this.onCommand(KNOCK_CMD).pipe(
      map(ev => {
        let data: any = ev.data;
        if (typeof ev.data === 'string') {
          data = JSON.parse(ev.data);
        }
        const { name, browserId, isIpad } = data;

        this.guestConnections.set(browserId, ev.from);

        return {
          name,
          id: browserId,
          isIPad: isIpad,
        } as KnockData;
      }),
    );
  }

  onGuestKnockCanceled() {
    return this.onCommand(CANCEL_KNOCK_CMD).pipe(
      map(ev => {
        let data: any = ev.data;
        if (typeof ev.data === 'string') {
          data = JSON.parse(ev.data);
        }

        this.clearGuestConnection(ev.from);

        return data.browserId as KnockData['id'];
      }),
    );
  }

  onGuestKnockingDisconnected() {
    return this.onRemoteDisconnected().pipe(
      map(({ id: connectionId }) => this.clearGuestConnection(connectionId)),
      filter(id => !!id),
    );
  }

  onGuestAdmitted() {
    return this.onCommand(ADMIT_CMD).pipe(map(ev => ev.data));
  }

  onGuestAdmissionDenied() {
    return this.onCommand(DENY_CMD);
  }

  onHostConnected() {
    return this.onCommand(HOST_CONNECTED_CMD);
  }

  admit(id: string, token: string) {
    const connectionId = this.guestConnections.get(id);
    return this.commandTo(connectionId, ADMIT_CMD, token).pipe(
      tap(() => {
        this.clearGuestConnection(connectionId);
      }),
    );
  }

  deny(id: string) {
    const connectionId = this.guestConnections.get(id);
    return this.commandTo(connectionId, DENY_CMD).pipe(
      tap(() => {
        this.clearGuestConnection(connectionId);
      }),
    );
  }

  knock({ name, id, isIPad }: KnockData) {
    return this.command(
      KNOCK_CMD,
      JSON.stringify({
        name,
        browserId: id,
        isIpad: isIPad,
      }),
    );
  }

  cancelKnock(id: string) {
    return this.command(CANCEL_KNOCK_CMD, JSON.stringify({ browserId: id }));
  }

  notifyHostConnected(connectionId?: string) {
    if (connectionId) {
      return this.commandTo(connectionId, HOST_CONNECTED_CMD);
    }
    return this.command(HOST_CONNECTED_CMD);
  }

  private clearGuestConnection(connectionId: string) {
    const [guestId] =
      Array.from(this.guestConnections.entries()).find(
        ([, connId]) => connId === connectionId,
      ) ?? [];

    if (guestId) {
      this.guestConnections.delete(guestId);
    }

    return guestId;
  }
}
