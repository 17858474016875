<div class="pl-game-drawer">
  <pl-clue-drawer
    *ngIf="activity.type === 'game-clue'"
    [activity]="activity"
  ></pl-clue-drawer>
  <pl-candyland-drawer
    *ngIf="activity.type === 'game-candyland'"
    [activity]="activity"
  >
  </pl-candyland-drawer>
  <pl-guess-who-drawer
    *ngIf="activity.type === 'whos-that'"
    [activity]="activity"
  ></pl-guess-who-drawer>
  <pl-go-fish-drawer
    *ngIf="activity.type === 'game-go-fish'"
    [activity]="activity"
  ></pl-go-fish-drawer>
  <pl-scenes-drawer
    *ngIf="activity.type === 'game-scenes'"
    [activity]="activity"
  ></pl-scenes-drawer>
  <pl-scenes-drawer
    *ngIf="activity.type === 'game-potato-head'"
    [activity]="activity"
  ></pl-scenes-drawer>
  <pl-vendor-game-drawer
    *ngIf="isVendorGame(activity)"
    [activity]="activity"
  ></pl-vendor-game-drawer>
</div>
