import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';

import { AppState } from '@app/store';
import { Participant, selectParticipant, SessionActions } from '@room/session/store';
import { ConferenceRTService } from '../../conference-rt.service';
import { RTStream, StreamType } from '../conference.model';
import { ConferenceActions } from '../conference.actions';

@Injectable({ providedIn: 'root' })
export class ConferenceAddGuestPrimaryEffects {
  guestAdmitted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SessionActions.admitSuccess),
      concatLatestFrom(({ id }) => this.store.select(selectParticipant(id))),
      map(([, participant]) => this.createStream(participant)),
      map(stream => ConferenceActions.addGuestPrimary({ stream })),
    );
  });

  addPrimaryGuestStream$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConferenceActions.addGuestPrimary),
      mergeMap(({ stream }) => {
        return this.conferenceRTService.addStream(stream).pipe(
          map(() => ConferenceActions.addGuestPrimarySuccess({ stream })),
          catchError((error) => {
            const errorObj = {
              stream,
              error,
            };
            console.error('ADD_GUEST_PRIMARY', errorObj);
            return of(ConferenceActions.addGuestPrimaryError(errorObj));
          }),
        );
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private conferenceRTService: ConferenceRTService,
  ) { }

  private createStream(participant: Participant) {
    const type = StreamType.primary;
    const newStream: RTStream = {
      type,
      id: `${participant.id}-${type}`,
      participantId: participant.id,
      displayName: participant.displayName,
      video: {
        isHidden: true,
        effects: {
          isCovered: true,
        },
      },
      microphone: {
        isMuted: true,
      },
    };
    return newStream;
  }
}
