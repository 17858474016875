import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnDestroy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'pl-document-item',
  templateUrl: './pl-document-item.component.html',
  styleUrls: ['./pl-document-item.component.less'],
})
export class PLDocumentItemComponent implements OnInit, OnChanges, OnDestroy {
  @Input() document: any = {};
  @Input() itemDisabled: any = {};
  @Input() allowDeleteCurrent = false;
  @Output() readonly loadDocument = new EventEmitter<any>();
  @Output() readonly deleteDocument = new EventEmitter<string>();
  @Output() readonly trashDocument = new EventEmitter<string>();
  @Output() readonly restoreDocument = new EventEmitter<string>();
  @Output() readonly nameChanged = new EventEmitter<string>();
  @ViewChild('docNameEditor', { static: false }) docNameEditor: ElementRef;

  dateInterval: any = null;
  isEditingName = false;

  ngOnInit() {
    this.updateDate();
    const updateDateSeconds = this.document.current ? 5 : 60;
    this.dateInterval = setInterval(() => {
      this.updateDate();
    }, updateDateSeconds * 1000);
  }

  ngOnDestroy() {
    if (this.dateInterval) {
      window.clearInterval(this.dateInterval);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isEditingName?.currentValue) {
      setTimeout(() => {
        this.docNameEditor.nativeElement.focus();
      });
    }
  }

  onLoadDocument() {
    if (this.itemDisabled || this.document.trashed || this.document.current) {
      return;
    }
    this.loadDocument.emit({
      key: this.document.key,
      name: this.document.name,
    });
  }

  onEnter(event) {
    this.document.name = event.target.value;
    this.isEditingName = false;
    this.nameChanged.emit(this.document);
  }

  onDeleteDocument() {
    setTimeout(() => {
      this.deleteDocument.emit(this.document.key);
    });
  }

  onTrashDocument() {
    this.trashDocument.emit(this.document.key);
  }

  onRestoreDocument() {
    this.restoreDocument.emit(this.document.key);
  }

  onToggleRename(value: boolean) {
    this.isEditingName = value;

    if (value) {
      setTimeout(() => {
        this.docNameEditor.nativeElement.focus();
      });
    }
  }

  updateDate() {
    if (this.document && this.document.modified) {
      this.document.date = moment(this.document.modified).fromNow();
    }
  }
}
