import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';

import { Notifications } from '@app/common/services/notifications.service';
import { FirebaseService } from '@app/common/firebase/firebase.service';
import { PLHttpService } from '@common/services/pl-http';
import { environment } from '@root/src/environments/environment';
import { map } from 'rxjs/operators';

export enum SiteShareMode {
  DEFAULT = 'default',
  BOOMCARDS = 'boomcards',
}

const DEFAULT_BROWSER_URL =
  'https://www.presencelearning.com/room-suggested-resources';

const BOOMCARDS_BROWSER_URL = 'https://wow.boomlearning.com/deckLibrary';

const MODE_URLS = {
  [SiteShareMode.DEFAULT]: DEFAULT_BROWSER_URL,
  [SiteShareMode.BOOMCARDS]: BOOMCARDS_BROWSER_URL,
};

interface InstanceData {
  urn: string;
  url: string;
  roles: {
    guest: string;
    owner: string;
  };
}

interface SiteshareData {
  instanceData: BehaviorSubject<InstanceData | null>;
  isActive: BehaviorSubject<boolean | null>;
  mode: BehaviorSubject<SiteShareMode | null>;
}

@Injectable()
export class SiteshareService {
  private ref: firebase.database.Reference;

  private data: SiteshareData = {
    isActive: new BehaviorSubject(false),
    instanceData: new BehaviorSubject(null),
    mode: new BehaviorSubject(SiteShareMode.DEFAULT),
  };

  private remoteHQCreateUrl = `${environment.apps.platform.url}/api/v1/remotehq/`;

  public isHost = false;
  public isLoading$ = new BehaviorSubject(false);
  public isActive$ = this.data.isActive;
  public instanceData$ = this.data.instanceData;
  public mode$ = this.data.mode;
  isSiteShareActive$: any;
  isBoomCardsSiteShareActive$: any;

  constructor(
    private firebaseService: FirebaseService,
    private plHttp: PLHttpService,
    private notifications: Notifications,
  ) {
    this.ref = this.firebaseService.getRoomRef('app/siteshare');

    this.ref.on('value', this.onValue.bind(this));

    this.isSiteShareActive$ = combineLatest([this.isActive$, this.mode$]).pipe(
      map(([active, mode]) => {
        return active && mode === SiteShareMode.DEFAULT;
      }),
    );
    this.isBoomCardsSiteShareActive$ = combineLatest([
      this.isActive$,
      this.mode$,
    ]).pipe(
      map(([active, mode]) => {
        return active && mode === SiteShareMode.BOOMCARDS;
      }),
    );
  }

  public toggleSiteshare(value: boolean, mode: SiteShareMode) {
    if ((!value && this.isActive()) || mode !== this.mode$.getValue()) {
      this.setInstanceData(null);
      this.setIsActive(false);
    }
    if (value) {
      const initialUrl = MODE_URLS[mode];
      this.isLoading$.next(true);
      this.plHttp
        .save(
          null,
          {
            initial_url: initialUrl,
          },
          this.remoteHQCreateUrl,
        )
        .subscribe(response => {
          this.isLoading$.next(false);
          this.isHost = true;
          this.setInstanceData({
            urn: response.instanceURN,
            url: response.embedURL,
            // We're passing default roles as fallback for backwards compatibility
            // it can simplified once API returns roles object in the response
            roles: response.roles || {
              owner: 'owner',
              guest: 'guest',
            },
          });
          this.setIsActive(true);
          this.setMode(mode);
        });
    }
  }

  public isActive() {
    return this.isActive$.getValue();
  }

  public stop() {
    this.setInstanceData(null);
    this.setIsActive(false);
  }

  private async setInstanceData(instanceData: InstanceData) {
    try {
      await this.ref.update({
        instanceData,
      });
    } catch (err) {
      this.notifications.show({
        text: 'Something went wrong... Please try again.',
        type: 'error',
      });
    }
  }

  private async setMode(mode: SiteShareMode) {
    try {
      await this.ref.update({
        mode,
      });
    } catch (err) {
      this.notifications.show({
        text: 'Something went wrong... Please try again.',
        type: 'error',
      });
    }
  }

  private async setIsActive(isActive: boolean) {
    try {
      await this.ref.update({
        isActive,
      });
    } catch (err) {
      this.notifications.show({
        text: 'Something went wrong... Please try again.',
        type: 'error',
      });
    }
  }

  private onValue(snap: firebase.database.DataSnapshot) {
    snap.forEach(s => {
      if (this.data[s.key]) {
        this.data[s.key].next(s.val());
      }
    });
  }
}
