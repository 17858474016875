<div class="overview-instructions">
  <img
    *ngIf="showClueOverview && !showClueInstruction"
    class="overview-instructions__img"
    src="/assets/board-games/clue_game_assets/clue_instructions.png"
  />

  <div
    class="overview-instructions__content mat-typography"
    *ngIf="showClueInstruction"
  >
    <h2>Gameplay:</h2>
    <h3>Asking for Evidence:</h3>

    <ol>
      <li>On your turn <strong>Select</strong> 'Ask for Evidence'</li>
      <li>
        You are the investigator:
        <strong>Ask the next player</strong> in line about any two pieces of
        evidence listed on the checklist. Example: "Player 2, do you have the
        cafeteria or the smart phone?"
      </li>
      <li>
        The "Witness"
        <strong>responds verbally: YES or NO,</strong> if the witness has either
        of the cards they will select <strong>ONE</strong> of their evidence
        cards to reveal to the investigator and the round is over. If the
        "witness" doesn't have either of the cards, they will say no and select
        "No matching evidence" on the screen. The investigator then asks the
        same question to the next player until someone reveals an evidence card
        or the investigator has asked all other players.
      </li>
      <li>
        The investigator's <strong>turn ends</strong> when a player has revealed
        a card OR they have asked all the players in the game. <br /><br />
        *In a 2 person game a ghost player will reveal a card to the
        investigator if the other player selects “No matching evidence.”
      </li>
    </ol>

    <h3>Tracking responses:</h3>

    <ol>
      <li>
        Players can use the checklist on the right to track other players’
        responses and narrow down the contents of the confidential folder.
      </li>
    </ol>

    <h3>Solving the case</h3>

    <ol>
      <li>On your turn Select 'Solve the Case'</li>
      <li>Select one piece of evidence from each category</li>
      <li>Submit your guess</li>
      <li>
        Any player can attempt to solve when the active player has opted to
        solve the case
      </li>
      <li>
        Any player who guesses wrong is eliminated from being able to solve
        again, but is still part of answering questions for other players
      </li>
    </ol>

    <h3>Winning the game</h3>

    <ol>
      <li>Any player who submits the correct guess wins</li>
      <li>
        A player who opted out of solving the crime, when the other players
        guess incorrectly, wins the game
      </li>
    </ol>
  </div>
</div>
