import { Component, Input } from '@angular/core';
import { DocumentationItemState } from '../../documentation.models';
import { StudentItem } from '../kanga-documentation.model';

@Component({
  selector: 'pl-kanga-documentation-appointment-item',
  templateUrl: 'kanga-documentation-appointment-item.component.html',
  styleUrls: ['kanga-documentation-appointment-item.component.less'],
})
export class KangaDocumentationAppointmentItemComponent {
  @Input() studentItem: StudentItem;

  @Input() public itemState: DocumentationItemState;

  ngOnInit() {
    if (!this.itemState) {
      this.itemState = {
        // selectedNotesTab: DocumentationNoteType.notes,
        selectedTabIndex: 0,
      };
      // this.itemStateChange.emit(this.itemState);
    }
  }

  avatarClicked() {
    console.log('avatarClicked', this.studentItem);
    // this.selectStudent.emit(this.student);
  }
  onTabChanged() {
    console.log('onTabChanged', this.itemState.selectedTabIndex);
    // this.itemStateChange.emit(this.itemState);
  }
}
