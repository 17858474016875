<pl-input-multi-select
  *ngIf="objectiveOptions?.length"
  [options]="objectiveOptions"
  [(model)]="selectedObjectives"
></pl-input-multi-select>
<div class="metrics-container">
  <pl-documentation-te-metric
    *ngFor="let id of selectedObjectives"
    [disabled]="loadingDict[id] && !objectivesDict[id].values?.uuid"
    [goal]="goalsDict[objectivesDict[id].goal]"
    [metric]="objectivesDict[id]"
    (changed)="onMetricChanged(objectivesDict[id])"
  >
  </pl-documentation-te-metric>
</div>
