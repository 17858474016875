import {  createReducer, on } from '@ngrx/store';
import { ClueState, initialState } from './state';
import * as ClueActions from './actions';

function updatePlayersReducer(state: ClueState, action) {
    return {
        ...state,
        playersArr: action.playersArr
    };
}

function goToNextPlayer(state: ClueState) {
    let { playerTurn, playersArr } = state;
    let nextPlayer = playerTurn === playersArr.length ? 1 : playerTurn+=1;
    return {
        ...state,
        playerTurn: nextPlayer
    };
}

function updateStartGameBtn(state: ClueState, props) {
    return {
        ...state,
        enableStartGameBtn: props.enableStartGameBtn
    }
}

function updateEndGameBtn(state: ClueState, props) {
    return {
        ...state,
        enableEndGameBtn: props.enableEndGameBtn
    }
}

function toggleShowInstruction(state: ClueState) {
    return {
        ...state,
        showInstruction: !state.showInstruction
    }
}

function toggleShowOverview(state: ClueState) {
    return {
        ...state,
        showOverview: !state.showOverview
    }
}

function closeShowInstruction(state: ClueState) {
    return {
        ...state,
        showInstruction: false
    }
}

function closeShowOverview(state: ClueState) {
    return {
        ...state,
        showOverview: false
    }
}

function updateCurrentUser(state: ClueState, props) {
    return {
        ...state,
        currentUser: {...props.currentUser}
    }
}

function updateCardReveal(state: ClueState, props) {
    return {
        ...state,
        cardReveal: {...props.cardReveal}
    }
}

export const reducer = createReducer(
    initialState,
    on(ClueActions.updatePlayers, updatePlayersReducer),
    on(ClueActions.goToNextPlayer, goToNextPlayer),
    on(ClueActions.updateStartGameBtn, updateStartGameBtn),
    on(ClueActions.updateEndGameBtn, updateEndGameBtn),
    on(ClueActions.toggleShowInstruction, toggleShowInstruction),
    on(ClueActions.toggleShowOverview, toggleShowOverview),
    on(ClueActions.updateCurrentUser, updateCurrentUser),
    on(ClueActions.updateCardReveal, updateCardReveal),
    on(ClueActions.closeShowInstruction, closeShowInstruction),
    on(ClueActions.closeShowOverview, closeShowOverview)
);
