<div class="checkers-drawer" data-testid="game-checkers-drawer">
  <mat-tab-group
    class="checkers-tabs is-compact"
    mat-stretch-tabs
    color="accent"
    [selectedIndex]="currentTabIndex$ | async"
    (selectedIndexChange)="onTabChange($event)"
  >
    <mat-tab label="Players">
      <ng-container *ngTemplateOutlet="players"></ng-container>
    </mat-tab>

    <mat-tab label="Options">
      <ng-container *ngTemplateOutlet="options"></ng-container>
    </mat-tab>
  </mat-tab-group>

  <game-start-end-btns
    [canStartGame]="canStartNewGame()"
    [isGameActive]="canEndGame()"
    (clickNewGame)="onClickStartNewGame()"
    (clickEndGame)="onClickEndGame()"
  ></game-start-end-btns>
</div>

<ng-template #players>
  <div class="players-container">
    <p>Bottom player always goes first.</p>

    <mat-form-field color="accent" class="w-100">
      <mat-label>Select top player</mat-label>
      <mat-select
        data-testid="top-player-select"
        name="top-player"
        [(ngModel)]="formData.model.playerTop"
        (selectionChange)="onChangePlayer('Top')"
        [disabled]="canEndGame()"
      >
        <mat-option *ngFor="let opt of formData.playerOpts" [value]="opt.value">
          {{ opt.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field color="accent" class="w-100">
      <mat-label>Select bottom player</mat-label>
      <mat-select
        data-testid="bottom-player-select"
        name="bottom-player"
        [(ngModel)]="formData.model.playerBottom"
        (selectionChange)="onChangePlayer('Bottom')"
        [disabled]="canEndGame()"
      >
        <mat-option *ngFor="let opt of formData.playerOpts" [value]="opt.value">
          {{ opt.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #options>
  <div class="options-group">
    <div class="mat-slide-toggle-group mb-3" data-testid="game-sounds-group">
      <label id="game-sounds-toggle"> Game sounds </label>
      <mat-slide-toggle
        data-testid="game-sounds-toggle"
        [ngModel]="!formData.model.mute"
        (ngModelChange)="onToggleSound($event)"
        aria-labelledby="game-sounds-toggle"
      >
        {{ formData.model.mute ? 'Off' : 'On' }}
      </mat-slide-toggle>
    </div>

    <div class="mat-slide-toggle-group" data-testid="move-hints-group">
      <label id="move-hints-toggle"> Move hints </label>
      <mat-slide-toggle
        data-testid="move-hints-toggle"
        [(ngModel)]="formData.model.showMoves"
        [disabled]="canEndGame()"
        aria-labelledby="move-hints-toggle"
      >
        {{ formData.model.showMoves ? 'On' : 'Off' }}
      </mat-slide-toggle>
    </div>
  </div>

  <div class="options-group">
    <label id="choose-board-theme-label">Board theme</label>

    <mat-radio-group
      aria-labelledby="choose-board-theme-label"
      class="radio-buttons"
      color="primary"
      data-testid="board-theme-radio-group"
      [(ngModel)]="formData.model.boardTheme"
    >
      <mat-radio-button
        *ngFor="let option of formData.boardThemeOpts"
        class="radio-bg-button"
        data-testid="board-theme-radio-button"
        [value]="option.value"
        [checked]="option.value === formData.model.boardTheme"
        [disabled]="canEndGame() && option.value !== formData.model.boardTheme"
      >
        <span class="radio-bg-label">
          <img
            class="radio-bg-preview"
            [src]="option.thumbnailUrl"
            *ngIf="option.thumbnailUrl"
          />
          {{ option.label }}
        </span>
      </mat-radio-button>
    </mat-radio-group>

    <label id="choose-board-theme-label">Board size</label>

    <mat-radio-group
      aria-labelledby="choose-board-size-label"
      color="primary"
      class="radio-buttons"
      data-testid="board-size-radio-group"
      [(ngModel)]="formData.model.boardSize"
    >
      <mat-radio-button
        *ngFor="let option of formData.boardSizeOpts"
        data-testid="board-size-radio-button"
        [value]="option.value"
        [checked]="option.value === formData.model.boardSize"
        [disabled]="canEndGame() && option.value !== formData.model.boardSize"
      >
        {{ option.label }}
      </mat-radio-button>
    </mat-radio-group>
  </div>
</ng-template>
