import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { CurrentUserModel } from '@common/models/CurrentUserModel';
import { LocalStorageService } from '@common/services/LocalStorageService';
import { Store } from '@ngrx/store';
import { PLActivityModelService } from '@root/src/app/modules/room/pl-activity/model/activity-model.service';
import { AppState } from '@root/src/app/store';
import { Observable, Subscription } from 'rxjs';
import {
  Activity,
  CentralContentType,
  selectIsCurrentCentralContent,
} from '../../../app/store';
import { MemoryCardsService } from '../services/cards.service';
import { PLMemoryBaseService } from '../services/memory-base.service';
import { PlayerCountOption } from '@common/components/player-count-select/player-count-select.component';
import { CardDisplayOption } from '@common/components/card-display-options/card-display-options.component';

@Component({
  selector: 'pl-memory-activity-drawer',
  templateUrl: 'memory-activity-drawer.component.html',
  styleUrls: ['./memory-activity-drawer.component.less'],
  providers: [MemoryCardsService],
  encapsulation: ViewEncapsulation.None, // TODO: Enable encapsulation when redesigning this drawer
})
export class PLMemoryActivityDrawerComponent
  extends PLMemoryBaseService
  implements OnInit, OnDestroy
{
  @Input() public activity: Activity;
  isInitialized = true;
  droppedCard: any;
  activityCards: any;
  xRay = false;
  inDrag: any;
  cardsToMove: any;
  availableCards: any;
  maxFlips = 0;
  forceUpdate = false;
  isActivityActive$: Observable<boolean>;
  private subscriptions: Subscription[] = [];

  playerCountOptions: PlayerCountOption[] = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
  ];
  numPlayers = 1;
  playerNameInputs: { value: string }[] = [{ value: '' }];
  isGameActive = false;
  totalMatches = 10;

  flipOptions: { value: number; label: string }[] = [
    { value: 2, label: '2 flips per turn' },
    { value: 3, label: '3 flips per turn' },
    { value: 4, label: '4 flips per turn' },
    { value: 0, label: 'Unlimited flips per turn' },
  ];

  gameStarted = false;
  cardType: CardDisplayOption;

  /**
   * @constructor
   * @param {MemoryCardsService} cardsService
   * @param {ActivityModel} activityModel
   * @param {LocalStorageService} localStorage
   * @param {Object} currentUserModel
   */
  constructor(
    private cards: MemoryCardsService,
    activityModel: PLActivityModelService,
    localStorage: LocalStorageService,
    currentUserModel: CurrentUserModel,
    private store: Store<AppState>,
  ) {
    super(activityModel, localStorage, currentUserModel);
    cards.on('dataUpdate', this.onDataUpdate);

    try {
      this.setXRay(localStorage.get(this.KEY_XRAY) === 'true');
    } catch (e) {
      console.error('localStorage error in MemoryDrawerController');
    }
    this.maxFlips = 0;

    this.forceUpdate = false;
  }

  ngOnInit() {
    this.isActivityActive$ = this.store.select(
      selectIsCurrentCentralContent(
        CentralContentType.QueueItem,
        this.activity,
      ),
    );
    this.cards.currentActivty = this.activity;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
  public get CardDisplayOption() {
    return CardDisplayOption;
  }

  onChangeNumPlayers(val) {
    this.numPlayers = val;
    this.updatePlayerNameInputs();
  }

  updatePlayerNameInputs() {
    if (this.playerNameInputs.length < this.numPlayers) {
      for (let i = this.playerNameInputs.length; i < this.numPlayers; i++) {
        this.playerNameInputs.push({ value: '' });
      }
    } else if (this.playerNameInputs.length > this.numPlayers) {
      this.playerNameInputs = this.playerNameInputs.slice(0, this.numPlayers);
    }
  }
  onClickStartNewGame() {
    this.isGameActive = true;
    let names = this.playerNameInputs.map(p => p.value);
    this.cards.setPlayerNames(names);
    this.cards.setIsGameActive(true);
  }
  onClickEndGame() {
    this.isGameActive = false;
    this.cards.setIsGameActive(false);
    this.handleShuffleDeck();
    this.handleRefreshDeck();
  }

  // NOT YET USED
  updateTotalMatches(val) {
    this.totalMatches = val;
  }

  get players() {
    return this.cards.players;
  }

  selectMaxFlips(num) {
    this.maxFlips = num;
    this.cards.maxFlipsPerTurn = num;
    this.cards.save();
  }

  onDataUpdate = (oldState, newState) => {
    if (!this.cards.isUserInput && this.isClinician()) {
      this.setXRay(false);
      this.cards.initialize(this.getDrawerCards());
    }
    this.maxFlips = this.cards.maxFlipsPerTurn;
    this.forceUpdate = !this.forceUpdate;

    this.isGameActive = this.cards.isGameActive;
    if (this.isGameActive) {
      const players = this.cards.players;
      this.numPlayers = players.length;
      this.updatePlayerNameInputs();
      players.forEach((p, i) => {
        this.playerNameInputs[i].value = p.name;
      });
    }
    this.cardType = this.cards.cardType;

    this.checkForLegacyDisplayType();

    this.isInitialized = true;
  };

  /**
   * It is possible for a Memory activity in a queue to have a display type
   * that is no longer compatible with the current paradigm. This method
   * checks for that and migrates the display type if necessary.
   */
  private checkForLegacyDisplayType() {
    const cardDisplayOptionValues = Object.values(CardDisplayOption);
    if (!cardDisplayOptionValues.includes(this.cardType)) {
      console.log('[Memory] migrating old card display type: ', this.cardType);
      this.cardType = CardDisplayOption.ImageAndText;
      this.setCardType(this.cardType);
    }
  }

  /**
   * Initializes cross-frame communication channel event
   * listeners
   */
  initChannel() {
    return this.getChannel().then((channel: any) => {
      channel.bind('cardDropped', (e, card) => {
        this.droppedCard = card;
        // delay the unsetting of the temp xray so the user can see the card land
        setTimeout(() => {
          this.setTempXRay(false);
        }, 500);
      });
    });
  }

  /**
   * Returns cards from activity model, pre-defined for this game
   *
   * @returns {Array}
   */
  getDrawerCards = () => {
    if (!this.activityCards) {
      const cards = this.activityModel.activity.config
        ? this.activityModel.activity.config.cards
        : [];

      this.activityCards = JSON.parse(JSON.stringify(cards || []));
    }
    return this.activityCards;
  };

  /**
   * Returns an array of cards which should be excluded from
   * activity cards
   *
   * @returns {Array}
   */
  getExcludeCards() {
    const cards = [].concat(this.cards.toArray());

    if (
      this.droppedCard &&
      !~cards.map(c => c.id).indexOf(this.droppedCard.id)
    ) {
      cards.push(this.droppedCard);
    }
    return cards;
  }

  /**
   * Remove player from a game by its index in the players collection
   * and saves new game state
   *
   * @param {number} index
   */
  removePlayer = index => {
    this.cards.removePlayer(index).save();
  };

  /**
   * Sets x-ray mode for a game and saves new game state
   *
   * @param {boolean} value
   */
  setXRay = valueArg => {
    const value = valueArg !== undefined ? valueArg : this.xRay;
    this.xRay = value;
    this.getChannel().then((channel: any) => {
      channel.call({
        method: 'xRayChanged',
        params: value,
      });
    });

    this.localStorage.set(this.KEY_XRAY, value);
  };

  /**
   * Sets temporary x-ray mode for use while dragging. Should not override
   * true x-ray selection and should not be locally stored
   *
   * @param {boolean} value
   */
  setTempXRay = value => {
    this.getChannel().then((channel: any) => {
      channel.call({
        method: 'tempXRayChanged',
        params: value,
      });
    });
  };

  /**
   * Sets display type for the cards and saves new game state
   *
   * @param {CardDisplayOption} cardType
   */
  setCardType(cardType: CardDisplayOption) {
    this.cards.cardType = cardType;
    this.cards.save();
  }

  /**
   * Cards shuffle event handler.
   * Actually shuffles cards ona deck and saves new game state.
   */
  handleShuffleDeck() {
    this.getChannel().then((channel: any) => {
      channel.call({
        method: 'animateCards',
      });
    });

    this.cards.shuffle().save();
  }

  /**
   * Resets cards on a deck to their initial state,
   * resets game scores, etc. and saves new game state.
   * let's say it fully restart the game.
   */
  handleRefreshDeck() {
    this.cards.reset();

    this.getChannel().then((channel: any) => {
      channel.call({
        method: 'animateCards',
      });
    });

    this.cards.save();
  }

  /**
   * Card drag start event handler
   *
   * @param {Event} e
   * @param {Object} card
   */
  handleDragCardStart = ({ event, data }) => {
    const json = JSON.stringify(data);

    this.inDrag = data.id;
    event.dataTransfer.setData('memorycard', json);
    this.setTempXRay(true);
  };

  /**
   * Card drag cancel event handler
   *
   * @param {Event} e
   * @param {Object} card
   */
  handleDragCardCancel = _ => {
    this.getChannel().then((channel: any) => {
      channel.call({
        method: 'dragCardCancel',
      });
    });
    this.droppedCard = null;
    this.inDrag = null;
    this.setTempXRay(false);
  };

  handleDragCardEnd = () => {
    this.setTempXRay(false);
  };

  get cardList() {
    const cardList = this.excludeFilter(
      this.getDrawerCards(),
      this.getExcludeCards(),
    );
    return cardList;
  }

  private excludeFilter(cards, excluded) {
    const args = Array.prototype.slice.call(arguments, 0);
    const one = args.shift();
    let filtered;
    const others = args.map(arg => (arg instanceof Array ? arg : [arg]));

    if (!one) {
      return [];
    }

    if (!others.length) {
      return one;
    }

    filtered = one;

    others.forEach(another => {
      const ids = another.map(item => item.id);
      filtered = filtered.filter(item => !~ids.indexOf(item.id));
    });

    return filtered;
  }
}
