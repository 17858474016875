<div class="candyland-board-container">
  <div class="instructions-modal" *ngIf="showCandyLandInstruction">
    <img
      class="text-center"
      src="/assets/board-games/candyland_game_assets/instructions.svg"
    />
  </div>

  <div *ngIf="!isGameActive" class="center-splash-container">
    <img
      class="text-center"
      src="/assets/board-games/candyland_game_assets/candyland_splash.jpg"
    />
  </div>

  <div *ngIf="isGameActive" class="candyland-game-board">
    <div class="foreground" *ngIf="!!pieces">
      <pl-board-game-piece
        *ngFor="let piece of pieces"
        [piece]="piece"
        (pieceDragged)="pieceDragEnded($event)"
      ></pl-board-game-piece>
    </div>

    <div class="castle-container">
      <img
        (click)="removeCastlePiece()"
        class="castle-img"
        src="/assets/board-games/candyland_game_assets/castle_assets/castle_click_{{
          currentCastle
        }}.png"
      />
    </div>

    <div class="card-deck-container">
      <div class="cursor-container" (click)="drawCard()"></div>
      <div
        class="card-container"
        [ngClass]="{ drawn: drawn, animating: animating }"
      >
        <div *ngIf="currentCard && !showCard" class="flashcard-back">
          <img
            src="/assets/board-games/candyland_game_assets/cards/{{
              currentCard
            }}.png"
          />
        </div>
      </div>
      <img
        class="card"
        *ngIf="currentCard && showCard"
        src="/assets/board-games/candyland_game_assets/cards/{{
          currentCard
        }}.png"
      />
    </div>
    <img
      class="game-board-img"
      src="/assets/board-games/candyland_game_assets/game_board.png"
    />
  </div>
</div>
