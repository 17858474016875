import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store';
import { RoomnameModel } from '../RoomnameModel';
import { FirebaseService } from '../../firebase/firebase.service';

@Injectable({ providedIn: 'root' })
export class FirebaseModel {
  firebaseRefs = {};
  connected = false;
  longDisconnect: boolean;
  disconnectionTimeout: NodeJS.Timeout;

  constructor(
    private roomnameModel: RoomnameModel,
    private ngrxStoreService: Store<AppState>,
    private firebaseService: FirebaseService,
  ) {}

  /**
   * Gets the current firebase reference by object name
   * @returns {*}
   */
  getFirebaseRef = (name): firebase.database.Reference => {
    return this.firebaseService.getRoomRef(name);
  };

  /**
   * Create the firebase ref for a given top level object name
   * @param name
   * @returns firebaseRef
   */
  createFirebaseRef = (roomname, name): firebase.database.Reference => {
    const path = `${roomname}/${name}`;
    const ref = firebase.database().ref(path);
    return ref;
  };

  monitorConnection = (): void => {
    const connectedRef = firebase.database().ref('.info/connected');
    connectedRef.on('value', snap => {
      this.handleConnectionUpdate(snap);
    });
    setInterval(() => {
      connectedRef.once('value', snap => {
        this.handleConnectionUpdate(snap);
      });
    }, 1000);
  };

  private handleConnectionUpdate(snap: any) {
    if (snap.val() === true) {
      this.connected = true;
      this.longDisconnect = false;
      this.dispatchConnectionStatus();
      clearTimeout(this.disconnectionTimeout);
    } else {
      this.connected = false;
      this.dispatchConnectionStatus();
      this.disconnectionTimeout = global.setTimeout(() => {
        if (!this.connected) {
          this.longDisconnect = true;
          this.dispatchConnectionStatus();
        }
      }, 5000);
    }
  }

  dispatchConnectionStatus = () => {
    this.ngrxStoreService.dispatch({
      type: 'FIREBASE_CONNECTION',
      payload: {
        connected: this.connected,
        longDisconnect: this.longDisconnect,
      },
    });
  };

  login = () => {
    this.setupFirebaseApp();
    return firebase
      .auth()
      .signInWithCustomToken(this.roomnameModel.value.firebase_auth_token)
      .then(() => {
        // wait 10 seconds to begin monitoring to avoid inappropriate bad
        // firebase connection warning being displayed when the page loads
        // slowly
        setTimeout(() => {
          this.monitorConnection();
        }, 10 * 1000);
      })
      .catch(error => {
        console.error('Firebase Login Failed', error);
        alert('Unable to sign into firebase');
      });
  };

  setupFirebaseApp = () => {
    if (firebase.apps.length === 0) {
      const config = {
        apiKey: this.roomnameModel.value.firebase_app_config.apiKey,
        authDomain: this.roomnameModel.value.firebase_app_config.authDomain,
        databaseURL: this.roomnameModel.value.firebase_baseurl,
        storageBucket:
          this.roomnameModel.value.firebase_app_config.storageBucket,
        messagingSenderId:
          this.roomnameModel.value.firebase_app_config.messageSenderId,
      };
      firebase.initializeApp(config);
    } else {
      console.log('[FirebaseModel] firebase app already initialized');
    }
  };
}
