import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GameStartEndBtnsComponent } from './game-start-end-btns.component';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [CommonModule, MatButtonModule],
  exports: [GameStartEndBtnsComponent],
  declarations: [GameStartEndBtnsComponent],
  providers: [],
})
export class GameStartEndBtnsModule {}
