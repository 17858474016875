import { propertyOf } from 'lodash';
import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { PromisableEventEmitter } from './promisable-event-emitter';
import { PLActivityModelService } from '../../model/activity-model.service';
import { Store } from '@ngrx/store';
import { AppState } from '@root/src/app/store';
import { selectIsLocalParticipantHost } from '../../../session/store';

@Injectable()
export class FlashCardsService extends PromisableEventEmitter {
  private props: any;
  private initDigest: () => any;
  private activityLink: any;
  private stateLink: any;
  private onDataValueHandler: any;
  public cards: any = [];
  public cards$ = new BehaviorSubject<[]>([]);
  public activityCards$ = new BehaviorSubject<[]>([]);
  public cardsChange$ = new BehaviorSubject<any>({});
  public cardsInit$ = new BehaviorSubject<any>([]);
  public cardsInitLocal$ = new BehaviorSubject<any>([]);
  public beforeCardsChange$ = new ReplaySubject<any>();
  public flashcardsClearAnimated$ = new BehaviorSubject<any>(1);
  public mode$ = new BehaviorSubject<string>('default');
  public presentationActiveCard$ = new BehaviorSubject<number>(0);
  public collageRandom$ = new BehaviorSubject<number>(0);
  public deselectCards$ = new BehaviorSubject<boolean>(false);
  private isHost = false;

  constructor(
    private activityModel: PLActivityModelService,
    private store: Store<AppState>,
  ) {
    super();
    this.store.select(selectIsLocalParticipantHost).subscribe(isHost => {
      this.isHost = isHost;
    });
    this.initDigest = () => setTimeout(() => {}, 0);
    this.cleanModel();
  }

  public updateFlashcardsClearAnimated(): any {
    let val = this.flashcardsClearAnimated$.getValue();
    const addOne = val++;
    this.flashcardsClearAnimated$.next(addOne);
  }

  public copy(card): any {
    return {
      id: card.id,
      type: card.type || 'both',
      url: card.url,
      title: card.title,
      x: card.x || 0,
      y: card.y || 0,
      angle: card.angle || 0,
      index: card.index || 0,
      size: card.size || 0,
    };
  }

  public copies(cards): any[] {
    return JSON.parse(JSON.stringify(cards));
  }

  public getCards() {
    return this.cards$.value;
  }

  public setCards(cardsArg) {
    const cards = cardsArg || [];
    // make sure we keep existing cards references
    const currentIds = this.cards.map(card => card.$id);
    const ids = cards.map(card => card.$id);

    currentIds.forEach((id, index) => {
      const i = ids.indexOf(id);

      if (~i) {
        // update with existing reference
        cards[i] = Object.assign(this.cards[index], cards[i]);
      }
    });

    // make sure we won't loose a reference to initial array
    // so first of all clear it
    this.cards.splice(0, this.cards.length);

    if (cards.length) {
      // and fill it with new values
      this.cards.push.apply(this.cards, cards);
    }

    function compare(a, b) {
      if (a.title > b.title) {
        return 1;
      }
      if (a.title < b.title) {
        return -1;
      }
      return 0;
    }

    /**
     * Sorting required by
     * https://presencelearning.atlassian.net/browse/PL-1407
     */
    this.cards = this.cards.sort(compare);
  }

  public updateFlashCardsDeselectCards(show: boolean) {
    this.deselectCards$.next(show);
  }

  public updateFlashCardsActivityCards(cards: [any], all = false) {
    const currentCards = this.activityCards$.getValue();

    const cardDict = {};
    const initialPosition = this.mode$.getValue() === 'default' ? 0 : -9999;
    cards.forEach((card: any) => {
      cardDict[card.id] = {
        ...card,
        size: 212,
        x: initialPosition,
        y: initialPosition,
      };
    });
    currentCards.forEach((card: any) => {
      cardDict[card.id] = card;
    });

    const updatedCards: any = Object.values(cardDict);
    if (this.isHost) {
      this.stateLink.update(updatedCards);
    }
    this.activityCards$.next(updatedCards);
  }

  public updateFlashCardsMode(mode: string) {
    this.mode$.next(mode);
    if (this.isHost) {
      this.activityLink.update({
        mode,
      });
    }
  }

  public updateFlashCardsPresentationActive(data: number) {
    this.presentationActiveCard$.next(data);
    this.activityLink.update({
      presentationActiveCard: data,
    });
  }

  public updateFlashCardsCollageRandom(data: number, cb) {
    this.collageRandom$.next(data);
    if (this.isHost) {
      this.activityLink.update(
        {
          collageRandom: data,
        },
        cb,
      );
    }
  }

  /**
   * Boolean flag signaling if this data object has been
   * completely initialized or not
   *
   * @property {boolean} FlashCards##isInitialized
   * @readonly
   */
  get isInitialized() {
    return this.props.isInitialized;
  }
  set isInitialized(initialized) {
    throw new TypeError('trying to change read-only property "isInitialized"!');
  }

  /**
   * Boolean flag signaling if current action is handled by a local events outside
   * of this object (if set to true) or should be treated as normal
   * event, triggering all required event handlers
   *
   * @property {boolean} FlashCards#isLocalEvent
   */
  get isLocalEvent() {
    return this.props.isLocalEvent;
  }
  set isLocalEvent(isLocal) {
    this.props.isLocalEvent = isLocal;
  }

  cleanModel() {
    if (this.activityLink) {
      this.activityLink.off('value', this.onSettingsValueHandler);
    }
    if (this.stateLink) {
      this.stateLink.off('value', this.onDataValueHandler);
    }

    // global class method?
    // we will remove this after converting the emits and the ons in all the flashcard files
    this.removeAllListeners('settingsChange');
    this.removeAllListeners('beforeCardsChange');
    this.removeAllListeners('cardsChange');
    this.removeAllListeners('cardsInit');

    /**
     * @access private
     * @property {{
     *  cards: Array,
     *  mode: string,
     *  presentationActiveCard: number,
     *  collageRandom: number,
     *  isInitialized: boolean,
     *  isLocalEvent: boolean
     * }} FlashCards#$$props
     */
    this.props = Object.assign(this.defaultValue(), {
      isInitialized: false,
      isLocalEvent: false,
    });

    return this;
  }

  defaultValue() {
    return {
      cards: [],
      state: {},
      mode: 'default',
      presentationActiveCard: 0,
      collageRandom: 0,
    };
  }

  // initialize firebase link
  initialize(activity) {
    const queueId = activity.queueId;
    const activityId = activity.activityId;

    this.activityLink = this.activityModel
      .getRef('activities')
      .child('queues')
      .child('items')
      .child(queueId)
      .child('items')
      .child(activityId)
      .child('flashcards_settings');

    this.stateLink = this.activityModel
      .getRef('activities')
      .child('queues')
      .child('items')
      .child(queueId)
      .child('items')
      .child(activityId)
      .child('flashcards_cards');

    this.onDataValueHandler = (data: any) => {
      const cardsData = data.val() || {};
      const cards = Object.keys(cardsData).map(id =>
        Object.assign(cardsData[id], { $id: id }),
      );
      const oldCards = this.copies(this.cards);

      this.updateCardsEvent(cards, oldCards);
      this.initDigest();
    };

    this.activityLink.on('value', this.onSettingsValueHandler);
    this.stateLink.on('value', this.onDataValueHandler);
  }

  private onSettingsValueHandler = (data: any) => {
    const defaultValue = this.defaultValue();
    const value = data.val() || defaultValue;
    const oldSettings = this.copies(this.props);

    const currentMode = value.mode || defaultValue.mode;
    const currentPresentationActive =
      value.presentationActiveCard || defaultValue.presentationActiveCard;
    const currentCollageRandom =
      value.collageRandom || defaultValue.collageRandom;
    this.mode$.next(currentMode);
    this.presentationActiveCard$.next(currentPresentationActive);
    this.collageRandom$.next(currentCollageRandom);
    this.emit('settingsChange', this, oldSettings);
    this.initDigest();
  };

  updateCardsEvent(cards, oldCards) {
    this.cards = cards || [];
    this.cardsChange$.next({ cards, oldCards });
  }

  ensureInit(oldCards) {
    if (!this.isInitialized) {
      this.props.isInitialized = true;
      this.cardsInit$.next(this.cards);
      return;
    }
    this.cardsInitLocal$.next(this.cards);
  }

  updateCards(cardsArg) {
    const cards = cardsArg || this.cards;

    const data = {};

    for (let i = 0, s = cards.length; i < s; i++) {
      if (cards[i].$id !== undefined) {
        data[cards[i].$id] = this.copy(cards[i]);
      }
    }
    this.stateLink.update(data);
    return this;
  }

  deleteCards(cards) {
    const cardsToDelete = cards || this.cards;
    const cardsLength = cardsToDelete.length;
    const data = {};

    for (let i = 0, s = cardsLength; i < s; i++) {
      data[cardsToDelete[i].$id] = null;
    }
    if (this.isHost) {
      this.stateLink.update(data);
    }
  }

  createCard(cardArg) {
    const card = this.copy(cardArg);
    this.cards.push(card);
    return this.stateLink.push(card).key;
  }

  cleanDuplicateCards(data) {
    if (Array.isArray(data.state)) {
      data.state = data.state.filter(card => {
        return !isNaN(card.id);
      });
    } else {
      let cardKeys: any[] = Object.keys(data.state);
      for (const key of cardKeys) {
        if (!isNaN(key)) {
          delete data.state[key];
        }
      }
    }
  }

  createCards(cardsArg) {
    const cards = this.copies(cardsArg);

    this.activityLink.transaction(d => {
      const data = Object.assign(d || {}, {
        mode:
          this.mode$.getValue() === 'default' ? 'grid' : this.mode$.getValue(),
        presentationActiveCard: this.presentationActiveCard$.getValue(),
        collageRandom: this.collageRandom$.getValue(),
        state: (d || {}).state || [],
      });

      // generate unique ids
      for (let i = 0, s = cards.length; i < s; i++) {
        const card = this.copy(cards[i]);

        this.cards.push(card);
        data.state[`-Card${Date.now()}${i}`] = card;
      }

      // remove temporal numeric ids
      this.cleanDuplicateCards(data);

      return data;
    });

    return this;
  }

  updateCard(card) {
    this.stateLink.child(card.$id).update(this.copy(card));

    return this;
  }

  deleteCard(card) {
    this.stateLink.child(card.$id).remove();

    return this;
  }

  isLinksReady() {
    return !!(this.activityLink && this.stateLink);
  }

  clean() {
    this.cards = [];
    this.initDigest();
    return this;
  }
}
