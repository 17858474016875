<div class="presence-documentation-client-selection">
  <div *ngIf="loading; else alert" class="loader">
    <mat-spinner diameter="36"></mat-spinner>
  </div>

  <ng-template #alert>
    <pl-alert-message
      class="margin-t"
      *ngIf="!clientAppointmentsSelectionOptions.length; else appointmentSelect"
      >To document a client, you must first add them to your schedule for the
      day.

      <button
        mat-flat-button
        color="primary"
        (click)="openSchedule()"
        class="w-100">
        <span>Open schedule</span>
        <pl-icon-new [name]="'open_in_new'"></pl-icon-new>
      </button>
    </pl-alert-message>
  </ng-template>

  <ng-template #appointmentSelect>
    <pl-multi-select-menu
      [selectionOptions]="clientAppointmentsSelectionOptions"
      [itemName]="'event'"
      (selectionChanged)="clientAppointmentSelectionChanged($event)">
      <div class="button-container">
        <button mat-stroked-button (click)="openSchedule()" class="open-button">
          <span>Open schedule</span>
          <pl-icon-new [name]="'open_in_new'"></pl-icon-new>
        </button>
        <button mat-flat-button class="done-button">
          <span>Done</span>
        </button>
      </div></pl-multi-select-menu
    >
  </ng-template>
</div>
