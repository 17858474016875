import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';

export interface PlayerCountOption {
  value: number;
  label: string;
}

@Component({
  selector: 'pl-player-count-select',
  templateUrl: './player-count-select.component.html',
  styleUrls: ['./player-count-select.component.less'],
})
export class PlayerCountSelectComponent {
  @Input() isDisabled = false;
  @Input() selection = 1;
  @Input() playerCountOptions: PlayerCountOption[] = [{ value: 1, label: '1' }];
  @Output() numPlayersChange = new EventEmitter<number>();

  numPlayers = 1;

  onChangeNumPlayers(val: number) {
    this.numPlayers = val;
    this.numPlayersChange.emit(val);
  }
}
