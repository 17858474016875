import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { documentationHelpUrl } from '../presence-documentation-constants.service';
import { PLRecordRoomService } from '@common/services/pl-records';
import { SelectionOption } from '@common/components/multi-select-menu/multi-select-menu.component';
import { PLUrlsService } from '@root/src/app/common/services/pl-http';
import { User } from '@root/src/app/modules/user/user.model';
import { first } from 'rxjs/operators';
import {
  ClientAppointment,
  ClientService,
  IepGoal,
  IepServiceArea,
  MetricPoint,
} from '../../documentation.models';
import { IepGoalsService } from '../iep-goals.service';

@Component({
  selector: 'pl-metrics-tracking',
  templateUrl: './metrics-tracking.component.html',
  styleUrls: ['./metrics-tracking.component.less'],
})
export class MetricsTrackingComponent implements OnChanges {
  @Input() service: ClientService;
  @Input() provider: User;
  @Input() clientAppointment: ClientAppointment;
  @Output() metricsUpdated = new EventEmitter<MetricPoint[]>();

  // One per client appointment, so will set later once have instance id.
  localStorageKey: string = '';
  localStorageData: any = {};

  goals: IepGoal[] = [];
  metrics: any[] = [];
  documentationHelpUrl: string;
  iepServiceAreas: IepServiceArea[];
  metricPoints: MetricPoint[] = [];
  metricOptions: SelectionOption[];
  updatingMetric: boolean;
  constructor(
    private iepGoalsService: IepGoalsService,
    private plRecordRoom: PLRecordRoomService,
    private plUrls: PLUrlsService,
  ) {
    this.documentationHelpUrl = documentationHelpUrl;
  }

  ngOnChanges() {
    if (this.clientAppointment) {
      this.localStorageKey = `documentationMetrics${this.clientAppointment.xInstanceId}`;
    }
    if (this.clientAppointment && this.service) {
      this.iepGoalsService
        .getIepAndInfo(this.clientAppointment.appointment.clients[0].uuid)
        .subscribe((res: any) => {
          this.iepServiceAreas = res;
          this.goals = this.iepGoalsService.getGoalsForService(
            this.iepServiceAreas,
            this.service,
          );
          if (this.goals?.length > 0) {
            this.metrics = this.iepGoalsService.getCombinedMetrics(this.goals);
            this.loadMetricPointsAndOptions(this.metrics);
          }
        });
    }
  }

  trackByMetricPoint(index: number, metricPoint: MetricPoint) {
    return metricPoint.id;
  }

  loadMetricPointsAndOptions(metrics: any[]) {
    if (!this.clientAppointment.record.uuid) {
      console.log(
        'no record uuid, bailing on loadMetricPointsAndOptions for appt: ',
        this.clientAppointment.uuid,
      );
      return;
    }
    this.iepGoalsService
      .loadMetricPoints(metrics, this.clientAppointment)
      .subscribe((res: any) => {
        this.metricPoints = res;
        this.loadLocalStorage();

        this.metricOptions = this.metricPoints.map(
          (metricPoint: MetricPoint) => {
            return {
              value: metricPoint.metric.id,
              label: metricPoint.metric.name,
              selected: metricPoint.xSelected,
            };
          },
        );

        // Even if not new, on first load if any any metrics, want to allow importing them.
        let atLeastOneTrial = this.metricPoints.some(
          metricPoint => metricPoint.trials > 0,
        );
        if (atLeastOneTrial) {
          this.metricsUpdated.emit(this.metricPoints);
        }
      });
  }

  private loadLocalStorage() {
    if (this.localStorageKey) {
      // Load state from local storage and pre-select if necessary.
      let data: any;
      try {
        data = localStorage.getItem(this.localStorageKey);
      } catch (e) {
        console.debug('localStorage error in PLDocumentationMetricsComponent');
      }
      if (data) {
        data = JSON.parse(data);

        if (data.selectedMetricPointsIds) {
          // Could just set them directly, but in case a metric was removed, check
          // that it still exists first.

          this.metricPoints.forEach((metricPoint: any) => {
            metricPoint.xSelected = data.selectedMetricPointsIds.some(
              id => id === metricPoint.metric.id,
            );
          });

          this.metricOptions = this.metricPoints.map(
            (metricPoint: MetricPoint) => {
              return {
                value: metricPoint.metric.id,
                label: metricPoint.metric.name,
                selected: metricPoint.xSelected,
              };
            },
          );
        }
      }
    }
  }

  metricSelectionChanged($event) {
    const selectedIds: string[] = [];
    this.metricOptions.forEach((option: SelectionOption) => {
      if (option.selected) {
        selectedIds.push(option.value);
      }
      this.metricPoints.forEach((metricPoint: any) => {
        if (option.value === metricPoint.metric.id) {
          metricPoint.xSelected = option.selected;
        }
      });
    });
    this.localStorageData.selectedMetricPointsIds = selectedIds;
    localStorage.setItem(
      this.localStorageKey,
      JSON.stringify(this.localStorageData),
    );
  }

  openGoals() {
    window.open(
      `${this.plUrls.urls.eduClientsFE}/client/${this.clientAppointment.appointment.clients[0].uuid}/iep-goals`,
      '_blank',
    );
  }

  metricPointUpdated(metricPoint: MetricPoint) {
    this.updatingMetric = true;
    const { id, record, correct, trials } = metricPoint;
    const metric: any = { id, record, correct, trials };
    metric.metricId = metricPoint.metric.id;
    this.plRecordRoom
      .saveMetricsPoint(
        metric,
        this.clientAppointment.record,
        this.clientAppointment.uuid,
        this.clientAppointment.record.appointment,
        this.clientAppointment.appointment.event,
        this.provider.uuid,
      )
      .pipe(first())
      .subscribe((res: any) => {
        metricPoint.id = res.metricPoint.id;
        this.updatingMetric = false;
        this.metricsUpdated.emit(this.metricPoints);
      });
  }
}
