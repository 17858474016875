import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StickerTemplate } from '../pl-scenes-factory.service';

@Component({
  selector: 'pl-scenes-stickers',
  templateUrl: './scenes-stickers.component.html',
  styleUrls: ['./scenes-stickers.component.less'],
})
export class PLScenesStickersComponent {
  @Input() areStickersVisible: boolean;
  @Input() canControlStickers: boolean;
  @Input() stickerOptions: { label: string; value: string }[] = [];
  @Input() selectedStickerPackName: string;
  @Input() stickerPackOptions: StickerTemplate[] = [];

  @Output() addSticker = new EventEmitter<StickerTemplate>();
  @Output() addAllStickers = new EventEmitter();
  @Output() areStickersVisibleChange = new EventEmitter<boolean>();
  @Output() selectedStickerPackNameChange = new EventEmitter<string>();
}
