import { Component, Input, OnChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@root/src/app/store';
import { Observable } from 'rxjs';
import {
  AppActions,
  CentralContentType,
  selectIsCentralContentEmpty,
  selectIsCentralContentVisible,
  selectIsCurrentCentralContent,
} from '../../app/store';

@Component({
  selector: 'pl-activity-switch-button',
  templateUrl: 'activity-switch-button.component.html',
  styleUrls: ['./activity-switch-button.component.less'],
})
export class ActivitySwitchButtonComponent implements OnChanges {
  isCentralContentVisible$: Observable<boolean>;
  isCurrentActivity$: Observable<boolean>;
  isWorkspaceEmpty$: Observable<boolean>;
  @Input() public contentDetail: any;
  @Input() public contentType: CentralContentType;

  constructor(private store: Store<AppState>) {
    this.isCentralContentVisible$ = store.select(selectIsCentralContentVisible);
    this.isWorkspaceEmpty$ = store.select(selectIsCentralContentEmpty);
  }

  ngOnChanges() {
    if (!this.contentType) {
      return;
    }
    this.isCurrentActivity$ = this.store.select(
      selectIsCurrentCentralContent(this.contentType, this.contentDetail),
    );
  }

  onSwitchToThis() {
    this.store.dispatch(
      AppActions.setCentralContent({
        contentType: this.contentType,
        contentDetail: this.contentDetail,
      }),
    );
  }

  onShowWorkspace(visible = false) {
    this.store.dispatch(
      AppActions.setIsCentralContentVisible({ isVisible: visible }),
    );
  }
}
