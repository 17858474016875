import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { DrawerState, selectDrawer } from '../store';
import { AppState } from '@root/src/app/store';
import {
  FeatureFlag,
  FeatureFlagsService,
} from '@root/src/app/common/services/feature-flags';

@Component({
  selector: 'pl-right-drawers',
  templateUrl: './pl-right-drawers.component.html',
  styleUrls: ['./pl-right-drawers.component.less'],
})
export class PLRightDrawersComponent implements OnInit {
  drawerSubscription: Observable<DrawerState>;
  isNewDocumentationEnabled$: Observable<boolean>;

  constructor(
    private store: Store<AppState>,
    private featureFlagsService: FeatureFlagsService,
  ) {}

  ngOnInit() {
    this.drawerSubscription = this.store.select(selectDrawer);
    this.isNewDocumentationEnabled$ = this.featureFlagsService.isFeatureEnabled(
      FeatureFlag.NEW_DOCUMENTATION,
    );
  }
}
