import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { PLIconModule, PLInputModule } from '@root/src/lib-components';
import { DocumentationTEClientComponent } from './documentation-te-client/documentation-te-client.component';
import { DocumentationTEMetricComponent } from './documentation-te-client/documentation-te-metrics/documentation-te-metric/documentation-te-metric.component';
import { DocumentationTEMetricsComponent } from './documentation-te-client/documentation-te-metrics/documentation-te-metrics.component';
import { SessionDurationSelctComponent } from './documentation-te-client/session-duration-select/session-duration-select.component';
import { DocumentationTEComponent } from './documentation-te.component';
import { DocumentationTEService } from './documentation-te.service';
import { PLCommonComponentsModule } from '@root/src/app/common/components';

const exportedComponents = [
  DocumentationTEComponent,
  DocumentationTEClientComponent,
  SessionDurationSelctComponent,
  DocumentationTEMetricsComponent,
  DocumentationTEMetricComponent,
];

@NgModule({
  imports: [
    CommonModule,
    MatMenuModule,
    PLInputModule,
    PLIconModule,
    PLCommonComponentsModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
  ],
  exports: [...exportedComponents],
  declarations: [...exportedComponents],
  providers: [DocumentationTEService],
})
export class DocumentationTEModule {}
