import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';

export namespace PLFormService {
  function controls(
    form: UntypedFormArray | UntypedFormGroup,
  ): Array<AbstractControl> {
    const controls = form.controls;

    if (Array.isArray(controls)) {
      return controls;
    } else {
      return Object.keys(controls).map(key => controls[key]);
    }
  }

  export function markAllAsTouched<
    T extends UntypedFormArray | UntypedFormGroup,
  >(formCollection: T): T {
    // loosely based on https://github.com/angular/angular/issues/11774
    formCollection.markAsTouched();

    controls(formCollection).forEach((control: AbstractControl) => {
      if (control instanceof UntypedFormControl) {
        control.markAsTouched();
      } else {
        markAllAsTouched(<UntypedFormArray | UntypedFormGroup>control);
      }
    });

    return formCollection;
  }
}
