<button
  class="piece-button"
  [ngClass]="{ disabled: isDisabled() }"
  (click)="clickedPiece()"
  (mousedown)="preventDragging($event)"
>
  <img
    class="piece-button-img"
    [src]="pieceSetup.type.imageSrc"
    [id]="pieceSetup.type.name"
  />
</button>
