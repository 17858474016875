import { Injectable } from '@angular/core';
import { environment } from '@root/src/environments/environment';
import { from } from 'rxjs';

export interface FirebaseConfig {
  customToken: string;
  roomName: string;
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  storageBucket: string;
  messagingSenderId: string;
}

export type LazyRef = () => firebase.database.Reference;

@Injectable({ providedIn: 'root' })
export class FirebaseService {
  private config: FirebaseConfig;

  initialize(config: FirebaseConfig) {
    if (this.config) {
      throw new Error('FirebaseService already initialized');
    }
    this.config = config;

    this.initializeApp();

    return this.signIn();
  }

  getRoomRef(path: string) {
    if (!this.config) {
      throw new Error('You should initialize FirebaseService first');
    }

    const { roomName } = this.config;

    return firebase.database().ref(`${roomName}/${path}`);
  }

  getLazyRoomRef(path: string): LazyRef {
    let ref: firebase.database.Reference;
    return () => {
      ref = ref || this.getRoomRef(path);
      return ref;
    };
  }

  private initializeApp() {
    const {
      apiKey,
      authDomain,
      databaseURL,
      storageBucket,
      messagingSenderId,
    } = this.config;

    firebase.initializeApp({
      apiKey,
      authDomain,
      databaseURL,
      storageBucket,
      messagingSenderId,
    });
  }

  private signIn() {
    const { customToken } = this.config;

    const auth = firebase.auth();

    if (environment.emulate_firebase) {
      auth.useEmulator('http://localhost:3015');
      firebase.database().useEmulator('localhost', 3014);
      return from(auth.signInAnonymously());
    }

    return from(auth.signInWithCustomToken(customToken));
  }
}
