import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PLMemoryActivityComponent } from './memory-activity.component';
import { MemoryCardsService } from './services/cards.service';
import { PLMemoryActivityDrawerComponent } from './drawer/memory-activity-drawer.component';
import { PLIconModule, PLInputModule } from '@root/index';
import { HotkeyModule } from 'angular2-hotkeys';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { FormsModule } from '@angular/forms';
import { PLDragAndDropModule } from '@common/directives/drag-n-drop';
import { GameStartEndBtnsModule } from '../game/game-start-end-btns';

import { PLCommonComponentsModule } from '@common/components';

const components = [PLMemoryActivityComponent, PLMemoryActivityDrawerComponent];

@NgModule({
  imports: [
    CommonModule,
    GameStartEndBtnsModule,
    PLCommonComponentsModule,
    PLIconModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatSelectModule,
    MatSliderModule,
    MatInputModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatTabsModule,
    FormsModule,
    PLInputModule,
    PLDragAndDropModule,
    HotkeyModule,
  ],
  exports: [...components],
  declarations: [...components],
  providers: [MemoryCardsService],
})
export class PLMemoryActivityModule {}
