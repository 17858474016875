<pl-pdf-viewer
  *ngIf="pdfUrl"
  [pdfUrl]="pdfUrl"
  [pageNumber]="currentPage"
  [scale]="scale"
  [bookMode]="bookMode"
  (pdfLoaded)="pdfLoaded($event)"
  [pageRotations]="pageRotations"
  [(scrollYPercent)]="scrollYPercent"
  [(scrollXPercent)]="scrollXPercent"
  (scrollYPercentChange)="saveScrollPosition()"
  (scrollXPercentChange)="saveScrollPosition()"
></pl-pdf-viewer>
