import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import moment from 'moment';

import { AppState } from '@app/store';

import { PLGuidService } from '@common/services/GuidService';
import { LocalStorageService } from '@common/services/LocalStorageService';
import { Notifications } from '@common/services/notifications.service';
import { FirebaseService } from '@common/firebase/firebase.service';

import {
  Activity,
  AppActions,
  CentralContent,
  CentralContentType,
  selectCurrentCentralContent,
  selectCurrentDrawerActivity,
} from '@room/app/store';
import {
  FeatureFlag,
  FeatureFlagsService,
} from '../../common/services/feature-flags';

type ViewType =
  | 'queues'
  | 'queue'
  | 'deleted-queues'
  | 'queue-activity'
  | 'queue-add-activity'
  | 'queue-library';

interface View {
  type: ViewType;
  title: string;
}

interface Queues {
  order: string[];
  items: Record<string, QueueItem>;
}

export interface QueueItem {
  name: string;
  deletedDate?: string;
  created?: number;
  order: string[];
  items: Record<string, Activity>;
}

export interface DeletedQueueItem extends QueueItem {
  id: string;
}

const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ssZ';

@Component({
  selector: 'pl-queue',
  templateUrl: 'pl-queue.component.html',
  styleUrls: ['pl-queue.component.less'],
})
export class PLQueueComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  viewStack: View[] = [
    {
      type: 'queues',
      title: 'Queues',
    },
  ];

  currentViewIndex = 0;

  activeQueueId: string;
  currentCentralContentActivity: CentralContent;

  queuesRef: firebase.database.Reference;
  appRef: firebase.database.Reference;
  queuesOrder: string[] = [];
  queuesItems: Record<string, QueueItem> = {};
  deletedQueues: DeletedQueueItem[] = [];

  hardDeleteTime = 30;
  hardDeleteUnit = 'days' as const;
  isEditingActiveQueue = false;
  isLoaded = false;
  animationsEnabled = false;

  activeActivity$: Observable<Activity>;
  isIntegratedLibraryEnabled$: Observable<boolean>;

  constructor(
    private localStorageService: LocalStorageService,
    private firebaseService: FirebaseService,
    private store: Store<AppState>,
    private guidService: PLGuidService,
    private zone: NgZone,
    private notificationsService: Notifications,
    private featureFlagsService: FeatureFlagsService,
  ) {
    this.queuesRef = firebaseService.getRoomRef('activities/queues');
    this.appRef = firebaseService.getRoomRef('app');
    this.activeActivity$ = this.store.select(selectCurrentDrawerActivity);
    this.isIntegratedLibraryEnabled$ =
      this.featureFlagsService.isFeatureEnabled(FeatureFlag.INTEGRATED_LIBRARY);
  }

  async ngOnInit() {
    this.subscriptions.push(this.listenToCentralContentActivity());

    const activeQueueId = await this.getActiveQueueId();
    const activeActivityId = await this.getCurrentDrawerActivityId();

    await this.addCreatedDates();

    this.queuesRef.on('value', snapshot => {
      const queues: Queues = snapshot.val() || { items: {}, order: [] };

      this.queuesItems = queues.items || {};
      this.queuesOrder = queues.order || [];
      this.deletedQueues = this.getDeletedQueues(queues.items);

      if (!this.isLoaded) {
        // Only delete the old queues once when the component is rendered, not on every update.
        this.hardDeleteOldQueues();

        // If there is an active queue, open it.
        if (activeQueueId && this.queuesItems[activeQueueId]) {
          this.openDetails({ queueId: activeQueueId });
        }

        // If there is an active activity, open it.
        if (activeActivityId) {
          this.openActivity({ activityId: activeActivityId });
        }

        setTimeout(() => {
          // Enable animations after the first render.
          // This way the view doesn't slide when setting it up
          this.animationsEnabled = true;
        }, 0);

        this.isLoaded = true;
      }
    });
  }

  private async addCreatedDates() {
    return this.queuesRef.once('value', snapshot => {
      const queues: Queues = snapshot.val() || { items: {}, order: [] };
      const queuesItems = queues.items || {};
      const entries: Array<[string, QueueItem]> = Object.entries(queuesItems);
      entries.forEach(([id, queue]) => {
        if (!queue.created) {
          this.queuesRef.child(`items/${id}/created`).set(Date.now());
        }
      });
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private getActiveQueueId(): Promise<string> {
    let activeQueueId;

    try {
      activeQueueId = this.localStorageService.get('activeQueueId');
    } catch (e) {
      // noop
    }

    return new Promise(resolve => {
      this.queuesRef.once('value', snapshot => {
        const queues = snapshot.val() as Queues;

        if (!queues) {
          return resolve(null);
        }

        if (activeQueueId && queues.items[activeQueueId]) {
          return resolve(activeQueueId);
        }

        resolve(null);
      });
    });
  }

  private getCurrentDrawerActivityId(): Promise<string> {
    return new Promise(resolve => {
      this.appRef
        .child('currentDrawerActivity/activityId')
        .once('value', snapshot => {
          resolve(snapshot.val());
        });
    });
  }

  // Returns list of all deleted queues
  // sorted from recent to oldest
  private getDeletedQueues(queuesItems: Record<string, QueueItem>) {
    return Object.keys(queuesItems)
      .map(queueId => ({
        id: queueId,
        ...queuesItems[queueId],
      }))
      .filter(queue => queue.deletedDate)
      .sort((a, b) =>
        moment(a.deletedDate).valueOf() > moment(b.deletedDate).valueOf()
          ? -1
          : 1,
      );
  }

  listenToCentralContentActivity() {
    return this.store.select(selectCurrentCentralContent).subscribe(content => {
      this.currentCentralContentActivity = content;
    });
  }

  changeQueueOrder(order: string[]) {
    this.queuesOrder = order;
    this.queuesRef.child('order').set(order);
  }

  changeActivitiesOrder(order: string[]) {
    if (!this.activeQueueId || !this.queuesItems[this.activeQueueId]) return;

    this.queuesItems[this.activeQueueId].order = order;
    this.queuesRef
      .child('items')
      .child(this.activeQueueId)
      .child('order')
      .set(order);
  }

  async deleteQueue({ queueId }: { queueId: string }) {
    const queueName = this.queuesItems[queueId].name;

    this.queuesRef
      .child('items')
      .child(queueId)
      .child('deletedDate')
      .set(moment().format(DATE_FORMAT));

    delete this.queuesItems[queueId];

    const index = this.queuesOrder.indexOf(queueId);
    this.queuesOrder.splice(index, 1);
    this.queuesRef.child('order').set(this.queuesOrder);

    if (queueId === this.activeQueueId) {
      this.setActiveQueueId(null);
      this.goBackToView('queues');
    }

    this.notificationsService.show(
      {
        text: `The queue "${queueName}" has been deleted.`,
      },
      'bottom',
      'left',
    );
  }

  restoreQueue({ queueId }: { queueId: string }) {
    const queueName = this.queuesItems[queueId].name;

    this.queuesRef.child('items').child(queueId).child('deletedDate').remove();

    this.queuesOrder.push(queueId);
    this.queuesRef.child('order').set(this.queuesOrder);

    this.notificationsService.show(
      {
        text: `The queue "${queueName}" has been restored.`,
      },
      'bottom',
      'left',
    );
  }

  hardDeleteOldQueues() {
    const oldestDate = moment().subtract(
      this.hardDeleteTime,
      this.hardDeleteUnit,
    );

    this.deletedQueues.forEach(queue => {
      if (moment(queue.deletedDate, DATE_FORMAT) < oldestDate) {
        this.queuesRef.child('items').child(queue.id).remove();
      }
    });
  }

  updateQueueName({
    queueId,
    queueName,
  }: {
    queueId: string;
    queueName: string;
  }) {
    if (!queueName && !this.queuesItems[queueId].name) {
      // Only generate the queue name when user attempts saving queue without it,
      // and the queue didn't have prior name setup
      queueName = this.generateQueueName();
    }

    if (queueName && queueName !== this.queuesItems[queueId].name) {
      this.queuesItems[queueId].name = queueName;
      this.queuesRef.child('items').child(queueId).child('name').set(queueName);
    }

    this.isEditingActiveQueue = false;
  }

  setActiveQueueId(queueId: string) {
    this.activeQueueId = queueId;
    this.localStorageService.set('activeQueueId', queueId);
  }

  createNewQueue(): string {
    const queueId = this.guidService.generateUUID();
    const newQueue: QueueItem = {
      name: '',
      items: {},
      order: [],
      created: Date.now(),
    };

    this.queuesItems[queueId] = newQueue;
    this.queuesOrder.unshift(queueId);

    this.queuesRef.update({
      [`items/${queueId}`]: newQueue,
      order: this.queuesOrder,
    });

    return queueId;
  }

  generateQueueName() {
    let i = 1;

    const list = this.queuesOrder.map(j => this.queuesItems[j].name);

    while (list.indexOf('Queue ' + i) >= 0) {
      i++;
    }

    return 'Queue ' + i;
  }

  async removeActivity({ activityId }: { activityId: string }) {
    if (!this.activeQueueId) return;

    const activitiesOrder = this.queuesItems[this.activeQueueId].order;
    const newOrder = activitiesOrder.filter(id => id !== activityId);

    this.queuesItems[this.activeQueueId].order = newOrder;
    delete this.queuesItems[this.activeQueueId].items[activityId];

    await this.queuesRef
      .child('items')
      .child(this.activeQueueId)
      .child('order')
      .set(newOrder);

    await this.queuesRef
      .child('items')
      .child(this.activeQueueId)
      .child('items')
      .child(activityId)
      .remove();

    if (
      this.currentCentralContentActivity.contentType ===
        CentralContentType.QueueItem &&
      this.currentCentralContentActivity.contentDetail?.activityId ===
        activityId
    ) {
      this.store.dispatch(
        AppActions.setCentralContent({
          contentType: CentralContentType.Empty,
          contentDetail: null,
        }),
      );
    }
  }

  goBack() {
    if (this.viewStack.length <= 1) {
      return;
    }

    this.currentViewIndex--;

    setTimeout(() => {
      const closedView = this.viewStack.pop();
      this.onViewClosed(closedView);
    }, 200);
  }

  onViewClosed(view: View) {
    if (view.type === 'queue-activity') {
      this.store.dispatch(
        AppActions.setCurrentDrawerActivity({ activity: null }),
      );
    }

    if (view.type === 'queue') {
      this.setActiveQueueId(null);
    }
  }

  goBackToView(viewId: ViewType) {
    const index = this.viewStack.findIndex(view => view.type === viewId);

    if (index === -1) {
      return;
    }

    this.currentViewIndex = index;

    setTimeout(() => {
      this.viewStack.splice(index + 1);
    }, 200);
  }

  openDetails({ queueId }: { queueId: string }) {
    this.setActiveQueueId(queueId);
    this.openView({
      type: 'queue',
      title: 'Queues',
    });
  }

  openEditQueue({ queueId }: { queueId: string }) {
    this.isEditingActiveQueue = true;
    this.openDetails({ queueId });
  }

  openCreateNewQueue() {
    const queueId = this.createNewQueue();
    this.openEditQueue({ queueId });
  }

  openDeletedQueues() {
    this.openView({
      type: 'deleted-queues',
      title: 'Deleted Queues',
    });
  }

  openActivity({ activityId }: { activityId: string }) {
    const activity = this.queuesItems[this.activeQueueId]?.items[activityId];

    if (!activity) return;

    this.store.dispatch(
      AppActions.setCurrentDrawerActivity({ activity: { ...activity } }),
    );

    this.openView({
      type: 'queue-activity',
      title: this.queuesItems[this.activeQueueId]?.name,
    });
  }

  openAddActivity() {
    this.openView({
      type: 'queue-add-activity',
      title: this.queuesItems[this.activeQueueId]?.name,
    });
  }

  openLibrary() {
    this.openView({
      type: 'queue-library',
      title: 'Library',
    });
  }

  private openView(view: View) {
    this.viewStack.push(view);

    this.zone.run(() => {
      this.currentViewIndex = this.viewStack.length - 1;
    });
  }
}
