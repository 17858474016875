<div data-testid="design-system-typography">
  <div class="demo-group">
    <div class="typography-demo">
      <h1 class="heading-1">Heading 1</h1>

      <h2 class="heading-2">Heading 2</h2>

      <h3 class="heading-3">Heading 3</h3>

      <h4 class="heading-4">Heading 4</h4>

      <h5 class="heading-5">Heading 5</h5>

      <p class="subheading">Subheading</p>
    </div>
  </div>
</div>
