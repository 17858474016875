import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '@root/src/app/store';
import { of } from 'rxjs';
import {
  concatMap,
  switchMap,
  map,
  withLatestFrom,
  filter,
} from 'rxjs/operators';
import { ConferenceActions } from '../../conference/store';
import { selectIsLocalParticipantHost } from '../../session/store';
import { DrawersService } from '../drawers.service';
import { DrawerActions } from './drawers.actions';
import { selectIsDrawerOpen } from './drawers.selectors';

@Injectable()
export class DrawersEffects {
  remoteValuesChanged$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConferenceActions.initSuccess),
      switchMap(() => this.drawersService.once()),
      concatMap(data => {
        return of(data).pipe(
          withLatestFrom(this.store.select(selectIsLocalParticipantHost)),
        );
      }),
      filter(([_, isHost]) => isHost),
      map(([data]) => DrawerActions.init(data)),
    );
  });

  setCurrentOpenedDrawer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DrawerActions.open),
      concatMap(data => {
        return of(data).pipe(
          withLatestFrom(this.store.select(selectIsLocalParticipantHost)),
        );
      }),
      filter(([_, isHost]) => isHost),
      concatMap(([{ drawerName }]) => {
        return this.drawersService
          .update({ activeName: drawerName, open: true })
          .pipe(map(() => DrawerActions.updateSuccess()));
      }),
    );
  });

  setDrawerClosed$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DrawerActions.close),
      concatMap(data => {
        return of(data).pipe(
          withLatestFrom(this.store.select(selectIsLocalParticipantHost)),
        );
      }),
      filter(([_, isHost]) => isHost),
      concatMap(() => {
        return this.drawersService
          .update({ open: false })
          .pipe(map(() => DrawerActions.updateSuccess()));
      }),
    );
  });

  toggleDrawer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DrawerActions.toggle),
      concatMap(data => {
        return of(data).pipe(
          withLatestFrom(this.store.select(selectIsLocalParticipantHost)),
        );
      }),
      filter(([_, isHost]) => isHost),
      switchMap(() => {
        return this.store.select(selectIsDrawerOpen).pipe(
          switchMap(isOpen => {
            return this.drawersService
              .update({ open: isOpen })
              .pipe(map(() => DrawerActions.updateSuccess()));
          }),
        );
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private drawersService: DrawersService,
    private store: Store<AppState>,
  ) {}
}
