<div class="game-drawer" data-testid="game-drawer">
  <mat-tab-group
    class="game-drawer-tabs is-compact"
    mat-stretch-tabs
    color="accent"
    [selectedIndex]="currentTabIndex$ | async"
    (selectedIndexChange)="onTabChange($event)"
  >
    <mat-tab label="Players">
      <ng-container *ngTemplateOutlet="players"></ng-container>
    </mat-tab>

    <mat-tab label="Options">
      <ng-container *ngTemplateOutlet="options"></ng-container>
    </mat-tab>
  </mat-tab-group>

  <game-start-end-btns
    [canStartGame]="canStartNewGame()"
    [isGameActive]="canEndGame()"
    (clickNewGame)="onClickStartNewGame()"
    (clickEndGame)="onClickEndGame()"
  ></game-start-end-btns>
</div>

<ng-template #players>
  <div class="players-container">
    <pl-player-count-select
      [playerCountOptions]="formData.numPlayersOpts"
      [selection]="formData.model.numPlayers"
      (numPlayersChange)="onChangeNumPlayers($event)"
      [isDisabled]="canEndGame()"
    ></pl-player-count-select>

    <mat-form-field color="accent" class="w-100">
      <mat-label>Select player 1</mat-label>
      <mat-select
        data-testid="player-select"
        name="top-player"
        [(ngModel)]="formData.model.player1"
        (selectionChange)="onChangePlayer(1)"
        [disabled]="canEndGame()"
      >
        <mat-option
          *ngFor="let opt of formData.playerOneOpts"
          [value]="opt.value"
        >
          {{ opt.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field color="accent" class="w-100">
      <mat-label>Select player 2</mat-label>
      <mat-select
        data-testid="player-select"
        name="bottom-player"
        [(ngModel)]="formData.model.player2"
        (selectionChange)="onChangePlayer(2)"
        [disabled]="canEndGame()"
      >
        <mat-option *ngFor="let opt of formData.playerOpts" [value]="opt.value">
          {{ opt.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field
      color="accent"
      class="w-100"
      *ngIf="formData.model.numPlayers > 2"
    >
      <mat-label>Select player 3</mat-label>
      <mat-select
        data-testid="player-select"
        name="bottom-player"
        [(ngModel)]="formData.model.player3"
        (selectionChange)="onChangePlayer(3)"
        [disabled]="canEndGame()"
      >
        <mat-option *ngFor="let opt of formData.playerOpts" [value]="opt.value">
          {{ opt.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field
      color="accent"
      class="w-100"
      *ngIf="formData.model.numPlayers > 3"
    >
      <mat-label>Select player 4</mat-label>
      <mat-select
        data-testid="player-select"
        name="bottom-player"
        [(ngModel)]="formData.model.player4"
        (selectionChange)="onChangePlayer(4)"
        [disabled]="canEndGame()"
      >
        <mat-option *ngFor="let opt of formData.playerOpts" [value]="opt.value">
          {{ opt.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #options>
  <div class="options-group">
    <div class="mat-slide-toggle-group" data-testid="game-sounds-group">
      <label id="game-sounds-toggle"> Game sounds </label>
      <mat-slide-toggle
        data-testid="game-sounds-toggle"
        [ngModel]="!formData.model.mute"
        (ngModelChange)="onToggleSound($event)"
        aria-labelledby="game-sounds-toggle"
      >
        {{ formData.model.mute ? 'Off' : 'On' }}
      </mat-slide-toggle>
    </div>
  </div>

  <div class="options-group">
    <label id="choose-board-theme-label">Board theme</label>

    <mat-radio-group
      aria-labelledby="choose-board-theme-label"
      class="radio-buttons"
      color="primary"
      data-testid="board-theme-radio-group"
      [(ngModel)]="formData.model.boardTheme"
    >
      <mat-radio-button
        *ngFor="let option of formData.boardThemeOpts"
        class="radio-bg-button"
        data-testid="board-theme-radio-button"
        [value]="option.value"
        [checked]="option.value === formData.model.boardTheme"
        [disabled]="canEndGame() && option.value !== formData.model.boardTheme"
      >
        <span class="radio-bg-label">
          <img
            class="radio-bg-preview"
            [src]="option.thumbnailUrl"
            *ngIf="option.thumbnailUrl"
          />
          {{ option.label }}
        </span>
      </mat-radio-button>
    </mat-radio-group>
  </div>
</ng-template>
