import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@root/src/app/store';
import { Observable } from 'rxjs';
import { selectIsTEProvider } from '../../freemium-status/store';

@Component({
  selector: 'pl-new-documentation-drawer',
  templateUrl: './new-documentation-drawer.component.html',
  styleUrls: ['./new-documentation-drawer.component.less'],
})
export class NewDocumentationDrawerComponent implements OnInit {
  isTEUser$: Observable<boolean>;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.isTEUser$ = this.store.select(selectIsTEProvider);
  }
}
