import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';

import { PLButtonModule, PLIconModule, PLInputModule } from '@root/index';
import { PLApiLocationsService } from '@common/services/api';

import { PLDocumentationClientsComponent } from './pl-documentation-clients.component';
import { PLDocumentationClientComponent } from './pl-documentation-client/pl-documentation-client.component';
import { PLDocumentationMetricsComponent } from './pl-documentation-metrics/pl-documentation-metrics.component';
import { PLDocumentationMetricPointComponent } from './pl-documentation-metric-point/pl-documentation-metric-point.component';
import { PLDocumentationNotesComponent } from './pl-documentation-notes/pl-documentation-notes.component';
import { PLRoomDocumentationService } from './pl-room-documentation.service';
import { PLCommonComponentsModule } from '@root/src/app/common/components';
import { MatButtonModule } from '@angular/material/button';
import { PLDocumentationErrorsComponent } from './pl-documentation-errors/pl-documentation-errors.component';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatRadioModule,
    PLButtonModule,
    PLCommonComponentsModule,
    PLIconModule,
    PLInputModule,
    MatButtonModule,
  ],
  exports: [
    PLDocumentationClientsComponent,
    PLDocumentationClientComponent,
    PLDocumentationMetricsComponent,
    PLDocumentationMetricPointComponent,
    PLDocumentationNotesComponent,
    PLDocumentationErrorsComponent,
  ],
  declarations: [
    PLDocumentationClientsComponent,
    PLDocumentationClientComponent,
    PLDocumentationMetricsComponent,
    PLDocumentationMetricPointComponent,
    PLDocumentationNotesComponent,
    PLDocumentationErrorsComponent,
  ],
  providers: [PLRoomDocumentationService, PLApiLocationsService],
})
export class PLDocumentationClientsModule {}

export { PLDocumentationClientsComponent } from './pl-documentation-clients.component';
