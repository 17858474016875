import { createAction, props } from '@ngrx/store';
import { DrawerNameType, DrawerState } from './drawers.state';

export interface DrawerOpenPayload {
  drawerName: DrawerNameType;
}

const init = createAction('/drawer/init', props<DrawerState>());
const open = createAction('/drawer/open', props<DrawerOpenPayload>());
const close = createAction('/drawer/close', props<DrawerOpenPayload>());
const toggle = createAction('/drawer/toggle');
const updateSuccess = createAction('/drawer/updateSuccess');
const setFromRemote = createAction(
  '/drawer/setFromRemote',
  props<DrawerState>(),
);

export const DrawerActions = {
  init,
  open,
  close,
  toggle,
  updateSuccess,
  setFromRemote,
};
