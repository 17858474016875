import { Component } from '@angular/core';
import {
  BoomCardsMode,
  BoomCardsService,
} from '../../../pl-boomcards/boomcards.service';
import { Observable } from 'rxjs';
import { MatRadioChange } from '@angular/material/radio';
import {
  SiteShareMode,
  SiteshareService,
} from '@root/src/app/modules/siteshare/siteshare.service';

@Component({
  selector: 'pl-boom-cards-drawer',
  templateUrl: 'boom-cards-drawer.component.html',
  styleUrls: ['./boom-cards-drawer.component.less'],
})
export class BoomCardsDrawerComponent {
  isBoomCardsActive$: Observable<boolean>;
  boomCardsMode$: Observable<BoomCardsMode>;

  constructor(
    private boomCardsService: BoomCardsService,
    private siteshareService: SiteshareService,
  ) {
    this.isBoomCardsActive$ = this.boomCardsService.isBoomCardsActive$;
    this.boomCardsMode$ = this.boomCardsService.boomCardsMode$;
  }

  toggleBoomCards(mode: BoomCardsMode, active: boolean) {
    const isStandardMode = mode === BoomCardsMode.standard;
    this.siteshareService.toggleSiteshare(
      isStandardMode && active,
      SiteShareMode.BOOMCARDS,
    );
    this.boomCardsService.toggleBoomCards(active);
  }

  onModeChanged(ev: MatRadioChange) {
    this.boomCardsService.setBoomcardsMode(ev.value);
  }
}
