import moment from 'moment';
import { Injectable } from '@angular/core';
import { tap, switchMap, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { PLUrlsService, PLHttpService } from '@common/services/pl-http';
import { AppState } from '@app/store';
import { RoomConferenceService } from '@room/conference';
import { CurrentUserModel } from '@common/models/CurrentUserModel';
import { RoomnameModel } from '@common/models/RoomnameModel';
import { FirebaseModel } from '@common/models/firebase/FirebaseModel';

export const RECORD_STREAM_ID = 'SESSION_RECORD_SCREENSHARE';

interface ToboxRecordingProperties {
  archiveId: string;
  providerId: string;
  clientIDs: string[];
  record: any;
}
@Injectable({ providedIn: 'root' })
export class TokboxRecordService {
  tokboxIsRecording = false;
  currentArchiveId = null;
  tokboxApiKey = '';
  sessionId = '';
  videoUrl = '';
  archiveName = '';
  tokboxEnvironment = 'enterprise';

  lastInitError = {
    code: '',
    message: '',
  };

  screensharing: boolean;
  appRef: any;

  constructor(
    private plHttp: PLHttpService,
    private currentUserModel: CurrentUserModel,
    roomnameModel: RoomnameModel,
    private plUrls: PLUrlsService,
    private firebaseModel: FirebaseModel,
    private ngrxStoreService: Store<AppState>,
    private conferenceService: RoomConferenceService,
  ) {
    this.tokboxApiKey = roomnameModel.value.tokbox_api_key;
    this.sessionId = roomnameModel.value.tokbox_session_id;
    this.tokboxEnvironment = roomnameModel.value.tokbox_environment;

    this.appRef = this.firebaseModel.getFirebaseRef('app');

    this.checkForRecording();
  }

  checkForRecording = () => {
    if (!this.currentUserModel.user.isClinicianOrExternalProvider()) {
      return;
    }
    this.appRef.once('value', snapshot => {
      const val = snapshot.val();
      if (val.tokboxIsRecording) {
        const recordingProps: ToboxRecordingProperties =
          val.tokboxRecordingProperties;
        if (
          recordingProps &&
          recordingProps.providerId === this.currentUserModel.user.uuid
        ) {
          this.getRecording(recordingProps);
        }
      }
    });
  };
  getRecording(recordingProps: ToboxRecordingProperties) {
    this.getTokboxArchive(recordingProps.archiveId).subscribe(
      (archive: any) => {
        if (archive.status === 'started' || archive.status === 'paused') {
          this.stopTokboxRecording(
            recordingProps.archiveId,
            recordingProps.clientIDs,
            recordingProps.record,
          ).subscribe(
            () => this.handleRecoverySuccess(recordingProps),
            error => {
              this.handleRecoveryFailure(recordingProps, error);
            },
          );
        } else if (
          archive.status === 'available' ||
          archive.status === 'uploaded'
        ) {
          this.postRecordingResult(
            archive,
            recordingProps.clientIDs,
            recordingProps.record,
          ).subscribe(
            () => this.handleRecoverySuccess(recordingProps),
            error => {
              this.handleRecoveryFailure(recordingProps, error);
            },
          );
        } else if (
          archive.status === 'expired' ||
          archive.status === 'failed'
        ) {
          console.log('Failing to recover existing failed archive: ', archive);
          this.handleRecoveryFailure(
            recordingProps,
            'Archive ' + archive.status,
          );
        }
      },
    );
  }

  handleScreenshareToggle = payload => {
    if (payload.screensharing) {
      this.startScreensharing();
    } else {
      this.conferenceService.stopScreenshare(RECORD_STREAM_ID);
      this.screensharing = false;
    }
  };

  setTokboxIsRecording = value => {
    this.appRef.update({
      tokboxIsRecording: value,
    });
  };

  /**
   * value should be: { clientIDs: payload.clientIDs, record: payload.record }
   */
  setTokboxRecordingProperties = value => {
    this.appRef.update({
      tokboxRecordingProperties: value,
    });
  };

  handleRecordToggle = payload => {
    this.tokboxIsRecording = payload.recording;
    this.setTokboxIsRecording(payload.recording);
    if (payload.recording) {
      this.startTokboxRecording().subscribe(archiveId => {
        this.setTokboxRecordingProperties({
          archiveId,
          clientIDs: payload.clientIDs,
          record: payload.record,
          providerId: this.currentUserModel.user.uuid,
        });
      });
    } else {
      this.stopTokboxRecording(
        this.currentArchiveId,
        payload.clientIDs,
        payload.record,
      ).subscribe();
    }
  };

  private handleRecoveryFailure(recordingProps: any, error: any): any {
    this.setTokboxIsRecording(false);
    this.setTokboxRecordingProperties(null);
    this.ngrxStoreService.dispatch({
      type: 'TOKBOX_RECORDING_SALVAGE',
      payload: {
        error,
        success: false,
        clientIds: recordingProps.clientIDs,
        archiveId: recordingProps.archiveId,
      },
    });
  }

  private handleRecoverySuccess(recordingProps: any): any {
    this.setTokboxIsRecording(false);
    this.setTokboxRecordingProperties(null);
    this.ngrxStoreService.dispatch({
      type: 'TOKBOX_RECORDING_SALVAGE',
      payload: {
        success: true,
        clientIds: recordingProps.clientIDs,
        archiveId: recordingProps.archiveId,
      },
    });
  }

  setLayoutClassesForStream = (streamId: string, classes: string[]) => {
    this.videoUrl = `${this.plUrls.urls.video}`;
    return this.httpPut(`${this.videoUrl}session/${this.sessionId}/stream`, {
      items: [
        {
          id: streamId,
          layoutClassList: classes,
        },
      ],
    }).pipe(
      tap({
        error: error => {
          console.error('setLayoutClassesForStream error: ', error);
        },
      }),
    );
  };

  startScreensharing = () => {
    this.conferenceService
      .startScreenshare(RECORD_STREAM_ID)
      .pipe(
        switchMap(() => {
          const streamId =
            this.conferenceService.getProviderId(RECORD_STREAM_ID);
          return this.setLayoutClassesForStream(streamId, ['screenshareVideo']);
        }),
      )
      .subscribe(
        () => {
          this.ngrxStoreService.dispatch({
            type: 'TOKBOX_SCREENSHARE_UPDATE',
            payload: {
              success: true,
            },
          });
        },
        error => {
          this.ngrxStoreService.dispatch({
            type: 'TOKBOX_SCREENSHARE_UPDATE',
            payload: {
              error,
              success: false,
            },
          });
        },
      );
    this.screensharing = true;
  };

  startTokboxRecording = () => {
    this.videoUrl = `${this.plUrls.urls.video}`;
    this.archiveName =
      this.currentUserModel.user.username + moment.utc().format();

    const stylesheet = `archive {
                position: relative;
                margin:0;
                width: 1280px;
                height: 720px;
                overflow: hidden;
            }
            stream {
                display: block;
                width: 1%;
                height: 1%;
            }
            stream.screenshareVideo {
                width: 100%;
                height: 100%;
            }`;

    return this.httpPost(this.videoUrl + 'archive/', {
      sessionId: this.sessionId,
      uid: this.archiveName,
      hasAudio: true,
      hasVideo: true,
      layout: {
        stylesheet,
        type: 'custom',
      },
      outputMode: 'composed',
      resolution: '1280x720',
    }).pipe(
      map((result: any) => {
        this.currentArchiveId = result.id;
        return this.currentArchiveId;
      }),
      tap({
        error: error => {
          console.error('startTokboxRecording error: ', error);
        },
      }),
    );
  };

  stopTokboxRecording = (archiveId, clientIDs, record) => {
    this.videoUrl = `${this.plUrls.urls.video}`;
    return this.httpPost(`${this.videoUrl}archive/${archiveId}/stop`, {
      uid: this.archiveName,
    }).pipe(
      switchMap(result =>
        this.getTokboxArchive(archiveId).pipe(
          switchMap(() => this.postRecordingResult(result, clientIDs, record)),
        ),
      ),
      tap({
        error: error => {
          console.error('stopTokboxRecording error: ', error);
        },
      }),
    );
  };

  postRecordingResult(recordingResult: any, clientIDs: string[], record: any) {
    const recordingUrl = this.plUrls.urls.recording;
    const clientList = clientIDs.map(id => {
      return { client: id };
    });
    return this.httpPost(recordingUrl, {
      clients: clientList,
      provider: this.currentUserModel.user.uuid,
      record: record.uuid,
      status: 'stopped',
      tokbox_env_origin: 'enterprise',
      tokbox_archive_id: recordingResult.id,
    });
  }

  getTokboxArchive(archiveId: string) {
    this.videoUrl = `${this.plUrls.urls.video}`;
    return this.httpGet(`${this.videoUrl}archive/${archiveId}`);
  }

  private httpGet(url: string) {
    return this.httpReq({
      url,
      method: 'GET',
    });
  }

  private httpPost(url: string, body: any) {
    return this.httpReq({
      url,
      body,
      method: 'POST',
    });
  }

  private httpPut(url: string, body: any) {
    return this.httpReq({
      url,
      body,
      method: 'PUT',
    });
  }

  private httpReq(options: {
    url: string;
    method: 'GET' | 'POST' | 'PUT';
    body?: any;
    headers?: any;
  }) {
    return this.plHttp.go(
      {
        headers: {
          'X-Tokbox-Env': this.tokboxEnvironment,
        },
        ...options,
      },
      null,
      { suppressError: true },
    );
  }
}
