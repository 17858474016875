import { Component, Input } from '@angular/core';
import { PLVendorGameService } from './vendor-games';

@Component({
  selector: 'pl-game',
  templateUrl: './pl-game.component.html',
  styleUrls: ['./pl-game.component.less'],
})
export class PLGameComponent {
  @Input() public activity;

  constructor(private vendorGameService: PLVendorGameService) {}

  isVendorGame() {
    return this.vendorGameService.isVendorGame(this.activity);
  }
}
