<div data-testid="design-system-common">
  <div class="demo-group">
    <header class="demo-group-header">
      <h2 class="heading-2">Drawer Items</h2>
    </header>
    <div class="demo-row">
      <div class="demo-col">
        <div class="dark-theme drawer">
          <!-- Suppress this until the Documentation update is done -->
          <!-- <div>
            <header class="demo-group-header">
              <h3 class="heading-2">Client Appointments Select</h3>
            </header>

            <pl-client-appointments-select></pl-client-appointments-select>
          </div> -->
          <div>
            <header class="demo-group-header">
              <h3 class="heading-2">Alert Message</h3>
            </header>
            <pl-alert-message>An important alert message.</pl-alert-message>
          </div>
          <div>
            <header class="demo-group-header">
              <h3 class="heading-2">Card Display Options</h3>
            </header>
            <pl-card-display-options></pl-card-display-options>
          </div>
          <div>
            <header class="demo-group-header">
              <h3 class="heading-2">Player Count Select</h3>
            </header>
            <pl-player-count-select
              [playerCountOptions]="playerCountOptions"
              [selection]="numPlayers"
            ></pl-player-count-select>
          </div>
        </div>
      </div>

      <div class="demo-col">
        <div class="dark-theme drawer">
          <div>
            <header class="demo-group-header">
              <h3 class="heading-2">Sort Menu</h3>
            </header>

            <pl-sort-menu
              [currentSortOption]="currentSortOption"
              [sortOptions]="sortOptions"
              (sortOptionSelected)="sortOrderChanged($event)"
            ></pl-sort-menu>
          </div>
          <div>
            <header class="demo-group-header">
              <h3 class="heading-2">Document Item</h3>
            </header>

            <pl-document-item
              [document]="demoDocument"
              [allowDeleteCurrent]="true"
            ></pl-document-item>
          </div>
          <div class="confirm-alert-demo">
            <header class="demo-group-header">
              <h3 class="heading-2">Confirm Alert Component</h3>
            </header>
            <button mat-button color="accent" (click)="showPlConfirmAlert()">
              <mat-icon>check_circle</mat-icon>
              Confirm Alert
            </button>

            <pl-confirm-alert
              *ngIf="showConfirmAlert"
              class="mt-3"
              (click)="$event.stopPropagation()"
              (cancel)="cancelAlert()"
              (confirm)="confirmAlert()"
            >
              Are you sure you want to do this thing?
            </pl-confirm-alert>
          </div>
        </div>
      </div>

      <div class="demo-col">
        <div class="demo-group">
          <header class="demo-group-header">
            <h2 class="heading-2">Color Picker</h2>
          </header>
          <div class="color-container">
            <pl-colorpicker></pl-colorpicker>
          </div>
        </div>
        <div class="demo-group">
          <header class="demo-group-header">
            <h2 class="heading-2">Confirm Component</h2>
          </header>
          <button mat-flat-button color="primary" (click)="showPlConfirm()">
            <mat-icon> open_in_new</mat-icon>
            Confirm
          </button>
        </div>
        <div class="demo-group">
          <header class="demo-group-header">
            <h2 class="heading-2">Loader</h2>
          </header>
          <pl-loader></pl-loader>
        </div>
        <div class="demo-group">
          <header class="demo-group-header">
            <h2 class="heading-2">Hijack</h2>
          </header>

          <mat-slide-toggle [(ngModel)]="showHijack" color="accent"
            >Show Hijack</mat-slide-toggle
          >
        </div>
      </div>
    </div>
  </div>
</div>
<pl-hijack-drawer *ngIf="showHijack"></pl-hijack-drawer>
