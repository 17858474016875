import { Component, Input } from '@angular/core';

@Component({
  selector: 'overview-instructions',
  templateUrl: './overview-instructions.component.html',
  styleUrls: ['./overview-instructions.component.less'],
})
export class PLClueOverviewInstructionsComponent {
  @Input() showClueInstruction: boolean;
  @Input() showClueOverview: boolean;
}
