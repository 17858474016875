<div class="backgrounds">
  <div class="backgrounds__content">
    <p class="backgrounds__text" *ngIf="isPreviewAvailable">
      Choose a scene background. You can show a complete example to everyone in
      the Room with the Preview scene button.
    </p>

    <mat-radio-group
      aria-labelledby="choose-background-radio-group"
      class="backgrounds__radio-buttons"
      color="primary"
      data-testid="backgrounds-radio-group"
      [value]="selectedSceneName"
      (change)="selectedSceneNameChange.emit($event.value)"
    >
      <mat-radio-button
        *ngFor="let option of sceneOptions"
        class="backgrounds__radio-button"
        data-testid="backgrounds-radio-button"
        [value]="option.value"
        [checked]="option.value === selectedSceneName"
      >
        <span class="radio-label">
          <img
            class="radio-bg-preview"
            [src]="option.backgroundImageSrc"
            *ngIf="option.backgroundImageSrc"
          />
          {{ option.label }}
        </span>
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <button
    *ngIf="isPreviewAvailable"
    (click)="togglePreview.emit()"
    mat-button
    class="backgrounds__preview-button"
    data-testid="toggle-preview-button"
  >
    <mat-icon class="material-icons-outlined">preview</mat-icon>
    {{ isPreviewEnabled ? 'Hide preview' : 'Preview scene' }}
  </button>
</div>
