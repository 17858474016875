<mat-tab-group>
  <mat-tab label="Audio">
    <ng-template matTabContent>
      <div class="padding-t padding-xlarge-b padding-xlarge-lr">
        <pl-audio-settings></pl-audio-settings>
      </div>
    </ng-template>
  </mat-tab>
  <mat-tab label="Video">
    <ng-template matTabContent>
      <div class="padding-t padding-xlarge-b padding-xlarge-lr">
        <pl-video-settings></pl-video-settings>
      </div>
    </ng-template>
  </mat-tab>
</mat-tab-group>
