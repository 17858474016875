<div class="pdf-assessment-drawer" data-testid="activity-pdf-drawer">
  <mat-tab-group
    class="pdf-assessment-tabs is-compact"
    mat-stretch-tabs
    color="accent"
    (selectedIndexChange)="onTabChange($event)"
  >
    <mat-tab label="Pages">
      <pl-pdf-drawer-pages
        [currentPage]="currentPage"
        [totalPages]="totalPages"
        [actualPageNumbers]="studentPages"
        [hasThumbs]="hasThumbs"
        [thumbs]="thumbs"
        [thumbnailIcon]="hasInstructions ? 'assessment-instructions' : null"
        (goToPage)="gotoPage($event)"
      ></pl-pdf-drawer-pages>
    </mat-tab>

    <mat-tab label="Display">
      <pl-pdf-drawer-display
        [scale]="currentScale"
        [totalPages]="totalPages"
        [canZoom]="true"
        (zoom)="zoomTo($event)"
      >
        <div class="instructions-controls" *ngIf="hasInstructions">
          <h3 class="instructions-controls__title">Assessment instructions</h3>
          <p class="instructions-controls__text">
            Instructions will be displayed on top of each relevant page but are
            visible only to you.
          </p>

          <div
            class="slide-toggle-group"
            data-testid="assessment-overlay-group"
          >
            <label
              id="assessment-overlay-toggle"
              class="slide-toggle-group__label"
            >
              Overlay
            </label>
            <mat-slide-toggle
              data-testid="assessment-overlay-toggle"
              [ngModel]="areInstructionsVisible"
              (toggleChange)="toggleInstructions()"
              aria-labelledby="assessment-overlay-toggle"
            >
              {{ areInstructionsVisible ? 'On' : 'Off' }}
            </mat-slide-toggle>
          </div>

          <div
            class="slider-group"
            data-testid="assessment-opacity-group"
            [class.is-disabled]="!areInstructionsVisible"
          >
            <div class="slider-group__label-container">
              <label class="slider-group__label" id="assessment-opacity-slider">
                Opacity
              </label>
              <span class="slider-group__value">
                {{ instructionsOpacity * 100 }}%
              </span>
            </div>
            <mat-slider
              [min]="0"
              [max]="1"
              [step]="0.1"
              [tickInterval]="'auto'"
              [ngModel]="instructionsOpacity"
              (input)="setInstructionsOpacity($event.value)"
              [disabled]="!areInstructionsVisible"
              aria-labelledby="assessment-opacity-slider"
              class="slider-group__slider"
            ></mat-slider>
          </div>
        </div>
      </pl-pdf-drawer-display>
    </mat-tab>
  </mat-tab-group>

  <pl-pdf-drawer-paginator
    [page]="currentPage"
    [totalPages]="totalPages"
    (pageSelected)="gotoPage($event)"
  ></pl-pdf-drawer-paginator>
</div>
