import { Component, OnInit } from '@angular/core';
import { PLConfirmService } from '@root/src/app/common/components/pl-confirm/pl-confirm.service';

@Component({
  selector: 'pl-design-system-page',
  templateUrl: './design-system-page.component.html',
  styleUrls: ['./design-system-page.component.scss'],
})
export class DesignSystemPageComponent implements OnInit {
  showIcons = false;
  isDarkThemeEnabled = false;

  constructor(private plConfirmService: PLConfirmService) {}

  onToggleDarkTheme(isDarkThemeEnabled: boolean): void {
    this.isDarkThemeEnabled = isDarkThemeEnabled;
    this.applyTheme();
  }

  ngOnInit() {
    this.applyTheme();
    document.documentElement.classList.add('scroll-enabled');
  }

  applyTheme() {
    if (this.isDarkThemeEnabled) {
      document.body.classList.add('dark-theme');
    } else {
      document.body.classList.remove('dark-theme');
    }
  }

  openConfirmDialog() {
    this.plConfirmService.show({
      header: 'Are you sure?',
      content: 'Lorem ipsum <strong>dolor</strong> sit amet.',
    });
  }
}
