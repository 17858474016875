import { Injectable } from '@angular/core';
@Injectable()
export class RoomClickService {
  methods = {
    drawer: {
      dragStart: () => {
        this.setShapesNonInteractive();

        $('.whiteboard').css('pointer-events', 'none');
        $('widgets-board *').css('pointer-events', 'none');
      },

      dragEnd: () => {
        this.setShapesInteractive();

        $('.whiteboard').css('pointer-events', '');
        $('widgets-board *').css('pointer-events', '');
      },
    },

    workspace: {
      dragStart: () => {
        this.setShapesNonInteractive();

        $('.whiteboard').css('pointer-events', 'none');
        $('widgets-board *').css('pointer-events', 'none');
      },

      dragEnd: () => {
        this.setShapesInteractive();

        $('.whiteboard').css('pointer-events', '');
        $('widgets-board *').css('pointer-events', '');
      },
    },
  };

  setShapesInteractive = () => {
    $('whiteboard, whiteboard .whiteboard-content').css(
      'pointer-events',
      'auto',
    );
    $('whiteboard .whiteboard-content *').css('pointer-events', 'auto');
  }

  setShapesNonInteractive = () => {
    $('whiteboard, whiteboard .whiteboard-content').css(
      'pointer-events',
      'none',
    );
    $('whiteboard .whiteboard-content *').css('pointer-events', 'none');
  }

  setWidgetboardInteractive = () => {
    $('widgets-board').css('pointer-events', 'auto');
  }

  setWidgetboardNonInteractive = () => {
    $('widgets-board').css('pointer-events', 'none');
  }

  dragstart = () => {
    console.log(
      '[RoomClickService] Drag start, disable pointer events on svg and widget container',
    );
    this.setShapesNonInteractive();
    this.setWidgetboardInteractive();

    $('widgets-board').addClass('interactive');
  }

  dragend = () => {
    console.log(
      '[RoomClickService] Drag end, enable pointer events on svg and widget container',
    );
    this.setShapesInteractive();
    this.setWidgetboardNonInteractive();
    $('widgets-board').removeClass('interactive');
  }

  trigger = (type, methodName) => {
    const method = this.methods[type];
    if (!method) {
      return;
    }
    if (method[methodName]) {
      method[methodName]();
    }
  }
}
