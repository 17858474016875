import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PLPDFViewerComponent } from './pdf-viewer.component';
import { PLIconModule, PLInputModule } from '@root/index';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { PDFActivityComponent } from './pdf-activity.component';
import { PLCommonComponentsModule } from '@root/src/app/common/components';

const components = [PLPDFViewerComponent, PDFActivityComponent];

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    PLInputModule,
    FormsModule,
    PLIconModule,
    PLCommonComponentsModule,
    MatMenuModule,
  ],
  exports: [...components],
  declarations: [...components],
  providers: [],
})
export class PLPDFActivityModule {}
