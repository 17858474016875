import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Activity } from '../../room/app/store';

import {
  SortType,
  SortOption,
  sortOptionsDict,
} from '@common/components/sort-menu/sort-menu.component';

@Component({
  selector: 'pl-queue-activities',
  templateUrl: './pl-queue-activities.component.html',
  styleUrls: ['./pl-queue-activities.component.less'],
})
export class PLQueueActivitiesComponent implements OnChanges {
  @Input() activities: Record<string, Activity>;
  @Input() activitiesOrder: string[];

  @Output() readonly openActivity = new EventEmitter<{ activityId: string }>();
  @Output() readonly removeActivity = new EventEmitter<{
    activityId: string;
  }>();
  @Output() readonly orderChanged = new EventEmitter<string[]>();
  @ViewChild('activitiesList') activitiesList: ElementRef;

  currentSortOption: SortOption = sortOptionsDict[SortType.Custom];
  currentActivitiesOrder: string[];

  sortOptions: SortOption[] = [
    sortOptionsDict[SortType.Custom],
    sortOptionsDict[SortType.Alphabetical],
    sortOptionsDict[SortType.ReverseAlphabetical],
  ];

  readonly PAGE_LENGTH = 100;
  currentPage = 1;
  displayOrder: string[] = [];
  displayItems: any = {};
  totalPages: number;

  get SortType() {
    return SortType;
  }

  ngOnChanges(changes: any) {
    if (changes.activitiesOrder) {
      this.currentActivitiesOrder = [...this.activitiesOrder];

      this.sortOrderChanged(sortOptionsDict[SortType.Custom]);
    }
  }
  incrementPage(value: number) {
    this.currentPage += value;
    this.paginate();
    this.activitiesList.nativeElement.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  private paginate() {
    const start = (this.currentPage - 1) * this.PAGE_LENGTH;
    const end = start + this.PAGE_LENGTH;
    this.displayOrder = this.currentActivitiesOrder.slice(start, end);
    this.displayItems = {};
    this.displayOrder.forEach(
      activityId =>
        (this.displayItems[activityId] = this.activities[activityId]),
    );

    this.totalPages = Math.ceil(
      this.currentActivitiesOrder.length / this.PAGE_LENGTH,
    );
  }

  sortOrderChanged(option: SortOption) {
    this.currentSortOption = option;
    switch (option.value) {
      case SortType.Custom:
        this.currentActivitiesOrder = [...this.activitiesOrder];
        break;
      case SortType.Alphabetical:
        this.currentActivitiesOrder = [...this.activitiesOrder].sort((a, b) => {
          const aName = this.activities[a].name;
          const bName = this.activities[b].name;
          return aName.localeCompare(bName);
        });
        break;
      case SortType.ReverseAlphabetical:
        this.currentActivitiesOrder = [...this.activitiesOrder].sort((a, b) => {
          const aName = this.activities[a].name;
          const bName = this.activities[b].name;
          return bName.localeCompare(aName);
        });
        break;
    }
    this.currentPage = 1;
    this.paginate();
  }

  onActivityDrop(event: CdkDragDrop<any[]>) {
    const newOrder = [...this.activitiesOrder];
    moveItemInArray(newOrder, event.previousIndex, event.currentIndex);
    this.orderChanged.emit(newOrder);
  }

  handleOpenActivity(activityId: string) {
    this.openActivity.emit({ activityId });
  }

  handleRemoveActivity(activityId: string) {
    this.removeActivity.emit({ activityId });
  }
}
