export enum DrawerNames {
  document = 'document',
  teamWrite = 'teamWrite',
  roomOverlay = 'roomOverlay',
  contentSharing = 'contentSharing',
  activities = 'activities',
  chat = 'chat',
  animation = 'animation',
  sessionRecord = 'sessionRecord',
  widget = 'widget',
  games = 'games',
}

export type DrawerNameType = keyof typeof DrawerNames;

export interface DrawerState {
  open: boolean;
  activeName: DrawerNameType;
}

export const initialState: DrawerState = {
  open: false,
  activeName: 'chat',
};
