<div class="game-drawer">
  <button mat-button class="instructions-button" (click)="toggleInstruction()">
    <mat-icon class="material-icons-outlined">preview</mat-icon>
    {{ showCandyLandInstruction ? 'Hide instructions' : 'Show instructions' }}
  </button>

  <div class="game-drawer__content">
    <button
      mat-stroked-button
      color="primary"
      class="w-100"
      [disabled]="undoBtnDisabled"
      (click)="restoreCastlePiece()"
    >
      Restore castle piece
    </button>
  </div>

  <game-start-end-btns
    [canStartGame]="enableStartGameBtn"
    [isGameActive]="isGameActive"
    (clickNewGame)="onClickStartNewGame()"
    (clickEndGame)="onClickEndGame()"
  ></game-start-end-btns>
</div>
