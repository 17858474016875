import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import {
  map,
  exhaustMap,
  concatMap,
  withLatestFrom,
  catchError,
  take,
  mergeMap,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Update } from '@common/firebase/firebase-collection';
import { RoomActions } from '@room/store';
import { RoomConferenceService } from '@root/src/app/modules/room/conference/room-conference.service';
import { ConferenceRTService } from '@room/conference/conference-rt.service';
import { AppActions, LayoutMode } from '@room/app/store';
import { filterSessionReady } from '@room/session/store';
import { selectConferenceStreamsInSession } from '../conference.selectors';
import { StreamLike, StreamType } from '../conference.model';
import { ConferenceActions } from '../conference.actions';

@Injectable()
export class ConferenceEffects {
  setConferenceConfig$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RoomActions.setSuccess),
      mergeMap(({ data }) => {
        const { tokbox_api_key, tokbox_session_id, tokbox_token } = data;

        this.conferenceService.setConfig({
          name: 'Video Conference',
          tokbox: {
            apiKey: tokbox_api_key,
            conferenceId: tokbox_session_id,
            connectionToken: tokbox_token,
          },
        });
        return this.waitForSession().pipe(map(() => ConferenceActions.init()));
      }),
    );
  });

  initConferenceProvider$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConferenceActions.init),
      exhaustMap(() => {
        return this.conferenceService.init().pipe(
          map(() => ConferenceActions.initSuccess()),
          catchError(error => {
            const errorObj = {
              error,
            };
            console.error('CONFERENCE_INIT', errorObj);
            return of(ConferenceActions.initError(errorObj));
          }),
        );
      }),
    );
  });

  setConferenceStatus$ = createEffect(() => {
    return this.conferenceService
      .getStatus()
      .pipe(map(status => ConferenceActions.setConferenceStatus({ status })));
  });

  layoutModeChange$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppActions.setLayoutMode),
      concatMap(({ layoutMode }) => {
        return of(layoutMode).pipe(
          withLatestFrom(this.store.select(selectConferenceStreamsInSession)),
        );
      }),
      concatMap(([layoutMode, streams]) => {
        const data: Record<string, Update<StreamLike>> = {};
        streams.forEach(stream => {
          let isPromoted = false;
          if (layoutMode === LayoutMode.grid) {
            isPromoted = true;
          } else if (
            layoutMode === LayoutMode.jumbotron &&
            stream.isLocal &&
            stream.type === StreamType.primary
          ) {
            isPromoted = true;
          }
          data[stream.id] = {
            isPromoted,
          };
        });
        return this.conferenceRTService.updateMultipleStreams(data).pipe(
          map(() => ConferenceActions.setLayoutModeSuccess({ layoutMode })),
          catchError(error => {
            const errorObj = {
              layoutMode,
              error,
            };
            console.error('SET_LAYOUT_MODE', errorObj);
            return of(ConferenceActions.setLayoutModeError(errorObj));
          }),
        );
      }),
    );
  });

  private waitForSession() {
    return this.store.pipe(filterSessionReady, take(1));
  }

  constructor(
    private actions$: Actions,
    private conferenceService: RoomConferenceService,
    private conferenceRTService: ConferenceRTService,
    private store: Store<AppState>,
  ) {}
}
