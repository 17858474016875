<div class="sort-menu">
  <button
    mat-button
    class="menu-trigger"
    [matMenuTriggerFor]="sortMenu"
    (click)="sortMenuOpen = true"
    [disabled]="disabled">
    <span class="menu-button-label">
      <span>Sort by:</span>
      <span class="bold">{{ currentSortOption.label }}</span>
      <mat-icon *ngIf="!sortMenuOpen">arrow_drop_down</mat-icon>
      <mat-icon *ngIf="sortMenuOpen">arrow_drop_up</mat-icon>
    </span>
  </button>
  <mat-menu
    #sortMenu="matMenu"
    xPosition="after"
    yPosition="below"
    backdropClass="is-dark"
    class="compact-menu-panel"
    (closed)="sortMenuOpen = false">
    <button
      mat-menu-item
      *ngFor="let option of sortOptions"
      (click)="sortOptionSelectedHandler(option)">
      <span>{{ option.label }}</span>
    </button>
  </mat-menu>
</div>
