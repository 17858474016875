<div class="pl-menu">
  <button
    class="pl-menu-button"
    mat-button
    [ngClass]="t.menuOpen ? 'open' : 'closed'"
    #t="matMenuTrigger"
    [matMenuTriggerFor]="myMenu">
    <span *ngIf="selectedCount === 0">Select {{ itemName }}(s)</span>
    <span *ngIf="selectedCount === 1">1 {{ itemName }} selected</span>
    <span *ngIf="selectedCount > 1"
      >{{ selectedCount }} {{ itemName }}s selected</span
    >
    <pl-icon-new [name]="'arrow_drop_down'"></pl-icon-new>
  </button>

  <mat-menu #myMenu="matMenu" backdropClass="is-dark" class="pl-menu-panel">
    <div class="pl-menu-content">
      <mat-list class="custom-select-options-container">
        <mat-list-item *ngFor="let option of selectionOptions">
          <mat-checkbox
            class="custom-check"
            [checked]="option.selected"
            (click)="$event.stopPropagation()"
            (change)="optionClicked($event, option)"
            >{{ option.label }}</mat-checkbox
          >
        </mat-list-item>
      </mat-list>

      <!-- Custom content for the bottom of the button panel goes here -->
      <ng-content></ng-content>
    </div>
  </mat-menu>
</div>
