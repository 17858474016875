<div class="pl-board-games-controls" *ngIf="game">
  <mat-tab-group mat-stretch-tabs class="is-compact h-100" color="accent">
    <mat-tab label="Players">
      <div class="pieces tab-content fade-in p-3">
        <p>Click to add player tokens to the board</p>

        <div
          class="pieces-list"
          *ngIf="currentPieceSetups"
          [class.is-inactive]="!openedGame || !isGameActive"
        >
          <pl-piece-select-button
            *ngFor="let pieceSetup of currentPieceSetups; trackBy: trackByPiece"
            (pieceClickedEvent)="pieceClicked($event)"
            [pieceSetup]="pieceSetup"
          >
          </pl-piece-select-button>
        </div>

        <button
          mat-stroked-button
          color="primary"
          class="w-100"
          [disabled]="!totalUsedPieceCount"
          (click)="clearBoard()"
        >
          Clear all tokens
        </button>
      </div>
    </mat-tab>

    <mat-tab label="Saved games">
      <div class="tab-content fade-in p-3">
        <pl-loader [hidden]="!trashingGame"></pl-loader>
        <div class="instances-list" [hidden]="trashingGame">
          <div
            class="document-item-container"
            *ngFor="let document of instances"
          >
            <pl-document-item
              *ngIf="!document.trashed"
              [document]="document"
              [allowDeleteCurrent]="true"
              (loadDocument)="loadGame($event)"
              (trashDocument)="trashGame($event)"
              (restoreDocument)="restoreGame($event)"
              (deleteDocument)="deleteGame($event)"
              (nameChanged)="gameInstanceNameChanged($event)"
              [itemDisabled]="!openedGame"
            ></pl-document-item>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<game-start-end-btns
  [allDisabled]="!openedGame"
  [canStartGame]="!isGameActive"
  [isGameActive]="isGameActive"
  (clickNewGame)="newGame()"
  (clickEndGame)="endGame()"
>
</game-start-end-btns>
