<div class="drawer-recording">
  <header class="drawer-header">
    <h3 class="drawer-header__title">Session recording</h3>
  </header>

  <div class="drawer-recording__alert-info">
    Session recording will be moving. Over the next few weeks, the ability to
    record assessments will be moved directly into documentation.
  </div>

  <main class="drawer-recording__content">
    <pl-session-record-controls></pl-session-record-controls>
  </main>
</div>
