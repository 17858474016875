import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { NewDocumentationDrawerComponent } from './new-documentation-drawer.component';

import { PresenceDocumentationModule } from './presence-documentation';
import { KangaDocumentationModule } from './kanga-documentation';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    PresenceDocumentationModule,
    KangaDocumentationModule,
  ],
  exports: [NewDocumentationDrawerComponent],
  declarations: [NewDocumentationDrawerComponent],
  providers: [],
})
export class NewDocumentationDrawerModule {}
