import { NgModule } from '@angular/core';
import { PLChatDrawerComponent } from './pl-chat-drawer.component';
import { StoreModule } from '@ngrx/store';
import { reducer } from './store/reducer';
import { PLChatDrawerService } from './pl-chat-drawer.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PLPermissionsModule } from '@app/common/auth';
import { PLCommonPipesModule } from '@common/pipes';
import { PLChatMessageComponent } from './pl-chat-message/pl-chat-message.component';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    PLCommonPipesModule,
    PLPermissionsModule,
    StoreModule.forFeature('chat', reducer),
    MatIconModule,
    MatButtonModule,
    MatSlideToggleModule,
    // TODO: Change this when effect work with the commented code
    /* EffectsModule.forFeature([
            ChatEffects,
        ]), */
  ],
  exports: [PLChatDrawerComponent],
  declarations: [PLChatDrawerComponent, PLChatMessageComponent],
  providers: [PLChatDrawerService],
})
export class PLChatDrawerModule {}
