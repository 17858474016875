<div *ngIf="active" class="pl-board-games">
  <div>
    <div class="background">
      <img *ngIf="currentGameId && gameBoardImgSrc" [src]="gameBoardImgSrc" />
      <img
        *ngIf="!currentGameId && gameSplashScreenSrc && loadDone"
        [src]="gameSplashScreenSrc"
      />
    </div>
    <div #boardgame class="foreground">
      <pl-board-game-piece
        *ngFor="let piece of pieces"
        [piece]="piece"
        (pieceUpdated)="pieceUpdated($event)"
        (pieceDragged)="pieceDragEnded($event)"
      ></pl-board-game-piece>
    </div>
  </div>
</div>
