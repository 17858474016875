<div
  class="clue-board-container"
  [ngClass]="{ 'show-opacity': showClueInstruction || showClueOverview }"
>
  <overview-instructions
    *ngIf="showClueInstruction || showClueOverview"
    [showClueInstruction]="showClueInstruction"
    [showClueOverview]="showClueOverview"
  >
  </overview-instructions>

  <confidential-folder-screen
    [firebaseClueRef]="firebaseClueRef"
    [currentUser]="currentUser"
    [provider]="provider"
    *ngIf="folderScreenActive"
  >
  </confidential-folder-screen>

  <div
    *ngIf="!isGameActive && !folderScreenActive"
    class="center-logo-container"
  >
    <img src="/assets/board-games/clue_game_assets/clue_logo.svg" />
  </div>

  <div *ngIf="isGameActive" class="gameplay-wrapper">
    <div class="gameplay-container">
      <div class="gameplay-container__header">
        <div
          class="player-tag"
          [ngClass]="{ 'player-active': player.position === currentPlayerTurn }"
          *ngFor="let player of playersArr"
        >
          <img
            *ngIf="player.position === currentPlayerTurn"
            src="/assets/board-games/clue_game_assets/investigation_icon.svg"
          />
          <div class="player-tag-text">
            <h3
              *ngIf="
                player.position !== currentPlayerTurn &&
                player.position !== investigationObj.witnessPosition
              "
            >
              Player {{ player.position }}
            </h3>
            <h3 *ngIf="player.position === currentPlayerTurn">Investigator</h3>
            <h3 *ngIf="player.position === investigationObj.witnessPosition">
              Witness
            </h3>
            <p>
              {{ player.name }}
            </p>
          </div>
        </div>

        <div
          *ngIf="ghostPlayerObj && ghostPlayerObj.hasGhostPlayer"
          class="player-tag"
        >
          <div class="player-tag-text">
            <h3>Player 3</h3>
            <p>Ghost Player</p>
          </div>
        </div>
      </div>

      <div
        class="turn-based-container"
        *ngIf="
          !solveCaseActive ||
          (!currentUser.solvingTheCaseObj.isSolving &&
            !conclusionScreenActive &&
            isGameActive)
        "
      >
        <div class="w-100 pr-5">
          <div
            class="game-action-container"
            *ngIf="
              investigationObj.investigator.userId === currentUser.userId &&
              !investigationObj.isAsking &&
              !(investigationObj.witnessPosition > 0) &&
              !currentUser.disabled
            "
          >
            <h3>It's your turn</h3>
            <p class="pt-3 pb-3">What would you like to do?</p>
            <div class="action-items">
              <button (click)="startSolveCaseFlow()">Solve the case</button>
              <button (click)="updateAskWitnessFlow()">Ask for evidence</button>
            </div>
          </div>

          <div
            class="game-action-container"
            *ngIf="
              solveCaseActive &&
              !currentUser.solvingTheCaseObj.isSolving &&
              !currentUser.solvingTheCaseObj.skipSolving &&
              !currentUser.disabled
            "
          >
            <h3>Somebody is trying to solve the case!</h3>
            <p class="pt-3 pb-3">Do you want to try and solve it too?</p>
            <div class="action-items">
              <button (click)="skipSolvingUpdate()">No</button>
              <button (click)="isSolvingUpdate()">Yes</button>
            </div>
          </div>

          <div
            class="game-action-container"
            *ngIf="
              investigationObj.investigator.userId === currentUser.userId &&
              investigationObj.isAsking &&
              !investigationObj.responseReceived &&
              !currentUser.disabled
            "
          >
            <h3>Asking for evidence</h3>
            <p class="pt-3 pb-3">
              Ask player {{ investigationObj.witnessPosition }} if they have any
              two pieces of evidence.
            </p>
          </div>

          <div
            class="game-action-container"
            *ngIf="
              investigationObj.investigator.userId === currentUser.userId &&
              investigationObj.responseReceived &&
              !currentUser.disabled
            "
          >
            <h3 *ngIf="!investigationObj.witnessPosition">
              Ghost Player has evidence to share with you!
            </h3>
            <h3 *ngIf="investigationObj.witnessPosition">
              Player {{ investigationObj.witnessPosition }} has evidence to
              share with you!
            </h3>
            <p class="pt-3 pb-3">This is top secret only you can see it</p>
            <div class="action-items">
              <button (click)="endPlayerTurn()">Done</button>
            </div>
          </div>

          <div
            class="game-action-container"
            *ngIf="
              investigationObj.witness.userId === currentUser.userId &&
              investigationObj.isAsking &&
              !cardReveal.isCardSelected
            "
          >
            <h3>Do you have the evidence?</h3>
            <p class="pt-3 pb-3">
              If yes, don’t say which one out loud, click on one of the evidence
              cards to show them. If you don’t have either of the cards, click
              “No matching evidence”
            </p>
            <div class="action-items">
              <button (click)="updateAskWitnessFlow()">
                No matching evidence
              </button>
            </div>
          </div>

          <div
            class="game-action-container"
            *ngIf="
              investigationObj.witness.userId === currentUser.userId &&
              investigationObj.isAsking &&
              cardReveal.isCardSelected
            "
          >
            <h3 *ngIf="investigationObj.responseReceived">
              Investigator is reviewing your card
            </h3>
            <div *ngIf="!investigationObj.responseReceived">
              <h3 class="mb-3">You have selected a card</h3>
              <div class="action-items">
                <button (click)="cancelSelectedCard()">Cancel</button>
                <button (click)="revealCard()">Reveal my card</button>
              </div>
            </div>
          </div>
        </div>
        <div
          *ngIf="
            investigationObj.investigator.userId === currentUser.userId &&
            investigationObj.responseReceived
          "
          class="card-in-hand card-revealed"
        >
          <img
            class="w-100"
            src="{{ investigationObj.responseReceived.card.icon }}"
          />
          <h4>{{ investigationObj.responseReceived.card.name }}</h4>
        </div>

        <player-hand
          class="player-cards-container"
          *ngIf="currentUser && currentUser.confidentialCards"
          [cardReveal]="cardReveal"
          [currentUser]="currentUser"
          [investigationObj]="investigationObj"
        >
        </player-hand>
      </div>

      <submit-answers
        *ngIf="
          solveCaseActive &&
          currentUser.solvingTheCaseObj.isSolving &&
          !conclusionScreenActive &&
          !currentUser.disabled
        "
        [firebaseClueRef]="firebaseClueRef"
        [playersArr]="playersArr"
        [currentUser]="currentUser"
        [cardReveal]="cardReveal"
        [investigationObj]="investigationObj"
        [conclusionScreenActive]="conclusionScreenActive"
      >
      </submit-answers>

      <conclusion-screen
        [firebaseClueRef]="firebaseClueRef"
        [playersArr]="playersArr"
        [currentUser]="currentUser"
        [endGameObj]="endGameObj"
        [provider]="provider"
        [currentPlayerTurn]="currentPlayerTurn"
        *ngIf="
          conclusionScreenActive ||
          (endGameObj.winnersList && endGameObj.winnersList.length > 0)
        "
      >
      </conclusion-screen>
    </div>

    <!-- Side list of investigation items -->
    <investigation-items
      class="investigation-items-container"
      [firebaseClueRef]="firebaseClueRef"
      [currentUser]="currentUser"
      [playersArr]="playersArr"
    >
    </investigation-items>
  </div>
</div>
