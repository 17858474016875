import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { User } from '@modules/user/user.model';
import { selectAuth } from '@modules/user/store';
import { AppState } from '@app/store';
import { PLUrlsService } from '@common/services/pl-http';
import { PLConfirmService } from '@root/src/app/common/components/pl-confirm/pl-confirm.service';
import {
  TokboxRecordService,
  TokboxRecordingSalvagedData,
  ScreenshareUpdate,
} from './tokbox-record.service';
import {
  ClientAppointment,
  ClientGQLData,
} from '@room/pl-drawers/new-documentation-drawer/documentation.models';

@Component({
  selector: 'pl-session-recording',
  templateUrl: './session-recording.component.html',
  styleUrls: ['./session-recording.component.less'],
})
export class SessionRecordingComponent implements OnInit, OnChanges, OnDestroy {
  @Input() clientData: ClientGQLData;
  @Input() clientAppointment: ClientAppointment;
  canRecordClient = false;

  @ViewChild('screensharePreview', { static: false })
  screenshareElement: ElementRef;

  usersSubscription: Subscription;
  tokboxSubscription: Subscription;

  sessionRecordDrawerActive: boolean;

  providerUserId = '';
  currentUser: User;
  urls: { [key: string]: string } = {};
  waitingForScreenshare: boolean;

  private subscriptions: Subscription[] = [];
  isRecording$: BehaviorSubject<boolean>;
  waitingToStartRecording: boolean;
  waitingToStopRecording: boolean;

  constructor(
    private store: Store<AppState>,
    private plUrls: PLUrlsService,
    private plConfirmService: PLConfirmService,
    private tokboxRecordService: TokboxRecordService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.clientData) {
      this.canRecordClient = this.clientData?.recordingAllowed;
    }
  }
  ngOnInit(): void {
    this.subscriptions.push(this.subscribeToUsers());
    this.subscribeToTokbox();
    this.urls = this.plUrls.urls;

    this.isRecording$ = this.tokboxRecordService.isRecording$;
    this.isRecording$.subscribe((isRecording: boolean) => {
      if (isRecording) {
        this.waitingToStartRecording = false;
      } else {
        this.waitingToStopRecording = false;
      }
    });
  }

  private subscribeToUsers(): Subscription {
    return this.store
      .select(selectAuth)
      .subscribe(({ isAuthenticated, user }) => {
        if (isAuthenticated) {
          this.currentUser = user;
          this.providerUserId = this.currentUser.uuid;
        }
      });
  }
  private subscribeToTokbox() {
    this.subscriptions.push(
      this.tokboxRecordService.recordingSalvaged$.subscribe(
        (data: TokboxRecordingSalvagedData) => {
          this.handleRecordingSalvaged(data);
        },
      ),
    );
    this.subscriptions.push(
      this.tokboxRecordService.screenshareUpdate$.subscribe(
        (data: ScreenshareUpdate) => {
          if (this.waitingForScreenshare) {
            this.waitingForScreenshare = false;
            if (data.success) {
              this.startRecording();
            } else {
              this.waitingToStartRecording = false;
            }
          }
        },
      ),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  handleRecordingSalvaged(data: TokboxRecordingSalvagedData) {
    if (data.success) {
      this.showSalvageSuccessNotification(data.clientIds);
    } else if (data.error) {
      this.showSalvageFailureNotification(data.error);
    }
  }

  showSalvageSuccessNotification(clientIDs) {
    this.plConfirmService.show({
      header: 'Session recording found',
      content: `<div>
                    A previous session recording was found and saved, you will find it in your client’s Events tab.
                    </div>`,
      confirmLabel: `View client events`,
      cancelLabel: 'Cancel',
      onConfirm: () => {
        window.open(
          `${this.urls.eduClientsFE}/client/${clientIDs[0]}/reports`,
          '_blank',
        );
      },
    });
  }
  showSalvageFailureNotification(error) {
    this.plConfirmService.show({
      header: 'Session recording found',
      content: `<div>
                        A previous session recording was found but we were unable to recover it.
                    </div>
                    <div style="margin: 10px 0 10px 0;">
                        <strong>Error Message</strong>
                    </div>
                    <div>
                        <textarea rows="4" cols="60">${error}</textarea>
                    </div>`,
      confirmLabel: 'OK',
      cancelLabel: 'Cancel',
    });
  }
  showStartRecordingDialog() {
    this.waitingToStartRecording = true;
    this.plConfirmService.show({
      header: 'Start session recording',
      content: `<div>
                        You are about to begin recording the contents of your Room.
                        <strong>After clicking the Record Room button below,</strong> you will be prompted to select what screen to share.
                        <strong><em>Be sure to select your browser window of your Room.</em></strong>
                        When you are done recording, click <strong>Stop Recording.</strong>
                        The recording will be available in your client’s Events view.
                    </div>`,
      confirmLabel: `Record Room`,
      cancelLabel: 'Cancel',
      onConfirm: () => {
        this.captureRoom();
      },
      onCancel: () => {
        setTimeout(() => {
          if (!this.waitingForScreenshare) {
            this.waitingToStartRecording = false;
          }
        }, 200);
      },
    });
  }

  captureRoom() {
    this.waitingForScreenshare = true;
    this.tokboxRecordService.startScreensharing();
  }

  private startRecording() {
    this.tokboxRecordService.startRecording(
      [this.clientData.id],
      this.clientAppointment.record,
    );
  }

  stopRecording() {
    this.waitingToStopRecording = true;
    this.tokboxRecordService.stopCurrentRecording();
  }
}
