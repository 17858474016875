import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';

export interface SelectionOption {
  value: string;
  label: string;
  selected: boolean;
}

@Component({
  selector: 'pl-multi-select-menu',
  templateUrl: 'multi-select-menu.component.html',
  styleUrls: ['multi-select-menu.component.less'],
})
export class MultiSelectMenuComponent implements OnChanges {
  @Input() selectionOptions: SelectionOption[] = [];
  @Input() itemName: string = 'item';
  @Output() readonly selectionChanged = new EventEmitter<SelectionOption[]>();

  selectedCount = 0;

  ngOnChanges() {
    this.updateSelectedCount();
  }

  private updateSelectedCount() {
    this.selectedCount = this.selectionOptions.filter(
      option => option.selected,
    ).length;
  }

  optionClicked(event, option: SelectionOption) {
    option.selected = event.checked;
    this.updateSelectedCount();
    this.selectionChanged.emit(this.selectionOptions);
  }
}
