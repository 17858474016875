import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { PresenceDocumentationComponent } from './presence-documentation.component';
import { PresenceDocumentationAppointmentSelectionComponent } from './presence-documentation-appointment-selection/presence-documentation-appointment-selection.component';
import { PresenceDocumentationAppointmentItemComponent } from './presence-documentation-appointment-item/presence-documentation-appointment-item.component';
import { ServiceDetailsComponent } from './service-details/service-details.component';
import { ClientServicesService } from './client-services.service';
import { ClientAppointmentsService } from './client-appointments.service';
import { MetricsTrackingComponent } from './metrics-tracking/metrics-tracking.component';
import { MetricItemComponent } from './metrics-tracking/metric-item/metric-item.component';

import { SessionRecordingModule } from '@room/session-recording';

import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PLCommonComponentsModule } from '@common/components';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { PresenceAppointmentItemNotesComponent } from './presence-documentation-appointment-item/appointment-item-notes/presence-appointment-item-notes.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    PLCommonComponentsModule,
    MatButtonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatRadioModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatTooltipModule,
    MatButtonToggleModule,
    SessionRecordingModule,
  ],
  exports: [PresenceDocumentationComponent],
  declarations: [
    PresenceDocumentationComponent,
    PresenceDocumentationAppointmentSelectionComponent,
    PresenceDocumentationAppointmentItemComponent,
    PresenceAppointmentItemNotesComponent,
    ServiceDetailsComponent,
    MetricsTrackingComponent,
    MetricItemComponent,
  ],
  providers: [ClientServicesService, ClientAppointmentsService],
})
export class PresenceDocumentationModule {}
