import { Ng2StateDeclaration } from '@uirouter/angular';

export const roomFutureState: Ng2StateDeclaration = {
  name: 'room.**',
  url: '/:clinician_username',
  params: {},
  loadChildren: () => import('./').then(mod => mod.RoomModule),
};

const invalidObserverFutureState: Ng2StateDeclaration = {
  name: 'invalid_observer.**',
  url: '/observe/:clinician_username',
  loadChildren: () => import('./').then(mod => mod.RoomModule),
};

export const observeFutureState: Ng2StateDeclaration = {
  name: 'observe.**',
  url: '/observe/:clinician_username',
  loadChildren: () => import('./').then(mod => mod.RoomModule),
};

export const roomFutureStates = [
  roomFutureState,
  invalidObserverFutureState,
  observeFutureState,
];
