import { Component, OnDestroy, OnInit, Input, NgZone } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import {
  Player,
  Cards,
  InvestigationObj,
  CardReveal,
  EndGameObj,
  GhostObj,
} from '../clue-store';
import * as ClueActions from '../clue-store/actions';
import * as ClueSelectors from '../clue-store/selectors';
import { CurrentUserModel } from '@root/src/app/common/models/CurrentUserModel';
import { ClueService } from '../pl-clue.service';
import { FirebaseService } from '@root/src/app/common/firebase/firebase.service';

@Component({
  selector: 'pl-clue-board',
  templateUrl: './pl-clue-board.component.html',
  styleUrls: ['./pl-clue-board.component.less'],
})
export class PLClueBoardComponent implements OnInit, OnDestroy {
  @Input() activity: any = {};
  private subscriptions: Subscription[] = [];
  public isGameActive: boolean = false;
  public folderScreenActive: boolean = false;
  public playersArr: Player[];
  public currentPlayerTurn: number;
  public showClueInstruction: boolean;
  public showClueOverview: boolean;
  public cards: Cards;
  public currentUser: Player;
  public firebaseClueRef;
  public investigationObj: InvestigationObj;
  public cardReveal: CardReveal;
  public solveCaseActive: boolean;
  public provider: string;
  public conclusionScreenActive: boolean;
  public endGameObj: EndGameObj;
  public ghostPlayerObj: GhostObj;

  constructor(
    public firebaseService: FirebaseService,
    private store: Store<any>,
    private currentUserModel: CurrentUserModel,
    private zone: NgZone,
    private clueService: ClueService,
  ) {}

  ngOnInit(): void {
    const fbPath = `activities/queues/items/${this.activity.queueId}/items/${this.activity.activityId}`;
    this.firebaseClueRef = this.firebaseService.getRoomRef(fbPath);
    this.currentUser = this.initCurrentUser();
    this.initFireBase();
    this.subscriptions.push(this.getShowInstructionStatus());
    this.subscriptions.push(this.getShowOverviewStatus());
    this.subscriptions.push(this.subscribeToGetCards());
    this.subscriptions.push(this.subscribeToCurrentUser());
    this.subscriptions.push(this.subscribeToCardRevealState());
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.firebaseClueRef.update({
      showInstruction: false,
      showOverview: false,
    });
    this.store.dispatch(ClueActions.closeShowOverview());
    this.store.dispatch(ClueActions.closeShowInstruction());
  }

  public startSolveCaseFlow() {
    const tempCurrentUser = {
      ...this.currentUser,
      solvingTheCaseObj: {
        ...this.currentUser.solvingTheCaseObj,
        isSolving: true,
      },
    };

    const updatedPlayersArr = this.playersArr.map(player => {
      let tempPlayer = {
        ...player,
        solvingTheCaseObj: { ...player.solvingTheCaseObj },
      };
      if (tempPlayer.userId === tempCurrentUser.userId) {
        tempPlayer.solvingTheCaseObj.isSolving = true;
        return tempPlayer;
      }
      return tempPlayer;
    });

    this.store.dispatch(
      ClueActions.updateCurrentUser({ currentUser: tempCurrentUser }),
    );
    this.firebaseClueRef.update({
      solveCaseActive: true,
      playersArr: updatedPlayersArr,
    });
  }

  public skipSolvingUpdate() {
    const tempCurrentUser = {
      ...this.currentUser,
      solvingTheCaseObj: {
        ...this.currentUser.solvingTheCaseObj,
        skipSolving: true,
      },
    };

    const updatedPlayersArr = this.playersArr.map(player => {
      let tempPlayer = {
        ...player,
        solvingTheCaseObj: { ...player.solvingTheCaseObj },
      };
      if (tempPlayer.userId === tempCurrentUser.userId) {
        tempPlayer.solvingTheCaseObj.skipSolving = true;
        return tempPlayer;
      }
      return tempPlayer;
    });

    const updatedEndGameObj = { ...this.endGameObj };
    if (!updatedEndGameObj.playersSkipped) {
      updatedEndGameObj.playersSkipped = [];
    }
    updatedEndGameObj.playersSkipped = [
      ...updatedEndGameObj.playersSkipped,
      tempCurrentUser,
    ];

    this.firebaseClueRef.update({
      endGameObj: updatedEndGameObj,
      playersArr: updatedPlayersArr,
    });
    this.store.dispatch(
      ClueActions.updateCurrentUser({ currentUser: tempCurrentUser }),
    );
  }

  public isSolvingUpdate() {
    const tempCurrentUser = {
      ...this.currentUser,
      solvingTheCaseObj: {
        ...this.currentUser.solvingTheCaseObj,
        isSolving: true,
      },
    };

    const updatedPlayersArr = this.playersArr.map(player => {
      let tempPlayer = {
        ...player,
        solvingTheCaseObj: { ...player.solvingTheCaseObj },
      };
      if (tempPlayer.userId === tempCurrentUser.userId) {
        tempPlayer.solvingTheCaseObj.isSolving = true;
        return tempPlayer;
      }
      return tempPlayer;
    });
    this.firebaseClueRef.update({ playersArr: updatedPlayersArr });
    this.store.dispatch(
      ClueActions.updateCurrentUser({ currentUser: tempCurrentUser }),
    );
  }

  public updateAskWitnessFlow(): any {
    const { witnessPosition, investigatorPosition } = this.investigationObj;
    const newObj = { ...this.investigationObj };

    if (!witnessPosition) {
      newObj.witnessPosition = investigatorPosition + 1;
    } else {
      newObj.witnessPosition += 1;
    }

    if (newObj.witnessPosition > this.playersArr.length) {
      newObj.witnessPosition = 1;
    }

    if (newObj.witnessPosition === newObj.investigatorPosition) {
      if (this.ghostPlayerObj && this.ghostPlayerObj.hasGhostPlayer) {
        this.revealGhostCard(newObj);
        return;
      } else {
        this.endPlayerTurn();
        return;
      }
    }

    newObj.witness = this.playersArr.filter(player => {
      return player.position === newObj.witnessPosition;
    })[0];

    newObj.isAsking = true;
    this.firebaseClueRef.update({ investigationObj: newObj });
  }

  public noEvidence(): any {
    this.investigationObj.isAsking = false;
    this.firebaseClueRef.update({ investigationObj: this.investigationObj });
  }

  public endPlayerTurn() {
    let nextPlayer = this.currentPlayerTurn + 1;
    if (nextPlayer > this.playersArr.length) {
      nextPlayer = 1;
    }

    const updatedInvestigationObj = this.clueService.updateInvestigationObj(
      this.playersArr,
      nextPlayer,
    );

    if (!updatedInvestigationObj) {
      this.firebaseClueRef.update({ clearCardRevealObj: true });
    } else {
      this.firebaseClueRef.update({
        investigationObj: updatedInvestigationObj.investigationObj,
        playerTurn: updatedInvestigationObj.playerTurn,
        clearCardRevealObj: true,
      });
    }
  }

  public cancelSelectedCard() {
    this.firebaseClueRef.update({ clearCardRevealObj: true });
  }

  // ===================================================================================================================
  // ======================================== PRIVATE METHODS BELOW ====================================================
  // ===================================================================================================================

  private initCurrentUser() {
    return {
      id: null,
      position: null,
      userId: null,
      name: '',
      confidentialCards: [
        { name: '', icon: '', status: '' },
        { name: '', icon: '', status: '' },
        { name: '', icon: '', status: '' },
      ],
      cardList: {},
      disabled: false,
      solvingTheCaseObj: {
        isSolving: false,
        skipSolving: false,
        answersSubmitted: false,
        answersObj: {
          suspect: { name: '', icon: '', status: '' },
          method: { name: '', icon: '', status: '' },
          location: { name: '', icon: '', status: '' },
        },
      },
    };
  }

  private disableCheck(disabledPlayers = []) {
    let isDisabled = [];

    if (disabledPlayers.length > 0) {
      isDisabled = disabledPlayers.filter(player => {
        if (player.userId === this.currentUserModel.user.uuid) {
          return player;
        }
      });
    }

    if (isDisabled.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  private initFireBase() {
    this.firebaseClueRef.on('value', data => {
      this.zone.run(() => {
        const val = data.val();

        if (val === null) {
          return;
        }

        const {
          playersArr,
          disabledPlayersArr,
          gameActive,
          playerTurn,
          showInstruction,
          showOverview,
          investigationObj,
          solveCaseActive,
          folderScreenActive,
          provider,
          conclusionScreenActive,
          endGameObj,
          ghostPlayerObj,
          clearCardRevealObj,
        } = val;

        if (this.disableCheck(disabledPlayersArr)) {
          return;
        }

        if (!this.isGameActive && gameActive) {
          this.currentUser = this.initCurrentUser();
        }

        if (!this.currentUser.userId && playersArr && playersArr.length > 0) {
          this.currentUser = this.setCurrentUserToStore(playersArr);
          if (this.currentUser.userId === null) {
            return;
          }
        }

        this.playersArr = playersArr;
        this.currentPlayerTurn = playerTurn;
        this.showClueInstruction = showInstruction;
        this.showClueOverview = showOverview;
        this.investigationObj = investigationObj;
        this.solveCaseActive = solveCaseActive;
        this.folderScreenActive = folderScreenActive;
        this.provider = provider;
        this.conclusionScreenActive = conclusionScreenActive;
        this.endGameObj = endGameObj;
        this.ghostPlayerObj = ghostPlayerObj;

        if (this.isGameActive !== gameActive) {
          this.isGameActive = gameActive;
          if (!this.isGameActive) {
            this.currentUser = this.initCurrentUser();
          }
        }

        if (clearCardRevealObj) {
          this.clearCardReveal();
        }

        if (this.isGameActive) {
          this.clueService.updateCurrentUser(this.playersArr, this.currentUser);
        }

        if (this.solveCaseActive && !this.conclusionScreenActive) {
          this.checkWinConditions();
        }
      });
    });
  }

  private subscribeToCardRevealState(): Subscription {
    return this.store
      .pipe(select(ClueSelectors.clueCardReveal))
      .subscribe(data => {
        this.cardReveal = data;
      });
  }

  public revealCard() {
    const updatedInvestigationObj = { ...this.investigationObj };
    updatedInvestigationObj.responseReceived = { ...this.cardReveal };

    this.firebaseClueRef.update({ investigationObj: updatedInvestigationObj });
  }

  private setCurrentUserToStore(playersArr): Player {
    const fromPlayerArr = playersArr.filter(player => {
      return player.userId === this.currentUserModel.user.uuid;
    });

    if (fromPlayerArr.length === 0) {
      return this.initCurrentUser();
    }

    this.store.dispatch(
      ClueActions.updateCurrentUser({ currentUser: fromPlayerArr[0] }),
    );
    return fromPlayerArr[0];
  }

  private subscribeToCurrentUser(): Subscription {
    return this.store
      .pipe(select(ClueSelectors.clueCurrentUser))
      .subscribe(user => {
        this.currentUser = user;
      });
  }

  private subscribeToGetCards(): Subscription {
    return this.store.pipe(select(ClueSelectors.clueCards)).subscribe(cards => {
      this.cards = cards;
    });
  }

  private getShowOverviewStatus(): Subscription {
    return this.store
      .pipe(select(ClueSelectors.clueInstructionActive))
      .subscribe(data => {
        this.showClueOverview = data;
      });
  }

  private getShowInstructionStatus(): Subscription {
    return this.store
      .pipe(select(ClueSelectors.clueInstructionActive))
      .subscribe(data => {
        this.showClueInstruction = data;
      });
  }

  private clearCardReveal() {
    const clearCard = {
      card: {
        name: '',
        icon: '',
      },
      isCardSelected: false,
      revealCard: false,
    };

    this.store.dispatch(
      ClueActions.updateCardReveal({ cardReveal: clearCard }),
    );
    this.firebaseClueRef.update({ clearCardRevealObj: false });
  }

  private checkWinConditions() {
    let numOfPlayers = 0;
    let numOfSubmissions = 0;
    let waitingForPlayers: boolean;

    this.playersArr.map(player => {
      const { position, solvingTheCaseObj } = player;
      const { isSolving, answersSubmitted, skipSolving } = solvingTheCaseObj;

      if (position > 0 && isSolving) {
        numOfPlayers++;
      }
      if (answersSubmitted) {
        numOfSubmissions++;
      }
      if (!isSolving && !skipSolving && !answersSubmitted) {
        waitingForPlayers = true;
      }
    });

    if (waitingForPlayers) {
      return;
    }

    if (numOfPlayers === numOfSubmissions) {
      let updatedEndGameObj = { ...this.endGameObj };

      const filteringWinner = this.playersArr.filter(player => {
        const { suspect, method, location } =
          player.solvingTheCaseObj.answersObj;
        const { winningHand } = this.endGameObj;
        if (
          winningHand.suspect.name === suspect.name &&
          winningHand.method.name === method.name &&
          winningHand.location.name === location.name
        ) {
          return player;
        }
      });

      updatedEndGameObj.winnersList = filteringWinner;

      if (
        filteringWinner.length === 0 &&
        this.endGameObj.playersSkipped &&
        this.endGameObj.playersSkipped.length === 1
      ) {
        updatedEndGameObj.defaultWin = true;
        updatedEndGameObj.winnersList = [...this.endGameObj.playersSkipped];
      }

      if (
        updatedEndGameObj.winnersList.length > 0 ||
        ((!this.endGameObj.playersSkipped ||
          this.endGameObj.playersSkipped.length < 1) &&
          updatedEndGameObj.winnersList.length === 0)
      ) {
        updatedEndGameObj.showWinningCards = true;
      }

      this.firebaseClueRef.update({
        endGameObj: updatedEndGameObj,
        conclusionScreenActive: true,
      });
    }
  }

  private revealGhostCard(newInvestigatorObj) {
    const randomizedCard = Math.floor(
      Math.random() * this.ghostPlayerObj.ghostCardsArr.length,
    );

    newInvestigatorObj.responseReceived = {
      card: this.ghostPlayerObj.ghostCardsArr[randomizedCard],
      isCardSelected: true,
      revealCard: true,
    };
    newInvestigatorObj.witnessPosition = null;
    newInvestigatorObj.witness = {
      id: '',
      position: 0,
      name: '',
      userId: '',
      confidentialCards: {},
      cardList: [],
      disabled: false,
      solvingTheCaseObj: {
        isSolving: false,
        skipSolving: false,
        answersSubmitted: false,
        answersObj: {
          suspect: { name: '', icon: '', status: '' },
          method: { name: '', icon: '', status: '' },
          location: { name: '', icon: '', status: '' },
        },
      },
    };

    this.firebaseClueRef.update({ investigationObj: newInvestigatorObj });
  }
}
