import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { PLGoFishComponent } from './pl-go-fish.component';
import { PLGoFishDrawerComponent } from './pl-go-fish-drawer.component';
import { PLCardGameComponent } from '../pl-card-game/pl-card-game.component';
import { GameStartEndBtnsModule } from '../game-start-end-btns';
import { GameInstructionsControlModule } from '../game-instructions-control';
import { MatButtonModule } from '@angular/material/button';
import { PLCarouselModule } from './pl-carousel';
import { PLCommonComponentsModule } from '@common/components';

@NgModule({
  imports: [
    CommonModule,
    PLCarouselModule,
    MatIconModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatButtonModule,
    GameStartEndBtnsModule,
    GameInstructionsControlModule,
    PLCommonComponentsModule,
  ],
  exports: [PLGoFishComponent, PLGoFishDrawerComponent, PLCardGameComponent],
  declarations: [
    PLGoFishComponent,
    PLGoFishDrawerComponent,
    PLCardGameComponent,
  ],
  providers: [],
})
export class PLGoFishModule {}
