<div class="answers-container">
  <div *ngIf="!isSelecting" class="three-cards-container">
    <div
      (click)="showSuspects()"
      class="h-100 card-container"
      [ngClass]="{ 'selected-card-container': answersObj.suspect.name }"
    >
      <div class="card" *ngIf="answersObj.suspect.name">
        <img
          (click)="selectSuspect($event)"
          src="{{ answersObj.suspect.icon }}"
        />
        <p class="selected-card-name">
          {{ answersObj.suspect.name }}
        </p>
      </div>
      <img
        *ngIf="!answersObj.suspect.name"
        class="text-center h-100 ml-3 mr-3"
        [ngClass]="{ 'default-img': answersObj.suspect.name }"
        src="/assets/board-games/clue_game_assets/add_suspect.svg"
      />
    </div>

    <div
      (click)="showLocations()"
      class="h-100 card-container"
      [ngClass]="{ 'selected-card-container': answersObj.location.name }"
    >
      <div class="card" *ngIf="answersObj.location.name">
        <img
          (click)="selectSuspect($event)"
          src="{{ answersObj.location.icon }}"
        />
        <p class="selected-card-name">
          {{ answersObj.location.name }}
        </p>
      </div>
      <img
        *ngIf="!answersObj.location.name"
        class="text-center h-100 ml-3 mr-3"
        [ngClass]="{ 'default-img': answersObj.location.name }"
        src="/assets/board-games/clue_game_assets/add_location.svg"
      />
    </div>

    <div
      (click)="showMethods()"
      class="h-100 card-container"
      [ngClass]="{ 'selected-card-container': answersObj.method.name }"
    >
      <div class="card" *ngIf="answersObj.method.name">
        <img
          (click)="selectSuspect($event)"
          src="{{ answersObj.method.icon }}"
        />
        <p class="selected-card-name">
          {{ answersObj.method.name }}
        </p>
      </div>
      <img
        *ngIf="!answersObj.method.name"
        class="text-center h-100 ml-3 mr-3"
        [ngClass]="{ 'default-img': answersObj.method.name }"
        src="/assets/board-games/clue_game_assets/add_method.svg"
      />
    </div>
  </div>

  <div class="action-container" *ngIf="!isSelecting">
    <ng-container *ngIf="!canSubmit">
      <h2>Ready to solve the case?</h2>
      <p>
        To complete your accusation, select one SUSPECT, one LOCATION, and one
        METHOD from the list. Once you have your choices made, click the Submit.
      </p>
    </ng-container>

    <div
      *ngIf="canSubmit && !currentUser.solvingTheCaseObj.answersSubmitted"
      class="submit-answers-btn-container"
    >
      <button (click)="submitAnswers()" class="submit-answers-btn">
        Submit
      </button>
    </div>

    <ng-container
      *ngIf="
        currentUser.solvingTheCaseObj.answersSubmitted &&
        !conclusionScreenActive
      "
    >
      <h2>Solve the case</h2>
      <p>
        Once everyone’s responses have been recorded, all investigators will see
        who correctly identified the suspect, method, and location of the crime!
      </p>
    </ng-container>
  </div>

  <div *ngIf="isSelecting" class="selection-screen">
    <div class="action-container is-row">
      <h2>Select a {{ category }}</h2>
      <img
        class="hover-click"
        (click)="cancelSelection()"
        src="/assets/board-games/clue_game_assets/icon_red_x_cancel.svg"
      />
    </div>
    <div class="cards-container">
      <ng-container *ngIf="category === 'Suspect'">
        <div
          class="selection-card"
          *ngFor="let card of currentUser.cardList.suspects"
        >
          <img
            (click)="selectSuspect($event)"
            class="w-100 hover-click"
            src="{{ card.icon }}"
            [attr.card-name]="card.name"
            [attr.card-icon]="card.icon"
          />
          <p>
            {{ card.name }}
          </p>
        </div>
      </ng-container>

      <ng-container *ngIf="category === 'Method'">
        <div
          class="selection-card"
          *ngFor="let card of currentUser.cardList.methods"
        >
          <img
            (click)="selectMethod($event)"
            class="w-100 hover-click"
            src="{{ card.icon }}"
            [attr.card-name]="card.name"
            [attr.card-icon]="card.icon"
          />
          <p>
            {{ card.name }}
          </p>
        </div>
      </ng-container>

      <ng-container *ngIf="category === 'Location'">
        <div
          class="selection-card"
          *ngFor="let card of currentUser.cardList.locations"
        >
          <img
            (click)="selectLocation($event)"
            class="w-100 hover-click"
            src="{{ card.icon }}"
            [attr.card-name]="card.name"
            [attr.card-icon]="card.icon"
          />
          <p>
            {{ card.name }}
          </p>
        </div>
      </ng-container>
    </div>
  </div>
</div>
