import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';

import {
  SortType,
  SortOption,
  sortOptionsDict,
} from '@common/components/sort-menu/sort-menu.component';

@Component({
  selector: 'pl-queues-list',
  templateUrl: './pl-queues-list.component.html',
  styleUrls: ['./pl-queues-list.component.less'],
})
export class PLQueuesListComponent implements OnInit, OnChanges {
  @Input() queuesOrder: string[];
  @Input() queuesItems: Record<string, any>;
  @Input() isLoading: boolean;

  @Output() readonly openQueue = new EventEmitter<{ queueId: string }>();
  @Output() readonly deleteQueue = new EventEmitter<{ queueId: string }>();
  @Output() readonly editQueueName = new EventEmitter<{ queueId: string }>();
  @Output() readonly openCreateNewQueue = new EventEmitter<any>();
  @Output() readonly changeOrder = new EventEmitter<string[]>();

  queueIdBeingDeleted: string;
  currentSortOption: SortOption = sortOptionsDict[SortType.Custom];

  currentQueuesOrder: string[];

  ngOnInit() {
    this.currentQueuesOrder = [...this.getExistingQueueOrder()];
    this.sortOrderChanged(sortOptionsDict[SortType.Custom]);
  }

  ngOnChanges(changes) {
    if (changes.queuesOrder) {
      this.currentQueuesOrder = [...this.getExistingQueueOrder()];
      this.sortOrderChanged(sortOptionsDict[SortType.Custom]);
    }
  }

  sortOrderChanged(option: SortOption) {
    this.currentSortOption = option;
    switch (option.value) {
      case SortType.Custom:
        this.currentQueuesOrder = [...this.getExistingQueueOrder()];
        break;
      case SortType.Alphabetical:
        this.currentQueuesOrder = [...this.getExistingQueueOrder()].sort(
          (a, b) => {
            const aName = this.queuesItems[a].name;
            const bName = this.queuesItems[b].name;
            return aName.localeCompare(bName);
          },
        );
        break;
      case SortType.ReverseAlphabetical:
        this.currentQueuesOrder = [...this.getExistingQueueOrder()].sort(
          (a, b) => {
            const aName = this.queuesItems[a].name;
            const bName = this.queuesItems[b].name;
            return bName.localeCompare(aName);
          },
        );
        break;
      case SortType.Date:
        this.currentQueuesOrder = [...this.getExistingQueueOrder()].sort(
          (a, b) => {
            const aDate = this.queuesItems[a].created;
            const bDate = this.queuesItems[b].created;
            return bDate - aDate;
          },
        );
        break;
      case SortType.ReverseDate:
        this.currentQueuesOrder = [...this.getExistingQueueOrder()].sort(
          (a, b) => {
            const aDate = this.queuesItems[a].created;
            const bDate = this.queuesItems[b].created;
            return aDate - bDate;
          },
        );
        break;
    }
  }

  get SortType() {
    return SortType;
  }

  onQueueDrop(event: CdkDragDrop<any[]>) {
    const newOrder = [...this.queuesOrder];
    moveItemInArray(newOrder, event.previousIndex, event.currentIndex);
    this.changeOrder.emit(newOrder);
  }

  renameQueue(queueId: string) {
    this.editQueueName.emit({ queueId });
  }

  attemptDeleteQueue(queueId: string) {
    this.queueIdBeingDeleted = queueId;
  }

  cancelDeleteQueue() {
    this.queueIdBeingDeleted = null;
  }

  confirmDeleteQueue(queueId: string) {
    this.queueIdBeingDeleted = null;
    this.deleteQueue.emit({ queueId });
  }

  private getExistingQueueOrder() {
    return this.queuesOrder.filter(qo => !!this.queuesItems[qo]);
  }
}
