import { Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { CurrentUserModel } from '@common/models/CurrentUserModel';
import { AppState } from '@root/src/app/store';
import { PLLessonModelService } from '@root/src/app/modules/room/pl-activity/model/lesson-model.service';
import { debounce } from 'lodash';
import { Activity } from '../../app/store';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { selectCurrentUser } from '../../../user/store';
import { selectIsLocalParticipantHost } from '../../session/store';

export enum PDFFBPaths {
  PageNum = 'pageNum',
  BookMode = 'bookMode',
  Scale = 'scale',
  PageRotation = 'pageRotation',
  ScrollXPercent = 'scrollXPercent',
  ScrollYPercent = 'scrollYPercent',
}

@Component({
  selector: 'pl-pdf-lesson-viewer',
  templateUrl: 'pdf-lesson-viewer.component.html',
  styleUrls: ['./pdf-lesson-viewer.component.less'],
})
export class PLPDFLessonViewerComponent implements OnInit, OnDestroy {
  @Input() public activity: Activity;

  private DEFAULT_PAGE_NUM = 1;
  private DEFAULT_ROTATION_DEG = 0;
  private DEFAULT_MODE = 1;

  private DEFAULT_SCALE = 1.0;
  private DEFAULT_SCROLL_X = 0;
  private DEFAULT_SCROLL_Y = 0;
  private SINGLE_PAGE_MODE = 1;
  private DBL_PAGE_EVEN_MODE = 2;
  private DBL_PAGE_ODD_MODE = 3;
  private currentPage; // initialized in FB CB
  private scale; // initialized in FB CB
  private scrollXPercent = 0; // initialized in FB CB
  private scrollYPercent = 0; // initialized in FB CB
  private boundingEl: Element;
  private subscriptions: Subscription[] = [];
  isHost = false;
  rendering = false;
  renderTask;
  loading = false;
  bookMode = this.SINGLE_PAGE_MODE;
  pageRotations: Record<string, number> = {};
  canvasWidth = 0;
  canvasHeight = 0;
  holderWidth = 0;
  holderHeight = 0;
  pageHolderWidth = 0;
  studentMode = false;
  pageHolderTop = 0;
  scrollPage = debounce(e => this.saveScrollPosition(), 20);
  isStudent$: Observable<boolean>;
  pdfUrl = '';

  constructor(
    private currentUserModel: CurrentUserModel,
    private lessonModel: PLLessonModelService,
    private store: Store<AppState>,
    private zone: NgZone,
  ) {
    this.isStudent$ = store.select(selectCurrentUser).pipe(
      map(user => {
        return user && user.groups && user.groups.indexOf('student') > -1;
      }),
    );
    this.subscriptions.push(
      this.store.select(selectIsLocalParticipantHost).subscribe(isHost => {
        this.isHost = isHost;
      }),
    );
  }

  ngOnInit() {
    const elements = document.getElementsByClassName(
      'aspect-ratio-constriction',
    );
    if (elements.length) {
      this.boundingEl = elements[0];
    }
    if (this.activity.type === 'pdf') {
      this.lessonModel.foundationLoaded.then(
        () => {
          this.initialize();
        },
        error => {
          console.error(error);
        },
      );
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  setHolderHeight() {
    this.holderHeight = this.boundingEl.clientHeight;
    this.holderWidth = this.boundingEl.clientWidth;
  }

  loadPDF(url) {
    this.loading = true;
    this.subscriptions.push(
      this.lessonModel.getProtectedContentUrl(url).subscribe(data => {
        this.pdfUrl = data.assets[url];
        this.loadState();
      }),
    );
  }

  private saveScrollPosition() {
    if (this.lessonModel.share && this.isHost) {
      this.lessonModel.saveScrollPosition(
        this.scrollXPercent,
        this.scrollYPercent,
      );
    }
  }

  private positionPageXFromPercentage(xPercent) {
    this.zone.run(() => {
      this.scrollXPercent = xPercent;
    });
  }

  private positionPageYFromPercentage(yPercent) {
    this.zone.run(() => {
      this.scrollYPercent = yPercent;
    });
  }

  private getValueFromSnap(snap, key, defaultValue) {
    const val = snap.val();
    let newVal = val || defaultValue;

    if (val !== null && typeof val === 'object') {
      newVal = val[key];
    }

    return newVal || defaultValue;
  }

  private getPageRotationsFromSnap(snap) {
    return this.getValueFromSnap(
      snap,
      PDFFBPaths.PageRotation,
      this.DEFAULT_ROTATION_DEG,
    );
  }

  private getPageNumFromSnap(snap) {
    return this.getValueFromSnap(
      snap,
      PDFFBPaths.PageNum,
      this.DEFAULT_PAGE_NUM,
    );
  }

  private getModeFromSnap(snap) {
    return this.getValueFromSnap(snap, PDFFBPaths.BookMode, this.DEFAULT_MODE);
  }

  private getScaleFromSnap(snap) {
    return this.getValueFromSnap(snap, PDFFBPaths.Scale, this.DEFAULT_SCALE);
  }

  private getScrollXFromSnap(snap) {
    this.scrollXPercent = this.getValueFromSnap(
      snap,
      PDFFBPaths.ScrollXPercent,
      this.DEFAULT_SCROLL_X,
    );
    return this.scrollXPercent;
  }

  private getScrollYFromSnap(snap) {
    this.scrollYPercent = this.getValueFromSnap(
      snap,
      PDFFBPaths.ScrollYPercent,
      this.DEFAULT_SCROLL_Y,
    );
    return this.scrollYPercent;
  }

  private handleLessonError(error) {
    this.loading = false;
    console.error('[PdfViewerDirective] activity ref load error:', error.code);
  }

  private async loadState() {
    this.lessonModel.sessionId = this.activity.session_id;
    this.lessonModel.getSessionRef(this.activity.session_id).off('value');
    this.lessonModel.getSessionRef(this.activity.session_id).on(
      'value',
      snap => {
        let newPage = this.getPageNumFromSnap(snap);
        const newScale = this.getScaleFromSnap(snap);
        const pageRotations = this.getPageRotationsFromSnap(snap);
        const newMode = this.getModeFromSnap(snap);

        if (this.bookMode !== newMode) {
          this.bookMode = newMode;

          if (newMode === this.DBL_PAGE_ODD_MODE && newPage % 2 !== 1) {
            newPage = newPage - 1;
          } else if (newMode === this.DBL_PAGE_EVEN_MODE && newPage % 2 !== 0) {
            newPage = Math.max(newPage - 1, 1);
          }
        }

        if (this.currentPage !== newPage) {
          this.currentPage = newPage;
        }

        if (this.scale !== newScale) {
          this.scale = newScale;
        }

        this.pageRotations = pageRotations;
      },
      err => this.handleLessonError(err),
    );

    this.lessonModel
      .getSessionRef(this.activity.session_id)
      .child(PDFFBPaths.ScrollXPercent)
      .on(
        'value',
        snap => {
          const xPercent = this.getScrollXFromSnap(snap);

          this.positionPageXFromPercentage(xPercent);
          this.loading = false;
        },
        err => this.handleLessonError(err),
      );

    this.lessonModel
      .getSessionRef(this.activity.session_id)
      .child(PDFFBPaths.ScrollYPercent)
      .on(
        'value',
        snap => {
          const yPercent = this.getScrollYFromSnap(snap);

          this.positionPageYFromPercentage(yPercent);
          this.loading = false;
        },
        err => this.handleLessonError(err),
      );
  }

  private initialize() {
    const fileUrl = this.lessonModel.activity.file_url;

    this.loadPDF(fileUrl);
    // Block student scrolling.
    if (this.currentUserModel.user.isInGroup('student')) {
      this.studentMode = true;
    }
  }
}
