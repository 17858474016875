<button
  class="menu-trigger"
  [matMenuTriggerFor]="menu"
  [hidden]="isHidden$ | async"
  (click)="onMenuOpen()"
  [ngClass]="{ 'menu-active': isMenuOpen }"
>
  <mat-icon>expand_more</mat-icon>
</button>
<mat-menu #menu="matMenu" backdropClass="is-dark" (closed)="onMenuClosed()">
  <button
    mat-menu-item
    (click)="vm.isRotated ? onDeRotate() : onRotate()"
    *ngIf="{ isRotated: isRotated$ | async } as vm"
  >
    <span>Rotate camera</span>
  </button>
  <button
    mat-menu-item
    (click)="vm.isMirrored ? onUnMirror() : onMirror()"
    *ngIf="{ isMirrored: isMirrored$ | async } as vm"
  >
    <span>Mirror camera</span>
  </button>
</mat-menu>
