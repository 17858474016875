<div class="margin-large-t" *ngIf="isLoading && !noteData">
  <pl-loader></pl-loader>
</div>
<div class="client-expanded padding" *ngIf="noteData">
  <div>Session start time</div>
  <div class="margin-t session-date">
    <pl-input-datepicker
      (onChange)="saveNote()"
      [(model)]="startDate"
    ></pl-input-datepicker>
  </div>
  <div class="margin-t">
    <pl-input-time
      required
      (onChange)="saveNote()"
      [(model)]="startTime"
    ></pl-input-time>
  </div>
  <div class="session-wrapper" *ngIf="noteData">
    <div class="margin-t">Session duration</div>
    <div class="margin-t">
      <pl-session-duration-select
        [disabled]="isDurationSelectDisabled()"
        (changed)="saveNote()"
        [(model)]="noteData.session_duration"
        [options]="sessionDurationOptions"
      ></pl-session-duration-select>
    </div>
    <div class="margin-t">
      <pl-input-select
        (onChange)="saveNote()"
        [(model)]="noteData.service_type"
        placeholder="Service type"
        [options]="serviceTypes"
      ></pl-input-select>
    </div>
    <div class="margin-t">
      <pl-input-select
        (onChange)="saveNote()"
        [(model)]="noteData.attendance_status"
        placeholder="Attendance status"
        [options]="attendanceStatusOptions"
      ></pl-input-select>
    </div>
    <div class="margin-t flexbox">
      <button
        pl-button
        [ngClass]="{ selected: viewMode === 'notes' }"
        class="toggle-button room-gray flex1"
        (click)="toggleNotesMetrics('notes')"
      >
        Notes
      </button>
      <button
        pl-button
        [ngClass]="{ selected: viewMode === 'metrics' }"
        class="toggle-button room-gray flex1"
        (click)="toggleNotesMetrics('metrics')"
      >
        Progress
      </button>
    </div>
    <div class="margin-t" *ngIf="viewMode === 'notes'">
      <pl-input-textarea
        (onChange)="saveNote()"
        [(model)]="noteData.note"
        maxlength="10000"
        placeholder="Add session notes"
      ></pl-input-textarea>
    </div>
    <div class="margin-t" *ngIf="viewMode === 'metrics'">
      <pl-documentation-te-metrics
        [sessionId]="noteData.uuid"
        [clientGoals]="clientGoals"
        [clientEvent]="clientEvent"
        (saveMetric)="onSaveMetric($event)"
      ></pl-documentation-te-metrics>
    </div>
    <div class="margin-t center">
      <button pl-button class="danger" (click)="onEndSession()">Done</button>
    </div>
  </div>
</div>
