import { Component, OnDestroy, OnInit } from '@angular/core';

import { BehaviorSubject, ReplaySubject, Subscription } from 'rxjs';
import { User } from '@app/modules/user/user.model';
import { AppState } from '@app/store';
import { Store } from '@ngrx/store';
import { selectAuth } from '@modules/user/store';
import { SelectionOption } from '@common/components/multi-select-menu/multi-select-menu.component';
import {
  ClientAppointment,
  DocumentationItemState,
} from '../documentation.models';
import { ClientAppointmentsService } from './client-appointments.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'pl-presence-documentation',
  templateUrl: 'presence-documentation.component.html',
  styleUrls: ['./presence-documentation.component.less'],
})
export class PresenceDocumentationComponent implements OnInit, OnDestroy {
  currentUser: User;
  selectedClientAppointments$: ReplaySubject<ClientAppointment[]>;
  isBlackoutDay$: BehaviorSubject<boolean>;
  clientAppointmentsSelectionOptions$: BehaviorSubject<SelectionOption[]>;
  subscriptions: Subscription[] = [];
  itemStates: Record<string, DocumentationItemState> = {};
  constructor(
    public appointmentsService: ClientAppointmentsService,
    private store: Store<AppState>,
  ) {
    this.selectedClientAppointments$ =
      appointmentsService.selectedAppointments$;
    this.isBlackoutDay$ = appointmentsService.isBlackoutDay$;
    this.clientAppointmentsSelectionOptions$ =
      appointmentsService.appointmentsSelectionOptions$;
    this.subscriptions.push(
      this.store.select(selectAuth).subscribe(({ isAuthenticated, user }) => {
        if (isAuthenticated) {
          this.currentUser = user;
        }
      }),
    );
    // Wait for the first set of appointments to be loaded before
    this.selectedClientAppointments$.pipe(first()).subscribe(() => {
      this.itemStates = this.appointmentsService.getPersistedItemStates();
    });
  }

  ngOnInit() {
    // Listen for tab switches and focus changes, update appointments
    // when the user comes back from their calendar.
    // We may want to use polling, but this seems to be effective.
    document.addEventListener('visibilitychange', () => {
      if (!document.hidden) {
        this.appointmentsService.refresh();
      }
    });
    window.addEventListener('focus', () => {
      this.appointmentsService.refresh();
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.appointmentsService.cleanup();
  }

  appointmentSelectionChanged(options) {
    this.appointmentsService.selectionChanged(options);
  }

  trackByAppointment(_: number, appointment: ClientAppointment) {
    return appointment.appointment.uuid;
  }

  onItemStateChange(appointmentId: string, newValue: DocumentationItemState) {
    this.itemStates[appointmentId] = newValue;
    this.appointmentsService.setPersistedItemStates(this.itemStates);
  }
}
