import { Injectable } from '@angular/core';
import { IPadSupportService } from '@common/services/ipad-support.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
declare var YT: any;
export interface YoutubePlayer {
  loadLibrary: Function;
  cueVideo: Function;
  getCurrentTime: Function;
  getDuration: Function;
  mute: Function;
  isMuted: Function;
  play: Function;
  pause: Function;
  seek: Function;
  getState: Function;
  destroy: Function;
  playerReady$: Observable<void>;
  stateChanged$: Observable<any>;
}

@Injectable()
export class PLYoutubeFactoryService {
  iPadService: IPadSupportService;

  constructor(iPadSuppportService: IPadSupportService) {
    this.iPadService = iPadSuppportService;
  }

  newYoutube = (playerDiv): YoutubePlayer => {
    const resolveLoaded = res => {};
    const loadedPromise: any = new Promise(resolveLoaded);
    const _playerDiv = playerDiv;
    let _player: YT.Player;
    let _state;
    const playerReadySource = new Subject<void>();
    const stateChangedSource = new BehaviorSubject<any>(null);

    return {
      loadLibrary: () => {
        return new Promise<void>((resolve, reject) => {
          const onPlayerError = error => {
            console.warn('YouTube Player general error', error);
            reject();
          };

          const onPlayerReady = event => {
            playerReadySource.next();
          };

          const onStateChange = event => {
            let value = null;
            switch (event.data) {
              case -1:
                value = 'unstarted';
                break;
              case 0:
                value = 'ended';
                break;
              case 1:
                value = 'playing';
                break;
              case 2:
                value = 'paused';
                break;
              case 3:
                value = 'buffering';
                break;
              case 5:
                value = 'videoQueued';
                break;
              default:
                value = null;
            }
            _state = value;
            stateChangedSource.next(value);
          };

          const createPlayer = () => {
            _player = new YT.Player(_playerDiv, {
              events: {
                onStateChange,
                onReady: onPlayerReady,
                onError: onPlayerError,
              },
              playerVars: {
                enablejsapi: 1,
                autohide: 1,
                autoplay: 1,
                playsinline: 1,
                mute: 0,
                html5: 1,
                theme: 'light',
                modestbranding: 1,
                iv_load_policy: 3,
                showinfo: 0,
                controls: 0,
                rel: 0,
              },
            });
            resolve();
          };
          if ((<any>window).YT) {
            createPlayer();
          } else {
            const head = document.head;
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = 'https://www.youtube.com/iframe_api';
            head.appendChild(script);

            window.onYouTubeIframeAPIReady = () => {
              createPlayer();
            };
          }
        });
      },

      cueVideo: videoId => {
        _player.cueVideoById({
          videoId,
        });
      },

      getCurrentTime: () => {
        return _player.getCurrentTime();
      },

      getDuration: () => {
        return _player.getDuration();
      },

      mute: value => {
        if (value) {
          _player.mute();
        } else {
          _player.unMute();
        }
      },

      isMuted: () => {
        return _player.isMuted();
      },

      play: () => {
        if (this.iPadService.isIPadStudent()) {
          setTimeout(() => {
            _player.mute();
            _player.playVideo();
            setTimeout(() => {
              _player.unMute();
            }, 1000);
          }, 200);
        } else {
          _player.playVideo();
        }
      },

      pause: () => {
        _player.pauseVideo();
      },

      seek: targetTime => {
        _player.seekTo(targetTime, true);
      },

      getState: () => {
        return _state;
      },

      destroy: () => {
        if (_player) {
          if (_player.stopVideo) {
            _player.stopVideo();
            _player.destroy();
          }
          _player = null;
        }
      },
      playerReady$: playerReadySource.asObservable(),
      stateChanged$: stateChangedSource.asObservable(),
    };
  };
}
