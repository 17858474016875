import { Component, Input, NgZone, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store';
import { selectGameCards, selectIsGameActive } from './store/go-fish.selectors';
import { FirebaseService } from '@common/firebase/firebase.service';
import {
  selectActiveParticipants,
  selectLocalParticipantId,
} from '@room/session/store';
import { concatMap, withLatestFrom } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';
import { DRFActivityModel } from '@root/src/app/common/models/DRF/DRFActivityModel.service';
import { PLHttpService, PLUrlsService } from '@app/common/services/pl-http';
import { selectCurrentUser } from '@root/src/app/modules/user/store';
import { User } from '@root/src/app/common/models/users/UserModels';

@Component({
  selector: 'pl-go-fish',
  templateUrl: './pl-go-fish.component.html',
  styleUrls: ['./pl-go-fish.component.less'],
})
export class PLGoFishComponent implements OnInit, OnDestroy {
  @Input() activity: any = {};

  players: any[] = [];
  cards: any[] = [];
  currentPlayerId = '';
  playerDeckTypes = {
    hand: 1,
    discard: 0,
    won: 0,
  };
  startNewGame: boolean;
  turnCount = 0;
  cardDisplay: string;
  dealCount: number;
  mayViewAllPlayersIds = [];
  mayEndAllPlayersTurnsIds = [];
  mayMoveAllPlayersCardsIds = [];
  mayMoveCardsOffTurn = 0;
  playerImageUrls = [];
  playerLayout = 'vertical';
  otherPlayerCardLayout = 'full';
  animateMoveCardsSeconds = 1;
  useScoreboard = 1;
  showWonOnScoreboard = 1;
  showWonCardsSeconds = 5;
  wonCardsScoreboard = {
    cards: [],
    player: {},
  };
  hideHandIfNewCards = 1;
  toggleWonDeckVisible = 1;
  showInstructions = 0;
  useSingleWonAllPlayers = 1;
  requirePreselectingPlayerForCardMove = 0;
  showHandText = 0;
  cardBackImageUrl =
    'https://lightyearassets-cdn.presencelearning.com/f7eb9896-1f12-48a1-9477-89d7e17fe941.png';
  selectedCardsMax = 2;
  selectedCardsMaxOffTurn = 1;
  skipNewDeck = 1;
  flashSeconds = 1.5;
  drawCardsMax = 1;
  sortCardsMostRecentFirst = 1;

  fbPath: string;
  fbActivity: any = {};
  possiblePlayers = [];

  private currentUser: User;
  private subscriptions: Subscription[] = [];

  constructor(
    private firebaseService: FirebaseService,
    private store: Store<AppState>,
    private zone: NgZone,
    private plHttp: PLHttpService,
    private plUrls: PLUrlsService,
  ) {}

  ngOnInit() {
    this.fbPath = `activities/queues/items/${this.activity.queueId}/items/${this.activity.activityId}`;
    this.fbActivity = this.firebaseService.getRoomRef(this.fbPath);
    this.subscriptions.push(this.subscribeToCurrentUser());
    this.subscriptions.push(this.subscribeToPersonas());
    this.initFirebase();
    this.subscriptions.push(this.subscribeToGoFish());
    this.subscriptions.push(this.subscribeToIsGameActive());
    this.subscriptions.push(this.subscribeToCards());
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  subscribeToCards(): Subscription {
    return this.store.select(selectGameCards).subscribe(cards => {
      if (cards?.length) {
        this.cards = cards.map(card => ({ ...card }));
      }
    });
  }

  subscribeToPersonas(): Subscription {
    return this.store
      .select(selectActiveParticipants)
      .pipe(
        concatMap(participants => {
          return of(participants).pipe(
            withLatestFrom(this.store.select(selectLocalParticipantId)),
          );
        }),
      )
      .subscribe(([participants, localParticipantId]) => {
        const localParticipant = participants.find(
          p => p.id === localParticipantId,
        );
        if (!localParticipant) {
          return;
        }
        this.currentPlayerId = localParticipant.userId;
      });
  }

  subscribeToIsGameActive(): Subscription {
    return this.store.select(selectIsGameActive).subscribe(isGameActive => {
      this.newGame(isGameActive);
    });
  }

  newGame(newGameVal: boolean) {
    this.startNewGame = newGameVal;
  }

  subscribeToGoFish(): Subscription {
    return this.store.select('goFishGame').subscribe((data: any) => {
      if (data.turnCount !== undefined) {
        this.turnCount = data.turnCount;
        this.saveFirebaseVal('turnCount', this.turnCount);
      }
      if (data.cardDisplay !== undefined) {
        this.cardDisplay = data.cardDisplay;
      }
      if (data.dealCount !== undefined) {
        this.dealCount = data.dealCount;
      }
      if (data.xRayOn !== undefined) {
        const indexPlayer = this.mayViewAllPlayersIds.indexOf(
          this.currentPlayerId,
        );
        if (!data.xRayOn) {
          if (indexPlayer > -1) {
            this.mayViewAllPlayersIds.splice(indexPlayer, 1);
            // Force ngChange update to fire.
            this.mayViewAllPlayersIds = [].concat(this.mayViewAllPlayersIds);
          }
        } else if (indexPlayer < 0) {
          this.mayViewAllPlayersIds.push(this.currentPlayerId);
          // Force ngChange update to fire.
          this.mayViewAllPlayersIds = [].concat(this.mayViewAllPlayersIds);
        }
      }
    });
  }

  initFirebase() {
    this.fbActivity.child('players').on('value', ref => {
      this.zone.run(() => {
        const players = [];
        const playersObj = ref.val();
        if (playersObj) {
          for (const playerId of Object.keys(playersObj)) {
            players.push(playersObj[playerId]);
          }
        }
        this.players = players;
      });
    });
    this.fbActivity.child('cards').on('value', ref => {
      this.zone.run(() => {
        const cards = ref.val();
        if (cards) {
          this.cards = cards;
        }
      });
    });
    this.fbActivity.child('cardDisplay').on('value', ref => {
      this.zone.run(() => {
        const cardDisplay = ref.val();
        if (cardDisplay) {
          this.cardDisplay = cardDisplay;
        }
      });
    });
    this.fbActivity.child('dealCount').on('value', ref => {
      this.zone.run(() => {
        const dealCount = ref.val();
        if (dealCount) {
          this.dealCount = dealCount;
        }
      });
    });
    this.fbActivity.child('deck').on('value', ref => {
      const val = ref.val();
      if (val?.id) {
        this.recordDeckUsage(val.id);
      }
    });
    this.fbActivity.child('wonCardsScoreboard').on('value', ref => {
      this.zone.run(() => {
        const wonCardsScoreboard = ref.val();
        if (wonCardsScoreboard && wonCardsScoreboard.cards !== undefined) {
          this.wonCardsScoreboard = wonCardsScoreboard;
        } else {
          this.wonCardsScoreboard = {
            cards: [],
            player: {},
          };
        }
      });
    });
    this.fbActivity.child('turnCount').on('value', ref => {
      this.zone.run(() => {
        const turnCount = ref.val();
        if (turnCount !== undefined) {
          this.turnCount = turnCount;
        }
      });
    });

    this.fbActivity.child('mayEndAllPlayersTurnsIds').on('value', ref => {
      this.zone.run(() => {
        this.mayEndAllPlayersTurnsIds = ref.val();
      });
    });
    this.fbActivity.child('mayMoveAllPlayersCardsIds').on('value', ref => {
      this.zone.run(() => {
        this.mayMoveAllPlayersCardsIds = ref.val();
      });
    });
  }

  saveFirebaseVal(key, value) {
    this.fbActivity.child(key).set(value);
  }

  playerUpdated(evt) {
    const player = evt.player;
    const data = {};
    evt.keys.forEach(key => {
      data[key] = player[key];
    });
    this.fbActivity.child('players').child(player.id).update(data);
  }

  cardsUpdated(evt) {
    this.fbActivity.child('cards').set(evt.cards);
  }

  turnCountUpdated(evt) {
    this.fbActivity.child('turnCount').set(evt.turnCount);
  }

  cardsScoreboardWonUpdated(evt) {
    this.fbActivity.child('wonCardsScoreboard').set(evt.wonCardsScoreboard);
  }

  private recordDeckUsage(deckId) {
    const activityModel = new DRFActivityModel(this.plHttp, this.plUrls);
    activityModel.setKey(deckId);
    this.subscriptions.push(
      activityModel.use(this.currentUser.token).subscribe(),
    );
  }

  private subscribeToCurrentUser() {
    return this.store.select(selectCurrentUser).subscribe((user: User) => {
      this.currentUser = user;
    });
  }
}
