<div class="documentation-te-metric" *ngIf="metric">
  <div class="flexbox">
    <div class="name flex1">
      {{ metric.description }}
    </div>
    <div class="pointer goal-description-icon">
      <pl-icon
        [svg]="'metrics'"
        class="white pointer"
        [scale]="1"
        (click)="toggleGoalDescription()"
      ></pl-icon>
    </div>
  </div>
  <div [hidden]="!showGoal" class="goal-description padding-small">
    {{ goal.goal_title }}
  </div>
  <div class="flexbox">
    <div
      class="correct"
      [class.disabled]="disabled"
      (click)="!disabled && incrementCorrect()"
    >
      <pl-icon [svg]="'check'" class="white" [scale]="0.8"></pl-icon>
    </div>
    <div
      class="wrong"
      [class.disabled]="disabled"
      (click)="!disabled && incrementWrong()"
    >
      <pl-icon [svg]="'cross'" class="white" [scale]="0.8"></pl-icon>
    </div>
    <div class="flex1 metric-ratio">
      <div class="metric-ratio-inputs">
        <div class="metric-ratio-correct">
          <pl-input-text
            class="input documentation-no-border"
            [type]="'number'"
            [(model)]="metric.values.correct"
            [min]="0"
            [disabled]="disabled"
            (keyup)="keyInput($event, 'correct')"
            (onBlur)="blurInput($event, 'correct')"
          ></pl-input-text>
        </div>
        <div class="metric-ratio-slash">/</div>
        <div class="metric-ratio-trials">
          <pl-input-text
            class="input documentation-no-border"
            [type]="'number'"
            [(model)]="metric.values.incorrect"
            [min]="0"
            [disabled]="disabled"
            (keyup)="keyInput($event, 'trials')"
            (onBlur)="blurInput($event, 'trials')"
          ></pl-input-text>
        </div>
      </div>
      <div class="metric-percent">{{ accuracy }}%</div>
    </div>
  </div>
</div>
