import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store';
import { FirebaseModel } from './FirebaseModel';
import { selectAtLeastOneStudent } from '@room/session/store';
import _ from 'lodash';

/**
 * Model that holds the state of the state of application controls and widgets
 */
@Injectable({ providedIn: 'root' })
export class FirebaseAppModel {
  private whiteboardActiveSource = new BehaviorSubject<boolean>(false);
  whiteboardActive$ = this.whiteboardActiveSource.asObservable();

  fullScreenChanged$ = new BehaviorSubject<string>('normal');
  ready = false;

  /**
   * Application state properties
   * @type {{commsMode: string, whiteboardActive: boolean,
   * bigVideoId: number, sharingMyScreen: boolean, screenShareFailed: boolean,
   *  screenShareSupported: boolean}}
   */
  readonly defaults = {
    whiteboardActive: false, // TODO fix disappearing wb button if false
    sessionRecordDrawerActive: false,
    gamesActive: false,
    widgetsboardActive: false,
    whiteScreenActive: false,
    sharedCursorOn: false,
    activeQueueId: null,
    shouldShowWorkspace: true,
    tokboxIsRecording: false,
    tokboxRecordingProperties: null,
    sessionRecordingViewerActive: false,
    sessionRecordingVideo: {},
    status: 'ready',
    atLeastOneStudent: false,
  };

  private appSubjects: Record<string, BehaviorSubject<any>> = {
    fullscreen: this.fullScreenChanged$,
  };

  app: any = this.defaults;
  ref: any;

  constructor(
    private firebaseModel: FirebaseModel,
    ngrxStoreService: Store<AppState>,
  ) {
    ngrxStoreService
      .select(selectAtLeastOneStudent)
      .subscribe(atLeastOneStudent => {
        this.app.atLeastOneStudent = !!atLeastOneStudent;
      });
    this.ref = firebaseModel.getFirebaseRef('app');
    this.onStatus();
    this.onChanges();
  }

  onStatus = () => {
    this.firebaseModel.getFirebaseRef('status').on('value', snapshot => {
      // If not set, set to ready.
      this.app.status = snapshot.val() || 'ready';
      if (this.app.status === 'moved') {
        location.reload();
      }
    });
  };

  onChanges = () => {
    this.firebaseModel
      .getFirebaseRef('app/whiteboardActive')
      .on('value', snap => {
        const val = snap.val();
        if (val !== this.app.whiteboardActive) {
          this.app.whiteboardActive = val;
          this.whiteboardActiveSource.next(val);
        }
      });
  };

  setSessionRecordingViewerActive = value => {
    this.app.sessionRecordingViewerActive = value;

    this.ref.update({
      sessionRecordingViewerActive: value,
    });
  };

  setSessionRecordingVideo = value => {
    this.app.sessionRecordingVideo = value;

    this.ref.update({
      sessionRecordingVideo: {
        url: value.url,
        formattedDate: value.formattedDate,
      },
    });
  };

  setWhiteScreenActive = value => {
    this.app.whiteScreenActive = value;
    this.ref.update({
      whiteScreenActive: value,
    });
  };

  setChatMuted = value => {
    this.app.chatIsMuted = value;
    this.ref.update({
      chatIsMuted: value,
    });
  };

  /**
   * setWhiteboardActive
   * @param active
   */
  setWhiteboardActive = active => {
    this.app.whiteboardActive = active;
    this.whiteboardActiveSource.next(active);
    this.ref.update({
      whiteboardActive: active,
    });
  };

  setGamesActive = active => {
    this.app.gamesActive = active;
    this.ref.update({
      gamesActive: active,
    });
  };

  shouldDisableCursorSharing = drawerName => {
    return !(drawerName === 'games' || drawerName === 'activities');
  };

  /**
   * setSessionRecordDrawerActive
   * @param active
   */
  setSessionRecordDrawerActive = active => {
    this.app.sessionRecordDrawerActive = active;
    this.ref.update({
      sessionRecordDrawerActive: active,
    });
  };

  /**
   *  setWidgetboardStatus
   *  @param active
   */
  setWidgetboardStatus = active => {
    this.app.widgetsboardActive = active;
    this.ref.update({
      widgetsboardActive: active,
    });
  };

  /**
   *  shouldShowWorkspace
   *  @param {Boolean} value
   */
  showWorkspace = value => {
    this.app.shouldShowWorkspace = value;
    this.ref.update({
      shouldShowWorkspace: value,
    });
  };

  /**
   *  set
   *
   *  @param queueId
   */
  setActiveQueueId = queueId => {
    if (this.ready) {
      this.app.activeQueueId = queueId;
      this.ref.update({
        activeQueueId: queueId,
      });
    }
  };
}
