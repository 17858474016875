import { Component } from '@angular/core';

import { GameCategoryType } from '@modules/pl-games/store';
import {
  BoardGame,
  PLBoardGamesFactoryService,
} from '@modules/pl-games/pl-board-games/pl-board-games-factory.service';

@Component({
  selector: 'pl-games-drawer',
  templateUrl: './pl-games-drawer.component.html',
  styleUrls: ['./pl-games-drawer.component.less'],
})
export class PLGamesDrawerComponent {
  boardGamesList: BoardGame[];
  currentGame: BoardGame;
  currentGameCategory: GameCategoryType;
  detailView: boolean;

  constructor(private boardGamesFactory: PLBoardGamesFactoryService) {
    this.initBoardGames();
  }

  initBoardGames() {
    const gameNames = [
      'jungle-adventure',
      'space-adventure',
      'arctic-adventure',
    ];
    this.boardGamesList = gameNames.map(gameName =>
      this.boardGamesFactory.getBoardGame(gameName),
    );
  }

  leaveDetail() {
    this.detailView = false;
  }

  loadBoardGame(game: BoardGame) {
    this.currentGame = game;
    this.currentGameCategory = GameCategoryType.BoardGame;
    this.detailView = true;
  }
}
