<div class="pl-toast" (window:resize)="onResizeEle($event)" [ngClass]="classes.container" [ngStyle]="stylesContainer">
    <div class="alert">
        <div class="alert-content">
            <div class="left-icon padding-lr">
                <div class="left-icon-svg">
                    <pl-icon [svg]="leftIconSvg" [height]="12" [width]="12" [verticalAlign]="0"></pl-icon>
                </div>
            </div>
            <div class="content">
                <div *ngIf="closeOnTop" class="close-icon-top">
                    <pl-icon (click)="fullHide()" class="close-icon inline-block" [svg]="'close-inverted'" [scale]="1"></pl-icon>
                </div>
                <div class="message">
                    <div class="text">{{ message }}</div>
                    <pl-icon *ngIf="!closeOnTop" (click)="fullHide()" class="close-icon" [svg]="'cross'" [scale]="0.8" [verticalAlign]="'-6px'"></pl-icon>
                </div>
            </div>
        </div>
    </div>
</div>