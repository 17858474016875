import { Component } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'pl-design-system-forms',
  templateUrl: './design-system-forms.component.html',
  styleUrls: ['./design-system-forms.component.scss'],
})
export class DesignSystemFormsComponent {
  hidePassword = true;
  searchValue = 'Lorem ipsum';

  email = new UntypedFormControl('', [Validators.required, Validators.email]);

  getErrorMessage() {
    if (this.email.hasError('required')) {
      return 'You must enter a value';
    }

    return this.email.hasError('email') ? 'Not a valid email' : '';
  }
}
