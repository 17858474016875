import {
  Component,
  ChangeDetectorRef,
  OnInit,
  HostListener,
  Input,
  OnDestroy,
} from '@angular/core';
import { FirebaseAppModel } from '@common/models/firebase/firebase-app-model.service';
import { FlashCardsService } from '../services/flashcards-new.service';
import { RoomClickService } from '@common/services/RoomClickService';
import { PLActivityModelService } from '@root/src/app/modules/room/pl-activity/model/activity-model.service';
import { find, map, pick, propertyOf } from 'lodash';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@root/src/app/store';
import {
  CentralContentType,
  selectIsCurrentCentralContent,
} from '../../../app/store';
import { FlashcardsDnDService } from '../services/flashcards-dnd.service';
import { CardDisplayOption } from '@root/src/app/common/components/card-display-options/card-display-options.component';
interface Flashcard {
  id: number;
  thumbnail_url: string;
  type: string;
  url: string;
}

enum FlashcardsDisplayModeEnum {
  grid = 'Grid',
  bottom = 'Bottom grid',
  collage = 'Collage',
  presentation = 'Presentation',
  singlestack = 'Single stack',
}

@Component({
  selector: 'pl-flashcards-activity-drawer',
  templateUrl: './pl-flashcards-activity-drawer.component.html',
  styleUrls: ['./pl-flashcards-activity-drawer.component.less'],
  providers: [FlashCardsService],
})
export class PLFlashcardsActivityDrawerComponent implements OnInit, OnDestroy {
  @Input() public queueActivity;
  fields = [
    'keyCode',
    'charCode',
    'shiftKey',
    'metaKey',
    'altKey',
    'ctrlKey',
    'type',
  ];
  flashcardsDisplayModeEnum = FlashcardsDisplayModeEnum;
  flashcardsOnBoard = [];
  gotInitiatedList = false;
  showTitle = true;
  activity;
  flashcardsModel;
  showViewSwitcher = true; // May persist in cookies
  img = document.createElement('img');
  isActivityActive$: Observable<boolean>;
  availableCards: Flashcard[] = [];

  cardDisplayOptions: CardDisplayOption[] = [
    CardDisplayOption.ImageAndText,
    CardDisplayOption.ImageOnly,
  ];
  cardDisplayChoice: CardDisplayOption = CardDisplayOption.ImageAndText;
  firstOnBoard = true;

  @HostListener('document:keydown', ['$event']) handleKeyDown(
    e: KeyboardEvent,
  ) {
    this.activityModel.channel.call({
      method: 'PL-Remote-Dispatcher::event',
      params: pick(e, this.fields),
    });
  }

  constructor(
    private activityModel: PLActivityModelService,
    private fireBaseAppModel: FirebaseAppModel,
    private flashCardsService: FlashCardsService,
    private roomClickService: RoomClickService,
    private cdr: ChangeDetectorRef,
    private store: Store<AppState>,
    private flashCardsDndService: FlashcardsDnDService,
  ) {
    this.activity = this.activityModel.activity;
    this.flashcardsModel = this.flashCardsService;
    this.img.src =
      'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
  }

  ngOnInit(): void {
    this.activityModel.setSessionId(this.queueActivity.session_id);
    this.isActivityActive$ = this.store.select(
      selectIsCurrentCentralContent(
        CentralContentType.QueueItem,
        this.queueActivity,
      ),
    );
    this.flashCardsService.initialize(this.queueActivity);
    this.flashcardsActivityBinding();
    const initParams = {
      isInitialized: false,
      cards: [],
    };
    this.updateFlashcardsOnBoard(initParams);
    this.updateShowTitleStatus([]);
  }

  ngOnDestroy(): void {
    this.activityModel.channel.unbind(
      'flashcardsOnBoard',
      this.flaschardsOnBoardHandler,
    );
    this.activityModel.channel.unbind(
      'flashcardsSelected',
      this.flashcardsSelectedHandler,
    );
  }

  private flaschardsOnBoardHandler = (_trans, params) => {
    this.updateFlashcardsOnBoard(params);
  };
  private flashcardsSelectedHandler = (_trans, selectedCards) => {
    this.updateShowTitleStatus(selectedCards);
  };

  get mode() {
    return this.flashCardsService.mode$.getValue();
  }

  public toggleViewSwitcher(): void {
    this.showViewSwitcher = !this.showViewSwitcher;
  }

  private flashcardsActivityBinding(): void {
    this.activityModel.foundationLoaded.then(() => {
      this.activityModel.channel.bind(
        'flashcardsOnBoard',
        this.flaschardsOnBoardHandler,
      );
      this.activityModel.channel.bind(
        'flashcardsSelected',
        this.flashcardsSelectedHandler,
      );
      this.activityModel.channel.call({
        method: 'getCards',
      });
    });
  }

  private updateFlashcardsOnBoard(params) {
    if (!this.gotInitiatedList && params.isInitialized) {
      this.gotInitiatedList = true;
    }
    this.flashcardsOnBoard = params.cards;

    if (this.firstOnBoard && this.flashcardsOnBoard.length) {
      this.updateShowTitleStatus(this.flashcardsOnBoard);
      this.firstOnBoard = false;
    }

    this.availableCards = this.getCards();
  }

  private updateShowTitleStatus(selectedCards) {
    const cards = selectedCards.length ? selectedCards : this.flashcardsOnBoard;
    this.showTitle = cards.some(card => card.type === 'both');
    this.cardDisplayChoice = this.showTitle
      ? CardDisplayOption.ImageAndText
      : CardDisplayOption.ImageOnly;
    this.cdr.detectChanges();
  }

  changeCardDisplayOption(option: CardDisplayOption) {
    this.setShowTitle(option === CardDisplayOption.ImageAndText);
  }

  public setShowTitle(value) {
    this.showTitle = value;
    this.cardDisplayChoice = value
      ? CardDisplayOption.ImageAndText
      : CardDisplayOption.ImageOnly;
    this.activityModel.channel.call({
      method: 'flashcardsChangeShowTitle',
      params: this.showTitle,
    });
  }

  public activityLoaded() {
    return !!(
      propertyOf(this.fireBaseAppModel)('app.activeActivity') ||
      this.activityModel.activity.config
    );
  }
  cardsLayoutChanged(event) {
    this.setFlashcardsMode(event.value);
  }

  setFlashcardsMode(mode) {
    if (!this.activityLoaded() || !this.activityModel.channel) {
      return;
    }
    this.flashCardsService.isLocalEvent = true;
    this.activityModel.activity.mode = mode;
    this.flashCardsService.updateFlashCardsMode(
      this.activityModel.activity.mode,
    );
  }

  deleteAllCards() {
    // Without this, if in grid mode, it will still not call animate,
    // which leads to a delay before cards are removed.
    this.flashCardsService.updateFlashcardsClearAnimated();
    this.flashCardsService.deleteCards(null);
    // This is a pseudo hack to remove the presentation arrows.
    this.setFlashcardsMode('grid');

    this.flashCardsService.updateFlashCardsDeselectCards(true);
  }

  addAllCards() {
    if (!this.flashCardsService.isLinksReady()) {
      return;
    }

    const cards = this.getCards();

    if (!cards || !cards.length) {
      return;
    }
    this.setFlashcardsMode('grid');
    setTimeout(() => {
      this.flashCardsService.updateFlashCardsActivityCards(cards);
      this.activityModel.channel.call({
        method: 'addCardsOnWhiteboard',
        params: {
          cards,
          all: true,
        },
      });
    });
  }

  startDrag({ event, data }) {
    const json = JSON.stringify(data);

    this.roomClickService.trigger('drawer', 'dragStart');
    event.dataTransfer.effectAllowed = 'move';

    event.dataTransfer.setData('flashcard', json);
    event.dataTransfer.setDragImage(this.img, 0, 0);

    this.flashCardsDndService.updateFlashCardsDragging(json);
  }

  cancelDrag(e) {
    if (!this.activityModel.channel) {
      return false;
    }

    this.flashCardsDndService.updateFlashCardsDragging(null);
  }

  getCards() {
    let cards = this.activity.config.cards;
    if (cards && cards.length) {
      return cards.filter(card => {
        return !find(this.flashcardsOnBoard, {
          url: card.url,
          title: card.title,
        });
      });
    }
    return [];
  }

  trackByCard(_: number, card: Flashcard) {
    return card.id;
  }
}
