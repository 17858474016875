import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PLYoutubePlayerComponent } from './pl-youtube-player.component';
import { PLYoutubeActivityDrawerComponent } from './drawer/pl-youtube-activity-drawer.component';
import { PLYoutubeFactoryService } from './pl-youtube-factory.service';
import { PLIconModule } from '@root/index';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PLYouTubeService } from '../../../pl-queue/pl-queue-add/pl-quick-youtube/pl-you-tube.service';

const components = [PLYoutubeActivityDrawerComponent, PLYoutubePlayerComponent];
const providers = [PLYoutubeFactoryService, PLYouTubeService];

@NgModule({
  imports: [CommonModule, PLIconModule, MatIconModule, MatProgressBarModule],
  exports: [...components],
  declarations: [...components],
  providers: [...providers],
})
export class PLYoutubeActivityModule {}
