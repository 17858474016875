<div class="pl-go-fish">
  <pl-card-game
    [players]="players"
    [cards]="cards"
    [currentPlayerId]="currentPlayerId"
    [playerDeckTypes]="playerDeckTypes"
    [startNewGame]="startNewGame"
    [turnCount]="turnCount"
    [cardDisplay]="cardDisplay"
    [dealCount]="dealCount"
    [mayMoveCardsOffTurn]="mayMoveCardsOffTurn"
    [mayEndAllPlayersTurnsIds]="mayEndAllPlayersTurnsIds"
    [mayMoveAllPlayersCardsIds]="mayMoveAllPlayersCardsIds"
    [playerImageUrls]="playerImageUrls"
    [playerLayout]="playerLayout"
    [otherPlayerCardLayout]="otherPlayerCardLayout"
    [animateMoveCardsSeconds]="animateMoveCardsSeconds"
    [useScoreboard]="useScoreboard"
    [showWonOnScoreboard]="showWonOnScoreboard"
    [wonCardsScoreboard]="wonCardsScoreboard"
    [hideHandIfNewCards]="hideHandIfNewCards"
    [toggleWonDeckVisible]="toggleWonDeckVisible"
    [showWonCardsSeconds]="showWonCardsSeconds"
    [showInstructions]="showInstructions"
    [useSingleWonAllPlayers]="useSingleWonAllPlayers"
    [requirePreselectingPlayerForCardMove]="
      requirePreselectingPlayerForCardMove
    "
    [showHandText]="showHandText"
    [mayViewAllPlayersIds]="mayViewAllPlayersIds"
    [cardBackImageUrl]="cardBackImageUrl"
    [selectedCardsMax]="selectedCardsMax"
    [selectedCardsMaxOffTurn]="selectedCardsMaxOffTurn"
    [skipNewDeck]="skipNewDeck"
    [flashSeconds]="flashSeconds"
    [drawCardsMax]="drawCardsMax"
    [sortCardsMostRecentFirst]="sortCardsMostRecentFirst"
    (playerUpdated)="playerUpdated($event)"
    (cardsUpdated)="cardsUpdated($event)"
    (turnCountUpdated)="turnCountUpdated($event)"
    (cardsScoreboardWonUpdated)="cardsScoreboardWonUpdated($event)"
  ></pl-card-game>
</div>
