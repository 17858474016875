import { Component, Input } from '@angular/core';
import { Player } from '../../clue-store';
import { Store } from '@ngrx/store';
import * as ClueActions from '../../clue-store/actions';

@Component({
	selector: 'investigation-items',
	templateUrl: './investigation-items.component.html',
	styleUrls: ['./investigation-items.component.less'],
})

export class PLClueInvestigationItemsComponent {
	@Input() currentUser: Player;
	@Input() playersArr: Player[];
	@Input() firebaseClueRef;
	
	constructor(
		private store: Store<any>
	) {}

	public suspectNotes(suspect, action): void {
		let tempCurrentUser = {
			...this.currentUser,
			cardList: {
				locations: [ ...this.currentUser.cardList.locations ],
				methods: [ ...this.currentUser.cardList.methods ],
				suspects: [ ...this.currentUser.cardList.suspects ]
			},
			confidentialCards: [ ...this.currentUser.confidentialCards ]
		}
		
		const { suspects } = tempCurrentUser.cardList;
		const indexOfSuspect = suspects.findIndex((card) =>  card.name === suspect.name );

		suspects[indexOfSuspect] = {
			...suspects[indexOfSuspect],
			status: suspects[indexOfSuspect].status === action ? '' : action
		};

		let updatedPlayersArr = this.playersArr.map((player) => {
			if ( player.userId === tempCurrentUser.userId ) {
				player = tempCurrentUser
			}
			return player;
		});
		
		this.firebaseClueRef.update({ playersArr: updatedPlayersArr });
		this.store.dispatch(ClueActions.updateCurrentUser({ currentUser: tempCurrentUser }));
	}

	public locationNotes(location, action): void {
		let tempCurrentUser = {
			...this.currentUser,
			cardList: {
				locations: [ ...this.currentUser.cardList.locations ],
				methods: [ ...this.currentUser.cardList.methods ],
				suspects: [ ...this.currentUser.cardList.suspects ]
			},
			confidentialCards: [ ...this.currentUser.confidentialCards ]
		}
		
		const { locations } = tempCurrentUser.cardList;
		const indexOfSuspect = locations.findIndex((card) =>  card.name === location.name );

		locations[indexOfSuspect] = {
			...locations[indexOfSuspect],
			status: locations[indexOfSuspect].status === action ? '' : action
		};

		let updatedPlayersArr = this.playersArr.map((player) => {
			if ( player.userId === tempCurrentUser.userId ) {
				player = tempCurrentUser
			}
			return player;
		});
		
		this.firebaseClueRef.update({ playersArr: updatedPlayersArr });
		this.store.dispatch(ClueActions.updateCurrentUser({ currentUser: tempCurrentUser }));
	}

	public methodNotes(method, action): void {
		let tempCurrentUser = {
			...this.currentUser,
			cardList: {
				locations: [ ...this.currentUser.cardList.locations ],
				methods: [ ...this.currentUser.cardList.methods ],
				suspects: [ ...this.currentUser.cardList.suspects ]
			},
			confidentialCards: [ ...this.currentUser.confidentialCards ]
		}
		
		const { methods } = tempCurrentUser.cardList;
		const indexOfSuspect = methods.findIndex((card) =>  card.name === method.name );

		methods[indexOfSuspect] = {
			...methods[indexOfSuspect],
			status: methods[indexOfSuspect].status === action ? '' : action
		};
		let updatedPlayersArr = this.playersArr.map((player) => {
			if ( player.userId === tempCurrentUser.userId ) {
				player = tempCurrentUser
			}
			return player;
		});
		
		this.firebaseClueRef.update({ playersArr: updatedPlayersArr });
		this.store.dispatch(ClueActions.updateCurrentUser({ currentUser: tempCurrentUser }));
	}
}