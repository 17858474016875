import { NgModule } from '@angular/core';
import { PLDragDirective } from './drag.directive';
import { PLDropDirective } from './drop.directive';
import { CommonModule } from '@angular/common';

const exported = [PLDragDirective, PLDropDirective];

@NgModule({
  imports: [CommonModule],
  declarations: [...exported],
  exports: [...exported],
})
export class PLDragAndDropModule {}
