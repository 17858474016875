import { Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { FirebaseService } from '@common/firebase/firebase.service';
import { environment } from '@root/src/environments/environment';
import { AppState } from '@app/store';
import { PLLodashService } from '@common/services';
import { PLHttpService, PLUrlsService } from '@common/services/pl-http';
import {
  Participant,
  selectActiveParticipants,
  selectLocalParticipantId,
  ParticipantType,
} from '@room/session/store';
import { concatMap, withLatestFrom } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';
import {
  GO_FISH_UPDATE_CARD_DISPLAY,
  GO_FISH_UPDATE_DEAL_COUNT,
  GO_FISH_XRAY_STATUS,
  GO_FISH_TOGGLE_GAME_ACTIVE,
  GO_FISH_UPDATE_CARDS,
} from './store/go-fish.actions';
import { selectIsGameActive } from './store/go-fish.selectors';
import { DealCountOptions } from './pl-go-fish-types.types';
import { CardDisplayOption } from '@root/src/app/common/components/card-display-options/card-display-options.component';
interface DealCountEvent {
  value: DealCountOptions;
}

@Component({
  selector: 'pl-go-fish-drawer',
  templateUrl: './pl-go-fish-drawer.component.html',
  styleUrls: ['./pl-go-fish-drawer.component.less'],
})
export class PLGoFishDrawerComponent implements OnInit, OnDestroy {
  @Input() activity: any = {};

  deckSelected: boolean = false;
  gameActive: boolean;
  newGameBtnActive: boolean = false;
  cardDisplay: CardDisplayOption = CardDisplayOption.ImageAndText;
  dealCount: number = 3;
  dealCountOpts = [
    { value: 3, label: '3 cards per player' },
    { value: 5, label: '5 cards per player' },
  ];
  xRayOn: boolean = false;
  deck: any = {};
  deckImages: any = [];
  // Rather than using what is set by the deck, allow setting here as override.
  imageCopies = 2;

  fbPath: string;
  fbActivity: any = {};
  subscriptions: Subscription[] = [];
  mayEndAllPlayersTurnsIds = [];
  mayMoveAllPlayersCardsIds = [];
  possiblePlayers = [];
  playerImageUrls = [];

  constructor(
    private firebaseService: FirebaseService,
    private plHttp: PLHttpService,
    private plUrls: PLUrlsService,
    private store: Store<AppState>,
    private zone: NgZone,
    private plLodash: PLLodashService,
  ) {}

  ngOnInit() {
    this.getAvatars();
    this.fbPath = `activities/queues/items/${this.activity.queueId}/items/${this.activity.activityId}`;
    this.fbActivity = this.firebaseService.getRoomRef(this.fbPath);

    this.subscriptions.push(this.subscribeToOverlaysStore());
    this.subscriptions.push(this.subscribeToPersonas());
    this.initFirebase();
    this.subscriptions.push(this.subscribeToIsGameActive());
    this.canStartNewGame();
  }

  ngOnDestroy() {
    this.removeListener();
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  public get CardDisplayOption() {
    return CardDisplayOption;
  }

  subscribeToPersonas(): Subscription {
    return this.store
      .select(selectActiveParticipants)
      .pipe(
        concatMap(participants => {
          return of(participants).pipe(
            withLatestFrom(this.store.select(selectLocalParticipantId)),
          );
        }),
      )
      .subscribe(([participants, localParticipantId]) => {
        this.getPlayers(participants, localParticipantId);
      });
  }

  subscribeToOverlaysStore(): Subscription {
    return this.store.select('overlaysStore').subscribe(data => {
      if (!data.activeIFrameWhiteboard) {
        this.removeListener();
      }
    });
  }

  subscribeToIsGameActive(): Subscription {
    return this.store.select(selectIsGameActive).subscribe(isGameActive => {
      this.gameActive = isGameActive;
      if (this.gameActive) {
        this.newGameBtnActive = false;
      }
    });
  }

  getPlayers(participants: Participant[], localId: string) {
    const localParticipant = participants.find(p => p.id === localId);
    if (!localParticipant) {
      return;
    }

    const mayEndAllPlayersTurnsIds = [];
    const mayMoveAllPlayersCardsIds = [];
    this.possiblePlayers = participants.map(({ userId, displayName, type }) => {
      const player = {
        id: userId,
        name: displayName,
        buttonMayViewAllPlayers: 0,
        mayMoveAllPlayersCards: 0,
      };

      if (type === ParticipantType.host) {
        mayEndAllPlayersTurnsIds.push(userId);
        mayMoveAllPlayersCardsIds.push(userId);
        player.buttonMayViewAllPlayers = 1;
        player.mayMoveAllPlayersCards = 1;
      }

      return player;
    });
    this.mayEndAllPlayersTurnsIds = mayEndAllPlayersTurnsIds;
    this.mayMoveAllPlayersCardsIds = mayMoveAllPlayersCardsIds;

    this.fbActivity.update({
      mayEndAllPlayersTurnsIds: mayEndAllPlayersTurnsIds,
      mayMoveAllPlayersCardsIds: mayMoveAllPlayersCardsIds,
    });

    this.savePlayers(this.possiblePlayers);
  }

  savePlayers(players) {
    const data = {};
    players.forEach(player => {
      player.imageUrl = this.playerImageUrls.splice(
        Math.floor(Math.random() * this.playerImageUrls.length),
        1,
      );
      data[player.id] = this.plLodash.pick(player, [
        'id',
        'name',
        'currentTurn',
        'imageUrl',
        'buttonMayViewAllPlayers',
        'mayMoveAllPlayersCards',
        'preselectedPlayerForCardMove',
        'playerCurrentTurnStartedAt',
      ]);
    });
    this.fbActivity.child('players').set(data);
  }

  initFirebase() {
    this.fbActivity.child('deck').on('value', ref => {
      this.zone.run(() => {
        const deck = ref.val();
        if (deck) {
          this.deck = deck;
          this.deckSelected = true;
        }
        this.canStartNewGame();
      });
    });
    this.fbActivity.child('cards').on('value', ref => {
      this.zone.run(() => {
        const deckImages = ref.val();
        if (deckImages) {
          this.deckImages = deckImages;
        }
      });
    });
    this.fbActivity.child('dealCount').on('value', ref => {
      this.zone.run(() => {
        const dealCount = ref.val();

        if (dealCount) {
          this.dealCount = dealCount;
          this.store.dispatch({
            type: GO_FISH_UPDATE_DEAL_COUNT,
            payload: { dealCount: this.dealCount },
          });
        }
      });
    });
    this.fbActivity.child('cardDisplay').on('value', ref => {
      this.zone.run(() => {
        const cardDisplay = ref.val();

        if (cardDisplay) {
          this.cardDisplay = cardDisplay;
          this.store.dispatch({
            type: GO_FISH_UPDATE_CARD_DISPLAY,
            payload: { cardDisplay: this.cardDisplay },
          });
        }
      });
    });

    this.fbActivity.child('isGameActive').on('value', ref => {
      this.zone.run(() => {
        const data = ref.val();
        this.gameActive = data;
        if (this.gameActive) {
          this.newGameBtnActive = false;
        }
      });
    });
  }

  getAvatars() {
    const rootDir = `https://cdn.presencelearning.com/emoji/`;
    const stampIds = [
      '1f436',
      '1f431',
      '1f430',
      '1f98a',
      '1f99d',
      '1f43c',
      '1f981',
      '1f438',
      '1f992',
      '1f98d',
      '1f417',
      '1f437',
      '1f43b',
      '1f47d',
      '1f916',
    ];
    this.playerImageUrls = stampIds.map(id => `${rootDir}${id}.svg`);
  }

  showInstructions() {
    // place code here to open instructions modal when it is designed
  }

  newGame() {
    this.store.dispatch({
      type: GO_FISH_TOGGLE_GAME_ACTIVE,
      payload: { isGameActive: true },
    });
    this.store.dispatch({
      type: GO_FISH_UPDATE_DEAL_COUNT,
      payload: { dealCount: this.dealCount },
    });
    this.store.dispatch({
      type: GO_FISH_UPDATE_CARD_DISPLAY,
      payload: { cardDisplay: this.cardDisplay },
    });

    this.fbActivity.update({
      dealCount: this.dealCount,
      cardDisplay: this.cardDisplay,
      isGameActive: true,
    });
  }

  endGame() {
    this.store.dispatch({
      type: GO_FISH_TOGGLE_GAME_ACTIVE,
      payload: { isGameActive: false },
    });
    this.fbActivity.child('isGameActive').set(false);
    this.canStartNewGame();
  }

  changeCardDisplay(e: CardDisplayOption) {
    this.cardDisplay = e;

    if (this.gameActive) {
      this.fbActivity.child('cardDisplay').set(this.cardDisplay);
    } else if (!this.gameActive) {
      this.canStartNewGame();
    }
  }

  changeDealCount(e: DealCountEvent) {
    this.dealCount = parseInt(e.value);
    this.canStartNewGame();
  }

  canStartNewGame() {
    if (this.cardDisplay && this.dealCount && this.deckSelected) {
      this.newGameBtnActive = true;
    }
  }

  toggleXRay(event) {
    this.xRayOn = event.checked;
    this.store.dispatch({
      type: GO_FISH_XRAY_STATUS,
      payload: { xRayOn: this.xRayOn },
    });
  }

  chooseDeck() {
    window.addEventListener('message', this.deckMessageListener, false);
    this.store.dispatch({
      type: 'UPDATE_OVERLAYS',
      payload: { activeIFrameWhiteboard: true },
    });
  }

  getAndUseDeck(deckId) {
    const url = `${this.plUrls.urls.activity}${deckId}/`;
    const data = {
      id: deckId,
    };
    this.plHttp.get('', data, url).subscribe((res: any) => {
      const descriptor = res.descriptor ? JSON.parse(res.descriptor) : {};
      const images = descriptor.images || [];
      const imageDeck = Object.assign(res, {
        imageUrl: res.thumbnail_url,
        title: res.name,
        images: images,
      });
      this.setUseDeck(imageDeck);
    });
  }

  setUseDeck(deck) {
    this.deckImages = this.imagesToCards(deck.images);
    this.deck = deck;
    this.deckSelected = true;
    this.fbActivity.child('deck').set({
      uuid: this.deck.uuid,
      id: this.deck.id,
      title: this.deck.title,
      imageUrl: this.deck.imageUrl,
      owner: this.deck.owner,
    });

    this.fbActivity.child('cards').set(this.deckImages);

    this.store.dispatch({
      type: GO_FISH_UPDATE_CARDS,
      payload: { cards: this.deckImages },
    });
  }

  imagesToCards(deckImages) {
    const cards: any[] = [];
    deckImages.forEach(image => {
      for (let ii = 0; ii < this.imageCopies; ii++) {
        cards.push({
          id: `${image.id}${ii}`,
          text: image.title,
          imageUrl: image.thumbnail_url,
        });
      }
    });
    return cards;
  }

  private deckMessageListener = event => {
    if (!event.origin.includes(`${environment.apps.flutterApp.url}`)) {
      return;
    }
    const data = event.data.data;
    if (
      data.eventName === 'useDeck' &&
      data.imageDeckUuid &&
      data.imageDeckId
    ) {
      this.removeListener();
      this.getAndUseDeck(data.imageDeckId);
      this.store.dispatch({
        type: 'UPDATE_OVERLAYS',
        payload: { activeIFrameWhiteboard: false },
      });
    }
  };

  private removeListener = () => {
    window.removeEventListener('message', this.deckMessageListener, false);
  };
}
