import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { debounce } from 'lodash';
import { PdfDrawerPagesComponent } from '@root/src/app/modules/pl-queue/pdf-drawer-pages/pdf-drawer-pages.component';
import { LoggerService } from '@root/src/app/common/services/logger/logger.service';
import { PLLessonModelService } from '../../../pl-activity/model/lesson-model.service';
import { PDFFBPaths } from '../pdf-lesson-viewer.component';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

export enum BookModes {
  SINGLE_PAGE_MODE = 1,
  DBL_PAGE_EVEN_MODE = 2,
  DBL_PAGE_ODD_MODE = 3,
}

const DEFAULT_PAGE_NUM = 1;
const DEFAULT_ZOOM = 1;

@Component({
  selector: 'pl-pdf-lesson-viewer-drawer',
  templateUrl: 'pdf-lesson-viewer-drawer.component.html',
  styleUrls: ['pdf-lesson-viewer-drawer.component.less'],
})
export class PLPDFLessonViewerDrawerComponent
  implements OnDestroy, AfterViewInit
{
  @ViewChild(PdfDrawerPagesComponent)
  private pagesRef: PdfDrawerPagesComponent;

  totalPages = 0;
  pageRotations: Record<string, number> = {};
  bookMode = BookModes.SINGLE_PAGE_MODE;
  thumbs: string[];
  hasThumbs = false;
  currentPage = DEFAULT_PAGE_NUM;
  currentScale = DEFAULT_ZOOM;

  gotoPageChannel = debounce(pageNum => {
    this.activityModel.getSessionRef().child(PDFFBPaths.PageNum).set(pageNum);
  }, 200);

  constructor(
    private activityModel: PLLessonModelService,
    private loggerService: LoggerService,
  ) {}

  ngAfterViewInit() {
    this.init();
  }

  ngOnDestroy() {
    this.activityModel.channel.unbind('gotoPage');
  }

  async init() {
    try {
      await this.activityModel.foundationLoaded;

      const { bookMode, currentPage, currentScale, pageRotations } =
        await this.loadState();

      this.currentPage = currentPage;
      this.bookMode = bookMode;
      this.currentScale = currentScale;
      this.pageRotations = pageRotations;
      this.thumbs = this.activityModel.activity.page_thumbnails;
      this.hasThumbs = this.thumbs?.length > 0;

      this.addListeners();

      if (this.hasThumbs) {
        this.totalPages = this.thumbs?.length;
      } else {
        await this.loadPDF(this.activityModel.activity.config);
      }
    } catch (err) {
      this.loggerService.error(err);
      await new Promise(resolve => setTimeout(resolve, 1000));
      return this.init();
    }
  }

  async loadPDF(url: string) {
    return this.activityModel
      .getProtectedContentUrl(url)
      .pipe(
        switchMap(data => {
          const protectedUrl = data.assets[url];
          return of(this.pagesRef.loadPDF(protectedUrl));
        }),
      )
      .toPromise();
  }

  private addListeners() {
    this.activityModel.channel.bind('gotoPage', (trans, pageNum) => {
      this.currentPage = pageNum;
    });
  }

  private loadState(): Promise<{
    currentPage: number;
    currentScale: number;
    bookMode: BookModes;
    pageRotations: Record<string, number>;
  }> {
    return new Promise(resolve => {
      this.activityModel.getSessionRef().once('value', snap => {
        const data = snap.val() || {};
        resolve({
          currentPage: data[PDFFBPaths.PageNum] || DEFAULT_PAGE_NUM,
          currentScale: data[PDFFBPaths.Scale] || DEFAULT_ZOOM,
          bookMode: data[PDFFBPaths.BookMode] || BookModes.SINGLE_PAGE_MODE,
          pageRotations: data[PDFFBPaths.PageRotation] || {},
        });
      });
    });
  }

  onTabChange(tabIndex: number) {
    // Tab with index 0 renders thumbnails
    if (tabIndex === 0) {
      requestAnimationFrame(() => {
        this.pagesRef.triggerRerender();
      });
    }
  }

  switchModeTo(bookVal: BookModes) {
    this.bookMode = bookVal;
    this.activityModel.getSessionRef().child(PDFFBPaths.BookMode).set(bookVal);
  }

  zoomTo(zoomVal: number) {
    this.activityModel
      .getSessionRef()
      .child(PDFFBPaths.Scale)
      .set(isNaN(zoomVal) ? 1.0 : zoomVal);

    this.currentScale = zoomVal;
  }

  rotate90() {
    let rotateDeg = -90; // default

    const existingRotationKey = Object.keys(this.pageRotations).find(
      pageToRotate => parseInt(pageToRotate.slice(4), 10) === this.currentPage,
    );

    const existingRotation = this.pageRotations[existingRotationKey];

    if (existingRotation) {
      rotateDeg += existingRotation;
    }

    const newRotationKey = `page${this.currentPage}`;
    const newRotation = rotateDeg % 360;

    this.pageRotations = {
      ...this.pageRotations,
      [newRotationKey]: newRotation,
    };

    this.activityModel
      .getSessionRef()
      .child(PDFFBPaths.PageRotation)
      .child(newRotationKey)
      .set(newRotation);
  }

  gotoPage(pageNum: number) {
    this.currentPage = pageNum;
    if (this.bookMode === BookModes.DBL_PAGE_ODD_MODE && pageNum % 2 === 0) {
      pageNum--;
    }
    if (
      this.bookMode === BookModes.DBL_PAGE_EVEN_MODE &&
      pageNum % 2 === 1 &&
      pageNum !== 1
    ) {
      pageNum--;
    }

    this.gotoPageChannel(pageNum);
  }

  onPDFLoaded(pdf) {
    this.totalPages = pdf.numPages;
  }
}
