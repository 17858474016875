<div class="mat-dialog-header">
  <h2 mat-dialog-title>Select a Client</h2>
  <button mat-icon-button mat-dialog-close aria-label="Close client select">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <p class="mb-3">Clients scheduled for {{ todaysDateDisplay }}</p>
  <pl-client-appointments-select
    (clientAppointmentSelected)="clientAppointmentSelected($event)"
    [currentUser]="currentUser"
    [loadClients]="loadClients"
    [dialogMode]="true"
  >
  </pl-client-appointments-select>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-stroked-button color="primary" mat-dialog-close>Cancel</button>
  <button
    mat-raised-button
    color="primary"
    [disabled]="disableConfirm"
    [mat-dialog-close]="data"
  >
    Confirm
  </button>
</mat-dialog-actions>
