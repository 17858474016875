import { createAction } from '@common/utils';
import { props } from '@ngrx/store';

export enum ScreenshareActionTypes {
  start = '/screenshare/start',
  startSuccess = '/screenshare/startSuccess',
  startError = '/screenshare/startError',
  stop = '/screenshare/stop',
  startedRemotely = '/screenshare/startedRemotely',
  stoppedRemotely = '/screenshare/stoppedRemotely',
  subscribe = '/screenshare/subscribe',
  subscribeSuccess = '/screenshare/subscribeSuccess',
  subscribeError = '/screenshare/subscribeError',
  unsubscribe = '/screenshare/unsubscribe',
}

const start = createAction(ScreenshareActionTypes.start);

const startSuccess = createAction(ScreenshareActionTypes.startSuccess);

const startError = createAction(
  ScreenshareActionTypes.startError,
  props<{ error: any }>(),
);

const stop = createAction(ScreenshareActionTypes.stop);

const startedRemotely = createAction(ScreenshareActionTypes.startedRemotely);

const stoppedRemotely = createAction(ScreenshareActionTypes.stoppedRemotely);

const subscribe = createAction(ScreenshareActionTypes.subscribe);

const subscribeSuccess = createAction(ScreenshareActionTypes.subscribeSuccess);

const subscribeError = createAction(
  ScreenshareActionTypes.startError,
  props<{ error: any }>(),
);

const unsubscribe = createAction(ScreenshareActionTypes.unsubscribe);

export const ScreenshareActions = {
  start,
  startSuccess,
  startError,
  stop,
  startedRemotely,
  stoppedRemotely,
  subscribe,
  subscribeSuccess,
  subscribeError,
  unsubscribe,
};
