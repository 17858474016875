<div class="drawer-queue" data-testid="drawer-queue">
  <header class="drawer-header">
    <div class="drawer-header-left">
      <mat-icon
        *ngIf="currentViewIndex > 0"
        (click)="goBack()"
        class="icon-gray"
        data-testid="back-to-queue-button"
        role="button">
        arrow_back
      </mat-icon>
      <h3 class="drawer-header__title">
        {{ viewStack[currentViewIndex]?.title }}
      </h3>
    </div>

    <button
      mat-icon-button
      class="drawer-header__menu-button"
      [matMenuTriggerFor]="queuesMenu"
      *ngIf="viewStack[currentViewIndex]?.type === 'queues'">
      <mat-icon>more_horiz</mat-icon>
    </button>

    <mat-menu #queuesMenu="matMenu" backdropClass="is-dark">
      <!-- <button mat-menu-item>
        <span>Manage queues...</span>
      </button> -->
      <button mat-menu-item (click)="openDeletedQueues()">
        <span>Show deleted queues</span>
      </button>
    </mat-menu>
  </header>

  <!--
    Focusing input outside of the container viewport, even with overflow hidden
    results in scroll value to be changed and causes unwanted shifts,
    we're ensuring here that the scroll value is not affected
  -->
  <div class="panels-container" (scroll)="$event.target.scrollLeft = 0">
    <div
      class="panels-slider"
      [style.transform]="'translate3d(' + -currentViewIndex * 240 + 'px,0,0)'"
      [class.animations-enabled]="animationsEnabled">
      <div
        *ngFor="let view of viewStack; let i = index"
        class="panel-container">
        <ng-container
          *ngTemplateOutlet="panelView; context: { view }"></ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #panelView let-view="view">
  <ng-container [ngSwitch]="view.type">
    <ng-container *ngSwitchCase="'queues'">
      <pl-queues-list
        [isLoading]="!isLoaded"
        [queuesItems]="queuesItems"
        [queuesOrder]="queuesOrder"
        (openQueue)="openDetails($event)"
        (deleteQueue)="deleteQueue($event)"
        (editQueueName)="openEditQueue($event)"
        (openCreateNewQueue)="openCreateNewQueue()"
        (changeOrder)="changeQueueOrder($event)"></pl-queues-list>
    </ng-container>

    <pl-queue-detail
      *ngSwitchCase="'queue'"
      [queue]="activeQueueId ? queuesItems[activeQueueId] : null"
      [(isEditing)]="isEditingActiveQueue"
      (openActivity)="openActivity($event)"
      (openAddActivity)="openAddActivity()"
      (deleteQueue)="deleteQueue({ queueId: activeQueueId })"
      (updateQueueName)="
        updateQueueName({ queueId: activeQueueId, queueName: $event })
      "
      (changeActivitiesOrder)="changeActivitiesOrder($event)"
      (removeActivity)="removeActivity($event)"></pl-queue-detail>

    <ng-container *ngSwitchCase="'deleted-queues'">
      <pl-queues-deleted-list
        [hardDeleteTime]="hardDeleteTime"
        [hardDeleteUnit]="hardDeleteUnit"
        [queues]="deletedQueues"
        (restoreQueue)="restoreQueue($event)"></pl-queues-deleted-list>
    </ng-container>

    <ng-container *ngSwitchCase="'queue-activity'">
      <pl-activity-drawer *ngIf="activeActivity$ | async"></pl-activity-drawer>
    </ng-container>

    <ng-container *ngSwitchCase="'queue-add-activity'">
      <pl-queue-add
        [activeQueueId]="activeQueueId"
        [isIntegratedLibraryEnabled]="isIntegratedLibraryEnabled$ | async"
        (openLibrary)="openLibrary()"></pl-queue-add>
    </ng-container>

    <ng-container *ngSwitchCase="'queue-library'">
      <pl-activities></pl-activities>
    </ng-container>
  </ng-container>
</ng-template>
