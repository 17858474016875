import { Directive } from '@angular/core';

let nextId = 0;

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'pl-accordion',
})
export class PLAccordionDirective {
  readonly id = `pl-accordion-${nextId++}`;
}
