<div class="appointment-select" [ngClass]="{ disabled: selectedStudent }">
  <div class="pl-menu">
    <button
      class="pl-menu-button"
      mat-button
      [ngClass]="t.menuOpen ? 'open' : 'closed'"
      #t="matMenuTrigger"
      [matMenuTriggerFor]="studentOptions">
      <span>{{ selectedCount }} selected</span>
      <pl-icon-new [name]="'arrow_drop_down'"></pl-icon-new>
    </button>
    <mat-menu
      #studentOptions="matMenu"
      backdropClass="is-dark"
      class="pl-menu-panel">
      <div class="pl-menu-content">
        <div class="student-search">
          <mat-form-field
            (click)="$event.stopPropagation()"
            color="accent"
            name="student-search">
            <mat-label>
              <span class="search-label"
                >Search for Students
                <mat-icon class="icon">search</mat-icon></span
              >
            </mat-label>
            <input
              [(ngModel)]="studentFilter"
              matInput
              (keyup)="filterStudentsList()" />
          </mat-form-field>
        </div>
        <ng-container *ngIf="filteredEvents.length > 0">
          <label class="group-header">Scheduled students</label>
          <button
            mat-menu-item
            class="student-button"
            [ngClass]="{
              'selected-menu-item':
                item.client.uuid === selectedStudent?.client.uuid
            }"
            *ngFor="let item of filteredEvents"
            (click)="onSelectStudent(item)">
            {{ item.label }}
          </button>
          <hr />
        </ng-container>
        <label class="group-header">Unscheduled students</label>
        <button
          mat-menu-item
          class="student-button"
          [ngClass]="{
            'selected-menu-item':
              item.client.uuid === selectedStudent?.client.uuid
          }"
          *ngFor="let item of filteredClients"
          (click)="onSelectStudent(item)">
          {{ item.label }}
        </button>
      </div>
    </mat-menu>
  </div>
</div>
