import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { GameStartEndBtnsModule } from '../game-start-end-btns';

import { PLGuessWhoDrawerComponent } from './pl-guess-who-drawer.component';
import { PLGuessWhoBoardComponent } from './pl-guess-who-board.component';

@NgModule({
  imports: [CommonModule, MatSlideToggleModule, GameStartEndBtnsModule],
  exports: [PLGuessWhoBoardComponent, PLGuessWhoDrawerComponent],
  declarations: [PLGuessWhoDrawerComponent, PLGuessWhoBoardComponent],
  providers: [],
})
export class PLGuessWhoModule {}
