import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UIRouterModule } from '@uirouter/angular';

import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { DesignSystemPageComponent } from './design-system-page/design-system-page.component';
import { DesignSystemFormsComponent } from './design-system-forms/design-system-forms.component';
import { DesignSystemButtonsComponent } from './design-system-buttons/design-system-buttons.component';
import { DesignSystemTypographyComponent } from './design-system-typography/design-system-typography.component';
import { DesignSystemTabsComponent } from './design-system-tabs/design-system-tabs.component';
import { designSystemState } from './design-system.states';
import { DesignSystemCommonComponent } from './design-system-common/design-system-common.component';

import { PLCommonComponentsModule } from '@common/components';
import { PLDrawersModule } from '@modules/room/pl-drawers';
import { PLColorPickerModule } from '@common/components/pl-colorpicker';
import { PLWindowWarningModule } from '@common/components/window-warning';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatGridListModule,
    MatCardModule,
    MatDividerModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatToolbarModule,
    MatStepperModule,
    MatSelectModule,
    MatRadioModule,
    MatDialogModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatTabsModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    FormsModule,
    ReactiveFormsModule,
    PLColorPickerModule,
    PLCommonComponentsModule,
    PLDrawersModule,
    PLWindowWarningModule,
    UIRouterModule.forChild({
      states: [designSystemState],
    }),
  ],
  declarations: [
    DesignSystemPageComponent,
    DesignSystemButtonsComponent,
    DesignSystemFormsComponent,
    DesignSystemTypographyComponent,
    DesignSystemTabsComponent,
    DesignSystemCommonComponent,
  ],
})
export class DesignSystemModule {}
