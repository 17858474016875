import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { DismissService } from './dismiss.service';

@Component({
  selector: 'pl-dismiss-action',
  templateUrl: 'dismiss-action.component.html',
  providers: [DismissService],
})
export class DismissActionComponent {
  disabled$: Observable<boolean>;
  constructor(private dismissService: DismissService) {
    this.disabled$ = dismissService.canDismiss$;
  }

  dismiss() {
    this.dismissService.dismiss();
  }
}
