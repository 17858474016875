<div class="session-duration-select pl-input-time">
  <div class="select-and-options">
    <div #input class="select-button" (click)="toggleOptionsOpen()">
      <pl-input-text
        required
        placeholder="HH:MM"
        [(model)]="model"
        [iconRight]="'chevron-down'"
        [iconRightScale]="0.6"
        [iconRightVerticalAlign]="'-3px'"
        (onFocus)="onFocusInput()"
        (onBlur)="onBlurInput($event)"
        [disabled]="disabled"
        pattern="([01]?[0-9]|2[0-3]):[0-5][0-9]"
        (onChange)="onChange()"
      >
      </pl-input-text>
    </div>
    <pl-input-dropdown
      class="dropdown"
      [hidden]="!hasOptionsOpened"
      (onBlur)="onBlurDropdown($event)"
    >
      <div #dropdown class="options-container">
        <div class="options">
          <div
            *ngFor="let option of options"
            class="option"
            (click)="selectOption(option)"
          >
            {{ option.label }}
          </div>
        </div>
      </div>
    </pl-input-dropdown>
  </div>
</div>
