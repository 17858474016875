import { Injectable } from '@angular/core';
import { Notifications } from '@app/common/services/notifications.service';
import { Store } from '@ngrx/store';
import { FirebaseService } from '@root/src/app/common/firebase/firebase.service';
import { AppState } from '@root/src/app/store';
import { BehaviorSubject } from 'rxjs';
import {
  AppActions,
  CentralContentType,
  selectIsCurrentCentralContent,
} from '../app/store';

export enum BoomCardsMode {
  standard = 'standard',
  boomLive = 'boomLive',
}

@Injectable()
export class BoomCardsService {
  private ref: firebase.database.Reference;
  private _isActive = false;
  isBoomCardsActive$ = new BehaviorSubject(false);
  private _mode = BoomCardsMode.standard;
  boomCardsMode$ = new BehaviorSubject(BoomCardsMode.standard);
  studentUrl$ = new BehaviorSubject('');
  private boomCardsOnCentralContent = false;

  constructor(
    private notifications: Notifications,
    private firebaseService: FirebaseService,
    private store: Store<AppState>,
  ) {
    this.ref = this.firebaseService.getRoomRef('app/boomcards');
    this.ref.on('value', this.onValue.bind(this));
    this.store
      .select(selectIsCurrentCentralContent(CentralContentType.BoomCards, null))
      .subscribe(isCurrent => {
        this.boomCardsOnCentralContent = isCurrent;
      });
  }

  async toggleBoomCards(value: boolean) {
    this._isActive = value;
    try {
      await this.ref.update({
        isActive: this._isActive,
      });
    } catch (err) {
      this.notifications.show({
        text: 'Something went wrong... Please try again.',
        type: 'error',
      });
    }
  }

  async setStudentUrl(studentUrl: string) {
    try {
      await this.ref.update({
        studentUrl,
      });
    } catch (err) {
      this.notifications.show({
        text: 'Something went wrong... Please try again.',
        type: 'error',
      });
    }
  }

  async setBoomcardsMode(mode: BoomCardsMode) {
    try {
      await this.ref.update({
        mode,
      });
    } catch (err) {
      this.notifications.show({
        text: 'Something went wrong... Please try again.',
        type: 'error',
      });
    }
  }

  private onValue(snap: firebase.database.DataSnapshot) {
    snap.forEach(s => {
      if (s.key === 'isActive') {
        this._isActive = s.val();
        this.isBoomCardsActive$.next(this._isActive);
        if (!this._isActive) {
          this.setStudentUrl('');
        }
      } else if (s.key === 'studentUrl') {
        this.studentUrl$.next(s.val());
      } else if (s.key === 'mode') {
        this._mode = s.val();
        this.boomCardsMode$.next(this._mode);
        if (this.boomCardsOnCentralContent) {
          this.store.dispatch(
            AppActions.setCentralContent({
              contentType: CentralContentType.BoomCards,
              contentDetail: this._mode || BoomCardsMode.standard,
            }),
          );
        }
      }
    });
  }
}
