import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription, of } from 'rxjs';
import {
  map,
  distinctUntilChanged,
  concatMap,
  withLatestFrom,
  filter,
} from 'rxjs/operators';
import { CurrentUserModel } from '@common/models/CurrentUserModel';
import { FirebaseAppModel } from '@common/models/firebase/firebase-app-model.service';
import { AppState } from '@root/src/app/store';
import {
  selectIPadGuestsWithYoutubeInteractionPending,
  SessionActions,
  selectIsLocalParticipantGuestIPad,
} from '@room/session/store';
import { RoomConferenceService } from '@room/conference';

import { PLActivityModelService } from '@root/src/app/modules/room/pl-activity/model/activity-model.service';
import { PlAppGlobalService } from '@common/services/pl-app-global.service';
import { PLHijackHelperService } from '@common/services/pl-hijack.service';
import {
  PLYoutubeFactoryService,
  YoutubePlayer,
} from './pl-youtube-factory.service';
import { IPadSupportService } from '@common/services/ipad-support.service';
import { ResizeObserverService } from '@common/services';
import { selectIsYoutubeActive } from '@room/app/store';

@Component({
  selector: 'pl-youtube-player',
  templateUrl: 'pl-youtube-player.component.html',
  styleUrls: ['./pl-youtube-player.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class PLYoutubePlayerComponent implements OnInit, OnDestroy {
  videoId = null;
  updateTimeInterval = null;
  saveTimeToFBInterval = null;
  isDraggingScrubber = false;
  refs: any = {};
  // plAppGlobalService = new PlAppGlobalService(this, window, this.plHijackHelper);
  roomGlobal: any;
  isClinician: boolean;
  playerReady: boolean;
  blockPlayButton: boolean;
  isVideoBlocked: boolean;
  iPadStudentsPendingYoutubeInteraction: string;
  muted = false;
  playing = false;
  progressPercentage = 0;
  currentSecond = 0;
  subscriptions: Subscription[] = [];
  body: any;
  playerContainer: any;
  player: any;
  playerWidth: any;
  playerHeight: any;
  marginTop: any;
  marginLeft: any;
  youTube: YoutubePlayer;
  currentTime = '';
  totalTime = '';
  timeRef: any;
  private RANGE = 2;

  constructor(
    private currentUserModel: CurrentUserModel,
    private activityModel: PLActivityModelService,
    private firebaseAppModel: FirebaseAppModel,
    private store: Store<AppState>,
    private plHijackHelper: PLHijackHelperService,
    private plYoutubeFactory: PLYoutubeFactoryService,
    private iPadService: IPadSupportService,
    private ngrxStoreService: Store<AppState>,
    private conferenceService: RoomConferenceService,
    private resizeObserverService: ResizeObserverService,
    private plAppGlobalService: PlAppGlobalService,
  ) {}

  ngOnInit(): void {
    this.player = document.querySelector('.player');
    this.playerContainer = $('.pl-youtube-player');

    this.roomGlobal = this.plAppGlobalService.getWindowGlobal();
    this.isClinician =
      this.currentUserModel.user.isClinicianOrExternalProvider();
    const whiteboardActiveInitialState =
      this.firebaseAppModel.app.whiteboardActive;

    this.youTube = this.plYoutubeFactory.newYoutube(this.player);
    const containerEl = document.querySelector('.pl-youtube-player');
    this.subscriptions.push(
      this.resizeObserverService.observe(containerEl).subscribe(() => {
        this.resizeVideo();
      }),

      this.ngrxStoreService
        .select(selectIPadGuestsWithYoutubeInteractionPending)
        .pipe(
          map(students => students.map(s => s.displayName).join(', ')),
          distinctUntilChanged(),
        )
        .subscribe(label => {
          this.iPadStudentsPendingYoutubeInteraction = label;
        }),

      this.youTube.playerReady$.subscribe(() => {
        if (whiteboardActiveInitialState) {
          this.firebaseAppModel.setWhiteboardActive(false);
        }
        this.playerReady = true;
        this.setupReferences();
        this.refs.videoId.set(this.activityModel.activity.config);
        if (this.iPadService.isIPadStudent()) {
          this.isVideoBlocked = true;
          setTimeout(() => {
            this.setYoutubeInteractionPending(true);
            setTimeout(() => {
              $('.ipad-video-overlay').fadeIn();
            }, 500);
          }, 1);
        } else {
          $('.player').show();
        }
      }),

      this.youTube.stateChanged$.subscribe(data => {
        if (data && data.state === 'ended') {
          this.youTube.seek(0);
          this.play(false);
        }
      }),
      // PL-2146
      // YouTube on an iPad will stop (locally) when incoming conference streams startup, which
      // can leave the group of participants out of sync (some playing and some paused).
      // Work around this by deliberately pausing youtube for all participants to allow
      // the provider to resume in a controlled, synchronized manner.
      this.conferenceService
        .onJoined()
        .pipe(
          concatMap(ev => {
            return of(ev).pipe(
              withLatestFrom(
                this.ngrxStoreService.select(selectIsLocalParticipantGuestIPad),
              ),
            );
          }),
          filter(
            ([_, isIPadStudent]) =>
              isIPadStudent && !this.blockPlayButton && this.playing,
          ),
        )
        .subscribe(() => {
          this.play(false);
        }),
      this.youtubeActiveChanged(),
    );

    this.activityModel.foundationLoaded.then(this.initialize);
  }

  youtubeActiveChanged() {
    return this.store.select(selectIsYoutubeActive).subscribe(isActive => {
      if (!isActive && this.playing) {
        this.play(false);
      }
    });
  }

  initialize = () => {
    this.progressPercentage = 0;
    this.muted = false;
    this.playing = false;
    this.currentSecond = 0;

    this.updateTimeInterval = setInterval(this.updateTime, 100);
    this.saveTimeToFBInterval = setInterval(this.saveTimeToFB, 1000);

    this.youTube.loadLibrary().then(() => {
      this.resizeVideo();
    });

    if (this.activityModel.channel) {
      this.activityModel.channel.bind('play', () => {
        this.play('playing');
      });
    }
  };

  setYoutubeInteractionPending(isPending: boolean) {
    this.roomGlobal.showingYoutubeLootBox = isPending;
    this.ngrxStoreService.dispatch(
      SessionActions.setYoutubeInteractionPending({
        isPending,
      }),
    );
  }

  jumpTo(evt) {
    const targetVideoTime =
      (this.getScrubberPercentage(evt) / 100) * this.youTube.getDuration();
    this.refs.time.set(targetVideoTime);
  }

  onPressScrubber(evt) {
    this.isDraggingScrubber = true;
    this.progressPercentage = this.getScrubberPercentage(evt);
  }

  play(state) {
    this.refs.state?.child('playing')?.set(state);
    this.refs.time?.set(this.youTube.getCurrentTime());
  }
  /*
//TODO: Fix the resolver and reinject currentUserModel before re-enabling this.
    $window.addEventListener('beforeunload', function() {
        if(currentUserModel.user.isClinician()) {
            scope.play(false);
            $log.debug('[YouTubeDirective] pausing video on clinician exit');
        }
    });
*/
  mute(state) {
    this.refs.state?.child('muted').set(state);
  }

  // This is ipad-student side.
  activateYoutubeFromInteraction(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.roomGlobal.showingYoutubeLootBox = false;
    this.setYoutubeInteractionPending(false);
    $('.ipad-video-overlay').hide();
    $('.player').show();
    this.isVideoBlocked = false;
    this.blockPlayButton = true;
    // Local playback cannot be initiated remotely without a local user interaction.
    // The user interaction must be directly associated to the player by invoking playback
    // Also, we want the local result to end in a paused state to allow for the participants
    // to sync times and to give the provider a chance to control video playback.
    this.youTube.play();
    setTimeout(() => {
      this.play(false);
      this.youTube.pause();
      setTimeout(() => {
        this.blockPlayButton = false;
      }, 500);
    }, 500);
  }

  resizeVideo = () => {
    this.playerWidth = this.playerContainer.width() - 30;
    this.playerHeight = (this.playerWidth * 9) / 16;
    if (this.playerHeight > this.playerContainer.height() - 74) {
      this.playerHeight = this.playerContainer.height() - 74;
      this.playerWidth = (this.playerHeight * 16) / 9;
    }
    this.marginTop = this.playerHeight / 2 + 22;
    this.marginLeft = this.playerWidth / 2;
  };

  setupReferences = () => {
    const sessionId = this.activityModel.getSessionId();
    const base = `activities/sessions/${sessionId}/${this.activityModel.activity.configId}`;

    this.refs.videoId = this.activityModel.getRef(base + '/videoId');
    this.refs.videoId.on('value', this.handleVideoId, this.handleActivityError);

    this.refs.state = this.activityModel.getRef(base + '/state');
    this.refs.state.on(
      'value',
      this.handleActivityData,
      this.handleActivityError,
    );

    this.refs.time = this.activityModel.getRef(base + '/time');
    this.refs.time.on(
      'value',
      this.handleTimeActivityData,
      this.handleActivityError,
    );
  };

  destroyReferences() {
    if (this.refs) {
      if (this.refs.videoId) {
        this.refs.videoId.off('value', this.handleVideoId);
      }
      if (this.refs.state) {
        this.refs.state.off('value', this.handleActivityData);
      }
      if (this.refs.time) {
        this.refs.time.off('value', this.handleTimeActivityData);
      }
    }
  }

  handleActivityError(error) {
    console.error('[YouTubeComponent] activity ref load error:' + error.code);
  }

  handleVideoId = snap => {
    if (!this.playerReady) {
      return false;
    }
    this.videoId = snap.val();
    if (this.videoId) {
      this.youTube.cueVideo(this.videoId);
    } else {
      console.error('No video id found.');
    }
  };

  handleActivityData = snap => {
    if (!this.playerReady) {
      return false;
    }

    const sharedData = snap.val();

    if (!sharedData) {
      return;
    }

    // existing shared data has priority over the defaults
    this.playing =
      sharedData.playing !== undefined ? sharedData.playing : this.playing;
    this.muted = sharedData.muted !== undefined ? sharedData.muted : this.muted;

    const state = this.youTube.getState();
    if (this.playing) {
      this.youTube.play();
    } else if (!this.playing && state !== 'videoQueued') {
      // if the video is queued and you call pause, the video goes blank
      this.youTube.pause();
    }

    if (this.muted) {
      this.youTube.mute(true);
    } else {
      this.youTube.mute(false);
    }
  };

  timeDeltaInRange(serverTime, playerTime, range) {
    return Math.abs(serverTime - playerTime) < range;
  }

  handleTimeActivityData = snap => {
    if (!this.playerReady || snap.val() === null) {
      return false;
    }

    const timeFromServer = snap.val();
    if (typeof timeFromServer === 'undefined') {
      return;
    }

    const playerTime = this.youTube.getCurrentTime();

    if (this.timeDeltaInRange(timeFromServer, playerTime, this.RANGE)) {
      return false;
    }

    this.currentSecond = timeFromServer;

    // youtube api has a bug. When you seek to second x from second y, and immediately query
    // the currentsecond, it returns y, not x. Hack to fix is to start and then pause.
    if (!this.playing) {
      this.youTube.play();
    }

    this.youTube.seek(this.currentSecond);

    if (!this.playing) {
      this.youTube.pause();
    }
  };

  saveTimeToFB = () => {
    if (!this.playerReady) {
      return false;
    }
    if (this.timeRef) {
      this.refs.time.set(this.youTube.getCurrentTime());
    }
  };

  updateTime = () => {
    if (this.isDraggingScrubber) {
      return false;
    }
    if (!this.playerReady) {
      return false;
    }

    let currentSeconds = 0;
    if (this.youTube.getCurrentTime) {
      currentSeconds = Math.round(this.youTube.getCurrentTime());

      const minute = Math.floor(currentSeconds / 60);
      let seconds: any = currentSeconds % 60;
      if (seconds < 10) {
        seconds = '0' + seconds;
      }

      this.currentTime = `${minute}:${seconds}`;

      this.progressPercentage =
        100 * (currentSeconds / this.youTube.getDuration());
    }

    this.setTotalTime();
  };

  setTotalTime() {
    if (this.youTube.getDuration) {
      const durationSeconds = Math.round(this.youTube.getDuration());

      const minute = Math.floor(durationSeconds / 60);
      let seconds: any = durationSeconds % 60;
      if (seconds < 10) {
        seconds = '0' + seconds;
      }

      this.totalTime = `${minute}:${seconds}`;
    }
  }

  onMoveScrubber(evt) {
    if (this.isDraggingScrubber) {
      this.progressPercentage = this.getScrubberPercentage(evt);
    }
  }
  onReleaseScrubber(evt) {
    if (this.isDraggingScrubber) {
      this.jumpTo(evt);
      this.isDraggingScrubber = false;
    }
  }

  getScrubberPercentage(evt) {
    const target = $('.track')[0];
    const rect = target.getBoundingClientRect();
    const relativeLeft = evt.pageX - rect.left;
    return Math.max(
      0,
      Math.min(100, (relativeLeft / target.offsetWidth) * 100),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
    if (this.youTube) {
      this.youTube.destroy();
    }
    if (
      this.activityModel.getSessionRef &&
      this.activityModel.getSessionRef()
    ) {
      this.activityModel.getSessionRef().off();
    }

    if (this.iPadService.isIPadStudent()) {
      this.roomGlobal.showingYoutubeLootBox = false;
      this.setYoutubeInteractionPending(false);
    }
    if (this.firebaseAppModel.app.whiteboardActive) {
      this.firebaseAppModel.setWhiteboardActive(true);
    }
    clearInterval(this.updateTimeInterval);
    clearInterval(this.saveTimeToFBInterval);
    this.destroyReferences();
  }
}
