<div class="pl-image-save">
  <pl-input-text
    class="form-input"
    [(model)]="imageData.url"
    [label]="'Image URL'"
  ></pl-input-text>
  <img class="image" [src]="imageData.url" crossorigin="anonymous" />
  <pl-input-text
    class="form-input"
    [(model)]="imageData.title"
    [label]="'Title'"
  ></pl-input-text>
  <pl-loader *ngIf="loading"></pl-loader>
  <button
    *ngIf="!loading"
    pl-button
    class="primary"
    [disabled]="!imageData.title || !imageData.url"
    (click)="save()"
  >
    Save
  </button>
</div>
