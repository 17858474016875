import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store';
import { Subscription } from 'rxjs';
import { FirebaseModel } from '@common/models/firebase/FirebaseModel';
import { selectTeamWrite } from '../../../team-write/store/team-write.selectors';
import {
  RESET_TEAM_WRITE_DOC,
  NEW_TEAM_WRITE_DOC,
  LOAD_TEAM_WRITE_DOC,
  DELETE_TEAM_WRITE_DOC,
  TRASH_TEAM_WRITE_DOC,
  RESTORE_TEAM_WRITE_DOC,
  SAVE_TEAM_WRITE_DOC,
} from '../../../team-write/store/team-write.actions';

@Component({
  selector: 'pl-team-write-drawer',
  templateUrl: './pl-team-write-drawer.component.html',
  styleUrls: ['./pl-team-write-drawer.component.less'],
})
export class PLTeamWriteDrawerComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  teamWriteActive: boolean;
  teamWriteReady = false;
  documentsRef: any;
  currentDocumentRef: any;
  documents = [];
  currentDocument: any;

  _showDocuments = false;
  _showTrashedDocuments = false;
  readonly showDocumentsLocalStorageKey = 'pl-team-write-show-documents';
  readonly showTrashedDocumentsLocalStorageKey =
    'pl-team-write-show-trashed-documents';
  addNewHovering = false;
  loadingDocumentKey: any;
  loadWaiter: NodeJS.Timeout;

  constructor(
    private firebaseModel: FirebaseModel,
    private store: Store<AppState>,
  ) {}

  ngOnInit() {
    this.subscriptions.push(this.selectTeamWriteStore());
    this.initDocuments();
    this._showDocuments =
      localStorage.getItem(this.showDocumentsLocalStorageKey) === 'true';
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  get showDocuments() {
    return this._showDocuments;
  }

  set showDocuments(val) {
    this._showDocuments = val;
    localStorage.setItem(
      this.showDocumentsLocalStorageKey,
      val ? 'true' : 'false',
    );
  }

  get showTrashedDocuments() {
    return this._showTrashedDocuments;
  }

  set showTrashedDocuments(val) {
    this._showTrashedDocuments = val;
    localStorage.setItem(
      this.showTrashedDocumentsLocalStorageKey,
      val ? 'true' : 'false',
    );
  }

  private selectTeamWriteStore(): Subscription {
    return this.store.select(selectTeamWrite).subscribe((data: any) => {
      if (data.key === '') {
        return;
      }

      const { key, isReady } = data;

      if (this.loadingDocumentKey === key) {
        this.loadingDocumentKey = null;
        return;
      }
      if (isReady) {
        this.teamWriteReady = true;
        return;
      }
    });
  }

  showDocumentsClicked = () => {
    this.showDocuments = !this.showDocuments;
  };

  showTrashedDocumentsClicked = () => {
    this.showTrashedDocuments = !this.showTrashedDocuments;
  };

  initDocuments = () => {
    this.documentsRef = this.firebaseModel.getFirebaseRef(
      'teamWrite/documents',
    );
    this.currentDocumentRef = this.firebaseModel.getFirebaseRef(
      'teamWrite/currentDocument',
    );

    this.documentsRef.on('value', snapshot => {
      this.documents = [];
      snapshot.forEach(childSnapshot => {
        const nextVal = childSnapshot.val();
        const nextDoc = {
          key: childSnapshot.key,
          created: nextVal.created,
          modified: nextVal.modified,
          name: nextVal.name,
          trashed: nextVal.trashed,
        };
        this.documents.push(nextDoc);
      });
      this.documents = this.documents.sort((a, b) => {
        return b.modified - a.modified;
      });
      if (this.currentDocument) {
        this.updateCurrent();
      }
    });

    this.currentDocumentRef.on('value', snapshot => {
      const update = snapshot.val();
      if (update && update.key) {
        this.documentsRef.child(update.key).once('value', docSnap => {
          this.currentDocument = docSnap.val();
          if (this.currentDocument) {
            this.currentDocument.key = snapshot.val().key;
            this.updateCurrent();
          }
        });
      }
    });
  };

  private updateCurrent() {
    this.documents = this.documents.map(doc => {
      doc.current = doc.key === this.currentDocument.key;
      return doc;
    });
  }

  reset() {
    this.store.dispatch({
      type: RESET_TEAM_WRITE_DOC,
      payload: { reset: true },
    });
  }

  save() {
    this.store.dispatch({ type: SAVE_TEAM_WRITE_DOC, payload: { save: true } });
  }

  new() {
    this.showDocuments = true;
    this.store.dispatch({
      type: NEW_TEAM_WRITE_DOC,
      payload: { newDoc: true },
    });
  }

  loadDocument({ key }) {
    if (this.loadingDocumentKey) {
      return;
    }
    this.loadingDocumentKey = key;
    this.store.dispatch({
      type: LOAD_TEAM_WRITE_DOC,
      payload: { key: key, loadingDoc: true },
    });

    if (this.loadWaiter) {
      clearTimeout(this.loadWaiter);
    }
    this.loadWaiter = setTimeout(() => {
      this.loadingDocumentKey = null;
    }, 10 * 1000);
  }

  deleteDocument(key) {
    this.store.dispatch({
      type: DELETE_TEAM_WRITE_DOC,
      payload: { key: key, deletingDoc: true },
    });
  }

  trashDocument(key) {
    this.store.dispatch({
      type: TRASH_TEAM_WRITE_DOC,
      payload: { key: key, trashingDoc: true },
    });
  }

  restoreDocument(key) {
    this.store.dispatch({
      type: RESTORE_TEAM_WRITE_DOC,
      payload: { key: key, restoringDoc: true },
    });
  }

  nameChanged(doc) {
    if (doc.key === this.currentDocument?.key) {
      this.currentDocument.name = doc.name;
    }

    this.documentsRef.child(doc.key).update({ name: doc.name });
  }
}
