<div class="pdf-activity-drawer" data-testid="activity-pdf-drawer">
  <mat-tab-group
    class="pdf-activity-tabs is-compact"
    mat-stretch-tabs
    color="accent"
    (selectedIndexChange)="onTabChange($event)"
  >
    <mat-tab label="Pages">
      <pl-pdf-drawer-pages
        [currentPage]="currentPage"
        [totalPages]="totalPages"
        [bookMode]="bookMode"
        [hasThumbs]="hasThumbs"
        [thumbs]="thumbs"
        [pageRotations]="pageRotations"
        (goToPage)="gotoPage($event)"
        (pdfLoaded)="onPDFLoaded($event)"
      ></pl-pdf-drawer-pages>
    </mat-tab>

    <mat-tab label="Display">
      <pl-pdf-drawer-display
        [scale]="currentScale"
        [totalPages]="totalPages"
        [bookMode]="bookMode"
        [canSwitchMode]="true"
        [canZoom]="true"
        [canRotate]="true"
        (zoom)="zoomTo($event)"
        (switchMode)="switchModeTo($event)"
        (rotate)="rotate90()"
      ></pl-pdf-drawer-display>
    </mat-tab>
  </mat-tab-group>

  <pl-pdf-drawer-paginator
    [page]="currentPage"
    [totalPages]="totalPages"
    [bookMode]="bookMode"
    (pageSelected)="gotoPage($event)"
  ></pl-pdf-drawer-paginator>
</div>
