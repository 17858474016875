<div class="padding" *ngIf="error">
  <pl-alert-message>
    <ng-container [ngSwitch]="error">
      <ng-container *ngSwitchCase="appointmentErrors.overlappingSameAssignment">
        We have detected an issue with overlapping events on your schedule that
        will prevent clients from being documented. Please check your calendar
        for today and correct any events from different assignments that are
        overlapping. Once you have made your corrections, refresh the list to
        complete documentation.
        <button
          mat-flat-button
          color="primary"
          class="w-100"
          (click)="onViewSchedule()">
          <span>View schedule</span>
          <pl-icon-new [name]="'open_in_new'"></pl-icon-new>
        </button>
        <button
          mat-button
          color="primary"
          class="w-100"
          (click)="onChatWithSupport()">
          Chat with Support...
        </button>
      </ng-container>
      <ng-container *ngSwitchDefault>
        {{ error }}
      </ng-container>
    </ng-container>
  </pl-alert-message>
</div>
