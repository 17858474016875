import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { CurrentUserModel } from '../../common/models/CurrentUserModel';
import { AppState } from '../../store';
import { selectIsCentralContentVisible } from '../room/app/store';
import { PLDiceWidgetService } from '../room/pl-widgets/pl-dice-widget/pl-dice-widget.service';
import { PLSpinnerWidgetService } from '../room/pl-widgets/pl-spinner-widget/pl-spinner-widget.service';
import { PLWidgetsBoardModelService } from '../room/pl-widgets/pl-widgets-board/pl-widgets-board-model.service';
import { FirebaseService } from '../../common/firebase/firebase.service';

@Component({
  selector: 'pl-games',
  templateUrl: './pl-games.component.html',
  styleUrls: ['./pl-games.component.less'],
})
export class PlGamesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public active = false;
  @Input() public game;
  boardGamesStateRef;
  widget;
  private isWidgetVisible = false;
  private subscriptions: Subscription[] = [];
  private currentGameIdRef;
  private gameId;
  constructor(
    private widgetsBoardModel: PLWidgetsBoardModelService,
    public firebaseService: FirebaseService,
    private spinnerWidgetService: PLSpinnerWidgetService,
    private diceWidgetService: PLDiceWidgetService,
    private store: Store<AppState>,
    private currentUserModel: CurrentUserModel,
  ) {
    this.subscriptions.push(
      this.store.select(selectIsCentralContentVisible).subscribe(isVisible => {
        if (this.active && (!isVisible || this.gameId)) {
          this.toggleWidgets(isVisible);
        }
      }),
    );
  }

  ngOnInit(): void {
    this.boardGamesStateRef =
      this.firebaseService.getRoomRef('games/boardGames');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      !this.boardGamesStateRef ||
      !this.currentUserModel.user.isClinicianOrExternalProvider()
    ) {
      return;
    }
    const gameChange = changes.game;
    if (
      this.active &&
      gameChange &&
      gameChange.currentValue &&
      (!gameChange.previousValue ||
        gameChange.previousValue.name !== gameChange.currentValue.name)
    ) {
      this.removeWidgets();
      this.setupGameWidgets();
      this.isWidgetVisible = true;
      if (this.currentGameIdRef) {
        this.currentGameIdRef.off('value', this.gameIdUpdated);
      }
      this.currentGameIdRef = this.firebaseService.getRoomRef(
        `games/boardGames/${gameChange.currentValue.name}/currentGameId`,
      );
      this.currentGameIdRef.on('value', this.gameIdUpdated);
    } else if (!this.active && this.isWidgetVisible) {
      this.removeWidgets();
      this.isWidgetVisible = false;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  gameIdUpdated = snap => {
    const newId = snap.val();
    // hide widgets if game is made inactive after being active
    if (this.gameId && !newId) {
      this.toggleWidgets(false);
    }
    // show widgets if game is made active after being inactive
    if (!this.gameId && newId) {
      this.toggleWidgets(true);
    }
    // otherwise, don't do anything. toggling every time leads to duplicate widgets
    this.gameId = newId;
  };

  setupGameWidgets() {
    this.boardGamesStateRef
      .child('currentWidgets')
      .once('value', currentWidgetsSnap => {
        currentWidgetsSnap.forEach(childSnap => {
          const id = childSnap.val();
          this.widgetsBoardModel.deleteWidgetById(id);
        });

        let widgetConfig: any = {};
        let widgetX;
        let widgetY;
        const widget = this.game.widget;
        const widgetType = this.game.widgetType; // support deprecated "widgetType"
        if (widget) {
          if (widget.type === 'spinner-widget') {
            widgetConfig = { ...this.spinnerWidgetService.config };
          } else if (widget.type === 'dice-widget') {
            widgetConfig = { ...this.diceWidgetService.config };
          }
          widgetX = widget.startX;
          widgetY = widget.startY;
        } else if (widgetType) {
          if (widgetType === 'spinner-widget') {
            widgetConfig = { ...this.spinnerWidgetService.config };
            widgetX = 0.43;
            widgetY = 0.4;
          } else if (widgetType === 'dice-widget') {
            widgetConfig = { ...this.diceWidgetService.config };
            widgetX = 0.4;
            widgetY = 0.85;
          }
        }
        widgetConfig.hidden = !this.gameId;
        if (widget || widgetType) {
          const widgetRef = this.widgetsBoardModel.createWidgetProportional(
            widgetConfig,
            widgetX,
            widgetY,
          );
          this.boardGamesStateRef.child('currentWidgets').remove();
          this.boardGamesStateRef.child('currentWidgets').push(widgetRef.key);

          widgetRef.once('value', snap => {
            this.widget = snap.val();
            this.widget.key = widgetRef.key;
          });
        }
      });
  }

  removeWidgets() {
    this.boardGamesStateRef.child('currentWidgets').once('value', snap => {
      snap.forEach(childSnap => {
        const id = childSnap.val();
        this.widgetsBoardModel.deleteWidgetById(id);
      });
    });
  }

  toggleWidgets(val) {
    if (this.widget) {
      this.widget.hidden = !val;
      if (this.currentUserModel.user.isClinicianOrExternalProvider()) {
        this.widgetsBoardModel.updateWidgetByKey(this.widget.key, this.widget);
      }
    }
  }
}
