import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { AppState } from '@app/store';
import { AppModel } from '@common/models/app-model.service';
import { FirebaseAppModel } from '@common/models/firebase/firebase-app-model.service';
import { LeavingPageService } from '@common/window/services';

import {
  CentralContentType,
  selectIsCentralContentVisible,
  selectIsCurrentCentralContent,
} from '@room/app/store';
import { selectIsScreenshareActive } from '../../conference/screenshare/store';
import { ScreenshareService } from '../../conference/screenshare/screenshare.service';
import {
  SiteshareService,
  SiteShareMode,
} from '@app/modules/siteshare/siteshare.service';
import { BoomCardsService } from '../../pl-boomcards/boomcards.service';
import {
  FeatureFlag,
  FeatureFlagsService,
} from '@root/src/app/common/services/feature-flags';

export enum ContentShareMode {
  SCREENSHARE = 'ScreenShare',
  SITESHARE = 'SiteShare',
  BOOMCARDS = 'BoomCards',
  BOOMCARDS_SITESHARE = 'BoomCards_SiteShare',
}
@Component({
  selector: 'pl-content-sharing-drawer',
  templateUrl: 'pl-content-sharing-drawer.component.html',
  styleUrls: ['pl-content-sharing-drawer.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class PLContentSharingDrawerComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  isSiteShareActive$: Observable<boolean>;
  isSiteShareLoading$: Observable<boolean>;
  detailMode: ContentShareMode;

  isBoomCardsSiteShareActive$: Observable<boolean>;
  isBoomCardsActive$: Observable<boolean>;
  canBoomCardsSiteShare$: Observable<boolean>;
  canBoomCardsIframe$: Observable<boolean>;
  isScreenshareActive$: Observable<boolean>;

  constructor(
    public appModel: AppModel,
    public firebaseAppModel: FirebaseAppModel,
    public store: Store<AppState>,
    private siteshareService: SiteshareService,
    private leavingPageService: LeavingPageService,
    screenshareService: ScreenshareService,
    private boomCardsService: BoomCardsService,
    featureFlagsService: FeatureFlagsService,
  ) {
    this.isScreenshareActive$ = store.select(selectIsScreenshareActive);
    // if we're screensharing but the central content is not visible, we should mute the screenshare
    combineLatest([
      this.isScreenshareActive$,
      store.select(selectIsCentralContentVisible),
      store.select(
        selectIsCurrentCentralContent(CentralContentType.ContentShare, null),
      ),
    ])
      .pipe(
        map(([active, visible, contentActive]) => {
          return active && visible && contentActive;
        }),
      )
      .subscribe(result => {
        if (result) {
          screenshareService.unmute();
        } else {
          screenshareService.mute();
        }
      });

    this.isSiteShareActive$ = this.siteshareService.isSiteShareActive$;
    this.isSiteShareLoading$ = this.siteshareService.isLoading$;

    this.isBoomCardsSiteShareActive$ =
      this.siteshareService.isBoomCardsSiteShareActive$;
    this.isBoomCardsActive$ = this.boomCardsService.isBoomCardsActive$;

    this.canBoomCardsSiteShare$ = featureFlagsService.isFeatureEnabled(
      FeatureFlag.BOOMCARDS_SITESHARE,
    );
    this.canBoomCardsIframe$ = featureFlagsService.isFeatureEnabled(
      FeatureFlag.BOOMCARDS_IFRAME,
    );
  }

  get ContentShareMode() {
    return ContentShareMode;
  }

  ngOnInit() {
    this.subscriptions.push(this.listenToPageUnload());
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private listenToPageUnload() {
    return this.leavingPageService.onLeavingPage().subscribe(() => {
      this.siteshareService.stop();
      this.appModel.toggleScreenshare(false);
    });
  }

  enterDetailMode(mode: ContentShareMode) {
    this.detailMode = mode;
  }

  leaveDetail() {
    this.detailMode = null;
  }

  toggleSiteshare(value: boolean) {
    this.siteshareService.toggleSiteshare(value, SiteShareMode.DEFAULT);
  }
  toggleBoomCardsSiteshare(value: boolean) {
    this.siteshareService.toggleSiteshare(value, SiteShareMode.BOOMCARDS);
  }

  toggleScreenshare(value: boolean) {
    this.appModel.toggleScreenshare(value);
  }
}
