import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import { MatRippleModule } from '@angular/material/core';

import { PLIconModule } from '@root/index';
import { PLGameModule } from '@root/src/app/modules/room/pl-activity/game';

import { PLMemoryActivityModule } from '@modules/room/pl-activity/pl-memory-activity';
import { PLPDFActivityModule } from '@modules/room/pl-activity/pl-pdf-activity';
import { PLYoutubeActivityModule } from '@modules/room/pl-activity/pl-youtube-activity';
import { PLPDFAssessmentModule } from '@modules/room/pl-activity/pl-pdf-assessment';
import { ActivitySwitchModule } from '@modules/room/pl-drawers/activity-switch';
import { PLActivityModelService } from '@modules/room/pl-activity/model/activity-model.service';
import { PLAssessmentModelService } from '@modules/room/pl-activity/model/assessment-model.service';
import { PLLessonModelService } from '@modules/room/pl-activity/model/lesson-model.service';
import { PLAssessmentService } from '@modules/room/pl-activity/services/assessment.service';
import { PLPDFViewerDrawerComponent } from '@modules/room/pl-activity/pl-pdf-activity/drawer/pdf-viewer-drawer.component';
import { PLPDFLessonViewerDrawerComponent } from '@modules/room/pl-lesson/pl-pdf-lesson/drawer/pdf-lesson-viewer-drawer.component';
import { PLPDFAssessmentDrawerComponent } from '@modules/room/pl-activity/pl-pdf-assessment/drawer/pdf-assessment-drawer.component';
import { PLFlashCardsDrawerModule } from '@modules/room/pl-activity/flashcards/drawer';

import { PLQueueAddModule } from './pl-queue-add';
import { PLActivityDrawerComponent } from './pl-activity-drawer/pl-activity-drawer.component';
import { PdfDrawerPaginatorComponent } from './pdf-drawer-paginator/pdf-drawer-paginator.component';
import { PdfDrawerDisplayComponent } from './pdf-drawer-display/pdf-drawer-display.component';
import { PdfDrawerPagesComponent } from './pdf-drawer-pages/pdf-drawer-pages.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { PLQueueComponent } from './pl-queue.component';
import { PLQueuesListComponent } from './pl-queues-list/pl-queues-list.component';
import { PLQueueDetailComponent } from './pl-queue-detail/pl-queue-detail.component';
import { PLCommonComponentsModule } from '../../common/components';
import { PLQueuesDeletedListComponent } from './pl-queues-deleted-list/pl-queues-deleted-list.component';
import { PLQueueNameFormComponent } from './pl-queue-name-form/pl-queue-name-form.component';
import { PLQueueActivitiesComponent } from './pl-queue-activities/pl-queue-activities.component';
import { PLImageDecksModule } from '../room/pl-image-decks';

@NgModule({
  imports: [
    ActivitySwitchModule,
    CommonModule,
    DragDropModule,
    FormsModule,
    MatMenuModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatSliderModule,
    MatTooltipModule,
    MatButtonModule,
    MatTabsModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSlideToggleModule,
    PLIconModule,
    PLPDFActivityModule,
    PLYoutubeActivityModule,
    PLMemoryActivityModule,
    PLGameModule,
    PLQueueAddModule,
    PLImageDecksModule,
    PLPDFAssessmentModule,
    PLFlashCardsDrawerModule,
    PLCommonComponentsModule,
  ],
  exports: [
    PLQueueComponent,
    PLPDFViewerDrawerComponent,
    PLPDFLessonViewerDrawerComponent,
    PLPDFAssessmentDrawerComponent,
  ],
  declarations: [
    PLActivityDrawerComponent,
    PLQueueComponent,
    PLQueuesListComponent,
    PLQueueDetailComponent,
    PLQueuesDeletedListComponent,
    PLQueueNameFormComponent,
    PLQueueActivitiesComponent,
    PLPDFViewerDrawerComponent,
    PLPDFLessonViewerDrawerComponent,
    PLPDFAssessmentDrawerComponent,
    PdfDrawerPaginatorComponent,
    PdfDrawerDisplayComponent,
    PdfDrawerPagesComponent,
  ],
  providers: [
    PLActivityModelService,
    PLAssessmentModelService,
    PLAssessmentService,
    PLLessonModelService,
  ],
})
export class PLQueueModule {}
