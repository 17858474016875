import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { PLUrlsService } from '@common/services/pl-http';
import { IntrojsService } from '@common/services';
import { LiveagentService } from '@common/services/LiveagentService';
import { AppState } from '@root/src/app/store';
import { environment } from '@root/src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  selectCurrentUser,
  selectIsLimitedSaasAccess,
} from '../../../user/store';
import { RoomnameModel } from '@common/models/RoomnameModel';

@Component({
  selector: 'pl-help-drawer',
  templateUrl: 'pl-help-drawer.component.html',
  styleUrls: ['./pl-help-drawer.component.less'],
})
export class PLHelpDrawerComponent {
  version = '';
  isProvider$: Observable<boolean>;
  isLimitedSaasAccess$: Observable<boolean>;

  constructor(
    store: Store<AppState>,
    private plUrls: PLUrlsService,
    private liveagentService: LiveagentService,
    private introjsService: IntrojsService,
    private roomnameModel: RoomnameModel,
  ) {
    this.isProvider$ = store
      .select(selectCurrentUser)
      .pipe(
        map(
          user =>
            (user && user.groups && user.groups.indexOf('Provider') > -1) ||
            user.groups.indexOf('Service & Support') > -1 ||
            user.groups.indexOf('School Staff Providers') > -1 ||
            user.groups.indexOf('Private Practice') > -1,
        ),
      );
    this.isLimitedSaasAccess$ = store.select(selectIsLimitedSaasAccess);
    this.version = environment.git_sha ? environment.git_sha.slice(0, 4) : '';
  }

  onComputerSetup() {
    this.openLink(this.plUrls.urls.techcheckFE);
  }

  onSupportChat() {
    this.liveagentService.startChat();
  }

  onHelpCenter() {
    this.openLink(this.plUrls.urls.helpDocsFE);
  }

  onStartTour() {
    this.introjsService.roomOverview();
  }

  onTelehealthInstitute() {
    this.openLink(this.plUrls.urls.telehealthInstituteFE);
  }

  onFirebaseClick(event) {
    if (event && event.altKey) {
      const firebaseName = this.roomnameModel.value.name;
      const firebaseShardUrl = this.roomnameModel.value.firebase_baseurl;
      const firebaseURL = `https://${firebaseShardUrl}/${firebaseName}`;
      this.openLink(firebaseURL, true);
    }
  }

  private openLink(url, newWindow = true) {
    if (newWindow) {
      window.open(url, '_blank');
    } else {
      window.location.href = url;
    }
  }
}
