<div class="drawer-guesswho">
  <div class="settings">
    <div class="settings-toggle">
      Auto select avatar
      <mat-slide-toggle
        [checked]="autoSelect"
        (change)="toggleAutoSelect()"
        data-testid="guess-who-auto-select-toggle"
      >
        {{ autoSelect ? 'On' : 'Off' }}
      </mat-slide-toggle>
    </div>
    <div class="settings-toggle">
      Clinician X-ray
      <mat-slide-toggle
        [checked]="xRay"
        [disabled]="!gameStarted"
        (change)="toggleXRay()"
        data-testid="guess-who-xray-toggle"
      >
        {{ xRay ? 'On' : 'Off' }}
      </mat-slide-toggle>
    </div>
  </div>
  <game-start-end-btns
    [canStartGame]="!gameStarted"
    [isGameActive]="gameStarted"
    (clickNewGame)="toggleGame()"
    (clickEndGame)="toggleGame()"
  ></game-start-end-btns>
</div>
