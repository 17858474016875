<header class="dialog-header">
  <h2 class="dialog-header__title heading-2">
    {{ data.header }}
  </h2>

  <button mat-icon-button mat-dialog-close class="dialog-header__close-btn">
    <mat-icon>close</mat-icon>
  </button>
</header>

<mat-dialog-content class="dialog-content">
  <div class="dialog-content__text" [innerHTML]="data.content"></div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-stroked-button mat-dialog-close color="primary">Cancel</button>

  <button
    mat-raised-button
    mat-dialog-close
    cdkFocusInitial
    color="primary"
    (click)="handleConfirm()"
  >
    {{ data.confirmLabel || 'Confirm' }}
  </button>
</mat-dialog-actions>
