<div class="scenes-drawer" data-testid="drawer-scenes">
  <mat-tab-group
    mat-stretch-tabs
    class="scenes-drawer__tabs is-compact"
    color="accent"
    [selectedIndex]="currentTabIndex$ | async"
    (selectedIndexChange)="onTabChange($event)"
  >
    <mat-tab label="Background">
      <pl-scenes-backgrounds
        [selectedSceneName]="selectedSceneName$ | async"
        [sceneOptions]="sceneOptions"
        [isPreviewAvailable]="activity.type !== 'game-potato-head'"
        [isPreviewEnabled]="isPreviewEnabled"
        (togglePreview)="togglePreview()"
        (selectedSceneNameChange)="setSelectedSceneName($event)"
      ></pl-scenes-backgrounds>
    </mat-tab>
    <mat-tab label="Stickers">
      <pl-scenes-stickers
        [selectedStickerPackName]="selectedStickerPackName$ | async"
        [stickerOptions]="stickerOptions"
        [stickerPackOptions]="selectedStickerPackButtons"
        [areStickersVisible]="stickersVisible"
        [canControlStickers]="
          (this.isActivityActive$ | async) === true && !isPreviewEnabled
        "
        (addSticker)="addSticker($event)"
        (addAllStickers)="addAllStickers()"
        (areStickersVisibleChange)="setStickersVisible($event)"
        (selectedStickerPackNameChange)="setSelectedStickerPackName($event)"
      ></pl-scenes-stickers>
    </mat-tab>
  </mat-tab-group>

  <footer class="scenes-drawer__footer">
    <button mat-stroked-button color="primary" (click)="resetGame()">
      Reset game
    </button>
    <button
      mat-stroked-button
      color="primary"
      (click)="clearTrayStickers()"
      [disabled]="
        isPreviewEnabled ||
        !stickersVisible ||
        (hasStickersInTray$ | async) === false ||
        (isActivityActive$ | async) === false
      "
    >
      Clear tray
    </button>
  </footer>
</div>
