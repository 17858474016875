<div
  class="stream-box"
  [class.is-promoted]="isPromoted$ | async"
  [ngStyle]="styles"
>
  <pl-stream-display-name></pl-stream-display-name>
  <pl-stream-video-box></pl-stream-video-box>

  <div class="toolbar" #toolbar>
    <ng-content></ng-content>
  </div>
</div>
