import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DeletedQueueItem } from '../pl-queue.component';

@Component({
  selector: 'pl-queues-deleted-list',
  templateUrl: './pl-queues-deleted-list.component.html',
  styleUrls: ['./pl-queues-deleted-list.component.less'],
})
export class PLQueuesDeletedListComponent {
  @Input() queues: DeletedQueueItem[];
  @Input() hardDeleteTime: number;
  @Input() hardDeleteUnit: string;

  @Output() readonly restoreQueue = new EventEmitter<{ queueId: string }>();
}
