import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { RoomConferenceService } from '@room/conference';
import { RECORD_STREAM_ID } from './tokbox-record.service';

@Component({
  selector: 'pl-session-preview',
  templateUrl: 'pl-session-preview.component.html',
  styleUrls: ['pl-session-preview.component.less'],
})
export class PLSessionPreviewComponent {
  public mediaStream$: Observable<MediaStream>;

  constructor(conferenceService: RoomConferenceService) {
    this.mediaStream$ = conferenceService.getMediaStream(RECORD_STREAM_ID);
  }
}
