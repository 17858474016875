import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { FilterVideoType } from '../../../../store/conference.model';
import { FilterVideoService } from './filter-video.service';
import {
  FeatureFlagsService,
  FeatureFlag,
} from '@root/src/app/common/services/feature-flags';

@Component({
  selector: 'pl-filter-video',
  templateUrl: 'filter-video.component.html',
  styleUrls: ['filter-video.component.less'],
  providers: [FilterVideoService],
})
export class FilterVideoComponent {
  readonly FilterVideoType = FilterVideoType;
  filterMode$: Observable<string>;
  canBackgroundBlur$: Observable<boolean>;

  constructor(
    public filterVideoService: FilterVideoService,
    featureFlagsService: FeatureFlagsService,
  ) {
    this.filterMode$ = filterVideoService.filterMode$;
    this.canBackgroundBlur$ = featureFlagsService.isFeatureEnabled(
      FeatureFlag.BACKGROUND_BLURRING,
    );
  }

  filterChanged(event) {
    if (event.value === FilterVideoType.BLUR) {
      this.filterVideoService.blur();
    } else if (event.value === FilterVideoType.BACKGROUND) {
      this.filterVideoService.backgroundImage();
    } else {
      this.filterVideoService.clearFilter();
    }
  }
}
