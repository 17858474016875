<div
  *ngIf="{ drawerState: drawerSubscription | async } as drawerData"
  class="drawer-right"
  data-testid="drawer-right"
  [class.in]="drawerData.drawerState.open">
  <ng-container
    *ngIf="
      drawerData.drawerState.open &&
      drawerData.drawerState.activeName === 'activities'
    ">
    <pl-queue *plHasPermission="'PERMS_DEFAULT_PROVIDERS'"></pl-queue>
  </ng-container>

  <pl-games-drawer
    [hidden]="
      !drawerData.drawerState.open ||
      drawerData.drawerState.activeName !== 'games'
    "
    *plHasPermission="'PERMS_DEFAULT_PROVIDERS'"></pl-games-drawer>

  <ng-container
    *ngIf="isNewDocumentationEnabled$ | async; else legacyDocumentation">
    <pl-new-documentation-drawer
      [hidden]="
        !drawerData.drawerState.open ||
        drawerData.drawerState.activeName !== 'document'
      "
      *plHasPermission="
        'PERMS_DEFAULT_PROVIDERS'
      "></pl-new-documentation-drawer>
  </ng-container>
  <ng-template #legacyDocumentation>
    <pl-documentation-drawer
      [hidden]="
        !drawerData.drawerState.open ||
        drawerData.drawerState.activeName !== 'document'
      "
      *plHasPermission="'PERMS_DEFAULT_PROVIDERS'"></pl-documentation-drawer>
  </ng-template>

  <pl-team-write-drawer
    [hidden]="
      !drawerData.drawerState.open ||
      drawerData.drawerState.activeName !== 'teamWrite'
    "
    *plHasPermission="'PERMS_DEFAULT_PROVIDERS'"></pl-team-write-drawer>

  <pl-content-sharing-drawer
    [hidden]="
      !drawerData.drawerState.open ||
      drawerData.drawerState.activeName !== 'contentSharing'
    "
    *plHasPermission="'PERMS_DEFAULT_PROVIDERS'"></pl-content-sharing-drawer>

  <pl-chat-drawer
    [hidden]="
      !drawerData.drawerState.open ||
      drawerData.drawerState.activeName !== 'chat'
    "></pl-chat-drawer>

  <ng-container *ngIf="(isNewDocumentationEnabled$ | async) === false">
    <pl-session-record-drawer
      [hidden]="
        !drawerData.drawerState.open ||
        drawerData.drawerState.activeName !== 'sessionRecord'
      "
      *plHasPermission="
        'Therapist || Administrator'
      "></pl-session-record-drawer>
  </ng-container>
</div>
<pl-right-toolbar></pl-right-toolbar>
