import { Subscription } from 'rxjs';
import {
  Component,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  AfterViewInit,
  ViewChildren,
  QueryList,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store';
import { AppModel } from '@common/models/app-model.service';
import { User } from '@modules/user/user.model';
import { selectCurrentUser } from '@modules/user/store';
import {
  selectGroupedMessages,
  ChatMessageGroup,
  selectIsMuted,
} from './store';
import { PLChatDrawerService } from './pl-chat-drawer.service';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { selectHostUserId } from '../../session/store';
@Component({
  selector: 'pl-chat-drawer',
  templateUrl: 'pl-chat-drawer.component.html',
  styleUrls: ['pl-chat-drawer.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class PLChatDrawerComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('messagesContainer') messagesContainerEl: ElementRef<HTMLElement>;
  @ViewChildren('message') messagesEl: QueryList<HTMLElement>;
  private subscriptions: Subscription[] = [];
  public messages: ChatMessageGroup[] = [];
  private showClearVerification: boolean = false;
  public canUsersChat: boolean = true;
  public user: User;
  public hostUserId: string;

  public newMessage = '';

  get preventStudentType() {
    const { groups = [] } = this.user;
    return groups.includes('student') && !this.canUsersChat;
  }

  constructor(
    private appModel: AppModel,
    private store: Store<AppState>,
    private chatService: PLChatDrawerService,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.subscriptions.push(this.subscribeToUser());
    this.subscriptions.push(this.subscribeToGroupedMessages());
    this.subscriptions.push(this.subscribeToIsMuted());
    this.subscriptions.push(this.subscribeToHostUserId());
    // workaround for @ngrx/effects
    this.chatService.setupEffects();
  }

  ngAfterViewInit() {
    this.subscriptions.push(
      this.messagesEl.changes
        .pipe(
          map(list => list.length),
          distinctUntilChanged(),
        )
        .subscribe(() => {
          const containerEl = this.messagesContainerEl.nativeElement;
          containerEl.scrollTo({
            behavior: 'smooth',
            top: 0,
          });
        }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => {
      s.unsubscribe();
    });
    // workaround for @ngrx/effects
    this.chatService.clearEffects();
  }

  subscribeToUser(): Subscription {
    return this.store.select(selectCurrentUser).subscribe(user => {
      this.user = user;
    });
  }

  subscribeToGroupedMessages(): Subscription {
    return this.store.select(selectGroupedMessages).subscribe(messages => {
      this.messages = messages.reverse();
      this.sendChatInteraction();
      this.cd.detectChanges();
    });
  }

  subscribeToIsMuted(): Subscription {
    return this.store.select(selectIsMuted).subscribe(canUsersChat => {
      this.canUsersChat = canUsersChat;
      this.cd.detectChanges();
    });
  }

  subscribeToHostUserId(): Subscription {
    return this.store.select(selectHostUserId).subscribe(hostUserId => {
      this.hostUserId = hostUserId;
    });
  }

  onModelChange() {
    this.cd.detectChanges();
  }

  sendChatInteraction() {
    this.appModel.resetChatTimeout();
  }

  sendMessage(ev: KeyboardEvent) {
    ev.preventDefault();

    this.sendChatInteraction();

    this.newMessage = this.newMessage.trim();

    if (!this.newMessage) {
      return;
    }

    const messageData = {
      message: this.newMessage,
      userUuid: this.user.uuid,
      userName: this.user.display_name,
    };
    this.chatService.sendMessage(messageData);
    this.newMessage = '';
  }

  setChatMuted() {
    // TODO: Change this when effects work
    // TODO: Implement some feedback logic
    this.chatService.setIsMuted(this.canUsersChat);
  }

  clearMessages() {
    // TODO: Change this when effects work
    // TODO: Implement some feedback logic
    this.chatService.clearMessages();
    this.showClearVerification = false;
  }

  toggleShowClearVerification() {
    this.showClearVerification = !this.showClearVerification;
  }
}
