import { Component, OnDestroy } from '@angular/core';
import { PLGuessWhoService } from './pl-guess-who.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'pl-guess-who-drawer',
  templateUrl: './pl-guess-who-drawer.component.html',
  styleUrls: ['./pl-guess-who-drawer.component.less'],
})
export class PLGuessWhoDrawerComponent implements OnDestroy {
  autoSelect: boolean = false;
  xRay: boolean = false;
  gameStarted: boolean = false;
  subscriptions: Subscription[] = [];

  constructor(private guessWhoService: PLGuessWhoService) {
    this.subscriptions.push(
      this.guessWhoService.gameStarted$.subscribe(
        gameStarted => (this.gameStarted = gameStarted),
      ),
    );
    this.subscriptions.push(
      this.guessWhoService.xRayStatus$.subscribe(xRay => (this.xRay = xRay)),
    );
    this.subscriptions.push(
      this.guessWhoService.autoSelectStatus$.subscribe(
        autoSelect => (this.autoSelect = autoSelect),
      ),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  toggleXRay() {
    this.xRay = !this.xRay;
    this.guessWhoService.setXray(this.xRay);
  }

  toggleAutoSelect() {
    this.autoSelect = !this.autoSelect;
    this.guessWhoService.setAutoSelect(this.autoSelect);
  }

  toggleGame() {
    this.gameStarted = !this.gameStarted;
    this.guessWhoService.setGameStarted(this.gameStarted);
  }
}
