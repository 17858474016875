import { Component } from '@angular/core';

@Component({
  selector: 'pl-design-system-buttons',
  templateUrl: './design-system-buttons.component.html',
  styleUrls: ['./design-system-buttons.component.scss'],
})
export class DesignSystemButtonsComponent {
  showIcons = false;
}
