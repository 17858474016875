<div
  [ngClass]="{
    'hover-click':
      investigationObj.isAsking &&
      investigationObj.witness.userId === currentUser.userId
  }"
  *ngFor="let card of currentUser.confidentialCards"
  class="card-in-hand"
>
  <div class="check-container" *ngIf="cardReveal.card.name === card.name">
    <img
      src="/assets/board-games/clue_game_assets/white_check_green_back_circle.svg"
    />
  </div>

  <div (click)="updateCardReveal($event)" class="w-100">
    <img
      class="w-100"
      [attr.card-name]="card.name"
      [attr.card-icon]="card.icon"
      src="{{ card.icon }}"
    />
  </div>
  <h4>
    {{ card.name }}
  </h4>
</div>
