import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import {
  billingCodes,
  documentationHelpUrl,
} from '../presence-documentation-constants.service';

import { ClientAppointmentsService } from '../client-appointments.service';
import { PLUrlsService } from '@root/src/app/common/services/pl-http';
import {
  BillingCode,
  ClientAppointment,
  ClientGQLData,
  ClientService,
} from '../../documentation.models';
import { PLApiLocationsService } from '@root/src/app/common/services/api';
import { Location } from '@common/services/api/pl-api-locations.service';
import { PLGQLClientsService } from '@root/src/app/common/services/pl-graph-ql';

@Component({
  selector: 'pl-service-details',
  templateUrl: './service-details.component.html',
  styleUrls: ['./service-details.component.less'],
})
export class ServiceDetailsComponent implements OnInit, OnChanges {
  @Input() public requiresService = true;
  @Input() clientAppointment: ClientAppointment;
  @Input() services: ClientService[] = [];

  @Input() serviceSelectOptions: { value: string; label: string }[] = [];
  @Input() selectedServiceId: string;
  @Input() billingCodeSelectOptions: { value: string; label: string }[] = [];
  @Input() selectedBillingCode: BillingCode;
  @Input() isEvaluation: boolean;

  @Output() public openEventDetails = new EventEmitter<void>();

  @Output() readonly serviceSelected = new EventEmitter<string>();
  @Output() readonly billingCodeSelected = new EventEmitter<string>();
  @Output() readonly makeUpChoiceChanged = new EventEmitter<boolean>();

  timeRange: string = '';
  urls: { [key: string]: string } = {};
  documentationHelpUrl: string;
  selectedBillingCodeLabel: string = '';
  selectedBillingCodeId: string = '';
  selectedServiceLabel: string = '';
  client: ClientGQLData;

  promptForMakeUpSession: boolean = true;
  isMakeUpSessionRequired: boolean = false;
  recordLocation: Location;

  constructor(
    private appointmentsService: ClientAppointmentsService,
    private plUrls: PLUrlsService,
    private locationService: PLApiLocationsService,
    private plGQLClients: PLGQLClientsService,
  ) {
    this.documentationHelpUrl = documentationHelpUrl;
  }

  ngOnInit() {
    this.urls = this.plUrls.urls;
  }

  ngOnChanges(): void {
    if (this.clientAppointment) {
      this.updateTimeRange();
      this.getLocationDetails();
      this.isMakeUpSessionRequired =
        this.clientAppointment.record?.is_makeup_session_required;
      this.getClient();
    }
    if (this.selectedBillingCode && this.billingCodeSelectOptions) {
      this.selectedBillingCodeId = this.selectedBillingCode.uuid;
      const selected = this.billingCodeSelectOptions.find(
        option => option.value === this.selectedBillingCodeId,
      );
      this.selectedBillingCodeLabel = selected ? selected.label : '';
      this.updateRSMAbsencePrompt();
    }
    if (this.selectedServiceId && this.serviceSelectOptions) {
      const selected = this.serviceSelectOptions.find(
        option => option.value === this.selectedServiceId,
      );
      this.selectedServiceLabel = selected ? selected.label : '';
    }
  }
  getClient() {
    if (this.clientAppointment && this.clientAppointment.uuid) {
      this.plGQLClients
        .getById(this.clientAppointment.uuid)
        .subscribe((resClient: any) => {
          this.client = resClient.client;
        });
    }
  }

  getLocationDetails() {
    if (this.clientAppointment.record?.uuid) {
      this.locationService
        .get({ uuid: this.clientAppointment.record.location })
        .subscribe((res: any) => {
          this.recordLocation = res && res.length ? res[0] : {};
          this.updateRSMAbsencePrompt();

          // TODO - we may need to add this back in, was done in the previous documentation
          // if (this.recordLocation && this.recordLocation.record_tracking_type) {
          //   if (!this.client.record.tracking_type) {
          //     this.selectedTrackingType = 'regular';
          //     this.selectTrackingType();
          //   } else {
          //     this.selectedTrackingType = this.client.record.tracking_type;
          //   }
          // }
        });
    }
  }

  onOpenEventDetails() {
    this.openEventDetails.emit();
  }

  openServices() {
    const url = `${this.urls.eduClientsFE}/client/${this.clientAppointment.uuid}/services`;
    window.open(url, '_blank');
  }

  updateTimeRange() {
    const start = this.appointmentsService.getFormattedTime(
      this.clientAppointment.appointment.start,
    );
    const end = this.appointmentsService.getFormattedTime(
      this.clientAppointment.appointment.end,
    );
    this.timeRange = `${start} - ${end}`;
  }

  onBillingCodeSelected(value) {
    this.billingCodeSelected.emit(value);
  }

  selectedService(value) {
    this.serviceSelected.emit(value);
  }

  updateRSMAbsencePrompt() {
    const absent24Code = billingCodes.STUDENT_ABSENCE_LT_24_HR;
    const isAbsent24 = this.selectedBillingCode?.code === absent24Code;
    const isRsmSchool = this.recordLocation?.is_rsm_school;
    this.promptForMakeUpSession = isRsmSchool && isAbsent24;
  }

  onMakeUpChoiceChanged(event) {
    this.makeUpChoiceChanged.emit(event.value);
  }
}
