import { AppState } from '@app/store';
import { createSelector } from '@ngrx/store';

export const selectTeamWrite = (state: AppState) => state.teamWrite;

// these are single selector values. not currently being used. an example if we further refactor team write store
export const selectTeamWriteReady = createSelector(
  selectTeamWrite,
  teamWrite => teamWrite.isReady,
);

export const selectTeamWriteDocLoaded = createSelector(
  selectTeamWrite,
  teamWrite => teamWrite.isDocLoaded,
);

export const selectTeamWriteKey = createSelector(
  selectTeamWrite,
  teamWrite => teamWrite.key,
);
