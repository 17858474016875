import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RoomConferenceService } from '../../modules/room/conference';
import { CurrentUserModel } from '../models/CurrentUserModel';
import { AuthenticationService } from '../models/users/AuthenticationService';
import { Store } from '@ngrx/store';
import { AppState } from '../../store';
import { environment } from '@root/src/environments/environment';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';

/**
 * Manages access to protected digital content
 */
@Injectable({ providedIn: 'root' })
export class RightsMgmtService {
  assets = {};
  rightsPromise = null;
  response: any;

  constructor(
    private http: HttpClient,
    private currentUserModel: CurrentUserModel,
    private conferenceService: RoomConferenceService,
    private authenticationService: AuthenticationService,
    private ngrxStoreService: Store<AppState>,
  ) {}

  /**
   * Get the transient url to a protected asset given a key, assessment id,
   * and user token. Clinician tokens are their authentication jwt token.
   * student tokens are fetched by clinicians and stored in firebase.
   *
   * A null token implies a call from a clinician who's token is already
   * in the header.
   *
   * @param assessmentId
   * @param assetkey
   * @param token
   * @returns {*|Promise.<T>}
   */

  getProtectedContentUrl(assessmentId, assetkey, token, isLesson?) {
    const service = isLesson ? 'lesson' : 'assessment';
    let url =
      environment.apps.platform.url +
      '/api/v1/' +
      service +
      '/' +
      assessmentId +
      '/download/';
    if (!isLesson) url += '?url=' + encodeURIComponent(assetkey);
    console.log('[RightsMgmtService] returning protected url: ' + url);
    let headers;
    const useToken =
      this.currentUserModel.jwt || (this.currentUserModel.user as any).token;
    if (useToken != null && useToken !== '') {
      headers = { Authorization: `JWT ${useToken}` };
    }
    return this.http.get<any>(url, { headers }).pipe(
      map(data => {
        this.assets[assetkey] = data.url;
        return this;
      }),
    );
  }

  /**
   * Ok here's what happens with this call....
   *
   * 1. If the student tries to get an asset url with a bad token
   * (via getProtectedContentUrl), the call to get the url returns a 401.
   * 2. The authinterceptor then catches that and invokes this method.
   * 3. This sends a tokbox signal with the student's name and browerId
   * 4. On the clinician side, the handleJWTRequests() method in
   *    room.component is listening for this signal and that update will
   *    trigger the clinicians app to get a new token
   * 5. The clinician app will signal back the new token
   * 6. Once that is complete the handler here will trigger with a non-null
   *    value
   * 7. When we have the new token we'll retry the original request again
   *    (see the handler below)
   *
   * (Or you know, we could instead log students into an account, check their
   *   perms, return a valid token and avoid all of this.)
   * @param rightsPromise
   * @param response
   */
  resetCredentials(rightsPromise, response) {
    this.rightsPromise = rightsPromise;
    this.response = response;

    const data = JSON.stringify({
      name: this.currentUserModel.user.getName(),
      browserId: this.currentUserModel.user.uuid,
    });

    const subscription = this.conferenceService
      .onCommand('jwt-update')
      .subscribe(event => {
        const newToken = event.data;
        if (newToken !== '' && newToken !== undefined) {
          this.response.config.headers = {
            Authorization: `JWT ${newToken}`,
          };
          // TODO: Check this when in full ng2 app. Not sure if this is going to
          // work in hybrid app
          this.http
            .request(this.response.config)
            .subscribe(this.handleHttpResponse.bind(this));
          subscription.unsubscribe();
        }
      });

    this.conferenceService.command('update-jwt', data).subscribe();
  }

  /**
   * signal:update-jwt will be sent by RightsMgmtService from a student in the
   * event the student's
   * token has expired and needs a refresh
   **/
  handleJWTRequests() {
    this.conferenceService
      .onCommand('update-jwt')
      .pipe(
        mergeMap(event => {
          const data = JSON.parse(event.data);
          const browserId = data.browserId;
          const name = data.name;
          const from = event.from;
          return of(from).pipe(
            withLatestFrom(
              this.authenticationService
                .getStudentToken(name, browserId)
                .pipe(catchError(() => of(null))),
            ),
          );
        }),
        mergeMap(([from, token]) => {
          if (!token) {
            return EMPTY;
          }
          return this.conferenceService
            .commandTo(from, 'jwt-update', token)
            .pipe(
              catchError(error => {
                if (error) {
                  console.log(`signal error (${error.name}): ${error.message}`);
                }
                return of(null);
              }),
            );
        }),
      )
      .subscribe();
  }

  handleHttpResponse(data) {
    const assetkey = this.findKeyFromUrl(data.config.url);
    this.assets[assetkey] = data.url;
    this.rightsPromise.resolve(data);
    this.rightsPromise = null;
  }

  findKeyFromUrl(url) {
    console.log('[RightsMgmtService] parsing url: ', url);
    let assetkey = '';
    const queryindex = url.indexOf('?url=');
    if (queryindex !== -1) {
      assetkey = url.substring(queryindex + 5);
    }
    console.log('[RightsMgmtService] found key: ', assetkey);
    return assetkey;
  }

  /**
   * This basically sleeps the thread if we've already started one.
   * @returns {boolean}
   */
  isResetPending() {
    if (this.rightsPromise != null) {
      return true;
    }
    return false;
  }
}
