<header class="mat-dialog-header">
  <h2 mat-dialog-title>
    {{ (isLocal$ | async) ? 'Personal' : 'Client' }} Settings
  </h2>
  <button mat-dialog-close mat-icon-button aria-label="Close settings dialog">
    <mat-icon>close</mat-icon>
  </button>
</header>

<mat-dialog-content>
  <pl-local-settings *ngIf="isLocal$ | async; else remote"></pl-local-settings>

  <ng-template #remote>
    <pl-remote-settings></pl-remote-settings>
  </ng-template>
</mat-dialog-content>
