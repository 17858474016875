<div class="design-system-page">
  <header class="demo-header">
    <h1 class="heading-1">Design System Preview</h1>

    <mat-button-toggle-group
      name="theme"
      [value]="isDarkThemeEnabled"
      (change)="onToggleDarkTheme($event.value)"
    >
      <mat-button-toggle [value]="false">Light</mat-button-toggle>
      <mat-button-toggle [value]="true">Dark</mat-button-toggle>
    </mat-button-toggle-group>
  </header>

  <mat-tab-group animationDuration="0ms" class="demo-tab-group">
    <mat-tab label="Buttons">
      <pl-design-system-buttons></pl-design-system-buttons>
    </mat-tab>

    <mat-tab label="Forms">
      <pl-design-system-forms></pl-design-system-forms>
    </mat-tab>

    <mat-tab label="Typography">
      <pl-design-system-typography></pl-design-system-typography>
    </mat-tab>

    <mat-tab label="Tabs">
      <pl-design-system-tabs></pl-design-system-tabs>
    </mat-tab>

    <mat-tab label="Misc">
      <div
        data-testid="design-system-misc"
        style="width: fit-content; max-width: 100%"
      >
        <div class="demo-group">
          <header class="demo-group-header">
            <h2 class="heading-2">Slide Toggle</h2>
          </header>

          <div class="demo-row" data-testid="slide-toggles">
            <div class="demo-col">
              <h3 class="heading-3">Off</h3>
              <mat-slide-toggle>Default</mat-slide-toggle>
              <mat-slide-toggle color="primary">Primary</mat-slide-toggle>
              <mat-slide-toggle color="accent">Accent</mat-slide-toggle>
              <mat-slide-toggle disabled>Disabled</mat-slide-toggle>
            </div>
            <div class="demo-col">
              <h3 class="heading-3">On</h3>
              <mat-slide-toggle [ngModel]="true">Default</mat-slide-toggle>
              <mat-slide-toggle [ngModel]="true" color="primary">
                Primary
              </mat-slide-toggle>
              <mat-slide-toggle [ngModel]="true" color="accent">
                Accent
              </mat-slide-toggle>
              <mat-slide-toggle [ngModel]="true" disabled>
                Disabled
              </mat-slide-toggle>
            </div>
          </div>
        </div>

        <div class="demo-group">
          <header class="demo-group-header">
            <h2 class="heading-2">Radio Buttons</h2>
          </header>
          <div class="demo-row" data-testid="radio-buttons">
            <div class="demo-col">
              <mat-radio-button
                [checked]="false"
                color="primary"
                name="primary-radio"
              >
                Primary
              </mat-radio-button>

              <mat-radio-button
                [checked]="false"
                color="accent"
                name="accent-radio"
              >
                Accent
              </mat-radio-button>

              <mat-radio-button
                [checked]="false"
                disabled
                name="disabled-radio"
              >
                Disabled
              </mat-radio-button>
            </div>
            <div class="demo-col">
              <mat-radio-button
                [checked]="true"
                color="primary"
                name="primary-radio"
              >
                Primary
              </mat-radio-button>

              <mat-radio-button
                [checked]="true"
                color="accent"
                name="accent-radio"
              >
                Accent
              </mat-radio-button>

              <mat-radio-button [checked]="true" disabled name="disabled-radio">
                Disabled
              </mat-radio-button>
            </div>
          </div>
        </div>

        <div class="demo-group">
          <header class="demo-group-header">
            <h2 class="heading-2">Modals</h2>
          </header>

          <div class="demo-row">
            <button
              mat-raised-button
              color="accent"
              (click)="openConfirmDialog()"
              data-testid="open-confirm-dialog"
            >
              Confirmation Dialog
            </button>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Custom">
      <pl-design-system-common></pl-design-system-common>
    </mat-tab>
  </mat-tab-group>
</div>
