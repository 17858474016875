<div data-testid="design-system-forms">
  <div class="demo-group">
    <header class="demo-group-header">
      <h2 class="heading-2">Basic Inputs</h2>
    </header>

    <div class="demo-row">
      <div class="demo-col">
        <h3 class="heading-3">&nbsp;</h3>
        <div class="label-77">Primary</div>
        <div class="label-77">Accent</div>
        <div class="label-77">Disabled</div>
      </div>
      <div class="demo-col">
        <h3 class="heading-3">No value</h3>

        <mat-form-field appearance="outline" color="primary">
          <mat-label>First Name</mat-label>
          <input matInput placeholder="Type Something" />
          <mat-hint>Example hint...</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline" color="accent">
          <mat-label>First Name</mat-label>
          <input matInput placeholder="Type Something" />
          <mat-hint>Example hint...</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>First Name</mat-label>
          <input matInput placeholder="Type Something" disabled />
          <mat-hint>Example hint...</mat-hint>
        </mat-form-field>
      </div>
      <div class="demo-col">
        <h3 class="heading-3">With value</h3>

        <mat-form-field appearance="outline" color="primary">
          <mat-label>First Name</mat-label>
          <input matInput placeholder="Type Something" value="Jon" />
          <mat-hint>Example hint...</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline" color="accent">
          <mat-label>First Name</mat-label>
          <input matInput placeholder="Type Something" value="Jon" />
          <mat-hint>Example hint...</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>First Name</mat-label>
          <input matInput placeholder="Type Something" value="Jon" disabled />
          <mat-hint>Example hint...</mat-hint>
        </mat-form-field>
      </div>
      <div class="demo-col">
        <h3 class="heading-3">With icon</h3>

        <mat-form-field appearance="outline" color="primary">
          <mat-label>First Name</mat-label>
          <mat-icon matPrefix>person</mat-icon>
          <input matInput placeholder="Type Something" />
        </mat-form-field>

        <mat-form-field appearance="outline" color="accent">
          <mat-label>First Name</mat-label>
          <mat-icon matPrefix>person</mat-icon>
          <input matInput placeholder="Type Something" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>First Name</mat-label>
          <mat-icon matPrefix>person</mat-icon>
          <input matInput placeholder="Type Something" disabled />
        </mat-form-field>
      </div>

      <div class="demo-col">
        <h3 class="heading-3">With options</h3>

        <mat-form-field appearance="outline" color="primary">
          <mat-label>Select option</mat-label>
          <mat-select>
            <mat-option value="option">Option 1</mat-option>
            <mat-option value="option">Option 2</mat-option>
            <mat-option value="option">Option 3</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" color="accent">
          <mat-label>Select option</mat-label>
          <mat-select>
            <mat-option value="option">Option 1</mat-option>
            <mat-option value="option">Option 2</mat-option>
            <mat-option value="option">Option 3</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Select option</mat-label>
          <mat-select disabled>
            <mat-option value="option">Option 1</mat-option>
            <mat-option value="option">Option 2</mat-option>
            <mat-option value="option">Option 3</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="demo-group">
    <header class="demo-group-header">
      <h2 class="heading-2">Inputs with prefix & suffix</h2>
    </header>

    <div class="demo-row">
      <mat-form-field appearance="outline" color="accent">
        <mat-label>Enter your password</mat-label>
        <input matInput [type]="hidePassword ? 'password' : 'text'" />
        <button
          mat-icon-button
          matSuffix
          (click)="hidePassword = !hidePassword"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hidePassword"
        >
          <mat-icon>{{
            hidePassword ? 'visibility_off' : 'visibility'
          }}</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field appearance="outline" color="accent">
        <mat-label>Search phrase</mat-label>
        <input matInput [(value)]="searchValue" />
        <mat-icon matPrefix>search</mat-icon>
        <button
          mat-icon-button
          matSuffix
          *ngIf="searchValue.length > 0"
          (click)="searchValue = ''"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field appearance="outline" floatLabel="always" color="accent">
        <mat-label>Amount</mat-label>
        <input
          matInput
          type="number"
          class="example-right-align"
          placeholder="0"
        />
        <span matPrefix>$&nbsp;</span>
        <span matSuffix>.00</span>
      </mat-form-field>
    </div>
  </div>

  <div class="demo-group">
    <header class="demo-group-header">
      <h2 class="heading-2">Inputs with error message</h2>
    </header>

    <div class="demo-row">
      <mat-form-field appearance="outline" color="accent">
        <mat-label>Enter your email</mat-label>
        <input
          matInput
          data-testid="input-with-error"
          placeholder="pat@example.com"
          [formControl]="email"
          required
        />
        <mat-error *ngIf="email.invalid">{{ getErrorMessage() }}</mat-error>
      </mat-form-field>
    </div>
  </div>
</div>
