import { Injectable } from '@angular/core';
import { filter, map, switchMap } from 'rxjs/operators';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store';

import { RoomConferenceService } from '../../room-conference.service';
import {
  selectConferenceLocalStreams,
  selectConferenceRemoteStreams,
  selectHasRemoteStreamJoined,
  selectIsStreamInSession,
} from '../conference.selectors';
import { ConferenceActions } from '../conference.actions';
import { ConferenceReconnectionService } from '@common/conference/conference-reconnection.service';

@Injectable({ providedIn: 'root' })
export class ConferenceConnectionRecoveryEffects {
  startMonitoringConnection$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConferenceActions.initSuccess),
      switchMap(() => {
        return this.reconnectionService
          .monitorConnection(this.conferenceService)
          .pipe(map(() => ConferenceActions.reconnected()));
      }),
    );
  });

  rejoin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConferenceActions.reconnected),
      concatLatestFrom(() => this.store.select(selectConferenceLocalStreams)),
      map(([, streams]) => streams.map(s => s.id)),
      switchMap(ids => ids.map(id => ConferenceActions.join({ id }))),
    );
  });

  resubscribeLocal$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConferenceActions.reconnected),
      concatLatestFrom(() => this.store.select(selectConferenceRemoteStreams)),
      map(([, streams]) => streams.map(s => s.id)),
      switchMap(ids => ids.map(id => ConferenceActions.subscribe({ id }))),
    );
  });

  resubscribeOnRemoteReconnection$ = createEffect(() => {
    return this.conferenceService.onRemoteStreamCreated().pipe(
      concatLatestFrom(id => [
        this.store.select(selectIsStreamInSession(id)),
        this.store.select(selectHasRemoteStreamJoined(id)),
      ]),
      filter(
        ([, isInSession, hasRemoteJoined]) => isInSession && hasRemoteJoined,
      ),
      map(([id]) => ConferenceActions.subscribe({ id })),
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private conferenceService: RoomConferenceService,
    private reconnectionService: ConferenceReconnectionService,
  ) {}
}
