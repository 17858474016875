export const allClueCards = {
    suspects: [
        {
            name: "Missy Scarlet",
            icon: "/assets/board-games/clue_game_assets/suspects/missy_scarlet.png",
            status: ""
        },
        {
            name: "Maya Peacock",
            icon: "/assets/board-games/clue_game_assets/suspects/maya_peacock.png",
            status: ""
        },
        {
            name: "Olivia White",
            icon: "/assets/board-games/clue_game_assets/suspects/olivia_white.png",
            status: ""
        },
        {
            name: "Peter Plum",
            icon: "/assets/board-games/clue_game_assets/suspects/peter_plum.png",
            status: ""
        },
        {
            name: "Ryan Green",
            icon: "/assets/board-games/clue_game_assets/suspects/ryan_green.png",
            status: ""
        },
        {
            name: "Colin Mustard",
            icon: "/assets/board-games/clue_game_assets/suspects/colin_mustard.png",
            status: ""
        }
    ],
    locations: [
        {
            name: "Library",
            icon: "/assets/board-games/clue_game_assets/locations/library.png",
            status: ""
        },
        {
            name: "Art Room",
            icon: "/assets/board-games/clue_game_assets/locations/art_room.png",
            status: ""
        },
        {
            name: "Chemistry Lab",
            icon: "/assets/board-games/clue_game_assets/locations/chemistry_lab.png",
            status: ""
        },
        {
            name: "Teacher's Lounge",
            icon: "/assets/board-games/clue_game_assets/locations/teachers_lounge.png",
            status: ""
        },
        {
            name: "Cafeteria",
            icon: "/assets/board-games/clue_game_assets/locations/cafeteria.png",
            status: ""
        }
    ],
    methods: [
        {
            name: "Fishing Hook",
            icon: "/assets/board-games/clue_game_assets/methods/fishing_hook.png",
            status: ""
        },
        {
            name: "Computer Hack",
            icon: "/assets/board-games/clue_game_assets/methods/hack.png",
            status: ""
        },
        {
            name: "Smart Phone",
            icon: "/assets/board-games/clue_game_assets/methods/smart_phone.png",
            status: ""
        },
        {
            name: "Telescope",
            icon: "/assets/board-games/clue_game_assets/methods/telescope.png",
            status: ""
        }
    ]
}

export interface Player {
    id: string;
    position: number;
    name: string;
    userId: string;
    confidentialCards: Card[];
    cardList: any;
    disabled: boolean;
    solvingTheCaseObj: {
        isSolving: boolean,
        skipSolving: boolean,
        answersSubmitted: boolean,
        answersObj: {
            suspect: Card,
            method: Card,
            location: Card
        }
    }
}

export interface Cards {
    suspects: Card[],
    locations: Card[],
    methods: Card[]
}

export interface Card {
    name: string,
    icon: string,
    status: string,
}

export interface ClueState {
    playersArr: Player[];
    disabledPlayersArr: Player[];
    enableStartGameBtn: boolean;
    enableEndGameBtn: boolean;
    playerTurn: number;
    showInstruction: boolean;
    showOverview: boolean;
    cards: Cards;
    currentUser: Player;
    cardReveal: CardReveal;
}

export interface CardReveal {
    card: {
        name: string;
        icon: string;
    };
    isCardSelected: boolean;
    revealCard: boolean;
}

export const initialState: ClueState = {
    playersArr: [],
    disabledPlayersArr: [],
    playerTurn: 1,
    enableEndGameBtn: false,
    enableStartGameBtn: false, 
    showInstruction: false,
    showOverview: false,
    cards: allClueCards,
    currentUser: {
        id: null,
        name: "",
        userId: "",
        position: null,
        confidentialCards: [
            { name: "", icon: "", status: "" },
            { name: "", icon: "", status: "" },
            { name: "", icon: "", status: "" }
        ],
        cardList: {},
        disabled: false,
        solvingTheCaseObj: {
            isSolving: false,
            skipSolving: false,
            answersSubmitted: false,
            answersObj: {
                suspect: { name: "", icon: "", status: "" },
                method: { name: "", icon: "", status: "" },
                location: { name: "", icon: "", status: "" }
            }
        }
    },
    cardReveal: {
        card: {
            name: "",
            icon: ""
        },
        isCardSelected: false,
        revealCard: false
    }
};

// ===================================================================================================================
// ======================================== Firebase Interfaces ======================================================
// ===================================================================================================================
export interface EndGameObj {
    winningHand: {
        suspect: Card,
        method: Card,
        location: Card
    },
    showWinningCards: boolean,
    winnersList: Player[],
    playersSkipped: Player[],
    defaultWin: boolean
}

export interface InvestigationObj {
    investigatorPosition: number,
    witnessPosition: number,
    responseReceived: any,
    isAsking: boolean,
    investigator: Player,
    witness: Player
}

export interface GhostObj {
    hasGhostPlayer: boolean,
    ghostCardsArr: Card[]
}
