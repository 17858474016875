<form (submit)="onSubmitQueueName($event)" class="queue-name-form">
  <input
    class="queue-name-input"
    #input
    name="queue-name"
    placeholder="Name this queue"
    [(ngModel)]="queueName"
    (blur)="onInputBlur()"
    (keydown.esc)="onInputESCKey()"
  />
</form>
