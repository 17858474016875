import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClientGoal, ClientObjective } from '../../../documentation-te.model';

@Component({
  selector: 'pl-documentation-te-metric',
  templateUrl: 'documentation-te-metric.component.html',
  styleUrls: ['./documentation-te-metric.component.less'],
})
export class DocumentationTEMetricComponent {
  @Input() public metric: ClientObjective;
  @Input() public goal: ClientGoal;
  @Input() public disabled = false;
  @Output() public readonly changed = new EventEmitter<any>();

  showGoal = false;

  toggleGoalDescription() {
    this.showGoal = !this.showGoal;
  }

  incrementCorrect() {
    this.metric.values.correct++;
    this.changed.emit(this.metric.values);
  }

  incrementWrong() {
    this.metric.values.incorrect++;
    this.changed.emit(this.metric.values);
  }

  blurInput() {
    this.changed.emit(this.metric.values);
  }

  keyInput(evt: KeyboardEvent) {
    if (evt.keyCode === 13) {
      this.changed.emit(this.metric.values);
    }
  }

  get accuracy() {
    const divider =
      this.metric.values.incorrect + this.metric.values.correct || 1;
    return ((this.metric.values.correct * 100) / divider).toFixed(0);
  }
}
