<div class="button-panel">
  <button
    mat-flat-button
    color="success"
    (click)="newGame()"
    [disabled]="!canStartGame || isGameActive || allDisabled"
  >
    New game
  </button>
  <button
    mat-flat-button
    color="warn"
    (click)="endGame()"
    [disabled]="!isGameActive || canStartGame || allDisabled"
  >
    End game
  </button>
</div>
