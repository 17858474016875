<div
  class="memory-drawer"
  *ngIf="isInitialized"
  [plDrop]=""
  (dropped)="handleDragCardCancel($event)"
  (dragCancel)="handleDragCardCancel($event)"
  (dragEnd)="handleDragCardEnd($event)"
>
  <mat-tab-group class="memory-tabs is-compact" mat-stretch-tabs color="accent">
    <mat-tab label="Players">
      <ng-container *ngTemplateOutlet="players"></ng-container>
    </mat-tab>
    <mat-tab label="Cards">
      <ng-container *ngTemplateOutlet="cards"></ng-container>
    </mat-tab>
    <mat-tab label="Options">
      <ng-container *ngTemplateOutlet="options"></ng-container>
    </mat-tab>
  </mat-tab-group>

  <game-start-end-btns
    [canStartGame]="(isActivityActive$ | async) && !isGameActive"
    [isGameActive]="isGameActive"
    (clickNewGame)="onClickStartNewGame()"
    (clickEndGame)="onClickEndGame()"
  ></game-start-end-btns>
</div>

<ng-template #players>
  <div class="players-container">
    <pl-player-count-select
      [playerCountOptions]="playerCountOptions"
      [selection]="numPlayers"
      (numPlayersChange)="onChangeNumPlayers($event)"
      [isDisabled]="isGameActive"
    ></pl-player-count-select>

    <div class="player-names">
      <mat-form-field
        *ngFor="let playerName of playerNameInputs; let i = index"
      >
        <mat-label>Player {{ i + 1 }}</mat-label>
        <input
          placeholder="Player {{ i + 1 }} name"
          matInput
          [(ngModel)]="playerName.value"
          [disabled]="isGameActive"
        />
      </mat-form-field>
    </div>
  </div>
</ng-template>
<ng-template #cards>
  <div class="cards-container">
    <div class="shuffle-container">
      <button
        mat-raised-button
        color="primary"
        (click)="handleShuffleDeck()"
        [disabled]="!(isActivityActive$ | async)"
      >
        Shuffle deck
      </button>
    </div>
    <!-- <div class="slider-container">
      <div class="slider-container__label-container">
        <label class="slider-container__label" id="memory-total-matches">
          Total matches
        </label>
        <span class="slider-container__value">{{ totalMatches }}</span>
      </div>
      <mat-slider
        [min]="2"
        [max]="12"
        [step]="1"
        [tickInterval]="'auto'"
        [ngModel]="totalMatches"
        (input)="updateTotalMatches($event.value)"
        aria-labelledby="memory-total-matches"
        class="slider-container__slider"
      ></mat-slider>
    </div> -->
    <div class="cards-info">
      <label>Unused card pairs</label>
      <div>
        Existing matches can be replaced by dragging a pair from below onto the
        workspace.
      </div>
    </div>
    <div class="cards-list">
      <pl-alert-message class="margin-t" *ngIf="!cardList.length"
        >No more card pairs available.</pl-alert-message
      >
      <div
        class="memory-card"
        *ngFor="let card of cardList; let $index = index"
        [ngClass]="{
          disabled: !(isActivityActive$ | async)
        }"
      >
        <div
          class="dragbox"
          [ngClass]="{ disabled: !(isActivityActive$ | async) }"
          [plDrag]="handleDragCardStart"
          (dragStart)="handleDragCardStart($event)"
          [plDragData]="card"
        >
          <div class="card-content">
            <div class="icon">
              <figure
                [style.background-image]="'url(' + card.thumbnail_url + ')'"
                *ngIf="card.type !== CardDisplayOption.TextOnly"
              ></figure>
              <div
                class="card-text-image"
                *ngIf="card.type === CardDisplayOption.TextOnly"
              >
                <span>Text only</span>
              </div>
            </div>
            <div class="text">{{ card.title }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #options>
  <div class="options-container">
    <div class="number-of-flips-container">
      <label id="number-of-flips-label">Number of incorrect flips</label>
      <mat-radio-group
        aria-labelledby="Number of incorrect flips"
        class="radio-buttons"
        color="primary"
        data-testid="number-of-flips-radio-group"
        (change)="selectMaxFlips($event.value)"
      >
        <mat-radio-button
          *ngFor="let option of flipOptions"
          class="radio-bg-button"
          data-testid="number-of-flips-radio-button"
          [value]="option.value"
          [checked]="option.value === maxFlips"
        >
          {{ option.label }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="card-display-options-container">
      <pl-card-display-options
        (optionSelected)="setCardType($event)"
        [value]="cardType"
      ></pl-card-display-options>
    </div>

    <div class="clinician-xray-container">
      Clinician X-ray
      <mat-slide-toggle
        [checked]="xRay"
        [(ngModel)]="xRay"
        (change)="setXRay(xRay)"
        data-testid="guess-who-xray-toggle"
      >
        {{ xRay ? 'On' : 'Off' }}
      </mat-slide-toggle>
    </div>
  </div>
</ng-template>
