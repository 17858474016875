import { Component, Input, OnInit, NgZone, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';
import { AppState } from '@app/store';
import { Actions, ofType } from '@ngrx/effects';

import {
  PLScenesFactoryService,
  Sticker,
  StickerTemplate,
} from './pl-scenes-factory.service';

import { filter, map, skip } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import {
  CentralContentType,
  selectIsCurrentCentralContent,
} from '../../../app/store';

import { FirebaseService } from '@root/src/app/common/firebase/firebase.service';
import {
  GameSceneId,
  actions,
  currentTabIndexSelector,
  sceneNameSelector,
  stickerPackNameSelector,
} from '@root/src/app/stores/game-scenes.store';
import { TRAY_WIDTH_PERCENTAGE } from './pl-scenes.const';

const DEFAULT_SCENE = 'blank';

@Component({
  selector: 'pl-scenes-drawer',
  templateUrl: './pl-scenes-drawer.component.html',
  styleUrls: ['./pl-scenes-drawer.component.less'],
})
export class PLScenesDrawerComponent implements OnInit, OnDestroy {
  @Input() activeDrawer = true;
  @Input() activity: any;
  sceneBuilderisActive: boolean;
  selectedStickerPackButtons: StickerTemplate[];
  sceneOptions: { value: string; label: string }[];
  stickerOptions: { value: string; label: string }[];
  isPreviewEnabled = false;
  stickersVisible = true;

  stickers$ = new BehaviorSubject<Sticker[]>([]);
  stickersInTray$: Observable<Sticker[]>;
  hasStickersInTray$: Observable<boolean>;

  selectedSceneName$: Observable<string>;

  selectedStickerPackName: string;
  selectedStickerPackName$: Observable<string>;

  currentTabIndex$: Observable<number>;

  firebaseRef: any;
  isActivityActive$: Observable<boolean>;
  gameId: GameSceneId;
  private subscriptions: Subscription[] = [];

  constructor(
    public scenesFactory: PLScenesFactoryService,
    private store: Store<AppState>,
    private firebaseService: FirebaseService,
    private zone: NgZone,
    private actions$: Actions,
  ) {}

  ngOnInit() {
    this.gameId =
      this.activity?.type === 'game-potato-head' ? 'potatoHead' : 'scenes';

    this.isActivityActive$ = this.store.select(
      selectIsCurrentCentralContent(
        CentralContentType.QueueItem,
        this.activity,
      ),
    );

    if (this.activity.type === 'game-potato-head') {
      this.scenesFactory.loadScenes('potato-head');
    } else {
      this.scenesFactory.loadScenes('default');
    }

    this.sceneOptions = [
      {
        value: DEFAULT_SCENE,
        label: 'Blank',
        backgroundImageSrc: '/assets/images/background-blank.svg',
      },
    ].concat(this.scenesFactory.sceneOptions);

    this.stickerOptions = [].concat(this.scenesFactory.stickerOptions);

    this.stickersInTray$ = this.stickers$.pipe(
      map(stickers => stickers.filter(s => s.x > 100 - TRAY_WIDTH_PERCENTAGE)),
    );

    this.hasStickersInTray$ = this.stickersInTray$.pipe(
      map(stickers => stickers.length > 0),
    );

    this.selectedStickerPackName$ = this.store.select(
      stickerPackNameSelector(this.gameId),
    );
    this.selectedSceneName$ = this.store.select(sceneNameSelector(this.gameId));

    this.currentTabIndex$ = this.store.select(
      currentTabIndexSelector(this.gameId),
    );

    this.subscriptions.push(
      this.selectedStickerPackName$.subscribe(packName => {
        this.selectedStickerPackName = packName;

        const selectedPackButtons =
          this.scenesFactory.getStickerButtonsPackForName(
            this.selectedStickerPackName,
          );

        if (selectedPackButtons) {
          this.selectedStickerPackButtons = selectedPackButtons.stickerButtons;
        }
      }),
    );

    // TODO - move all this to a store/effect?
    const fbPath = `activities/queues/items/${this.activity.queueId}/items/${this.activity.activityId}`;
    this.firebaseRef = this.firebaseService.getRoomRef(fbPath);

    this.firebaseRef.child('stickers').on('value', snap => {
      this.stickers$.next(Object.values(snap.val() || {}));
    });

    this.firebaseRef.child('sceneName').once('value', snap => {
      this.zone.run(() => {
        const val = snap.val();
        this.store.dispatch(
          actions.setSceneName({
            sceneName: val || DEFAULT_SCENE,
            gameId: this.gameId,
          }),
        );
      });
    });

    this.firebaseRef.child('showPreview').once('value', snap => {
      this.zone.run(() => {
        const val = snap.val();
        this.isPreviewEnabled = val;
      });
    });

    this.firebaseRef.child('showStickers').once('value', snap => {
      this.zone.run(() => {
        let val = snap.val();
        if (val !== null) {
          this.stickersVisible = val;
        }
      });
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onTabChange(tabIndex: number) {
    this.store.dispatch(
      actions.setCurrentTabIndex({ gameId: this.gameId, tabIndex }),
    );
    // When user navigates between tabs, we need to reset the preview
    // so they don't get stuck with a weird state of not being able to use stickers
    if (this.isPreviewEnabled) {
      this.togglePreview();
    }
  }

  setSelectedStickerPackName(stickerPackName: string) {
    this.store.dispatch(
      actions.setStickerPackName({ stickerPackName, gameId: this.gameId }),
    );
  }

  togglePreview() {
    this.isPreviewEnabled = !this.isPreviewEnabled;

    this.store.dispatch(
      actions.togglePreview({
        preview: this.isPreviewEnabled,
        gameId: this.gameId,
      }),
    );

    this.firebaseRef.child('showPreview').set(this.isPreviewEnabled);
  }

  setStickersVisible(value: boolean) {
    this.stickersVisible = value;
    this.store.dispatch(
      actions.toggleStickerDisplay({
        showStickers: value,
        gameId: this.gameId,
      }),
    );
    this.firebaseRef.child('showStickers').set(value);
  }

  resetGame() {
    this.store.dispatch(
      actions.setSceneName({ sceneName: DEFAULT_SCENE, gameId: this.gameId }),
    );

    this.firebaseRef.child('sceneName').set(DEFAULT_SCENE);

    this.setSelectedStickerPackName('all');

    if (!this.stickersVisible) {
      this.setStickersVisible(true);
    }

    if (this.isPreviewEnabled) {
      this.togglePreview();
    }

    this.clearStickers();
  }

  setSelectedSceneName(sceneName: string) {
    this.store.dispatch(
      actions.setSceneName({ sceneName, gameId: this.gameId }),
    );

    this.firebaseRef.child('sceneName').set(sceneName);

    const scene = this.scenesFactory.getSceneForName(sceneName);
    const stickerPackName =
      scene?.stickerPackName || this.selectedStickerPackName;

    if (stickerPackName) {
      this.setSelectedStickerPackName(stickerPackName);
    } else {
      this.setSelectedStickerPackName('all');
    }
  }

  addAllStickers() {
    if (this.selectedStickerPackButtons) {
      this.store.dispatch(
        actions.addStickers({
          stickers: this.selectedStickerPackButtons,
          gameId: this.gameId,
        }),
      );
    }
  }
  addSticker(sticker) {
    this.store.dispatch(
      actions.addStickers({ stickers: [sticker], gameId: this.gameId }),
    );
  }

  clearStickers() {
    this.store.dispatch(actions.clearStickers({ gameId: this.gameId }));

    this.stickers$.getValue().forEach(sticker => {
      this.firebaseRef.child('stickers/' + sticker.key).remove();
    });
  }

  clearTrayStickers() {
    this.store.dispatch(actions.clearTrayStickers({ gameId: this.gameId }));
  }
}
