import { NgModule } from '@angular/core';

import { ConferenceComponent } from './conference.component';
import { ConferenceRTService } from './conference-rt.service';
import { RoomConferenceService } from './room-conference.service';
import { StoreModule } from '@ngrx/store';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { reducer as conferenceReducer } from './store';
import {
  ConferenceEffects,
  ConferenceLocalStreamsEffects,
  ConferenceRemoteStreamsEffects,
  ConferenceCleanupEffects,
  ConferenceConnectionRecoveryEffects,
  ConferenceJoinEffects,
  ConferenceSubscribeEffects,
  ConferenceAddGuestPrimaryEffects,
} from './store/effects';
import { ScreenshareComponent } from './screenshare/screenshare.component';
import { ConferenceStreamModule } from './conference-stream';
import { ScreenshareService } from './screenshare/screenshare.service';
import {
  reducer as screenshareReducer,
  ScreenshareEffects,
} from './screenshare/store';
import { ConferenceCommonModule } from './common';
import { PLCommonComponentsModule } from '@root/src/app/common/components';

const exportedComponents = [ScreenshareComponent, ConferenceComponent];

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('conference', conferenceReducer),
    StoreModule.forFeature('screenshare', screenshareReducer),
    EffectsModule.forFeature([
      ConferenceAddGuestPrimaryEffects,
      ConferenceSubscribeEffects,
      ConferenceJoinEffects,
      ConferenceEffects,
      ConferenceLocalStreamsEffects,
      ConferenceRemoteStreamsEffects,
      ConferenceCleanupEffects,
      ConferenceConnectionRecoveryEffects,
      ScreenshareEffects,
    ]),
    ConferenceCommonModule,
    ConferenceStreamModule,
    PLCommonComponentsModule,
  ],
  exports: [...exportedComponents],
  declarations: [...exportedComponents],
  providers: [ConferenceRTService, ScreenshareService, RoomConferenceService],
})
export class ConferenceModule {}

export * from './conference-rt.service';
export * from './room-conference.service';
