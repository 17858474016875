import { Injectable } from '@angular/core';
import moment from 'moment';
import { Observable, BehaviorSubject, Subscription, ReplaySubject } from 'rxjs';
import { User } from '@app/modules/user/user.model';
import { AppState } from '@app/store';
import { Store } from '@ngrx/store';
import { selectAuth } from '@modules/user/store';
import { PLTimezoneService } from '@common/services';
import { PLRecordRoomService } from '@common/services/pl-records';
import { SelectionOption } from '@common/components/multi-select-menu/multi-select-menu.component';
import {
  ClientAppointment,
  DocumentationItemState,
} from '../documentation.models';

@Injectable({
  providedIn: 'root',
})
export class ClientAppointmentsService {
  private subscriptions: Subscription[] = [];
  private currentUser: User;
  private appointments: ClientAppointment[] = [];
  private readonly localStorageKey: string = 'documentationClients';
  private localStorageData: any = {};

  appointmentsSelectionOptions$ = new BehaviorSubject<SelectionOption[]>([]);
  selectedAppointments$ = new ReplaySubject<ClientAppointment[]>();
  isBlackoutDay$ = new BehaviorSubject<boolean>(false);
  loadingAppointments: boolean;

  constructor(
    private plRecordRoom: PLRecordRoomService,
    private store: Store<AppState>,
    private plTimezone: PLTimezoneService,
  ) {
    this.subscriptions.push(
      this.store.select(selectAuth).subscribe(({ isAuthenticated, user }) => {
        if (isAuthenticated) {
          this.currentUser = user;
          this.subscribeToClientAppointments();
        }
      }),
    );
  }

  cleanup() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  private subscribeToClientAppointments() {
    this.loadingAppointments = true;
    this.subscriptions.push(
      this.plRecordRoom
        .getClientAppointmentsData()
        .subscribe((resClientAppointments: any) => {
          this.loadingAppointments = false;
          if (
            !resClientAppointments.clients ||
            this.currentUser.xProvider === null
          ) {
            return;
          }

          const clientAppointments = this.formatClientAppointments(
            resClientAppointments.clients,
          );
          this.appointments = clientAppointments.sort(
            (a: ClientAppointment, b: ClientAppointment) => {
              return a.xSortString.localeCompare(b.xSortString);
            },
          );
          let selectionOptions = this.appointments.map(clientAppointment => {
            return {
              selected: false,
              value: clientAppointment.xInstanceId,
              label: `${clientAppointment.xTime} - ${
                clientAppointment.first_name
              } ${clientAppointment.last_name.slice(0, 1)}.`,
            };
          });
          this.updateFromLocalStorage(selectionOptions).subscribe(options => {
            this.appointmentsSelectionOptions$.next(options);
            this.updateSelectedAppointments(options);
          });
        }),
    );
  }

  private updateFromLocalStorage(selectionOptions: SelectionOption[]) {
    return new Observable<SelectionOption[]>(observer => {
      let data: any;
      try {
        data = localStorage.getItem(this.localStorageKey);
      } catch (e) {
        console.debug('localStorage error in PLDocumentationClientsComponent');
      }
      if (data) {
        data = JSON.parse(data);
        if (data.selectedClientAppointmentIds) {
          // Could just set them directly, but in case an appointment was removed, check
          // that it still exists first.
          const selectedIds = [];
          data.selectedClientAppointmentIds.forEach((id: string) => {
            const index = selectionOptions.findIndex(
              option => option.value === id,
            );
            if (index > -1) {
              selectedIds.push(id);
            }
          });
          selectionOptions.forEach(appointmentOption => {
            appointmentOption.selected = selectedIds.includes(
              appointmentOption.value,
            );
          });
          observer.next(selectionOptions);
        }
      } else {
        observer.next(selectionOptions);
      }
    });
  }

  refresh() {
    if (this.loadingAppointments) {
      return;
    }
    this.loadingAppointments = true;
    this.plRecordRoom.refreshClientAppointmentsData(this.currentUser);
  }

  selectionChanged(options: SelectionOption[]) {
    this.updateSelectedAppointments(options);
  }

  getFormattedTime(time: string) {
    const timeMoment = moment.tz(
      time,
      this.plTimezone.formatDateTime,
      this.currentUser.xProvider.timezone,
    );
    const timeFormatted = timeMoment.format('h:mma');
    return timeFormatted;
  }

  setPersistedItemStates(value: Record<string, DocumentationItemState>) {
    localStorage.setItem('documentationItemStates', JSON.stringify(value));
  }

  getPersistedItemStates(): Record<string, DocumentationItemState> {
    const itemStates = localStorage.getItem('documentationItemStates');
    let parsedStates: Record<string, DocumentationItemState> = itemStates
      ? JSON.parse(itemStates)
      : {};

    // Clean up any states for appointments that no longer exist.
    const result = Object.keys(parsedStates).reduce((acc, key) => {
      if (this.appointments.some(app => app.appointment.uuid === key)) {
        acc[key] = parsedStates[key];
      }
      return acc;
    }, {});
    // Changes made, save to local storage.
    if (Object.keys(result).length !== Object.keys(parsedStates).length) {
      this.setPersistedItemStates(result);
    }

    return result;
  }

  private formatClientAppointments(
    clientAppointments: ClientAppointment[],
  ): ClientAppointment[] {
    return clientAppointments.map((clientAppointment: ClientAppointment) => {
      let timeMoment = moment.tz(
        clientAppointment.appointment.start,
        this.plTimezone.formatDateTime,
        this.currentUser.xProvider.timezone,
      );
      let xTime = this.getFormattedTime(clientAppointment.appointment.start);
      let xTimeMilitary = timeMoment.format('HH:mm');
      if (
        clientAppointment.record &&
        !clientAppointment.record?.assignment_proposal
      ) {
        const assignmentProposal =
          clientAppointment.appointment?.assignment_proposal ||
          clientAppointment.appointment?.event?.assignment_proposal;
        clientAppointment.record.assignment_proposal = assignmentProposal;
      }
      return Object.assign(clientAppointment, {
        xInstanceId: `${clientAppointment.uuid}${clientAppointment.appointment.uuid}`,
        xTime: xTime,
        xExpanded: true,
        xSortString: `${xTimeMilitary} ${clientAppointment.first_name} ${clientAppointment.last_name}`,
      });
    });
  }

  private updateSelectedAppointments(selectionOptions: SelectionOption[]) {
    const selectedAppointments = [];
    const selectedAppointmentIds = [];

    selectionOptions.forEach(appointmentOption => {
      if (appointmentOption.selected) {
        const selectedAppointment = this.appointments.find(
          appointment => appointment.xInstanceId === appointmentOption.value,
        );
        selectedAppointments.push(selectedAppointment);
        selectedAppointmentIds.push(selectedAppointment.xInstanceId);
      }
    });
    this.setBlackoutDayForSelectedClients(selectedAppointments);
    this.writeToLocalStorage(selectedAppointmentIds);
    this.selectedAppointments$.next(selectedAppointments);
  }

  private setBlackoutDayForSelectedClients(selectedAppointments) {
    if (!selectedAppointments.length) {
      this.isBlackoutDay$.next(false);
      return;
    }
    this.isBlackoutDay$.next(
      !!(
        selectedAppointments.find((_: any) => _.appointment.is_blacked_out) ||
        localStorage.getItem('DEBUG_BLACKOUT_DAY')
      ),
    );
  }

  private writeToLocalStorage(selectedAppointmentIds: any[]) {
    this.localStorageData.selectedClientAppointmentIds = Array.from(
      selectedAppointmentIds,
    );
    localStorage.setItem(
      this.localStorageKey,
      JSON.stringify(this.localStorageData),
    );
  }
}
