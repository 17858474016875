import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'game-start-end-btns',
  templateUrl: './game-start-end-btns.component.html',
  styleUrls: ['./game-start-end-btns.component.less'],
})
export class GameStartEndBtnsComponent {
  @Output() clickNewGame = new EventEmitter<any>();
  @Output() clickEndGame = new EventEmitter<any>();
  @Input() allDisabled: boolean;
  @Input() canStartGame: boolean;
  @Input() isGameActive: boolean;
  loadingGame: boolean = false;

  newGame(): void {
    this.clickNewGame.emit();
  }

  endGame(): void {
    this.clickEndGame.emit();
  }
}
