import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import {
  PLVendorGameService,
  PLGameDbAPI,
  PLGameDb,
} from './pl-vendor-game.service';
import { PLVendorGamesByActivity } from './pl-vendor-game-definitions.service';
import { Participant, ParticipantType } from '@room/session/store';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const PL_GAME_DB = 'PLGameDb';

@Component({
  selector: 'pl-vendor-game',
  templateUrl: './pl-vendor-game.component.html',
  styleUrls: ['./pl-vendor-game.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PLVendorGameComponent implements OnInit, OnDestroy {
  @Input() activity: any;

  destroyed$ = new Subject<boolean>();

  initialized = false;
  gameConfig: any;
  iframeSrc: string;
  participants: Participant[];
  showTrioExample: boolean;
  showTrioInstruction: boolean;
  gameDb: PLGameDb = null;
  settingsApi: PLGameDbAPI;

  constructor(
    public vendorGameService: PLVendorGameService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.gameDb = this.vendorGameService.setupPLGameDb(this.activity);

    // Games use DB instance under global variable, so we set it up before rendering the iframe
    window[PL_GAME_DB] = this.gameDb;

    this.settingsApi = this.gameDb.ref('settings');

    this.subscribeToUsers();

    if (this.activity.type === 'game-gametable-trio') {
      this.initTrioExampleInstruction();
      this.watchTrioRefs();
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.settingsApi.offValue(this.onValue);
    this.gameDb.destroy();
  }

  private initTrioExampleInstruction(): void {
    this.showTrioInstruction = false;
    this.showTrioExample = false;
    this.settingsApi.update({
      showExample: this.showTrioExample,
      showInstruction: this.showTrioInstruction,
    });
  }

  private watchTrioRefs(): void {
    this.settingsApi.onValue(this.onValue);
  }

  private onValue = (trioGameValues: any) => {
    if (!trioGameValues) {
      return;
    }
    const { showExample, showInstruction } = trioGameValues;
    this.showTrioInstruction = showInstruction;
    this.showTrioExample = showExample;
    this.cdr.detectChanges();
  };

  private subscribeToUsers(): Subscription {
    return this.vendorGameService
      .getParticipantsAndLocalId()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([participants, localId]) => {
        this.participants = participants;

        // setup iframe once and only once
        if (!this.initialized) {
          let url: string;
          const done = () => {
            this.iframeSrc = this.vendorGameService.sanitizeUrl(url);

            console.log(
              '--- Vendor game: Checking state before rendering iframe',
              { STATE: this, iframeSrc: url, hasPLGameDb: !!this.gameDb },
            );
            if (this.gameDb) {
              this.initialized = true;
              console.log('--- Vendor Game: iframe initialized...', {
                STATE: this,
                iframeSrc: url,
              });
            } else {
              console.error(
                '--- Vendor Game: Shared PLGameDb was not initialized',
                { STATE: this },
              );
            }
            this.cdr.detectChanges();
          };

          console.log(
            '--- Vendor Game: Checking for shared PLGameDb object...',
            { STATE: this, PLGameDb: this.gameDb },
          );

          if (this.gameDb) {
            const game = PLVendorGamesByActivity[this.activity.type];
            const localParticipant = this.participants.find(
              p => p.id === localId,
            );
            const isRoomOwner =
              localParticipant.type === ParticipantType.host ? 1 : 0;
            url = game.getIframeUrl({
              isRoomOwner,
              clientId: localParticipant.userId,
            });
            done();
          } else {
            console.error(
              '--- Vendor Game: Shared PLGameDb was not initialized',
              { STATE: this },
            );
          }
        }
      });
  }
}
