<div class="mat-dialog-header">
  <h2 mat-dialog-title>
    <ng-container *ngIf="data.saveMode === 'default'">Save</ng-container>
    <ng-container *ngIf="data.saveMode === 'existing'"
      >Unsaved images found</ng-container
    >
    <ng-container *ngIf="data.saveMode === 'on_exit'">User exited</ng-container>
    <ng-container *ngIf="data.saveMode === 'interrupted'"
      >You exited jumbotron</ng-container
    >
  </h2>
  <button
    mat-icon-button
    mat-dialog-close
    aria-label="Close save screenshot session"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <p class="mb-3">
    <ng-container *ngIf="data.saveMode === 'default'">
      Saving to {{ data.clientName }}'s Events
    </ng-container>
    <ng-container *ngIf="data.saveMode === 'existing'">
      Would you like to save images to {{ data.clientName }}'s Events?
    </ng-container>
    <ng-container *ngIf="data.saveMode === 'on_exit'">
      User exited. Would you like to save images to {{ data.clientName }}'s
      Events?
    </ng-container>
    <ng-container *ngIf="data.saveMode === 'interrupted'">
      Would you like to save images to {{ data.clientName }}'s Events?
    </ng-container>
  </p>

  <mat-form-field class="w-100 mat-form-field-standalone" color="accent">
    <mat-label>Name</mat-label>
    <input matInput [(ngModel)]="data.sessionName" maxlength="254" />
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    mat-stroked-button
    mat-dialog-close
    color="primary"
    *ngIf="data.saveMode === 'default'"
  >
    Cancel
  </button>
  <button
    mat-stroked-button
    color="primary"
    *ngIf="data.saveMode !== 'default'"
    [mat-dialog-close]="{ discard: true }"
  >
    Discard
  </button>
  <button
    mat-raised-button
    color="primary"
    [disabled]="disableSave()"
    [mat-dialog-close]="data"
  >
    Save & Submit
  </button>
</mat-dialog-actions>
