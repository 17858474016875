import {
  SceneDescription,
  StickerDescription,
  StickerPackDescription,
} from './pl-scenes-factory.service';

const potatoBodySticker: StickerDescription = {
  name: 'potato-body',
  width: 27.6,
  height: 40,
  baseLayer: true,
  displayName: 'Potato body',
};

const eyeStickers: StickerDescription[] = [
  {
    name: 'eyes',
    width: 15.5,
    height: 8.4,
    baseLayer: null,
    displayName: 'Eyes',
  },
  {
    name: 'eyes-with-lashes',
    width: 17.4,
    height: 9.1,
    baseLayer: null,
    displayName: 'Eyes with lashes',
  },
  {
    name: 'drowsy-eyes',
    width: 15.9,
    height: 8.4,
    baseLayer: null,
    displayName: 'Drowsy eyes',
  },
  {
    name: 'smaller-eyes-with-lashes',
    width: 21.3,
    height: 7.7,
    baseLayer: null,
    displayName: 'Smaller eyes with lashes',
  },
  {
    name: 'yellow-glasses',
    width: 27,
    height: 10.5,
    baseLayer: null,
    displayName: 'Yellow glasses',
  },
  {
    name: 'eye-patch',
    width: 21,
    height: 10.5,
    baseLayer: null,
    displayName: 'Eye patch',
  },
  {
    name: 'blue-sunglasses',
    width: 24.4,
    height: 10,
    baseLayer: null,
    displayName: 'Blue sunglasses',
  },
  {
    name: 'pink-eye-mask',
    width: 23.4,
    height: 13,
    baseLayer: null,
    displayName: 'Pink eye mask',
  },
  {
    name: 'purple-glasses',
    width: 26,
    height: 10,
    baseLayer: null,
    displayName: 'Purple glasses',
  },
];

const noseStickers: StickerDescription[] = [
  {
    name: 'pink-nose',
    width: 14,
    height: 9.8,
    baseLayer: null,
    displayName: 'Pink nose',
  },
  {
    name: 'red-nose',
    width: 14,
    height: 9.8,
    baseLayer: null,
    displayName: 'Red nose',
  },
];

const mouthStickers: StickerDescription[] = [
  {
    name: 'buck-teeth',
    width: 16,
    height: 9.7,
    baseLayer: null,
    displayName: 'Buck teeth',
  },
  {
    name: 'tongue',
    width: 14,
    height: 9,
    baseLayer: null,
    displayName: 'Tongue',
  },
  {
    name: 'teeth',
    width: 18,
    height: 11.1,
    baseLayer: null,
    displayName: 'Teeth',
  },
  {
    name: 'tongue-out',
    width: 13.4,
    height: 12.6,
    baseLayer: null,
    displayName: 'Tongue out',
  },
  {
    name: 'open-mouth',
    width: 7.7,
    height: 11.5,
    baseLayer: null,
    displayName: 'Open mouth',
  },
  {
    name: 'smile',
    width: 15.4,
    height: 11.5,
    baseLayer: null,
    displayName: 'Smile',
  },
  {
    name: 'pacifier',
    width: 16,
    height: 12.5,
    baseLayer: null,
    displayName: 'Pacifier',
  },
  {
    name: 'red-lips',
    width: 16.5,
    height: 10.2,
    baseLayer: null,
    displayName: 'Red lips',
  },
];

const earStickers: StickerDescription[] = [
  {
    name: 'left-ear-with-earring',
    width: 8.4,
    height: 15.9,
    baseLayer: null,
    displayName: 'Left ear with earring',
  },
  {
    name: 'right-ear-with-earring',
    width: 8.4,
    height: 15.9,
    baseLayer: null,
    displayName: 'Right ear with earring',
  },
  {
    name: 'left-ear-with-lobe',
    width: 8.3,
    height: 12.2,
    baseLayer: null,
    displayName: 'Left ear with lobe',
  },
  {
    name: 'right-ear-with-lobe',
    width: 8.3,
    height: 12.2,
    baseLayer: null,
    displayName: 'Right ear with lobe',
  },
  {
    name: 'left-ear-broad-lobe',
    width: 8.4,
    height: 9,
    baseLayer: null,
    displayName: 'Left ear broad lobe',
  },
  {
    name: 'right-ear-broad-lobe',
    width: 8.4,
    height: 9,
    baseLayer: null,
    displayName: 'Right ear broad lobe',
  },
  {
    name: 'round-left-ear',
    width: 8.5,
    height: 10.9,
    baseLayer: null,
    displayName: 'Round left ear',
  },
  {
    name: 'round-right-ear',
    width: 8.5,
    height: 10.9,
    baseLayer: null,
    displayName: 'Round right ear',
  },
];

const armStickers: StickerDescription[] = [
  {
    name: 'left-side-thumbs-up',
    width: 23.3,
    height: 19.1,
    baseLayer: null,
    displayName: 'Left side thumbs up',
  },
  {
    name: 'right-side-thumbs-up',
    width: 23.3,
    height: 19.1,
    baseLayer: null,
    displayName: 'Right side thumbs up',
  },
  {
    name: 'left-side-peace-sign',
    width: 18.4,
    height: 21,
    baseLayer: null,
    displayName: 'Left side peace sign',
  },
  {
    name: 'right-side-peace-sign',
    width: 18.4,
    height: 21,
    baseLayer: null,
    displayName: 'Right side peace sign',
  },
  {
    name: 'left-side-flex',
    width: 13.8,
    height: 19.1,
    baseLayer: null,
    displayName: 'Left side flex',
  },
  {
    name: 'right-side-flex',
    width: 13.8,
    height: 19.1,
    baseLayer: null,
    displayName: 'Right side flex',
  },
  {
    name: 'left-side-on-hip',
    width: 16.3,
    height: 18.7,
    baseLayer: null,
    displayName: 'Left side on hip',
  },
  {
    name: 'right-side-on-hip',
    width: 16.3,
    height: 18.7,
    baseLayer: null,
    displayName: 'Right side on hip',
  },
  {
    name: 'left-side-behind-back',
    width: 18.6,
    height: 16,
    baseLayer: null,
    displayName: 'Left side behind back',
  },
  {
    name: 'right-side-behind-back',
    width: 18.6,
    height: 16,
    baseLayer: null,
    displayName: 'Right side behind back',
  },
  {
    name: 'left-side-horn-sign',
    width: 17.7,
    height: 24.8,
    baseLayer: null,
    displayName: 'Left side horn sign',
  },
  {
    name: 'right-side-horn-sign',
    width: 17.7,
    height: 24.8,
    baseLayer: null,
    displayName: 'Right side horn sign',
  },
  {
    name: 'left-side-wave',
    width: 14,
    height: 31.8,
    baseLayer: null,
    displayName: 'Left side wave',
  },
  {
    name: 'right-side-wave',
    width: 14,
    height: 31.8,
    baseLayer: null,
    displayName: 'Right side wave',
  },
  {
    name: 'left-side-down',
    width: 16.2,
    height: 26.6,
    baseLayer: null,
    displayName: 'Left side down',
  },
  {
    name: 'right-side-down',
    width: 16.2,
    height: 26.6,
    baseLayer: null,
    displayName: 'Right side down',
  },
  {
    name: 'left-side-point',
    width: 24.4,
    height: 22.7,
    baseLayer: null,
    displayName: 'Left side point',
  },
  {
    name: 'right-side-point',
    width: 24.4,
    height: 22.7,
    baseLayer: null,
    displayName: 'Right side point',
  },
];

const headwareStickers: StickerDescription[] = [
  {
    name: 'red-bandana',
    width: 27,
    height: 9.7,
    baseLayer: null,
    displayName: 'Red bandana',
  },
  {
    name: 'green-clown-hat',
    width: 23.9,
    height: 26.5,
    baseLayer: null,
    displayName: 'Green clown hat',
  },
  {
    name: 'fireman-hat',
    width: 25,
    height: 16.9,
    baseLayer: null,
    displayName: 'Firefighter hat',
  },
  {
    name: 'pirate-hat',
    width: 32,
    height: 18.5,
    baseLayer: null,
    displayName: 'Pirate hat',
  },
  {
    name: 'pink-hat-with-bow',
    width: 24.8,
    height: 14,
    baseLayer: null,
    displayName: 'Pink hat with bow',
  },
  {
    name: 'police-hat',
    width: 24.5,
    height: 13.7,
    baseLayer: null,
    displayName: 'Police hat',
  },
  {
    name: 'green-baseball-cap',
    width: 24,
    height: 11.7,
    baseLayer: null,
    displayName: 'Green baseball hat',
  },
  {
    name: 'green-beanie',
    width: 24,
    height: 17.1,
    baseLayer: null,
    displayName: 'Green beanie',
  },
  {
    name: 'red-spinner-hat',
    width: 22.5,
    height: 11.5,
    baseLayer: null,
    displayName: 'Red spinner hat',
  },
  {
    name: 'red-baseball-cap',
    width: 19,
    height: 11.1,
    baseLayer: null,
    displayName: 'Red baseball cap',
  },
  {
    name: 'brown-bowler-hat',
    width: 24.6,
    height: 13.8,
    baseLayer: null,
    displayName: 'Brown bowler hat',
  },
  {
    name: 'pink-hat-and-ponytail',
    width: 29,
    height: 10.6,
    baseLayer: null,
    displayName: 'Pink hat and ponytail',
  },
];

const feetStickers: StickerDescription[] = [
  {
    name: 'orange-feet',
    width: 33,
    height: 13.3,
    baseLayer: null,
    displayName: 'Orange feet',
  },
  {
    name: 'green-shoes',
    width: 34,
    height: 13.4,
    baseLayer: null,
    displayName: 'Green shoes',
  },
  {
    name: 'green-heels',
    width: 28.9,
    height: 15,
    baseLayer: null,
    displayName: 'Green heels',
  },
  {
    name: 'brown-boots',
    width: 31.3,
    height: 13,
    baseLayer: null,
    displayName: 'Brown boots',
  },
  {
    name: 'blue-shoes',
    width: 29,
    height: 15.5,
    baseLayer: null,
    displayName: 'Blue shoes',
  },
  {
    name: 'purple-skiing-shoes',
    width: 30,
    height: 24,
    baseLayer: null,
    displayName: 'Purple skiing shoes',
  },
  {
    name: 'pirate-stump',
    width: 27.3,
    height: 15,
    baseLayer: null,
    displayName: 'Pirate stump',
  },
  {
    name: 'yellow-boots',
    width: 31.5,
    height: 16,
    baseLayer: null,
    displayName: 'Yellow shoes',
  },
  {
    name: 'pink-slippers',
    width: 33,
    height: 15.6,
    baseLayer: null,
    displayName: 'Pink slippers',
  },
  {
    name: 'red-heels',
    width: 28,
    height: 15.2,
    baseLayer: null,
    displayName: 'Red heels',
  },
];

const mustacheStickers: StickerDescription[] = [
  {
    name: 'bushy',
    width: 24.2,
    height: 9,
    baseLayer: null,
    displayName: 'Bushy',
  },
  {
    name: 'spreadout',
    width: 24.9,
    height: 7.5,
    baseLayer: null,
    displayName: 'Spreadout',
  },
  {
    name: 'upbraid',
    width: 21.3,
    height: 9.7,
    baseLayer: null,
    displayName: 'Upbraid',
  },
  {
    name: 'downbraid',
    width: 20.6,
    height: 7.5,
    baseLayer: null,
    displayName: 'Downbraid',
  },
  {
    name: 'combed',
    width: 21.7,
    height: 11.4,
    baseLayer: null,
    displayName: 'Combed',
  },
  {
    name: 'handlebar',
    width: 26,
    height: 7.8,
    baseLayer: null,
    displayName: 'Handlebar',
  },
  {
    name: 'classic',
    width: 21.9,
    height: 10.3,
    baseLayer: null,
    displayName: 'Classic',
  },
];

export const potatoHeadStickerPacks: StickerPackDescription[] = [
  {
    name: 'potatohead-arms',
    displayName: 'Arms and Hands',
    location: 'potatohead',
    stickers: [potatoBodySticker, ...armStickers],
  },
  {
    name: 'potatohead-headwear',
    displayName: 'Headware',
    location: 'potatohead',
    stickers: [potatoBodySticker, ...headwareStickers],
  },
  {
    name: 'potatohead-ears',
    displayName: 'Ears and Accessories',
    location: 'potatohead',
    stickers: [potatoBodySticker, ...earStickers],
  },
  {
    name: 'potatohead-eyes',
    displayName: 'Eyes and Accessories',
    location: 'potatohead',
    stickers: [potatoBodySticker, ...eyeStickers],
  },
  {
    name: 'potatohead-feet',
    displayName: 'Feet and Footwear',
    location: 'potatohead',
    stickers: [potatoBodySticker, ...feetStickers],
  },
  {
    name: 'potatohead-mouths',
    displayName: 'Mouths',
    location: 'potatohead',
    stickers: [potatoBodySticker, ...mouthStickers],
  },
  {
    name: 'potatohead-mustaches',
    displayName: 'Mustaches',
    location: 'potatohead',
    stickers: [potatoBodySticker, ...mustacheStickers],
  },
  {
    name: 'potatohead-noses',
    displayName: 'Noses',
    location: 'potatohead',
    stickers: [potatoBodySticker, ...noseStickers],
  },
];

export const potatoHeadScenes: SceneDescription[] = [
  {
    displayName: 'Bedroom',
    name: 'bedroom',
    location: 'potatohead',
    background: 'bedroom',
  },
  {
    displayName: 'Farm',
    name: 'farm',
    location: 'potatohead',
    background: 'farm',
  },
  {
    displayName: 'Haunted house',
    name: 'haunted-house',
    location: 'potatohead',
    background: 'haunted-house',
    backgroundImageSuffix: 'jpg',
  },
  {
    displayName: 'Ice',
    name: 'ice',
    location: 'potatohead',
    background: 'ice',
  },
  {
    displayName: 'Mountains',
    name: 'mountain-climbing',
    location: 'potatohead',
    background: 'mountain-climbing',
    backgroundImageSuffix: 'jpg',
  },
  {
    displayName: 'Palms',
    name: 'palms',
    location: 'potatohead',
    background: 'palms',
    backgroundImageSuffix: 'jpg',
  },
  {
    displayName: 'Underwater',
    name: 'underwater',
    location: 'potatohead',
    background: 'underwater',
    backgroundImageSuffix: 'jpg',
  },
  {
    displayName: 'Sky',
    name: 'in-the-sky',
    location: 'potatohead',
    background: 'in-the-sky',
    backgroundImageSuffix: 'jpg',
  },
  {
    displayName: 'Space',
    name: 'space',
    location: 'potatohead',
    background: 'space',
  },
];
