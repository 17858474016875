import { Injectable } from '@angular/core';
import { CurrentUserModel } from '@common/models/CurrentUserModel';
import { DRFActivityModel } from '@common/models/DRF/DRFActivityModel.service';
import { DRFAssessmentModel } from '@common/models/DRF/DRFAssessmentModel.service';
import { DRFLessonModel } from '@common/models/DRF/DRFLessonModel.service';
import { FirebaseModel } from '@common/models/firebase/FirebaseModel';
import { ChannelFactoryService } from '@common/services/channel.service';
import { RightsMgmtService } from '@common/services/RightsMgmtService';
import { PLHttpService, PLUrlsService } from '@common/services/pl-http';
import { ReplaySubject } from 'rxjs';
import { PLActivityModelService } from './activity-model.service';
export interface AssessmentSessionChangeEvent {
  type: string;
  data: any;
  _snapshot: any;
  sessionId: string;
}
@Injectable()
export class PLAssessmentModelService extends PLActivityModelService {
  pageRef: firebase.database.Reference;
  preferencesRef: firebase.database.Reference;

  DEFAULT_SCROLL_X = 0;
  DEFAULT_SCROLL_Y = 0;
  DEFAULT_OPACITY = 0.6;

  callbackRegistry: any = new Set();

  allowToggleInstructions = true;
  showInstructions = false;
  instructionOpacity = 0.6;

  scrollXPercent = 0;
  scrollYPercent = 0;
  private changedSubject = new ReplaySubject<any>(null);
  changed$ = this.changedSubject.asObservable();
  private currentSessionId;

  get sessionId() {
    return this.currentSessionId;
  }

  set sessionId(value) {
    this.currentSessionId = value;
    this.clearCallbackRegistry();
    this.loadState();
  }

  constructor(
    drfActivityModel: DRFActivityModel,
    drfAssessmentModel: DRFAssessmentModel,
    drfLessonModel: DRFLessonModel,
    firebaseModel: FirebaseModel,
    private currentUserModel: CurrentUserModel,
    private rightsMgmtService: RightsMgmtService,
    ChannelService: ChannelFactoryService,
    plUrlsService: PLUrlsService,
    plHttp: PLHttpService,
  ) {
    super(
      firebaseModel,
      drfActivityModel,
      drfAssessmentModel,
      ChannelService,
      plUrlsService,
      plHttp,
    );
    this.showInstructions = this.getDefaultShowInstructions();
  }

  /***************************
   * overridden functions
   ***************************/
  reset() {
    this.clearCallbackRegistry(); // this must be before any persisted value changes

    this.allowToggleInstructions = true;
    this.showInstructions = this.getDefaultShowInstructions();
    this.instructionOpacity = 0.6; // default

    return super.reset(); // this must be last
  }

  /***************************
   * rights mgmt
   ***************************/

  getProtectedContentUrl(key) {
    return this.rightsMgmtService.getProtectedContentUrl(
      this.getActivityId(),
      key,
      null,
    );
  }

  cachedProtectedAsset(key) {
    return this.rightsMgmtService.assets[key];
  }

  /***************************
   * instruction visibility
   ***************************/
  toggleInstructions() {
    if (this.canUserAccessInstructions()) {
      this.saveInstructionsVisible(!this.showInstructions);
    }
  }

  getDefaultShowInstructions() {
    if (
      this.currentUserModel &&
      this.currentUserModel.user &&
      this.currentUserModel.user.isAssessmentUser()
    ) {
      return true;
    }
    return false;
  }

  setShowInstructions(value) {
    if (this.canUserAccessInstructions()) {
      this.showInstructions = value;
    }
  }

  getShowInstructions() {
    return this.showInstructions;
  }

  canUserAccessInstructions() {
    return this.currentUserModel.user.isAssessmentUser();
  }

  setInstructionOpacity(value) {
    this.instructionOpacity = value;
  }

  getInstructionOpacity() {
    return this.instructionOpacity;
  }

  setScrollXPercent(value) {
    this.scrollXPercent = value;
  }

  getScrollXPercent() {
    return this.scrollXPercent;
  }

  setScrollYPercent(value) {
    this.scrollYPercent = value;
  }

  getScrollYPercent() {
    return this.scrollYPercent;
  }

  /***************************
   * firebase mutation methods
   ***************************/
  saveScrollPosition(scrollX, scrollY) {
    try {
      this.pageRef.update({ scrollXPercent: scrollX, scrollYPercent: scrollY });
    } catch (err) {
      console.error('[AssessmentModel] Error in saveScrollPosition: ', err);
    }
  }

  saveOpacity(val) {
    this.preferencesRef.child('instructionsOpacity').set(val);
  }

  saveInstructionsVisible(val) {
    this.preferencesRef.child('instructionsVisible').set(val);
  }

  saveZoom(value) {
    this.pageRef.child('zoom').set(value);
  }

  savePage(page) {
    try {
      this.pageRef.update(page);
    } catch (err) {
      console.error('[AssessmentModel] Error in savePage: ', err);
    }
  }

  /***************************
   * firebase listeners
   ***************************/
  loadState() {
    const eventType = 'value';
    if (this.pageRef) {
      this.pageRef.off(eventType, this.handlePageChange, this);
      this.pageRef.off(eventType, this.handleOpacityChange, this);
      this.pageRef.off(eventType, this.handleInstructionsChange, this);
      this.pageRef.off(eventType, this.handleZoomChange, this);
      this.pageRef.off(eventType, this.handleOffsetChange, this);
      this.pageRef.off(eventType, this.handleScrollXPercent, this);
      this.pageRef.off(eventType, this.handleScrollYPercent, this);
    }
    this.preferencesRef = this.firebaseModel.getFirebaseRef(
      `activities/sessions/${this.sessionId}/${this.activity.configId}/preferences`,
    );

    this.pageRef = this.firebaseModel.getFirebaseRef(
      `activities/sessions/${this.sessionId}/${this.activity.configId}/pageNum`,
    );

    let ref = this.pageRef;
    this.registerCallback(
      ref,
      eventType,
      ref.on(eventType, this.handlePageChange, this),
      null,
    );

    ref = this.preferencesRef.child('instructionsOpacity');
    this.registerCallback(
      ref,
      eventType,
      ref.on(eventType, this.handleOpacityChange, this),
      null,
    );

    ref = this.preferencesRef.child('instructionsVisible');
    this.registerCallback(
      ref,
      eventType,
      ref.on(eventType, this.handleInstructionsChange, this),
      null,
    );

    ref = this.pageRef.child('zoom');
    this.registerCallback(
      ref,
      eventType,
      ref.on(eventType, this.handleZoomChange, this),
      null,
    );

    ref = this.pageRef.child('offset');
    this.registerCallback(
      ref,
      eventType,
      ref.on(eventType, this.handleOffsetChange, this),
      null,
    );

    ref = this.pageRef.child('scrollXPercent');
    this.registerCallback(
      ref,
      eventType,
      ref.on(eventType, this.handleScrollXPercent, this),
      null,
    );

    ref = this.pageRef.child('scrollYPercent');
    this.registerCallback(
      ref,
      eventType,
      ref.on(eventType, this.handleScrollYPercent, this),
      null,
    );
  }

  private handlePageChange(snap) {
    this.dispatchEvent(snap, snap.val(), 'value', 'pageChangeEvent');
  }

  private handleInstructionsChange(snap) {
    // Skip null values, which will evaluate to falsy.
    if (snap.val() !== null) {
      this.setShowInstructions(snap.val());
      this.dispatchEvent(
        snap,
        this.getShowInstructions(),
        'value',
        'instructionsChangeEvent',
      );
    }
  }

  private handleOpacityChange(snap) {
    this.instructionOpacity =
      snap.val() == null ? this.DEFAULT_OPACITY : snap.val();
    this.dispatchEvent(
      snap,
      this.instructionOpacity,
      'value',
      'opacityChangeEvent',
    );
  }

  private handleZoomChange(snap) {
    this.dispatchEvent(snap, snap.val(), 'value', 'zoomChangeEvent');
  }

  private handleOffsetChange(snap) {
    this.dispatchEvent(snap, snap.val(), 'value', 'offsetChangeEvent');
  }

  private handleScrollXPercent(snap) {
    this.setScrollXPercent(this.getScrollXFromSnap(snap));
    this.dispatchEvent(
      snap,
      this.getScrollXPercent(),
      'value',
      'scrollXChangeEvent',
    );
  }

  private handleScrollYPercent(snap) {
    this.setScrollYPercent(this.getScrollYFromSnap(snap));
    this.dispatchEvent(
      snap,
      this.getScrollYPercent(),
      'value',
      'scrollYChangeEvent',
    );
  }

  dispatchEvent(snap, data, srcType, targetType) {
    const event: AssessmentSessionChangeEvent = {
      data,
      type: targetType,
      _snapshot: snap,
      sessionId: this.sessionId,
    };
    this.changedSubject.next(event);
  }

  private registerCallback(ref, event, callback, context) {
    this.callbackRegistry.add({ ref, event, callback, context });
  }

  /**
   * Clean up all firebase listeners that were registered.
   * @private
   */
  private clearCallbackRegistry() {
    if (!this.callbackRegistry) {
      this.callbackRegistry = new Set();
    }
    for (const item of this.callbackRegistry.values()) {
      try {
        item.ref.off(item.event, item.callback, this);
      } catch (error) {
        console.warn(error);
      }
    }
    this.callbackRegistry.clear();
  }

  /***************************
   * util
   ***************************/
  private getValueFromSnap(snap, key, defaultValue) {
    const val = snap.val();
    let newVal = val || defaultValue;
    if (val !== null && typeof val === 'object') {
      newVal = val[key];
    }
    return newVal || defaultValue;
  }

  private getScrollXFromSnap(snap) {
    const scrollXPercent = this.getValueFromSnap(
      snap,
      this.fbScrollXPercentPath(),
      this.DEFAULT_SCROLL_X,
    );
    return scrollXPercent;
  }

  private getScrollYFromSnap(snap) {
    const scrollYPercent = this.getValueFromSnap(
      snap,
      this.fbScrollYPercentPath(),
      this.DEFAULT_SCROLL_Y,
    );
    return scrollYPercent;
  }

  private fbScrollXPercentPath() {
    return 'scrollXPercent';
  }

  private fbScrollYPercentPath() {
    return 'scrollYPercent';
  }
}
