import { Component, Input, OnInit } from '@angular/core';
import { EndGameObj, Player } from '../../clue-store';
import { ClueService } from '../../pl-clue.service';

@Component({
  selector: 'conclusion-screen',
  templateUrl: './conclusion-screen.component.html',
  styleUrls: ['./conclusion-screen.component.less'],
})

export class PLClueConclusionScreenComponent implements OnInit {
  @Input() currentUser: Player;
  @Input() endGameObj: EndGameObj;
  @Input() playersArr: Player[];
  @Input() provider: string;
  @Input() currentPlayerTurn: any;
  @Input() firebaseClueRef;
  public correctCards;
  public winnerByDefaultBool: boolean = false;
  public winnersBySolutionBool: boolean = false;
  public showContinueBtn: boolean = false;

  constructor(
    private clueService: ClueService
  ) {}

  ngOnInit(): void {
    this.winnerByDefault();
    this.winnersBySolution();
    this.showContinueBtnCheck();
  }

  private showContinueBtnCheck() {
    const { playersSkipped, winnersList } = this.endGameObj;

    if (
      (playersSkipped && playersSkipped.length > 1) &&
      (!winnersList || winnersList.length === 0) &&
      this.currentUser.userId === this.provider
    ) {
      this.showContinueBtn = true;
    }
  }

  public winnersBySolution() {
      const { winnersList, defaultWin } = this.endGameObj;

      if (winnersList && winnersList.length > 0 && !defaultWin) {
          this.winnersBySolutionBool = true;
      }
  }

  public winnerByDefault() {
    const { winnersList, playersSkipped } = this.endGameObj;

    if (
      (winnersList && winnersList.length === 1) &&
      (playersSkipped && playersSkipped.length === 1) &&
      (playersSkipped[0].userId == winnersList[0].userId)
    ) {
      this.winnerByDefaultBool = true;
    }
  }

  public continueGameClick() {
    let updatedEndGameObj = { ...this.endGameObj };
    updatedEndGameObj.playersSkipped = [];
    let updatedPlayersArr = this.playersArr.map((player) => {

      let tempPlayer = {
        ...player,
        solvingTheCaseObj: {
            ...player.solvingTheCaseObj
        }
      }
      let { answersSubmitted, isSolving, skipSolving } = tempPlayer.solvingTheCaseObj;
      if (answersSubmitted && isSolving) {
        tempPlayer.disabled = true;
      }

      if (skipSolving) {
        tempPlayer.solvingTheCaseObj.skipSolving = false;
      }
      return tempPlayer;
    });

    let nextPlayer = this.currentPlayerTurn + 1;
    if (nextPlayer > this.playersArr.length) {
      nextPlayer = 1;
    }

    const updatedInvestigationObj = this.clueService.updateInvestigationObj(this.playersArr, nextPlayer);

    this.firebaseClueRef.update({
      investigationObj: updatedInvestigationObj.investigationObj,
      playerTurn: updatedInvestigationObj.playerTurn,
      playersArr: updatedPlayersArr,
      endGameObj: updatedEndGameObj,
      conclusionScreenActive: false,
      solveCaseActive: false
    });
  }
}