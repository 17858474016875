<div class="metrics-tracking">
  <div
    *ngIf="
      !goals || goals?.length === 0 || metricOptions?.length === 0;
      else metrics
    ">
    <pl-alert-message>
      <div>
        To track progress for this client, you must add a therapy goal and at
        least one metric.
      </div>
      <a [href]="documentationHelpUrl" target="_blank"
        >Read about documentation in the Presence Knowledge Base
        <pl-icon-new [name]="'open_in_new'" inline></pl-icon-new>
      </a>
      <button
        mat-flat-button
        color="primary"
        (click)="openGoals()"
        class="w-100">
        <span *ngIf="!goals || goals?.length === 0; else metricMessage"
          >Add goal</span
        >
        <ng-template #metricMessage> <span>Add metric</span></ng-template>
        <pl-icon-new [name]="'open_in_new'"></pl-icon-new>
      </button>
    </pl-alert-message>
  </div>
  <ng-template #metrics>
    <pl-multi-select-menu
      *ngIf="metricOptions"
      [selectionOptions]="metricOptions"
      [itemName]="'metric'"
      (selectionChanged)="metricSelectionChanged($event)">
      <div class="button-container">
        <button mat-stroked-button (click)="openGoals()" class="open-button">
          Open goals
          <pl-icon-new [name]="'open_in_new'"></pl-icon-new>
        </button>
        <button mat-flat-button class="done-button">
          <span>Done</span>
        </button>
      </div>
    </pl-multi-select-menu>

    <div
      *ngFor="let metricPoint of metricPoints; trackBy: trackByMetricPoint"
      [hidden]="!metricPoint.xSelected">
      <pl-metric-item
        [metricPoint]="metricPoint"
        [updating]="updatingMetricPoint"
        [clientAppointment]="clientAppointment"
        (metricPointUpdated)="metricPointUpdated($event)"></pl-metric-item>
    </div>
  </ng-template>
</div>
