import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PLInputModule } from '@root/index';

import { PLCommonComponentsModule } from '@common/components';
import { PLDrawerPanelModule } from '../pl-drawer-panel';

import { PLGamesDrawerComponent } from './pl-games-drawer.component';
import { PLBoardGameControlsComponent } from './controls/pl-board-game-controls/pl-board-game-controls.component';
import { PLPieceSelectButtonComponent } from './controls/pl-piece-select-button/pl-piece-select-button.component';
import { PlBoardGameSelectButtonComponent } from './pl-board-game-select-button/pl-board-game-select-button.component';

import { StoreModule } from '@ngrx/store';

import { reducer } from '../store';
import { PLPermissionsModule } from '@common/auth';
import { ActivitySwitchModule } from '../activity-switch';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { GameStartEndBtnsModule } from '../../pl-activity/game/game-start-end-btns';

@NgModule({
  imports: [
    CommonModule,
    PLInputModule,
    PLCommonComponentsModule,
    PLDrawerPanelModule,
    PLPermissionsModule,
    StoreModule.forFeature('drawer', reducer),
    ActivitySwitchModule,
    MatIconModule,
    MatTabsModule,
    MatButtonModule,
    GameStartEndBtnsModule,
  ],
  exports: [PLGamesDrawerComponent],
  declarations: [
    PLGamesDrawerComponent,
    PLBoardGameControlsComponent,
    PLPieceSelectButtonComponent,
    PlBoardGameSelectButtonComponent,
  ],
  providers: [],
})
export class PLGamesDrawerModule {}

export {
  PLGamesDrawerComponent,
  PLBoardGameControlsComponent,
  PLPieceSelectButtonComponent,
  PlBoardGameSelectButtonComponent,
};
