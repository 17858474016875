import { Component, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppModel } from '@common/models/app-model.service';
import { AppState } from '@app/store';
import { Observable, Subscription } from 'rxjs';
import {
  DrawerActions,
  DrawerNameType,
  DrawerNames,
  DrawerState,
  selectDrawer,
} from '../store';
import { FirebaseAppModel } from '@common/models/firebase/firebase-app-model.service';
import { selectUnreadCount } from '../pl-chat-drawer/store';
import { matMenuAnimations } from '@angular/material/menu';
import { PLChatDrawerService } from '../pl-chat-drawer/pl-chat-drawer.service';
import {
  FeatureFlag,
  FeatureFlagsService,
} from '@root/src/app/common/services/feature-flags';

interface ToolbarItem {
  id: string;
  drawerName: DrawerNameType;
  iconProps: {
    icon: string;
    size?: number;
    disabledIcon?: string;
  };
  tooltip: string;
  permissions?: string;
  uiFlag?: string;
  featureFlag?: string;
}

const toolbarItems: ToolbarItem[] = [
  {
    id: 'activities-drawer-button',
    drawerName: 'activities',
    iconProps: {
      icon: 'documentation',
    },
    tooltip: 'Queues',
    permissions: 'PERMS_DEFAULT_PROVIDERS',
  },
  {
    id: 'games-drawer-button',
    drawerName: 'games',
    iconProps: {
      icon: 'gameboard',
    },
    tooltip: 'Games',
    permissions: 'PERMS_DEFAULT_PROVIDERS',
  },
  {
    id: 'teamwrite-drawer-button',
    drawerName: 'teamWrite',
    iconProps: {
      icon: 'team-write',
    },
    tooltip: 'Team Write',
    permissions: 'PERMS_DEFAULT_PROVIDERS',
  },
  {
    id: 'content-sharing-drawer-button',
    drawerName: 'contentSharing',
    iconProps: {
      icon: 'cobrowsing',
    },
    tooltip: 'Content sharing',
    permissions: 'PERMS_DEFAULT_PROVIDERS',
  },
  {
    id: 'documentation-drawer-button',
    drawerName: 'document',
    iconProps: {
      icon: 'notes',
    },
    tooltip: 'Documentation',
    permissions: 'Therapist || Administrator || SaaS Providers',
  },
  {
    id: 'session-record-drawer-button',
    drawerName: 'sessionRecord',
    iconProps: {
      icon: 'video',
    },
    tooltip: 'Session Recording',
    permissions: 'Therapist || Administrator',
  },
  {
    id: 'chat-drawer-button',
    drawerName: 'chat',
    iconProps: {
      icon: 'chat',
      disabledIcon: 'chat-disabled',
    },
    tooltip: 'Chat',
    permissions: 'All',
  },
];

@Component({
  selector: 'pl-right-toolbar',
  templateUrl: 'pl-right-toolbar.component.html',
  styleUrls: ['pl-right-toolbar.component.less'],
})
export class PLRightToolbarComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  drawer: DrawerState = {
    activeName: 'chat',
    open: false,
  };
  unreadChatCount$: Observable<number>;
  isHelpDrawerActive = false;
  items = [...toolbarItems];
  private subscriptions: Subscription[] = [];

  get isTokboxRecording() {
    return this.firebaseAppModel.app.tokboxIsRecording;
  }

  constructor(
    private appModel: AppModel,
    private firebaseAppModel: FirebaseAppModel,
    // TODO: Remove the above when the app is fully migrated to use ngrx
    private store: Store<AppState>,
    private cd: ChangeDetectorRef,
    private chatService: PLChatDrawerService,
    private featureFlagsService: FeatureFlagsService,
  ) {
    matMenuAnimations.transformMenu.definitions.splice(2, 1);
    this.unreadChatCount$ = store.select(selectUnreadCount);
  }

  ngOnInit() {
    this.chatService.currentTime = Date.now();
    this.subscriptions.push(this.subscribeToDrawer());
    this.subscriptions.push(this.checkDocumentationFeatureFlag());
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private checkDocumentationFeatureFlag() {
    return this.featureFlagsService
      .isFeatureEnabled(FeatureFlag.NEW_DOCUMENTATION)
      .subscribe(enabled => {
        if (enabled) {
          this.items = toolbarItems.filter(
            item => item.drawerName !== 'sessionRecord',
          );
        } else {
          this.items = [...toolbarItems];
        }
      });
  }

  private subscribeToDrawer(): Subscription {
    return this.store.select(selectDrawer).subscribe(drawer => {
      this.drawer = drawer;
      if (drawer.open) {
        if (!this.isValidDrawerName(drawer.activeName)) {
          // Invalid drawer state
          this.toggleDrawer();
          return;
        }
        this.appModel.openDrawer(this.drawer.activeName);
      } else {
        this.appModel.closeDrawer();
      }
    });
  }

  toggleDrawer() {
    if (this.drawer.open) {
      this.store.dispatch(
        DrawerActions.close({ drawerName: this.drawer.activeName }),
      );
    } else if (!this.drawer.open) {
      this.store.dispatch(
        DrawerActions.open({ drawerName: this.drawer.activeName }),
      );
    }
  }

  openDrawer(drawerName: DrawerNameType) {
    if (drawerName === this.drawer.activeName) {
      return this.toggleDrawer();
    }

    this.store.dispatch(DrawerActions.open({ drawerName }));
  }

  toggleHelpActive(active: boolean) {
    this.isHelpDrawerActive = active;
  }

  private isValidDrawerName(drawerName: DrawerNameType) {
    return Object.keys(DrawerNames).indexOf(drawerName) > -1;
  }
}
