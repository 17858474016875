import { Component, OnInit, Input, NgZone, ElementRef } from '@angular/core';
import { GamePieceInstance } from '@modules/pl-games/pl-board-games/pl-board-games-factory.service';
import { FirebaseService } from '@root/src/app/common/firebase/firebase.service';

@Component({
  selector: 'pl-candyland-board',
  templateUrl: './pl-candyland-board.component.html',
  styleUrls: ['./pl-candyland-board.component.less'],
})
export class PLCandyLandBoardComponent implements OnInit {
  @Input() activity: any = {};
  showCandyLandInstruction: boolean = false;
  isGameActive: boolean;
  currentCastle: number;
  cardDeck: any[] = [];
  currentCard: string;
  drawn: boolean;
  animating: boolean;
  currentCardUrl: string;
  showCard: boolean;
  cardDeckLen: number;

  public firebaseCandyLandRef;
  public pieces: GamePieceInstance[] = [];

  constructor(
    public firebaseService: FirebaseService,
    public element: ElementRef,
    private zone: NgZone,
  ) {}

  ngOnInit(): void {
    const fbPath = `activities/queues/items/${this.activity.queueId}/items/${this.activity.activityId}`;
    this.firebaseCandyLandRef = this.firebaseService.getRoomRef(fbPath);
    this.initFirebase();
  }

  drawCard() {
    if (this.animating) {
      return;
    }

    if (this.cardDeck.length === 0) {
      this.currentCard = 'card_back';
    }

    const newCard = this.cardDeck.pop();
    this.firebaseCandyLandRef.update({
      currentCard: newCard,
      cardDeck: this.cardDeck,
    });
  }

  setAnimation() {
    this.drawn = true;
    this.animating = true;
    this.showCard = false;

    setTimeout(() => {
      this.drawn = false;
      this.animating = false;
      this.showCard = true;
    }, 600);
  }

  placePieces(playerPieces) {
    this.pieces = [];
    const keys = Object.keys(playerPieces);
    keys.forEach(key => {
      let currentObj = playerPieces[key];
      this.pieces.push(currentObj);
    });
  }

  removeCastlePiece() {
    if (this.currentCastle === 22) {
      return;
    }
    this.currentCastle = this.currentCastle + 1;
    this.firebaseCandyLandRef.update({
      currentCastleNumber: this.currentCastle,
    });
  }

  fbUpdatePiece(piece: GamePieceInstance) {
    piece.updated = new Date().getTime();
    this.firebaseCandyLandRef.child('pieces/' + piece.key).update(piece);
    this.firebaseCandyLandRef.child('modified').set(new Date().getTime());
  }

  pieceDragEnded(dragEvent: any) {
    const piece = dragEvent.piece;
    const distance = dragEvent.distance;

    const boundrect = this.element.nativeElement.getBoundingClientRect();
    const newHeight = boundrect.height * 0.7;

    piece.x += (100 * distance.x) / boundrect.width;
    piece.y += (100 * distance.y) / newHeight;

    const pieceRight = piece.x + piece.width;
    const pieceBottom = piece.y + piece.height;

    const existing = this.pieces.find(p => p.key === piece.key);
    if (existing) {
      existing.x = piece.x;
      existing.y = piece.y;
    }

    if (piece.x > 79 || pieceRight < 18 || piece.y > 100 || pieceBottom < 0) {
      piece.x = pieceRight < 18 ? 18 : piece.x;
      piece.x = pieceRight > 79 ? 79 : piece.x;
      piece.y = piece.y > 99 ? 95 : piece.y;
      piece.y = pieceBottom < 1 ? 0 : piece.y;
    }
    this.fbUpdatePiece(piece);
  }

  initFirebase() {
    this.firebaseCandyLandRef.on('value', data => {
      this.zone.run(() => {
        const val = data.val();
        if (!val) {
          return;
        }

        const {
          isGameActive,
          showInstruction,
          currentCastleNumber,
          cardDeck,
          currentCard,
          pieces,
        } = val;

        if (currentCard && cardDeck && cardDeck.length !== this.cardDeckLen) {
          this.setAnimation();
          this.cardDeckLen = cardDeck.length;
        }

        if (!!pieces && Object.keys(pieces).length === 6 && isGameActive) {
          this.placePieces(pieces);
        } else if (!pieces) {
          this.pieces = [];
        }

        this.isGameActive = !!isGameActive;
        this.showCandyLandInstruction = !!showInstruction;
        this.currentCastle = currentCastleNumber || 0;
        this.cardDeck = cardDeck || [];
        this.currentCard = currentCard || '';
        this.currentCardUrl = currentCard
          ? `/assets/board-games/candyland_game_assets/cards/${currentCard}.png`
          : '/assets/board-games/candyland_game_assets/cards/card_back.png';
      });
    });
  }
}
