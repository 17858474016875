<div class="player-count-select">
  <label id="number-of-players-label">Number of players</label>

  <mat-button-toggle-group
    aria-describedby="number-of-players-label"
    name="player-number"
    [value]="selection"
    (change)="onChangeNumPlayers($event.value)"
    [disabled]="isDisabled"
  >
    <mat-button-toggle
      *ngFor="let option of playerCountOptions"
      [value]="option.value"
      [disabled]="isDisabled"
      class="button-toggle"
      data-testid="player-count-option"
      >{{ option.label }}</mat-button-toggle
    >
  </mat-button-toggle-group>
</div>
