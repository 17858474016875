<div class="activity-drawer pl-activity-drawer">
  <div class="drawer-subheader">
    <h4
      class="drawer-subheader__title"
      *ngIf="currentActivity.name.length < 50"
    >
      {{ currentActivity.name }}
    </h4>
    <h4
      class="drawer-subheader__title"
      *ngIf="currentActivity.name.length > 50"
      matTooltip="{{ currentActivity.name }}"
    >
      {{ currentActivity.name }}
    </h4>
  </div>

  <pl-activity-switch-button
    [contentType]="'queueitem'"
    [contentDetail]="currentActivity"
  ></pl-activity-switch-button>

  <div class="activity-drawer__content" *ngIf="showActivity">
    <pl-pdf-lesson-viewer-drawer
      *ngIf="activityType === 'pdf'"
    ></pl-pdf-lesson-viewer-drawer>

    <pl-pdf-viewer-drawer
      *ngIf="activityType === 'pdfviewer'"
    ></pl-pdf-viewer-drawer>

    <pl-youtube-activity-drawer
      *ngIf="activityType === 'youtube'"
    ></pl-youtube-activity-drawer>

    <pl-flashcards-activity-drawer
      *ngIf="activityType === 'flashcards'"
      [queueActivity]="currentActivity"
    ></pl-flashcards-activity-drawer>

    <pl-memory-activity-drawer
      class="pl-memory-activity-drawer"
      *ngIf="activityType === 'memory'"
      [activity]="currentActivity"
    ></pl-memory-activity-drawer>

    <pl-pdf-assessment-drawer
      *ngIf="
        activityType === 'doubleassessment' ||
        activityType === 'wjaudioassessment'
      "
      [activity]="currentActivity"
    ></pl-pdf-assessment-drawer>

    <pl-game-drawer
      *ngIf="
        activityType &&
        ((activityType && activityType.slice(0, 5) === 'game-') ||
          activityType === 'whos-that')
      "
      class="game-drawer"
      [activity]="currentActivity"
    ></pl-game-drawer>
  </div>

  <div *ngIf="unavailable" class="padding italicized">
    Sorry, the selected item is no longer available.
  </div>

  <div *ngIf="assessmentAccessDenied" class="padding italicized">
    You currently do not have access to this content because there are no
    relevant evaluation services on your caseload at this time.
  </div>
</div>
