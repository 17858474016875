import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { StreamScreenshotService } from './stream-screenshot.service';
import { FilteredCanvasService } from '@root/src/app/common/services/filtered-canvas.service';

@Component({
  selector: 'pl-stream-screenshot-action',
  templateUrl: 'stream-screenshot-action.component.html',
  styleUrls: ['stream-screenshot-action.component.less'],
  providers: [StreamScreenshotService],
})
export class StreamScreenshotActionComponent {
  public isScreenshotting$: Observable<boolean>;
  public canScreenshot$: Observable<boolean>;
  showScreenshotButton$: Observable<boolean>;
  showButton$: Observable<boolean>;
  isCapturing$: Observable<boolean>;
  loading: boolean;

  constructor(
    private streamScreenshotService: StreamScreenshotService,
    private filteredCanvasService: FilteredCanvasService,
  ) {
    this.showButton$ = this.streamScreenshotService.showButton$;
    this.isScreenshotting$ = this.streamScreenshotService.isScreenshotting$;
    this.isCapturing$ = this.streamScreenshotService.isCapturing$;
  }

  startScreenshotting() {
    this.loading = true;
    this.filteredCanvasService.loadNet().then(result => {
      this.loading = false;
      this.streamScreenshotService.startScreenshotting();
    });
  }

  capture() {
    this.streamScreenshotService.capture();
  }
}
