<div
  class="flashcard-activity-drawer"
  data-testid="flashcards-drawer"
  (dropped)="cancelDrag($event)"
  (dragCancel)="cancelDrag($event)"
>
  <mat-tab-group
    class="flashcards-tabs is-compact"
    mat-stretch-tabs
    color="accent"
  >
    <mat-tab label="Cards">
      <ng-container *ngTemplateOutlet="cards"></ng-container>
    </mat-tab>

    <mat-tab label="Options">
      <ng-container *ngTemplateOutlet="options"></ng-container>
    </mat-tab>
  </mat-tab-group>
</div>

<ng-template #cards>
  <div class="cards-container">
    <div class="cards-controls">
      <button
        mat-raised-button
        color="primary"
        [disabled]="!(isActivityActive$ | async) || !availableCards.length"
        [ngClass]="{
          disabled: !(isActivityActive$ | async) || !availableCards.length
        }"
        (click)="addAllCards()"
      >
        Add all cards
      </button>
      <button
        mat-raised-button
        color="primary"
        [disabled]="!(isActivityActive$ | async) || !flashcardsOnBoard.length"
        class="remove-all-button"
        [ngClass]="{
          disabled: !(isActivityActive$ | async) || !flashcardsOnBoard.length
        }"
        (click)="deleteAllCards()"
      >
        Remove all cards
      </button>
      <div>Cards can be dragged from below onto the workspace.</div>
    </div>
    <div class="flashcard-list-container">
      <div
        class="flashcard-list"
        [ngClass]="{ disabled: !(isActivityActive$ | async) }"
      >
        <pl-alert-message class="margin-t" *ngIf="!availableCards.length"
          >No more cards available.</pl-alert-message
        >
        <div
          *ngFor="let card of availableCards; trackBy: trackByCard"
          [plDrag]="startDrag"
          (dragStart)="startDrag($event)"
          [plDragData]="card"
          class="card"
        >
          <div class="card-icon">
            <figure
              [ngStyle]="{
                'background-image': 'url(' + card.thumbnail_url + ')'
              }"
              *ngIf="card.type !== 'title'"
            ></figure>
            <div class="card-text-image" *ngIf="card.type === 'title'">
              Text only
            </div>
          </div>
          <div class="card-text">
            <div>{{ card.title }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #options>
  <div class="options-container">
    <div class="cards-options">
      <label>Cards layout</label>
      <div class="cards-layout-options">
        <div
          class="cards-layout-options-item"
          *ngFor="let mode of flashcardsDisplayModeEnum | keyvalue"
          (click)="setFlashcardsMode(mode.key)"
        >
          <pl-icon-new
            class="display-icon"
            [name]="'display-' + mode.key"
          ></pl-icon-new>
          <span>{{ mode.value }}</span>
        </div>
      </div>
    </div>
    <div class="cards-options">
      <pl-card-display-options
        (optionSelected)="changeCardDisplayOption($event)"
        [value]="cardDisplayChoice"
        [useOptions]="cardDisplayOptions"
      ></pl-card-display-options>
    </div>
  </div>
</ng-template>
