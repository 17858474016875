<ng-container *ngIf="{ val: isLocal$ | async } as isLocal">
  <pl-local-stream
    data-testid="local-stream"
    *ngIf="isLocal.val"
  ></pl-local-stream>
  <pl-remote-stream
    data-testid="remote-stream"
    *ngIf="!isLocal.val"
  ></pl-remote-stream>
</ng-container>
