<div
  class="drawer-content-sharing"
  [ngClass]="{ 'panel-2-active': detailMode }">
  <header class="drawer-header">
    <div class="drawer-header-left">
      <mat-icon
        *ngIf="detailMode"
        (click)="leaveDetail()"
        class="icon-gray"
        role="button">
        arrow_back
      </mat-icon>
      <h3 class="drawer-header__title">Content sharing</h3>
    </div>
  </header>

  <div class="panel-1 content-sharing-tab-one">
    <p class="info">
      Bring interactive content into your Room either from your computer or from
      the web!
    </p>
    <div class="content-select-btn-container border-top">
      <button
        class="content-select-btn"
        (click)="enterDetailMode(ContentShareMode.SCREENSHARE)">
        <img
          width="40"
          src="/assets/svg/activity_panel/content_sharing/screen_share_icon.svg" />
        <div>
          <p class="top-label">SCREEN SHARE</p>
          <p class="bottom-label">Share your screen</p>
        </div>
      </button>
    </div>

    <div class="content-select-btn-container">
      <button
        class="content-select-btn"
        (click)="enterDetailMode(ContentShareMode.SITESHARE)">
        <img
          width="40"
          src="/assets/svg/activity_panel/content_sharing/site_share_icon.svg" />
        <div>
          <p class="top-label">SITE SHARE</p>
          <p class="bottom-label">Browse the web</p>
        </div>
      </button>
    </div>

    <div
      class="content-select-btn-container"
      *ngIf="canBoomCardsSiteShare$ | async">
      <button
        class="content-select-btn"
        (click)="enterDetailMode(ContentShareMode.BOOMCARDS_SITESHARE)">
        <img
          width="40"
          src="/assets/svg/activity_panel/content_sharing/boom_cards.svg" />
        <div>
          <p class="top-label">BOOM CARDS</p>
          <p class="bottom-label">Play Boom Cards</p>
        </div>
      </button>
    </div>

    <div
      class="content-select-btn-container"
      *ngIf="canBoomCardsIframe$ | async">
      <button
        class="content-select-btn"
        (click)="enterDetailMode(ContentShareMode.BOOMCARDS)">
        <img
          width="40"
          src="/assets/svg/activity_panel/content_sharing/boom_cards.svg" />
        <div>
          <p class="top-label">BOOM CARDS</p>
          <p class="bottom-label">Play Boom Cards</p>
        </div>
      </button>
    </div>
  </div>

  <div class="panel-2 content-sharing-details">
    <main class="drawer-ui">
      <ng-container *ngIf="detailMode === ContentShareMode.SCREENSHARE">
        <div class="drawer-subheader">
          <h4 class="drawer-subheader__title">Screen share</h4>
        </div>
        <pl-activity-switch-button
          [contentType]="'contentshare'"></pl-activity-switch-button>
        <div class="content-info">
          <p>
            Share your computer screen or window to show content from your
            desktop including:
          </p>
          <ul class="item-list">
            <li>PDFs</li>
            <li>documents</li>
            <li>images</li>
            <li>web browser content</li>
          </ul>
          <p>Firefox does not support screen sharing audio.</p>
          <p>
            Be sure not to show any HIPAA protected or other sensitive
            information that may be on your screen.
          </p>

          <button
            *ngIf="(isScreenshareActive$ | async) === false"
            (click)="toggleScreenshare(true)"
            mat-flat-button
            color="success">
            Start screen share
          </button>

          <button
            *ngIf="isScreenshareActive$ | async"
            (click)="toggleScreenshare(false)"
            mat-flat-button
            color="warn">
            Stop screen share
          </button>
        </div>
      </ng-container>

      <ng-container *ngIf="detailMode === ContentShareMode.SITESHARE">
        <div class="drawer-subheader">
          <h4 class="drawer-subheader__title">Site Share</h4>
        </div>
        <pl-activity-switch-button
          [contentType]="'siteshare'"
          [contentDetail]="'default'"></pl-activity-switch-button>

        <div class="content-info">
          <p>
            Browse websites and play interactive games with the ability to share
            mouse control.
          </p>
          <p>
            To allow students to interact with websites, select “Guests can
            control” from the “Permissions” menu within Site Share.
          </p>
          <button
            *ngIf="(isSiteShareActive$ | async) === false"
            (click)="toggleSiteshare(true)"
            mat-raised-button
            color="success">
            Start Site Share
          </button>

          <button
            *ngIf="isSiteShareActive$ | async"
            (click)="toggleSiteshare(false)"
            mat-raised-button
            color="warn">
            Stop Site Share
          </button>
        </div>
      </ng-container>

      <ng-container *ngIf="detailMode === ContentShareMode.BOOMCARDS_SITESHARE">
        <div class="drawer-subheader">
          <h4 class="drawer-subheader__title">Boom Cards</h4>
        </div>
        <pl-activity-switch-button
          [contentType]="'siteshare'"
          [contentDetail]="'boomcards'"></pl-activity-switch-button>
        <div class="content-info">
          <pl-alert-message>
            Boom Cards are interactive activities that can be played with
            everyone in the Room. You can access your Boom Cards Library through
            Site Share.

            <a href="https://wow.boomlearning.com/" target="_blank">
              Learn more about Boom Cards
              <pl-icon-new [name]="'open_in_new'" inline></pl-icon-new>
            </a>
          </pl-alert-message>
          <button
            class="margin-t"
            *ngIf="(isBoomCardsSiteShareActive$ | async) === false"
            (click)="toggleBoomCardsSiteshare(true)"
            mat-raised-button
            color="success">
            Start Boom Cards
          </button>

          <button
            *ngIf="isBoomCardsSiteShareActive$ | async"
            (click)="toggleBoomCardsSiteshare(false)"
            mat-raised-button
            color="warn">
            Stop Boom Cards
          </button>
          Once you have logged in to Boom Cards and chosen an activity from your
          Library, select “Guests can control” from the “Permissions” menu to
          allow everyone to interact with the activity.
        </div>
      </ng-container>

      <pl-boom-cards-drawer
        *ngIf="
          detailMode === ContentShareMode.BOOMCARDS
        "></pl-boom-cards-drawer>
    </main>
  </div>
</div>
