import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PLSessionRecordDrawerControlsComponent } from './pl-session-record-controls.component';
import { PLCommonComponentsModule } from '@common/components';
import {
  PLApiBillingCodesService,
  PLApiAbstractService,
} from '@root/src/app/common/services/api';
import { PLGQLClientsService } from '@common/services/pl-graph-ql';
import { PLButtonModule, PLInputModule } from '@root/index';
import { PLSessionPreviewComponent } from './pl-session-preview.component';
import { PLSessionRecordDrawerComponent } from './pl-session-record-drawer.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  imports: [
    CommonModule,
    PLCommonComponentsModule,
    PLButtonModule,
    PLInputModule,
    MatIconModule,
  ],
  exports: [PLSessionRecordDrawerComponent],
  declarations: [
    PLSessionPreviewComponent,
    PLSessionRecordDrawerControlsComponent,
    PLSessionRecordDrawerComponent,
  ],
  providers: [
    PLApiBillingCodesService,
    PLApiAbstractService,
    PLGQLClientsService,
  ],
})
export class PLSessionRecordModule {}
