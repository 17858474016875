<div class="trio-drawer" data-testid="game-trio-drawer">
  <button
    mat-button
    class="show-button"
    (click)="toggleInstruction()"
    [disabled]="showExample"
  >
    <mat-icon class="material-icons-outlined">preview</mat-icon>

    {{ showInstruction ? 'Hide instructions' : 'Show instructions' }}
  </button>
  <button
    mat-button
    class="show-button"
    (click)="toggleExample()"
    [disabled]="showInstruction"
  >
    <mat-icon class="material-icons-outlined">preview</mat-icon>
    {{ showExample ? 'Hide examples' : 'Show examples' }}
  </button>
  <mat-tab-group
    class="trio-tabs is-compact"
    mat-stretch-tabs
    color="accent"
    [selectedIndex]="currentTabIndex$ | async"
  >
    <mat-tab label="Players">
      <ng-container *ngTemplateOutlet="players"></ng-container>
    </mat-tab>

    <mat-tab label="Options">
      <ng-container *ngTemplateOutlet="options"></ng-container>
    </mat-tab>
  </mat-tab-group>

  <game-start-end-btns
    [canStartGame]="canStartGame && !isGameActive"
    [isGameActive]="isGameActive"
    (clickNewGame)="onClickStartNewGame()"
    (clickEndGame)="onClickEndGame()"
  ></game-start-end-btns>
</div>

<ng-template #players>
  <div class="players-container">
    <pl-player-count-select
      [playerCountOptions]="formData.numPlayersOpts"
      [selection]="formData.model.numPlayers"
      (numPlayersChange)="onChangeNumPlayers($event)"
      [isDisabled]="isGameActive"
    ></pl-player-count-select>
    <ng-container *ngFor="let player of formData.model.players">
      <mat-form-field
        color="accent"
        class="w-100"
        *ngIf="formData.model.numPlayers >= player.position"
      >
        <mat-label>Select player {{ player.position }}</mat-label>
        <mat-select
          [(ngModel)]="player.value"
          (selectionChange)="onChangePlayerCheck(player.position, player.value)"
          [disabled]="isGameActive"
          data-testid="trio-player"
        >
          <mat-option
            *ngFor="let opt of formData.playerOpts"
            [value]="opt.value"
          >
            {{ opt.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
  </div>
</ng-template>

<ng-template #options>
  <div class="options-group">
    <div class="mat-slide-toggle-group" data-testid="game-sounds-group">
      <label id="game-sounds-toggle"> Game sounds </label>
      <mat-slide-toggle
        data-testid="game-sounds-toggle"
        [(ngModel)]="formData.model.gameSounds"
        (ngModelChange)="onChangeMute()"
        aria-labelledby="game-sounds-toggle"
      >
        {{ formData.model.gameSounds ? 'On' : 'Off' }}
      </mat-slide-toggle>
    </div>
  </div>
</ng-template>
