import { NgModule } from '@angular/core';
import { TimeFormatterPipe } from './time-formatter.pipe';
import { SafePipe } from './safe.pipe';

@NgModule({
  imports: [],
  exports: [TimeFormatterPipe, SafePipe],
  declarations: [TimeFormatterPipe, SafePipe],
  providers: [],
})
export class PLCommonPipesModule {}

export { TimeFormatterPipe, SafePipe };
