import { Injectable } from '@angular/core';
import { FirebaseService } from '@common/firebase/firebase.service';
import { FirebaseRef } from '@common/firebase/firebase-ref';
import { map } from 'rxjs/operators';
import { DrawerState } from './store';

@Injectable({ providedIn: 'root' })
export class DrawersService {
  private drawersRef: FirebaseRef;

  constructor(firebaseService: FirebaseService) {
    this.drawersRef = new FirebaseRef(
      firebaseService.getLazyRoomRef('app/rightDrawers'),
    );
  }

  onChanged() {
    return this.drawersRef.valueChanges.pipe(
      map(snap => snap.val() as DrawerState),
    );
  }

  update(data: any) {
    return this.drawersRef.update(data);
  }

  once() {
    return this.drawersRef.once('value').pipe(
      map(snap => snap.val() as DrawerState),
    );
  }
}
