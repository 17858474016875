<div data-testid="design-system-tabs">
  <div class="demo-group">
    <div class="demo-row">
      <div class="demo-col">
        <h2 class="heading-2">Basic Tabs</h2>

        <h3 class="heading-3">Primary</h3>
        <mat-tab-group>
          <mat-tab label="Tab 1"></mat-tab>
          <mat-tab label="Tab 2"></mat-tab>
          <mat-tab label="Tab 3"></mat-tab>
        </mat-tab-group>

        <h3 class="heading-3">Accent</h3>
        <mat-tab-group color="accent">
          <mat-tab label="Tab 1"></mat-tab>
          <mat-tab label="Tab 2"></mat-tab>
          <mat-tab label="Tab 3"></mat-tab>
        </mat-tab-group>
      </div>
      <div class="demo-col">
        <h2 class="heading-2">Compact Tabs</h2>

        <h3 class="heading-3">Primary</h3>
        <mat-tab-group class="is-compact">
          <mat-tab label="Tab 1"></mat-tab>
          <mat-tab label="Tab 2"></mat-tab>
          <mat-tab label="Tab 3"></mat-tab>
        </mat-tab-group>

        <h3 class="heading-3">Accent</h3>
        <mat-tab-group class="is-compact" color="accent">
          <mat-tab label="Tab 1"></mat-tab>
          <mat-tab label="Tab 2"></mat-tab>
          <mat-tab label="Tab 3"></mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
