import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

interface YoutubeThumbnail {
  url: string;
  width: number;
  height: number;
}

export interface YoutubeResult {
  kind: string;
  etag: string;
  id: string;
  snippet: {
    publishedAt: string;
    channelId: string;
    title: string;
    description: string;
    thumbnails: {
      default: YoutubeThumbnail;
      medium: YoutubeThumbnail;
      high: YoutubeThumbnail;
      standard: YoutubeThumbnail;
      maxres: YoutubeThumbnail;
    };
    channelTitle: string;
    tags: string[];
    categoryId: string;
    liveBroadcastContent: string;
    defaultLanguage: string;
    localized: {
      title: string;
      description: string;
    };
    defaultAudioLanguage: string;
  };
  contentDetails: {
    duration: string;
    dimension: string;
    definition: string;
    caption: string;
    licensedContent: boolean;
    contentRating: unknown;
    projection: string;
  };
  player: {
    embedHtml: string;
  };
}

@Injectable()
export class PLYouTubeService {
  readonly youtubeAPIkey = 'AIzaSyBpXfscTZy4Ap6eaVV2UGwwVX1mn5e59HI';
  readonly baseSearchByIdURL = `https://www.googleapis.com/youtube/v3/videos?part=snippet,contentDetails,player&key=${this.youtubeAPIkey}`;

  public readonly NO_RESULTS = 'NO_RESULTS';

  youTubeGetID(url) {
    let ID = '';
    const parts = url
      .replace(/(>|<)/gi, '')
      .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (parts[2] !== undefined) {
      ID = parts[2].split(/[^0-9a-z_-]/i);
      ID = ID[0];
    } else {
      ID = url;
    }
    return ID;
  }

  youTubeIDSearch = function (query) {
    return new Observable<YoutubeResult | 'NO_RESULTS'>((observer: any) => {
      const id = this.youTubeGetID(query);
      const searchURL = `${this.baseSearchByIdURL}&id=${id}`;

      // execute request
      const searchRequest = new XMLHttpRequest();
      searchRequest.open('GET', searchURL, true);
      searchRequest.onload = () => {
        let response;

        if (searchRequest.readyState !== 4) return;

        try {
          response = JSON.parse(searchRequest.responseText);
        } catch (err) {
          // noop
        }

        if (searchRequest.status === 200 && response) {
          if (response.items.length === 0) {
            observer.next(this.NO_RESULTS);
          } else {
            observer.next(response.items[0]);
          }
        } else {
          observer.error(response?.error?.message || 'Unexpected Error');
        }

        observer.complete();
      };
      searchRequest.onerror = function (e) {
        console.error(searchRequest.statusText, e);
        observer.error();
      };
      searchRequest.send();
    });
  };
}
