import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store';
import { StreamSettingsComponent } from '../stream-settings.component';
import { selectHasParticipantJoined } from '../../../session/store';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pl-stream-settings-action',
  templateUrl: 'stream-settings-action.component.html',
})
export class StreamSettingsActionComponent implements OnInit {
  @Input() private participantId: string;

  disabled$: Observable<boolean>;

  constructor(private dialog: MatDialog, private store: Store<AppState>) {}

  ngOnInit() {
    this.disabled$ = this.store
      .select(selectHasParticipantJoined(this.participantId))
      .pipe(map(hasJoined => !hasJoined));
  }

  showSettings() {
    this.dialog.open(StreamSettingsComponent, {
      data: { participantId: this.participantId },
      width: '370px',
      minHeight: '80%',
    });
  }
}
