export enum LayoutMode {
  compact = 'compact',
  jumbotron = 'jumbotron',
  grid = 'grid',
}

export interface AppConfig {
  isCursorShared: boolean;
  isClientClickMuted: boolean;
  isToggleDisabled: boolean;
  layoutMode: LayoutMode;
  activitiesActive: boolean;
  gamesActive: boolean;
  shouldShowWorkspace: boolean;
  whiteScreenActive: boolean;
  whiteboardActive: boolean;
  widgetsboardActive: boolean;
  currentDrawerActivity: Activity;
  isCentralContentVisible: boolean;
  currentCentralContent: CentralContent;
}

export enum ActivityType {
  ACTIVITIY = 'activity',
  ASSESSMENT = 'assessment',
  GAME = 'game',
  INSTANT_YOUTUBE = 'instant_youtube',
  LESSON = 'lesson',
}

export interface Activity {
  activityId: string;
  activity_type: ActivityType;
  id: number;
  name: string;
  queueId: string;
  session_id: string;
  slug: string;
  type: string;
  resource_type?: string;
}

export enum CentralContentType {
  Empty = 'empty',
  QueueItem = 'queueitem',
  Teamwrite = 'teamwrite',
  ContentShare = 'contentshare',
  Game = 'game',
  SiteShare = 'siteshare',
  BoomCards = 'boomcards',
}

export interface CentralContent {
  contentType: CentralContentType;
  contentDetail?: any;
}

export type AppConfigState = Partial<AppConfig>;
