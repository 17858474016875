<div class="item-notes padding" *ngIf="notes">
  <mat-button-toggle-group
    [value]="currentDisplayedNote"
    (change)="onChangeDisplayedNote($event.value)"
    *ngIf="noteSchema?.code === 'soap'">
    <mat-button-toggle class="button-toggle" [value]="'subjective'">
      S
    </mat-button-toggle>
    <mat-button-toggle class="button-toggle" [value]="'objective'">
      O
    </mat-button-toggle>
    <mat-button-toggle class="button-toggle" [value]="'assessment'">
      A
    </mat-button-toggle>
    <mat-button-toggle class="button-toggle" [value]="'plan'">
      P
    </mat-button-toggle>
    <mat-button-toggle class="button-toggle" [value]="'notes'">
      General
    </mat-button-toggle>
  </mat-button-toggle-group>

  <button
    [disabled]="isSigned || isLoading"
    mat-raised-button
    color="primary"
    *ngIf="
      metricPoints && hasNewMetrics && currentDisplayedNote === 'objective'
    "
    (click)="onImportMetrics()">
    <pl-icon-new [name]="'add_circle_outline'"></pl-icon-new> Import metrics
  </button>
  <mat-form-field>
    <div class="loader" *ngIf="isSavingNotes">
      <pl-loader></pl-loader> Saving
    </div>
    <textarea
      [disabled]="isSigned || isLoading"
      (ngModelChange)="onNotesChanged()"
      [(ngModel)]="notes[currentDisplayedNote]"
      class="notes-input"
      matInput
      [placeholder]="
        'Add ' +
        (currentDisplayedNote === 'notes' ? 'general' : currentDisplayedNote) +
        ' note'
      "></textarea>
  </mat-form-field>
  <pl-confirm-alert
    *ngIf="currentDictationStatus === 'starting'"
    cancelText="Cancel"
    confirmText="I understand"
    (confirm)="onConfirmDictationStart()"
    (cancel)="onStopDictation()">
    While dictating your notes, please avoid using any personally identifiable
    information (PII).
  </pl-confirm-alert>
  <button
    [disabled]="isSigned || isLoading"
    *ngIf="currentDictationStatus === 'stopped'"
    mat-stroked-button
    color="primary"
    (click)="onStartDictation()">
    <pl-icon-new [name]="'microphone'"></pl-icon-new>
    Dictate notes
  </button>
  <button
    [disabled]="isSigned || isLoading"
    mat-stroked-button
    color="warn"
    *ngIf="currentDictationStatus === 'dictating'"
    (click)="onStopDictation()">
    <pl-icon-new [name]="'cancel'"></pl-icon-new>
    Stop dictation
  </button>

  <ng-container *ngIf="isEvaluation">
    <div>
      This evaluation has assessment components which may be documented
      individually.
    </div>
    <button mat-stroked-button color="primary" (click)="onViewComponents()">
      View components
      <pl-icon-new [name]="'open_in_new'"></pl-icon-new>
    </button>
  </ng-container>
</div>

<mat-expansion-panel *ngIf="!isEvaluation">
  <mat-expansion-panel-header>
    <mat-panel-title>
      Sign-off -
      <span class="unsigned" *ngIf="!isSigned">&nbsp;Unsigned </span>
      <span class="signed" *ngIf="isSigned">&nbsp;Signed </span>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="sign-container">
    <mat-checkbox [(ngModel)]="signConfirmation" [disabled]="isSigned"
      >I verify that this event occured, as reported</mat-checkbox
    >
    <pl-alert-message *ngIf="!canSign && signConfirmation">
      At least one S/O/A/P note is required for sign-off.
    </pl-alert-message>
    <button
      *ngIf="!isSigned"
      [disabled]="!canSign || isLoading || !signConfirmation"
      (click)="onSignOff(true)"
      mat-flat-button
      color="success"
      class="w-100">
      <ng-container *ngIf="isSigningLoading">
        <pl-loader></pl-loader> Unsigning event
      </ng-container>
      <ng-container *ngIf="!isSigningLoading"> Sign-off </ng-container>
    </button>

    <button
      [disabled]="isLoading"
      *ngIf="isSigned"
      (click)="onSignOff(false)"
      mat-stroked-button
      color="primary"
      class="w-100">
      <ng-container *ngIf="isSigningLoading">
        <pl-loader></pl-loader> Signing-off
      </ng-container>
      <ng-container *ngIf="!isSigningLoading"> Unsign </ng-container>
    </button>
  </div>
</mat-expansion-panel>
