<div class="presence-documentation">
  <div class="blackout-alert">
    <pl-alert-message
      *ngIf="isBlackoutDay$ | async"
      class="pl-non-service-day margin padding center">
      <div class="alert-header">
        <mat-icon inline> warning </mat-icon>
        <label>Non-Service day</label>
      </div>
      <div>
        This is a Non-Service day at the school. Please make sure you're
        authorized to work for this school today.
      </div>
    </pl-alert-message>
  </div>
  <pl-presence-documentation-appointment-selection
    (selectionChanged)="appointmentSelectionChanged($event)"
    [clientAppointmentsSelectionOptions]="
      clientAppointmentsSelectionOptions$ | async
    "></pl-presence-documentation-appointment-selection>

  <div class="documentation-appointment-list">
    <pl-presence-documentation-appointment-item
      *ngFor="
        let clientAppointment of selectedClientAppointments$ | async;
        trackBy: trackByAppointment
      "
      [itemState]="itemStates[clientAppointment.appointment.uuid]"
      (itemStateChange)="
        onItemStateChange(clientAppointment.appointment.uuid, $event)
      "
      [clientAppointment]="clientAppointment"
      [provider]="currentUser">
    </pl-presence-documentation-appointment-item>
  </div>
</div>
