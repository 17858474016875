<ul class="activities-list">
  <li
    *ngFor="let item of activities; let index = index"
    class="activity-item"
    [ngClass]="item.xClasses"
    data-test="activity-item"
    (click)="activityClicked(item)"
  >
    <img
      *ngIf="item.thumbnail_url"
      class="activity-item__img"
      [src]="item.thumbnail_url"
    />

    <div class="activity-item__content">
      <div class="activity-item__type">
        {{
          (item.type === 'game' ? item.game_type : item.activity_type)
            | activityTypeLabel
        }}
      </div>
      <div
        class="activity-item__name"
        data-testid="activity-item-name"
        [matTooltipDisabled]="item.name.length < 30"
        matTooltip="{{ item.name }}"
      >
        {{ item.name }}
      </div>
    </div>

    <button
      mat-icon-button
      class="activity-item__icon"
      [hidden]="!item.xAdded"
      (click)="removeActivity.emit(item); $event.stopPropagation()"
    >
      <mat-icon class="icon-check">check_circle</mat-icon>
      <mat-icon class="icon-remove">do_not_disturb_on</mat-icon>
    </button>
  </li>
</ul>
