<div class="metric-item">
  <div>{{ metricPoint.metric.name }}</div>

  <div class="scorebar">
    <div class="buttons">
      <button
        mat-flat-button
        class="correct-button"
        (click)="incrementCorrect()">
        <mat-icon>done</mat-icon>
      </button>
      <button mat-flat-button class="wrong-button" (click)="incrementWrong()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="scores">
      <div class="input-with-arrows">
        <input
          matInput
          type="number"
          [(ngModel)]="metricPoint.correct"
          (keyup)="onKeyUpCorrect()"
          (blue)="onBlurCorrect()" />
        <div class="up-down-buttons">
          <button mat-button (click)="correctUp()">
            <mat-icon class="arrow-icon">arrow_drop_up</mat-icon>
          </button>
          <button mat-button (click)="correctDown()">
            <mat-icon class="arrow-icon">arrow_drop_down</mat-icon>
          </button>
        </div>
      </div>
      <span class="slash">/</span>
      <div class="input-with-arrows">
        <input matInput type="number" [(ngModel)]="metricPoint.trials" />
        <div class="up-down-buttons">
          <button mat-button (click)="trialsUp()">
            <mat-icon class="arrow-icon">arrow_drop_up</mat-icon>
          </button>
          <button mat-button (click)="trialsDown()">
            <mat-icon class="arrow-icon">arrow_drop_down</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="accuracy padding-b">
    Accuracy: {{ metricPoint.percent_correct }}%
  </div>
  <mat-expansion-panel class="metric-goal">
    <mat-expansion-panel-header> Show goal </mat-expansion-panel-header>
    <div>{{ metricPoint.metric.goal.description }}</div>
  </mat-expansion-panel>
</div>
