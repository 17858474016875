<div data-testid="design-system-buttons">
  <div class="demo-group">
    <header class="demo-group-header">
      <h2 class="heading-2">Basic Buttons</h2>
      <mat-slide-toggle [(ngModel)]="showIcons"> Show Icons </mat-slide-toggle>
    </header>

    <div class="demo-row" data-testid="basic-buttons">
      <div class="demo-col">
        <h3 class="heading-3">mat-button</h3>
        <button mat-button>
          <mat-icon *ngIf="showIcons">home</mat-icon>
          Basic
        </button>
        <button mat-button color="primary">
          <mat-icon *ngIf="showIcons">home</mat-icon>
          Primary
        </button>
        <button mat-button color="accent">
          <mat-icon *ngIf="showIcons">home</mat-icon>
          Accent
        </button>
        <button mat-button color="warn">
          <mat-icon *ngIf="showIcons">home</mat-icon>
          Warn
        </button>
        <button mat-button color="success">
          <mat-icon *ngIf="showIcons">home</mat-icon>
          Success
        </button>
        <button mat-button disabled>
          <mat-icon *ngIf="showIcons">home</mat-icon>
          Disabled
        </button>
      </div>

      <div class="demo-col">
        <h3 class="heading-3">mat-flat-button</h3>
        <button mat-flat-button>
          <mat-icon *ngIf="showIcons">home</mat-icon>
          Basic
        </button>
        <button mat-flat-button color="primary">
          <mat-icon *ngIf="showIcons">home</mat-icon>
          Primary
        </button>
        <button mat-flat-button color="accent">
          <mat-icon *ngIf="showIcons">home</mat-icon>
          Accent
        </button>
        <button mat-flat-button color="warn">
          <mat-icon *ngIf="showIcons">home</mat-icon>
          Warn
        </button>
        <button mat-flat-button color="success">
          <mat-icon *ngIf="showIcons">home</mat-icon>
          Success
        </button>
        <button mat-flat-button disabled>
          <mat-icon *ngIf="showIcons">home</mat-icon>
          Disabled
        </button>
      </div>

      <div class="demo-col">
        <h3 class="heading-3">mat-raised-button</h3>
        <button mat-raised-button>
          <mat-icon *ngIf="showIcons">home</mat-icon>
          Basic
        </button>
        <button mat-raised-button color="primary">
          <mat-icon *ngIf="showIcons">home</mat-icon>
          Primary
        </button>
        <button mat-raised-button color="accent">
          <mat-icon *ngIf="showIcons">home</mat-icon>
          Accent
        </button>
        <button mat-raised-button color="warn">
          <mat-icon *ngIf="showIcons">home</mat-icon>
          Warn
        </button>
        <button mat-raised-button color="success">
          <mat-icon *ngIf="showIcons">home</mat-icon>
          Success
        </button>
        <button mat-raised-button disabled>
          <mat-icon *ngIf="showIcons">home</mat-icon>
          Disabled
        </button>
      </div>

      <div class="demo-col">
        <h3 class="heading-3">mat-stroked-button</h3>
        <button mat-stroked-button>
          <mat-icon *ngIf="showIcons">home</mat-icon>
          Basic
        </button>
        <button mat-stroked-button color="primary">
          <mat-icon *ngIf="showIcons">home</mat-icon>
          Primary
        </button>
        <button mat-stroked-button color="accent">
          <mat-icon *ngIf="showIcons">home</mat-icon>
          Accent
        </button>
        <button mat-stroked-button color="warn">
          <mat-icon *ngIf="showIcons">home</mat-icon>
          Warn
        </button>
        <button mat-stroked-button color="success">
          <mat-icon *ngIf="showIcons">home</mat-icon>
          Success
        </button>
        <button mat-stroked-button disabled>
          <mat-icon *ngIf="showIcons">home</mat-icon>
          Disabled
        </button>
      </div>

      <div class="demo-col">
        <h3 class="heading-3">mat-icon-button</h3>
        <button mat-icon-button><mat-icon>home</mat-icon></button>
        <button mat-icon-button color="primary">
          <mat-icon>home</mat-icon>
        </button>
        <button mat-icon-button color="accent">
          <mat-icon>home</mat-icon>
        </button>
        <button mat-icon-button color="warn">
          <mat-icon>home</mat-icon>
        </button>
        <button mat-icon-button color="success">
          <mat-icon>home</mat-icon>
        </button>
        <button mat-icon-button disabled>
          <mat-icon>home</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="demo-group">
    <header class="demo-group-header">
      <h2 class="heading-2">Rounded Buttons</h2>
    </header>

    <div class="demo-row" data-testid="rounded-buttons">
      <div class="demo-col">
        <h3 class="heading-3">mat-button</h3>
        <button class="is-rounded" mat-button>
          <mat-icon *ngIf="showIcons">home</mat-icon>
          Basic
        </button>
        <button class="is-rounded" mat-button color="primary">
          <mat-icon *ngIf="showIcons">home</mat-icon>
          Primary
        </button>
        <button class="is-rounded" mat-button color="accent">
          <mat-icon *ngIf="showIcons">home</mat-icon>
          Accent
        </button>
        <button class="is-rounded" mat-button color="warn">
          <mat-icon *ngIf="showIcons">home</mat-icon>
          Warn
        </button>
        <button class="is-rounded" mat-button color="success">
          <mat-icon *ngIf="showIcons">home</mat-icon>
          Success
        </button>
        <button class="is-rounded" mat-button disabled>
          <mat-icon *ngIf="showIcons">home</mat-icon>
          Disabled
        </button>
      </div>

      <div class="demo-col">
        <h3 class="heading-3">mat-flat-button</h3>
        <button class="is-rounded" mat-flat-button>
          <mat-icon *ngIf="showIcons">home</mat-icon>
          Basic
        </button>
        <button class="is-rounded" mat-flat-button color="primary">
          <mat-icon *ngIf="showIcons">home</mat-icon>
          Primary
        </button>
        <button class="is-rounded" mat-flat-button color="accent">
          <mat-icon *ngIf="showIcons">home</mat-icon>
          Accent
        </button>
        <button class="is-rounded" mat-flat-button color="warn">
          <mat-icon *ngIf="showIcons">home</mat-icon>
          Warn
        </button>
        <button class="is-rounded" mat-flat-button color="success">
          <mat-icon *ngIf="showIcons">home</mat-icon>
          Success
        </button>
        <button class="is-rounded" mat-flat-button disabled>
          <mat-icon *ngIf="showIcons">home</mat-icon>
          Disabled
        </button>
      </div>

      <div class="demo-col">
        <h3 class="heading-3">mat-raised-button</h3>
        <button class="is-rounded" mat-raised-button>
          <mat-icon *ngIf="showIcons">home</mat-icon>
          Basic
        </button>
        <button class="is-rounded" mat-raised-button color="primary">
          <mat-icon *ngIf="showIcons">home</mat-icon>
          Primary
        </button>
        <button class="is-rounded" mat-raised-button color="accent">
          <mat-icon *ngIf="showIcons">home</mat-icon>
          Accent
        </button>
        <button class="is-rounded" mat-raised-button color="warn">
          <mat-icon *ngIf="showIcons">home</mat-icon>
          Warn
        </button>
        <button class="is-rounded" mat-raised-button color="success">
          <mat-icon *ngIf="showIcons">home</mat-icon>
          Success
        </button>
        <button class="is-rounded" mat-raised-button disabled>
          <mat-icon *ngIf="showIcons">home</mat-icon>
          Disabled
        </button>
      </div>

      <div class="demo-col">
        <h3 class="heading-3">mat-stroked-button</h3>
        <button class="is-rounded" mat-stroked-button>
          <mat-icon *ngIf="showIcons">home</mat-icon>
          Basic
        </button>
        <button class="is-rounded" mat-stroked-button color="primary">
          <mat-icon *ngIf="showIcons">home</mat-icon>
          Primary
        </button>
        <button class="is-rounded" mat-stroked-button color="accent">
          <mat-icon *ngIf="showIcons">home</mat-icon>
          Accent
        </button>
        <button class="is-rounded" mat-stroked-button color="warn">
          <mat-icon *ngIf="showIcons">home</mat-icon>
          Warn
        </button>
        <button class="is-rounded" mat-stroked-button color="success">
          <mat-icon *ngIf="showIcons">home</mat-icon>
          Success
        </button>
        <button class="is-rounded" mat-stroked-button disabled>
          <mat-icon *ngIf="showIcons">home</mat-icon>
          Disabled
        </button>
      </div>
    </div>
  </div>

  <div class="demo-group">
    <header class="demo-group-header">
      <h2 class="heading-2">FAB Buttons</h2>
    </header>

    <div class="demo-row" data-testid="fab-buttons">
      <div class="demo-col">
        <h3 class="heading-3">&nbsp;</h3>
        <div class="label-56">Basic</div>
        <div class="label-56">Primary</div>
        <div class="label-56">Accent</div>
        <div class="label-56">Warn</div>
        <div class="label-56">Success</div>
        <div class="label-56">Disabled</div>
      </div>

      <div class="demo-col is-center">
        <h3 class="heading-3">mat-fab</h3>
        <button mat-fab><mat-icon>home</mat-icon></button>
        <button mat-fab color="primary"><mat-icon>home</mat-icon></button>
        <button mat-fab color="accent"><mat-icon>home</mat-icon></button>
        <button mat-fab color="warn"><mat-icon>home</mat-icon></button>
        <button mat-fab color="success"><mat-icon>home</mat-icon></button>
        <button mat-fab disabled><mat-icon>home</mat-icon></button>
      </div>

      <div class="demo-col is-center">
        <h3 class="heading-3">mat-mini-fab</h3>
        <button mat-mini-fab><mat-icon>home</mat-icon></button>
        <button mat-mini-fab color="primary">
          <mat-icon>home</mat-icon>
        </button>
        <button mat-mini-fab color="accent">
          <mat-icon>home</mat-icon>
        </button>
        <button mat-mini-fab color="warn">
          <mat-icon>home</mat-icon>
        </button>
        <button mat-mini-fab color="success">
          <mat-icon>home</mat-icon>
        </button>
        <button mat-mini-fab disabled><mat-icon>home</mat-icon></button>
      </div>
    </div>
  </div>

  <div class="demo-group">
    <header class="demo-group-header">
      <h2 class="heading-2">Split Buttons</h2>
    </header>

    <div class="demo-row" data-testid="split-buttons">
      <div class="demo-col">
        <h3 class="heading-3">mat-button</h3>

        <div class="split-button">
          <button mat-button class="left">
            <mat-icon *ngIf="showIcons">search</mat-icon>
            <span>Basic</span>
          </button>
          <button mat-button class="right">
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
        </div>

        <div class="split-button">
          <button mat-button color="primary" class="left">
            <mat-icon *ngIf="showIcons">search</mat-icon>
            <span>Primary</span>
          </button>
          <button mat-button color="primary" class="right">
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
        </div>

        <div class="split-button">
          <button mat-button color="accent" class="left">
            <mat-icon *ngIf="showIcons">search</mat-icon>
            <span>Accent</span>
          </button>
          <button mat-button color="accent" class="right">
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
        </div>

        <div class="split-button">
          <button mat-button color="warn" class="left">
            <mat-icon *ngIf="showIcons">search</mat-icon>
            <span>Warn</span>
          </button>
          <button mat-button color="warn" class="right">
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
        </div>

        <div class="split-button">
          <button mat-button color="success" class="left">
            <mat-icon *ngIf="showIcons">search</mat-icon>
            <span>Success</span>
          </button>
          <button mat-button color="success" class="right">
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
        </div>

        <div class="split-button">
          <button mat-button disabled class="left">
            <mat-icon *ngIf="showIcons">search</mat-icon>
            <span>Disabled</span>
          </button>
          <button mat-button disabled class="right">
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
        </div>
      </div>

      <div class="demo-col">
        <h3 class="heading-3">mat-flat-button</h3>

        <div class="split-button">
          <button mat-flat-button class="left">
            <mat-icon *ngIf="showIcons">search</mat-icon>
            <span>Basic</span>
          </button>
          <button mat-flat-button class="right">
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
        </div>

        <div class="split-button">
          <button mat-flat-button color="primary" class="left">
            <mat-icon *ngIf="showIcons">search</mat-icon>
            <span>Primary</span>
          </button>
          <button mat-flat-button color="primary" class="right">
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
        </div>

        <div class="split-button">
          <button mat-flat-button color="accent" class="left">
            <mat-icon *ngIf="showIcons">search</mat-icon>
            <span>Accent</span>
          </button>
          <button mat-flat-button color="accent" class="right">
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
        </div>

        <div class="split-button">
          <button mat-flat-button color="warn" class="left">
            <mat-icon *ngIf="showIcons">search</mat-icon>
            <span>Warn</span>
          </button>
          <button mat-flat-button color="warn" class="right">
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
        </div>

        <div class="split-button">
          <button mat-flat-button color="success" class="left">
            <mat-icon *ngIf="showIcons">search</mat-icon>
            <span>Success</span>
          </button>
          <button mat-flat-button color="success" class="right">
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
        </div>

        <div class="split-button">
          <button mat-flat-button disabled class="left">
            <mat-icon *ngIf="showIcons">search</mat-icon>
            <span>Disabled</span>
          </button>
          <button mat-flat-button disabled class="right">
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
        </div>
      </div>

      <div class="demo-col">
        <h3 class="heading-3">mat-raised-button</h3>

        <div class="split-button">
          <button mat-raised-button class="left">
            <mat-icon *ngIf="showIcons">search</mat-icon>
            <span>Basic</span>
          </button>
          <button mat-raised-button class="right">
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
        </div>

        <div class="split-button">
          <button mat-raised-button color="primary" class="left">
            <mat-icon *ngIf="showIcons">search</mat-icon>
            <span>Primary</span>
          </button>
          <button mat-raised-button color="primary" class="right">
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
        </div>

        <div class="split-button">
          <button mat-raised-button color="accent" class="left">
            <mat-icon *ngIf="showIcons">search</mat-icon>
            <span>Accent</span>
          </button>
          <button mat-raised-button color="accent" class="right">
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
        </div>

        <div class="split-button">
          <button mat-raised-button color="warn" class="left">
            <mat-icon *ngIf="showIcons">search</mat-icon>
            <span>Warn</span>
          </button>
          <button mat-raised-button color="warn" class="right">
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
        </div>

        <div class="split-button">
          <button mat-raised-button color="success" class="left">
            <mat-icon *ngIf="showIcons">search</mat-icon>
            <span>Success</span>
          </button>
          <button mat-raised-button color="success" class="right">
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
        </div>

        <div class="split-button">
          <button mat-raised-button disabled class="left">
            <mat-icon *ngIf="showIcons">search</mat-icon>
            <span>Disabled</span>
          </button>
          <button mat-raised-button disabled class="right">
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
        </div>
      </div>

      <div class="demo-col">
        <h3 class="heading-3">mat-stroked-button</h3>

        <div class="split-button">
          <button mat-stroked-button class="left">
            <mat-icon *ngIf="showIcons">search</mat-icon>
            <span>Basic</span>
          </button>
          <button mat-stroked-button class="right">
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
        </div>

        <div class="split-button">
          <button mat-stroked-button color="primary" class="left">
            <mat-icon *ngIf="showIcons">search</mat-icon>
            <span>Primary</span>
          </button>
          <button mat-stroked-button color="primary" class="right">
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
        </div>

        <div class="split-button">
          <button mat-stroked-button color="accent" class="left">
            <mat-icon *ngIf="showIcons">search</mat-icon>
            <span>Accent</span>
          </button>
          <button mat-stroked-button color="accent" class="right">
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
        </div>

        <div class="split-button">
          <button mat-stroked-button color="warn" class="left">
            <mat-icon *ngIf="showIcons">search</mat-icon>
            <span>Warn</span>
          </button>
          <button mat-stroked-button color="warn" class="right">
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
        </div>

        <div class="split-button">
          <button mat-stroked-button color="success" class="left">
            <mat-icon *ngIf="showIcons">search</mat-icon>
            <span>Success</span>
          </button>
          <button mat-stroked-button color="success" class="right">
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
        </div>

        <div class="split-button">
          <button mat-stroked-button disabled class="left">
            <mat-icon *ngIf="showIcons">search</mat-icon>
            <span>Disabled</span>
          </button>
          <button mat-stroked-button disabled class="right">
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
