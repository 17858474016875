import { AppState } from '@app/store';
import { createSelector } from '@ngrx/store';

export const selectGoFishDrawer = (state: AppState) => state.goFishGame;

export const selectIsGameActive = createSelector(
  selectGoFishDrawer,
  goFishDrawer => goFishDrawer.isGameActive,
);

export const selectGameCards = createSelector(
  selectGoFishDrawer,
  goFishDrawer => goFishDrawer.cards,
);
