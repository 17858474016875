import { NgModule } from '@angular/core';
import { PLContentSharingDrawerComponent } from './pl-content-sharing-drawer.component';
import { CommonModule } from '@angular/common';
import { ActivitySwitchModule } from '../activity-switch';
import { MatIconModule } from '@angular/material/icon';
import { SiteshareModule } from '@app/modules/siteshare';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { PLCommonComponentsModule } from '@root/src/app/common/components';
import { BoomCardsDrawerComponent } from './boom-cards-drawer/boom-cards-drawer.component';
import { MatRadioModule } from '@angular/material/radio';

@NgModule({
  imports: [
    CommonModule,
    ActivitySwitchModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatRadioModule,
    SiteshareModule,
    FormsModule,
    PLCommonComponentsModule,
  ],
  exports: [PLContentSharingDrawerComponent, BoomCardsDrawerComponent],
  declarations: [PLContentSharingDrawerComponent, BoomCardsDrawerComponent],
  providers: [],
})
export class PLContentSharingDrawerModule {}
