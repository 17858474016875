import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '@root/src/app/store';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { selectIsObserverPendingInteraction } from '../../../session/store';
import { RoomConferenceService } from '../../room-conference.service';
import { ConferenceActions } from '../conference.actions';
import { selectIsStreamInSession } from '../conference.selectors';

@Injectable({ providedIn: 'root' })
export class ConferenceSubscribeEffects {
  addRemoteStream$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConferenceActions.addRemote),
      map(({ stream }) => stream.id),
      concatLatestFrom(id => this.store.select(selectIsStreamInSession(id))),
      filter(([, isInSession]) => isInSession),
      map(([id]) => ConferenceActions.subscribe({ id })),
    );
  });

  subscribe$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConferenceActions.subscribe),
      mergeMap(({ id }) => {
        return this.subscribe(id).pipe(
          map(() => ConferenceActions.subscribeSuccess({ id })),
          catchError(error => {
            const errorObj = {
              id,
              error,
            };
            console.error('SUBSCRIBE TO REMOTE', errorObj);
            return of(ConferenceActions.subscribeError(errorObj));
          }),
        );
      }),
    );
  });

  unsubscribe$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ConferenceActions.unsubscribe),
        tap(({ id }) => {
          this.conferenceService.unsubscribe(id);
        }),
      );
    },
    {
      dispatch: false,
    },
  );

  private subscribe(id: string) {
    return this.waitForObserverInteraction().pipe(
      mergeMap(() => this.conferenceService.subscribe(id)),
    );
  }

  private waitForObserverInteraction() {
    return this.store.select(selectIsObserverPendingInteraction).pipe(
      filter(isPending => !isPending),
      take(1),
    );
  }

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private conferenceService: RoomConferenceService,
  ) {}
}
