import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@root/src/app/store';
import moment from 'moment';
import { Subscription, Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import {
  ClientGoal,
  SessionNote,
  StudentItem,
  ClientObjective,
} from '../documentation-te.model';
import { DocumentationTEService } from '../documentation-te.service';

@Component({
  selector: 'pl-documentation-te-client',
  templateUrl: 'documentation-te-client.component.html',
  styleUrls: ['./documentation-te-client.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class DocumentationTEClientComponent
  implements OnChanges, OnDestroy, OnInit
{
  @Input() public clientEvent: StudentItem;
  @Output() public readonly sessionEnded = new EventEmitter<void>();

  isLoading = false;
  startDate = '';
  startTime = '';

  metricPoint = { metric: { goal: {} } };
  viewMode = 'notes';
  serviceTypes = [
    {
      label: 'Therapy',
      value: 'Therapy',
    },
    {
      label: 'Evaluation',
      value: 'Evaluation',
    },
    {
      label: 'Screening',
      value: 'Screening',
    },
    {
      label: 'Consultation',
      value: 'Consultation',
    },
  ];
  attendanceStatusOptions = [
    {
      label: 'Absent',
      value: 'absent',
    },
    {
      label: 'Present',
      value: 'present',
    },
    {
      label: 'Cancellation',
      value: 'cancellation',
    },
    {
      label: 'Late',
      value: 'late',
    },
    {
      label: 'Other',
      value: 'other',
    },
  ];
  sessionDurationOptions = [];
  noteData: SessionNote;
  clientGoals: ClientGoal[] = [];
  private timeout;
  private subscriptions: Subscription[] = [];
  private currentStudent: StudentItem;

  constructor(
    private docService: DocumentationTEService,
    private store: Store<AppState>,
  ) {
    moment.relativeTimeThreshold('m', 60);
    const durations = [];
    for (let i = 15; i <= 300; i += i < 60 ? 15 : 60) {
      const duration = moment.duration(i, 'minutes');
      const label = duration.humanize();
      const value = moment.utc(duration.as('milliseconds')).format('HH:mm');
      durations.push({
        label,
        value,
      });
    }
    this.sessionDurationOptions = durations;
  }

  ngOnChanges() {
    if (!this.clientEvent) {
      return;
    }
    this.viewMode = 'notes';
    this.currentStudent = { ...this.clientEvent };
    this.isLoading = true;
    if (this.currentStudent.event) {
      const dateParts = this.currentStudent.event.start_date_local.split('T');
      this.startDate = dateParts[0];
      this.startTime = dateParts[1];
      if (this.currentStudent.event.uuid === undefined) {
        // This is a non-persisted recurring event, there is no session yet
        this.initNewNote();
        this.isLoading = false;
        return;
      }

      this.subscriptions.push(
        this.docService
          .getStudentSession(this.currentStudent)
          .subscribe(note => {
            if (note) {
              this.noteData = {
                ...note,
              };
              if (note.session_duration) {
                // HH:MM:SS format -> HH:MM
                const sessionDurationParts = note.session_duration.split(':');
                this.noteData.session_duration = `${sessionDurationParts[0]}:${sessionDurationParts[1]}`;
              }
            } else {
              this.initNewNote();
            }
            this.getGoals();
            this.isLoading = false;
          }),
      );
    } else {
      const now = moment();
      this.startDate = now.format('YYYY-MM-DD');
      this.startTime = now.format('HH:mm');
      this.initNewNote();
      this.isLoading = false;
    }
  }

  ngOnInit() {
    this.getGoals();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getGoals() {
    const goals$ = this.docService
      .getClientGoals(this.clientEvent.client.uuid, this.noteData?.uuid)
      .pipe(first());
    goals$.subscribe(res => {
      if (res?.length) {
        this.clientGoals = res;
      }
    });
    return goals$;
  }

  initNewNote() {
    this.noteData = {
      note: '',
      client: this.currentStudent.client.uuid,
    };
  }

  toggleNotesMetrics(viewMode: string) {
    this.viewMode = viewMode;
  }

  isSessionReady() {
    const durationRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
    const { session_duration } = this.noteData;
    const isValidDuration = durationRegex.test(session_duration);
    return (
      (this.startDate && this.startTime && !session_duration) || isValidDuration
    );
  }

  saveNote(options?: {
    endSession?: boolean;
    metricEvent?: { objective: ClientObjective; metricCallback: Function };
  }) {
    if (!this.isSessionReady()) {
      return;
    }
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      this.isLoading = true;
      const startDateTime = moment(`${this.startDate}T${this.startTime}`);
      let endDateTime = moment(`${this.startDate}T${this.startTime}`);
      if (this.noteData.session_duration) {
        const minutes = moment
          .duration(this.noteData.session_duration)
          .asMinutes();
        endDateTime = endDateTime.add(minutes, 'minutes');
      }
      if (!this.currentStudent.event) {
        this.currentStudent.event = {
          clients: [this.currentStudent.client.uuid],
          title: `${this.noteData.service_type || 'Therapy'}`,
        };
      }
      this.currentStudent.event.start_date_local = `${this.startDate}T${this.startTime}`;
      this.currentStudent.event.end_date_local =
        endDateTime.format('YYYY-MM-DDTHH:mm');
      this.currentStudent.event.start_date = startDateTime
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss');
      this.currentStudent.event.end_date = endDateTime
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss');
      this.currentStudent.event.timezone =
        Intl.DateTimeFormat().resolvedOptions().timeZone;

      // TODO: cleanup this series of Observable subscriptions
      this.docService.saveEvent(this.currentStudent.event).subscribe(res => {
        this.currentStudent.event.uuid = res.uuid;
        this.noteData.event = res.uuid;
        this.saveNoteData().subscribe(sessionNote => {
          const metricEvent = options?.metricEvent;
          if (metricEvent) {
            this.docService
              .updateMetric(metricEvent.objective, sessionNote.uuid)
              .subscribe(savedObjective => {
                this.getGoals().subscribe(() => {
                  metricEvent.metricCallback(savedObjective);
                });
              });
          } else {
            this.getGoals();
          }
          this.noteData.uuid = sessionNote.uuid;
          this.isLoading = false;
          if (options?.endSession) {
            this.sessionEnded.emit();
          }
        });
      });
    }, 500);
  }

  onEndSession() {
    this.saveNote({ endSession: true });
  }

  isDurationSelectDisabled() {
    if (!this.startDate || !this.startTime) {
      return false;
    }
    const startDate = moment(`${this.startDate}T${this.startTime}`);
    const now = moment();
    return now.diff(startDate, 'days', true) >= 3;
  }

  onSaveMetric($event: {
    objective: ClientObjective;
    metricCallback: Function;
  }) {
    this.saveNote({ metricEvent: $event });
  }

  private saveNoteData(): Observable<SessionNote> {
    const data: SessionNote = {
      ...this.noteData,
    };
    if (this.noteData.session_duration) {
      const sessionDuration = `${this.noteData.session_duration}:00`;
      data.session_duration = sessionDuration;
    }
    return this.docService.saveNote(data);
  }
}
