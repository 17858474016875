import { Component, ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@root/src/app/store';
import { Observable } from 'rxjs';
import { selectCanPromote } from '../../../store';
import { StreamBoxService } from '../stream-box.service';

@Component({
  selector: 'pl-stream-video-box',
  templateUrl: 'stream-video-box.component.html',
  styleUrls: ['stream-video-box.component.less'],
})
export class StreamVideoBoxComponent {
  public mediaStream$: Observable<MediaStream>;
  public isMuted$: Observable<boolean>;
  public isMirrored$: Observable<boolean>;
  public isRotated$: Observable<boolean>;
  public isCovered$: Observable<boolean>;
  public canPromote$: Observable<boolean>;
  public id$: Observable<string>;
  isScreenshotting$: Observable<boolean>;

  constructor(
    private streamBoxService: StreamBoxService,
    private element: ElementRef,
    private store: Store<AppState>,
  ) {
    this.mediaStream$ = streamBoxService.mediaStream$;
    this.isMirrored$ = streamBoxService.isMirrored$;
    this.isRotated$ = streamBoxService.isRotated$;
    this.isCovered$ = streamBoxService.isCovered$;
    this.id$ = streamBoxService.id$;
    this.isScreenshotting$ = streamBoxService.isScreenshotting$;
    this.canPromote$ = this.store.select(selectCanPromote);

    this.streamBoxService.nativeElement = this.element.nativeElement;
  }

  videoCaptureComplete() {
    this.streamBoxService.stopScreenshotting();
  }

  onPlayed() {
    this.streamBoxService.setVideoStarting(false);
  }
}
