import { Component, EventEmitter, Input, Output } from '@angular/core';
import { QueueItem } from '../pl-queue.component';

@Component({
  selector: 'pl-queue-detail',
  templateUrl: './pl-queue-detail.component.html',
  styleUrls: ['./pl-queue-detail.component.less'],
})
export class PLQueueDetailComponent {
  @Input() queue: QueueItem;
  @Input() isEditing: boolean;

  @Output() readonly deleteQueue = new EventEmitter<any>();
  @Output() readonly openActivity = new EventEmitter<{ activityId: string }>();
  @Output() readonly openAddActivity = new EventEmitter<any>();
  @Output() readonly updateQueueName = new EventEmitter<string>();
  @Output() readonly isEditingChange = new EventEmitter<boolean>();
  @Output() readonly changeActivitiesOrder = new EventEmitter<string[]>();
  @Output() readonly removeActivity = new EventEmitter<{
    activityId: string;
  }>();
  isDeleting = false;

  editQueueName() {
    this.isEditingChange.emit(true);
  }

  attemptDeleteQueue() {
    this.isDeleting = true;
  }

  cancelDeleteQueue() {
    this.isDeleting = false;
  }

  confirmDeleteQueue() {
    this.isDeleting = false;
    this.deleteQueue.emit();
  }

  saveQueueName(name: string) {
    this.updateQueueName.emit(name);
    this.isEditingChange.emit(false);
  }

  cancelSaveQueueName() {
    this.isEditingChange.emit(false);
  }
}
