import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BookModes } from '../../room/pl-activity/pl-pdf-activity/drawer/pdf-viewer-drawer.component';

@Component({
  selector: 'pl-pdf-drawer-paginator',
  templateUrl: './pdf-drawer-paginator.component.html',
  styleUrls: ['./pdf-drawer-paginator.component.less'],
})
export class PdfDrawerPaginatorComponent {
  @Input() page: number;
  @Input() totalPages: number;
  @Input() bookMode: BookModes = BookModes.SINGLE_PAGE_MODE;
  @Output() readonly pageSelected = new EventEmitter<number>();

  bookModeEnum = BookModes;

  canGoToPrevPage() {
    return (
      this.page > 1 &&
      !(this.bookMode === BookModes.DBL_PAGE_ODD_MODE && this.page === 2)
    );
  }

  canGoToNextPage() {
    if (this.page === this.totalPages) return false;

    if (this.bookMode === BookModes.SINGLE_PAGE_MODE) {
      return true;
    }

    if (
      this.bookMode === BookModes.DBL_PAGE_EVEN_MODE &&
      this.totalPages % 2 !== 0 &&
      this.page + 1 === this.totalPages
    ) {
      return false;
    }

    if (
      this.bookMode === BookModes.DBL_PAGE_ODD_MODE &&
      this.totalPages % 2 === 0 &&
      this.page + 1 === this.totalPages
    ) {
      return false;
    }

    return true;
  }

  handleGoToPage(event: KeyboardEvent) {
    if (event.code !== 'Enter') return;

    const el = event.target as HTMLInputElement;
    const pageNum = parseInt(el.value, 10);

    if (pageNum === this.page) return;

    if (!pageNum || pageNum < 0) {
      el.value = String(this.page);
      return;
    }

    if (pageNum > this.totalPages) {
      return this.pageSelected.emit(this.totalPages);
    }

    return this.pageSelected.emit(pageNum);
  }

  handleGoToPrevPage() {
    if (!this.canGoToPrevPage()) return;

    let pageDest = this.page - 1;

    if (this.bookMode === BookModes.DBL_PAGE_EVEN_MODE) {
      pageDest = this.page - 2 - (this.page % 2);
    }

    if (this.bookMode === BookModes.DBL_PAGE_ODD_MODE) {
      pageDest = this.page - 2;

      if (this.page % 2 === 0) {
        pageDest--;
      }
    }

    pageDest = Math.max(1, pageDest);

    if (pageDest === this.page) return;

    return this.pageSelected.emit(pageDest);
  }

  handleGoToNextPage() {
    if (!this.canGoToNextPage()) return;

    let pageDest = this.page + 1;

    if (this.bookMode === BookModes.DBL_PAGE_EVEN_MODE && this.page % 2 === 0) {
      pageDest = this.page + 2;
    }

    if (this.bookMode === BookModes.DBL_PAGE_ODD_MODE && this.page % 2 !== 0) {
      pageDest = this.page + 2;
    }

    pageDest = Math.min(pageDest, this.totalPages);

    if (pageDest === this.page) return;

    return this.pageSelected.emit(pageDest);
  }
}
